const courses_updated = [
    {
        "id": "b8694e01676bbdbd6b0154d5552b316e",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Creative Artist",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "18"
                },
                {
                    "course_name": "Creative Artist",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "ACM London",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Artist",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Artist (Integrated Masters)",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "MCCI",
                    "duration": "3 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Entrepreneurship - Composition",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Entrepreneurship - Composition",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "ACM London",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Entrepreneurship - Composition (Integrated Masters)",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "MCCI",
                    "duration": "3 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Entrepreneurship - Performance",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Entrepreneurship - Performance",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "ACM London",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Entrepreneurship - Performance (Integrated Masters)",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "MCCI",
                    "duration": "3 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Entrepreneurship - Production",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Entrepreneurship - Production",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "ACM London",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Entrepreneurship - Production (Integrated Masters)",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "MCCI",
                    "duration": "3 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Entrepreneurship - Songwriting",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Entrepreneurship - Songwriting",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "ACM London",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Entrepreneurship - Songwriting (Integrated Masters)",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "MCCI",
                    "duration": "3 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Game Development: Art",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Game Development: Audio",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Game Development: Business",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Game Development: Design",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Game Development: Programming",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Live Production & Technical Arts",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "ACM London",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Live Production & Technical Arts",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "ACM London",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Live Production & Technical Arts (Integrated Masters)",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "MCCI",
                    "duration": "3 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Business (Management & Entrepreneurship)",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "ACM London",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Business (Management & Entrepreneurship)",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Business (Management and Entrepreneurship) [Integrated Masters]",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "MCCI",
                    "duration": "3 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Composition",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "ACM London",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Composition",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Composition",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Composition (Integrated Masters)",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "MCCI",
                    "duration": "3 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Performance - Bass",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "ACM London",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Performance - Bass",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "18"
                },
                {
                    "course_name": "Music Performance - Bass",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Performance - Bass (Integrated Masters)",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "MCCI",
                    "duration": "3 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Performance - Drums",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "ACM London",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Performance - Drums",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "18"
                },
                {
                    "course_name": "Music Performance - Drums",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Performance - Drums (Integrated Masters)",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "MCCI",
                    "duration": "3 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Performance - Guitar",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "ACM London",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Performance - Guitar",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "18"
                },
                {
                    "course_name": "Music Performance - Guitar",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Performance - Guitar (Integrated Masters)",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "MCCI",
                    "duration": "3 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Performance - Jazz & Third Stream",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "ACM London",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Performance - Jazz & Third Stream",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "ACM London",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Performance - Jazz & Third Stream",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Performance - Jazz & Third Stream (Integrated Masters)",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Performance - Keys",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "18"
                },
                {
                    "course_name": "Music Performance - Keys",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Performance - Keys",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "ACM London",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Performance - Keys (Integrated Masters)",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "MCCI",
                    "duration": "3 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Performance - Modern Progressive Music",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "ACM London",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Performance - Modern Progressive Music",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Performance - Modern Progressive Music",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "MCCI",
                    "duration": "3 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Performance - Vocals",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Performance - Vocals",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "18"
                },
                {
                    "course_name": "Music Performance - Vocals",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "ACM London",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Performance - Vocals (Integrated Masters)",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "MCCI",
                    "duration": "3 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Production",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "18"
                },
                {
                    "course_name": "Music Production",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "BA/BMus (H)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Production",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "ACM London",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Production (Integrated Masters)",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "MCCI",
                    "duration": "3 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Rap & MC",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "18"
                },
                {
                    "course_name": "Rap & MC",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Rap & MC",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "ACM London",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Rap & MC (Integrated Masters)",
                    "university": "ACM (The Academy of Contemporary Music)",
                    "location": "Multiple Locations",
                    "qualification": "MCCI",
                    "duration": "3 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "7135f22a4f0b450a8878458ff1bca343",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Acting",
                    "university": "Arts University Bournemouth",
                    "location": "Main Site - Arts University Bournemouth",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Animation Production",
                    "university": "Arts University Bournemouth",
                    "location": "Main Site - Arts University Bournemouth",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Architecture",
                    "university": "Arts University Bournemouth",
                    "location": "Main Site - Arts University Bournemouth",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Commercial Photography",
                    "university": "Arts University Bournemouth",
                    "location": "Main Site - Arts University Bournemouth",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Communication Design and Strategy",
                    "university": "Arts University Bournemouth",
                    "location": "Main Site - Arts University Bournemouth",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Costume",
                    "university": "Arts University Bournemouth",
                    "location": "Main Site - Arts University Bournemouth",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Creative Direction",
                    "university": "Arts University Bournemouth",
                    "location": "Main Site - Arts University Bournemouth",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Creative Technologies",
                    "university": "Arts University Bournemouth",
                    "location": "Main Site - Arts University Bournemouth",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Arts University Bournemouth",
                    "location": "Main Site - Arts University Bournemouth",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Curation, Exhibition and Experience Design",
                    "university": "Arts University Bournemouth",
                    "location": "Main Site - Arts University Bournemouth",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Design for Costume and Performance",
                    "university": "Arts University Bournemouth",
                    "location": "Main Site - Arts University Bournemouth",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Design for Sustainable Futures",
                    "university": "Arts University Bournemouth",
                    "location": "Main Site - Arts University Bournemouth",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Events Management",
                    "university": "Arts University Bournemouth",
                    "location": "Main Site - Arts University Bournemouth",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Fashion",
                    "university": "Arts University Bournemouth",
                    "location": "Main Site - Arts University Bournemouth",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Fashion Communication",
                    "university": "Arts University Bournemouth",
                    "location": "Main Site - Arts University Bournemouth",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Film Production",
                    "university": "Arts University Bournemouth",
                    "location": "Main Site - Arts University Bournemouth",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Arts University Bournemouth",
                    "location": "Main Site - Arts University Bournemouth",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Games Art and Design",
                    "university": "Arts University Bournemouth",
                    "location": "Main Site - Arts University Bournemouth",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Arts University Bournemouth",
                    "location": "Main Site - Arts University Bournemouth",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Illustration",
                    "university": "Arts University Bournemouth",
                    "location": "Main Site - Arts University Bournemouth",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Interior Architecture and Design",
                    "university": "Arts University Bournemouth",
                    "location": "Main Site - Arts University Bournemouth",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Interior Architecture and Design for Health and Wellbeing",
                    "university": "Arts University Bournemouth",
                    "location": "Main Site - Arts University Bournemouth",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Make-Up for Media and Performance",
                    "university": "Arts University Bournemouth",
                    "location": "Main Site - Arts University Bournemouth",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Modelmaking",
                    "university": "Arts University Bournemouth",
                    "location": "Main Site - Arts University Bournemouth",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Photography",
                    "university": "Arts University Bournemouth",
                    "location": "Main Site - Arts University Bournemouth",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Textiles Design",
                    "university": "Arts University Bournemouth",
                    "location": "Main Site - Arts University Bournemouth",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "a80493401e6d7f9fec44c1f81cab199c",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Musical Theatre (Professional Performance) 2-Year Accelerated Degree",
                    "university": "Associated Studios Performing Arts Academy",
                    "location": "Associated Studios",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Musical Theatre Performance",
                    "university": "Associated Studios Performing Arts Academy",
                    "location": "Associated Studios",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        }
    },
    {
        "id": "c877da2fbea6072c8836d1b1b640719e",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "804bcaeaea1c9d2f6f6f3760aad60a14",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "Bloomsbury Institute London",
                    "location": "Bloomsbury Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Accounting and Finance (Accelerated)",
                    "university": "Bloomsbury Institute London",
                    "location": "Bloomsbury Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Accounting and Finance (Top-up)",
                    "university": "Bloomsbury Institute London",
                    "location": "Bloomsbury Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "Bloomsbury Institute London",
                    "location": "Bloomsbury Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Business Management (Accelerated)",
                    "university": "Bloomsbury Institute London",
                    "location": "Bloomsbury Campus",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Business Management (Top-up)",
                    "university": "Bloomsbury Institute London",
                    "location": "Bloomsbury Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Legal Practice",
                    "university": "Bloomsbury Institute London",
                    "location": "Bloomsbury Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Law and Legal Practice (Accelerated)",
                    "university": "Bloomsbury Institute London",
                    "location": "Bloomsbury Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Law and Legal Practice (Top-up)",
                    "university": "Bloomsbury Institute London",
                    "location": "Bloomsbury Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "Bloomsbury Institute London",
                    "location": "Bloomsbury Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Wealth Management",
                    "university": "Bloomsbury Institute London",
                    "location": "Bloomsbury Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "Bloomsbury Institute London",
                    "location": "Bloomsbury Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration",
                    "university": "Bloomsbury Institute London",
                    "location": "Bloomsbury Campus",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        }
    },
    {
        "id": "1e2f8a56122abfd4dcc8e11838eace13",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Accounting and Finance (with a placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Animation",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Animation (with a placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Archaeology (with a placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Architectural Engineering",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Architectural Engineering",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BEng",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Architectural Engineering (with a placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Architectural Engineering (with a placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Architectural Technology",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Architectural Technology (with a placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biomedical Engineering (including a placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biomedical Engineering (with a placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biomedical Engineering (with Integrated  Foundation Year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Biomedical Engineering (with Integrated Foundation Year & Placement Year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business and Management",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business and Management (with a placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Studies and Law",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Studies and Law (with a placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Chemical Engineering (with a placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Chemical Engineering (with a placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Chemical Engineering (with Integrated Foundation Year & Placement Year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Chemical Engineering (with Integrated Foundation Year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Chemistry (with a placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Civil and Structural Engineering",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Civil and Structural Engineering",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Civil and Structural Engineering (with a placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Civil and Structural Engineering (with Integrated Foundation Year and Placement Year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Civil and Structural Engineering (with Integrated Foundation Year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Civil and Structural Engineering (with placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Clinical Sciences",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Clinical Sciences (with placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Clinical Technology",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Clinical Technology (with a placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science (with a placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science (with Integrated Foundation Year and Placement Year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Computer Science BSc (with Integrated Foundation Year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Computer Science for Artificial Intelligence",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science for Artificial Intelligence (with a placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science for Artificial Intelligence (with Integrated Foundation Year and Placement Year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Computer Science for Artificial Intelligence (with Integrated Foundation Year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Computer Science for Cyber Security",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science for Cyber Security (with a placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science for Cyber Security (with Integrated Foundation Year and Placement Year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Computer Science for Cyber Security (with Integrated Foundation Year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Diagnostic Radiography",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Economics (with a placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Film and Television Production",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Film and Television Production (with a placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Finance and Business Analytics",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Finance and Business Analytics (with a placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Finance and Economics",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Finance and Economics (with a placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Forensic & Medical Sciences",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Forensic & Medical Sciences (with a placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Forensic Anthropology",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Forensic Anthropology (with a placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Forensic Science (with a placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Foundation in Clinical Sciences and Medicine leading to BSc Clinical Sciences",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Foundation in Clinical Sciences and Medicine leading to BSc Clinical Sciences (with placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Foundation Year",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Foundation Year in Engineering",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Game Design and Development",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Game Design and Development (with placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Healthcare Science (Life Sciences)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Heritage and Archaeology",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Heritage and Archaeology (with placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Business and Management",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Business and Management (with a placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Relations, Politics and Security Studies",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Law",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Law (with placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Law with Business and Management",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Law with Business and Management (with placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Management and Business Analytics",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Management and Business Analytics (with Placement Year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Marketing (with Placement Year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Master of Optometry (MOptom)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MOptom",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mechanical Engineering (with Integrated Foundation Year and Placement Year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Mechanical Engineering (with Integrated Foundation Year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Mechanical Engineering (with Placement Year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mechanical Engineering (with Placement Year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Midwifery",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Midwifery (Postgraduate Entry)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MLaw Legal Theory & Solicitors Practice",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MLaw",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "MLaw Legal Theory & Solicitors Practice (with placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MLaw",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "MNurse (Adult/Mental Health)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MNurs",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "MNurse (Child/Mental Health)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MNurs",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "MPhysiotherapy (Sport and Exercise Medicine)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing (Adult) (Harrogate and District NHS Trust)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing (Adult) (Mid Yorkshire Hospitals)",
                    "university": "University of Bradford",
                    "location": "Dewsbury and District Hospital",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing (Children's)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Paramedic Science",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Pharmaceutical and Cosmetic Science",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Pharmacy",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MPharm (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Pharmacy (including pre-registration training)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MPharm (H)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology (with placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology and Criminology",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology with Counselling",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology with Counselling (with placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Public Health and Community Wellbeing",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Social Work MA (Postgraduate Entry)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Software Engineering (with Integrated Foundation Year and Placement Year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Software Engineering (with Integrated Foundation Year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Software Engineering (with placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sustainable Process Engineering",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sustainable Process Engineering",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sustainable Process Engineering",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sustainable Process Engineering",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sustainable Process Engineering (with Integrated Foundation Year & Placement Year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sustainable Process Engineering (with Integrated Foundation Year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Working with Children, Young People and Families",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Working with Children, Young People and Families (with placement year)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Advanced Biomedical Engineering",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Chemical and Petroleum Engineering",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Civil and Structural Engineering",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Mechanical Engineering",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Analytical Sciences",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Artificial Intelligence and Data Analytics",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Computer Science and Artificial Intelligence",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeological Sciences",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and Identity",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence and Machine Learning",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Big Data Science and Technology",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioinformatics MSc",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer Drug Discovery",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer Pharmacology",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction and Project Management MSc",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital and Strategic Marketing",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Civil Engineering",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drug Toxicology and Safety Pharmacology",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance for Development",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Management",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "European and International Business Management",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Filmmaking",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Investment",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance, Accounting and Management",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Technology (FinTech)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Archaeology and Crime Scene Investigation",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Bioarchaeology and Palaeopathology",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management (CIPD Accreditation)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Accounting and Finance",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Banking and Financial Technology Law",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Management",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Corporate Law and Governance",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development Management",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Rights Law and Development",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Legal Studies",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Security Studies",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Landscape Archaeology and Digital Heritage MSc",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership in Health & Social Care (International)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Logistics, Data Analytics and Supply Chain Management",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management MSc",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing MSc",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master by Research (MRes)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Chemistry MSc",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Bioscience MSc",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Resources and Environmental Law and Policy",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing Studies (International) MSc",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Peace, Conflict and Development",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Peace, Resilience and Social Justice",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Peacebuilding and Conflict Resolution",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Technology and Medicines Control MSc",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiology and Molecular Biology",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Planning and Management",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology of Health and Wellbeing",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health MPH",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MPH",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Renewable and Sustainable Energy",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Satellite Systems Engineering MSc",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Skin Science and Stem Cell Biology MSc",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Sciences Research for Healthcare MSc",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Development",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Technology and Artificial Intelligence Law",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Analytical Sciences",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Artificial Intelligence and Data Analytics",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Computer Science and Artificial Intelligence",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeological Sciences",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and Identity",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence and Machine Learning",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Big Data Science and Technology",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital and Strategic Marketing",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Civil Engineering",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance for Development",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Filmmaking",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Investment",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance, Accounting and Management",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Archaeology and Crime Scene Investigation",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Bioarchaeology and Palaeopathology",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management (CIPD Accreditation)",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Accounting and Finance",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Banking and Financial Technology Law",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Management",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Corporate Law and Governance",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development Management",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Rights Law and Development",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Legal Studies",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Security Studies",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Landscape Archaeology and Digital Heritage MSc",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Logistics, Data Analytics and Supply Chain Management",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management MSc",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing MSc",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA (Executive MBA in Dubai)",
                    "university": "University of Bradford",
                    "location": "University of Bradford Middle East Regional Office",
                    "qualification": "MBA (Executive)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Resources and Environmental Law and Policy",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Peace, Conflict and Development",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Peace, Resilience and Social Justice",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Peacebuilding and Conflict Resolution",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Planning and Management",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Sciences Research for Healthcare MSc",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Development",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Technology and Artificial Intelligence Law",
                    "university": "University of Bradford",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "dc1cf1edfa8e466a41d07c89dd9e54b9",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "3D Design and Craft",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Accounting, Finance and Economics (with Integrated Foundation Year)",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Aerospace Engineering (Top-Up)",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering (with Integrated Foundation Year)",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "72-96"
                },
                {
                    "course_name": "Animation",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Architectural Technology",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-136"
                },
                {
                    "course_name": "Architecture (with Integrated Foundation Year)",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Art History and Visual Culture",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Automotive Engineering",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Automotive Engineering",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Automotive Engineering (Top-Up)",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive Engineering (with Integrated Foundation Year)",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-96"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Biological Sciences with Integrated Foundation Year",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Biomedical Science with Integrated Foundation Year",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Building Surveying",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Business Management (Top-Up)",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (with Integrated Foundation Year)",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Management with Economics",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Business Management with Entrepreneurship",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Business Management with Events",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Business Management with Finance",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Business Management with Human Resource Management",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Business Management with Law",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Business Management with Marketing",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Business Management with Tourism",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Civil Engineering (with Integrated Foundation Year)",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "72-80"
                },
                {
                    "course_name": "Civil Engineering (with Integrated Foundation Year)",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-80"
                },
                {
                    "course_name": "Civil Engineering with Construction Management",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Civil Engineering with Construction Management",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Civil with Environmental Engineering",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Civil with Environmental Engineering",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Computer Science for Games",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Computer Science with Artificial Intelligence",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Computer Science with Cyber Security",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Contemporary History",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Content Creation",
                    "university": "University of Brighton",
                    "location": "Main site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Creative Industries",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Design Engineering",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Design Engineering",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Design Engineering with Integrated Foundation Year",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Diagnostic Radiography",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Digital Games Development",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Early Childhood Education and Care",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Ecology and Conservation",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Ecology and Conservation",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Ecology and Conservation with Integrated Foundation Year",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Education",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Electrical and Electronic Engineering (with Integrated Foundation Year)",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-96"
                },
                {
                    "course_name": "Electronic and Communication Engineering",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Electronic and Communication Engineering",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Electronic and Communication Engineering (with Integrated Foundation Year)",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "72-96"
                },
                {
                    "course_name": "Electronic Engineering (Top-Up)",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Music and Sound",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "English Language",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-104"
                },
                {
                    "course_name": "English Language and Creative Writing",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "English Language and English Literature",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "English Language and Linguistics",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "English Literature and Creative Writing",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Environmental Management",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Environmental Sciences",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Environmental Sciences with IFY",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Exercise, Health and Rehabilitation Science",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fashion and Design History",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fashion Communication with Business Studies",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fashion Design with Business Studies",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Film",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Finance and Investment",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fine Art Painting",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fine Art Printmaking",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Games Art & Design",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-115"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Geography with IFY",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Globalisation: History, Politics and Culture",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Illustration",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Interior Architecture",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Interior Architecture (with Integrated Foundation Year)",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "International Business Management",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Journalism",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Law",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Law (Canadian Law)",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Law (with Integrated Foundation Year)",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Law with Business",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Linguistics",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Marketing Management",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Mechanical and Manufacturing Engineering (Top-Up)",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Mechanical Engineering (with Integrated Foundation Year)",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "72-96"
                },
                {
                    "course_name": "Media Production",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Media Studies",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Midwifery",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Music Business and Media",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing (Child)",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Paramedic Science",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "MPharm (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Pharmacy with Preparatory Year",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "MPharm (H)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Philosophy, Politics, Ethics",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Photography",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Physical Education with QTS (Secondary)",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Physician Associate Studies",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Podiatry",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Politics",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-113"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Politics and Social Change",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Politics, Sexuality and Gender",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Primary Education 3-7 with QTS",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Education 5-11 with QTS",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Product Design (with Integrated Foundation Year)",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Psychology and Criminology",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Psychology and Sociology",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Psychology with Counselling Studies",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Quantity Surveying",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Robotics and Mechatronics Engineering",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Robotics and Mechatronics Engineering",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Robotics and Mechatronics Engineering with Integrated Foundation Year",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BEng",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Social Science",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Social Work (Postgraduate Entry)",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Sport (with Integrated Foundation Year)",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Sport Coaching",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Sport Management",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Sports Journalism",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Strength and Conditioning",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Textiles Design with Business Studies",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting (ACCA)",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Occupational Therapy",
                    "university": "University of Brighton",
                    "location": "Falmer Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Physiotherapy",
                    "university": "University of Brighton",
                    "location": "Falmer Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Podiatry",
                    "university": "University of Brighton",
                    "location": "Falmer Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Exercise Physiology",
                    "university": "University of Brighton",
                    "location": "Falmer Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Sport Physiology",
                    "university": "University of Brighton",
                    "location": "Falmer Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Approved Mental Health Practice PGDip",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "PgDip",
                    "duration": "6 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aquatic Environments MRes",
                    "university": "University of Brighton",
                    "location": "No Locations",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural and Urban Design",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture (RIBA part 2)",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MArch",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Design (Secondary) PGCE",
                    "university": "University of Brighton",
                    "location": "Falmer Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Atmospheric Science MRes",
                    "university": "University of Brighton",
                    "location": "No Locations",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology (Secondary) PGCE",
                    "university": "University of Brighton",
                    "location": "Falmer, Brighton",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry (Secondary) PGCE",
                    "university": "University of Brighton",
                    "location": "Falmer, Brighton",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Community Psychology",
                    "university": "University of Brighton",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Management",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Brighton",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Curating Collections and Heritage",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Analytics",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diagnostic Radiography (pre-registration) MSc",
                    "university": "University of Brighton",
                    "location": "Falmer Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Construction",
                    "university": "University of Brighton",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earthquake and Structural Engineering",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecology and Conservation",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy and Environmental Performance of Buildings",
                    "university": "University of Brighton",
                    "location": "No Locations",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Management",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English (Secondary) PGCE",
                    "university": "University of Brighton",
                    "location": "Falmer, Brighton",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Assessment and Management",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Politics MRes",
                    "university": "University of Brighton",
                    "location": "No Locations",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Accounting",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Banking",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Investment",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Risk Management",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Further Education and Skills PGCE",
                    "university": "University of Brighton",
                    "location": "Falmer, Brighton",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geoarchaeology MRes",
                    "university": "University of Brighton",
                    "location": "Multiple Locations",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geographical Information Systems and Environmental Management",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography (Secondary) PGCE",
                    "university": "University of Brighton",
                    "location": "Falmer, Brighton",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geoscience MRes",
                    "university": "University of Brighton",
                    "location": "Multiple Locations",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "GIS and Remote Sensing MRes",
                    "university": "University of Brighton",
                    "location": "No Locations",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Globalisation (Politics, Conflict and Human Rights)",
                    "university": "University of Brighton",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health",
                    "university": "University of Brighton",
                    "location": "Falmer Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Education",
                    "university": "University of Brighton",
                    "location": "Falmer Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Management",
                    "university": "University of Brighton",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Promotion",
                    "university": "University of Brighton",
                    "location": "Falmer Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Research",
                    "university": "University of Brighton",
                    "location": "Falmer Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History (Secondary) PGCE",
                    "university": "University of Brighton",
                    "location": "Falmer Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Design and Material Culture",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Design",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MA",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law and Social Justice",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "PgCert",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism",
                    "university": "University of Brighton",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law Conversion",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Logistics and Supply Chain Management",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Entrepreneurship)",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Human Resources)",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing (Branding and Communication)",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing (Digital Marketing)",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing (International Marketing)",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics (Secondary) PGCE",
                    "university": "University of Brighton",
                    "location": "Falmer, Brighton",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Therapy (Pre-registration)",
                    "university": "University of Brighton",
                    "location": "Falmer Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Sciences",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy (OSPAP)",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Photography",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Education (Secondary) PGCE",
                    "university": "University of Brighton",
                    "location": "Falmer, Brighton",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics (Secondary) PGCE",
                    "university": "University of Brighton",
                    "location": "Falmer, Brighton",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Mathematics (Secondary) PGCE",
                    "university": "University of Brighton",
                    "location": "Falmer, Brighton",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy (Pre-registration)",
                    "university": "University of Brighton",
                    "location": "Falmer Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Podiatry (Pre-registration)",
                    "university": "University of Brighton",
                    "location": "Falmer Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Precision Medicine Cancer MSc",
                    "university": "University of Brighton",
                    "location": "No Locations",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary 3-7 years PGCE",
                    "university": "University of Brighton",
                    "location": "Falmer, Brighton",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary 5-11 years PGCE",
                    "university": "University of Brighton",
                    "location": "Falmer, Brighton",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Studies in Accounting (ACCA)",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "Diploma",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management for Construction",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Regenerative Medicine and Devices",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Riverine and Estuarine Processes MRes",
                    "university": "University of Brighton",
                    "location": "No Locations",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sequential Design and Illustration",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work MSc",
                    "university": "University of Brighton",
                    "location": "Multiple Locations",
                    "qualification": "PgDip",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Practitioner MSc PGDip",
                    "university": "University of Brighton",
                    "location": "Falmer, Brighton",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Journalism",
                    "university": "University of Brighton",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strength and Conditioning",
                    "university": "University of Brighton",
                    "location": "Falmer Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Stress, Ageing and Chronic Disease",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Design",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "The Brighton MBA",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Town Planning",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "User Experience Design",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Water and Environmental Management",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Accounting (ACCA)",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice",
                    "university": "University of Brighton",
                    "location": "Falmer, Brighton",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Occupational Therapy",
                    "university": "University of Brighton",
                    "location": "Falmer Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Physiotherapy",
                    "university": "University of Brighton",
                    "location": "Falmer Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Podiatry",
                    "university": "University of Brighton",
                    "location": "Falmer Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Exercise Physiology",
                    "university": "University of Brighton",
                    "location": "Falmer Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Sport Physiology",
                    "university": "University of Brighton",
                    "location": "Falmer Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Approved Mental Health Practice PGDip",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aquatic Environments MRes",
                    "university": "University of Brighton",
                    "location": "No Locations",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural and Urban Design",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture (RIBA part 2)",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MArch",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Atmospheric Science MRes",
                    "university": "University of Brighton",
                    "location": "No Locations",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Practice",
                    "university": "University of Brighton",
                    "location": "Falmer, Brighton",
                    "qualification": "Graduate Certificate",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Community Psychology",
                    "university": "University of Brighton",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Management",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Brighton",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Curating Collections and Heritage",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Analytics",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Construction",
                    "university": "University of Brighton",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Years Education PGCert with Early Years Teacher Status",
                    "university": "University of Brighton",
                    "location": "Falmer, Brighton",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earthquake and Structural Engineering",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Brighton",
                    "location": "Falmer, Brighton",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy and Environmental Performance of Buildings",
                    "university": "University of Brighton",
                    "location": "No Locations",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Management",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Assessment and Management",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Politics MRes",
                    "university": "University of Brighton",
                    "location": "No Locations",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geoarchaeology MRes",
                    "university": "University of Brighton",
                    "location": "Multiple Locations",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geographical Information Systems and Environmental Management",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geoscience MRes",
                    "university": "University of Brighton",
                    "location": "Multiple Locations",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "GIS and Remote Sensing MRes",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Globalisation (Politics, Conflict and Human Rights)",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health",
                    "university": "University of Brighton",
                    "location": "Falmer Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Education",
                    "university": "University of Brighton",
                    "location": "Falmer Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Management",
                    "university": "University of Brighton",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Promotion",
                    "university": "University of Brighton",
                    "location": "Falmer Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Research",
                    "university": "University of Brighton",
                    "location": "Falmer Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Design and Material Culture",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Humanistic Counselling and Psychotherapy",
                    "university": "University of Brighton",
                    "location": "Multiple Locations",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Design",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MA",
                    "duration": "30 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law and Social Justice",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism",
                    "university": "University of Brighton",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law Conversion",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MBA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leading Practice Education PGCert",
                    "university": "University of Brighton",
                    "location": "Falmer Campus",
                    "qualification": "PgCert",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Logistics and Supply Chain Management",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management, Practice and Law in Architecture",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing (Branding and Communication)",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing (Digital Marketing)",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing (International Marketing)",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Photography",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Precision Medicine Cancer MSc",
                    "university": "University of Brighton",
                    "location": "No Locations",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Social Work Practice",
                    "university": "University of Brighton",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Studies in Accounting (ACCA)",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "Diploma",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management for Construction",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychodynamic Counselling and Psychotherapy",
                    "university": "University of Brighton",
                    "location": "Multiple Locations",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Regenerative Medicine and Devices",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Riverine and Estuarine Processes MRes",
                    "university": "University of Brighton",
                    "location": "Multiple Locations",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sequential Design and Illustration",
                    "university": "University of Brighton",
                    "location": "Brighton",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Practitioner MSc PGDip",
                    "university": "University of Brighton",
                    "location": "Falmer, Brighton",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Journalism",
                    "university": "University of Brighton",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strength and Conditioning",
                    "university": "University of Brighton",
                    "location": "Falmer Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Design",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "The Brighton MBA",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Town Planning",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "User Experience Design",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Water and Environmental Management",
                    "university": "University of Brighton",
                    "location": "Moulsecoomb Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "97084b4de217983160c0b43149dcdadb",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "8ba0c6d7b65f9efa7d8dfa67a788081c",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accountancy",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accountancy with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Business Management BSc",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Business Management BSc with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and Sociology",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and Sociology with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and Sociology with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive Engineering",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive Engineering",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive Engineering with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive Engineering with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Banking and Finance",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Banking and Finance with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences (Biochemistry)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences (Biochemistry) with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences (Genetics)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences (Genetics) with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences (Human Health)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences (Human Health) with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences (Immunology)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences (Immunology) with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "BSc Computer Science (Cybersecurity)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "BSc Computer Science (Cybersecurity)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Computing",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Computing (eBusiness)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Computing (eBusiness) with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Computing (Human-Computer Interaction)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Computing (Human-Computer Interaction) with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Computing (Social Media)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Computing (Social Media) with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Computing with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Entrepreneurship",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Entrepreneurship with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering (Environmental Engineering)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering (Environmental Engineering)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering (Environmental Engineering) with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering (Environmental Engineering) with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering (Flood and Coastal Engineering)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering (Flood and Coastal Engineering)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering (Flood and Coastal Engineering) with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering (Flood and Coastal Engineering) with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Artificial Intelligence)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Artificial Intelligence) with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Digital Media and Games)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Digital Media and Games) with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Network Computing)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Network Computing) with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Software Engineering)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Software Engineering) with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Systems Engineering",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Systems Engineering with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "MDes",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "MDes",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Design",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Design with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Accounting",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Accounting with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Business Finance",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Business Finance with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Management",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Management with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Mathematics with an Integrated Foundation Year",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Mathematics with an Integrated Foundation Year with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic & Electrical Engineering (Artificial Intelligence)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic & Electrical Engineering (Artificial Intelligence) with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic & Electrical Engineering (Communication Systems)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic & Electrical Engineering (Communication Systems) with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic & Electrical Engineering (Computer Systems)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic & Electrical Engineering (Computer Systems) with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic & Electrical Engineering (Energy Systems)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic & Electrical Engineering (Energy Systems) with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering (Top-up)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering with an Integrated Foundation Year",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering with an Integrated Foundation Year with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English with Creative Writing",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English with Creative Writing with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Studies",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Studies and English",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Studies and English with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Studies with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Production",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Production and Theatre",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Production and Theatre with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Production with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Accounting",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Accounting with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Mathematics",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "MMath",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Mathematics",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Mathematics with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Mathematics with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "MMath",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Design",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Design and Creative Writing",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Challenges (Global Innovation)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BASc (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Challenges (Global Innovation) with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BASc (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Challenges (Planetary Health)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BASc (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Challenges (Planetary Health) with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BASc (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Challenges (Security)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BASc (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Challenges (Security) with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BASc (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Challenges (Social Cohesion)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BASc (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Challenges (Social Cohesion) with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BASc (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and International Relations",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and International Relations with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Geography and Anthropology",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Geography and Anthropology with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Geography and Anthropology with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management BSc",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management BSc with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Industrial Design",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Industrial Design",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "MDes",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Industrial Design with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Industrial Design with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "MDes",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Politics",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Politics with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and History",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and History with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism (Communication)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism (Politics)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (Graduate Entry)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (Graduate Entry) with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Criminal Justice",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Criminal Justice with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with International Arbitration & Commercial Law",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with International Arbitration & Commercial Law with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Life Sciences",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Life Sciences with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "MMath",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Computing with an Integrated Foundation Year with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Computing with Integrated Foundation Year",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics for Data Science BSc",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics for Data Science with Placement BSc",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Computer Science",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Computer Science and Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "MMath",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communications",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communications with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communications with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine MBBS",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "MB BS",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-144"
                },
                {
                    "course_name": "Military and International History",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Military and International History with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music (Production)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music (Production) with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Child Health)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physician Associate MSc",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and History",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and History with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Sociology",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Sociology with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design Engineering",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design Engineering",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "MDes",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design Engineering with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design Engineering with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "MDes",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Sport, Health and Exercise)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Sport, Health and Exercise) with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Sport, Health and Exercise) with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Registered Nurse (Adult)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "Master of Science (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology (Digital Media)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology (Digital Media) with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology (Digital Media) with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport, Health and Exercise Sciences",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport, Health and Exercise Sciences",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Sport, Health and Exercise Sciences (Physical Education, Coaching and Social Issues)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport, Health and Exercise Sciences (Physical Education, Coaching and Social Issues)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Sport, Health and Exercise Sciences (Physical Education, Coaching and Social Issues) with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport, Health and Exercise Sciences with Business Studies",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport, Health and Exercise Sciences with Business Studies",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Sport, Health and Exercise Sciences with Business Studies with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport, Health and Exercise Sciences with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and Creative Writing",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and Creative Writing with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and English",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and English with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Effects and Motion Graphics",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Effects and Motion Graphics with Placement",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Creative Writing",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4.5 to 6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4.5 to 6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English with Creative Writing",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4.5 to 6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Studies",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4.5 to 6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Studies and English",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4.5 to 6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Production",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4.5 to 6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Production and Theatre",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4.5 to 6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4.5 to 6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music (Production)",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4.5 to 6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and Creative Writing",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4.5 to 6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and English",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4.5 to 6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Business Intelligence",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "19 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Business Management",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Electronic and Electrical Engineering",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "14 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Engineering Design",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Manufacturing Systems",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Mechanical Engineering",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Professional Practice (APP)",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Professional Practice (Cardiovascular Health)",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Professional Practice (Critical Care)",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Professional Practice (Musculoskeletal Rehabilitation)",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Professional Practice (Neurological Rehabilitation)",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Professional Practice (Pelvic Health)",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology of Childhood, Youth and Education",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology of International Development and Humanitarian Assistance",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art Psychotherapy",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MArts",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence Strategy MSc",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence, Law and Technology",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive and Motorsport Engineering",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Banking and Finance",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Building Services Engineering MSc",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration MBA",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management Integrated",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Finance",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Intelligence and Digital Marketing",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children, Youth and International Development",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Education",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive and Clinical Neuroscience",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "PhD with Integrated Study",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Corporate Brand Management",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "19 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Analytics",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design",
                    "university": "Brunel University London",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design and Branding Strategy",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Integrated",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "PhD with Integrated Study",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Strategy and Innovation",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Design (3D Animation)",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "13 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Design (Immersive Mixed Reality)",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Design (Motion Graphics)",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Design and Branding",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Games Theory and Design",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Service Design",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dramatherapy MA",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics Integrated",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "PhD with Integrated Study",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Integrated",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "PhD with Integrated Study",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electric Vehicle Systems",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Computer Engineering Integrated",
                    "university": "Brunel University London",
                    "location": "No Locations",
                    "qualification": "PhD with Integrated Study",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Management",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Management",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "PgCert",
                    "duration": "4 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Management",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Management",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Sciences",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Accounting",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Investment",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Mathematics",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Flood and Coastal Engineering (with Infrastructure Design)",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Flood and Coastal Engineering (with Project Risk Management)",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global South Asia Studies MSc",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MArts",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Supply Chain Management",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Systems and Computing Integrated",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "PhD with Integrated Study",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrated Product Design",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intellectual Property Law",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "LLM",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intelligence and Security Studies",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Sustainability",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "LLM",
                    "duration": "27 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Financial Regulation and Corporate Law",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Rights Law",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Journalism",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Brunel University London",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law, Policy and Practice",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MA",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical Sciences Integrated",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "PhD with Integrated Study",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering Integrated",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "PhD with Integrated Study",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanisms and Therapy in Cancer",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanisms and Therapy in Infection and Inflammation",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communications",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Anthropology",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Therapy (pre-registration)",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physician Associate",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy (pre-registration)",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Communication",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and History",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project and Infrastructure Management",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological and Psychiatric Anthropology",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological Sciences (Conversion)",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology, Culture and Evolution",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health and Health Promotion",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Relations and Media Strategies",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Renewable Energy Engineering MSc",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Communication",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Public Health Nursing",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Psychology",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport, Health and Exercise Sciences",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics with Data Analytics",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Engineering",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "14 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability, Entrepreneurship and Design",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Electrical Power",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Water and Environmental Engineering",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Wireless and Computer Communication Networks",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Engineering Design",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Professional Practice (APP)",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology of Childhood, Youth and Education",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology of International Development and Humanitarian Assistance",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence, Law and Technology",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Banking and Finance",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration MBA",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management Integrated",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Finance",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children, Youth and International Development",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Education",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive and Clinical Neuroscience",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Analytics",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "MPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design and Branding Strategy",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MA",
                    "duration": "2.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Strategy and Innovation",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MA",
                    "duration": "2.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Design (3D Animation)",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Design (Immersive Mixed Reality)",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Design (Motion Graphics)",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Design and Branding",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Management",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "PgCert",
                    "duration": "16 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "Brunel University London",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Management",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Management",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Sciences",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Flood and Coastal Engineering (with Infrastructure Design)",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Flood and Coastal Engineering (with Project Risk Management)",
                    "university": "Brunel University London",
                    "location": "Multiple Locations",
                    "qualification": "MSc (PG)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global South Asia Studies MSc",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MArts",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intellectual Property Law",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intellectual Property Law",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "LLM",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intelligence and Security Studies",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "LLM",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Financial Regulation and Corporate Law",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "LLM",
                    "duration": "39 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Rights Law",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Journalism",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MA",
                    "duration": "2.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "LLM",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law, Policy and Practice",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MA",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical Sciences Integrated",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "PhD with Integrated Study",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanisms and Therapy in Cancer",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanisms and Therapy in Infection and Inflammation",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communications",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Anthropology",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Musculoskeletal Ultrasound PgCert",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and History",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological and Psychiatric Anthropology",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological Sciences (Conversion)",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology, Culture and Evolution",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health and Health Promotion",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Relations and Media Strategies",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc (PG)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Communication",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Public Health Nursing",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Psychology",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport, Health and Exercise Sciences",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics with Data Analytics",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Electrical Power",
                    "university": "Brunel University London",
                    "location": "Brunel University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "5aee588e3da407453c955f56949e7d6a",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accountancy",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Accountancy\u00a0(with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Accounting and Finance for International Business  (Top-Up)",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Accounting and Finance for International Business (Top-up)",
                    "university": "Coventry University",
                    "location": "Coventry University London",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Accounting and Finance\u00a0(with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Acting",
                    "university": "Coventry University",
                    "location": "CU Scarborough",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Acting",
                    "university": "Coventry University",
                    "location": "CU Scarborough",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Acting",
                    "university": "Coventry University",
                    "location": "CU Scarborough",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Acting for Stage and Screen",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Adult Nursing",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Adult Nursing",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Adult Nursing (Blended Learning)",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Adult Nursing (Blended Learning)",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Adult Nursing with foundation year",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Advertising and Digital Marketing",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Advertising and Digital Marketing (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Aerospace Engineering (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Aerospace Technology",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Aerospace Technology (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Animation",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Applied Biosciences BSc (Hons)",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Applied Biosciences HNC",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Applied Biosciences HND",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Applied Biosciences with Foundation Year",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Applied Mechanical Engineering (Top-Up)",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Psychology",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Applied Psychology",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Applied Psychology",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Applied Psychology with Foundation Year",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Architectural Design and Technology",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "Master in Science (with Honours) \u2013 MSci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Architectural Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Architectural Engineering (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Architectural Technology",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Architectural Technology (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Architecture",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Artificial Intelligence (Top-Up)",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive and Transport Design",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Automotive Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "117-152"
                },
                {
                    "course_name": "Automotive Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Automotive Engineering (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Aviation Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Aviation Management (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Banking and Finance",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Banking and Finance\u00a0(with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Biological and Forensic Sciences",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biological and Forensic Sciences (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Biomedical Science (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Building Surveying",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Building Surveying (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Administration",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BBA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Administration (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BBA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business and Finance",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business and Finance\u00a0(with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business and Human Resource Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business and Human Resources Management\u00a0(with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business and Marketing",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business and Marketing (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Economics",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business Economics\u00a0(with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Enterprise and Innovation Management (Top-up)",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Business Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Business Management & Leadership with Foundation Year",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Business Management (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Management and Leadership",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business Management and Leadership (HNC)",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management and Leadership (HND)",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Childhood, Youth and Education Studies",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Childhood, Youth and Education Studies (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Children and Young People's Nursing",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Children and Young People's Nursing",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Civil and Environmental Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Civil and Environmental Engineering (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Civil Engineering (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Cloud Computing",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Cloud Computing (accelerated)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Cloud Computing with Foundation Year",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Commercial Law",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Commercial Law (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Community Coaching for England (HTQ)",
                    "university": "Coventry University",
                    "location": "CU Scarborough",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Computer Science (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Computer Science with Artificial Intelligence",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Computer Science with Artificial Intelligence",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "Master in Science (with Honours) \u2013 MSci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Computer Science with Artificial Intelligence (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Computer Systems Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Computer Systems Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Computer Systems Engineering (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Computing for England (Cyber Security) (HTQ)",
                    "university": "Coventry University",
                    "location": "CU Scarborough",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Computing Science",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Computing Science",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Computing Science",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Construction Management for England (HTQ)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Construction Project Management (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Criminal Law and Justice",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminal Law and Justice (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Criminology",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Criminology and Law",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology and Law (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Criminology and Psychology",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology and Psychology (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Data Science",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 to 5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Data Science",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Data Science (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Diagnostic Radiography",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Diagnostic Radiography BSc",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Dietetics",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Digital Media",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Disaster and Emergency Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Disaster and Emergency Management (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Early Childhood Development & Learning with Foundation Year",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Early Childhood Development and Learning",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Early Childhood Development and Learning",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Early Childhood Development and Learning",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Economics",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Economics\u00a0(with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Electrical and Electronic Engineering (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Electro-Mechanical Engineering",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Electro-Mechanical Engineering",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "BEng",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Electro-Mechanical Engineering",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Electro-Mechanical Engineering with Foundation Year",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Energy Management",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Energy Management",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Energy Management",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Engineering Business Management (Top-Up)",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Creative Writing",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English and Education Management (Top-Up)",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and TESOL",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English Language and Literature",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Literature",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Enterprise and Entrepreneurship",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Enterprise and Entrepreneurship\u00a0(with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Ethical Hacking and Cyber Security",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Ethical Hacking and Cyber Security",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Ethical Hacking and Cyber Security (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Event Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Event Management\u202f(with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Fashion",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fashion, Brand and Communication",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Film Production",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Finance and Investment",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Finance and Investment (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Financial Economics",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Financial Economics and Banking",
                    "university": "Coventry University",
                    "location": "Coventry University London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Financial Economics\u00a0(with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Financial Management and Accounting",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Financial Management and Accounting",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Financial Management and Accounting",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Financial Management and Accounting with Foundation Year",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Food Science",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Food Science (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Forensic Investigations",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Forensic Investigations (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Forensic Psychology (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Forensic Science (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Games Art",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Games Design and Development",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Games Technology",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Games Technology",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Games Technology (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Geography",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Geography",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Geography and Environmental Hazards",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Global Business (Top-up)",
                    "university": "Coventry University",
                    "location": "Coventry University London",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Global Business Management",
                    "university": "Coventry University",
                    "location": "Coventry University London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Global Business Management (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU London (Greenwich)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Global Events Management",
                    "university": "Coventry University",
                    "location": "Coventry University London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Global Events Management (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU London (Greenwich)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Global Financial Planning",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Global Financial Planning (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Global Marketing",
                    "university": "Coventry University",
                    "location": "Coventry University London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Global Marketing (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU London (Greenwich)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Health & Social Care with Foundation Year",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Health and Social Care BA (Hons)",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Health and Social Care HNC",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Health and Social Care HND",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Healthcare Professions' Support for England (HTQ)",
                    "university": "Coventry University",
                    "location": "CU London (Dagenham)",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "History",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "History and Politics",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Human Biosciences",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Human Biosciences (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Illustration",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Informatics (Top-up)",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Technology Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Information Technology Management (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Interactive Media and Web Technologies",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Interactive Media and Web Technologies (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Interior Architecture and Design",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Business (Top-Up)",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "International Business Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Business Management\u00a0(with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "International Digital Marketing (Top-Up)",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "International Fashion Business",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Fashion Management and Marketing",
                    "university": "Coventry University",
                    "location": "Coventry University London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120"
                },
                {
                    "course_name": "International Fashion Management and Marketing (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU London (Greenwich)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "International Fashion, PR and Communication (Top-up)",
                    "university": "Coventry University",
                    "location": "Coventry University London",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "International Finance and Accounting",
                    "university": "Coventry University",
                    "location": "Coventry University London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "International Finance and Banking (Top-Up)",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "International Hospitality and Tourism Management",
                    "university": "Coventry University",
                    "location": "Coventry University London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "International Hospitality and Tourism Management (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU London (Greenwich)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "International Law",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Law (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "International Marketing (Top-Up)",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "International Relations",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Journalism",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Law",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Law (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Law and Practice",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Law and Practice",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Law and Practice",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Law and Practice",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Law and Practice",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Law and Practice",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Law and Practice with Foundation Year",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Leadership and Management for England (HTQ)",
                    "university": "Coventry University",
                    "location": "CU London (Dagenham)",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Learning Disabilities Nursing",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Learning Disabilities Nursing",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Manufacturing Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Manufacturing Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Manufacturing Engineering (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Marketing",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Marketing (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Marketing and Public Relations",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Marketing and Public Relations (HNC)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Marketing and Public Relations (HND)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Marketing and Public Relations with Foundation Year",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Marketing Management (Top-up)",
                    "university": "Coventry University",
                    "location": "Coventry University London",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Mathematics (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Mathematics and Statistics",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mathematics and Statistics (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mechanical Engineering (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Media (Top-Up)",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communications",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Media Production",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mental Health Nursing",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Mental Health Nursing",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Midwifery",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Midwifery",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "MLaw with Professional Practice",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MLaw",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Motorsport Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Motorsport Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Motorsport Engineering (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Audio Production",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Nursing Associate",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Nursing Associate",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Nutrition and Health",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Nutrition and Health (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Operating Department Practice",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Operating Department Practice",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Paramedic Science",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Paramedic Science",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Pharmacology (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Photography",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Physics and Mathematics",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "117-120"
                },
                {
                    "course_name": "Physics and Mathematics (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Politics",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Politics & International Relations",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Popular Music Performance and Songwriting",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Primary Education & Teaching Studies with Foundation Year",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Primary Education and Teaching Studies BA (Hons)",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Primary Education and Teaching Studies HNC",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Primary Education and Teaching Studies HND",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Product Design",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Professional Accounting",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Professional Accounting (HNC)",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Professional Accounting (HND)",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Professional Accounting with Foundation Year",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Psychology",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Psychology with Counselling",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Public Health",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Public Health",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Public Health (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Public Health and Community Studies",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Public Health and Community Studies (HNC)",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Public Health and Community Studies (HND)",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Public Health and Community Studies with Foundation Year",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Quantity Surveying and Commercial Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Quantity Surveying and Commercial Management\u00a0(with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Real Estate and Property Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Real Estate and Property Management (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Renewable Energy",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Renewable Energy (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Social Work",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Sociology",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sociology and Criminology",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Software Engineering (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sport and Exercise Psychology",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport and Exercise Psychology (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Sport and Exercise Science (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Sport Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport Management\u202f(with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sport Performance and Coaching BSc (Hons)",
                    "university": "Coventry University",
                    "location": "CU Scarborough",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sport Performance and Coaching HNC",
                    "university": "Coventry University",
                    "location": "CU Scarborough",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sport Performance and Coaching HND",
                    "university": "Coventry University",
                    "location": "CU Scarborough",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Sport Performance and Coaching with Foundation Year",
                    "university": "Coventry University",
                    "location": "CU Scarborough",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Sports and Exercise Therapy",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sports and Exercise Therapy (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sports Coaching",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sports Coaching (with foundation year)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Tourism and Hospitality Management",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Tourism and Hospitality Management (HNC)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Tourism and Hospitality Management (HND)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Tourism and Hospitality Management with Foundation Year",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Visual Effects (VFX)",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Accounting and Finance for International Business (Top-up)",
                    "university": "Coventry University",
                    "location": "Coventry University London",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "See entry requirements"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Financial Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Adult Nursing (pre-registration) Blended Learning",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Adult Social Work",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Child and Family Social Work",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Mechanical Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advancing Physiotherapy Practice",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advertising and Marketing",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agroecology, Water and Food Sovereignty",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Air Transport Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Antimicrobial Resistance",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Innovation Leadership",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Psychology",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MArch",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence and Human Factors",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive and Transport Design",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive Journalism",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Brand Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Organisational Psychology",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children and Young People's Nursing (Pre-registration)",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering Project Management",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering/Civil Engineering (Technical Route)",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication, Culture and Media",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Management with BIM",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Project and Cost Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Control, Automation and Artificial Intelligence",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Crowded Places and Public Safety Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Computational Intelligence",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dietetics and Leadership",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing with Data Analytics",
                    "university": "Coventry University",
                    "location": "Coventry University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diplomacy, Law and Global Change",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Disaster Management and Resilience",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Automotive Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Embedded Systems Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Emergency Management and Resilience",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Project Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Education Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language Teaching and Applied Linguistics",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Events and Experience Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Media Production",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "FinTech",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology and Mental Health",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Studio Development",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Business",
                    "university": "Coventry University",
                    "location": "Coventry University London",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Finance",
                    "university": "Coventry University",
                    "location": "Coventry University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Financial Trading",
                    "university": "Coventry University",
                    "location": "Coventry University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health Care Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Healthcare Management and Leadership",
                    "university": "Coventry University",
                    "location": "Coventry University London",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Journalism and Public Relations",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Pharmaceutical and Biotech Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Illustration and Animation",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Design",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Economics",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Entrepreneurship",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Fashion Management",
                    "university": "Coventry University",
                    "location": "Coventry University London",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Fashion Marketing",
                    "university": "Coventry University",
                    "location": "Coventry University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Resource Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Resource Management",
                    "university": "Coventry University",
                    "location": "Coventry University London",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Marketing Management",
                    "university": "Coventry University",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Project Management",
                    "university": "Coventry University",
                    "location": "Coventry University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management of Information Systems and Technology",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular Biology",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Musculoskeletal Physiotherapy",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Production",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Therapy (pre-registration)",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oil and Gas Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oil and Gas Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology and Drug Discovery",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Photography",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy and Leadership (pre-registration)",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Popular Music",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design Innovation",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Production Engineering and Operations Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Accounting",
                    "university": "Coventry University",
                    "location": "Coventry University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health Nutrition",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Renewable Energy Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Renewable Energy Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Social Research",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Development",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Psychology",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strength and Conditioning",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Supply Chain Management and Logistics",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability and Environmental Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Terrorism, International Crime and Global Security",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Virtual and Augmented Reality",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Accounting and Financial Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Adult Social Work",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Child and Family Social Work",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Mechanical Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advertising and Marketing",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agroecology, Water and Food Sovereignty",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Air Transport Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Brand Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Organisational Psychology",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering Project Management",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering/Civil Engineering (Technical Route)",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication, Culture and Media",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Management with BIM",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Project and Cost Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Control, Automation and Artificial Intelligence",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Critical Care Nursing",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Crowded Places and Public Safety Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Computational Intelligence",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing with Data Analytics",
                    "university": "Coventry University",
                    "location": "Coventry University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diplomacy, Law and Global Change",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Disaster Management and Resilience",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Automotive Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Embedded Systems Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Emergency Management and Resilience",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Project Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Education Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language Teaching and Applied Linguistics",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Media Production",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "FinTech",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Finance",
                    "university": "Coventry University",
                    "location": "Coventry University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Financial Trading",
                    "university": "Coventry University",
                    "location": "Coventry University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Healthcare Management and Leadership",
                    "university": "Coventry University",
                    "location": "Coventry University London",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Pharmaceutical and Biotech Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Illustration and Animation",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Design",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Economics",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Fashion Management",
                    "university": "Coventry University",
                    "location": "Coventry University London",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Fashion Marketing",
                    "university": "Coventry University",
                    "location": "Coventry University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Resource Management",
                    "university": "Coventry University",
                    "location": "Coventry University London",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Resource Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Marketing Management",
                    "university": "Coventry University",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Project Management",
                    "university": "Coventry University",
                    "location": "Coventry University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership and Management",
                    "university": "Coventry University",
                    "location": "Multiple Locations",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Advanced Standing)",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "MA",
                    "duration": "6 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management of Information Systems and Technology",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular Biology",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Musculoskeletal First Contact Practitioner",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Musculoskeletal Physiotherapy",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oil and Gas Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oil and Gas Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology and Drug Discovery",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Photography",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "Coventry University",
                    "location": "CU Coventry",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Popular Music",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design Innovation",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Accounting",
                    "university": "Coventry University",
                    "location": "Coventry University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health Nutrition",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Renewable Energy Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Social Research",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Development",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Engineering",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability and Environmental Management",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Terrorism, International Crime and Global Security",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Virtual and Augmented Reality",
                    "university": "Coventry University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "bde62806d7c84f8b539c23c0bb1e48fa",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "cc6244bbd685ed511f450d1426629527",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Accounting and Finance with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Animation",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Animation with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Applied Social Work",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Architectural Technology & Practice with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Architectural Technology and Practice",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Artificial Intelligence and Data Science",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Artificial Intelligence and Data Science with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Artificial Intelligence in Criminology",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Artificial Intelligence in Digital Marketing",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Artificial Intelligence in Healthcare",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Artificial Intelligence in Human Resources",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Artificial Intelligence in Psychology",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Biology",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Biology with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Biomedical Science with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Accounting & Finance",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Accounting & Finance with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Business Management and Accounting",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Business Management and Business Technology",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Business Management and Economics",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management and English",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business Management and Entrepreneurship",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business Management and Finance",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management and Human Resource Management",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management and Marketing",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management and Sustainable Practice",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Management, Supply Chain and Logistics",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Child and Family Health and Wellbeing",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Child and Family Health and Wellbeing with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Civil Engineering with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Civil Engineering with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Computer Games Modelling and Animation",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Games Modelling and Animation with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Computer Games Programming",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Computer Games Programming with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Computer Science with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Construction Management",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Construction Management with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Counselling and Psychotherapy (Humanistic Approaches)",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Counselling and Psychotherapy (Humanistic Approaches) with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Creative and Professional Writing and Entrepreneurship",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Creative Expressive Arts, Health and Wellbeing",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Expressive Arts, Health and Wellbeing with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Creative Writing and Publishing",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Creative Writing and Publishing with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology and Sociology with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Criminology with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Cyber Security with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Dance and Movement for Wellbeing",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Diagnostic Radiography",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Early Childhood Studies with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Earth Sciences",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Earth Sciences with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Economics and Finance with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Economics with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Education Studies with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Education Studies with optional pathway in SEND or TESOL",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Electrical and Electronic Engineering with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Engineering Management Top-up",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BEng",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "English",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English and Creative and Professional Writing",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "English and Education Studies",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English and History",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "English and Law",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English and Psychology",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "English and Publishing",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Environmental Sustainability",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Environmental Sustainability with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Event Management",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Event Management with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Fashion Design",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Fashion Design and Marketing",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fashion Design and Marketing with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Fashion Design with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Film and High End Television Production with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Film and High-End Television Production",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Finance and Technology",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Fine Art with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Football Journalism",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Football Journalism with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Forensic Psychology with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Forensic Science with Criminology",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Forensic Science with Criminology with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Forensic Science with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Foundation Pathways Programme",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "Fd cert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Affairs and Politics",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Global Affairs and Politics with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Graphic Design with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Health and Social Care with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "History",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "History and Business Management",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "History and Criminology",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "History and Economics",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "History and Education",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "History and Law",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "History and Sociology",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "History with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Human Biology",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Human Biology with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Human Resource Management and Entrepreneurship",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Illustration",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Illustration with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Information Technology",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Information Technology with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Interior Architecture and Venue Design",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Interior Architecture and Venue Design with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Interior Design",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Interior Design with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "International Business Management",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Business Management with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "International Foundation Programme",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "Fd cert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Hospitality Management",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Hospitality Management with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "International Relations and Business Management",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Relations and Economics",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Relations and Law",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Relations and Sociology",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Tourism Management",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "International Tourism Management with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Journalism",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Journalism with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "LLB Law",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "LLB Law with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Manufacturing and Production Engineering (Top Up)",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BEng",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Marketing (Management) (top-up)",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Marketing and Consumer Psychology",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Marketing and Data Insights",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Marketing and Management",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Marketing with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Marketing, PR and Advertising",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Mechanical and Manufacturing Engineering (Top Up)",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BEng",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mechanical Engineering with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Media and Business Management",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Media and Communication",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Media and Communication with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Media and English",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Media and Marketing",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Media Content Creation",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Media Content Creation with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "MEdu (in curriculum enhancement) with Qualified Teacher Status",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "MEd",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Midwifery",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Motorsport Engineering",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Motorsport Engineering with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "MSci Nursing (Mental Health) and Leadership",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Music Production",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Music Production with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "University of Derby",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing (Adult) Direct Entry",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Child)",
                    "university": "University of Derby",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Nursing (Learning Disabilities)",
                    "university": "University of Derby",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "University of Derby",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Nursing Associate",
                    "university": "University of Derby",
                    "location": "Multiple Locations",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Performance Analysis and Coaching Science",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Performance Analysis and Coaching Science with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Photography",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Photography (Commercial)",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Photography (Commercial) with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Photography with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Physical Education, Physical Activity and Sport",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Physical Education, Physical Activity and Sport with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Policing and Investigations",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Popular Music",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Popular Music and Business Management",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Popular Music with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Primary Education with QTS",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BEd (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Professional Policing with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Psychology and Business Management",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Psychology and Criminology",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Psychology and Early Childhood Studies",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Psychology and Education Studies",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Psychology and Sociology",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Psychology and Sport Studies",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Psychology and Theatre",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Psychology with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Public Relations and Journalism",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Public Relations and Journalism with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Publishing and Psychology",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Quantity Surveying & Commercial Management",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Quantity Surveying & Commercial Management with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sociology with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sound, Light & Live Event Engineering with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sound, Light and Live Event Engineering",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Special Educational Needs & Disability with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Special Educational Needs and Disability",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Specialist Sports Journalism",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Specialist Sports Journalism with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport and Exercise Science with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sport Coaching and Coach Development",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport Coaching and Coach Development with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sport Management",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sport Management with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sport Therapy and Rehabilitation",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Sport Therapy and Rehabilitation with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sports Nutrition and Health",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sports Nutrition and Health with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Strength, Conditioning and Rehabilitation",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Strength, Conditioning and Rehabilitation with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Technical Theatre and Performance Design",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Theatre Arts",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Theatre Arts with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Zoology",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Zoology with Foundation Year",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Media and Communication",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Acoustics",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Sport and Exercise Science",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Theatre and Education",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art Therapy",
                    "university": "University of Derby",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts",
                    "university": "University of Derby",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Audio Engineering",
                    "university": "University of Derby",
                    "location": "Markeaton Street",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Big Data Analytics",
                    "university": "University of Derby",
                    "location": "Markeaton Street",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Building Information Modelling and Project Collaboration",
                    "university": "University of Derby",
                    "location": "Markeaton Street",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Career Guidance and Development",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering and Construction Management",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Exercise Science",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Behavioural Psychotherapy (Adult or Children and Young People)",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Community Specialist Practice",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation Biology",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Control and Instrumentation",
                    "university": "University of Derby",
                    "location": "Markeaton Street",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Investigation",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Justice and Criminology",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "University of Derby",
                    "location": "Markeaton Street",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dance and Choreography",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dance Movement Psychotherapy",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design",
                    "university": "University of Derby",
                    "location": "Markeaton Street",
                    "qualification": "MDes (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diagnostic Radiography (pre-registration)",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dramatherapy",
                    "university": "University of Derby",
                    "location": "Britannia Mill",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (with optional specialist pathways)",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Assessment and Control",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Screen Production",
                    "university": "University of Derby",
                    "location": "Markeaton Street",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Investigation and Digital Intelligence",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "GeoEnergy",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Affairs and Politics",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Operations and Supply Chain Management",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Derby",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Technology",
                    "university": "University of Derby",
                    "location": "Markeaton Street",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrative Counselling and Psychotherapy",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intelligence, Security and Disaster Management",
                    "university": "University of Derby",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Finance",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Human Resource Management",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Marketing",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership in Health and Social Care",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "LLM (including specialist pathways)",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA Creative and Cultural Industries",
                    "university": "University of Derby",
                    "location": "Markeaton Street",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing Management",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA Global",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical and Manufacturing Engineering",
                    "university": "University of Derby",
                    "location": "Markeaton Street",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Ultrasound",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular Medicine",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc International Hospitality and Tourism Management",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Sustainable and Ethical Business Management",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Production",
                    "university": "University of Derby",
                    "location": "Markeaton Street",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Therapy",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Adult) with NMC registration",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Mental Health) with NMC registration",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Therapy (pre-registration)",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Primary with Qualified Teacher Status",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Police Leadership, Strategy and Organisation",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MPH",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public History and Heritage",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Publishing",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Sciences and Humanities",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Derby",
                    "location": "Britannia Mill",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Public Health Nursing (Health Visiting or Occupational Health Nursing or School Nursing)",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Engineering Management",
                    "university": "University of Derby",
                    "location": "Markeaton Street",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Architecture and Healthy Buildings",
                    "university": "University of Derby",
                    "location": "Markeaton Street",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Sport and Exercise Science",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Theatre and Education",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts",
                    "university": "University of Derby",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Audio Engineering",
                    "university": "University of Derby",
                    "location": "Markeaton Street",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Behaviour Change",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Big Data Analytics",
                    "university": "University of Derby",
                    "location": "Markeaton Street",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Building Information Modelling and Project Collaboration",
                    "university": "University of Derby",
                    "location": "Markeaton Street",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Career Guidance and Development",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering and Construction Management",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Exercise Science",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Behavioural Psychotherapy (Adult or Children and Young People)",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Community Specialist Practice",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation Biology",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Control and Instrumentation",
                    "university": "University of Derby",
                    "location": "Markeaton Street",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Investigation",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Justice and Criminology",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dance and Choreography",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design",
                    "university": "University of Derby",
                    "location": "Markeaton Street",
                    "qualification": "MDes (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Doctorate in Business Administration (DBA)",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "DBA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "EdD",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (with optional specialist pathways)",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Assessment and Control",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Investigation and Digital Intelligence",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "GeoEnergy",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Affairs and Politics",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Technology",
                    "university": "University of Derby",
                    "location": "Markeaton Street",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrative Counselling and Psychotherapy",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intelligence, Security and Disaster Management",
                    "university": "University of Derby",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Finance",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Human Resource Management",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Marketing",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership in Health and Social Care",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "LLM (including specialist pathways)",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA Creative and Cultural Industries",
                    "university": "University of Derby",
                    "location": "Markeaton Street",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing Management",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA Global",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical and Manufacturing Engineering",
                    "university": "University of Derby",
                    "location": "Markeaton Street",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Ultrasound",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular Medicine",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc International Hospitality and Tourism Management",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Sustainable and Ethical Business Management",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Production",
                    "university": "University of Derby",
                    "location": "Markeaton Street",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Police Leadership, Strategy and Organisation",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MPH",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public History and Heritage",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Publishing",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Sciences and Humanities",
                    "university": "University of Derby",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Public Health Nursing (Health Visiting or Occupational Health Nursing or School Nursing)",
                    "university": "University of Derby",
                    "location": "Kedleston Road",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Engineering Management",
                    "university": "University of Derby",
                    "location": "Markeaton Street",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable and Ethical Business Management (Block Delivery)",
                    "university": "University of Derby",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Architecture and Healthy Buildings",
                    "university": "University of Derby",
                    "location": "Markeaton Street",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "c03a67ccf7729250b17aa81a730feb91",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Adult Nursing & Social Work",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MNSW",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Animation",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Biology",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Biology with STEM Foundation Year",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-96"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Biomedical Science with STEM Foundation Year",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-96"
                },
                {
                    "course_name": "Biotechnology",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Biotechnology with STEM Foundation Year",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-96"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business and Economics",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business and Finance",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business and Marketing",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business Management",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business Management with Foundation Year",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MChem",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Child & Adolescent Mental Health & Wellbeing",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Childhood & Youth Studies",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Childhood & Youth Studies and Criminology",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Children's Learning and Development",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Children's Nursing & Social Work",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MNSW",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Computer Engineering",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Engineering with STEM Foundation Year",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-96"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Science & Artificial Intelligence",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Science with STEM Foundation Year",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-96"
                },
                {
                    "course_name": "Computing",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Computing (Games Programming)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Computing (Games Programming) with STEM Foundation Year",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-96"
                },
                {
                    "course_name": "Computing (Networks, Cyber Security and Forensics)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Computing (Networks, Cyber Security and Forensics) with STEM Foundation Year",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-96"
                },
                {
                    "course_name": "Computing with STEM Foundation Year",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-96"
                },
                {
                    "course_name": "Counselling and Psychotherapy",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Creative Writing and English Literature",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Criminal Justice",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Criminology",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Criminology and Law",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Criminology and Psychology",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Dance",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Drama",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Ecology and Conservation",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Ecology and Conservation with STEM Foundation Year",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-96"
                },
                {
                    "course_name": "Economics",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Education",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Education and English",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Education and History",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Education and Mathematics",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Education and Religion",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Education and Sociology",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Education and Special Educational Needs",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Education with Foundation Year",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Electrical and Electronic Engineering with STEM Foundation Year",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-96"
                },
                {
                    "course_name": "Electrical and Electronic Engineering with STEM Foundation Year",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-96"
                },
                {
                    "course_name": "Electrical Engineering",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Engineering",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Engineering",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "English",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "English Literature",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "English with Foundation Year",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Film",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Film & Broadcast Production",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Genetics",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Genetics with STEM Foundation Year",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-96"
                },
                {
                    "course_name": "Geography",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Geography",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Geography with Foundation Year",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Geography with STEM Foundation Year",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-96"
                },
                {
                    "course_name": "Geology with Physical Geography",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Geology with Physical Geography with STEM Foundation Year",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-96"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "History",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "History and Politics",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "History with Foundation Year",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Information Technology Management for Business",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Information Technology Management for Business with STEM Foundation Year",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-96"
                },
                {
                    "course_name": "Integrated Health and Social Care",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "International Business",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Law",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Law and Business",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Law with Politics",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Learning Disabilities Nursing & Social Work",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MNSW",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Marketing",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Mathematics with a foundation year",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Mechanical Engineering with STEM Foundation Year",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-96"
                },
                {
                    "course_name": "Mechanical Engineering with STEM Foundation Year",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-96"
                },
                {
                    "course_name": "Media",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Media with Foundation Year",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Medicine",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MB ChB",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine with Foundation Year (6 years)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MB ChB",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health Nursing & Social Work",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MNSW",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Midwifery",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Music Production",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Musical Theatre",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Nurse Paramedic (Adult)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Nursing (Adult and Child)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Nursing (Adult and Learning Disabilities)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Nursing (Adult and Mental Health)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Nursing (Child and Learning Disabilities)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Nursing (Child)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Nursing (Learning Disabilities)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Nursing (Mental Health and Child)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Nursing (Mental Health and Learning Disabilities)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Nursing Associate",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Nutrition & Health",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Operating Department Practice",
                    "university": "Edge Hill University",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Paramedic Practice",
                    "university": "Edge Hill University",
                    "location": "St James\u2019 Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Physical Education & School Sport",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Physical Geography & Geology",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Physical Geography & Geology with STEM Foundation Year",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-96"
                },
                {
                    "course_name": "Physician Associate Studies (Postgraduate)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Physics with STEM Foundation Year",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-96"
                },
                {
                    "course_name": "Plant Science",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Plant Science with STEM Foundation Year",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-96"
                },
                {
                    "course_name": "Politics & International Relations",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Primary Early Years Education with QTS",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Primary Education with QTS",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Psychology",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Psychology with Clinical & Health Psychology",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Psychology with Educational Psychology",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Psychology with Forensic Psychology",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Psychology with Sport and Exercise Psychology",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Robotics & Artificial Intelligence",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Robotics & Artificial Intelligence with STEM Foundation Year",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-96"
                },
                {
                    "course_name": "Secondary English Education with QTS",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Secondary Mathematics Education with QTS",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Secondary Religious Education with QTS",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Social Work",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Sociology",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Sociology with Foundation Year",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Sport & Exercise Science",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Sport, Health and Exercise Sciences",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Sports Business Management",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Sports Coaching",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Sports Therapy",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Working and Teaching in The Early Years",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Chemistry",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MChem",
                    "duration": "5 to 8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Integrated Health and Social Care",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Law and Business",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Psychology with Clinical & Health Psychology",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Psychology with Educational Psychology",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Psychology with Forensic Psychology",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Psychology with Sport and Exercise Psychology",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120-128"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Applied Sport & Exercise Science",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence, Digital and Cyber Law",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Child & Adolescent Mental Health & Wellbeing",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Child and Adolescent Mental Health and Wellbeing",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation Management",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Intelligence & Data Analysis",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science & Artificial Intelligence",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Doctor of Philosophy",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Children and Young People's Mental Health)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Cognitive Science and Learning)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Curriculum Design)",
                    "university": "Edge Hill University",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Early Years)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Evidence Informed Practice)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Leadership)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Special Educational Needs)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Teacher Education)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Media",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Further Education and Skills",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrated Palliative & End of Life Care",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Creative Enterprise",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing Communications and Branding",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Masters by Research",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA Business Administration",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA Business Administration (Finance)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA Business Administration (Human Resource Management)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA Business Administration (Marketing)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "20 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nineteenth-Century Studies",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Child)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Child)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Learning Disabilities)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Learning Disabilities)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Person Centred Experiential Counselling and Psychotherapy",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Person-Centred Experiential Counselling and Psychotherapy",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Primary Early Years Education with QTS",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Primary Education with QTS",
                    "university": "Edge Hill University",
                    "location": "Multiple Locations",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Primary Mathematics Specialist with QTS",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Primary Physical Education Specialist with QTS",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Computing with QTS (11-16)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary English with QTS (11-16)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Geography with QTS (11-16)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary History with QTS (11-16)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Mathematics with QTS (11-16)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Physical Education with QTS (11-16)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Religious Education with QTS (11-16)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Science (Biology) with QTS (11-16)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Science (Chemistry) with QTS (11-16)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Science (Physics) with QTS (11-16)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCert Integrated Palliative & End of Life Care",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Policing and Law Enforcement Investigation",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Postgraduate Research Pre-Sessional",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "No award",
                    "duration": "4 weeks",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Conversion)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychotherapy and Counselling \u2013 Contemporary Creative Approaches",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health Nutrition",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Safeguarding in Sport",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Sciences (Critical Autism Studies)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Sciences (Critical Autism Studies)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PgCert",
                    "duration": "3 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport, Physical Activity and Mental Health",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Therapy",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "SQE/Legal Practice",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Subject Knowledge Enhancement Biology",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "No award",
                    "duration": "16 weeks",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Subject Knowledge Enhancement Chemistry",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "No award",
                    "duration": "20 weeks",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Subject Knowledge Enhancement Computing",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "No award",
                    "duration": "8 weeks",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Subject Knowledge Enhancement English",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "No award",
                    "duration": "8 weeks",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Subject Knowledge Enhancement Mathematics",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "No award",
                    "duration": "8 weeks",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Subject Knowledge Enhancement Mathematics (Primary Mathematics Specialist)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "No award",
                    "duration": "8 weeks",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Subject Knowledge Enhancement Physics",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "No award",
                    "duration": "20 weeks",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Subject Knowledge Enhancement Religious Education",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "No award",
                    "duration": "8 weeks",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Surgical Care Practice",
                    "university": "Edge Hill University",
                    "location": "St James\u2019 Manchester",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Leadership and Management",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "TESOL (Teaching English to Speakers of Other Languages)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Cardiothoracic Care",
                    "university": "Edge Hill University",
                    "location": "Liverpool Heart and Chest Hospital",
                    "qualification": "PgCert",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice (Learning Disability and/or Autism)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice Apprenticeship",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Critical Care",
                    "university": "Edge Hill University",
                    "location": "Liverpool Heart and Chest Hospital",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Sport & Exercise Science",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence, Digital and Cyber Law",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Child & Adolescent Mental Health & Wellbeing",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Child and Adolescent Mental Health and Wellbeing",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Professional Development",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PgDip",
                    "duration": "1.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Professional Development",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Professional Development",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PgCert",
                    "duration": "1.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation Management",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Intelligence & Data Analysis",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science & Artificial Intelligence",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Doctor of Philosophy",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PhD",
                    "duration": "4.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Children and Young People's Mental Health)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Cognitive Science and Learning)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Curriculum Design)",
                    "university": "Edge Hill University",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Dysalculia)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Dyscalculia)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Early Years)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Evidence Informed Practice)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Leadership)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Special Educational Needs)",
                    "university": "Edge Hill University",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Teacher Education)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Mental Health Practitioner for Children and Young People",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PgDip (Initial Qualification)",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Emergency Services Management (DESM)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "Professional Doctorate",
                    "duration": "4.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Media",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Further Education and Skills",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrated Palliative & End of Life Care",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc",
                    "duration": "2.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Creative Enterprise",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership Development",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Learning Disability and/or Autism",
                    "university": "Edge Hill University",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing Communications and Branding",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Masters by Research",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MRes",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA Business Administration",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA Business Administration (Finance)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA Business Administration (Human Resource Management)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA Business Administration (Marketing)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Leadership",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PgCert",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Practice in Adult Learning Disability",
                    "university": "Edge Hill University",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health & Wellbeing Practitioner: Specialist Adult Mental Health",
                    "university": "Edge Hill University",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nineteenth-Century Studies",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Person Centred Experiential Counselling and Psychotherapy",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Person-Centred Experiential Counselling and Psychotherapy",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCert Integrated Palliative & End of Life Care",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PgCert",
                    "duration": "1.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Policing and Law Enforcement Investigation",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Postgraduate Medical Education",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PgCert",
                    "duration": "1.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Doctorate Education",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "EdD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Doctorate Emergency Services Management",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "Professional Doctorate",
                    "duration": "4.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Conversion)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychotherapy and Counselling \u2013 Contemporary Creative Approaches",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health Nutrition",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Safeguarding in Sport",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Senior Wellbeing Practitioner: Children and Young People",
                    "university": "Edge Hill University",
                    "location": "No Locations",
                    "qualification": "PgDip (Initial Qualification)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Sciences (Critical Autism Studies)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Special Educational Needs Coordination",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "No award",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "SpLD (Dyslexia) with AMBDA/ATS",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PgCert",
                    "duration": "1.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport, Physical Activity and Mental Health",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Therapy",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Supervision: Children and Young People's Mental Health & Wellbeing",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Surgery",
                    "university": "Edge Hill University",
                    "location": "Wrightington Hospital",
                    "qualification": "MCh",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Leadership and Management",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching and Learning in Clinical Practice",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PgCert",
                    "duration": "1.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "TESOL (Teaching English to Speakers of Other Languages)",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urology",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urology Practice",
                    "university": "Edge Hill University",
                    "location": "Ormskirk (Main Campus)",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "3d0d4bdd433dc64954f4f6dfe22cdc8b",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accountancy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BAcc (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accountancy with Finance",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BAcc (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accountancy with International Accounting",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BAcc (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accountancy with Languages",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BAcc (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accountancy/Economics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BAcc (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting & Mathematics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Statistics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aeronautical Engineering",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aeronautical Engineering",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Systems",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Systems",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anatomy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History/Archaeology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History/Celtic Civilisation",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History/Celtic Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History/English Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History/French",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History/History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History/Italian",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History/Philosophy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History/Politics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology/Business Economics (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology/Celtic Civilisation",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology/Celtic Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology/Classics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology/Economic & Social History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology/Economic & Social History (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology/Economics (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology/English Language & Linguistics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology/English Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology/Environmental Geoscience",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology/Film & Television Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology/Gaelic",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology/Geography",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology/Geography",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology/German",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology/History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology/History of Art",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology/Latin",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology/Mathematics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology/Music",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology/Politics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology/Politics (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology/Psychology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology/Scottish History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology/Spanish",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology/Theatre Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology/Theology & Religious Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Design (PGDE - Graduates Only)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astronomy and Mathematics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astronomy and Mathematics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astronomy and Physics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astronomy and Physics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology (PGDE - Graduates Only)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business & Management/Mathematics (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business & Management/Music",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business & Management/Philosophy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business & Management/Philosophy (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business & Management/Politics (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business & Management/Psychology (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business & Management/Russian",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business & Management/Social & Public Policy (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business & Management/Spanish",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics/Business & Management (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics/Economic & Social History (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics/Geography (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics/Mathematics (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics/Philosophy (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics/Politics (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics/Psychology (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics/Scottish History (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics/Social & Public Policy (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Education (PGDE - Graduates Only)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Celtic Civilisation/Central & East European Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic Civilisation/Classics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic Civilisation/English Language & Linguistics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic Civilisation/English Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic Civilisation/Geography",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic Civilisation/History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic Civilisation/Italian",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic Civilisation/Mathematics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic Civilisation/Philosophy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic Civilisation/Psychology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic Civilisation/Scottish History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic Civilisation/Scottish Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic Civilisation/Social & Public Policy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic Civilisation/Theology & Religious Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic Studies/Central & East European Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic Studies/Classics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic Studies/English Language & Linguistics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic Studies/English Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic Studies/French",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic Studies/History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic Studies/Mathematics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic Studies/Music",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic Studies/Philosophy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic Studies/Psychology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic Studies/Scottish History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic Studies/Scottish Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic Studies/Theology & Religious Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Central & East European Studies with Quantitative Methods (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Central & East European Studies/Business Economics (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Central & East European Studies/Classics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Central & East European Studies/Digital Media & Information Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Central & East European Studies/Economic and Social History (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Central & East European Studies/Economics (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Central & East European Studies/English Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Central & East European Studies/Geography (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Central & East European Studies/German",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Central & East European Studies/History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Central & East European Studies/History (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Central & East European Studies/History of Art",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Central & East European Studies/Italian",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Central & East European Studies/Philosophy (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Central & East European Studies/Politics (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Central & East European Studies/Psychology (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Central & East European Studies/Scottish History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Central & East European Studies/Scottish Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Central & East European Studies/Social & Public Policy (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Central & East European Studies/Sociology (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Central and East European Studies (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Physics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Physics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Physics with work placement",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry (PGDE - Graduates Only)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry and Mathematics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry and Mathematics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Medicinal Chemistry",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Medicinal Chemistry with Work Placement",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Work Placement",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with Architecture",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with Architecture",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics/Computing",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics/English Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics/Film & Television Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics/French",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics/Geography",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics/History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics/Italian",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics/Mathematics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics/Music",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics/Philosophy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics/Politics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics/Politics (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics/Psychology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics/Russian",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics/Scottish History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics/Social & Public Policy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics/Social & Public Policy (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics/Sociology (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics/Theology & Religious Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Common Law",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Common Law (Graduate Entry)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLB",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Common Law with French Language",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Common Law with German Language",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Common Law with Italian Language",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Common Law with Spanish Language",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Common Law/Business and Management",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Common Law/Economic & Social History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Common Law/English Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Common Law/History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Common Law/Philosophy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Common Law/Politics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Community Development",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature/Central & East European Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature/Classics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature/Economics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature/English Language & Linguistics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature/English Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature/Film and Television Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature/French",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature/German",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature/History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature/History of Art",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature/Italian",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature/Music",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature/Philosophy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature/Russian",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature/Scottish Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature/Theatre Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing (PGDE Graduates Only)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science and Physics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science Faster Route",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science Faster Route",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science/Business & Management",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science/Business & Management (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science/Economic & Social History (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science/English Language & Linguistics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science/English Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science/French",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science/Geography",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science/History of Art",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science/Latin",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science/Mathematics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science/Mathematics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science/Music",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science/Physics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science/Politics (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science/Psychology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science/Statistics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science/Theatre Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Arts and Industries",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dentistry",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BDS",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media & Information Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media & Information Studies/Archaeology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media & Information Studies/Business & Management",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media & Information Studies/Celtic Civilisation",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media & Information Studies/English Language & Linguistics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media & Information Studies/English Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media & Information Studies/Film & Television Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media & Information Studies/French",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media & Information Studies/Geography",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media & Information Studies/History of Art",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media & Information Studies/Latin",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media & Information Studies/Mathematics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media & Information Studies/Music",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media & Information Studies/Philosophy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media & Information Studies/Politics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media & Information Studies/Psychology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media & Information Studies/Social & Public Policy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media & Information Studies/Sociology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media & Information Studies/Spanish",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media & Information Studies/Theatre Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media & Information Studies/Theology & Religious Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic & Social History with Quantitative Methods (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic & Social History/Business & Management (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic & Social History/Celtic Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic & Social History/Economics (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic & Social History/English Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic & Social History/French",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic & Social History/Geography (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic & Social History/German",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic & Social History/History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic & Social History/History (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic & Social History/Mathematics (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic & Social History/Music",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic & Social History/Philosophy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic & Social History/Philosophy (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic & Social History/Politics (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic & Social History/Psychology (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic & Social History/Scottish History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic & Social History/Scottish History (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic & Social History/Social & Public Policy (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic & Social History/Sociology (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic and Social History (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics/Business & Management (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics/English Language & Linguistics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics/English Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics/French",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics/Geography (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics/Greek",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics/History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics/History (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics/Latin",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics/Mathematics (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics/Music",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics/Philosophy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics/Philosophy (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics/Politics (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics/Psychology (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics/Russian",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics/Scottish History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics/Scottish History (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics/Social & Public Policy (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics/Theatre Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics/Theology & Religious Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education with Teacher Qualification Primary (Undergraduate)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MEd",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education with Teacher Qualification Primary including Catholic Teacher\u2019s Certificate (Undergraduate)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MEd",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Software Engineering",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Software Engineering",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Software Engineering",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronics and Electrical Engineering",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronics and Electrical Engineering",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronics with Music",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronics with Music",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English (PGDE - Graduates Only)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language & Linguistics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language & Linguistics/English Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language & Linguistics/French",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language & Linguistics/German",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language & Linguistics/Greek",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language & Linguistics/History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language & Linguistics/Italian",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language & Linguistics/Latin",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language & Linguistics/Mathematics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language & Linguistics/Music",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language & Linguistics/Philosophy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language & Linguistics/Politics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language & Linguistics/Psychology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language & Linguistics/Russian",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language & Linguistics/Scottish History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language & Linguistics/Scottish Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature/Business and Management",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature/Film & Television Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature/French",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature/Gaelic",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature/German",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature/History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature/History of Art",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature/Latin",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature/Mathematics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature/Music",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature/Philosophy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature/Politics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature/Russian",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature/Scottish History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature/Scottish Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature/Sociology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Geoscience",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science and Sustainability",
                    "university": "University of Glasgow",
                    "location": "Dumfries Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Television Studies/French",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Television Studies/German",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Television Studies/History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Television Studies/History of Art",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Television Studies/Latin",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Television Studies/Music",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Television Studies/Philosophy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Television Studies/Politics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Television Studies/Scottish History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Television Studies/Scottish Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Television Studies/Social & Public Policy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Television Studies/Sociology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Television Studies/Spanish",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Television Studies/Theatre Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BFin (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Mathematics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Statistics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French (PGDE - Graduates Only)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French with German (PGDE - Graduates Only)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French with Italian (PGDE - Graduates Only)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French with Spanish (PGDE - Graduates Only)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French/Business and Management",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French/Geography",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French/German",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French/History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French/History of Art",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French/Italian",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French/Latin",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French/Mathematics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French/Music",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French/Politics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French/Psychology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French/Russian",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French/Theatre Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French/Theology & Religious Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gaelic",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gaelic/Business and Management",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gaelic/Celtic Civilisation",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gaelic/Central & East European Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gaelic/Comparative Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gaelic/English Language & Linguistics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gaelic/French",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gaelic/German",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gaelic/History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gaelic/Mathematics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gaelic/Philosophy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gaelic/Psychology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gaelic/Scottish History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gaelic/Social & Public Policy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gateway to Medical Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genetics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography (PGDE Graduates Only)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography/ Mathematics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography/Business and Management (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography/Celtic Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography/German",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography/History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography/History of Art",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography/Music",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography/Philosophy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography/Politics (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography/Scottish History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography/Scottish Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography/Social & Public Policy (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography/Sociology (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography/Theatre Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German/Business and Management",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German/Economics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German/History of Art",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German/Italian",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German/Mathematics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German/Music",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German/Philosophy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German/Politics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German/Psychology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German/Russian",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German/Theatre Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German/Theology & Religious Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Greek",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Greek/History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Greek/History of Art",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Greek/Latin",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Greek/Politics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Greek/Social & Public Policy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Greek/Spanish",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Greek/Theatre Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Greek/Theology & Religious Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History (Medieval/Modern or Medieval/Modern/Scottish)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History (PGDE - Graduates Only)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art/Business and Management",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art/Italian",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art/Latin",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art/Mathematics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art/Music",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art/Philosophy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art/Politics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art/Psychology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art/Russian",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art/Scottish History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art/Scottish Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art/Social & Public Policy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art/Spanish",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art/Theatre Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art/Theology & Religious Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History/Business & Management",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History/Business and Management (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History/History of Art",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History/Italian",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History/Latin",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History/Mathematics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History/Music",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History/Philosophy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History/Politics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History/Politics (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History/Psychology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History/Russian",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History/Scottish Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History/Sociology (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History/Theatre Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History/Theology & Religious Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Biology & Physiology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Immunology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations with Quantitative Methods (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations/Central & East European Studies (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations/Economic & Social History (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations/Social & Public Policy (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations/Sociology (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian/Business & Management",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian/Latin",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian/Mathematics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian/Music",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian/Philosophy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian/Theatre Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian/Theology & Religious Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Latin",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Latin/Business & Management",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Latin/Geography",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Latin/Mathematics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Latin/Music",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Latin/Scottish Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Latin/Social & Public Policy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Latin/Spanish",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Latin/Theology & Religious Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA (Hons) Global Sustainable Development",
                    "university": "University of Glasgow",
                    "location": "Dumfries Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine & Freshwater Biology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Design and Technology Education",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MDTechEd",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Chemistry",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Chemistry with Work Placement",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics & Statistics Faster Route",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSci",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics & Statistics Faster Route",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics (PGDE Graduates Only)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Physics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Psychology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Statistics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Statistics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics Faster Route",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Master in Science (with Honours) \u2013 MSci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics Faster Route",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics/Business and Management",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics/Economics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics/Music",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics/Philosophy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics/Philosophy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics/Physics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics/Politics (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics/Russian",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics/Scottish History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics/Scottish Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics/Theatre Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics/Theology & Religious Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Design Engineering",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Design Engineering",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Aeronautics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Aeronautics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MB",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Studies (PGDE - Graduates Only)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Molecular and Cellular Biology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular and Cellular Biology (with Biotechnology)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular and Cellular Biology (with Plant Science)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BMus (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music/Philosophy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music/Politics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music/Psychology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music/Russian",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music/Scottish History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music/Scottish Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music/Social & Public Policy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music/Theatre Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music/Theology & Religious Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BN (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGDE (Secondary - STEM) (Dumfries)",
                    "university": "University of Glasgow",
                    "location": "Dumfries Campus",
                    "qualification": "PgDipEd",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "PGDE with Teaching Qualification (Primary) (Dumfries)",
                    "university": "University of Glasgow",
                    "location": "Dumfries Campus",
                    "qualification": "PgDipEd",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy/Central & East European Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy/Politics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy/Politics (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy/Psychology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy/Russian",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy/Scottish History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy/Sociology (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy/Spanish",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy/Theatre Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy/Theology & Religious Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics (PGDE Graduates Only)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics with Quantitative Methods (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics/Psychology (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics/Scottish History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics/Scottish History (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics/Scottish Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics/Social & Public Policy (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics/Spanish",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics/Theatre Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese/Archaeology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese/Business and Management",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese/Classics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese/Digital Media & Information Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese/Economic and Social History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese/English Language & Linguistics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese/English Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese/Film and Television Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese/French",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese/Gaelic",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese/Geography",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese/German",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese/Greek",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese/History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese/History of Art",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese/Italian",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese/Latin",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese/Mathematics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese/Philosophy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese/Politics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese/Psychology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese/Russian",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese/Scottish History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese/Scottish Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese/Social & Public Policy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese/Spanish",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese/Theatre Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese/Theology and Religious Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pre-med/Pre-dent studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary (PGDE - Graduates Only)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Primary (PGDE - Graduates Only) including Catholic Teacher\u2019s Certificate",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Primary Education with Teaching Qualification (Dumfries)",
                    "university": "University of Glasgow",
                    "location": "Dumfries Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design Engineering",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design Engineering",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology/Scottish History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology/Scottish Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology/Social & Public Policy (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology/Sociology (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology/Spanish",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology/Statistics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology/Theatre Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology/Theology and Religious Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religious Studies (PGDE - Graduates Only)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Robotics & Artificial Intelligence",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics & Artificial Intelligence",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian/Central and East European Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian/Scottish Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian/Sociology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scots Law",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scots Law (Graduate Entry)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLB",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scots Law with French Language",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scots Law with French Legal Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scots Law with German Language",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scots Law with German Legal Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scots Law with Italian Language",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scots Law with Italian Legal Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scots Law with Spanish Language",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scots Law with Spanish Legal Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scots Law/Business & Management",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scots Law/English Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scots Law/History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scots Law/Philosophy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scots Law/Politics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scots Law/Social & Public Policy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scottish History/Scottish Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scottish History/Spanish",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scottish History/Theatre Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scottish History/Theology & Religious Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scottish Language and Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scottish Literature/Sociology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scottish Literature/Theatre Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scottish Literature/Theology & Religious Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social & Public Policy (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social & Public Policy with Quantitative Methods (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social and Public Policy/English Language & Linguistics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social and Public Policy/English Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social and Public Policy/Theatre Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology with Quantitative Methods (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology/Business and Management (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology/Business Economics (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology/Classics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology/Economics (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology/English Language & Linguistics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology/French",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology/German",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology/History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology/History of Art",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology/Philosophy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology/Politics (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology/Scottish History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology/Social and Public Policy (SocSci)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology/Theatre Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology/Theology and Religious Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering Faster Route",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering Faster Route",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering with Work Placement",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering with Work Placement (Faster Route)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish (PGDE - Graduates Only)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish with French (PGDE - Graduates Only)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Spanish/Comparative Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish/Economics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish/English Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish/French",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish/Geography",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish/German",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish/History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish/Italian",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish/Mathematics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish/Music",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish/Scottish Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish/Social and Public Policy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish/Sociology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish/Theatre Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish/Theology and Religious Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports & Exercise Science",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics and Economics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics Faster Route",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics Faster Route",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre Studies/Classics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre Studies/English Language & Linguistics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre Studies/English Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology & Religious Studies BD",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BD (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religious Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religious Studies/Comparative Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religious Studies/Computing Science",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religious Studies/English Language & Linguistics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religious Studies/English Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religious Studies/Gaelic",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religious Studies/Politics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religious Studies/Russian",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religious Studies/Social and Public Policy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religious Studies/Theatre Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Biosciences",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Medicine",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BVMS",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Medicine (Graduates Only)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BVMS",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Adult Education For Social Change (Erasmus Mundus International Master)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "International Master's",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Adult Education, Community Development and Youth Work",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Functional Materials",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Imaging and Sensing",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Statistics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MRes",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering and Management",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient Cultures",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Welfare Science, Ethics and Law",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Linguistics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc (PG)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Neuropsychology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgDip",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc (PG)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MLitt",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History: Collecting and Provenance In an International Context",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History: Dress and Textile Histories",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MLitt",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History: Technical Art History, Making and Meaning",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MLitt",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astrophysics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Behavioural Science",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioinformatics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgCert",
                    "duration": "3 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology and Management",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Brain Sciences",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer Research and Precision Oncology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cardiovascular Sciences",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc (MedSci)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Central and East European, Russian and Eurasian Studies (Erasmus Mundus International Master)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "International Master's",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Biology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc (PG)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Medicinal Chemistry",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children's Literature and Literacies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MEd",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children's Literature, Media and Culture (Erasmus Mundus International Master)",
                    "university": "University of Glasgow",
                    "location": "No Locations",
                    "qualification": "International Master's",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Church History and Theology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MTh",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "City Planning",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "City Planning and Real Estate Development",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering and Management",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Ancient History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Neuropsychology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgDip",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Neuropsychology Knowledge and Practice",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc (MedSci)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Neuropsychology Practice",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Pharmacology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc (MedSci)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Psychology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "DClinPsy",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MLitt",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Systems Engineering",
                    "university": "University of Glasgow",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science",
                    "university": "University of Glasgow",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conflict Archaeology and Heritage",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation Management of African Ecosystems",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Corporate and Financial Law",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Corporate Governance and Accountability",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Industries and Cultural Policy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing (Online Distance Learning)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MLitt",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MRes",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Criminal Justice",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Critical Care",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Curatorial Practice (Contemporary Art)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MLitt",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Analytics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Analytics for Economics and Finance",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc (PG)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Development Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Society",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diploma in Professional Legal Practice",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Modern History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth Futures: Environment, Communities, Relationships",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc (PG)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecology and Environmental Biology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MRes",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic Development  (With Finance & Policy Pathways)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MRes",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics, International Banking & Finance",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Primary) PGDE",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Secondary) PGDE",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education For Sustainable Futures In Adult, Community & Youth Contexts",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc (Ed)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education In Museums & Heritage",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "International Master's",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Policies For Global Development (Erasmus Mundus International Master)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "International Master's",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education, Public Policy and Equity",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MEd",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronics and Electrical Engineering",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronics and Electrical Engineering and Management",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronics and Photonics Manufacturing",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Endodontics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Linguistics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MLit",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature: American Modern Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MLit",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature: Fantasy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MLitt",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature: Modernities - Literature, Culture, Theory",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MLitt",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Enhanced Practice in Education",
                    "university": "University of Glasgow",
                    "location": "Dumfries Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment and Sustainable Development",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment, Culture and Communication",
                    "university": "University of Glasgow",
                    "location": "Dumfries Campus",
                    "qualification": "MLitt",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Futures: Sustainable Systems",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc (MedSci)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Risk Management",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science (Glasgow-Nankai Joint Graduate School)",
                    "university": "University of Glasgow",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Epidemiology of Infectious Diseases and Antimicrobial Resistance",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MLitt",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Curation",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Filmmaking and Media Arts",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Management",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial  Modelling & Investment",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc (Econ)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Economics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Risk Management",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Technology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Security",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genetic and Genomic Counselling (with Work Placement)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc (MedSci)",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geoinformation Technology and Cartography",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geomatics and Management",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geospatial and Mapping Sciences",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgCert",
                    "duration": "4 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Economy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Markets, Local Creativities (Erasmus Mundus International Master)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "International Master's",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Mental Health",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Migrations and Social Justice",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Security",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Care, Advanced Practice In",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc (MedSci)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Service Management",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Healthcare Chaplaincy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Historically Informed Performance Practice (In Conjunction With RCS)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Housing Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Anatomy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgCert",
                    "duration": "5 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Geography: Space, Politics, Ecologies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MRes",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Nutrition",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc (MedSci)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Rights and International Politics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Immunology and Inflammatory Disease",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Inclusive Education: Research, Policy and Practice",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MEd",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Industrial Heterogenous Catalysis",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Infection Biology (with specialisms)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Management and Preservation",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Technology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intellectual Property and the Digital Economy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Accounting and Financial Management",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MAcc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law (LLM)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Corporate Finance and Banking",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Economic Law",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Finance",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MFin",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Financial Analysis",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Resource Management and Development",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law and Security",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law of Global Security, Peace and Development (Erasmus Mundus LLM)",
                    "university": "University of Glasgow",
                    "location": "No Locations",
                    "qualification": "LLM",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management and Design Innovation",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MRes",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations (Glasgow-Nankai Joint Graduate School)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Strategic Marketing",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Investment Banking and Finance",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Land and Hydrographic Surveying",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgDip",
                    "duration": "8 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Land and Hydrographic Surveying (with Work Placement)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (LLM)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and Sustainable Tourism",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Enterprise and Business Growth",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Human Resources",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with International Finance",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Managing Art and Cultural Heritage in Global Markets",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "International Master's",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration (MBA)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MBA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Global Business (MGB)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MBA (International)",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Material Culture and Artefact Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics / Applied Mathematics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering and Management",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media Management",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media, Communications and International Journalism",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media, Culture and Society",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Devices Engineering",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Genetics and Genomics",
                    "university": "University of Glasgow",
                    "location": "No Locations",
                    "qualification": "MSc (MedSci)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Physics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Visualisation and Human Anatomy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Museum Education",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Museum Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Industries",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Musicology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MMus",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nanoscience and Nanotechnology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nuclear and Environmental Physics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing Science, Advanced",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oral and Maxillofacial Surgery",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc (PG)",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oral Sciences",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy (Conversion)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy of Mind and Psychology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Playwriting and Dramaturgy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MLitt",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Communication",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Precision Medicine (with specialisms)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Precision Medicine and Pharmacological Innovation",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design Engineering",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological Science (Conversion)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological Science, Research Methods of",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological Studies (Conversion)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MPH",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Policy and Management",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Policy Research",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MRes",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantitative Finance",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantitative Methods in Biodiversity, Conservation and Epidemiology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantum Technology",
                    "university": "University of Glasgow",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics and Artificial Intelligence",
                    "university": "University of Glasgow",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian, East European and Eurasian Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MRes",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian, East European and Eurasian Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scottish History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Security, Intelligence & Strategic Studies (Erasmus Mundus International Master)",
                    "university": "University of Glasgow",
                    "location": "No Locations",
                    "qualification": "International Master's",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sensor and Imaging Systems",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Slavery, Forced Migration & Reparative Justice",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "International Master's",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Research Methods",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MRes",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Development",
                    "university": "University of Glasgow",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sound Design and Audiovisual Practice",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "South European Studies (Erasmus Mundus International Master)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "International Master's",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spatial Planning",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Speech, Language and Sociolinguistics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Science and Medicine",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Stem Cell Engineering for Regenerative Medicine",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Engineering",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Energy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Tourism & Global Challenges",
                    "university": "University of Glasgow",
                    "location": "Dumfries Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Water Environments",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "TESOL: Teaching of English to Speakers of Other Languages",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MEd",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "TESOL: Teaching of English to Speakers of Other Languages",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Textile Conservation",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MPhil",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and Performance Practices",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MLitt",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MLitt",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Analytics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban and Regional Planning (Glasgow-Nankai Joint Graduate School)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Research",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MRes",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgCert",
                    "duration": "6 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Transport",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "War Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Adult Education, Community Development and Youth Work",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgDip",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient Cultures",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Linguistics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc (PG)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc (PG)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MLitt",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History: Collecting and Provenance In an International Context",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History: Dress and Textile Histories",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MLitt",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Child Health",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Childhood Practice",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgDip",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children's Literature and Literacies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MEd",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Church History and Theology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MTh",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "City Planning",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "City Planning and Real Estate Development",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Ancient History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Neuropsychology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgDip",
                    "duration": "48 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Neuropsychology Knowledge and Practice",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc (MedSci)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Neuropsychology Practice",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Pharmacology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc (MedSci)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MLitt",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conflict Archaeology and Heritage",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Corporate and Financial Law",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLM",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MLitt",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing (Online Distance Learning)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MLitt",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MRes",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Criminal Justice",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Critical Care",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diploma in Professional Legal Practice",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgDip",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Modern History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education For Sustainable Futures In Adult, Community & Youth Contexts",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc (Ed)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education, Public Policy and Equity",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Leadership",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MEd",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MEd",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "End Of Life Studies (Online Distance Learning)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc (PG)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Linguistics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MLit",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature: American Modern Literature",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MLit",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature: Fantasy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MLitt",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature: Modernities - Literature, Culture, Theory",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MLitt",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Enhanced Practice in Education",
                    "university": "University of Glasgow",
                    "location": "Dumfries Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment, Culture and Communication",
                    "university": "University of Glasgow",
                    "location": "Dumfries Campus",
                    "qualification": "MLitt",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Risk Management",
                    "university": "University of Glasgow",
                    "location": "Dumfries Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MLitt",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Curation",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgDip",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geoinformation Technology and Cartography",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geospatial and Mapping Sciences",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgCert",
                    "duration": "9 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Economy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Mental Health",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Migrations and Social Justice",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Security",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Care, Advanced Practice In",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc (MedSci)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Service Management",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Healthcare Chaplaincy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Historically Informed Performance Practice (In Conjunction With RCS)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgDip",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Housing Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgDip",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Geography: Space, Politics, Ecologies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MRes",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Rights and International Politics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Inclusive Education: Research, Policy and Practice",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MEd",
                    "duration": "72 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Inclusive Education: Research, Policy and Practice (Online Distance Learning available)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgDip",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Management and Preservation",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intellectual Property and the Digital Economy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLM",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law (LLM)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLM",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Economic Law",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLM",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLM",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law and Security",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLM",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MRes",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Into Headship",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Land and Hydrographic Surveying",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgDip",
                    "duration": "20 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (LLM)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "LLM",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Lymphoedema Management, Advanced",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Material Culture and Artefact Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics / Applied Mathematics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media, Communications and International Journalism",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media, Culture and Society",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgDip",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Middle Leadership and Management in Schools",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ministry, Theology and Practice",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MTh",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgDip",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Museum Education",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Museum Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Industries",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Musicology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MMus",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy (Conversion)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy of Mind and Psychology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Playwriting and Dramaturgy",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MLitt",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Communication",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Positive Behaviour Support",
                    "university": "University of Glasgow",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Practice with PGDE",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MEd",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MPH",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health (Online) (Online Distance Learning)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MPH",
                    "duration": "30 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Policy and Management",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Policy Research",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MRes",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgCert",
                    "duration": "48 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "60 months",
                    "study_mode": "Mixed mode",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian, East European and Eurasian Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MRes",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian, East European and Eurasian Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scottish History",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgDip",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Research Methods",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MRes",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sound Design and Audiovisual Practice",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spatial Planning",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgCert",
                    "duration": "48 months",
                    "study_mode": "Mixed mode",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Speech, Language and Sociolinguistics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Science and Medicine",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Science and Medicine (Online Distance Learning)",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Engineering",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Water Environments",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teacher Leadership and Learning",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgCert",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "TESOL: Teaching of English to Speakers of Other Languages",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "TESOL: Teaching of English to Speakers of Other Languages",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MEd",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Textile Conservation",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MPhil",
                    "duration": "48 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and Performance Practices",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MLitt",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MLitt",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Analytics",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Research",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MRes",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "PgCert",
                    "duration": "7 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Transport",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "War Studies",
                    "university": "University of Glasgow",
                    "location": "Gilmorehill (Main) Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "dded9d35ad4fa8bb318a9852754a1380",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "2D Digital Animation",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "3D Animation and Visual Effects",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "3D Games Art and Design",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Accounting",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Advertising and Digital Marketing",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Aerospace Engineering with Pilot Studies",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Aerospace Engineering with Pilot Studies",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Aerospace Engineering with Space Technology",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Aerospace Engineering with Space Technology",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Astrophysics",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Astrophysics",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Astrophysics with Space Science",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Astrophysics with Space Sciences",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Sandwich with time abroad",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Audio Engineering",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Automotive Engineering",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Automotive Engineering",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Automotive Engineering with Motorsport",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Automotive Engineering with Motorsport",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Automotive Technology",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "BA (Hons) International Business Management (Top up)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Biological Science",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "BSc (Hons) Professional Policing",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "BSc (Hons) Therapeutic Radiography",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Business Administration",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Business Administration (top-up)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Accounting",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Business and Finance",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Business and Human Resource Management",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Business and Marketing",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Business Economics",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Business Management with Information Systems",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Business Management with Leadership",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Character and Creative Effects",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Comics and Concept Art",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Computer Science (Artificial Intelligence)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Computer Science (Software Engineering)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Computer Science (top-up)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science, Cyber Security and Networks",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Computing Technologies (Networks and Cybersecurity) Foundation Degree",
                    "university": "University of Hertfordshire",
                    "location": "Multiple Locations",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computing Technologies (Software Development) Foundation Degree",
                    "university": "University of Hertfordshire",
                    "location": "Multiple Locations",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Creative Media Arts",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich with time abroad",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Creative Media Technology",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich with time abroad",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Creative Writing and Digital Media",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Criminology and  Criminal Justice",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Diagnostic Radiography and Imaging",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Dietetics",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Digital Media and Communications",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Early Childhood Education",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Education",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Engineering with Foundation Year",
                    "university": "University of Hertfordshire",
                    "location": "Multiple Locations",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "English Language and Creative Writing",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "English Language and Journalism",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "English Language and Linguistics",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "English Language and Literature",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "English Language and Media",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "English Language with English Language Teaching",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "English Language with Film",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "English Literature and Creative Writing",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "English Literature and Journalism",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "English Literature and Media",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "English Literature with Film",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Environmental Management and Ecology",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Event Management",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Event Management/Tourism",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Fashion and Fashion Business",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Film and Television (Production)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Finance",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Financial Mathematics",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Foundation Business Management",
                    "university": "University of Hertfordshire",
                    "location": "Multiple Locations",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Games Design and Development (L6 top-up)- HRC",
                    "university": "University of Hertfordshire",
                    "location": "Hertford Regional College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Graphic Design (Advertising and Branding)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Graphic Design Foundation Degree",
                    "university": "University of Hertfordshire",
                    "location": "Hertford Regional College",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "History",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "History and Creative Writing",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "History and English Language",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "History and English Literature",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "History and Journalism",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "History and Philosophy",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "History with Film",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Illustration",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Information Technology",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Initial Year for Extended Degree in Science - Mathematics",
                    "university": "University of Hertfordshire",
                    "location": "North Hertfordshire College",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Initial Year for Extended Degree in Science - Physical Sciences",
                    "university": "University of Hertfordshire",
                    "location": "North Hertfordshire College",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Initial Year for Extended Degree Science- BIOMEDICAL AND HEALTHCARE SCIENCE",
                    "university": "University of Hertfordshire",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Initial Year for Extended Degree Science- GEOGRAPHY AND ENVIRONMENTAL SCIENCES",
                    "university": "University of Hertfordshire",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Initial Year for Extended Degree Science- PSYCHOLOGY",
                    "university": "University of Hertfordshire",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Initial Year for Extended Degree Science- SPORTS",
                    "university": "University of Hertfordshire",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Initial Year Science Optometry",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MOptom",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Interior Architecture and Design",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "International Business with French",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "International Business with German",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "International Business with Japanese",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "International Business with Mandarin",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "International Business with Spanish",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "International Tourism Management",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Journalism and Creative Writing",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Journalism and Digital Media",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Law",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Law (Accelerated)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Live Sound, Lighting and Performance Technology",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA/BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "LLB  Law with Foundation Year",
                    "university": "University of Hertfordshire",
                    "location": "West Herts College",
                    "qualification": "LLB (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich with time abroad",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Mass Communications",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Midwifery",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Model Design (Character and Creative Effects)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Model Design (Special Effects)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Model Design and Special Effects",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Motorsport Technology",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Music and Sound Design Technology",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Music Composition and Sound for Film and Games",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Music Production and Promotion",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA/BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Nursing (Child)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Nursing (Learning Disabilities)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Optometry",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MOptom",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-144"
                },
                {
                    "course_name": "Paramedic Science",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Performing Arts (L6 top-up)- HRC",
                    "university": "University of Hertfordshire",
                    "location": "Hertford Regional College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Science",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Pharmacy",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MPharm (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Philosophy and Creative Writing",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Philosophy and English Language",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Philosophy and English Literature",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Philosophy and Journalism",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Philosophy and Media",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Philosophy with Film",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Philosophy, Religion and Ethics",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Physician Associate Studies (Post Graduate)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "Master of Physician Associate Studies \u2013 MPAS",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Physics with Space Sciences",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Sandwich with time abroad",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Physics with Space Sciences",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Primary Education (with QTS)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BEd (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Psychology in Education",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Robotics and Artificial intelligence",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BEng",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Sociology and Psychology",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Songwriting and Artist Development",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Sport Business Management",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Sports Coaching",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Sports Studies",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Sports Studies with Psychology",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Sports Therapy",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Three-dimensional Design Foundation Degree",
                    "university": "University of Hertfordshire",
                    "location": "Hertford Regional College",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Visual Merchandising Foundation Degree",
                    "university": "University of Hertfordshire",
                    "location": "Hertford Regional College",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Visual Merchandising, Styling and Promotion (L6 top-up)- HRC",
                    "university": "University of Hertfordshire",
                    "location": "Hertford Regional College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "FdSc Health Care Practice (Nursing Associate HTQ)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Advanced Clinical Practice",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science with Placement Year",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science with Research",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Paramedic Practice",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Physiotherapy",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animation",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Mental Health Practice",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "4 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture and Urbanism",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MArch",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art Therapy",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence and Robotics with Advanced Research",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence and Robotics with Placement Year",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence with Robotics",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astrophysics",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astrophysics with Advanced Research",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astrophysics with Placement Year",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Audio Engineering",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive Engineering",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bar Practice",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analysis and Consultancy",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Law",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Law with Placement Year",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Law with Work Experience",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Psychology",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer Immunotherapy",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer Immunotherapy with Advanced Research",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer Immunotherapy with Placement Year",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cardiology and Stroke",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Dermatology",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Radiographic Reporting",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Skin Integrity and Wound Management",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Behavioural Therapy",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communications and Information Engineering",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Networks and Systems Security",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Networks and Systems Security with Advanced Research",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Networks and Systems Security with Placement Year",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Concept Art",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary Crafts",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary Textiles",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Music Production",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Justice",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Justice with Placement Year",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Justice with Work Experience",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security with Advanced Research",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Analytics",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Analytics with Advanced Research",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Analytics with Placement Year",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science with Placement Year",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "Professional Doctorate",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diagnostic Radiography and Imaging (Pre-Registration)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media Arts",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media Arts (Online)",
                    "university": "University of Hertfordshire",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drug Discovery and Toxicology",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drug Discovery and Toxicology with Advanced Research",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drug Discovery and Toxicology with Placement Year",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Childhood Education",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education with Advanced Research",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education with Placement Year",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Leadership",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronics Engineering",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Law and the Environment",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Management",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Management for Agriculture",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Sciences",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Production",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Investment Banking",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Investment Banking with Advanced Research",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Investment Banking with Placement Year",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Investment Management",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "Professional Doctorate",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Folklore Studies",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Policy, Nutrition and Diet",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Art and Design",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Business",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Governance, Risk management and Compliance",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Medical Education",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Medical Simulation",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Heritage",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "Professional Doctorate",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hospitality, Leisure, Tourism and Transport",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Geography",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Geography",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management (Global)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management (Work Psychology)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management with Advanced Research",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management with Placement Year",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resources Management",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Illustration",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intellectual Property and Information Law",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intellectual Property and Information Law with Placement Year",
                    "university": "University of Hertfordshire",
                    "location": "North Hertfordshire College",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intellectual Property and Information Law with Work Experience",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Architecture and Design",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Advanced Research",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Placement Year",
                    "university": "University of Hertfordshire",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law and Arbitration with Placement Year",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law and Arbitration with Work Experience",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law and Arbitration with Work Experience",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Financial Law",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Rights Law",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Rights Law with Placement Year",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Rights Law with Work Experience",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations with Advanced Research",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations with Placement Year",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tourism and Hospitality Management",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tourism, Hospitality and Event Management with Placement Year",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tourism, Hospitality and Events Management with Advanced Research",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "IT Law and Policy",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism and Media Communications",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism and Media Communications with Advanced Research",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism and Media Communications with Advanced Research",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism and Media Communications with Placement Year",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal Practice",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "LLM Master of Laws",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "LLM with Work Experience",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management Studies",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Digital Marketing",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Logistics and Supply Chain Management",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Sustainability",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Manufacturing Management",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Manufacturing Management (18-month route)",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Manufacturing Management with Advanced Project",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Manufacturing Management with Extended Professional Practice",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Midwifery",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MMid",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Masters of Laws LLM with Placement Year",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Imaging and Radiation Sciences (Advanced Medical Imaging )",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Imaging and Radiation Sciences (Advanced Radiotherapy and Oncology Practice)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Microbiology",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Microbiology with Advanced Research",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Microbiology with Placement Year",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular Biotechnology",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Sound for Film and Games",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Learning Disabilities)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Psychology",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Outdoor Environmental Education",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paramedic Science (Pre-reg)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology, Toxicology and Pharmacy",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy (Overseas Pharmacists Assessment Programme)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Photography",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Geography",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Geography",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physician Associate Studies",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy (Pre-Registration)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Power Electronics and Control",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Education",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "PGCE (Professional)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Conversion)",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MPH",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health with Advanced Research",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MPH",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health with Extended Placement",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MPH",
                    "duration": "1 year",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Relations and Digital Communications",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Relations and Digital Communications with Advanced Research",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Relations and Digital Communications with Placement Year",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Research in Clinical Psychology",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Screenwriting",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Education Art and Design",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "PGCE (Professional)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Education Biology",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "PGCE (Professional)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Education Business Education",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Education Chemistry",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "PGCE (Professional)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Education Computing",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "PGCE (Professional)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Education English",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Education Geography",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Education History",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "PGCE (Professional)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Education Mathematics",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "PGCE (Professional)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Education Modern Foreign Languages",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "PGCE (Professional)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Education Physical Education",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Education Physics",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "SEND and Inclusion",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work (Step Up to Social Work)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering with Advanced Research",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering with Placement Year",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Nursing (Community Childrens Nursing)",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Nursing (Community District Nursing)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Nursing (General Practice Nursing)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Public Health Nursing (Health Visiting)",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Public Health Nursing (School Nursing)",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Marketing with Brand Leadership",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Marketing with Brand Leadership (Experiential Learning)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Marketing with Brand Leadership with Placement Year",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Marketing with Digital Media Management",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Marketing with Digital Media Management (Experiential Learning)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Marketing with Digital Media Management with Placement Year",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Marketing with Global Business Development",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strength, Conditioning and Rehabilitation",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Supply Chain and Logistics Management",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Supply Chain and Logistics Management (18-month route)",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Supply Chain and Logistics Management with Advanced Project",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Supply Chain and Logistics Management with Extended Professional Practice",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Planning",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Planning and Environmental Management",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Planning and Transport",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics with Advanced Research",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Transport Planning",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Transport Planning with Advanced Research",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Transport Planning with Placement Year",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Water and Environmental Management",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Aerospace Engineering",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animation",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Mental Health Practice",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "6 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture and Urbanism",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MArch",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art Therapy",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astrophysics",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Audio Engineering",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive Engineering",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "DBA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analysis and Consultancy",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Law",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Psychology",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer Immunotherapy",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer Immunotherapy with Advanced Research",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cardiology and Stroke",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Dermatology",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Skin Integrity and Wound Management",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Behavioural Therapy",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communications and Information Engineering",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Networks and Systems Security",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Concept Art",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary Crafts",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary Nursing",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "5 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary Textiles",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Music Production",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Justice",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Analytics",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media Arts",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media Arts (Online)",
                    "university": "University of Hertfordshire",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drug Discovery and Toxicology",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Childhood Education",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "EdD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Leadership",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Law and the Environment",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "EngD",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Management",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Management for Agriculture",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Sciences",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Investment Banking",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Investment Management",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "Professional Doctorate",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Folklore Studies",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Policy, Nutrition and Diet",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Art and Design",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Governance, Risk management and Compliance",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Medical Education",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Medical Simulation",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Research",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "Professional Doctorate",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Heritage",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "Professional Doctorate",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hospitality, Leisure, Tourism and Transport",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Geography",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Geography",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management (Global)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management (Work Psychology)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resources Management",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Illustration",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intellectual Property and Information Law",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Architecture and Design",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Financial Law",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Rights Law",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tourism and Hospitality Management",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "IT Law and Policy",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism and Media Communications",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "LLM Master of Laws",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "DMan",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management Studies",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Manufacturing Management",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Microbiology",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "DM",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health and Wellbeing in Education (2 Year Route)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health and Wellbeing in Education (3 Year route)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery and Women's Health",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular Biotechnology",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Sound for Film and Games",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Psychology",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Outdoor Environmental Education",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology, Toxicology and Pharmacy",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacovigilance",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy Practice",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Photography",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Geography",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Geography",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Power Electronics and Control",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Practice Cert in Supplementary Prescribing (Diagnostic Radiographer, Dietician)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "Certificate of Postgraduate Study",
                    "duration": "6 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Practice Certificate in Independent Prescribing (Allied Health Professionals)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "Certificate of Postgraduate Study",
                    "duration": "6 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Practice Certificate in Independent Prescribing (Nurse)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "Certificate of Postgraduate Study",
                    "duration": "6 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Practice Certificate in Independent Prescribing (Pharmacy)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "Certificate of Postgraduate Study",
                    "duration": "6 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Conversion)",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MPH",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Relations and Digital Communications",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Research in Clinical Psychology",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "SEND and Inclusion",
                    "university": "University of Hertfordshire",
                    "location": "De Havilland Campus",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Nursing (Community Childrens Nursing)",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "PgDip",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Nursing (Community District Nursing)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Nursing (General Practice Nursing)",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Public Health Nursing (Health Visiting)",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Public Health Nursing (School Nursing)",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Marketing with Brand Leadership",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Marketing with Global Business Development",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strength, Conditioning and Rehabilitation",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Supply Chain and Logistics Management",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Planning",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Planning and Environmental Management",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Planning and Transport",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Transport Planning",
                    "university": "University of Hertfordshire",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Water and Environmental Management",
                    "university": "University of Hertfordshire",
                    "location": "College Lane Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "b3b0e279674c01688d10fddbf73a19c1",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "42-48"
                },
                {
                    "course_name": "Accounting",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Acting and Performance",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "42-48"
                },
                {
                    "course_name": "Administration and Information Technology",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Administration and Information Technology",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "42-48"
                },
                {
                    "course_name": "Adventure Education",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "North, West and Hebrides",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "63-64"
                },
                {
                    "course_name": "Adventure Performance and Coaching",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "North, West and Hebrides",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "63-64"
                },
                {
                    "course_name": "Adventure Tourism Management",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "North, West and Hebrides",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "63-64"
                },
                {
                    "course_name": "Aircraft Engineering",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21-32"
                },
                {
                    "course_name": "Aircraft Engineering",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aircraft Maintenance Engineering & Management",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64-69"
                },
                {
                    "course_name": "An Cursa Comais (Intensive Gaelic - Intermediate)",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Applied Music",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-87"
                },
                {
                    "course_name": "Applied Sciences",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Applied Software Development",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "63-64"
                },
                {
                    "course_name": "Archaeological Science",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Archaeology and Criminology",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Archaeology and Environmental Studies",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "63-64"
                },
                {
                    "course_name": "Archaeology and Literature",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Archaeology and Sociology",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Archaeology and Theology",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Archaeology with Gaelic Studies",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Architectural Technology",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Inverness",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Technology",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Inverness",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "40-42"
                },
                {
                    "course_name": "Art and Contemporary Practices",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Audio Engineering",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Beauty Therapy",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "42-48"
                },
                {
                    "course_name": "Beauty Therapy",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Bioscience",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioscience",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Business",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "42-48"
                },
                {
                    "course_name": "Business",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Child and Youth Studies",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "63-64"
                },
                {
                    "course_name": "Child and Youth Studies with Community Learning and Development",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "63-64"
                },
                {
                    "course_name": "Childhood Practice",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "24-42"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Inverness",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "42-48"
                },
                {
                    "course_name": "Computing",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Computing",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Construction Management",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Contemporary Art Practice",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Contemporary Art Practice",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "42-48"
                },
                {
                    "course_name": "Contemporary Film Making in the Highlands and Islands",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "63-64"
                },
                {
                    "course_name": "Counselling",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing in the Highlands and Islands",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "69-72"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Criminology and Theology",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Culture and Heritage",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Culture and Heritage with Gaelic Studies",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Culture, Heritage and Archaeology",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Culture, Heritage and Criminology",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Culture, Heritage and History",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Culture, Heritage and Literature",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Culture, Heritage and Politics",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Culture, Heritage and Sociology",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Culture, Heritage and Theology",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Digital Design and Web Development",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Drama and Production",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Inverness",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Mechanical Engineering",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Engineering",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Systems Pathway)",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Inverness",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "42-48"
                },
                {
                    "course_name": "Engineering Systems",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Systems",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Inverness",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "63-64"
                },
                {
                    "course_name": "Environmental Science (with a year in industry) BScH",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Inverness",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "63-64"
                },
                {
                    "course_name": "Equine Business Management",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "North, West and Hebrides",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "63-64"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "63-64"
                },
                {
                    "course_name": "Fitness, Health and Exercise",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "42-48"
                },
                {
                    "course_name": "Fitness, Health and Exercise",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "42-48"
                },
                {
                    "course_name": "Food, Nutrition and Textiles Education",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Forestry",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Inverness",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "42-48"
                },
                {
                    "course_name": "Forestry and Treescapes Management",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Inverness",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "42-48"
                },
                {
                    "course_name": "Gaelic",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Gaelic and Communication",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Gaelic and Education",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Gaelic and Related Studies",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Gaelic and Traditional Music",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Sabhal Mor Ostaig",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Gaelic Media",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Sabhal Mor Ostaig",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Gaelic Scotland",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Geography",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Geography",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Inverness",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "63-96"
                },
                {
                    "course_name": "Geography (with a year in industry) BScH",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Inverness",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "63-96"
                },
                {
                    "course_name": "Geography and Archaeology",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Geography and Criminology",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Geography and History",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Geography and Politics",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Geography, Culture & Heritage",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Golf Management",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "North, West and Hebrides",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "63-64"
                },
                {
                    "course_name": "Health and Social Studies",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "63-64"
                },
                {
                    "course_name": "Healthcare Practice",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32-42"
                },
                {
                    "course_name": "Highland Heritage for Tourism",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Orkney",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "History",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "History and Archaeology",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "History and Criminology",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "History and Literature",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "History and Politics",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "History and Sociology",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "History and Theology",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "History with Gaelic Studies",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Horticulture",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Argyll",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Horticulture",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Hospitality Management",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Hospitality Operations",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Integrative Healthcare",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Moray",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "63-64"
                },
                {
                    "course_name": "Interactive Media",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Jewellery",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "North, West and Hebrides",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Literature",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Literature and Creative Writing",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Literature and Criminology",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Literature and Politics",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Literature and Sociology",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Literature and Theology",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Marine and Coastal Tourism",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "North, West and Hebrides",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Science",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Scottish Association for Marine Science",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-114"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Moral and Philosophical Studies with Religious Education",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Inverness",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Music",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Music",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "North, West and Hebrides",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Music Business",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Music Business",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Musical Theatre",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Musical Theatre",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "69-72"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "69-72"
                },
                {
                    "course_name": "Optometry with Independent Prescribing MOptom",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "UHI House",
                    "qualification": "MOptom",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "114-120"
                },
                {
                    "course_name": "Oral Health Science",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-108"
                },
                {
                    "course_name": "PGDE Primary (English Medium)",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "PGDE Primary (Gaelic Medium)",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "PGDE Secondary (Gaelic Medium)",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "PGDE Secondary Teaching (English Medium) Art and Design",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21"
                },
                {
                    "course_name": "PGDE Secondary Teaching (English Medium) Biology and Science",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21"
                },
                {
                    "course_name": "PGDE Secondary Teaching (English Medium) Business Education",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21"
                },
                {
                    "course_name": "PGDE Secondary Teaching (English Medium) Chemistry with Science",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21"
                },
                {
                    "course_name": "PGDE Secondary Teaching (English Medium) Computing Science",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21"
                },
                {
                    "course_name": "PGDE Secondary Teaching (English Medium) English",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21"
                },
                {
                    "course_name": "PGDE Secondary Teaching (English Medium) History",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21"
                },
                {
                    "course_name": "PGDE Secondary Teaching (English Medium) Home Economics",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21"
                },
                {
                    "course_name": "PGDE Secondary Teaching (English Medium) Mathematics",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21"
                },
                {
                    "course_name": "PGDE Secondary Teaching (English Medium) Music",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21"
                },
                {
                    "course_name": "PGDE Secondary Teaching (English Medium) Physics with Science",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21"
                },
                {
                    "course_name": "PGDE Secondary Teaching (English Medium) Religious Education",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21"
                },
                {
                    "course_name": "PGDE Secondary Teaching (English Medium) Technical Education",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Philosophy and History",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Philosophy and Literature",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Philosophy and Politics",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Philosophy and Sociology",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Philosophy and Theology",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Philosophy, Politics and Economics",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Photography",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "North, West and Hebrides",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Politics",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Politics and Archaeology",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Politics and Criminology",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Politics and Theology",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Popular Music",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Professional Cookery",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Professional Golf",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "North, West and Hebrides",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "63-64"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Social Sciences",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Social Sciences",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Social Services",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "24-42"
                },
                {
                    "course_name": "Sociology and Criminology",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Sociology and Politics",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Sociology and Theology",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Soft Tissue Therapy",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "42-48"
                },
                {
                    "course_name": "Sound Production",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Sport and Fitness",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Sports Coaching and Development",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "42-48"
                },
                {
                    "course_name": "Sports Coaching and Development",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "42-48"
                },
                {
                    "course_name": "Sports Management",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Therapy",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "42-48"
                },
                {
                    "course_name": "Sports Therapy and Rehabilitation",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "42-48"
                },
                {
                    "course_name": "Sustainable Architectural Studies",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Inverness",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Sustainable Development",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Sustainable Development and Archaeology",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Sustainable Development and Criminology",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Sustainable Development and History",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Sustainable Development and Literature",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Sustainable Development and Politics",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Sustainable Development with Gaelic Studies",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Sustainable Development, Culture and Heritage",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Technical Theatre",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Technical Theatre and Production Arts",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "42-48"
                },
                {
                    "course_name": "Technological Education",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Theatre Industry Practice",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theological Studies",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "63-64"
                },
                {
                    "course_name": "Visual Communication",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "42-48"
                },
                {
                    "course_name": "Visual Communication",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Visual Communication and Design",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Wildlife and Conservation Management",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "North, West and Hebrides",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Wildlife and Conservation Management",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "North, West and Hebrides",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "21-24"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Primary (English Medium)",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Inverness",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "21-24"
                },
                {
                    "course_name": "Secondary (English Medium)",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Inverness",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "21-24"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Applied Social Sciences: Globalisation and Crime",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "MSc",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aquaculture, Environment and Society",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Scottish Association for Marine Science",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeological Practice",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Orkney",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeological Studies",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Social Practice",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Shetland",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration (Aviation)",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "MBA",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration (Executive)",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "MBA",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration (Renewable Energy)",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "MBA",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children and Young People's Participation and Leadership",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "MA",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Coastal and Maritime Societies and Cultures",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "UHI Centre for History",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary Art and Archaeology",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Orkney",
                    "qualification": "MA",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Engineering",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "North, West and Hebrides",
                    "qualification": "MSc",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (General)",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "North, West and Hebrides",
                    "qualification": "MSc",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Extreme Weather",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "North, West and Hebrides",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Highlands and Islands Literature",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "UHI Centre for History",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Archaeology of the Highlands and Islands",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "UHI Centre for History",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of the Highlands and Islands",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "UHI Centre for History",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interpretation: Management and Practice",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "MSc",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Island Studies",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Orkney",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "North, West and Hebrides",
                    "qualification": "MSc",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "MMus",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and the Environment",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Net Zero Communities",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "North, West and Hebrides",
                    "qualification": "MSc",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology Conversion",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Inverness",
                    "qualification": "MSc",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Research Methods",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "North, West and Hebrides",
                    "qualification": "PgCert",
                    "duration": "6 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scottish Heritage",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Orkney",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability and Rural Regeneration",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "North, West and Hebrides",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Energy Solutions",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "North, West and Hebrides",
                    "qualification": "MSc",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Mountain Development",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "MSc",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology, Worldview and Culture",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "HTC",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Tourism",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "MSc",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Viking Studies",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Orkney",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Nurse Practitioner/Professional Practice",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Department of Nursing and Midwifery Inverness",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Data Analytics",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Inverness",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Social Sciences: Globalisation and Crime",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeological Practice",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Orkney",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeological Studies",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "MLitt",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Social Practice",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Shetland",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration (Aviation)",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "MBA",
                    "duration": "2.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration (Environment)",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "MBA",
                    "duration": "2.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration (Executive)",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "MBA",
                    "duration": "2.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration (Renewable Energy)",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "MBA",
                    "duration": "2.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration (Resilience)",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "MBA",
                    "duration": "1.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chartered Management Institute Level 11 Strategic Management and Leadership SCQF CMI Extended Diploma",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "Executive Diploma",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children and Young People's Participation and Leadership",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Coastal and Maritime Societies and Cultures",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "UHI Centre for History",
                    "qualification": "MLitt",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary Art and Archaeology",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Orkney",
                    "qualification": "MA",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Pedagogy",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "North, West and Hebrides",
                    "qualification": "MEd",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Engineering",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "North, West and Hebrides",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (General)",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "North, West and Hebrides",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Equine Leadership and Management",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "North, West and Hebrides",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Extreme Weather",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "North, West and Hebrides",
                    "qualification": "MSc",
                    "duration": "1.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gaelic Education",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Sabhal Mor Ostaig",
                    "qualification": "MEd",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Wellbeing",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "North, West and Hebrides",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Leadership and Management",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Inverness",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Healthcare Quality Improvement",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "North, West and Hebrides",
                    "qualification": "PgCert",
                    "duration": "1.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Highlands and Islands Literature",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "MLitt",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "UHI Centre for History",
                    "qualification": "MLitt",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Archaeology of the Highlands and Islands",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "UHI Centre for History",
                    "qualification": "MLitt",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of the Highlands and Islands",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "UHI Centre for History",
                    "qualification": "MLitt",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Inverness",
                    "qualification": "MSc",
                    "duration": "2.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interpretation: Management and Practice",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Island Studies",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Orkney",
                    "qualification": "MLitt",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership and Management",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Inverness",
                    "qualification": "MSc",
                    "duration": "2.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management in Golf",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "North, West and Hebrides",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Material Culture and Gaidhealtachd History",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Sabhal Mor Ostaig",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "North, West and Hebrides",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "MMus",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and the Environment",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Net Zero Communities",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "North, West and Hebrides",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Outdoor and Adventure Therapeutic Practice",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "North, West and Hebrides",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology Conversion",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Inverness",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Research Methods",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "North, West and Hebrides",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Resilience Leadership and Management",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Rural Advanced Practice",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Department of Nursing and Midwifery Inverness",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scottish Heritage",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Orkney",
                    "qualification": "MLitt",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Qualification in Headship",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Orkney",
                    "qualification": "MEd",
                    "duration": "3.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability and Rural Regeneration",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "North, West and Hebrides",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Energy Solutions",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "North, West and Hebrides",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Mountain Development",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "MSc",
                    "duration": "2.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching Qualification Further Education",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Multiple Locations",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Tertiary and Higher Education",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "North, West and Hebrides",
                    "qualification": "MEd",
                    "duration": "1.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology, Worldview and Culture",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "HTC",
                    "qualification": "MLitt",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Tourism",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Perth",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Viking Studies",
                    "university": "University of the Highlands and Islands (UHI)",
                    "location": "Orkney",
                    "qualification": "MLitt",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "7f22ef008a9f5f3875b39bec7f28b49b",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Business Administration",
                    "university": "Hult International Business School",
                    "location": "Multiple Locations",
                    "qualification": "BBA (Hons)",
                    "duration": "2 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "f34d2b3a7d7c415ba539f9994360ca3d",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Aeronautical Engineering",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry with French for Science",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry with German for Science",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry with Spanish for Science",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences with French for Science",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences with German for Science",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences with Spanish for Science",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomaterials and Tissue Engineering",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Technology Ventures",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology with French for Science",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology with German for Science",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology with Spanish for Science",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with a Year in Industry",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Management",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Management with a Year in Industry",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Medicinal Chemistry",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Medicinal Chemistry and a Year in Industry",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Molecular Physics",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Molecular Physics and a Year in Industry",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Research Abroad",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Research Abroad and a Year in Industry",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with a Year Abroad",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing (Artificial Intelligence and Machine Learning)",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing (International Programme of Study)",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing (Management and Finance)",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing (Security and Reliability)",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing (Software Engineering)",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing (Visual Computing and Robotics)",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Engineering",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth and Planetary Science",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth and Planetary Science",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth and Planetary Science with a Year Abroad",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144-168"
                },
                {
                    "course_name": "Ecology and Environmental Biology",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics, Finance and Data Science",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical & Electronic Engineering",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical & Electronic Engineering",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical & Electronic Engineering with Management",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Information Engineering",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Information Engineering",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology with a Year Abroad",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geophysics",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geophysics",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geophysics with a Year Abroad",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science and Engineering",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science and Engineering",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials with Nuclear Engineering",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics (Pure Mathematics)",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Computer Science",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Computer Science",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with a Year Abroad",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Applied Mathematics/Mathematical Physics",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Mathematical Computation",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Statistics",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Statistics for Finance",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Biosciences",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Biosciences with Management",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular Bioengineering",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with a Year Abroad",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Theoretical Physics",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Theoretical Physics",
                    "university": "Imperial College London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "4472ce84e35dc75fa284d53b9e1b33ec",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting with Finance",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Accounting with Finance with Foundation & Placement Years",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-40"
                },
                {
                    "course_name": "Accounting with Finance with Foundation Year",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-40"
                },
                {
                    "course_name": "Accounting with Finance with Placement Year",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management (Top up)",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Foundation and Placement Years",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "32-40"
                },
                {
                    "course_name": "Business Management with Foundation Year",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-40"
                },
                {
                    "course_name": "Business Management with Law",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Law with Foundation & Placement Years",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-40"
                },
                {
                    "course_name": "Business Management with Law with Foundation Year",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-40"
                },
                {
                    "course_name": "Business Management with Law with Placement Year",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Marketing",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Marketing with Foundation and Placement Years",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "BSc",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-40"
                },
                {
                    "course_name": "Business Management with Marketing with Foundation Year",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-40"
                },
                {
                    "course_name": "Business Management with Marketing with Placement Year",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Placement Year",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science",
                    "university": "The University of Law",
                    "location": "London Bloomsbury",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science with Foundation Year",
                    "university": "The University of Law",
                    "location": "London Bloomsbury",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Criminology",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology and Policing",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology and Policing with Foundation Year",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Criminology with Foundation Year",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "International Strategic Business Management",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Strategic Business Management with Foundation & Placement Years",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-40"
                },
                {
                    "course_name": "International Strategic Business Management with Foundation Year",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-40"
                },
                {
                    "course_name": "International Strategic Business Management with Placement Year",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Law",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Law (Accelerated)",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Law (Senior Status)",
                    "university": "The University of Law",
                    "location": "London Bloomsbury",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Business",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Law with Business with Foundation Year",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Law with Criminology with Foundation Year",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Law with Foundation Year",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Law with International Business",
                    "university": "The University of Law",
                    "location": "London Bloomsbury",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Law with International Business with Foundation Year",
                    "university": "The University of Law",
                    "location": "London Bloomsbury",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "MLaw (Solicitors' Practice) - 4 Year Integrated Masters",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "MLaw",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Professional Policing - Accelerated",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Professional Policing with Foundation Year",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Psychology",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology with Criminology",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology with Criminology with Foundation Year",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Psychology with Foundation Year",
                    "university": "The University of Law",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "c488588d044694ea1d0bdf8920717147",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Aerospace Engineering (with a year abroad)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Aerospace Engineering (with a year abroad)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Aerospace Engineering (with Industry)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Aerospace Engineering (with Industry)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Ancient History",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Ancient History and Archaeology",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Ancient History and History",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Applied Psychology",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-147"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MBiolSci",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Biological Sciences (Biochemistry)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Biological Sciences (Biochemistry)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MBiolSci",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Biological Sciences (Genetics)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Biological Sciences (Genetics)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MBiolSci",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Biological Sciences (Microbiology)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MBiolSci",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Biological Sciences (Microbiology)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Biological Sciences (Neuroscience)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MBiolSci",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Biological Sciences (Neuroscience)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Biological Sciences (Physiology with Pharmacology)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Biological Sciences (Physiology with Pharmacology)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MBiolSci",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Biological Sciences (with Foundation Year)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "96-128"
                },
                {
                    "course_name": "Biological Sciences (Zoology)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Biomedical Engineering (with a year abroad)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Biomedical Engineering (with a year abroad)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Biomedical Engineering (with a year in industry)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Biomedical Engineering (with a year in industry)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Business and Management",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Business Economics",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-147"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-147"
                },
                {
                    "course_name": "Chemistry (with a year abroad)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-147"
                },
                {
                    "course_name": "Chemistry (with a year in Industry)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-147"
                },
                {
                    "course_name": "Chemistry with Foundation Year",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Sciences",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MCompu (H)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-168"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-168"
                },
                {
                    "course_name": "Computer Science (with a year abroad)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-168"
                },
                {
                    "course_name": "Computer Science (with a Year in Industry)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128-168"
                },
                {
                    "course_name": "Computer Science with Artificial Intelligence",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-168"
                },
                {
                    "course_name": "Computer Science with Artificial Intelligence with Industry",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-168"
                },
                {
                    "course_name": "Computer Science with Foundation Year",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Computing",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Creative Computing with Foundation Year",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Criminology with Forensic Psychology",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Diagnostic Radiography",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-141"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Economics and Accounting",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Economics and Data Analytics",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Electrical & Electronic Engineering (with a year abroad)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-152"
                },
                {
                    "course_name": "Electrical & Electronic Engineering (with a year abroad)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-152"
                },
                {
                    "course_name": "Electrical & Electronic Engineering (with Industry)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-152"
                },
                {
                    "course_name": "Electrical & Electronic Engineering (with Industry)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-152"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-152"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-152"
                },
                {
                    "course_name": "Engineering with Foundation Year",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "English",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "English and American Studies",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "English and French Law",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-160"
                },
                {
                    "course_name": "English and History",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "English with Creative Writing",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Environmental Science with Foundation Year",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Media Studies",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Film Studies",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Film Studies and English",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Finance",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Financial Economics and Banking",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "General Engineering",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "General Engineering",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "General Engineering (with a year abroad)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "General Engineering (with a year abroad)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "General Engineering (with Industry)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "General Engineering (with Industry)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Geography with Foundation Year",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-152"
                },
                {
                    "course_name": "Geology",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MGeol",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-160"
                },
                {
                    "course_name": "Geology with Foundation Year",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology with Palaeontology",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-152"
                },
                {
                    "course_name": "Geology with Palaeontology",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MGeol",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-160"
                },
                {
                    "course_name": "History",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-152"
                },
                {
                    "course_name": "History and American Studies",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-152"
                },
                {
                    "course_name": "History and Archaeology",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "History and Politics",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-152"
                },
                {
                    "course_name": "Human Geography",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "International Relations and History",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Journalism",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Journalism and English",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Journalism and Marketing",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Journalism and Media",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Journalism and Sociology",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Journalism with Creative Writing",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Law",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-160"
                },
                {
                    "course_name": "Law (Graduate Entry)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (JD Pathway)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with a Modern Language",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-160"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-160"
                },
                {
                    "course_name": "Law with Politics",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-160"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MMath",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Actuarial Science",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Actuarial Science",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MMath (Hons)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Foundation Year",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Mechanical Engineering (with a year abroad)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Mechanical Engineering (with a year abroad)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Mechanical Engineering (with Industry)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Mechanical Engineering (with Year in Industry)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Media and Communication",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Media, Society and Culture",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Medical Biochemistry",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Medical Biosciences (Biochemistry)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MBiolSci",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Medical Biosciences (Genetics)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MBiolSci",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Medical Biosciences (Microbiology)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MBiolSci",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Medical Biosciences (Physiology)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MBiolSci",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Medical Genetics",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Medical Microbiology",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Medical Physiology",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Medicinal Chemistry",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-147"
                },
                {
                    "course_name": "Medicinal Chemistry",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-147"
                },
                {
                    "course_name": "Medicinal Chemistry (with a year abroad)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-147"
                },
                {
                    "course_name": "Medicinal Chemistry (with a year in industry)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-147"
                },
                {
                    "course_name": "Medicine",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MB BCh",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "152-168"
                },
                {
                    "course_name": "Medicine (with a foundation year)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MB ChB",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Midwifery with Leadership",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Modern and Contemporary History",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-152"
                },
                {
                    "course_name": "Modern Languages",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Modern Languages and English",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Modern Languages and International Relations",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Modern Languages and Translation",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Modern Languages with Film Studies",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Modern Languages with Management",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Nursing with Leadership Dual Field Mental Health and Adult Nursing",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing with Leadership Dual Field Mental Health and Children's Nursing",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing with Leadership Single Registration Adult Nursing",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Operating Department Practice",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Pharmacy",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MPharm (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Physical Geography and Geology",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-152"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MPhys (Hons)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-174"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-174"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MPhys (Hons)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-174"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-174"
                },
                {
                    "course_name": "Physics with Foundation Year",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Space Science",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-174"
                },
                {
                    "course_name": "Physics with Space Science",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "MPhys (Hons)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-174"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Physiotherapy with Foundation Year",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Politics",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Politics and Economics",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Politics and Sociology",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-147"
                },
                {
                    "course_name": "Psychology with Cognitive Neuroscience",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-147"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Sociology with Criminology",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-168"
                },
                {
                    "course_name": "Software Engineering (with a Year Abroad)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-168"
                },
                {
                    "course_name": "Software Engineering (with a Year in Industry)",
                    "university": "University of Leicester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-168"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "eb229da0a551cd54b9a5812279508f49",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "33807419d74d1e3b62d1f3ae5320290b",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accountancy and Finance",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Animal Behaviour and Welfare",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Animal Behaviour and Welfare",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "Master of Biosciences (with Honours) \u2013 MBio (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Animal Behaviour and Welfare with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Animation and Visual Effects",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Animation and Visual Effects with Arts Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Applied Pharmaceutical Science",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Applied Pharmaceutical Science with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BArch",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Architecture with Arts Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Banking and Finance",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "Master of Biosciences (with Honours) \u2013 MBio (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Biochemistry with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Biology",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "Master of Biosciences (with Honours) \u2013 MBio (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biology",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Biology with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Biomedical Engineering with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "Master of Biosciences (with Honours) \u2013 MBio (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Biomedical Science with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Bioveterinary Science",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Bioveterinary Science",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "Master of Biosciences (with Honours) \u2013 MBio (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Bioveterinary Science with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Business",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business and Finance",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business and Management",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business and Marketing",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business Economics",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Chemistry for Drug Discovery and Development",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Chemistry for Drug Discovery and Development",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Chemistry with Drug Discovery and Development with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MChem (H)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Chemistry with Drug Discovery and Development with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Chemistry with Education",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Chemistry with Education",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Chemistry with Education with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Chemistry with Education with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MChem (H)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Chemistry with Mathematics",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Chemistry with Mathematics",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Chemistry with Mathematics with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Chemistry with Mathematics with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MChem (H)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Chemistry with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MChem (H)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Chemistry with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Classical Studies",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MComp (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Computer Science with Artificial Intelligence",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Computer Science with Artificial Intelligence with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Computer Science with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Computer Science with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MComp (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Conservation of Cultural Heritage",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Advertising",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Advertising with Arts Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Dance",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Diagnostic Radiography",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Drama and English",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Drama, Theatre and Performance",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Ecology and Conservation",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Ecology and Conservation",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "Master of Biosciences (with Honours) \u2013 MBio (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Ecology and Conservation with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Education",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Education and Psychology",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Electrical and Electronic Engineering with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "English",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English and Creative Writing",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English and History",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Events Management",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Film & Television Studies",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Film Production",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Fine Art with Arts Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Forensic Chemistry",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Forensic Chemistry",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Forensic Chemistry with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Forensic Chemistry with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MChem (H)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Forensic Science with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Games Computing",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Games Computing",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Games Computing with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Games Computing with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MComp (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Games Computing with Virtual and Augmented Reality",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Games Computing with Virtual and Augmented Reality",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "General Engineering",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "General Engineering",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "General Engineering",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "General Engineering with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Graphic Design with Arts Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "History",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Human Geography",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "Master of Geography (with Honours) \u2013 MGeog (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Illustration",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Illustration with Arts Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Interior Architecture and Design",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Interior Architecture and Design with Arts Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "International Business Management",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "International Relations and Politics",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "International Tourism Management",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Journalism",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Journalism Studies",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Law",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Law and Criminology",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Marketing and Advertising",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MMath (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Mathematics and Computer Science",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Mathematics and Computer Science",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Mathematics and Theoretical Physics",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Mathematics and Theoretical Physics",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MMath (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mathematics and Theoretical Physics with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MMath (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Mathematics and Theoretical Physics with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Mathematics with Computer Science with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "Master in Science (with Honours) \u2013 MSci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Mathematics with Computer Science with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Mathematics with Philosophy",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Mathematics with Philosophy with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Mathematics with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MMath (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Mathematics with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Mechanical Engineering with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Mechatronics",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Mechatronics with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Media Production",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Media Studies",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Midwifery",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Modern History",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Musical Theatre",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Nursing (Registered Nurse - Adult)",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Nursing (Registered Nurse - Child)",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Nursing (Registered Nurse - Mental Health)",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Paramedic Science",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Pharmaceutical Science",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Pharmaceutical Science with Business Management",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Pharmaceutical Science with Business Management with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Pharmaceutical Science with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Pharmacy",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MPharm (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Pharmacy with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MPharm (H)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Photography",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Photography with Arts Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Physical Education and Sport",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Physical Geography",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "Master of Geography (with Honours) \u2013 MGeog (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "Master of Physics (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MPhys (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Physics with Astrophysics with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Physics with Astrophysics with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Physics with Philosophy",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "Master of Physics (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Physics with Philosophy",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Physics with Philosophy with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "Master of Physics (with Honours) \u2013 MPhys (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Physics with Philosophy with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Physics with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Physics with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "Master of Physics (with Honours) \u2013 MPhys (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Politics",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Product Design",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Product Design with Arts Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology (Sport and Exercise Psychology)",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology with Forensic Psychology",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology with Mental Health",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Robotics",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Robotics with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Social Work Practice",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sound and Music Production",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sport and Exercise Therapy",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sport Development and Coaching",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sports Business Management",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sports Journalism",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Strength and Conditioning in Sport",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Technical Theatre and Stage Management",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Zoology",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Zoology with Science Foundation Year",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Mechanical Engineering",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Technology and Applications",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agri-Food Technology",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agri-Food Technology with Professional Practice",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "14 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agricultural Science and Technology",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agricultural Science and Technology",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Analytical Sciences",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Behaviour and Welfare",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "PhD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Computer Science",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Mathematics",
                    "university": "University of Lincoln",
                    "location": "Multiple Locations",
                    "qualification": "PhD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "PhD",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "PhD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts and Cultural Management",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astrophysics",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "PhD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Banking and Finance",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry and Molecular Biology",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MPhil",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biodiversity, Conservation and Nature Recovery",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioveterinary Science",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "PhD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with Sustainability",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Animal Behaviour",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cloud Computing",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Physics",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation of Cultural Heritage",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation of Cultural Heritage",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MPhil (Res)",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation Studies",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "Grad Dip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Science and Management",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary Physiotherapy Practice",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Criminal Justice",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cultural Heritage Management",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Applied Analytics",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Developmental Psychology",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Materials and Battery Science",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Management",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment and Planetary Health",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Events Management",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Management",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Production",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Toxicology",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Development",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender Studies",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Governance",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Architecture and Design",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Architecture and Design with Professional Practice",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Corporate and Commercial Law",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Hospitality Management",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Sports Business Management",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tourism Management",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Human Resources",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Project Management",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Architecture (MArch)",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MArch",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communications",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval Studies",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Pre-Registration - Adult)",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Pre-registration - Child)",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Pre-Registration - Mental Health",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, Policy and Leadership",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological Research Methods",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics and Autonomous Systems",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Speech and Language Therapy (Pre-registration)",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Science",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Therapy",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Supply Chain and Logistics Management",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Accounting",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Mechanical Engineering",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Technology and Applications",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agri-Food Technology",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agricultural Science and Technology",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agricultural Science and Technology",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Analytical Sciences",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Behaviour and Welfare",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Computer Science",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Mathematics",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "PhD",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astrophysics",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry and Molecular Biology",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioveterinary Science",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with Sustainability",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Animal Behaviour",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Physics",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation of Cultural Heritage",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation of Cultural Heritage",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MPhil (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation Studies",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "Grad Dip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Science and Management",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Criminal Justice",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cultural Heritage Management",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Developmental Psychology",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Materials and Battery Science",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Management",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment and Planetary Health",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Events Management",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Management",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Production",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Toxicology",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Development",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender Studies",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Governance",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Architecture and Design",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Corporate and Commercial Law",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tourism Management",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Human Resources",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Project Management",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Architecture (MArch)",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MArch",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA Leadership",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MBA",
                    "duration": "2.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communications",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval Studies",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, Policy and Leadership",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological Research Methods",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics and Autonomous Systems",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Science",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Therapy",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Supply Chain and Logistics Management",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre",
                    "university": "University of Lincoln",
                    "location": "Lincoln (Main Site)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "22da2a25abc6e2302df0ce5f0606cd1f",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Arts and Sciences: Interdisciplinary Problems and Methods",
                    "university": "London Interdisciplinary School (LIS)",
                    "location": "The London Interdisciplinary School (LIS)",
                    "qualification": "BASc (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Interdisciplinary Problems and Methods",
                    "university": "London Interdisciplinary School (LIS)",
                    "location": "The London Interdisciplinary School (LIS)",
                    "qualification": "Master of Arts and Science (MASc)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        }
    },
    {
        "id": "f2220ad02d7e77b2a460e46e9f46479f",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "a3ecdc68db408f0762158fe278597b7d",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "3D for Games and Film with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Accounting and Finance with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Advertising, Public Relations and Branding",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Advertising, Public Relations and Branding with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Animation",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Animation with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Architectural Technology",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Banking and Finance",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Banking and Finance with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "111"
                },
                {
                    "course_name": "Biochemistry with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56-64"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biological Sciences with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "FD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biomedical Science with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56-64"
                },
                {
                    "course_name": "Business Accounting",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Accounting with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Computing and Data Analytics",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Computing and Data Analytics with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Economics",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Economics with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "FD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56-64"
                },
                {
                    "course_name": "Business Management",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business Management (2 year Top Up)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Business Management (Finance)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management (Finance) with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management (Human Resource Management)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business Management (Innovation & Entrepreneurship)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business Management (Marketing)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management (Marketing) with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56-64"
                },
                {
                    "course_name": "Business Management (T1)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "240"
                },
                {
                    "course_name": "Business Management with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56-64"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56-64"
                },
                {
                    "course_name": "Computer Systems Engineering",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Systems Engineering with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56-64"
                },
                {
                    "course_name": "Creative Writing and Journalism",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Criminology (Policing and Investigations)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Criminology with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Criminology with Psychology",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Cyber Security and Digital Forensics",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Cyber Security and Digital Forensics with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56-64"
                },
                {
                    "course_name": "Dance",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Design Engineering",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Design Engineering with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56-64"
                },
                {
                    "course_name": "Digital Media and Communications",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Digital Media and Communications with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "FdA",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Economics",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Economics with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Education Studies",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Electronic Engineering with Foundation year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56-64"
                },
                {
                    "course_name": "Environmental Health",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Environmental Health with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56-64"
                },
                {
                    "course_name": "Fashion",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fashion Communication and Styling",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fashion Communication and Styling with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Fashion Marketing",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fashion Marketing with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "FD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Fashion with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "FdA",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Film",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Film with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Financial Services (Top Up)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "240"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fine Art with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Football Coaching and Performance (Watford FC Community Sport and Education Trust)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Football Coaching and Performance with Foundation Year (Watford FC Community Sport and Education Trust)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56-64"
                },
                {
                    "course_name": "Football Development & Coaching",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Game and Level Design",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Game and Level Design with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Games Design and Development",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Games Design and Development with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Graphic Design with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Healthcare Science (Audiology)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Healthcare Science (Cardiac Physiology)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Illustration",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Illustration with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Information Systems (Top Up) (Online Distance Learning)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Information Technology",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Information Technology and Business Information System (Distance Learning) (Top Up)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Information Technology and Business Information Systems (Top Up)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Technology with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56-64"
                },
                {
                    "course_name": "Interior Architecture",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Interior Architecture with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Interior Design",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Interior Design with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "International Business",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Business Administration (Top Up)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "International Business with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "International Hospitality and Tourism Management (Top-up)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "International Tourism Management",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Tourism Management W/FY",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Law",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Law with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "LLB",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Law with International Relations",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Marketing",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Marketing with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Mechatronics and Robotics",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mechatronics and Robotics with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56-64"
                },
                {
                    "course_name": "Medical Physiology",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Medical Physiology with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56-64"
                },
                {
                    "course_name": "Medical Science",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Medical Science with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56-64"
                },
                {
                    "course_name": "Medical Science with Innovation and Enterprise",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Midwifery (Long)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Midwifery with Professional Registration (Shortened Programme for Registered Adult Nurses)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Neuroscience",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Neuroscience with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56-64"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing (Child)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing (Professional Practice) (Top Up)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Occupational Safety and Health Management (Top Up)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Pharmaceutical Sciences",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Pharmaceutical Sciences with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "FdSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56-64"
                },
                {
                    "course_name": "Photography",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Photography with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Primary Education (QTS)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Product Design",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Professional Arts Practice",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Psychology",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology  with Counselling Skills",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology with Counselling Skills with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56-64"
                },
                {
                    "course_name": "Psychology with Criminology",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Psychology with Criminology with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56-64"
                },
                {
                    "course_name": "Psychology with Education with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56-64"
                },
                {
                    "course_name": "Psychology with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56-64"
                },
                {
                    "course_name": "Psychology with Neuroscience",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology with Neuroscience with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56-64"
                },
                {
                    "course_name": "Public Health",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Public Health with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56-64"
                },
                {
                    "course_name": "Social Work",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "17 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Sociology with Criminology",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sociology with Criminology with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sociology with Psychology",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Sport and Exercise Rehabilitation",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport and Exercise Science (Physical Education and Coaching)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport and Exercise Science (Physical Education and Coaching) W/FY",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56-64"
                },
                {
                    "course_name": "Sport and Exercise Science with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56-64"
                },
                {
                    "course_name": "Sports and Exercise Rehabilitation with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "FdSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56-64"
                },
                {
                    "course_name": "Theatre",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Veterinary Nursing (College of Animal Welfare - Huntingdon)",
                    "university": "Middlesex University",
                    "location": "College of Animal Welfare - Huntington",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Veterinary Nursing (College of Animal Welfare - Leeds)",
                    "university": "Middlesex University",
                    "location": "College of Animal Welfare - Leeds",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Veterinary Nursing (College of Animal Welfare)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Veterinary Nursing (College of Animal Welfare) (Distance Education) (Top Up)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Veterinary Nursing with Foundation Year (College of Animal Welfare - Huntingdon)",
                    "university": "Middlesex University",
                    "location": "College of Animal Welfare - Huntington",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56-64"
                }
            ],
            "Part-time": [
                {
                    "course_name": "3D for Games and Film with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Advertising, Public Relations and Branding",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Accounting",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Computing and Data Analytics",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Computing and Data Analytics with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business Management (Innovation & Entrepreneurship)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Cyber Security and Digital Forensics",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Cyber Security and Digital Forensics with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "56-64"
                },
                {
                    "course_name": "Dance",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Digital Media and Communications",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fashion",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fashion Marketing",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fashion Marketing with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "FD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Fashion with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "FdA",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Financial Services (Top Up)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "240"
                },
                {
                    "course_name": "Football Coaching and Performance (Watford FC Community Sport and Education Trust)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Information Systems (Top Up) (Online Distance Learning)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Information Technology and Business Information System (Distance Learning) (Top Up)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "International Hospitality and Tourism Management (Top-up)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Law with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "LLB",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Medical Science with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "56-64"
                },
                {
                    "course_name": "Medical Science with Innovation and Enterprise",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Mental Health Nursing (Professional Practice) (top-up)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "300"
                },
                {
                    "course_name": "Midwifery (Professional Practice) (Top Up)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BMid (Hon)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "300"
                },
                {
                    "course_name": "Neuroscience with Foundation Year",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "56-64"
                },
                {
                    "course_name": "Pharmaceutical Sciences",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Professional Practice, Arts and Creative Industries (Specialisation)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Veterinary Nursing (College of Animal Welfare) (Distance Education) (Top Up)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "See entry requirements"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Applied Psychology",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts Management",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automation and Digital Manufacturing",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Banking and Finance",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science (Cellular Pathology)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science (Clinical Biochemistry)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science (Haematology and Transfusion Science)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science (Medical Immunology)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science (Medical Microbiology)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Branding and Marketing Communications",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Branding and Marketing Communications",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Building Information Modelling and Construction Technologies (Distance Education)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Building Information Modelling Management and Integrated Digital Delivery",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Building Information Modelling Management and Integrated Digital Delivery",
                    "university": "Middlesex University",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Information Systems Management",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Information Systems Management with Integrated Placement (15 months)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Information Systems Management with Integrated Placement (24 months)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer Biology and Therapeutics",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgDip (Initial Qualification)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cardiac Rhythm Management and Electrophysiology",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cardiac Rhythm Management and Electrophysiology",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cardiac Ultrasound",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cardiac Ultrasound",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cardiology",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children\u2019s Book Illustration and Graphic Novels",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Health Psychology and Wellbeing",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Neuroscience",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive and Clinical Neuroscience",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Neuroscience",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Networks and Network Design",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Integrated Placement (15 months)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Integrated Placement (24 months)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Technology",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Technology",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Theatre Technologies",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology with Forensic Psychology",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security and Pen Testing",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security and Pen Testing with Integrated Placement (15 months)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security and Pen Testing with Integrated Placement (24 months)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cybercrime and Digital Investigation",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science with Integrated Placement (15 months)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science with Integrated Placement (24 months)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Developmental Psychology in Action",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Forensics",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drug Design and Discovery",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with Integrated Placement (15 months)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with Integrated Placement (24 months)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Management",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Health",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Executive Master of Business Administration",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MBA (Executive)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Exercise and Physical Activity for Special Populations and Healthy Ageing",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Exercise and Physical Activity for Special Populations and Healthy Ageing",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Exercise and Physical Activity for Special Populations and Healthy Ageing (Distance Education)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Mathematics",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Governance and Sustainable Development",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Supply Chain Management",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Higher Education",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management and Development",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Rights Law",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Security Management",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Innovation Management and Entrepreneurship",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intelligent Telecommunications Engineering",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interiors (Architecture & Design)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Law",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management (Enhanced)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Hospitality and Event Management",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Investment and Finance",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal Practice (SQE)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing Management",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration (April)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration (January)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration (November)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration (Top-Up)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronic Systems Engineering",
                    "university": "Middlesex University",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media Management",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health and Substance Use (Dual Diagnosis)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health Studies (Top Up)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery Studies",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Sound for Screen",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Network Management and Cloud Computing",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Network Management and Cloud Computing with Integrated Placement (15 months)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "Master of Science (Hons)",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Network Management and Cloud Computing with Integrated Placement (24 months)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "Master of Science (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing Studies",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Health, Safety  and Environmental Management",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Health, Safety and Well-Being Management",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Photography",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Place Branding (Distance Education)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Printmaking",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Practice in Healthcare (Negotiated Specialism)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "Master of Science (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological Therapies and Interventions",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Conversion)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgDip",
                    "duration": "14 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Nutrition",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Psychology (BPS accredited)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Performance Analysis",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Rehabilitation",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Rehabilitation and Soft Tissue Therapy",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strength and Conditioning",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strength and Conditioning (DE)",
                    "university": "Middlesex University",
                    "location": "No Locations",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability and Environmental Management",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Telecommunication Engineering",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "User Experience Design",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Clinical Practice",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice  (Specialism)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice (Mental Health)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice (Mental Health)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice (Midwifery)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice (Midwifery)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice (Nursing)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice (Nursing)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice (Paediatrics and Child Health)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice (Primary Care Nursing)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice (Primary Care Nursing)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Professional Practice (Negotiated Specialism) MA/MSc/PGCert",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Social Work Practice",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Social Work Practice",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Social Work Practice",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Social Work Practice  (Practice Education)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Psychology",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Statistics",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts Management",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automation and Digital Manufacturing",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Banking and Finance",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Behavioural Economics",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science (Cellular Pathology)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science (Clinical Biochemistry)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science (Haematology and Transfusion Science)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science (Medical Immunology)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science (Medical Microbiology)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Branding and Marketing Communications",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Branding and Marketing Communications",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Branding and Marketing Communications",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Building Information Modelling and Construction Technologies (Distance Education)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Building Information Modelling Management and Integrated Digital Delivery",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Information Systems Management",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cardiac Rhythm Management and Electrophysiology",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cardiac Rhythm Management and Electrophysiology",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cardiac Rhythm Management and Electrophysiology",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cardiac Ultrasound",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cardiac Ultrasound",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cardiac Ultrasound",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cardiology",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children\u2019s Book Illustration and Graphic Novels",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Health Psychology and Wellbeing",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Neuroscience",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Physiology (Neurophysiology)",
                    "university": "Middlesex University",
                    "location": "No Locations",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Physiology (Neurophysiology)",
                    "university": "Middlesex University",
                    "location": "No Locations",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive and Clinical Neuroscience",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Neuroscience",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Networks and Network Design",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Technology",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Technology",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Theatre Technologies",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology with Forensic Psychology",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cybercrime and Digital Investigation",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Developmental Psychology in Action",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Forensics",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drug Design and Discovery",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (PGX30X)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with Integrated Placement (15 months)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Management",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Health",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Exercise and Physical Activity for Special Populations and Healthy Ageing",
                    "university": "Middlesex University",
                    "location": "Helicentre Aviation",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Exercise and Physical Activity for Special Populations and Healthy Ageing",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Exercise and Physical Activity for Special Populations and Healthy Ageing",
                    "university": "Middlesex University",
                    "location": "Helicentre Aviation",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Mathematics",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Governance and Sustainable Development",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Supply Chain Management",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Higher Education",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Rights Law",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Security Management",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Innovation Management and Entrepreneurship",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interiors (Architecture & Design)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Law",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Law",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Hospitality and Event Management",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Investment and Finance",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Operations Management (Top up)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "20 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing Management",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration (January)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronic Systems Engineering",
                    "university": "Middlesex University",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health and Substance Use (Dual Diagnosis)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health Studies",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health Studies (Top Up)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery Studies",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Business",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Network Management and Cloud Computing",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing Studies",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Health, Safety and Well-Being Management",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paediatric Enhanced Clinical Practice",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgCert",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paediatrics and Child Health Care",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgCert",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paediatrics and Child Health Care Practice",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paediatrics and Child Health Care Practice (Specialism)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paediatrics and Child Health Care Practice (Specialism)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgCert",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Photography",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Place Branding (Distance Education)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Printmaking",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Arts Practice (DL)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Practice in Healthcare (Negotiated Specialism)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "Master of Science (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Practice, Artistic Practitioner",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Practice, Creative Industries Professional",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Practice, Dance (specialisation)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Practice, Dance Technique Pedagogy",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Practice, Somatic Studies",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Conversion)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work (Top Up)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MA",
                    "duration": "5 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Psychology (BPS accredited)",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Performance Analysis",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Rehabilitation",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Rehabilitation and Soft Tissue Therapy",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strength and Conditioning",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability and Environmental Management",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Telecommunication Engineering",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "User Experience Design",
                    "university": "Middlesex University",
                    "location": "Hendon Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "f4899d9078aa32115b24918c316d2823",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agri-Business Management",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agriculture",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agriculture with Farm Business Management",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History and Archaeology",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History and History",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Science",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture and Urban Planning",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bachelor of Laws in Global Legal Studies with Honours",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "152-168"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Genetics",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Genetics",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences (Deferred Choice)",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences (Integrated Masters)",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "BSc Honours Computer Science (Cyber Security)",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "BSc Honours Computer Science with Industrial Placement (Cyber Security)",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business, Accounting and Finance (with Work Placements)",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering (Industry)",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Medicinal Chemistry",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Medicinal Chemistry (MChem)",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese or Japanese Studies",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil and Structural Engineering",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil and Structural Engineering",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil and Structural Engineering with Year in Industry",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil and Structural Engineering with Year in Industry",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering (4 years)",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering (5 years)",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with Year in Industry",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with Year in Industry",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies and English",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Combined Honours",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Game Engineering)",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Software Engineering)",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Industrial Placement",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Industrial Placement (Game Engineering)",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Industrial Placement (Software Engineering)",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary and Popular Music",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dental Therapy",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dentistry",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BDS",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dietetics",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "MDiet",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Business Management",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering (5 years)",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering (with Industrial Project)",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronics and Computer Engineering",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronics and Computer Engineering (with Industrial Project)",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Foundation year",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "Fd cert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Literature",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and History",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature with Creative Writing",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Media",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Practices",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Folk and Traditional Music",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Business Management and Marketing",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography and Urban Planning",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Archaeology",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Nutrition",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Nutrition with Professional Placement",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management with Placement",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Journalism, Media and Culture",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics with French",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics with German",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics with Japanese/Chinese",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics with Spanish",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Biology",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Zoology",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing and Management",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "MMath",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Accounting",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Economics",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Statistics",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Statistics Foundation year",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "Fd cert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Business",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Finance",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media, Communication and Cultural Studies",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and Business Studies",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and International Relations",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and Linguistics",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages, Translation and Interpreting",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BMus (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Naval Architecture and Marine Engineering",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Naval Architecture and Marine Engineering",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-160"
                },
                {
                    "course_name": "Nutrition with Food Marketing",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nutrition with Food Marketing with Professional Placement",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology (Integrated Masters)",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "MPharm (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physician Associate Studies (Postgraduate)",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics Foundation Year",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "Fd cert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiological Sciences",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiological Sciences (Integrated Masters)",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Planning",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "MPlan",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Economics",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and History",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Politics and Sociology",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish, Portuguese and Latin American Studies",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Speech and Language Sciences",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "MSc (UG)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Speech and Language Therapy",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Land and Business Management",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Planning",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Maritime Engineering",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability Management",
                    "university": "Newcastle University",
                    "location": "Main Site (Newcastle)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        }
    },
    {
        "id": "ecea16b880ffa312f84bf54cf83ba492",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Accounting and Finance (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Acting",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Acting for Contemporary and Devised Performance",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Adult Nursing",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Advertising & Digital Marketing",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Advertising & Digital Marketing (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Animation",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Animation (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Architectural Technology",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Architectural Technology (top-up)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Technology (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Artificial Intelligence and Data Science",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Artificial Intelligence and Data Science (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biochemistry (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Biological Science",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biological Science with Foundation Year",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biomedical Science (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Computing",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Computing (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Children and Young People's Nursing",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Commerce and International Business (top-up)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Networks Engineering",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Networks Engineering (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science (top up)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Creative Film, Television and Digital Media Production",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Creative Film, Television and Digital Media Production (top-up)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Film, Television and Digital Media Production (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Criminal and Corporate Investigation",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminal and Corporate Investigation (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Criminology with Psychology",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology with Psychology (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Dental Nursing",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Early Childhood Studies (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Economics (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Education Studies",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Education Studies (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Electronics and Computer Engineering",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Electronics and Computer Engineering (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Engineering",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Engineering (Top-Up)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "English",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Environmental Science (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Esports",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "ESports (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Events Management",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Events Management (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Fashion Marketing and Promotion",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fashion Marketing and Promotion (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Fashion, Textiles, Footwear & Accessories",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fashion, Textiles, Footwear & Accessories (top-up)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion, Textiles, Footwear & Accessories (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Film and Screen Studies",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Film and Screen Studies (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Finance",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fine Art (top-up)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Fine Art Painting & Drawing",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fine Art Painting and Drawing (top-up)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art Painting and Drawing (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Forensic Psychology (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Games Art",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Games Art (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Games Design",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Games Design (top-up)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Design (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Games Programming",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Games Programming (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Geography (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Global Business Management (top-up)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Communication Design",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Graphic Communication Design (top-up)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Communication Design (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Hair, Make-up and Prosthetics for Stage and Screen",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Hair, Make-up and Prosthetics for Stage and Screen (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Health and Social Care Practice",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Health and Social Care Practice (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Health Sciences",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Health Sciences (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "History",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "History (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Human Resource Management (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Illustration",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Illustration (top-up)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Illustration (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Interior Architecture & Spatial Design",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Interior Architecture & Spatial Design (top-up)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Architecture and Spatial Design (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "International Accounting (top-up)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Banking and Finance (Top-Up)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Business Management (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "International Education (top up)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Finance (Top-Up)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tourism & Hospitality Management (top-up)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tourism Management",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Tourism Management (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Law",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Law (two-year accelerated)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Law (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Law with Criminology (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Learning and Teaching",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Learning and Teaching (SEND and Inclusive Provision)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Learning and Teaching (top-up)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Learning Disability Nursing",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Marketing (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Marketing Management (top-up)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "MEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mechanical Engineering (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Mental Health Nursing",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Midwifery",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Multimedia Journalism",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Multimedia Journalism (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Multimedia Sports Journalism",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Multimedia Sports Journalism (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Music Production",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Music Production (top-up)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Production (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Nursing Associate",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Occupational Therapy (extended)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Paramedic Science",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Pharmacology (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Photography",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Photography (top-up)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Photography (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Physical Education and Sport",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Physical Education and Sport (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "132"
                },
                {
                    "course_name": "Podiatry",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Politics and International Relations (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Popular Music",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Popular Music (top-up)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Popular Music (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Primary Education 5-11 (QTS)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Product Design",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Product Design (top-up)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Psychology (Counselling)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Psychology (Counselling) with Foundation Year",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Psychology (Developmental and Educational)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Psychology (Developmental and Educational) with Foundation Year",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Psychology (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sociology (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Software Engineering (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sport and Exercise Psychology",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Sport and Exercise Psychology (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport and Exercise Science (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sport Coaching",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport Coaching (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sport Management & Leadership",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport Management and Leadership (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sport Rehabilitation and Conditioning",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport Rehabilitation and Conditioning (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sustainable Logistics and Supply Chain Management",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sustainable Logistics and Supply Chain Management (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL) (Top-Up)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Web Development and Cyber Security",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Web Development and Cyber Security (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Working with Children, Young People and Families",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Working with Children, Young People and Families (with Foundation Year)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Northampton",
                    "location": "Waterside",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Design and Manufacturing",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Occupational Therapy",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "BIM-Enabled Sustainable Buildings",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration (with Work Placement)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration - Finance",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Child and Adolescent Mental Health",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing",
                    "university": "University of Northampton",
                    "location": "Waterside",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing (Computer Networks Engineering)",
                    "university": "University of Northampton",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing (Internet Technology and Security)",
                    "university": "University of Northampton",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing (Software Engineering)",
                    "university": "University of Northampton",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal and Corporate Investigations and Intelligence",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design, Communication and Experiences",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Northampton",
                    "location": "Waterside",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Mental Health Practitioner",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English - Contemporary Literature",
                    "university": "University of Northampton",
                    "location": "Waterside",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial and Investment Analysis",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Northampton",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Northampton",
                    "location": "Waterside",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Architecture",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management",
                    "university": "University of Northampton",
                    "location": "Waterside",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Hotel Management",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Marketing Strategy",
                    "university": "University of Northampton",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Northampton",
                    "location": "Waterside",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tourism Development",
                    "university": "University of Northampton",
                    "location": "Waterside",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leather Technology",
                    "university": "University of Northampton",
                    "location": "Waterside",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Logistics & Supply Chain Management",
                    "university": "University of Northampton",
                    "location": "Waterside",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular Medicine",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy (pre-registration)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Northampton",
                    "location": "Waterside",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "University of Northampton",
                    "location": "Waterside",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Special Educational Needs and Inclusion",
                    "university": "University of Northampton",
                    "location": "Waterside",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Special Educational Needs and Inclusion (Autism Pathway)",
                    "university": "University of Northampton",
                    "location": "Waterside",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Public Health Nursing",
                    "university": "University of Northampton",
                    "location": "Waterside",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strength and Conditioning",
                    "university": "University of Northampton",
                    "location": "Waterside",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL)",
                    "university": "University of Northampton",
                    "location": "Waterside",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Clinical Practice",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "MSc (PG)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Design and Manufacturing",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Occupational Therapy",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advancing Practice",
                    "university": "University of Northampton",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "BIM-Enabled Sustainable Buildings",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration",
                    "university": "University of Northampton",
                    "location": "Waterside",
                    "qualification": "DBA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Child and Adolescent Mental Health",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing",
                    "university": "University of Northampton",
                    "location": "Waterside",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing (Computer Networks Engineering)",
                    "university": "University of Northampton",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing (Internet Technology and Security)",
                    "university": "University of Northampton",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing (Software Engineering)",
                    "university": "University of Northampton",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling",
                    "university": "University of Northampton",
                    "location": "Waterside",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling Children and Young People",
                    "university": "University of Northampton",
                    "location": "Waterside",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal and Corporate Investigations and Intelligence",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design, Communication and Experiences",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Northampton",
                    "location": "Waterside",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English - Contemporary Literature",
                    "university": "University of Northampton",
                    "location": "Waterside",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Northampton",
                    "location": "Waterside",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Northampton",
                    "location": "Waterside",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Architecture",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leather Technology",
                    "university": "University of Northampton",
                    "location": "Waterside",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular Medicine",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Practice Education",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Practice in Health and Social Care",
                    "university": "University of Northampton",
                    "location": "Waterside",
                    "qualification": "DProf",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Northampton",
                    "location": "Waterside",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "University of Northampton",
                    "location": "Waterside",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Special Educational Needs and Inclusion",
                    "university": "University of Northampton",
                    "location": "Waterside",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Special Educational Needs and Inclusion (Autism Pathway)",
                    "university": "University of Northampton",
                    "location": "Waterside",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Public Health Nursing (top up)",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specific Learning Difficulties and Inclusion",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strength and Conditioning",
                    "university": "University of Northampton",
                    "location": "Waterside",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Systemic Family Practice",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL)",
                    "university": "University of Northampton",
                    "location": "Waterside",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Transformational Leadership",
                    "university": "University of Northampton",
                    "location": "Waterside Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "4bb813a3f020aa58b8a58eade4024e32",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Aerospace Engineering (with Foundation Year)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Agriculture",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Agriculture",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Agriculture (with foundation year)",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Animal Biology",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Animal Biology",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Animal Biology (with foundation year)",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Animation",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - Nottingham",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Animation",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Applied Sport Science (top-up)",
                    "university": "Nottingham Trent University",
                    "location": "Mansfield",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Technology",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Architectural Technology",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Architectural Technology (with Foundation Year)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Architecture",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MArch",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BArch (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Architecture (with Foundation Year)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BArch (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Artisan Food Production",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Artisan Food Production",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Artisan Food Production",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "BA (Hons) Furniture and Product Design (with Foundation Year) FT",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Biochemistry (with foundation year)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Biological Science (with foundation year)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Biomedical Engineering (with Foundation Year) FT",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Biomedical Science (with foundation year)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Biomedical Sciences",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Biomedical Sciences",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Broadcast Journalism",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "BSc (Hons) Product Design (with Foundation Year) FT",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Building Surveying",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Building Surveying",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business",
                    "university": "Nottingham Trent University",
                    "location": "Mansfield",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business (with Foundation Year)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Law",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Business Management (Accelerated)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Business Management and Accounting and Finance",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business Management and Accounting and Finance",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business Management and Economics",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business Management and Economics",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business Management and Entrepreneurship",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business Management and Entrepreneurship",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business Management and Human Resources",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business Management and Human Resources",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business Management and Marketing",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business Management and Marketing",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business Management and Sustainability\u00a0FT",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business Management and Sustainability\u00a0SW",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Chemistry (with Foundation Year)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Chemistry with Secondary Education (QTS)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Childhood and Psychology",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Childhood and Society",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Childhood: Health and Wellbeing",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Civil Engineering Design and Construction",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Civil Engineering Design and Construction",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Communications and English",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Communications and Film & Television",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Communications and History",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Communications and Linguistics",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Communications and Philosophy",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MCompu (H)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MComp (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Science (Artificial Intelligence)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Science (Artificial Intelligence)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Science (Artificial Intelligence) (with Foundation Year)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Computer Science (Games Technology)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Science (Games Technology)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Science (Games Technology) (with foundation year)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Computer Science (with Foundation Year)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Computer Science and Mathematics",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Science and Mathematics",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Science and Mathematics (with Foundation Year) FT",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Computing",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Computing",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Computing (with foundation year)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Computing with Secondary Education (QTS)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Construction Management",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Construction Management",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-104"
                },
                {
                    "course_name": "Content Creation",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - Nottingham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Content Creation (London)",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Content Creation (with Foundation Year) London",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Costume Design and Construction",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Creative Direction and Curation for Fashion",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Criminal Justice",
                    "university": "Nottingham Trent University",
                    "location": "Mansfield",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Criminal Justice (Top-up)",
                    "university": "Nottingham Trent University",
                    "location": "Mansfield",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Cyber Security (with foundation year)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Data Science",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Data Science",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Data Science (with Foundation Year)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Design for Film and Television",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Design for Theatre and Live Performance",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Digital Photorgraphy",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - Nottingham",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Early Childhood Education and Care: Leadership and Management",
                    "university": "Nottingham Trent University",
                    "location": "Mansfield",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Ecology and Conservation",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Ecology and Conservation",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Ecology and Conservation (with foundation year)",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Economics",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Economics",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Economics with Business",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Economics with Business",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Economics with International Finance and Banking",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Economics with International Finance and Banking",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Education",
                    "university": "Nottingham Trent University",
                    "location": "Mansfield",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Education and Professional Practice (Top-up)",
                    "university": "Nottingham Trent University",
                    "location": "Mansfield",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Education Studies and Psychology",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Electrical and Electronic Engineering for England",
                    "university": "Nottingham Trent University",
                    "location": "Mansfield",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering for England",
                    "university": "Nottingham Trent University",
                    "location": "Mansfield",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Electronic and Electrical Engineering (with foundation year) FT",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Electronic and Electrical Engineering FT",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Electronic and Electrical Engineering FT",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Electronic and Electrical Engineering SW",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Electronic and Electrical Engineering SW",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Engineering Management (top-up)",
                    "university": "Nottingham Trent University",
                    "location": "Mansfield",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "English and Film & TV",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "English and History",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "English and Media",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "English and Philosophy",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "English Language and Linguistics",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "English Language and Linguistics",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "English with Secondary Education (QTS)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Environmental Science (with foundation year)",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Equine Sports Science",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Equine Sports Science",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Equine Sports Science (with foundation year)",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Esports Production",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - Nottingham",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Esports Production (London)",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Esports Production (with Foundation Year) London",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Event Management",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - Nottingham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Event Management (London)",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Event Management (with Foundation Year) London",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Event Production",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - Nottingham",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Event Production",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - Nottingham",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Event Production (London)",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Event Production (with Foundation Year) London",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Fashion Communication and Promotion",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Fashion Design",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Fashion Knitwear Design and Knitted Textiles",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Fashion Knitwear Design and Knitted Textiles",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Fashion Management",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Fashion Management",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Fashion Marketing and Branding",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Fashion Photography FT",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Film & TV and History",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Film & TV and Philosophy",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Film Production Technology",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - Nottingham",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Film Technology",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Filmmaking",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Financial Mathematics",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Financial Mathematics",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Financial Mathematics (with Foundation Year) FT",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Forensic Chemistry",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MChem (H)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Forensic Chemistry",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Forensic Chemistry",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Forensic Chemistry",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "Master in Science (with Honours) \u2013 MSci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Forensic Science (with foundation year)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Furniture and Product Design",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Furniture and Product Design",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Future Homes Design and Construction",
                    "university": "Nottingham Trent University",
                    "location": "Mansfield",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Games Art",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Games Art",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - Nottingham",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Games Design",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Games Technology",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - Nottingham",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Geography",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Geography",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Geography (with foundation year)",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Graphics & Digital Design FT",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - Nottingham",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "History",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "History and Linguistics",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "History and Philosophy",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "History with International Relations",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "History with Politics",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Illustration",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - Nottingham",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Illustration FT",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Interior Architecture and Design",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Interior Architecture and Design",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Interior Architecture and Design (with Foundation Year)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "International Business",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "International Business",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "International Business with French",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "International Business with Spanish",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "International Fashion Business",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "International Relations",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "International Relations",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Journalism",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Law",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Law (Sandwich)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Law Senior Status",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Business",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Law with Psychology",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Linguistics and Media",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Linguistics and Philosophy",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Manufacturing Engineering for England",
                    "university": "Nottingham Trent University",
                    "location": "Mansfield",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Manufacturing Engineering for England",
                    "university": "Nottingham Trent University",
                    "location": "Mansfield",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Marketing",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MMath (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MMath (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Mathematics (with Foundation Year)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Mathematics and Physics",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Mathematics and Physics",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Mathematics and Physics (with Foundation Year) FT",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Mathematics with Data Science (with Foundation Year)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Mathematics with Data Science FT",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mathematics with Data Science SW",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mathematics with Secondary Education (QTS)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Mathematics with Statistics (with foundation year) FT",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Mathematics with Statistics FT",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Mathematics with Statistics SW",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Mechanical Engineering (with Foundation Year) FT",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BEng",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Mechanical Engineering for England",
                    "university": "Nottingham Trent University",
                    "location": "Mansfield",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering for England",
                    "university": "Nottingham Trent University",
                    "location": "Mansfield",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Media and Film & TV",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Media and Philosophy",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Media Communications and Culture",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Media Production",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Medical Physics",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Medical Physics",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Medicinal Chemistry",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MChem",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Medicinal Chemistry",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Medicinal Chemistry",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Medicinal Chemistry",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MChem",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Microbiology",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Microbiology",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Microbiology (with foundation year)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Modern Methods of Construction for England",
                    "university": "Nottingham Trent University",
                    "location": "Mansfield",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Motion Graphics",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Music Business",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - Nottingham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Music Business (London)",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Music Business (with Foundation Year) London",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Music Performance",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - Nottingham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Music Performance",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - Nottingham",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Music Performance  (London)",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Music Performance (with Foundation Year) London",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Music Production",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - Nottingham",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Music Production",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - Nottingham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Music Production (London)",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Music Production (with Foundation Year) London",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "Nottingham Trent University",
                    "location": "Mansfield",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Nursing (Learning Disabilities)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "Nottingham Trent University",
                    "location": "Mansfield",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Paramedic Science",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Pharmacology (with foundation year)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Photography",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Physics",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Physics",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Physics",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Physics",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Physics (with Foundation Year)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Physics with Nuclear Technology",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Physics with Nuclear Technology",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Physics with Secondary Education (QTS)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Politics",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Politics",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Primary Education (QTS)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Product Design",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Product Design",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Product Design",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Product Design",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Product Design (with Foundation Year) FT",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Property Development and Planning",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Property Development and Planning",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Property Finance and Investment",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Property Finance and Investment",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Psychology",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Psychology",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Psychology (Educational and Developmental Psychology)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Psychology (Educational and Developmental Psychology)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Psychology (Forensic Psychology)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Psychology (Forensic Psychology)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Psychology (Mental Health)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Psychology (Mental Health)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Psychology with Counselling",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Psychology with Counselling",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Psychology with Criminology",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Psychology with Criminology",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Psychology with Sociology",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Psychology with Sociology",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Quantity Surveying and Commercial Management",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Quantity Surveying and Commercial Management",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Real Estate",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Real Estate",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Social Work",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Sociology",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Sociology and Criminology",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Software Engineering (with foundation year)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sound Engineering and Audio Production",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - Nottingham",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Sound Engineering and Audio Production",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - Nottingham",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Sound Engineering and Audio Production (London)",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Sound Engineering and Audio Production (with Foundation Year) London",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Special Educational Needs, Disability and Inclusion",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "Nottingham Trent University",
                    "location": "Mansfield",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sport and Exercise Science (with foundation year)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sport Engineering",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Sport Engineering",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Sport Engineering",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Sport Engineering",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Sport Engineering (with Foundation Year) FT",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BEng",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sport Science and Coaching",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Sport Science and Coaching",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Sport Science and Coaching (with foundation year)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sport Science and Management",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Sport Science and Management",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Sport Science and Management (with foundation year)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sport Science and Mathematics",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Sport Science and Mathematics",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Sport Science and Mathematics (with Foundation Year) FT",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sport Science, Health and Nutrition",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Sport Science, Health and Nutrition",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Sport Science, Health and Nutrition with foundation year)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sports Photography FT",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Television Production",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Television Production Technology",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - Nottingham",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Textile Design",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Veterinary Nursing",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Veterinary Nursing Science (top-up)",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Virtual Production",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - Nottingham",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Virtual Production (London)",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Virtual Production (with Foundation Year) London",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Visual Effects Art",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Visual Effects Production Technology",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - Nottingham",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Visual Effects Technical Direction",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Wildlife Conservation",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Wildlife Conservation",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Wildlife Conservation (with foundation year)",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Working with Children, Young People and Families",
                    "university": "Nottingham Trent University",
                    "location": "Mansfield",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Working with Children, Young People and Families (Top-up)",
                    "university": "Nottingham Trent University",
                    "location": "Mansfield",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Youth Justice",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Zoo Biology",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Zoo Biology",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Zoo Biology (with foundation year)",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Zoology",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Zoology",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Zoology (with foundation year)",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Advertising and Marketing Communications",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Analytical Chemistry",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animation",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Child Psychology",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Ecology and Geospatial Techniques",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Barristers Training Course (PGDip)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "PgDip",
                    "duration": "7 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biodiversity Conservation",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Broadcast Journalism",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Building Surveying",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Transformation",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer Biology",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cell Biology",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry/Chemistry (Professional Practice)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cloud and Enterprise Computing",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Management",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Content Creation - Confetti London",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Content Creation - Confetti Nottingham",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - Nottingham",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Corporate Law",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Technologies",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyberpsychology",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design: Products and Furniture",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design: Products and Technology",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Architecture and Construction",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Finance and Data Analytics",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics, Banking and Finance",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Communications Engineering",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Endangered Species Recovery and Conservation",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Management",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literary Research",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Equine Health and Welfare",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Equine Performance",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Equine Performance, Health and Welfare",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Esports Production - Confetti London",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Esports Production - Confetti Nottingham",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - Nottingham",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Event Management - Confetti London",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Event Management - Confetti Nottingham",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - Nottingham",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Event Production - Confetti London",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Event Production - Confetti Nottingham",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - Nottingham",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion and Creative Pattern Cutting",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA (PG)",
                    "duration": "45 weeks",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Communications",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Knitwear",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Marketing",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Filmmaking",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Accounting",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Investment Banking",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MFA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "FinTech and Financial Markets",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Mental Health",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Further Education and Skills",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "CertEd",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Media and Communication",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Communication",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Law",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Rights",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Illustration",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Innovation Management and Entrepreneurship",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Architecture and Design",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business (Dual Award)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business (Single Award)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Energy Law",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Environmental Law",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Fashion Management",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law Enforcement",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Security",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics (by Research)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Luxury Fashion Brand Management",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Magazine Journalism",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Business Analytics)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Global Supply Chain Management)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (International Business)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Sustainable Leadership)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing and Brand Management",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing and Data Analytics",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration (MBA)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Laws (General)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "LL.M",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Material and Technology Futures",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical Sciences",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Engineering",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Imaging",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular Cell Biology",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular Microbiology",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Museum and Heritage Development",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Business - Confetti London",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Business - Confetti Nottingham",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - Nottingham",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuropharmacology",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Learning Disabilities)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Psychology",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paramedic Science",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Analysis",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical and Medicinal Science",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy (by Research)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Photography",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Public Policy",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Property Development and Planning",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological Research Methods",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological Well Being and Mental Health",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Policy",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantity Surveying",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Security Studies",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Smart Agriculture",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sound Engineering & Audio Production - Confetti London",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sound Engineering & Audio Production - Confetti Nottingham",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - Nottingham",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Law",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "SQE 1 and SQE 2 Preparation Course (LLM)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "SQE 1 Preparation Course (LLM)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "SQE 1 Preparation Course (PGDip)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "PgDip",
                    "duration": "6 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "SQE 2 Preparation Course (PGCert)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "PgCert",
                    "duration": "3 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Engineering with Management",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Engineering with Materials",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Engineering: Energy",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Technology Law",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "TESOL (Teaching English to Speakers of Other Languages)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Textiles",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Virtual Production - Confetti London",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Virtual Production - Confetti Nottingham",
                    "university": "Nottingham Trent University",
                    "location": "Confetti - Nottingham",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Youth Work Leadership and Practice",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Analytical Chemistry",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Child Psychology",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Ecology and Geospatial Techniques",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biodiversity Conservation",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Broadcast Journalism",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Building Surveying",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer Biology",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Career Guidance Practice",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "PgCert",
                    "duration": "0.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cell Biology",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry/Chemistry (Professional Practice)",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cloud and Enterprise Computing",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Management",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Corporate Law",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyberpsychology",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design: Products and Furniture",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design: Products and Technology",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MA",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education and Early Childhood",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Leadership & Management",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Endangered Species Recovery and Conservation",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literary Research",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Equine Health and Welfare",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Equine Performance",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Equine Performance, Health and Welfare",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Executive Masters of Business Administration",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MBA (Executive)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion and Textiles",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Mental Health",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Further Education and Skills",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "CertEd",
                    "duration": "2 years",
                    "study_mode": "Part-time evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Media and Communication",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Law",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Rights",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Energy Law",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Environmental Law",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Security",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics (by Research)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Magazine Journalism",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Laws (General)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "LL.M",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular Cell Biology",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular Microbiology",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Museum and Heritage Development",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuropharmacology",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Psychology",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Analysis",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical and Medicinal Science",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy (by Research)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Public Policy",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Property Development and Planning",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological Research Methods",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological Well Being and Mental Health",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Policy",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantity Surveying",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Security Studies",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Smart Agriculture",
                    "university": "Nottingham Trent University",
                    "location": "Brackenhurst Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "Nottingham Trent University",
                    "location": "Clifton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Special & Inclusive Education",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Law",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "SQE 1 and SQE 2 Preparation Course (LLM)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time weekend",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "SQE 1 Preparation Course (LLM)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time weekend",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "SQE 1 Preparation Course (PGDip)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Part-time weekend",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "SQE 2 Preparation Course (PGCert)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "PgCert",
                    "duration": "6 months",
                    "study_mode": "Part-time weekend",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Engineering with Management",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Engineering with Materials",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Technology Law",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "TESOL (Teaching English to Speakers of Other Languages)",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Youth Work Leadership and Practice",
                    "university": "Nottingham Trent University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "a4708b09d57ee1b955432fdae5074d91",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accountancy",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering including an Industrial Year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering including an Industrial Year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agricultural Business Management",
                    "university": "University of Nottingham",
                    "location": "Sutton Bonington Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agriculture",
                    "university": "University of Nottingham",
                    "location": "Sutton Bonington Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "American & Canadian Studies (Study Abroad)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "American Studies and English",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "American Studies and History",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History and Archaeology",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History and History",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Science",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Science",
                    "university": "University of Nottingham",
                    "location": "Sutton Bonington Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and Geography",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and History",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Environment Engineering",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Environment Engineering",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Environment Engineering including an Industrial Year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Environment Engineering including an Industrial Year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BArch (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture and Environmental Design",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History and Visual Culture",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts and Humanities with Foundation Year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology",
                    "university": "University of Nottingham",
                    "location": "Sutton Bonington Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology",
                    "university": "University of Nottingham",
                    "location": "Sutton Bonington Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "BSc Physics with Year in Computer Science",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer Sciences",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer Sciences",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering including an Industrial Year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering including an Industrial Year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with Environmental Engineering",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with Environmental Engineering",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with Environmental Engineering including an Industrial Year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with Environmental Engineering including an Industrial Year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with a Year in Industry",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with an International Study Year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering including an Industrial Year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering including an Industrial Year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Civilisation",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Civilisation and Philosophy",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and English",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Artificial Intelligence with Year in Industry",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science including International Year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Artificial Intelligence",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Artificial Intelligence",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Artificial Intelligence including International Year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Year in Industry",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cultural and Creative Industries",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Econometrics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and International Economics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with French",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with German",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Hispanic Studies",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical & Electronic Engineering",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering including an Industrial Year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering including an Industrial Year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering including an Industrial Year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering with a Year Abroad",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering with a Year Abroad",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Engineering",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Engineering",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Engineering with a Year Abroad",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Engineering with a Year Abroad (Year 2 out)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Engineering with a Year Abroad (Year 3 out)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Engineering with a Year in Industry",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Engineering with a Year in Industry (Yr 3 out)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Engineering with a Year in Industry (Yr 4 out)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Computer Engineering",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Computer Engineering",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Computer Engineering with a Year Abroad",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Computer Engineering with a Year Abroad (Year 2 out)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Computer Engineering with a Year Abroad (Year 3 out)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Computer Engineering with a Year in Industry",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Computer Engineering with a Year in Industry (Year 3 out)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Computer Engineering with a Year in Industry (Year 4 out)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with a Year Abroad",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with a Year Abroad (Year 2 out)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with a Year Abroad (Year 3 out)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with a Year in Industry",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with a Year in Industry (Year 3 out)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with a Year in Industry (Year 4 out)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering and Physical Sciences with Foundation Year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and French",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Hispanic Studies",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and History",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Philosophy",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Literature",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English with Creative Writing",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Biology",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Biology",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Engineering",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Engineering",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Engineering including an Industrial Year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Engineering including an Industrial Year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Screen Studies",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Studies and American Studies",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance, Accounting and Management",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance, Accounting and Management with a Placement Year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Mathematics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Science",
                    "university": "University of Nottingham",
                    "location": "Sutton Bonington Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Science and Nutrition",
                    "university": "University of Nottingham",
                    "location": "Sutton Bonington Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and History",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and International Media and Communications Studies",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Philosophy",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French Studies",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography BA",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography BSc",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with Business BA",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and History",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Promotion and Public Health",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Sciences with Foundation Year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "Fd cert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies and History",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Contemporary Chinese Studies",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and East European Cultural Studies",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Philosophy BA",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Politics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art and English",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Industrial Economics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Industrial Economics with a Placement Year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Industrial Economics with Insurance",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Industrial Economics with Insurance with a Placement Year",
                    "university": "University of Nottingham",
                    "location": "Nottingham",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Media and Communications Studies",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Asian Studies",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Security Studies",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with French and French Law",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Spanish and Spanish Law",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Liberal Arts",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with a Placement Year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Manufacturing Engineering",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Manufacturing Engineering",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Manufacturing Engineering including an Industrial Year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Manufacturing Engineering including an Industrial Year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical Physics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical Physics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MMath",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics (International Study)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Economics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with a Year in Industry",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MMath (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with a Year in Industry",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering Including an Industrial year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering including an Industrial Year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering including an Integrated Study Abroad Year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering including an Integrated Study Abroad Year (Yr 2 abroad)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering including an Integrated Study Abroad Year (Yr 3 abroad)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Physiology and Therapeutics",
                    "university": "University of Nottingham",
                    "location": "Derby",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicinal and Biological Chemistry",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicinal and Biological Chemistry",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicinal and Biological Chemistry with an Assessed Year in Industry",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BMBS",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine (Graduate Entry)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BMBS",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine at Lincoln",
                    "university": "University of Nottingham",
                    "location": "Lincoln",
                    "qualification": "BMBS",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine with a Foundation Year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BMBS",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology",
                    "university": "University of Nottingham",
                    "location": "Sutton Bonington Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern European Studies",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Language Studies",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages with Business",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages with Translation",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSci Physics with Year in Computer Science",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "144-153"
                },
                {
                    "course_name": "Music",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Music Technology",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Philosophy",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Sciences",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Sciences",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Sciences with International Study",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Sciences with International Study",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Children)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nutrition",
                    "university": "University of Nottingham",
                    "location": "Sutton Bonington Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nutrition and Dietetics",
                    "university": "University of Nottingham",
                    "location": "Sutton Bonington Campus",
                    "qualification": "MNutr",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Sciences",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Sciences (with a Year in Industry)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MPharm (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy with Integrated Foundation Training MPharm",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MPharm (H)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Psychology",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Theology",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy, Politics and Economics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Medical Physics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Medical Physics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Theoretical Astrophysics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Theoretical Astrophysics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Theoretical Physics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Theoretical Physics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Plant Biology",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Plant Biology",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and American Studies",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Economics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Philosophy",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design and Manufacture",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design and Manufacture",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design and Manufacture including an Industrial Year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design and Manufacture including an Industrial year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design and Manufacture including an Integrated Study Abroad Year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design and Manufacture including an Integrated Study Abroad Year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Cognitive Neuroscience",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religion, Culture and Ethics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religion, Philosophy and Ethics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian and Contemporary Chinese Studies",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian Studies",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science with Foundation Year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSci",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science with Foundation Year (Integrated Honours Programme)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work (postgraduate entry)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and International Media and Communications Studies",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Rehabilitation",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religious Studies",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Medicine and Surgery",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BVMBVS",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Medicine and Surgery including a Gateway Year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BVMBVS",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Medicine and Surgery including a Preliminary Year",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BVMBVS",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Additive Manufacturing and 3D Printing",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Architecture Design",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MArch",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Building Performance Engineering",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Chemical Engineering",
                    "university": "University of Nottingham",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Civil Engineering",
                    "university": "University of Nottingham",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Electrical and Electronic Engineering with Extended Research",
                    "university": "University of Nottingham",
                    "location": "Nottingham",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Materials",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Structural Engineering",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Nutrition",
                    "university": "University of Nottingham",
                    "location": "Sutton Bonington Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Linguistics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Linguistics and English Language Teaching",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Sport and Exercise Medicine",
                    "university": "University of Nottingham",
                    "location": "Queen's Medical Centre",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture (ARB RIBA Part 2)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MArch",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture and Sustainable Design",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MArch",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture Design and Build",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MArch",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Banking and Finance",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Behavioural Economics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioengineering",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioinformatics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology",
                    "university": "University of Nottingham",
                    "location": "Sutton Bonington Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer Immunology and Biotechnology",
                    "university": "University of Nottingham",
                    "location": "Nottingham",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Nottingham",
                    "location": "Nottingham",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese to English Translation and Interpreting",
                    "university": "University of Nottingham",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering and Management",
                    "university": "University of Nottingham",
                    "location": "Nottingham",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate Change, Environment and Society",
                    "university": "University of Nottingham",
                    "location": "Nottingham",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical and Molecular Microbiology",
                    "university": "University of Nottingham",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Nutrition",
                    "university": "University of Nottingham",
                    "location": "Sutton Bonington Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Behavioural Therapy",
                    "university": "University of Nottingham",
                    "location": "Queen's Medical Centre",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Neuroscience",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication and Entrepreneurship",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Neuroscience, Cognition and AI",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science or Computer Science (Artificial Intelligence)",
                    "university": "University of Nottingham",
                    "location": "Nottingham",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science or Computer Science (Artificial Intelligence) pathway",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MA/MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Criminal Justice",
                    "university": "University of Nottingham",
                    "location": "Nottingham",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cultural Industries and Entrepreneurship",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Physical Systems",
                    "university": "University of Nottingham",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Physical Systems",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Development Economics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Developmental Disorders",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Teaching and Learning",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drug Discovery",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic Development and Policy Analysis",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Data Science",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Econometrics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Leadership and Management",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Engineering",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Engineering for Sustainable and Renewable Energy",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Communications and Computer Engineering",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Studies",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Entrepreneurship, Innovation and Management",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Leadership and Management",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Process Engineering",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film, Television and Screen Industries",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Investment",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial and Computational Mathematics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Economics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Technology",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "First Contact Practitioner",
                    "university": "University of Nottingham",
                    "location": "Nottingham",
                    "qualification": "PgCert",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Process Engineering",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Production Management MSc",
                    "university": "University of Nottingham",
                    "location": "Sutton Bonington Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic and Criminological Psychology",
                    "university": "University of Nottingham",
                    "location": "Nottingham",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Environmental Sustainability",
                    "university": "University of Nottingham",
                    "location": "Nottingham",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Social and Public Policy",
                    "university": "University of Nottingham",
                    "location": "Nottingham",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gravity, Particles and Fields",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Computer Interaction",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Factors and Ergonomics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management and Organisation",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Rights Law",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Immunology and Immunotherapeutics",
                    "university": "University of Nottingham",
                    "location": "Queen's Medical Centre",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Industrial Engineering and Operations Management",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Systems and Operations Management",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Commercial Law",
                    "university": "University of Nottingham",
                    "location": "Nottingham",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Economics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Media and Communications Studies",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Security and Terrorism",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tourism Management and Marketing",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (Master of Laws)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Logistics and Supply Chain Management",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Machine Learning in Science",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management Psychology",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Public Administration",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MPA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Public Health",
                    "university": "University of Nottingham",
                    "location": "Nottingham",
                    "qualification": "MPH",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Public Health (Global Health)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MPH",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Public Health (Health Research)",
                    "university": "University of Nottingham",
                    "location": "Nottingham",
                    "qualification": "MPH",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Education",
                    "university": "University of Nottingham",
                    "location": "Multiple Locations",
                    "qualification": "MMedSci",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health Research and Practice",
                    "university": "University of Nottingham",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology and Immunology",
                    "university": "University of Nottingham",
                    "location": "Queen's Medical Centre",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular Genetics and Diagnostics",
                    "university": "University of Nottingham",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neurological Rehabilitation",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nutritional Sciences",
                    "university": "University of Nottingham",
                    "location": "Sutton Bonington Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Psychology",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oncology",
                    "university": "University of Nottingham",
                    "location": "Queen's Medical Centre",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Person-Centred Experiential Counselling and Psychotherapy Practice",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "University of Nottingham",
                    "location": "City Hospital Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Power Electronics and Drives",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "PGCE (Professional)",
                    "duration": "11 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Conversion)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology Research Methods",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quality and Patient Safety Improvement",
                    "university": "University of Nottingham",
                    "location": "Queen's Medical Centre",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantum Science and Technology",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantum Science and Technology MSc",
                    "university": "University of Nottingham",
                    "location": "Nottingham",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Renewable Energy and Architecture",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Research Methods (Health)",
                    "university": "University of Nottingham",
                    "location": "Queen's Medical Centre",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Risk Management",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Screen Translation",
                    "university": "University of Nottingham",
                    "location": "Nottingham",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "PGCE (Professional)",
                    "duration": "11 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Science Research (Criminology)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Science Research (Political Science and International Relations)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Science Research (Social Policy and Social Work)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Science Research (Sociology)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Special and Inclusive Education",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Injury Rehabilitation",
                    "university": "University of Nottingham",
                    "location": "Nottingham",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports and Exercise Medicine",
                    "university": "University of Nottingham",
                    "location": "Queen's Medical Centre",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics with Machine Learning",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Stem Cell Technology and Regenerative Medicine",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Supply Chain and Operations Management",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Building Technology",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Energy and Entrepreneurship",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Energy Engineering",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Urban Design",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MArch",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching Chinese to Speakers of Other Languages (TCSOL)",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL)",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Technology and Intellectual Property Law",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation and Localisation Studies",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Medicine and Surgery - Wildlife",
                    "university": "University of Nottingham",
                    "location": "Sutton Bonington Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Work and Organisational Psychology",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Clinical Practice",
                    "university": "University of Nottingham",
                    "location": "Queen's Medical Centre",
                    "qualification": "MSc",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Materials",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Nutrition",
                    "university": "University of Nottingham",
                    "location": "Sutton Bonington Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Linguistics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Linguistics and English Language Teaching",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Sport and Exercise Medicine",
                    "university": "University of Nottingham",
                    "location": "Queen's Medical Centre",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology",
                    "university": "University of Nottingham",
                    "location": "Sutton Bonington Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical and Molecular Microbiology",
                    "university": "University of Nottingham",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Exercise Rehabilitation",
                    "university": "University of Nottingham",
                    "location": "Nottingham",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Variable",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication and Entrepreneurship",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cultural Industries and Entrepreneurship",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Teaching and Learning",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MA",
                    "duration": "2.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MA",
                    "duration": "2.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Studies",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Executive MBA",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MBA (Executive)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film, Television and Screen Industries",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "First Contact Practitioner",
                    "university": "University of Nottingham",
                    "location": "Nottingham",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic and Criminological Psychology",
                    "university": "University of Nottingham",
                    "location": "Nottingham",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Factors and Ergonomics",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Rights Law",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Immunology and Immunotherapeutics",
                    "university": "University of Nottingham",
                    "location": "Queen's Medical Centre",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Commercial Law",
                    "university": "University of Nottingham",
                    "location": "Nottingham",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Media and Communications Studies",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Security and Terrorism",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (Master of Laws)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Machine Learning in Science",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management Psychology",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Public Administration",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MPA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Public Health",
                    "university": "University of Nottingham",
                    "location": "Nottingham",
                    "qualification": "MPH",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Public Health (Global Health)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MPH",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Public Health (Health Research)",
                    "university": "University of Nottingham",
                    "location": "Nottingham",
                    "qualification": "PgDip (Initial Qualification)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Education",
                    "university": "University of Nottingham",
                    "location": "Multiple Locations",
                    "qualification": "MMedSci",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health Research and Practice",
                    "university": "University of Nottingham",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mentoring and Coaching Teachers",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "PgCert",
                    "duration": "1.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neurological Rehabilitation",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nutritional Sciences",
                    "university": "University of Nottingham",
                    "location": "Sutton Bonington Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Psychology",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Person-Centred Experiential Counselling and Psychotherapy Practice",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "University of Nottingham",
                    "location": "City Hospital Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Contemporary History",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Variable",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Practice in Architecture (Part 3)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "PgDip",
                    "duration": "14 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Procurement Law and Policy",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quality and Patient Safety Improvement",
                    "university": "University of Nottingham",
                    "location": "Queen's Medical Centre",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Rehabilitation",
                    "university": "University of Nottingham",
                    "location": "Medical School",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Variable",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Research Methods (Health)",
                    "university": "University of Nottingham",
                    "location": "Queen's Medical Centre",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Science Research (Political Science and International Relations)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Science Research (Social Policy and Social Work)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Science Research (Sociology)",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Special and Inclusive Education",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MA",
                    "duration": "2.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Special and Inclusive Education (Online)",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MA",
                    "duration": "2.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports and Exercise Medicine",
                    "university": "University of Nottingham",
                    "location": "Queen's Medical Centre",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching Chinese to Speakers of Other Languages (TCSOL)",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MA",
                    "duration": "2.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL)",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MA",
                    "duration": "2.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Technology and Intellectual Property Law",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation and Localisation Studies",
                    "university": "University of Nottingham",
                    "location": "University Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Trauma Informed Practice",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MA",
                    "duration": "2.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Physiotherapy",
                    "university": "University of Nottingham",
                    "location": "Sutton Bonington Campus",
                    "qualification": "MSc (PG)",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Work and Organisational Psychology",
                    "university": "University of Nottingham",
                    "location": "Jubilee Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "afc7e1661fd697bf6e02232afd5380a5",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "4cca771f84df31e8f94bb700aa9487dc",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Accounting and Finance with Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "32-80"
                },
                {
                    "course_name": "Acting",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Acting with Foundation",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Anthropology",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Anthropology with Foundation",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Architectural Engineering",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-136"
                },
                {
                    "course_name": "Art History",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Audio and Music Technology",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Bachelor of Dental Surgery with Integrated Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BDS",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Biological Sciences with Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Biomedical Science with Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Biosciences",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Building Surveying",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Business",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Business Management (fast track)",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Business Management with Finance",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Business Management with Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "32-80"
                },
                {
                    "course_name": "Business Management with Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-80"
                },
                {
                    "course_name": "Business Management with Human Resource Management",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Business Management with International Business",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Business Management with Marketing",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Business Management with Project Management",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Chemistry with Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Civil and Coastal Engineering",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Civil and Coastal Engineering",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Civil Engineering with Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Clinical Physiology (Cardiac Physiology)",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Computer Science (Artificial Intelligence)",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Computer Science (Cyber Security)",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Computer Science (Games Development)",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Computer Science (Software Engineering)",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Computer Science with Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Creative Media",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Creative Media with Foundation",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Creative Writing with Foundation",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Criminology and Psychology",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Criminology with Foundation",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "32-80"
                },
                {
                    "course_name": "Dental Surgery",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BDS",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dental Therapy and Hygiene",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dental Therapy and Hygiene with Integrated Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diagnostic Radiography",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diagnostic Radiography with Integrated Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Diagnostic Radiography with Ultrasound Fundamentals",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Dietetics",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Dietetics",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MDiet",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Dietetics with Integrated Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Digital Media Design",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Digital Media Design",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Drama",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Drama with Foundation",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Early Childhood Studies with Foundation",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Ecology and Conservation",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Economics with Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-80"
                },
                {
                    "course_name": "Education",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Education for Sustainability",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Education with Foundation",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Electrical and Electronic Engineering with Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "English",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "English and Creative Writing",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "English and Creative Writing with Foundation",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "English with Foundation",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Environmental Chemistry",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Environmental Geography",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Environmental Geoscience",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Environmental Management and Sustainability",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Environmental Science with Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Events Management",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Events Management with Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-80"
                },
                {
                    "course_name": "Filmmaking",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-144"
                },
                {
                    "course_name": "Financial Economics",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-144"
                },
                {
                    "course_name": "Fine Art with Foundation",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Game Arts and Design",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Game Production and Design",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Geography with Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Geography with GIS and Data Science",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Geography with International Relations",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Geography with Ocean Science",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Geology",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Geology",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MGeol",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Geology with Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Graphic Design with Foundation",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "History",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "History with Foundation",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Hospitality, Tourism and Events Management",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Human Biosciences",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Illustration",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-144"
                },
                {
                    "course_name": "Illustration with Foundation",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Integrated Engineering",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Interior Design",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Interior Design with Foundation",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "International Business Management",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "International Finance",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "International Logistics and Supply Chain Management",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "International Relations with Foundation",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "32-80"
                },
                {
                    "course_name": "International Supply Chain and Shipping Management",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "International Tourism Management",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "International Tourism Management with Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "32-80"
                },
                {
                    "course_name": "Law",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Law and Criminology",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Law with Foundation",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-80"
                },
                {
                    "course_name": "Marine Biology",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Marine Biology and Coastal Ecology",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Marine Biology and Oceanography",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Marine Biology with Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-64"
                },
                {
                    "course_name": "Marine Technology",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Marine Technology",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Maritime Business",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Maritime Business with Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-80"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Marketing with Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "32-80"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Mathematics with Computer Science",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Mathematics with Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Mathematics with Statistics",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Mathematics with Theoretical Physics",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Mechanical Engineering with Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Medicine",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BMBS",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine with Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BMBS",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Midwifery",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Midwifery (Pre-Registration)",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Midwifery (Pre-Registration)",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Midwifery (Shortened)",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Midwifery (Shortened) (Blended Learning)",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Music",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Nursing (Adult Health and Child Health)",
                    "university": "University of Plymouth",
                    "location": "Plymouth Site",
                    "qualification": "MNurs",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Nursing (Adult Health and Mental Health)",
                    "university": "University of Plymouth",
                    "location": "Multiple Locations",
                    "qualification": "MNurs",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Nursing (Adult Health)",
                    "university": "University of Plymouth",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Nursing (Adult Health) with Integrated Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Nursing (Child Health and Mental Health)",
                    "university": "University of Plymouth",
                    "location": "Plymouth Site",
                    "qualification": "MNurs",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Nursing (Child Health)",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Nursing (Child Health) with Integrated Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "University of Plymouth",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Nursing (Mental Health) with Integrated Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Nursing Associate",
                    "university": "University of Plymouth",
                    "location": "Multiple Locations",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64-96"
                },
                {
                    "course_name": "Nutrition Exercise and Health",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MOccTh",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Occupational Therapy (Pre-Registration)",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Occupational Therapy with Integrated Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Ocean Exploration and Surveying",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Ocean Science",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Ocean Science and Marine Conservation",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Ocean Sciences with Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Optometry",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MOptom",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Optometry with Integrated Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MOptom",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80-96"
                },
                {
                    "course_name": "Paramedic Science",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Paramedic Science with Integrated Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Photography",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Photography with Foundation",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Physical Geography and Geology",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MPhysio",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Physiotherapy (Pre-Registration)",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy with Integrated Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Podiatry",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Podiatry with Integrated Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Politics",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Politics with Foundation",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-80"
                },
                {
                    "course_name": "Pre-Registration Nursing (Adult Health)",
                    "university": "University of Plymouth",
                    "location": "Plymouth Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Pre-Registration Nursing (Child Health)",
                    "university": "University of Plymouth",
                    "location": "Plymouth Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Pre-Registration Nursing (Mental Health)",
                    "university": "University of Plymouth",
                    "location": "Plymouth Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Primary Education  (2-year fast track)",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Product and Furniture Design",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Product and Furniture Design with Foundation",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Psychological Studies",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MPsych",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Psychology with Clinical Psychology",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MPsych",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Psychology with Criminology",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Psychology with Human Biology",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Psychology with Human Neuroscience",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MPsych",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Psychology with Integrated Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Psychology with Sociology",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Quantity Surveying",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Robotics",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Robotics",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Robotics with Foundation Year",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Sociology with Foundation",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "32-80"
                },
                {
                    "course_name": "Zoology",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-128"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Acting",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Professional Practice in Neurological Rehabilitation",
                    "university": "University of Plymouth",
                    "location": "Plymouth Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Professional Practice in Occupational Therapy",
                    "university": "University of Plymouth",
                    "location": "Plymouth Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Professional Practice in Physiotherapy",
                    "university": "University of Plymouth",
                    "location": "Plymouth Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Marine Science",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Theatre",
                    "university": "University of Plymouth",
                    "location": "Plymouth Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Design",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate Science, Policy and Society",
                    "university": "University of Plymouth",
                    "location": "Plymouth Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Psychology",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Coastal Engineering",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication Design",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cybercrime, Terrorism and Security",
                    "university": "University of Plymouth",
                    "location": "Plymouth Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Business Analytics",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Environmental Intelligence",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital and Social Media Marketing",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Culture and Society",
                    "university": "University of Plymouth",
                    "location": "Plymouth Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental and Engineering Geology",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Consultancy",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Geochemistry",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Humanities",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Expedition and Marine Medicine",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Experience Design",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Filmmaking",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Game Design",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Wellness Through Physiotherapy",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Data Science and Statistics",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Heritage Theory and Practice",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Neuroscience",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Nutrition",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hydrography",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Plymouth",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Logistics and Supply Chain Management",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Procurement and Supply Chain Management",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations: Global Security and Development",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Shipping",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Low Carbon Architectural Engineering",
                    "university": "University of Plymouth",
                    "location": "Plymouth Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA Fine Art",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA Game Design",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Biology",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Conservation",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Maritime Cyber Security",
                    "university": "University of Plymouth",
                    "location": "Plymouth Site",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Maritime History",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Architecture (ARB/RIBA Part 2)",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MArch",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA Master of Business Administration",
                    "university": "University of Plymouth",
                    "location": "Multiple Locations",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering Design",
                    "university": "University of Plymouth",
                    "location": "Plymouth Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Engineering",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Project Management",
                    "university": "University of Plymouth",
                    "location": "Plymouth Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Production",
                    "university": "University of Plymouth",
                    "location": "Plymouth Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Therapy (Pre-Registration)",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Offshore Renewable Energy",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Offshore Renewable Energy Engineering",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Operations and Supply Chain Management",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Photography",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy (Pre-Registration)",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Planning",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Publishing",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Research Methods in Psychology",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scientific Diving",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Screenwriting",
                    "university": "University of Plymouth",
                    "location": "Plymouth Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Aquaculture and Fisheries",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Environmental Management",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Tourism and Hospitality Management",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoo and Aquarium Conservation Biology",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Acting",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Professional Practice in Neurological Rehabilitation",
                    "university": "University of Plymouth",
                    "location": "Plymouth Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Professional Practice in Occupational Therapy",
                    "university": "University of Plymouth",
                    "location": "Plymouth Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Professional Practice in Physiotherapy",
                    "university": "University of Plymouth",
                    "location": "Plymouth Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Theatre",
                    "university": "University of Plymouth",
                    "location": "Plymouth Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate Science, Policy and Society",
                    "university": "University of Plymouth",
                    "location": "Plymouth Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Psychology",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication Design",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cybercrime, Terrorism and Security",
                    "university": "University of Plymouth",
                    "location": "Plymouth Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Environmental Intelligence",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Culture and Society",
                    "university": "University of Plymouth",
                    "location": "Plymouth Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Consultancy",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Geochemistry",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Humanities",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Heritage Theory and Practice",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Neuroscience",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Nutrition",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hydrography",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations: Global Security and Development",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Low Carbon Architectural Engineering",
                    "university": "University of Plymouth",
                    "location": "Plymouth Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA Fine Art",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Biology",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Maritime History",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA Master of Business Administration",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering Design",
                    "university": "University of Plymouth",
                    "location": "Plymouth Site",
                    "qualification": "MSc",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Project Management",
                    "university": "University of Plymouth",
                    "location": "Plymouth Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Planning",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Publishing",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Research Methods in Psychology",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Screenwriting",
                    "university": "University of Plymouth",
                    "location": "Plymouth Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Aquaculture and Fisheries",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Environmental Management",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Tourism and Hospitality Management",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoo and Aquarium Conservation Biology",
                    "university": "University of Plymouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "19ba9e0af1475016c391d01454ac5a24",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Music Business (1 year)",
                    "university": "pointblank Music School",
                    "location": "Main Campus",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Music Business (3 Years)",
                    "university": "pointblank Music School",
                    "location": "Main Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Music Business (3 Years) with Foundation Year",
                    "university": "pointblank Music School",
                    "location": "Main Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Music Production & DJ Performance (1 Year)",
                    "university": "pointblank Music School",
                    "location": "Main Campus",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Music Production & DJ Performance (2 Years)",
                    "university": "pointblank Music School",
                    "location": "Main Campus",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Music Production & DJ Performance (2 Years) with Foundation Year",
                    "university": "pointblank Music School",
                    "location": "Main Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Music Production & DJ Performance (3 Years)",
                    "university": "pointblank Music School",
                    "location": "Main Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Music Production & DJ Performance (3 Years) with Foundation Year",
                    "university": "pointblank Music School",
                    "location": "Main Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Music Production & Software Engineering (1 year)",
                    "university": "pointblank Music School",
                    "location": "Main Campus",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Music Production & Software Engineering (3 Years)",
                    "university": "pointblank Music School",
                    "location": "Main Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Music Production & Songwriting (1 year)",
                    "university": "pointblank Music School",
                    "location": "Main Campus",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Music Production & Songwriting (3 Years)",
                    "university": "pointblank Music School",
                    "location": "Main Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Music Production & Songwriting (3 Years) with Foundation Year",
                    "university": "pointblank Music School",
                    "location": "Main Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Music Production & Sound Engineering (1 Year)",
                    "university": "pointblank Music School",
                    "location": "Main Campus",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Music Production & Sound Engineering (2 Years)",
                    "university": "pointblank Music School",
                    "location": "Main Campus",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Music Production & Sound Engineering (2 Years) with Foundation Year",
                    "university": "pointblank Music School",
                    "location": "Main Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Music Production & Sound Engineering (3 Years)",
                    "university": "pointblank Music School",
                    "location": "Main Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Music Production & Sound Engineering (3 Years) with Foundation Year",
                    "university": "pointblank Music School",
                    "location": "Main Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Music Production and Vocal Performance (1 Year)",
                    "university": "pointblank Music School",
                    "location": "Main Campus",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Music Production and Vocal Performance (2 Years)",
                    "university": "pointblank Music School",
                    "location": "Main Campus",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Music Production and Vocal Performance (2 Years) with Foundation Year",
                    "university": "pointblank Music School",
                    "location": "Main Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Music Production and Vocal Performance (3 Years)",
                    "university": "pointblank Music School",
                    "location": "Main Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Music Production and Vocal Performance (3 Years) with Foundation Year",
                    "university": "pointblank Music School",
                    "location": "Main Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Radio, Audio and Podcasting (1 Year)",
                    "university": "pointblank Music School",
                    "location": "Main Campus",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "8a625cf416bd657ff607b787b8ee6604",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting & Finance",
                    "university": "Richmond American University London",
                    "location": "Chiswick Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Advertising and Public Relations",
                    "university": "Richmond American University London",
                    "location": "Chiswick Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Arts and Visual Cultures",
                    "university": "Richmond American University London",
                    "location": "Chiswick Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Business and Data Analytics",
                    "university": "Richmond American University London",
                    "location": "Chiswick Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Business Enterprise and Innovation",
                    "university": "Richmond American University London",
                    "location": "Chiswick Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Communications and Media Studies",
                    "university": "Richmond American University London",
                    "location": "Chiswick Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Richmond American University London",
                    "location": "Chiswick Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "Richmond American University London",
                    "location": "Chiswick Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Fashion Management and Marketing",
                    "university": "Richmond American University London",
                    "location": "Chiswick Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Film",
                    "university": "Richmond American University London",
                    "location": "Chiswick Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Finance & Investment",
                    "university": "Richmond American University London",
                    "location": "Chiswick Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "International Business",
                    "university": "Richmond American University London",
                    "location": "Chiswick Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "International History",
                    "university": "Richmond American University London",
                    "location": "Chiswick Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "International Relations",
                    "university": "Richmond American University London",
                    "location": "Chiswick Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Marketing",
                    "university": "Richmond American University London",
                    "location": "Chiswick Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Political Science",
                    "university": "Richmond American University London",
                    "location": "Chiswick Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Psychology",
                    "university": "Richmond American University London",
                    "location": "Chiswick Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "32a5d3da3b9566c31acd147808c78699",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Accounting Studies Top Up",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Adult and Mental Health Nursing",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "Master in Science (with Honours) \u2013 MSci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Adult Nursing",
                    "university": "University of Roehampton",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Animation",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Animation with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Architectural Technology",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Architectural Technology CertHE",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Architectural Technology DipHE",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Architectural Technology with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Biblical Studies and Theology",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biological Sciences with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business (General) (HNC)",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business (General) (HND)",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Foundation Studies: Certificate of Higher Education",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business International Foundation Pathway",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Business Management and Economics",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management and Economics Top-Up",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and Economics with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management and Entrepreneurship",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management and Entrepreneurship Top-up",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and Entrepreneurship with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management and Finance",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management and Finance Top-up",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and Finance with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management and Marketing",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management and Marketing Top-up",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and Marketing with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management Top-up Degree",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA/BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business: International Year 1",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Children's Nursing",
                    "university": "University of Roehampton",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Civil Engineering BEng",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Civil Engineering BEng with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Civil Engineering MEng",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Computer Games Programming",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Games Programming with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science Top-up",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Computing (HNC)",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Computing (HND)",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Computing and Business",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computing and Digital Technologies",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computing and Digital Technologies with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Computing Foundation Studies: Certificate of Higher Education",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computing International Foundation Pathway",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Construction Management",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Construction Management CertHE",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Construction Management DipHE",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Construction Management with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Criminology and Criminal Justice",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology and Criminal Justice CertHE",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Criminology and Criminal Justice DipHE",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "DipHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Criminology and Criminal Justice/Sociology",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology and Criminal Justice/Sociology with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Criminology and Policing",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology and Policing CertHE",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Policing DipHE",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "DipHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Criminology and Policing with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Criminology with Criminal Justice with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Cyber Security (Top-up)",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Dance",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Dance (Top-up)",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Design",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Digital Design with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Digital Media",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Digital Media with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Digital Technologies (HNC)",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Digital Technologies (HND)",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Early Childhood Education and Care (HNC)",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Early Childhood Education and Care (HND)",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Early Childhood Studies (FdA)",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Early Childhood Studies (Top-Up)",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Childhood Studies CertHE",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Early Childhood Studies with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Economics with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Education and Youth Studies",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Education and Youth Studies CertHE",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Education and Youth Studies DipHE",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Education Foundation Studies: Certificate of Higher Education",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Education International Foundation Pathway",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "English and Journalism",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English and Journalism with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English Literature with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Film Production",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Film Production with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Finance and Accounting",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Finance and Accounting with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Games Art",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Games Art with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Games Design",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Games Design with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Health and Life Sciences Foundation Studies: Certificate of Higher Education",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Health and Life Sciences International Foundation Pathway",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "History",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "History and English",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "History and English with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "History with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Human Resource Management (Top-up)",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Humanities and Social Sciences Foundation Studies: Certificate of Higher Education",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Humanities and Social Sciences International Foundation Pathway",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Business and Finance Top-up",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Top-up",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "International Relations and Economics",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Journalism",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Journalism with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Law and Politics",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Law and Politics CertHE",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Law and Politics DipHE",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Law and Politics with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Law Foundation Studies: Certificate of Higher Education",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Law International Foundation Pathway",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Liberal Arts",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Liberal Arts CertHE",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Liberal Arts DipHE",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "DipHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Liberal Arts with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "LLB Law",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "LLB Law and Criminal Justice",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "LLB Law and Criminal Justice with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "LLB Law with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "LLB Law with Politics",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "LLB Law with Politics CertHE",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "LLB Law with Politics DipHE",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "LLB Law with Politics with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Marketing Top-up",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Media and Communications",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Media and Communications with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Media Foundation Studies: Certificate of Higher Education",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Media International Foundation Pathway",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Mental Health Nursing",
                    "university": "University of Roehampton",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Neuroscience",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing Associate",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Nutrition and Health",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nutrition and Health with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Occupational Therapy (pre-registration)",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Philosophy Religion and Ethics with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Philosophy, Religion and Ethics",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Physiotherapy (pre-registration)",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Politics and International Relations with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Politics, International Relations, and History",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Politics, International Relations, and History with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Politics, Philosophy and Economics",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Politics, Philosophy and Economics CertHE",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, Philosophy and Economics DipHE",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Politics, Philosophy and Economics with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Primary Education (QTS) Foundation Stage/KS1",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Primary Education (QTS) KS1/KS2",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Primary Education Studies",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Primary Education Studies with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology & Clinical Psychology with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Psychology (Clinical & Mental Health)",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology and Coaching",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology and Cognitive Neuroscience",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology and Cognitive Neuroscience with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Psychology and Criminal Justice",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology and Criminal Justice with Foundation year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Psychology and Education Practice",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology and Education Practice with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Psychology and Sociology",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology and Sociology with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Psychology Foundation Studies: Certificate of Higher Education",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Psychology International Foundation Pathway",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Psychology with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sociology with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Software Engineering (Top-up) BEng",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sport and Exercise Sciences",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport and Exercise Sciences with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sports Management",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sports Management with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sports Therapy",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Theology, Mission and Practice",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Therapeutic Psychology and Counselling",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Therapeutic Psychology and Counselling with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Web Design and Development",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Web Design and Development Top-up",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Web Design and Development with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Zoology",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Zoology with Foundation Year",
                    "university": "University of Roehampton",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "31d00ab3ffc41f901c77420df0b5a404",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Animal Biology, Behaviour, Welfare and Ethics",
                    "university": "Royal Veterinary College, University of London",
                    "location": "main site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Biology, Behaviour, Welfare and Ethics with Placement Year",
                    "university": "Royal Veterinary College, University of London",
                    "location": "main site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Biological Research",
                    "university": "Royal Veterinary College, University of London",
                    "location": "main site",
                    "qualification": "Master in Science (with Honours) \u2013 MSci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Biological Research with Placement Year",
                    "university": "Royal Veterinary College, University of London",
                    "location": "main site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Bioveterinary Research",
                    "university": "Royal Veterinary College, University of London",
                    "location": "main site",
                    "qualification": "Master in Science (with Honours) \u2013 MSci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Bioveterinary Research with Placement Year",
                    "university": "Royal Veterinary College, University of London",
                    "location": "main site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (Wildlife Health Science)",
                    "university": "Royal Veterinary College, University of London",
                    "location": "main site",
                    "qualification": "Master in Science (with Honours) \u2013 MSci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (Wildlife Health Science)",
                    "university": "Royal Veterinary College, University of London",
                    "location": "main site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (Wildlife Health Science) with Placement Year",
                    "university": "Royal Veterinary College, University of London",
                    "location": "main site",
                    "qualification": "Master in Science (with Honours) \u2013 MSci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (Wildlife Health Science) with Placement Year",
                    "university": "Royal Veterinary College, University of London",
                    "location": "main site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "Royal Veterinary College, University of London",
                    "location": "main site",
                    "qualification": "Master in Science (with Honours) \u2013 MSci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "Royal Veterinary College, University of London",
                    "location": "main site",
                    "qualification": "Master in Science (with Honours) \u2013 MSci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences with Placement Year",
                    "university": "Royal Veterinary College, University of London",
                    "location": "main site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences with Placement Year",
                    "university": "Royal Veterinary College, University of London",
                    "location": "main site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioveterinary Sciences",
                    "university": "Royal Veterinary College, University of London",
                    "location": "main site",
                    "qualification": "Master in Science (with Honours) \u2013 MSci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioveterinary Sciences",
                    "university": "Royal Veterinary College, University of London",
                    "location": "main site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioveterinary Sciences with Placement Year",
                    "university": "Royal Veterinary College, University of London",
                    "location": "main site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioveterinary Sciences with Placement Year",
                    "university": "Royal Veterinary College, University of London",
                    "location": "main site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graduate Accelerated Veterinary Medicine",
                    "university": "Royal Veterinary College, University of London",
                    "location": "main site",
                    "qualification": "BVetMed",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Gateway Programme",
                    "university": "Royal Veterinary College, University of London",
                    "location": "main site",
                    "qualification": "BVetMed",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Medicine",
                    "university": "Royal Veterinary College, University of London",
                    "location": "main site",
                    "qualification": "BVetMed",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Medicine with Intercalated BSc Year",
                    "university": "Royal Veterinary College, University of London",
                    "location": "main site",
                    "qualification": "BVetMed",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Nursing",
                    "university": "Royal Veterinary College, University of London",
                    "location": "main site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Nursing",
                    "university": "Royal Veterinary College, University of London",
                    "location": "main site",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Science (Aberystwyth)",
                    "university": "Royal Veterinary College, University of London",
                    "location": "Aberystwyth University (Aber)",
                    "qualification": "BVSc",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "One Health: Ecosystems, Humans and Animals",
                    "university": "Royal Veterinary College, University of London",
                    "location": "Camden Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Small Animal Internship",
                    "university": "Royal Veterinary College, University of London",
                    "location": "main site",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Clinical Studies",
                    "university": "Royal Veterinary College, University of London",
                    "location": "Camden Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Epidemiology",
                    "university": "Royal Veterinary College, University of London",
                    "location": "Camden Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Wild Animal Biology",
                    "university": "Royal Veterinary College, University of London",
                    "location": "Camden Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Veterinary Epidemiology",
                    "university": "Royal Veterinary College, University of London",
                    "location": "Camden Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "f48332d0c83d76f954c6846abd67166f",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Accounting and Finance (Including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Accounting and Finance (Sandwich course)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Addysg Gychwynnol Athrawon Cynradd gyda SAC",
                    "university": "University of South Wales",
                    "location": "Newport",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-112"
                },
                {
                    "course_name": "Advertising",
                    "university": "University of South Wales",
                    "location": "Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Aeronautical Engineering",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Aeronautical Engineering",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-104"
                },
                {
                    "course_name": "Aeronautical Engineering (Including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Aeronautical Engineering (Sandwich course)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "80-104"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Aerospace Engineering (Including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Aerospace Engineering (Sandwich Course)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Aircraft Engineering and Maintenance Systems (Top Up)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aircraft Maintenance Engineering",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Aircraft Maintenance Engineering (Including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Animal Health & Welfare",
                    "university": "University of South Wales",
                    "location": "Coleg Gwent (Usk)",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Animation (2D and Stop Motion)",
                    "university": "University of South Wales",
                    "location": "Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Applied Cyber Security",
                    "university": "University of South Wales",
                    "location": "Newport",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Applied Cyber Security (Including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Newport",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art Practice",
                    "university": "University of South Wales",
                    "location": "Merthyr Tydfil College",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Automotive Engineering",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Automotive Engineering",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive Engineering",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Automotive Engineering (Including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Banking, Finance and Investment (Top Up)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Biology",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Biology (Including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Biomedical Science (Including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Building Surveying",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Building Surveying",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Building Surveying (Including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business and Management",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business and Management (Including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business and Management (Sandwich)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business Management (Accounting and Finance) (Top Up)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (Event Management) (Top Up)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (Human Resource Management) (Top Up)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (Marketing) (Top Up)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (Top Up)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Childhood Development",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Childhood Development (Including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Childhood Development (Sandwich course)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Childhood Studies",
                    "university": "University of South Wales",
                    "location": "Multiple Locations",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Childhood Studies (Top-Up)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Civil Engineering (Including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Civil Engineering (Sandwich course)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Civil Engineering (Sandwich course)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "80-104"
                },
                {
                    "course_name": "Community Football Coaching and Development",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Community Health and Well Being",
                    "university": "University of South Wales",
                    "location": "Multiple Locations",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Community Health and Wellbeing",
                    "university": "University of South Wales",
                    "location": "Multiple Locations",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Computer Animation",
                    "university": "University of South Wales",
                    "location": "Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computer Games Design",
                    "university": "University of South Wales",
                    "location": "Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science (Including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Computer Science (Sandwich course)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computing",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-112"
                },
                {
                    "course_name": "Computing",
                    "university": "University of South Wales",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing (Including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Computing (Sandwich course)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-112"
                },
                {
                    "course_name": "Computing (Top Up)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Construction Project Management (Including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Construction Project Management (Sandwich Course)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Costume Construction",
                    "university": "University of South Wales",
                    "location": "Coleg y Cymoedd (Nantgarw)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Counselling and Therapeutic Practice",
                    "university": "University of South Wales",
                    "location": "Newport",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative and Therapeutic Arts",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Industries (Popular Music Technology) (Top-Up)",
                    "university": "University of South Wales",
                    "location": "Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Criminology and Criminal Justice (Including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Criminology and Criminal Justice and Youth Justice (Including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Criminology and Youth Justice",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Criminology with Psychology",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Digital Forensics",
                    "university": "University of South Wales",
                    "location": "Newport",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-104"
                },
                {
                    "course_name": "Digital Forensics (including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Newport",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Documentary Photography",
                    "university": "University of South Wales",
                    "location": "Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Early Years Education (Top Up)",
                    "university": "University of South Wales",
                    "location": "Newport",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Years Education and Practice (with Early Years Practitioner status)",
                    "university": "University of South Wales",
                    "location": "Newport",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Education",
                    "university": "University of South Wales",
                    "location": "Newport",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-104"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-104"
                },
                {
                    "course_name": "Electrical and Electronic Engineering (Including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Electrical and Electronic Engineering (Sandwich course)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English and Creative Writing",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English and Creative Writing (Including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "English and History",
                    "university": "University of South Wales",
                    "location": "Coleg Gwent (Ebbw Vale)",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Esports",
                    "university": "University of South Wales",
                    "location": "Merthyr Tydfil College",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Fashion Business and Marketing",
                    "university": "University of South Wales",
                    "location": "Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Fashion Business and Marketing",
                    "university": "University of South Wales",
                    "location": "Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Fashion Design",
                    "university": "University of South Wales",
                    "location": "Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Fashion Design (Sandwich)",
                    "university": "University of South Wales",
                    "location": "Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Fashion Promotion",
                    "university": "University of South Wales",
                    "location": "Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Fashion Promotion",
                    "university": "University of South Wales",
                    "location": "Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Film",
                    "university": "University of South Wales",
                    "location": "Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Finance",
                    "university": "University of South Wales",
                    "location": "Merthyr Tydfil College",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Football Coaching and Performance",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Football Coaching, Development and Administration",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Forensic Investigation",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Forensic Investigation",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Forensic Investigation (Including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Forensic Science (Including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Game Art",
                    "university": "University of South Wales",
                    "location": "Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Game Art and Design",
                    "university": "University of South Wales",
                    "location": "Multiple Locations",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Graphic Communication",
                    "university": "University of South Wales",
                    "location": "Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Graphic Communication",
                    "university": "University of South Wales",
                    "location": "Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "80-112"
                },
                {
                    "course_name": "Health, Wellbeing and Social Care Management",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Health, Wellbeing and Social Care Management (Including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "History",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "History (Including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Hotel and Hospitality Management",
                    "university": "University of South Wales",
                    "location": "Newport",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Illustration",
                    "university": "University of South Wales",
                    "location": "Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Illustration",
                    "university": "University of South Wales",
                    "location": "Coleg Gwent (Cross Keys)",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Interior Design",
                    "university": "University of South Wales",
                    "location": "Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Interior Design",
                    "university": "University of South Wales",
                    "location": "Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "International Business (Top-Up)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Management",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "International Wildlife Biology",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "International Wildlife Biology",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "International Wildlife Biology (Including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Law",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Law (Accelerated Route)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (Including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "LLB",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Law and Criminology",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "LLB",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Legal Practice (SQE)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Legal Studies",
                    "university": "University of South Wales",
                    "location": "Multiple Locations",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Marketing (Including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Marketing (Sandwich)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Master of Chiropractic",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MChiro (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Master of Chiropractic (Including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MChiro",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Mechanical Engineering (Including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Mechanical Engineering (Sandwich course)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Media Production",
                    "university": "University of South Wales",
                    "location": "Coleg Gwent (Cross Keys)",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Media Production",
                    "university": "University of South Wales",
                    "location": "Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Media, Culture and Journalism",
                    "university": "University of South Wales",
                    "location": "Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Medical Sciences",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Medicinal and Biological Chemistry",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Medicinal and Biological Chemistry",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Medicinal and Biological Chemistry (Including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Midwifery",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Business",
                    "university": "University of South Wales",
                    "location": "Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Music Producing",
                    "university": "University of South Wales",
                    "location": "Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Nursing (Adult) September Entry",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Child)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Nursing (Learning Disabilities) September Entry",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Mental Health) September Entry",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Operating Department Practice (ODP)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance and Media",
                    "university": "University of South Wales",
                    "location": "Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Performing Arts",
                    "university": "University of South Wales",
                    "location": "Multiple Locations",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Pharmaceutical Science",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Pharmaceutical Science",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Pharmaceutical Science (Including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Photography",
                    "university": "University of South Wales",
                    "location": "Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Photography",
                    "university": "University of South Wales",
                    "location": "Multiple Locations",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Popular and Commercial Music",
                    "university": "University of South Wales",
                    "location": "Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Popular Music Technology",
                    "university": "University of South Wales",
                    "location": "Coleg Gwent (Cross Keys)",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Popular Music Technology",
                    "university": "University of South Wales",
                    "location": "Coleg Gwent (Cross Keys)",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Post Compulsory Education and Training (PcET)",
                    "university": "University of South Wales",
                    "location": "Newport",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Post Compulsory Education and Training (PcET)",
                    "university": "University of South Wales",
                    "location": "Newport",
                    "qualification": "PGCE (Professional)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Initial Teacher Education with QTS",
                    "university": "University of South Wales",
                    "location": "Newport",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Initial Teacher Education with QTS",
                    "university": "University of South Wales",
                    "location": "Newport",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-112"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Professional Policing (Including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of South Wales",
                    "location": "Multiple Locations",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Psychology (Including foundation year)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Psychology (Sandwich course)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Psychology with Counselling",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Psychology with Counselling (Sandwich course)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Psychology with Criminology",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Psychology with Criminology (Sandwich Course)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Psychology with Developmental Disorders",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Psychology with Developmental Disorders (Sandwich course)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Public and Emergency Services",
                    "university": "University of South Wales",
                    "location": "Torfaen Learning Zone",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Services",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Public Services (Including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Quantity Surveying and Commercial Management",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Quantity Surveying and Commercial Management (Including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Quantity Surveying and Commercial Management (Sandwich course)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Real Estate",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Real Estate",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Real Estate (Including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Retail Management",
                    "university": "University of South Wales",
                    "location": "Multiple Locations",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Rugby Coaching and Performance",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of South Wales",
                    "location": "Newport",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Sociology (Including Foundation Year)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sound, Lighting and Live Event Technology",
                    "university": "University of South Wales",
                    "location": "Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sound, Lighting and Live Event Technology",
                    "university": "University of South Wales",
                    "location": "Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sports and Exercise Therapy",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sports Business and Management",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sports Coaching and Development",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sports Coaching and Development",
                    "university": "University of South Wales",
                    "location": "Multiple Locations",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sports Journalism",
                    "university": "University of South Wales",
                    "location": "Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Strength and Conditioning",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Theatre and Drama",
                    "university": "University of South Wales",
                    "location": "Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "TV & Film Set Design",
                    "university": "University of South Wales",
                    "location": "Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "TV and Film: Hair, Make Up and Special Effects",
                    "university": "University of South Wales",
                    "location": "Coleg y Cymoedd (Nantgarw)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-104"
                },
                {
                    "course_name": "TV and Film: Prop Making",
                    "university": "University of South Wales",
                    "location": "Coleg y Cymoedd (Nantgarw)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-104"
                },
                {
                    "course_name": "Veterinary Nursing",
                    "university": "University of South Wales",
                    "location": "Coleg Gwent (Usk)",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Visual Effects and Motion Graphics",
                    "university": "University of South Wales",
                    "location": "Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Working With Children and Families",
                    "university": "University of South Wales",
                    "location": "Newport",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Youth and Social Care",
                    "university": "University of South Wales",
                    "location": "Coleg Gwent (Cross Keys)",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Acute and Critical Care",
                    "university": "University of South Wales",
                    "location": "Glyntaff Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aeronautical Engineering",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Aircraft Engineering and Maintenance Systems (Top Up)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animation (2D and Stop Motion)",
                    "university": "University of South Wales",
                    "location": "Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "80-104"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "80-104"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Fashion Design (Sandwich)",
                    "university": "University of South Wales",
                    "location": "Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Football Coaching and Performance",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Health, Wellbeing and Social Care Management",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "History",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Legal Practice (SQE)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "LLB (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Psychology with Criminology",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Real Estate",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sports Coaching and Development",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Strength and Conditioning",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "BSc (Hons)",
                    "duration": "4 to 6 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "112"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Advanced Computer Science",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Performance Football Coaching",
                    "university": "University of South Wales",
                    "location": "Glyntaff Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aeronautical Engineering",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Analytical and Forensic Science",
                    "university": "University of South Wales",
                    "location": "Glyntaff Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animation",
                    "university": "University of South Wales",
                    "location": "Cardiff (Atrium) Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Cyber Security",
                    "university": "University of South Wales",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aviation Engineering and Management",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Behaviour Analysis and Therapy",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Behaviour Analysis Supervised Practice",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Child and Adolescent Mental Health (CAMH)",
                    "university": "University of South Wales",
                    "location": "City Campus, Newport",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of South Wales",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Psychology",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing and Information Systems",
                    "university": "University of South Wales",
                    "location": "Cardiff (Atrium) Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Crime and Justice",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security, Risk and Resilience",
                    "university": "University of South Wales",
                    "location": "Newport",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Innovation",
                    "university": "University of South Wales",
                    "location": "Cardiff (Atrium) Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Forensics",
                    "university": "University of South Wales",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama",
                    "university": "University of South Wales",
                    "location": "Cardiff (Atrium) Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Innovation in Learning and Teaching)",
                    "university": "University of South Wales",
                    "location": "City Campus, Newport",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education for Healthcare Professionals",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronics and Information Technology",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Management",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "University of South Wales",
                    "location": "Cardiff (Atrium) Campus",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Enhancing Clinical Practice",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film",
                    "university": "University of South Wales",
                    "location": "Cardiff (Atrium) Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film (Cinematography)",
                    "university": "University of South Wales",
                    "location": "Cardiff (Atrium) Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film (Directing)",
                    "university": "University of South Wales",
                    "location": "Cardiff (Atrium) Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film (Documentary)",
                    "university": "University of South Wales",
                    "location": "Cardiff (Atrium) Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film (Editing)",
                    "university": "University of South Wales",
                    "location": "Cardiff (Atrium) Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film (Production Management)",
                    "university": "University of South Wales",
                    "location": "Cardiff (Atrium) Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film (Screen Writing)",
                    "university": "University of South Wales",
                    "location": "Cardiff (Atrium) Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Investment",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Audit and Accounting",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Enterprise",
                    "university": "University of South Wales",
                    "location": "Cardiff (Atrium) Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Communication",
                    "university": "University of South Wales",
                    "location": "Cardiff (Atrium) Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Public Service Management",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Manangement",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law",
                    "university": "University of South Wales",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Logistics and Supply Chain Management",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Security and Risk Management",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Security and Risk Management (Counter Terrorism)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law, Technology and Innovation",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Laws",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership and Management (Education)",
                    "university": "University of South Wales",
                    "location": "City Campus, Newport",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership in Sport",
                    "university": "University of South Wales",
                    "location": "Glyntaff Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal Practice",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "Grad Dip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal Practice",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal Research",
                    "university": "University of South Wales",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA Global",
                    "university": "University of South Wales",
                    "location": "No Locations",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mobile and Satellite Communications",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Engineering and Production",
                    "university": "University of South Wales",
                    "location": "Cardiff (Atrium) Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Chemistry",
                    "university": "University of South Wales",
                    "location": "Glyntaff Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Accounting (with ACCA tuition)",
                    "university": "University of South Wales",
                    "location": "Newport",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Accounting (with ACCA tuition) (Fast Track)",
                    "university": "University of South Wales",
                    "location": "Newport",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Practice",
                    "university": "University of South Wales",
                    "location": "Glyntaff Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Practice (SQE)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management",
                    "university": "University of South Wales",
                    "location": "City Campus, Newport",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "University of South Wales",
                    "location": "Glyntaff Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Renewable Energy and Sustainable Technology",
                    "university": "University of South Wales",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Safety, Health and Environmental Management",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "SEN/ALN (Additional Learning Needs)",
                    "university": "University of South Wales",
                    "location": "Newport",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "SEN/ALN (Autism)",
                    "university": "University of South Wales",
                    "location": "City Campus, Newport",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Songwriting and Production",
                    "university": "University of South Wales",
                    "location": "Cardiff (Atrium) Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Public Health Nursing (Health Visiting)",
                    "university": "University of South Wales",
                    "location": "Glyntaff Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Public Health Nursing (School Nursing)",
                    "university": "University of South Wales",
                    "location": "Glyntaff Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport, Health and Exercise Science",
                    "university": "University of South Wales",
                    "location": "Glyntaff Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Coaching and Performance",
                    "university": "University of South Wales",
                    "location": "Glyntaff Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic and Digital Marketing",
                    "university": "University of South Wales",
                    "location": "Cardiff",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Procurement Management",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL)",
                    "university": "University of South Wales",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Journalism",
                    "university": "University of South Wales",
                    "location": "Cardiff (Atrium) Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Wildlife and Conservation Management",
                    "university": "University of South Wales",
                    "location": "Glyntaff Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Working for Children and Young People (Youth Work Initial Qualifying)",
                    "university": "University of South Wales",
                    "location": "Newport",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Working with Adult and Young People who Offend",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Acute and Critical Care",
                    "university": "University of South Wales",
                    "location": "Glyntaff Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practitioner",
                    "university": "University of South Wales",
                    "location": "Glyntaff Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Performance Football Coaching",
                    "university": "University of South Wales",
                    "location": "Glyntaff Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animation",
                    "university": "University of South Wales",
                    "location": "Cardiff (Atrium) Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Cyber Security",
                    "university": "University of South Wales",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art Psychotherapy",
                    "university": "University of South Wales",
                    "location": "City Campus, Newport",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts Practice (Art, Health and Well-Being)",
                    "university": "University of South Wales",
                    "location": "Cardiff (Atrium) Campus",
                    "qualification": "MA",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aviation Engineering and Management",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Behaviour Analysis and Therapy",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration",
                    "university": "University of South Wales",
                    "location": "City Campus, Newport",
                    "qualification": "MBA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "DBA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Child and Adolescent Mental Health (CAMH)",
                    "university": "University of South Wales",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of South Wales",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Psychology",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Community Health Studies (Children's Community Nursing)",
                    "university": "University of South Wales",
                    "location": "Glyntaff Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Community Health Studies (Practice Nursing)",
                    "university": "University of South Wales",
                    "location": "Glyntaff Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Community Health Studies (Specialist Practitioner District Nursing)",
                    "university": "University of South Wales",
                    "location": "Glyntaff Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing and Information Systems",
                    "university": "University of South Wales",
                    "location": "Cardiff (Atrium) Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Crime and Justice",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Critical Care",
                    "university": "University of South Wales",
                    "location": "Glyntaff Campus",
                    "qualification": "PgCert",
                    "duration": "9 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Critical Care",
                    "university": "University of South Wales",
                    "location": "Glyntaff Campus",
                    "qualification": "Advanced Certificate",
                    "duration": "9 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security, Risk and Resilience",
                    "university": "University of South Wales",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Innovation",
                    "university": "University of South Wales",
                    "location": "Cardiff (Atrium) Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Forensics",
                    "university": "University of South Wales",
                    "location": "Newport",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama",
                    "university": "University of South Wales",
                    "location": "Cardiff (Atrium) Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Innovation in Learning and Teaching)",
                    "university": "University of South Wales",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Wales)",
                    "university": "University of South Wales",
                    "location": "Newport",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronics and Information Technology",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "University of South Wales",
                    "location": "Cardiff (Atrium) Campus",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Enhancing Clinical Practice",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Investment",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Audit and Accounting",
                    "university": "University of South Wales",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Enterprise",
                    "university": "University of South Wales",
                    "location": "Cardiff (Atrium) Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Communication",
                    "university": "University of South Wales",
                    "location": "Cardiff (Atrium) Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Public Service Management",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of South Wales",
                    "location": "Newport",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Independent Prescribing Practice",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrative Counselling and Psychotherapy",
                    "university": "University of South Wales",
                    "location": "City Campus, Newport",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrative Counselling Skills",
                    "university": "University of South Wales",
                    "location": "Newport",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law",
                    "university": "University of South Wales",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Logistics and Supply Chain Management",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Security and Risk Management",
                    "university": "University of South Wales",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Security and Risk Management (Counter Terrorism)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law, Technology and Innovation",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Laws",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership and Management",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership and Management (Education)",
                    "university": "University of South Wales",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leading Digital Transformation",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal Practice",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal Practice",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "Grad Dip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal Research",
                    "university": "University of South Wales",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mobile and Satellite Communications",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Engineering and Production",
                    "university": "University of South Wales",
                    "location": "Cardiff (Atrium) Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Therapy",
                    "university": "University of South Wales",
                    "location": "City Campus, Newport",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Palliative Care",
                    "university": "University of South Wales",
                    "location": "Glyntaff Campus",
                    "qualification": "PgCert",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Play Therapy",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Accounting (with ACCA tuition)",
                    "university": "University of South Wales",
                    "location": "Newport",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Accounting (with ACCA tuition) (Fast Track)",
                    "university": "University of South Wales",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Engineering",
                    "university": "University of South Wales",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Practice",
                    "university": "University of South Wales",
                    "location": "Glyntaff Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Practice (SQE)",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "University of South Wales",
                    "location": "Glyntaff Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Renewable Energy and Sustainable Technology",
                    "university": "University of South Wales",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Safety, Health and Environmental Management",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "SEN/ALN (Additional Learning Needs)",
                    "university": "University of South Wales",
                    "location": "Multiple Locations",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "SEN/ALN (Autism)",
                    "university": "University of South Wales",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "SEN/ALN (Specific Learning Difficulties)",
                    "university": "University of South Wales",
                    "location": "City Campus, Newport",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Songwriting and Production",
                    "university": "University of South Wales",
                    "location": "Cardiff (Atrium) Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Public Health Nursing (Health Visiting)",
                    "university": "University of South Wales",
                    "location": "Glyntaff Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport, Health and Exercise Science",
                    "university": "University of South Wales",
                    "location": "Glyntaff Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Coaching and Performance",
                    "university": "University of South Wales",
                    "location": "Glyntaff Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Procurement Management",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Wildlife and Conservation Management",
                    "university": "University of South Wales",
                    "location": "Glyntaff Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Working for Children and Young People (Youth Work Initial Qualifying)",
                    "university": "University of South Wales",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Working with Adult and Young People who Offend",
                    "university": "University of South Wales",
                    "location": "Pontypridd",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "59f504dbb1ea816386ae373364fd74b9",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance with Placement Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance with Study Abroad Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Taxation",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Taxation with Placement Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Acoustical Engineering",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Acoustical Engineering",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Acoustical Engineering with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Acoustical Engineering with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Acoustical Engineering with Industrial Placement Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Acoustical Engineering with Industrial Placement Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Acoustical Engineering with Industrial Placement Year and Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Acoustical Engineering with Industrial Placement Year and Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aeronautics and Astronautics",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aeronautics and Astronautics",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aeronautics and Astronautics / Aerodynamics",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aeronautics and Astronautics / Aerodynamics with Industrial Placement Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aeronautics and Astronautics / Spacecraft Engineering",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aeronautics and Astronautics / Spacecraft Engineering with Industrial Placement Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aeronautics and Astronautics with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aeronautics and Astronautics with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aeronautics and Astronautics with Industrial Placement Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aeronautics and Astronautics with Industrial Placement Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aeronautics and Astronautics with Industrial Placement Year and Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aeronautics and Astronautics with Industrial Placement Year and Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Electronic Engineering",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Electronic Engineering",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Electronic Engineering with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Electronic Engineering with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Electronic Engineering with Industrial Studies",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Electronic Engineering with Industrial Studies and Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History and Archaeology",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History and Archaeology with Year Abroad",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History and History",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History and History with Year Abroad",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History with Year Abroad",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and Anthropology",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and Anthropology with Year Abroad",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology with Year Abroad",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence with Industrial Studies",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence with Industrial Studies and Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Audiology",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Audiology",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Audiology with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology and Marine Biology",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology and Marine Biology",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology and Marine Biology with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering with Industrial Studies",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering with Industrial Studies and Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics with Placement Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics with Study Abroad Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Entrepreneurship",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Entrepreneurship with Placement Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Entrepreneurship with Study Abroad Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and Accounting",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and Accounting with Placement Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and Human Resource Management",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and Human Resource Management with Placement Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Placement Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Study Abroad Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cardiac Physiology (Healthcare Science)",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cardiac Physiology (Healthcare Science) with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering  with Industrial Placement Year and Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with Industrial Placement Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with Industrial Placement Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with Industrial Placement Year and Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with Science Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with Science Foundation Year and Industrial Placement Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry (Digital Methods and Computational Modelling)",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MChem",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry (Digital Methods and Computational Modelling)",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with External Placement",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MChem",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Maths",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Maths",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Medicinal Sciences",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Medicinal Sciences",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with year-long industry experience",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering and Architecture",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering and Architecture with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering and Architecture with Industrial Placement Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with Industrial Placement Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with Industrial Placement Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with Industrial Placement Year and Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with Industrial Placement Year and Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Engineering",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Engineering",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Engineering with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Engineering with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Engineering with Industrial Studies",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Engineering with Industrial Studies and Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Artificial Intelligence",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Cyber Security",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Industrial Studies",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Industrial Studies and Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Computing",
                    "university": "University of Southampton",
                    "location": "Winchester School of Art",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Psychology",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics (MEcon)",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEcon",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Accounting",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Actuarial Science",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Business Management",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Philosophy",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Philosophy (with Year Abroad)",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Data Science",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education and Psychology",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering with Industrial Studies",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering with Industrial Studies and Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Engineering",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Engineering",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Engineering with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Engineering with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Engineering with Industrial Studies",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Engineering with Industrial Studies and Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with Artificial Intelligence",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with Computer Systems",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with Industrial Studies",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with Industrial Studies and Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with Mobile & Secure Systems",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with Nanotechnology",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with Photonics",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with Wireless Communications",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and History",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and History with Year Abroad",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Modern Languages (One Language)",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Linguistics",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Linguistics with Year Abroad",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Literature",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English with Creative Writing",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English with Year Abroad",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Geoscience",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Geoscience",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Geoscience with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Design",
                    "university": "University of Southampton",
                    "location": "Winchester School of Art",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Marketing with Management",
                    "university": "University of Southampton",
                    "location": "Winchester School of Art",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and English",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and English with Year Abroad",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and History",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and History with Year Abroad",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies with Year Abroad",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film, TV and Digital Media",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film, TV and Digital Media with Year Abroad",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Financial Technology",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Financial Technology with Placement Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Financial Technology with Study Abroad Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Southampton",
                    "location": "Winchester School of Art",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Design and Art",
                    "university": "University of Southampton",
                    "location": "Winchester School of Art",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Communication",
                    "university": "University of Southampton",
                    "location": "Winchester School of Art",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Modern Languages (One Language)",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History with Year Abroad",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Geography",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Placement Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Study Abroad Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language, Culture and Communication",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (Accelerated Programme)",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (European Legal Studies)",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (International Legal Studies)",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (Juris Doctor)",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Psychology",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Liberal Arts",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Biology",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Biology",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Biology with foundation year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Biology with Oceanography",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Biology with Oceanography",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Biology with Oceanography with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Maritime Engineering",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Maritime Engineering",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Maritime Engineering with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Maritime Engineering with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Maritime Engineering with Industrial Placement Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Maritime Engineering with Industrial Placement Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Maritime Engineering with Industrial Placement Year and Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Maritime Engineering with Industrial Placement Year and Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing with Placement Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing with Study Abroad Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical Physics (MMath)",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MMath (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical Physics with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MMath (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical Sciences",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics (MMath)",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MMath",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Actuarial Science",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Computer Science",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Finance",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MMath (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Statistics",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics, Operational Research, Statistics and Economics (BSc MORSE)",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering / Aerospace with Industrial Placement Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering / Automotive with Industrial Placement Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering / Biomedical Engineering",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering / Biomedical Engineering with Industrial Placement Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering / Manufacturing with Industrial Placement Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering / Mechatronics with Industrial Placement Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering / Sustainable Energy Systems with Industrial Placement Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Industrial Placement Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Industrial Placement Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Industrial Placement Year and Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Industrial Placement Year and Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering/Aerospace Engineering",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering/Automotive Engineering",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering/Manufacturing",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering/Mechatronics",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering/Sustainable Energy Systems",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronic Engineering",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronic Engineering",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronic Engineering with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronic Engineering with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronic Engineering with Industrial Studies",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronic Engineering with Industrial Studies and Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Engineering",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Engineering",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Engineering with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Engineering with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Engineering with Industrial Placement Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Engineering with Industrial Placement Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Engineering with Industrial Placement Year and Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Engineering with Industrial Placement Year and Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern History and Politics",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern History and Politics with Year Abroad",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages (1 Language: French, German or Spanish)",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages (1 Language: French, German or Spanish) - Integrated Masters in Languages",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MLang",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages (2 Languages: French, German or Spanish) - Integrated Masters in Languages",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MLang",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages (2 Languages: French, German, Portuguese or Spanish)",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages (3 Languages: Chinese, French, German, Portuguese or Spanish)",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and Business Management (1 Language: French, German or Spanish)",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and Linguistics  (1 Language: French, German or Spanish)",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and Linguistics  (2 Languages: French, German or Spanish)",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and Linguistics  (2 Languages: French, German or Spanish) - Integrated Masters in Languages",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MLang",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and Linguistics (1 Language: French, German or Spanish) - Integrated Masters in Languages",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MLang",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and Philosophy (1 Language: French or German)",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and Politics (1 Language: French, German or Spanish)",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Business Management",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Business Management with a year abroad",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music with Year Abroad",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Adult and Child)",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MN",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Adult and Mental Health)",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MN",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Child and Mental Health)",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MN",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Child)",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing - Adult (pre-registration)",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing - Child (pre-registration)",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing - Mental Health (pre-registration)",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Therapy with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oceanography",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oceanography",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oceanography with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology and Drug Discovery",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and English",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and English with Year Abroad",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and History",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and History with Year Abroad",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Politics",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Politics with Year Abroad",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy Ethics and Religion",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy with Year Abroad",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy, Ethics and Religion with Year Abroad",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy, Politics and Economics",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy, Politics and Economics with Year Abroad",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Geography",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Artificial Intelligence",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astronomy",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "Master of Physics (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Mathematics",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Quantum Science and Technologies",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Space Science",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Economics",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Criminology",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering with Industrial Studies",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering with Industrial Studies and Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Luxury (Textiles)",
                    "university": "University of Southampton",
                    "location": "Winchester School of Art",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology with Foundation Year",
                    "university": "University of Southampton",
                    "location": "Main Site - Highfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "International Criminology and Criminal Justice",
                    "university": "University of Southampton",
                    "location": "Highfield Campus and Boldrewood Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        }
    },
    {
        "id": "e04f049fd4d50b9178bd6d69299949d9",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "4f1dce2bf3464500d5f0cbbde1f1937b",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Adult Nursing",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Advanced Certificate in Nautical Science",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk at East Coast College (Lowestoft)",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Diploma in Nautical Science",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk at East Coast College (Lowestoft)",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Care Practice (Adult Support Needs) (Progresion Route)",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk at East Coast College (Great Yarmouth)",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Applied Care Practice (Children & Families) (Progression Route)",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk at East Coast College (Great Yarmouth)",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Applied Care Practice (Mental Health Support) (Progression Route)",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk at East Coast College (Great Yarmouth)",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Psychology",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk at East Coast College (Great Yarmouth)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biomedical Science with Foundation Year",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management (Top Up)",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Childhood",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Childhood: Primary Education with QTS",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Children's Health, Play and Wellbeing",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk at East Coast College (Great Yarmouth)",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Children's Nursing",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computing (Optional Pathways: Cyber Security, Web and Mobile Development, Artificial Intelligence)",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Conservation Science and Animal Management (Wildlife and Zoo)",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk at East Coast College (Great Yarmouth)",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Creative Arts for Health and Wellbeing",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk at East Coast College (Great Yarmouth)",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Creative Arts Practice (progression route)",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk at East Coast College (Great Yarmouth)",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Crime, Justice and Society",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk at East Coast College (Lowestoft)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Dental Hygiene and Therapy",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Diagnostic Radiography",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Digital Film Production",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Early Years & Primary Practice",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Early Years and Childhood Studies (Progression route)",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk at East Coast College (Lowestoft)",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Engineering (General Engineering)",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk at East Coast College (Lowestoft)",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "60"
                },
                {
                    "course_name": "Engineering (General Engineering)",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk at East Coast College (Lowestoft)",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Esports",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fashion & Textiles",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk at East Coast College (Great Yarmouth)",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Forensic Sciences",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk at East Coast College (Lowestoft)",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Games Development",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Graphic Design (Graphic Illustration)",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Health and Wellbeing (Nursing Associate)",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "History",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Inclusive Practice and Integrated Working (Progression Route)",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk at East Coast College (Lowestoft)",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Law",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Literary Studies with Creative Writing",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk at East Coast College (Lowestoft)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Maritime Management (Progression Route)",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk at East Coast College (Lowestoft)",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health Nursing",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Midwifery",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nutrition & Human Health",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nutrition and Human Health (with Science Foundation Year)",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Paramedic Science",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Person-centred Counselling",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk at East Coast College (Great Yarmouth)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Photography",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology and Counselling",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology and Criminology",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology and Sociology",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Radiotherapy and Oncology",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Screenwriting",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Social Welfare, Advice and Guidance Practice",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk at East Coast College (Great Yarmouth)",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport & Exercise Science",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport Performance Analysis",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport, Health & Exercise",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk at East Coast College (Great Yarmouth)",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Sport, Health and Exercise (Progression Route)",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk at East Coast College (Great Yarmouth)",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Sustainable Futures (progression route)",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk at East Coast College (Great Yarmouth)",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Visual Art",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk at East Coast College (Lowestoft)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Visual Effects and Post Production (progression route)",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk at East Coast College (Great Yarmouth)",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Effects and Post-production",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk at East Coast College (Great Yarmouth)",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Wildlife, Ecology and Conservation Science",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Wildlife, Ecology and Conservation Science (with Foundation Year)",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Adult Nursing (Pre-registration)",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applications of Psychology",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Childhood Studies",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative and Critical Writing",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Law and Justice",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Artificial Intelligence",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diagnostic Radiography (Pre-registration)",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Management",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Masters in Business Administration - IMBA",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "International Master's",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Conversion)",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health and Wellbeing",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Practitioner (District Nursing)",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic People Management",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Suffolk Executive MBA (SEMBA)",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Clinical Practice",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applications of Psychology",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Childhood Studies",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Behaviour Therapy and Counselling",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computed Tomography",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative and Critical Writing",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Law and Justice",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Artificial Intelligence",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Enhanced Clinical Practice",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "PgDip",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Management",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Magnetic Resonance Imaging",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paediatric Imaging",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Practice: Advanced Radiography Practice (Progression Route)",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Practice: Integrated Care",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Conversion)",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health and Wellbeing",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health Nursing",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Radiographic Reporting of the Adult Chest",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work Practice Education",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Practitioner (District Nursing)",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic People Management",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "MSc",
                    "duration": "15 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic People Management",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Suffolk Executive MBA (SEMBA)",
                    "university": "University of Suffolk",
                    "location": "University of Suffolk",
                    "qualification": "MBA",
                    "duration": "30 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "86ed5c81464bc88eb18be6081c5e2ea6",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Accounting and Finance (with a professional placement year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "American Studies (with a study abroad year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "American Studies and English  (with a study abroad year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "American Studies and Film Studies  (with a study abroad year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "American Studies and History  (with a study abroad year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "American Studies and Politics  (with a study abroad year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Anthropology",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Anthropology and International Development",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Anthropology with a Language",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Art History",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Art History (with a professional placement year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Art History and Film Studies",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Arts and Humanities (with a foundation year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Astrophysics",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-165"
                },
                {
                    "course_name": "Banking and Digital Finance",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Banking and Digital Finance (with a professional placement year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Biochemistry (with an industrial placement year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Biology",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Biology",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Business and Management Studies",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Business and Management Studies (with a professional placement year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Business, Management and Economics (with a foundation year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Chemistry (with an industrial placement year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Chemistry with Summer Research Placements",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-165"
                },
                {
                    "course_name": "Childhood and Youth: Theory and Practice",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-141"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MCompu (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Computer Science (with an industrial placement year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Computer Science (with an industrial placement year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MCompu (H)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Computer Science and Artificial Intelligence",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Computer Science and Artificial Intelligence (with an industrial placement year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Computing for Business and Management",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Computing for Business and Management (with an industrial placement year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Computing for Digital Media and Games",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Computing for Digital Media and Games (with an industrial placement year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Computing Sciences (with a foundation year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Creative Industries",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-141"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-141"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-141"
                },
                {
                    "course_name": "Criminology with Psychology",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-141"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Data Science (with an industrial placement)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Design and Business (with a foundation year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Drama and English",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Drama and Film Studies",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Drama with a Language",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Drama, Theatre and Performance",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Ecology and Conservation",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Ecology and Conservation",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Economics (with a professional placement year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Economics (with a professional placement year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Economics and Data Science",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Economics and International Development",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Economics and Management Studies",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Education",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-141"
                },
                {
                    "course_name": "Education with Psychology",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-141"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Electrical and Electronic Engineering (with an industrial placement year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Electrical and Electronic Engineering (with an industrial placement year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Engineering (with a foundation year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "English",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "English and Art History",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "English and Creative Writing",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "English and Film Studies",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "English and History",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "English and Media Studies",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "English Language and Linguistics",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "English Language and Linguistics (with TESOL)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "English Language and Literature",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Film Studies",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Filmmaking",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Finance and Business",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Finance and Business (with a professional placement year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Finance and Technology (FinTech)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Finance and Technology (FinTech) (with a professional placement year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Genetics",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Genetics",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Geography and Anthropology",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Geography and International Development",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Geography and International Relations",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Geography with a Language",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Geography, Sustainable Development and Climate Change",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "History",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "History and Anthropology",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "History and International Relations",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "History and Philosophy",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "History and Politics",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "History and Sociology",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-147"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "International Business (with a professional placement year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "International Business (with a study abroad year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "International Development",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "International Development with a Language",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "International Relations and Anthropology",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "International Relations and Development",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "International Relations and Sociology",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "International Relations with a Language",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Law",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Law (Graduate Entry)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with a Language",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Law with American Studies  (with a study abroad year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Law with Business and Management",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Law with International Relations",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Law with Politics",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Liberal Arts",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Life Sciences (with a foundation year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Marketing and Management",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Marketing and Management (with a professional placement year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Marketing and Management with Psychology",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Marketing and Management with Psychology (with a Professional Placement Year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MMath",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Mathematics (research placement)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MMath",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "152-165"
                },
                {
                    "course_name": "Mathematics (with a foundation year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Mathematics with Data Science",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Mathematics with Data Science",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MMath",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Mathematics with Economics",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Mathematics with Economics",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MMath",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Mathematics with Finance",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MMath (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Mathematics with Finance",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Mechanical Engineering (with an industrial placement year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Mechanical Engineering (with an industrial placement year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Mechanical Engineering with Robotics",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Mechanical Engineering with Robotics",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Mechanical Engineering with Robotics (with an industrial placement year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Mechanical Engineering with Robotics (with an industrial placement year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Media and Communications",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Media and Journalism",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Media Production",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-141"
                },
                {
                    "course_name": "Medical Neuroscience",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Medical Neuroscience",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Music",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-141"
                },
                {
                    "course_name": "Music Technology",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-141"
                },
                {
                    "course_name": "Neuroscience",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Neuroscience",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Neuroscience with Psychology",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Philosophy and English",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Philosophy and Sociology",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Philosophy, Politics and Economics",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-165"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Physics (Quantum Technology)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-165"
                },
                {
                    "course_name": "Physics (Quantum Technology) (research placement)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "152"
                },
                {
                    "course_name": "Physics (research placement)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "152"
                },
                {
                    "course_name": "Physics (with an industrial placement year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "144-165"
                },
                {
                    "course_name": "Physics (with an industrial placement year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Physics and Astronomy (with a foundation year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-165"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Physics with Data Science",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-165"
                },
                {
                    "course_name": "Physics with Data Science",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Politics",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Politics and Philosophy",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Politics and Sociology",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Primary and Early Years Education (with Qualified Teacher Status)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-141"
                },
                {
                    "course_name": "Product Design",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-147"
                },
                {
                    "course_name": "Product Design",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-147"
                },
                {
                    "course_name": "Product Design (with an industrial placement year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-147"
                },
                {
                    "course_name": "Product Design (with an industrial placement year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-147"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Psychology (with a foundation year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Psychology (with a professional placement year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Psychology with Business and Management",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Psychology with Clinical Approaches",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Psychology with Criminology",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Psychology with Neuroscience",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Robotic and Mechatronic Engineering",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Robotic and Mechatronic Engineering",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Robotic and Mechatronic Engineering (with an industrial placement year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Robotic and Mechatronic Engineering (with an industrial placement year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Social Sciences (with a foundation year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-141"
                },
                {
                    "course_name": "Social Work (Postgraduate Entry)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work (Postgraduate Entry)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-147"
                },
                {
                    "course_name": "Sociology and International Development",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-147"
                },
                {
                    "course_name": "Sociology with a Language",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-147"
                },
                {
                    "course_name": "Sociology with Media Studies",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-147"
                },
                {
                    "course_name": "Sustainable Automotive Engineering",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Sustainable Automotive Engineering",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Sustainable Automotive Engineering (with an industrial placement year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Sustainable Automotive Engineering (with an industrial placement year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-165"
                },
                {
                    "course_name": "Zoology",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Zoology",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Banking and Finance",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Development Economics",
                    "university": "University of Sussex",
                    "location": "Sussex House",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy and Climate Policy",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Entrepreneurship and Innovation",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fintech, Risk and Investment Analysis",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Supply Chain and Logistics Management",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Development",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Economics",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Finance and Economics",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and Finance",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing and Consumer Psychology",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational and Organizational Psychology",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science and Technology Policy",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science and Technology Policy (with a professional placement year)",
                    "university": "University of Sussex",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Innovation Management",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Innovation Management (with a professional placement year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Marketing",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Development",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Finance and Accounting",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Finance and Accounting (with a professional placement year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Banking and Finance",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Development Economics",
                    "university": "University of Sussex",
                    "location": "Sussex House",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy and Climate Policy",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Entrepreneurship and Innovation",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Supply Chain and Logistics Management",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Finance and Economics",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and Finance",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational and Organizational Psychology",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science and Technology Policy",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science and Technology Policy (with a professional placement year)",
                    "university": "University of Sussex",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Innovation Management",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Innovation Management (with a professional placement year)",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Development",
                    "university": "University of Sussex",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "6dfa83666abb473e63d2352ad098f149",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Accounting & Finance with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting & Finance with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Accounting (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "2"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Accounting and Finance (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "2"
                },
                {
                    "course_name": "Accounting and Finance with a Foundation Year (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "1"
                },
                {
                    "course_name": "Accounting and Finance with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Accounting and Finance with a Year in Industry (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "2"
                },
                {
                    "course_name": "Accounting with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting with a Foundation Year (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "1"
                },
                {
                    "course_name": "Accounting with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Accounting with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Accounting with a Year in Industry (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "2"
                },
                {
                    "course_name": "Actuarial Science",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Actuarial Science with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Actuarial Science with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Actuarial Science with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-168"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-168"
                },
                {
                    "course_name": "Aerospace Engineering with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-144"
                },
                {
                    "course_name": "Aerospace Engineering with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-168"
                },
                {
                    "course_name": "Aerospace Engineering with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "136-168"
                },
                {
                    "course_name": "Aerospace Engineering with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-168"
                },
                {
                    "course_name": "Aerospace Engineering with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-168"
                },
                {
                    "course_name": "American Studies",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "American Studies and History with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "American Studies and History with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "American Studies and Politics",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "American Studies and Politics with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "American Studies with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72-80"
                },
                {
                    "course_name": "American Studies with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "American Studies with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Ancient and Medieval History",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Ancient and Medieval History with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Ancient and Medieval History with a Year In Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Ancient History",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Ancient History and History with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Ancient History and History with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Ancient History with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72-80"
                },
                {
                    "course_name": "Ancient History with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Ancient History with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Applied Business Management",
                    "university": "Swansea University",
                    "location": "Coleg Cambria - Northop",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Applied Mathematics",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Applied Mathematics with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-135"
                },
                {
                    "course_name": "Applied Mathematics with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-135"
                },
                {
                    "course_name": "Applied Medical Sciences",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-160"
                },
                {
                    "course_name": "Applied Medical Sciences with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Applied Medical Sciences with a Placement Year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Astrophysics",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Astrophysics with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Astrophysics with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Biochemistry and Genetics",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Biochemistry and Genetics",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Biochemistry and Genetics with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Biochemistry with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88-112"
                },
                {
                    "course_name": "Biological Sciences with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Biological Sciences with deferred choice of specialisation",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Biology",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Biology (Secondary PGCE)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "TAR Uwchradd gyda SAC: Bioleg",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-112"
                },
                {
                    "course_name": "Biology with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Biology with a Year in Applied Research",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Biology with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-168"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-168"
                },
                {
                    "course_name": "Biomedical Engineering with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-144"
                },
                {
                    "course_name": "Biomedical Engineering with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "136-168"
                },
                {
                    "course_name": "Biomedical Engineering with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-168"
                },
                {
                    "course_name": "Biomedical Engineering with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-168"
                },
                {
                    "course_name": "Biomedical Engineering with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-168"
                },
                {
                    "course_name": "Business Law",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Business Law in Practice",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Business Law with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Business Management",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Business Management (Business Analytics)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Business Management (Business Analytics) (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Business Management (Business Analytics) with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (Business Analytics) with a Foundation Year (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "1"
                },
                {
                    "course_name": "Business Management (Business Analytics) with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Business Management (Business Analytics) with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Business Management (Business Analytics) with a Year in Industry (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "2"
                },
                {
                    "course_name": "Business Management (Enterprise and Innovation)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Business Management (Enterprise and Innovation) (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BBA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "2"
                },
                {
                    "course_name": "Business Management (Enterprise and Innovation) with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (Enterprise and Innovation) with a Foundation Year (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "1"
                },
                {
                    "course_name": "Business Management (Enterprise and Innovation) with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Business Management (Enterprise and Innovation) with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Business Management (Enterprise and Innovation) with a Year in Industry (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "2"
                },
                {
                    "course_name": "Business Management (Finance)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Business Management (Finance) (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "2"
                },
                {
                    "course_name": "Business Management (Finance) with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (Finance) with a Foundation Year (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "1"
                },
                {
                    "course_name": "Business Management (Finance) with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Business Management (Finance) with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Business Management (Finance) with a Year in Industry (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "2"
                },
                {
                    "course_name": "Business Management (Human Resource Management)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Business Management (Human Resource Management) (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "2"
                },
                {
                    "course_name": "Business Management (Human Resource Management) with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (Human Resource Management) with a Foundation Year (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "1"
                },
                {
                    "course_name": "Business Management (Human Resource Management) with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Business Management (Human Resource Management) with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Business Management (Human Resource Management) with a Year in Industry (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "2"
                },
                {
                    "course_name": "Business Management (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "1"
                },
                {
                    "course_name": "Business Management (Marketing)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Business Management (Marketing) (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "2"
                },
                {
                    "course_name": "Business Management (Marketing) with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (Marketing) with a Foundation Year (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "1"
                },
                {
                    "course_name": "Business Management (Marketing) with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Business Management (Marketing) with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Business Management (Marketing) with a Year in Industry (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "2"
                },
                {
                    "course_name": "Business Management (Modern Languages)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Business Management (Modern Languages) with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Business Management (Modern Languages) with a Foundation Year (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc",
                    "duration": "3.5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (Modern Languages) with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Business Management (Modern Languages) with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Business Management (Operations)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Business Management (Operations) (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc",
                    "duration": "2.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Business Management (Operations)\u00a0with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Business Management (Operations)\u00a0with a Foundation Year (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc",
                    "duration": "3.5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Business Management (Operations)\u00a0with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Business Management (Operations)\u00a0with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Business Management (Operations)\u00a0with a Year in Industry (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc",
                    "duration": "3.5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Business Management with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with a Foundation Year (January)",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "1"
                },
                {
                    "course_name": "Business Management with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Business Management with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Business Management with a Year In Industry (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "2"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-168"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-168"
                },
                {
                    "course_name": "Chemical Engineering with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-144"
                },
                {
                    "course_name": "Chemical Engineering with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-168"
                },
                {
                    "course_name": "Chemical Engineering with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "136-168"
                },
                {
                    "course_name": "Chemical Engineering with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-168"
                },
                {
                    "course_name": "Chemical Engineering with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-168"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Chemistry (Secondary PGCE)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72-80"
                },
                {
                    "course_name": "Chemistry with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Chemistry with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Civil Engineering  with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Civil Engineering with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Civil Engineering with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Civil Engineering with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Civil Engineering with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Classical Studies",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Classical Studies with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-80"
                },
                {
                    "course_name": "Classical Studies with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Classical Studies with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Combined Honours",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Combined Honours with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Combined Honours with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Computer Science (Secondary PGCE)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "TAR Uwchradd gyda SAC: Cemeg",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "TAR Uwchradd gyda SAC: Cyfrifiadureg",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Artificial Intelligence",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Computer Science and Artificial Intelligence with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Computer Science and Artificial Intelligence with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Computer Science with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Computer Science with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Computer Science with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Computer Science with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Computer Science with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Criminology and Criminal Justice",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Criminology and Criminal Justice with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Criminology and Criminal Justice with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Criminology and Psychology",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Criminology and Social Policy",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Criminology and Social Policy with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Criminology and Social Policy with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Criminology and Sociology with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Criminology and Sociology with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Cymraeg, Cyfryngau a Chysylltiadau Cyhoeddus (Welsh, Media and Public Relations)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Cynradd gyda SAC",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design and Technology (Secondary PGCE)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "TAR Uwchradd gyda SAC: Dylunio a Thechnoleg",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Technology",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Early Childhood Studies With Early Years Practitioner Status",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Early Childhood Studies with Early Years Practitioner Status with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72-80"
                },
                {
                    "course_name": "Early Childhood Studies with Early Years Practitioner Status with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Economics",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Economics & Business with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics & Finance with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "1"
                },
                {
                    "course_name": "Economics (Top-Up Degree) (International applicants only)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Economics and Business",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Economics and Business (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "1"
                },
                {
                    "course_name": "Economics and Business with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Economics and Business with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Economics and Business with a Year in Industry (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "1"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Economics and Finance (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "1"
                },
                {
                    "course_name": "Economics and Finance with a Foundation Year (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "1"
                },
                {
                    "course_name": "Economics and Finance with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Economics and Finance with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Economics and Finance with a Year in Industry (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "2"
                },
                {
                    "course_name": "Economics with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Economics with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Economics with a Year in Industry (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "1"
                },
                {
                    "course_name": "Education",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Education and Psychology",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Education and Welsh",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Education and Welsh (a Pathway for Second Language Students)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Education and Welsh with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Education and Welsh with a Year Abroad (a Pathway for Second Language Students)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Education with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72-80"
                },
                {
                    "course_name": "Education with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Egyptology and Ancient History",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Egyptology and Ancient History with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-80"
                },
                {
                    "course_name": "Egyptology and Ancient History with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Egyptology and Ancient History with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Electronic and Electrical Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-168"
                },
                {
                    "course_name": "Electronic and Electrical Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-168"
                },
                {
                    "course_name": "Electronic and Electrical Engineering with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-144"
                },
                {
                    "course_name": "Electronic and Electrical Engineering with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-168"
                },
                {
                    "course_name": "Electronic and Electrical Engineering with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-168"
                },
                {
                    "course_name": "Electronic and Electrical Engineering with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "136-168"
                },
                {
                    "course_name": "Electronic and Electrical Engineering with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112-168"
                },
                {
                    "course_name": "Engineering with deferred choice of specialism with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-144"
                },
                {
                    "course_name": "TAR Uwchradd gyda SAC: Saesneg",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English (Secondary PGCE)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "English Language and Applied Linguistics",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "English Language and Applied Linguistics with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "English Language and Applied Linguistics with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "English Language and Literature",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "English Language and Literature with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "English Language and Literature with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "English Language and Media",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "English Language and Media with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "English Language and Media with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "English Language with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72-80"
                },
                {
                    "course_name": "English Language with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "English Language with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "English Literature",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "English Literature and French",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "English Literature and German",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "English Literature and History",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "English Literature and History with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "English Literature and History with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "English Literature and Spanish",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "English Literature and Teaching of English to Speakers of Other Languages",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "English Literature and Teaching of English to Speakers of Other Languages with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120"
                },
                {
                    "course_name": "English Literature and Teaching of English to Speakers of Other Languages with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "English Literature and Welsh (a Pathway for First Language students)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "English Literature and Welsh (a pathway for First Language students) with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "English Literature and Welsh (a Pathway for First Language students) with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "English Literature and Welsh (a pathway for Second Language students)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "English Literature and Welsh (a pathway for Second Language students) with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "English Literature and Welsh (a pathway for Second Language students) with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "English Literature with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72-80"
                },
                {
                    "course_name": "English Literature with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "English Literature with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "English Literature with Creative Writing",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "English Literature with Creative Writing with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120"
                },
                {
                    "course_name": "English Literature with Creative Writing with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "English with Creative Writing with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72-80"
                },
                {
                    "course_name": "Environmental Geoscience",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Environmental Geoscience with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Environmental Geoscience with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Environmental Science and the Climate Emergency",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Environmental Science and the Climate Emergency (Single) with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-96"
                },
                {
                    "course_name": "Environmental Science and the Climate Emergency (Single) with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Environmental Science and the Climate Emergency (Single) with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Film and Visual Culture",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Film and Visual Culture with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Film and Visual Culture with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Finance",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Finance (FinTech)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Finance (FinTech) with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Finance (FinTech) with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Finance (FinTech) with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Finance (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "2"
                },
                {
                    "course_name": "Finance (Sustainability)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Finance (Sustainability) with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Finance (Sustainability) with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Finance (Sustainability) with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Finance with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance with a Foundation Year (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "1"
                },
                {
                    "course_name": "Finance with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Finance with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Finance with a Year in Industry (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "2"
                },
                {
                    "course_name": "French and History",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "General Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "General Engineering with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-144"
                },
                {
                    "course_name": "General Engineering with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "General Engineering with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Genetics",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Genetics",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Genetics with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88-112"
                },
                {
                    "course_name": "Geography",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Geography",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-135"
                },
                {
                    "course_name": "Geography with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-96"
                },
                {
                    "course_name": "Geography with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Geography with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Geography with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Geography with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "German and History",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Global Business Management (Top-Up Degree) (International applicants only)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Hanes a Chymraeg",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Health and Social Care with Applied Practice",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with advanced practice",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Healthcare Science (Audiology)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Healthcare Science (Cardiac Physiology)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Healthcare Science (Medical Engineering)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Healthcare Science (Neurophysiology)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Healthcare Science (Nuclear Medicine)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Healthcare Science (Radiation Physics)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Healthcare Science (Radiotherapy Physics)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Healthcare Science (Rehabilitation Engineering)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Healthcare Science (Respiratory and Sleep Physiology)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "History",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "History and American Studies",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "History and Ancient History",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "History and Politics with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "History and Welsh (a pathway for First Language Students) with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "History and Welsh (a pathway for First Language Students) with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "History and Welsh (a Pathway for Second Language Students)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "History and Welsh (a pathway for Second Language Students) with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "History and Welsh (a pathway for Second Language Students) with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "History with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72-80"
                },
                {
                    "course_name": "History with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "History with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Human Geography",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Human Geography with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Human Geography with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "International Relations",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "International Relations and American Studies",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "International Relations and American Studies with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "International Relations and American Studies with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "International Relations and History",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "International Relations and History with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120"
                },
                {
                    "course_name": "International Relations and History with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "International Relations with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72-80"
                },
                {
                    "course_name": "International Relations with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "International Relations with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "International Relations with French",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "International Relations with German",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "International Relations with Spanish",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "International Tourism Management",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "International Tourism Management with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "International Tourism Management with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Journalism, Media and Communications",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Journalism, Media and Communications with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Journalism, Media and Communications with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Law",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Law (Senior Status)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law in Practice",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Law in Practice with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Law in Practice with Criminology",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Law in Practice with Criminology with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Law in Practice with International Relations",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-136"
                },
                {
                    "course_name": "Law in Practice with International Relations with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Law in Practice with Politics",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-136"
                },
                {
                    "course_name": "Law in Practice with Politics with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Law with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Law with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Law with Criminology with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Law with Criminology with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Law with International Relations",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-136"
                },
                {
                    "course_name": "Law with International Relations with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Law with International Relations with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-136"
                },
                {
                    "course_name": "Law with Politics",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-136"
                },
                {
                    "course_name": "Law with Politics with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Law with Politics with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-136"
                },
                {
                    "course_name": "Leadership for Global Challenges",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Leadership for Global Challenges with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Leadership for Global Challenges with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Marine Biology",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Marine Biology with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Marine Biology with a Year in Applied Research",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Marine Biology with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Marketing",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Marketing (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "1"
                },
                {
                    "course_name": "Marketing with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Marketing with a Foundation Year (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "1"
                },
                {
                    "course_name": "Marketing with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Marketing with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Marketing with a Year in Industry (January Start)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "2"
                },
                {
                    "course_name": "Materials Science and Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-168"
                },
                {
                    "course_name": "Materials Science and Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-168"
                },
                {
                    "course_name": "Materials Science and Engineering with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-144"
                },
                {
                    "course_name": "Materials Science and Engineering with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-168"
                },
                {
                    "course_name": "Materials Science and Engineering with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "136-168"
                },
                {
                    "course_name": "Materials Science and Engineering with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-168"
                },
                {
                    "course_name": "Materials Science and Engineering with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-168"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MMath",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Mathematics (Secondary PGCE)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "TAR Uwchradd gyda SAC: Mathemateg",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Computer Science",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Mathematics and Computer Science with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Mathematics and Computer Science with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Mathematics and Computer Science with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Mathematics and Sport Science",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Mathematics and Sport Science with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-135"
                },
                {
                    "course_name": "Mathematics and Sport Science with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-135"
                },
                {
                    "course_name": "Mathematics for Finance",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Mathematics for Finance with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Mathematics for Finance with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Mathematics with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Mathematics with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Mathematics with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MMath",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Mathematics with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MMath (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Mathematics with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-168"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-168"
                },
                {
                    "course_name": "Mechanical Engineering with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-144"
                },
                {
                    "course_name": "Mechanical Engineering with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "136-168"
                },
                {
                    "course_name": "Mechanical Engineering with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-168"
                },
                {
                    "course_name": "Mechanical Engineering with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-168"
                },
                {
                    "course_name": "Mechanical Engineering with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-168"
                },
                {
                    "course_name": "Media and Communication",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Media and Communication with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72-80"
                },
                {
                    "course_name": "Media and Communication with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Media and Communication with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Media and English Literature",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Media and English Literature with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Media and English Literature with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Media and Welsh",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Media and Welsh with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Media and Welsh with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Medical Biochemistry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Medical Biochemistry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Medical Biochemistry with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88-112"
                },
                {
                    "course_name": "Medical Genetics",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Medical Genetics",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Medical Genetics with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88-112"
                },
                {
                    "course_name": "Medical Pharmacology",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-152"
                },
                {
                    "course_name": "Medical Pharmacology with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Medicine (Graduate Entry)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MB BCh",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology and Immunology",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Microbiology and Immunology with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88-112"
                },
                {
                    "course_name": "Microbiology and Immunology with a Placement Year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Midwifery",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BMid (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Modern Foreign Languages, Translation and Interpreting with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Modern Language \u2013 French, Spanish (Secondary PGCE)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "TAR Uwchradd gyda SAC:Ffrangeg, Sbaeneg  PGCert",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Modern Languages with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Modern Languages with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Modern Languages with Education",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Modern Languages with Education with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Modern Languages with Education with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Modern Languages, Translation and Interpreting",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Modern Languages, Translation and Interpreting with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Nursing (Adult) - Carmarthen",
                    "university": "Swansea University",
                    "location": "St David's Park, Carmarthen",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Nursing (Adult) - Carmarthen - March Entry",
                    "university": "Swansea University",
                    "location": "St David's Park, Carmarthen",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Nursing (Adult) - Swansea",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Nursing (Adult) - Swansea - March Entry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Nursing (Child)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Nursing (Dispersed Learning) (Adult)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Nursing (Dispersed Learning) (Mental Health)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Nursing (Learning Disability)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Nursing (Mental Health) - March Entry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Occupational Therapy (Full-Time)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Operating Department Practice",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Osteopathy",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MOst (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Paramedic Science",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "PGCE Primary with QTS",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MPharm",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Pharmacy with a Preparatory (Foundation) Year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MPharm (H)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88-112"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Philosophy and Politics",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Philosophy and Politics with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Philosophy and Politics with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Philosophy with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Philosophy with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Philosophy, Politics and Economics",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Philosophy, Politics and Economics with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Philosophy, Politics and Economics with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Physical Geography",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Physical Geography with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Physical Geography with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Physician Associate Studies - Postgraduate Taught",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "Master of Physician Associate Studies \u2013 MPAS",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-136"
                },
                {
                    "course_name": "Physics",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "135-144"
                },
                {
                    "course_name": "TAR Uwchradd gyda SAC: Ffiseg",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics (Secondary PGCE)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Physics with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-135"
                },
                {
                    "course_name": "Physics with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Physics with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-135"
                },
                {
                    "course_name": "Physics with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-135"
                },
                {
                    "course_name": "Physics with Particle Physics & Cosmology",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Politics",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Politics and History",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Politics and International Relations with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Politics and International Relations with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Politics and Social Policy",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Politics and Social Policy with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Politics and Social Policy with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Politics with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72-80"
                },
                {
                    "course_name": "Politics with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Politics with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Population Health and Medical Sciences",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Population Health and Medical Sciences with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Psychology",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Psychology",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Psychology with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Psychology with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Public Relations and Media",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Public Relations and Media with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72-80"
                },
                {
                    "course_name": "Public Relations and Media with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Public Relations and Media with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Pure Mathematics",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-135"
                },
                {
                    "course_name": "Pure Mathematics with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-135"
                },
                {
                    "course_name": "Pure Mathematics with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Social Policy",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Social Policy with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Social Policy with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Social Policy with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Social Work",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Social Work",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sociology",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Sociology and Psychology",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Sociology and Psychology with a Foundation year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sociology and Psychology with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Sociology and Social Policy",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Sociology and Social Policy with a Foundation year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sociology and Social Policy with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Sociology and Social Policy with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Sociology with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sociology with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Sociology with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Software Engineering with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Software Engineering with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Spanish and History",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Sport & Exercise Science",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-168"
                },
                {
                    "course_name": "Sport & Exercise Science with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-168"
                },
                {
                    "course_name": "Sport, Media and Culture",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Sport, Media and Culture with a Foundation Year",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Sport, Media and Culture with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Sport, Media and Culture with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "TESOL and English Language",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "TESOL and English Language with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "TESOL and English Language with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Theoretical Physics with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-135"
                },
                {
                    "course_name": "Theoretical Physics with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Cymraeg (llwybr iaith gyntaf)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Cymraeg gyda Blwyddyn mewn Gwaith (llwybr i fyfyrwyr iaith gyntaf)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Welsh (a Pathway for Second Language Students)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Welsh (a Pathway for Second Language Students) with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Welsh (Secondary PGCE)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "TAR Uwchradd gyda SAC: Cymraeg",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cymraeg, Cyfryngau a Chysylltiadau Cyhoeddus (Welsh, Media and Public Relations)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Cymraeg, Cyfryngau a Chysylltiadau Cyhoeddus  (Welsh, Media and Public Relations)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Zoology",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Zoology with a Year Abroad",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Zoology with a Year in Applied Research",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Zoology with a Year in Industry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Occupational Therapy (Part-Time)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "120"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Actuarial Science",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Health and Care Management (Value-Based)",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Management (Complex Systems), MSc / PGDip / PGCert",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Management (Health Innovation and Transformation)",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Practice in Health Care",
                    "university": "Swansea University",
                    "location": "St David's Park, Carmarthen",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Software Technology",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Sport Performance Science, MSc",
                    "university": "Swansea University",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "American Studies",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History and Classical Culture",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Analytical Science (LCMS)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Criminal Justice and Criminology",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Data Science",
                    "university": "Swansea University",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Linguistics",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Physics and Materials",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Approved Mental Health Professional",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence, MSc",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemical Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology (Secondary PGCE)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science (Clinical Biochemistry)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science (Clinical Microbiology)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioprocess Engineering",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biosciences",
                    "university": "Swansea University",
                    "location": "Singleton Park, Swansea",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration (MBA)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry (Secondary PGCE)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Childhood Studies",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children and Young People",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese to English Translation and Language Teaching",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "Swansea University",
                    "location": "Singleton Park, Swansea",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Psychology and Mental Health",
                    "university": "Swansea University",
                    "location": "Singleton Park, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication, Media Practice and Public Relations",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Engineering with Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Mechanics",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Modelling in Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Secondary PGCE)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science: Informatique (Swansea)",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing and Future Interaction Technologies",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing (Extended)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Crime and Terrorism",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Desalination and Water Re-Use",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design and Technology (Secondary PGCE)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Development and Human Rights",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Development Studies",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Developmental and Therapeutic Play",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Humanities",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media, Culture and Society",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth Observation",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Egyptology",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering with Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Innovation",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "EngD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Aerospace)",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Leadership and Management",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Metals for High Performance Applications in Aerospace and Related Technologies",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "EngD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English (Secondary PGCE)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Drone Remote Sensing",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Dynamics",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Dynamics and Climate Change",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Experimental Physics",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Big Data Analytics",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Analytics",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Technology (FinTech)",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fuel Technology",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genomic Medicine",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geographic Information and Climate Change",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gerontology and Ageing Studies",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Glaciology",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Biodiversity and Conservation",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Environmental Modelling",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Migration",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Wellbeing",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Care Management",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Data Science",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Economics",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Humanities",
                    "university": "Swansea University",
                    "location": "Singleton Park, Swansea",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Informatics",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Policy",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Sciences",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Healthcare Management",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Computer Interaction",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Geography",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Rights",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human-Centred Big Data and Artificial Intelligence",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intellectual Property and Innovation",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Accounting and Finance",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Banking and Finance",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial and Maritime Law",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Finance",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Financial Management",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MFin",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Journalism",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Maritime Law",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations (Extended)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Security and Development",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Security and Development (Extended)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Trade Law",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Investment Management",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Legal Practice",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "LLM",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal Practice",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal Practice and Advanced Drafting",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "LegalTech and Commercial Law",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Literary Translation",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Logic and Computation",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Artificial Intelligence)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Business Analytics)",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Digital Business)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Enterprise and Innovation)",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Finance)",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Human Resource Management)",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (International Management)",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Marketing)",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Media)",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Operations and Supply Management)",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Sport)",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Sustainable Business)",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Sustainable Business)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Engineering with Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials, Modelling and Manufacturing",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "EngD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics (Secondary PGCE)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Computing for Finance",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communication Studies",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media Geographies",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media Studies",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical and Health Care Studies",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Neuroscience",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Radiation Physics",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval Studies",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Membrane Technology",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microsensors and Actuators",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Language - French, Spanish (Secondary PGCE)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nanoelectronics",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nanomedicine",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nanotechnology",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Child)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oil, Gas and Renewable Energy Law",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Primary with QTS",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Geography",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physician Associate Studies (UK Students Only)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "Master of Physician Associate Studies \u2013 MPAS",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics (Secondary PGCE)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Power Engineering and Sustainable Energy",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Accounting",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "4 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Legal Practice",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Translation",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Translation (extended)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Translation (Extended,with Universit\u00e9 Grenoble Alpes)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health and Health Promotion",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public History and Heritage",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public History and Heritage (Extended)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Policy",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Research in Health Professions Education (RiHPE)",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "DProf",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Research Methods in Psychology",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Simulation Driven Product Design",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research Methods",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Theory and Space",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work and Social Care",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Society, Environment and Global Change, MSc",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Public Health Nursing (Health Visiting)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Public Health Nursing (School Nursing)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports and Excercise Science",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Communication and Journalism",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Staging/Screening Europe",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Accounting",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Engineering with Industry",
                    "university": "Swansea University",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Metals for Gas Turbine Applications",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "EngD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Engineering Management for International Development",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Finance",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Telecommunication Systems",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Telecommunications",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Computer Science",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Tissue Engineering and Regenerative Medicine",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Toxicology and Pharmacology",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation and Interpreting",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation and Interpreting (Extended with Universit\u00e9 Grenoble Alpes)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation and Interpreting (extended)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Studies",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Virtual Reality",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Virtual Reality with Industry",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual and Interactive Computing",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Computing",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "War and Society",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "War and Society",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Welsh (Secondary PGCE)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Welsh/Cymraeg",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Actuarial Science",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice (Primary Care)",
                    "university": "Swansea University",
                    "location": "St David's Park, Carmarthen",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Health and Care Management (Value-Based)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Management (Applied Innovation)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Management (Complex Systems), MSc / PGDip / PGCert",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Management (Health Innovation and Transformation)",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Practice in Health Care",
                    "university": "Swansea University",
                    "location": "St David's Park, Carmarthen",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Software Technology",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "American Studies",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History and Classical Culture",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Analytical Science (LCMS)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Criminal Justice and Criminology",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Linguistics",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemical Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science (Clinical Biochemistry)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science (Clinical Microbiology)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioprocess Engineering",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biosciences",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MRes",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Blood Component Transfusion",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration (MBA)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Childhood Studies",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children and Young People",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "Swansea University",
                    "location": "Singleton Park, Swansea",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Psychology and Mental Health",
                    "university": "Swansea University",
                    "location": "Singleton Park, Swansea",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Science (Medical Physics)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication, Media Practice and Public Relations",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Community and Primary Healthcare Practice",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing and Future Interaction Technologies",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MRes",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing (Extended)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Crime and Terrorism",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Desalination and Water Re-Use",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Development and Human Rights",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Development Studies",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Developmental and Therapeutic Play",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Humanities",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media, Culture and Society",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Doctor of Business Administration",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "DBA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth Observation",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Wales)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Wales): Additional Learning Needs",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Wales): Leadership",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education for the Health Professions",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Egyptology",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Innovation",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Enhanced Professional Midwifery Practice",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Enhanced Professional Practice",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Dynamics",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Dynamics and Climate Change",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fuel Technology",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genomic Medicine",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geographic Information and Climate Change",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gerontology and Ageing Studies",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Glaciology",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Biodiversity and Conservation",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Environmental Modelling",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Migration",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Wellbeing",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Care Management",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Data Science",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Economics",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Humanities",
                    "university": "Swansea University",
                    "location": "Singleton Park, Swansea",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Informatics",
                    "university": "Swansea University",
                    "location": "Singleton Park, Swansea",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Informatics",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Policy",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Sciences",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Healthcare Management",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Computer Interaction",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Geography",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Rights",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intellectual Property and Innovation",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial and Maritime Law",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "LLM",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Journalism",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Maritime Law",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations (Extended)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Security and Development",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Security and Development (Extended)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Trade Law",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "LLM",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal Practice and Advanced Drafting",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "LegalTech and Commercial Law",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Literary Translation",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Logic and Computation",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MRes",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Artificial Intelligence)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Business Analytics)",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Digital Business)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Enterprise and Innovation)",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Finance)",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Human Resource Management)",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (International Management)",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Marketing)",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Media)",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Operations and Supply Management)",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Sport)",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Sustainable Business)",
                    "university": "Swansea University",
                    "location": "Bay Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Sustainable Business)",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Computing for Finance",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communication Studies",
                    "university": "Swansea University",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media Geographies",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media Studies",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical and Health Care Studies",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Education",
                    "university": "Swansea University",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Engineering",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Neuroscience",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Radiation Physics",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval Studies",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Membrane Technology",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microsensors and Actuators",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nanoelectronics",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nanomedicine",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nanotechnology",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Non-Medical Prescribing for Allied Health Professionals",
                    "university": "Swansea University",
                    "location": "St David's Park, Carmarthen",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Non-Medical Prescribing for Nurses and Midwives",
                    "university": "Swansea University",
                    "location": "St David's Park, Carmarthen",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Non-Medical Prescribing for Pharmacists",
                    "university": "Swansea University",
                    "location": "St David's Park, Carmarthen",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oil, Gas and Renewable Energy Law",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Geography",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Power Engineering and Sustainable Energy",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Legal Practice",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Translation",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Translation (extended)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health and Health Promotion",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public History and Heritage",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Policy",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Research in Health Professions Education (RiHPE)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "DProf",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Research Methods in Psychology",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Simulation Driven Product Design",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research Methods",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Theory and Space",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work and Social Care",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Public Health Nursing (Health Visiting)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports and Excercise Science",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Communication and Journalism",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Staging/Screening Europe",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Telecommunication Systems",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Telecommunications",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Computer Science",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Tissue Engineering and Regenerative Medicine",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation and Interpreting",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation and Interpreting (extended)",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Studies",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Virtual Reality",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual and Interactive Computing",
                    "university": "Swansea University",
                    "location": "Bay Campus, Swansea",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "War and Society",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Welsh/Cymraeg",
                    "university": "Swansea University",
                    "location": "Singleton Park Campus",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "3939b9542026cfc4f9f895842ae21243",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Worcester",
                    "location": "Birmingham Metropolitan College (Matthew Boulton College)",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Accounting and Finance (Placement Year)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Animation and Filmmaking",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Animation and Game Art",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Animation and Graphic Design",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Animation and Illustration",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Animation and Screenwriting",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Applied Health & Social Care (top-up)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Artificial Intelligence (Placement Year)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Assistant Practitioner",
                    "university": "University of Worcester",
                    "location": "Multiple Locations",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Biological Sciences (Biochemistry with Foundation Year)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Biological Sciences (Biochemistry)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-104"
                },
                {
                    "course_name": "Biological Sciences (Biology with Foundation Year)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences (Biology)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-104"
                },
                {
                    "course_name": "Biological Sciences (Zoology with Foundation Year)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Biological Sciences (Zoology)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-104"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-104"
                },
                {
                    "course_name": "Biomedical Science (with Foundation Year)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business & Finance",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business & Finance (Placement Year)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business & Human Resource Management",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business & Human Resource Management (Placement Year)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business & Marketing",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business & Marketing (Placement Year)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management (Placement Year)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management (top-up)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Business Psychology",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Studies",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Studies (Placement Year)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Child & Adolescent Mental Health",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Child & Adolescent Mental Health (top-up)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children and Young People's Mental Health and Wellbeing",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Children and Young People's Mental Health and Wellbeing with Foundation Year",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Clinical Psychology",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computing",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computing (Placement Year)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computing with Foundation Year",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Counselling",
                    "university": "University of Worcester",
                    "location": "Herefordshire, Ludlow and North Shropshire College",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Counselling Psychology",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Writing and English Language",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Writing and English Literature",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Writing and History",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Writing and Illustration",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Writing and Media & Film Studies",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Writing and Screenwriting",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Cricket Coaching & Management (Integrated Masters)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "MSport",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology (study in Dudley)",
                    "university": "University of Worcester",
                    "location": "Dudley College of Technology (The Broadway)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology and Psychology",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology with Forensic Psychology",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology with Policing",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology with Politics",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Cyber Security (Placement Year)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Dance and Performance",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Dental Technology",
                    "university": "University of Worcester",
                    "location": "Birmingham Metropolitan College (Matthew Boulton College)",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Diagnostic Radiography",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Diagnostic Radiography with Foundation Year",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Digital Marketing (Placement Year)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Early Childhood (Graduate Practitioner)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Early Childhood (Social Pedagogy)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Early Years (0-8) (Professional Practice)",
                    "university": "University of Worcester",
                    "location": "Multiple Locations",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Early Years (0-8) Foundation Degree",
                    "university": "University of Worcester",
                    "location": "Multiple Locations",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Education and Development (Outdoors Learning)",
                    "university": "University of Worcester",
                    "location": "Somerset Centre for Integrated Learning",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Education and Development (SEND)",
                    "university": "University of Worcester",
                    "location": "Somerset Centre for Integrated Learning",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Education and Development (Teaching and Learning)",
                    "university": "University of Worcester",
                    "location": "Somerset Centre for Integrated Learning",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Education Studies",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Education Studies and English Language",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Education Studies and English Literature",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Education Studies and Psychology",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Education Studies and Sociology",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Language and English Literature",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Language and Journalism",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Language and Media & Film Studies",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Language and Sociology",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English Literature and History",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Literature and Journalism",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Literature and Media & Film Studies",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Literature and Theatre, Acting & Performance",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Environmental Management & Sustainability",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Environmental Management & Sustainability with Foundation and Professional Placement Years",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Management & Sustainability with Foundation Year",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Management & Sustainability with Professional Placement Year",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Filmmaking",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Filmmaking and Media & Film Studies",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Filmmaking and Screenwriting",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Fine Art and Illustration",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Fine Art with Psychology",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Forensic & Applied Biology",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-104"
                },
                {
                    "course_name": "Forensic & Applied Biology (with Foundation Year)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Foundation Degree Criminology",
                    "university": "University of Worcester",
                    "location": "Birmingham Metropolitan College (Matthew Boulton College)",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Game Art",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Geography with International Year Abroad",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Graphic Design and Illustration",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Graphic Design, Advertising & Marketing",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Health & Social Care",
                    "university": "University of Worcester",
                    "location": "Herefordshire, Ludlow and North Shropshire College",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Health & Social Care",
                    "university": "University of Worcester",
                    "location": "Herefordshire, Ludlow and North Shropshire College",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "History",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "History and Journalism",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "History and Sociology",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "History with Politics",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Illustration",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Integrated Working with Children and Families (top-up)",
                    "university": "University of Worcester",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Integrative Counselling",
                    "university": "University of Worcester",
                    "location": "Iron Mill College (Exeter Campus or Poole Campus)",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Integrative Counselling (top-up)",
                    "university": "University of Worcester",
                    "location": "Iron Mill College (Exeter Campus or Poole Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management (Placement Year)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Finance (top-up)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Journalism",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Journalism and Media & Film Studies",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Journalism and Screenwriting",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Law",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Law with Forensic Psychology",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Law with History",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Law with Policing",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Law with Politics",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Learning Support",
                    "university": "University of Worcester",
                    "location": "Halesowen College",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Marketing, Advertising & Public Relations",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Marketing, Advertising & Public Relations (Placement Year)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Media & Film Studies",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Media & Film Studies and Screenwriting",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Media & Film Studies and Sociology",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Medical Sciences",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-104"
                },
                {
                    "course_name": "Medical Sciences (with Foundation Year)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine (Graduate Entry)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "MB ChB",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Musical Theatre",
                    "university": "University of Worcester",
                    "location": "Millennium Performing Arts",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-96"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "University of Worcester",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing (Adult) with Foundation Year",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Nursing (Child)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing (Mental Health) with Foundation Year",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Nursing Associate",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Nutrition and Dietetics",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Nutrition and Dietetics with Foundation Year",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Occupational Therapy with Foundation Year",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Paramedic Science",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Paramedic Science with Foundation Year",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Physical Education",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Physical Education and Sports Coaching",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Physician Associate (Postgraduate)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Physiotherapy with Foundation Year",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Primary Education with QTS (3-7 years)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Primary Education with QTS (5-11 years)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Professional Practice",
                    "university": "University of Worcester",
                    "location": "Somerset Centre for Integrated Learning",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology and Sociology",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology with Politics",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Screenwriting and Theatre, Acting & Performance",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Social Work (Postgraduate Entry)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sociology with Politics",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Special Educational Needs, Disabilities & Inclusion",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Sport & Exercise Psychology",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport Business Management",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport Business Management (with Placement Year)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sports and Exercise Science",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sports Coaching",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sports Coaching and Physical Education",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sports Performance Coaching",
                    "university": "University of Worcester",
                    "location": "Birmingham Metropolitan College (Sutton Coldfield College)",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Sports Therapy",
                    "university": "University of Worcester",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Teaching & Learning Support",
                    "university": "University of Worcester",
                    "location": "Multiple Locations",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Theatre, Acting & Performance",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Touring Theatre",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "MTheatre",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Allied Health Studies",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Biology",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Criminology",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Sport Physiology",
                    "university": "University of Worcester",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Sports Performance Analysis",
                    "university": "University of Worcester",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Design",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Atmospheric Sciences",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business",
                    "university": "University of Worcester",
                    "location": "City Campus",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Psychology",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing",
                    "university": "University of Worcester",
                    "location": "City Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Media",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Media",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cultural Studies",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dementia Studies",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama and Performance",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecology",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Early Childhood)",
                    "university": "University of Worcester",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Leadership and Management)",
                    "university": "University of Worcester",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Mentoring and Coaching)",
                    "university": "University of Worcester",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Special and Inclusive Education)",
                    "university": "University of Worcester",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Language",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Studies/Science",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Healthcare Management and Leadership",
                    "university": "University of Worcester",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Biology",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Geography",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Worcester",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management",
                    "university": "University of Worcester",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Sport Management",
                    "university": "University of Worcester",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal Practice",
                    "university": "University of Worcester",
                    "location": "No Locations",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Logistics and Supply Chain Management",
                    "university": "University of Worcester",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Worcester",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration",
                    "university": "University of Worcester",
                    "location": "No Locations",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Cultural Studies",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Culture",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery (Shortened)",
                    "university": "University of Worcester",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nutrition and Lifestyle Medicine",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Psychology",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Therapy (Pre-registration)",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE - Further Education",
                    "university": "University of Worcester",
                    "location": "No Locations",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE - Primary",
                    "university": "University of Worcester",
                    "location": "No Locations",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE - Primary - Physical Education Specialism",
                    "university": "University of Worcester",
                    "location": "No Locations",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE - Secondary - Art and Design",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE - Secondary - Business with Economics",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE - Secondary - Computer Science",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE - Secondary - Design and Technology (Food)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE - Secondary - English",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE - Secondary - Geography",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE - Secondary - History",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE - Secondary - Mathematics",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE - Secondary - Modern Languages",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE - Secondary - Physical Education",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE - Secondary - Psychology",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE - Secondary - Religious Education",
                    "university": "University of Worcester",
                    "location": "No Locations",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE - Secondary - Science: Biology",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE - Secondary - Science: Chemistry",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE - Secondary - Science: Physics",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Primary with Special Educational Needs, Disability and Inclusion (SENDI)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Geography",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy (Pre-registration)",
                    "university": "University of Worcester",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Plant Biology",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pre-Masters Studies",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "Cert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management",
                    "university": "University of Worcester",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Worcester",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work and Social Policy",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Sciences",
                    "university": "University of Worcester",
                    "location": "Riverside Centre",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Coaching",
                    "university": "University of Worcester",
                    "location": "Riverside Centre",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Understanding Domestic and Sexual Violence",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Clinical Practice",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Social Work Practice",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Allied Health Studies",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Biology",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Sport Physiology",
                    "university": "University of Worcester",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Sports Performance Analysis",
                    "university": "University of Worcester",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Design",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Atmospheric Sciences",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business",
                    "university": "University of Worcester",
                    "location": "City Campus",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Psychology",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing",
                    "university": "University of Worcester",
                    "location": "Multiple Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Media",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Media",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cultural Studies",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dementia Studies",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama and Performance",
                    "university": "University of Worcester",
                    "location": "Multiple Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecology",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Early Childhood)",
                    "university": "University of Worcester",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Leadership and Management)",
                    "university": "University of Worcester",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Mentoring and Coaching)",
                    "university": "University of Worcester",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Special and Inclusive Education)",
                    "university": "University of Worcester",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Language",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Studies/Science",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Executive Master of Business Administration (EMBA)",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "MBA (Executive)",
                    "duration": "2.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Healthcare Management and Leadership",
                    "university": "University of Worcester",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MRes",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Biology",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Geography",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Worcester",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Independent and Supplementary Prescribing",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "Postgraduate Award",
                    "duration": "0.5 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management",
                    "university": "University of Worcester",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Sport Management",
                    "university": "University of Worcester",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leading Culture Change in Safeguarding",
                    "university": "University of Worcester",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Learning and Teaching in Higher Education",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal Practice",
                    "university": "University of Worcester",
                    "location": "No Locations",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Logistics and Supply Chain Management",
                    "university": "University of Worcester",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Worcester",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration",
                    "university": "University of Worcester",
                    "location": "No Locations",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Cultural Studies",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Culture",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health and Wellbeing Practitioner in Specialist Adult Mental Health",
                    "university": "University of Worcester",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "National Award SENCo (NASENCO) (Special Educational Needs Coordination)",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nutrition and Lifestyle Medicine",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Psychology",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Outdoor Education",
                    "university": "University of Worcester",
                    "location": "Riverside Centre",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Person-Centred Dementia Studies",
                    "university": "University of Worcester",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE - Primary",
                    "university": "University of Worcester",
                    "location": "No Locations",
                    "qualification": "PGCE",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Geography",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Plant Biology",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Practice",
                    "university": "University of Worcester",
                    "location": "City Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management",
                    "university": "University of Worcester",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work and Social Policy",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work Practice Education",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "Professional Award",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Sciences",
                    "university": "University of Worcester",
                    "location": "Riverside Centre",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Coaching",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Coaching",
                    "university": "University of Worcester",
                    "location": "Riverside Centre",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Tutoring and Coaching",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Understanding Domestic and Sexual Violence",
                    "university": "University of Worcester",
                    "location": "St John's Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "fd3ddef373e40ea002f4bc7e2d8e7f6b",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accountancy",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accountancy and Business Management",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accountancy and Economics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accountancy and Finance",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accountancy and French (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accountancy and German (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accountancy and Legal Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accountancy and Spanish & Latin American Studies (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Behaviour",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Behaviour and Welfare (advanced entry only)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and Archaeology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and English",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and Film & Visual Culture",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and French",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and French (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and Gaelic Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and Geography",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and German",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and German (5yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and History",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and International Relations",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and Language & Linguistics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and Philosophy",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and Politics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and Psychology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and Sociology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and Spanish & Latin American Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and Spanish & Latin American Studies (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and Theology & Religion",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Mathematics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Mathematics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and Art History",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and Celtic & Anglo Saxon Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and Gaelic Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and History",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and Language & Linguistics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History and Celtic & Anglo Saxon Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History and English",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History and Film & Visual Culture",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History and French",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History and French (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History and History",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History and Music",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History and Spanish & Latin American Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History and Spanish & Latin American Studies (5 years)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History and Theology & Religion",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology with Science (PGDE - Graduates only)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Education (PGDE - Graduates only)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and Economics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and English",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and Finance",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and French",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and French (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and Gaelic Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and Geography",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and German",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and German (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and History",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and Information Systems",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and Information Systems",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and International Relations",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and Legal Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and Mathematics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "108-152"
                },
                {
                    "course_name": "Business Management and Politics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and Psychology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and Real Estate",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and Sociology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and Spanish & Latin American Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and Spanish & Latin American Studies (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic & Anglo Saxon Studies and English",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic & Anglo Saxon Studies and Film & Visual Culture",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic & Anglo Saxon Studies and French",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic & Anglo Saxon Studies and French (5yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic & Anglo Saxon Studies and Gaelic",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic & Anglo Saxon Studies and German",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic & Anglo Saxon Studies and German (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic & Anglo Saxon Studies and History",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic & Anglo Saxon Studies and Language & Linguistics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic & Anglo Saxon Studies and Philosophy",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic & Anglo Saxon Studies and Sociology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic & Anglo Saxon Studies and Spanish & Latin American Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic & Anglo Saxon Studies and Spanish & Latin American Studies (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic & Anglo Saxon Studies and Theology & Religion",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic and Anglo Saxon Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Science (PGDE - Graduates only)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil and Environmental Engineering",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Civil and Structural Engineering",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Civil Engineering with Subsea Technology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Comparative Literature and Culture",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature and Culture (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature and Culture and French (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature and Culture and Gaelic",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature and Culture and German (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature and Culture and Mandarin",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature and Culture and Spanish & Latin American Studies (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature and Culture with French (4yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature and Culture with Gaelic (4yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature and Culture with German (4yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature and Culture with Mandarin (4yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature and Culture with Spanish & Latin American Studies (4yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "108-152"
                },
                {
                    "course_name": "Computing and Music",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "108-128"
                },
                {
                    "course_name": "Computing Science",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Computing Science",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science (Artificial Intelligence)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Computing Science (Cybersecurity)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Computing Science and Mathematics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science and Physics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing with Industrial Placement",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Conservation Biology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling, Psychology and Education",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "108-152"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dental Surgery (Graduates Only)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BDS",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Divinity",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BD (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama (PGDE - Graduates only)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and French",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and French (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Gaelic Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Geography",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and German",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and German (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and History",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and International Relations",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Legal Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Mathematics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Philosophy",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Politics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Psychology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Sociology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Spanish & Latin American Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Spanish & Latin American Studies (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Electrical and Electronic Engineering with Renewable Energy",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Electrical and Electronic Engineering with Robotics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Electronic and Software Engineering",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Engineering",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Engineering (Civil and Environmental)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Civil and Structural)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Civil)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Electrical & Electronic)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Electronic and Software)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Mechanical and Electrical)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Mechanical with Oil and Gas Studies)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Mechanical)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English (PGDE - Graduates only)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Film & Visual Culture",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and French",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and French (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Gaelic Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and German",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and German (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and History",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and International Relations",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Language & Linguistics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Philosophy",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Scottish Literature",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Sociology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Spanish & Latin American Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Spanish & Latin American Studies (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Theology & Religion",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Literature",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English with Creative Writing",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English with Music Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Exercise and Health Science",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Visual Culture and French",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Visual Culture and French (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Visual Culture and Gaelic Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Visual Culture and German",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Visual Culture and German (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Visual Culture and History",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Visual Culture and International Relations",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Visual Culture and Philosophy",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Visual Culture and Sociology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Visual Culture and Spanish & Latin American Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Visual Culture and Spanish & Latin American Studies (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Visual Culture",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and French",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and French (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and German",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and German (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and International Relations",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Real Estate",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Spanish & Latin American Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Spanish & Latin American Studies (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French (PGDE - Graduates only)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Gaelic Studies (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Geography (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and German (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and History",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and History (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and International Relations",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and International Relations (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Language & Linguistics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Language & Linguistics (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Legal Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Legal Studies (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Music",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Music (5yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Philosophy",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Philosophy (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Politics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Politics (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Sociology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Sociology (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Spanish & Latin American Studies (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gaelic Studies and Geography",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gaelic Studies and German (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gaelic Studies and History",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gaelic Studies and Language & Linguistics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gaelic Studies and Music",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gaelic Studies and Politics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gaelic Studies and Sociology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gaelic Studies and Spanish & Latin American Studies (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gaelic Studies and Theology & Religion",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gateway to Medicine (G2M)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "General Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography (PGDE - Graduates only)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography and German",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography and German (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography and International Relations",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography and Sociology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography and Spanish & Latin American Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography and Spanish & Latin American Studies (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology and Physics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geoscience",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German (PGDE - Graduates only)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and History",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and History (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and International Relations",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and International Relations (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Language & Linguistics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Language & Linguistics (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Legal Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Legal Studies (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Mathematics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "108-152"
                },
                {
                    "course_name": "German and Mathematics (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "108-152"
                },
                {
                    "course_name": "German and Music",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Music (5yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Philosophy",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Philosophy (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Politics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Politics (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Sociology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Sociology (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Spanish & Latin American Studies (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History (PGDE - Graduates only)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and International Relations",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Language & Linguistics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Legal Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Music",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Philosophy",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Politics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Sociology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Spanish & Latin American Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Spanish & Latin American Studies (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Theology & Religion",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History with Music Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business (5 years)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MBus",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with French",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with French (5 years)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MBus",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Gaelic",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Gaelic (5 years)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MBus",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with German",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with German (5 years)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MBus",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Mandarin",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Mandarin (5 years)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MBus",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Spanish",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Spanish (5 years)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MBus",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law and Comparative Law",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Language & Linguistics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Legal Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Sociology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Spanish & Latin American Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Spanish & Latin American Studies (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Theology & Religion",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language & Linguistics and Philosophy",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language & Linguistics and Sociology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language & Linguistics and Spanish & Latin American Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language & Linguistics and Spanish & Latin American Studies (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language and Linguistics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language and Linguistics & Psychology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language and Linguistics with Mandarin",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law - Accelerated (Graduates only)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLB",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and European Legal Studies (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and French Law (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and German Law (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Spanish Law (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Computing Science",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with English Law",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with English Law - Accelerated (Graduates only)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLB",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with English Law and European Legal Studies (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with options in Accountancy",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with options in Business Management",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with options in Economics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with options in French",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with options in Gaelic Language",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with options in German",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with options in Mandarin",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with options in Music",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with options in Spanish",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal Studies and Philosophy",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal Studies and Politics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal Studies and Psychology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal Studies and Spanish & Latin American Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal Studies and Spanish & Latin American Studies (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mandarin (PGDE - Graduates only)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Biology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "108-128"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics (PGDE - Graduates only)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Philosophy",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "108-128"
                },
                {
                    "course_name": "Mathematics and Physics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Spanish & Latin American Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "108-128"
                },
                {
                    "course_name": "Mathematics and Spanish & Latin American Studies (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "108-128"
                },
                {
                    "course_name": "Mathematics with Gaelic",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Gaelic",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "108-128"
                },
                {
                    "course_name": "Mechanical and Electrical Engineering",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Mechanical Engineering with Biomechanics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Mechanical Engineering with Management",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Mechanical Engineering with Subsea Technology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Medicine",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MB ChB",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages and Translation & Interpretation Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Studies (PGDE - Graduates only)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular and Cellular Biology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BMus (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music (PGDE - Graduates only)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Philosophy",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "108-152"
                },
                {
                    "course_name": "Music and Spanish & Latin American Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Spanish & Latin American Studies (5yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Theology & Religion",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "108-152"
                },
                {
                    "course_name": "Music Education",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BMus (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Philosophy",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "108-152"
                },
                {
                    "course_name": "Neuroscience with Psychology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Petroleum Engineering",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Physics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "108-152"
                },
                {
                    "course_name": "Philosophy and Politics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Psychology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Sociology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Theology & Religion",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy, Politics and Economics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Sciences",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Geology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Philosophy",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Science (PGDE - Graduates only)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Plant and Soil Science",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Sociology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Spanish & Latin American Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Spanish & Latin American Studies (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Theology & Religion",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary (PGDE - Graduates only)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Education",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Online (PGDE - Graduates only)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "18 months",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Sociology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with Counselling Skills",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with French",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with French",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with Gaelic",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with German",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religious, Moral and Philosophical Studies (PGDE - Graduates only)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scottish Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Spanish & Latin American Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Spanish & Latin American Studies (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Theology & Religion",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish & Latin American Studies and Theology & Religion",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish & Latin American Studies and Theology & Religion (5 yrs)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish (PGDE - Graduates only)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports and Exercise Science",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BTh (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religion",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Chemical Engineering",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice (Blended Learning)",
                    "university": "University of Aberdeen",
                    "location": "Foresterhill Campus",
                    "qualification": "MSc (PG)",
                    "duration": "36 months",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Mechanical Engineering",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Structural Engineering",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropological Research",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Health Sciences",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology of the North",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioarchaeological Science",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomolecular Archaeology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology and Bioinformatics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration (Business Analytics)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Consulting and Psychology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Law and Sustainable Development",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cardiovascular Science and Diabetes",
                    "university": "University of Aberdeen",
                    "location": "Foresterhill Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry for Sustainable Energy",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Christianity and the Visual Arts",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MLitt",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Pharmacology",
                    "university": "University of Aberdeen",
                    "location": "Foresterhill Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MLitt",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Corporate Finance and Law",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MLitt",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Justice",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Justice and Human Rights",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cultural and Creative Communication",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cultural Heritage",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cybersecurity",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cybersecurity and Machine Learning",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Business Management",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Decommissioning",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drug Discovery and Development",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Economics and Law",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Politics and Law",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Transition Law with Dissertation",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Transition Law with Professional Skills",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Transition Systems and Technologies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy, Economics and Finance",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc (Econ)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Literature",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MLitt",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Management",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ethnology and Folklore",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MLitt",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film, Visual Culture and Arts Management",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MLitt",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Investment Management",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc (Econ)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Investment Management (with CFA Level 1 Examination)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc (Econ)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Real Estate",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Real Estate (with CFA Level 1 Examination)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Technology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "General Law",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geographical Information Systems",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geophysics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Business Communication",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health and Management",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Data Science",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Nutrition",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Immunology and Immunotherapy",
                    "university": "University of Aberdeen",
                    "location": "Foresterhill Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Industrial Robotics",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Technology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Technology with Cybersecurity",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrated Petroleum Geoscience",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International and Comparative Law",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Finance",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law with Dissertation",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law with Professional Skills",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Dispute Resolution with Dissertation",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Dispute Resolution with Professional Skills",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Finance and Political Relations",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Resource Management",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Rights",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Intellectual Property and Information Law with Dissertation",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Intellectual Property and Information Law with Professional Skills",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law and International Relations",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law with Professional Skills",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Political Economy",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and International Law",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Management",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Trade Law",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Trade Law and Treaty Negotiation with Professional Skills",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Literatures, Environments and Places",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MLitt",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing Management",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Imaging",
                    "university": "University of Aberdeen",
                    "location": "Foresterhill Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Physics",
                    "university": "University of Aberdeen",
                    "location": "Foresterhill Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval and Early Modern Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MLitt",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology",
                    "university": "University of Aberdeen",
                    "location": "Foresterhill Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern History",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MLitt",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular Medicine",
                    "university": "University of Aberdeen",
                    "location": "Foresterhill Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Museum Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MLitt",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MMus",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Resources Law",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Offshore Engineering",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oil and Gas Engineering",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oil and Gas Enterprise Management",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oil and Gas Law with Dissertation",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oil and Gas Law with Professional Skills",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Osteoarchaeology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Peace and Conflict Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Peace, Security and International Law",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Person-Centred Counselling",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Petroleum Data Management",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Petroleum Engineering",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physician Associate Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Planetary Sciences",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Policy Evaluation",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Precision Medicine and Pharmacological Innovation",
                    "university": "University of Aberdeen",
                    "location": "Foresterhill Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Process Safety",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "University of Aberdeen",
                    "location": "Foresterhill Campus",
                    "qualification": "MPH",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate (Commercial Option)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate (International Option)",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Renewable Energy Engineering",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Reproductive and Developmental Biology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics and Artificial Intelligence",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Rural Surveying & Rural Property Management",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MLE",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Safety and Reliability Engineering",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Studies and Diplomacy",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Studies and Energy Security",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Studies and International Law",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Studies and Management",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Subsea Engineering",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Subsurface Energy Engineering",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability Transitions",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Energy Geoscience",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Product, Process and Energy Engineering",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Water Management",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "TESOL - Teaching English to Speakers of Other Languages",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MTh",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translating and Interpreting Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Viking and Medieval Nordic Studies",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MLitt",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Strategic Studies and Diplomacy",
                    "university": "University of Aberdeen",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "80deeb96d19a0fe1a947f952f1839e76",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-144"
                },
                {
                    "course_name": "Accounting and Finance with Business Analytics",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-144"
                },
                {
                    "course_name": "Accounting and Finance with Law",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-102"
                },
                {
                    "course_name": "Accounting and Finance with People Management",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-144"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-144"
                },
                {
                    "course_name": "Business Management",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-144"
                },
                {
                    "course_name": "Business Management with Analytics",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-144"
                },
                {
                    "course_name": "Business Management with Events Management",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-144"
                },
                {
                    "course_name": "Business Management with Law",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-102"
                },
                {
                    "course_name": "Business Management with People Management",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-144"
                },
                {
                    "course_name": "Civil and Environmental Engineering",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-144"
                },
                {
                    "course_name": "Civil and Environmental Engineering",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Computer Arts",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-160"
                },
                {
                    "course_name": "Computer Game Applications Development",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-160"
                },
                {
                    "course_name": "Computer Games Technology",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-160"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-108"
                },
                {
                    "course_name": "Computer Science with Cybersecurity",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-152"
                },
                {
                    "course_name": "Computing",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-152"
                },
                {
                    "course_name": "Criminology",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-152"
                },
                {
                    "course_name": "Criminology and Policing",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-152"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-152"
                },
                {
                    "course_name": "Digital Marketing and Business Management",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-144"
                },
                {
                    "course_name": "Digital Marketing with Events Management",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-144"
                },
                {
                    "course_name": "Ethical Hacking",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-152"
                },
                {
                    "course_name": "Fitness, Nutrition and Health",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-144"
                },
                {
                    "course_name": "Food and Consumer Science",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-144"
                },
                {
                    "course_name": "Food Science, Nutrition and Wellbeing",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-102"
                },
                {
                    "course_name": "Food, Nutrition and Health",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-144"
                },
                {
                    "course_name": "Forensic Sciences",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-144"
                },
                {
                    "course_name": "Game Design and Production",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-160"
                },
                {
                    "course_name": "Law",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-108"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-108"
                },
                {
                    "course_name": "Marketing and Business Management",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-144"
                },
                {
                    "course_name": "Mental Health Nursing",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-102"
                },
                {
                    "course_name": "Physical Activity and Health",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-152"
                },
                {
                    "course_name": "Psychology",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-160"
                },
                {
                    "course_name": "Psychology and Counselling",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-108"
                },
                {
                    "course_name": "Psychology with Development and Education",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-160"
                },
                {
                    "course_name": "Psychology with Forensic and Investigative Psychology",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-160"
                },
                {
                    "course_name": "Psychology with Forensic Scene Investigation",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-160"
                },
                {
                    "course_name": "Psychology with Health Sciences",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-160"
                },
                {
                    "course_name": "Sport & Exercise",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-152"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-152"
                },
                {
                    "course_name": "Sport and Management",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-144"
                },
                {
                    "course_name": "Sports Development and Coaching",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-152"
                },
                {
                    "course_name": "Strength and Conditioning",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-152"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance (CIMA Gateway)",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Artificial Intelligence and User Experience",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences with Industrial Practise",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Games Technology",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction and Project Management",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing and e-Business",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment and Business",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment and Energy",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ethical Hacking and Cyber Security",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fintech",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Quality and Safety with Industrial Practise",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Quality, Safety and Innovation",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Development",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MProf",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global MBA",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Resource Management",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Technical Art and Visual Effects",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Water and Environmental Management",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Biomedical Sciences",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences with Industrial Practise",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MSc",
                    "duration": "28 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Games Technology",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction and Project Management",
                    "university": "Abertay University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing and e-Business",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment and Business",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment and Energy",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fintech",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Quality and Safety with Industrial Practise",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Quality, Safety and Innovation",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global MBA",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MBA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Resource Management",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Technical Art and Visual Effects",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Water and Environmental Management",
                    "university": "Abertay University",
                    "location": "Abertay Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "ccece12394930de41dca2155ffa392d4",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "9cdb3ddad5f9cf5619d09ffd5527e49b",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "0808df817c98d55d814f013eaec3281a",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Animal Behaviour and Welfare",
                    "university": "Activate Learning",
                    "location": "Merrist Wood College",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Computing for England (HTQ)",
                    "university": "Activate Learning",
                    "location": "Reading College",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Counselling",
                    "university": "Activate Learning",
                    "location": "Guildford College",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling Top-up",
                    "university": "Activate Learning",
                    "location": "Guildford College",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Technologies (Cyber Security and Cloud Networking)",
                    "university": "Activate Learning",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32-96"
                },
                {
                    "course_name": "Digital Technologies (Data Analytics)",
                    "university": "Activate Learning",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32-96"
                },
                {
                    "course_name": "Digital Technologies (Software Development and Programming)",
                    "university": "Activate Learning",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32-96"
                },
                {
                    "course_name": "Digital Technologies - Cyber Security",
                    "university": "Activate Learning",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Technologies \u2013 Cloud Networking",
                    "university": "Activate Learning",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership and Management  (Online)",
                    "university": "Activate Learning",
                    "location": "Online",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership and Management HTQ (Online)",
                    "university": "Activate Learning",
                    "location": "Online",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Wildlife and Conservation",
                    "university": "Activate Learning",
                    "location": "Merrist Wood College",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Wildlife and Conservation (Top-up)",
                    "university": "Activate Learning",
                    "location": "Merrist Wood College",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoo Management",
                    "university": "Activate Learning",
                    "location": "Merrist Wood College",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Zoo Management (Top-up)",
                    "university": "Activate Learning",
                    "location": "Merrist Wood College",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Wildlife and Conservation",
                    "university": "Activate Learning",
                    "location": "Merrist Wood College",
                    "qualification": "FdSc",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Wildlife and Conservation (Top-up)",
                    "university": "Activate Learning",
                    "location": "Merrist Wood College",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoo Management",
                    "university": "Activate Learning",
                    "location": "Merrist Wood College",
                    "qualification": "FdSc",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Zoo Management (Top-up)",
                    "university": "Activate Learning",
                    "location": "Merrist Wood College",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "ef30296fad0948181d22fdf23426660f",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "db0cc6792e9ed891fff443bcdf7815f3",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "e265ccc553cc66c6d64fa6dd2dc1a9d9",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Architecture and Urban Design (Projective Cities)",
                    "university": "Architectural Association School of Architecture",
                    "location": "School of Architecture",
                    "qualification": "MPhil",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture and Urbanism (DRL)",
                    "university": "Architectural Association School of Architecture",
                    "location": "School of Architecture",
                    "qualification": "MArch",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation and Reuse",
                    "university": "Architectural Association School of Architecture",
                    "location": "School of Architecture",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design and Make",
                    "university": "Architectural Association School of Architecture",
                    "location": "School of Architecture",
                    "qualification": "MArch",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Emergent Technologies and Design",
                    "university": "Architectural Association School of Architecture",
                    "location": "School of Architecture",
                    "qualification": "MArch",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Critical Thinking in Architecture",
                    "university": "Architectural Association School of Architecture",
                    "location": "School of Architecture",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Housing and Urbanism",
                    "university": "Architectural Association School of Architecture",
                    "location": "School of Architecture",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Landscape Urbanism",
                    "university": "Architectural Association School of Architecture",
                    "location": "School of Architecture",
                    "qualification": "MArch",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spatial Performance and Design (AAIS)",
                    "university": "Architectural Association School of Architecture",
                    "location": "School of Architecture",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Environmental Design",
                    "university": "Architectural Association School of Architecture",
                    "location": "School of Architecture",
                    "qualification": "MArch",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Conservation and Reuse",
                    "university": "Architectural Association School of Architecture",
                    "location": "School of Architecture",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "99c3caf8993111f132e22cd2b0006cbc",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "efd3491fd7337ee79490bafe58246010",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "3ba0858ea65642553231e87ee3cb1b12",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Animation & Games",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Animation & Games (Extended Degree)",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Commercial Photography",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Commercial Photography (Extended Degree)",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Costume Production",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Costume Production (Extended Degree)",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Craft & Material Practices",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Craft & Material Practices (Extended Degree)",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fashion Design",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fashion Design (Extended Degree)",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fashion Media & Marketing",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fashion Media & Marketing (Extended Degree)",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Film & Screen Arts",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Film & Screen Arts (Extended Degree)",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fine Art (Extended Degree)",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Graphic Design (Extended Degree)",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Illustration",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Illustration (Extended Degree)",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Interior Design & Styling  (Extended Degree)",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Interior Design and Styling",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Painting Drawing and Printmaking",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Painting Drawing and Printmaking (Extended Degree)",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Photography",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Photography (Extended Degree)",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Textile Design",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Textile Design (Extended Degree)",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Animation & Games",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Commercial Photography",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Costume Production",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Craft & Material Practices",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fashion Design",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fashion Media & Marketing",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Film & Screen Arts",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Illustration",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Interior Design and Styling",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Painting Drawing and Printmaking",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Photography",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Textile Design",
                    "university": "Arts University Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-120"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "4c0fdb0681f83d6307b186c63d46ef45",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Business and Events Management",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business and Events Management with Foundation Year (BSc)",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "33"
                },
                {
                    "course_name": "Business and Healthcare Management",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business and Healthcare Management with Foundation Year",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Hospitality Management",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business and Hospitality Management with Foundation Year",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Human Resource Management",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business and Human Resource Management with Foundation Year",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Law",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business and Law with Foundation Year",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business and Marketing",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business and Marketing with Foundation Year",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Tourism Management",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business and Tourism Management with Foundation Year",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Accounting",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Finance and Accounting with Foundation Year",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Health and Social Care with Foundation Year",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "International Business Management with Foundation Year",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "LLB",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Law LLB with Foundation Year",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "33"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Financial Management",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Hospitality and Tourism Management",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Marketing",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Project Management",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "LLM Law",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration (MBA) - International",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "MBA (International)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration (MBA) \u2013 Health and Social Care",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Accounting and Financial Management",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Hospitality and Tourism Management",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Marketing",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Project Management",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "LLM Law",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration (MBA) - International",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "MBA (International)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration (MBA) \u2013 Health and Social Care",
                    "university": "ARU London",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "8a88409d9f0d37327c0c85dc737b31b9",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "a64d3f742b90453585278ef5c72ae7ec",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "6ddefec3ee09d5d4ebef09c38d8a9ece",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence and Robotics",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and International Relations",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Politics",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Sociology",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Supply Chain Management",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-128"
                },
                {
                    "course_name": "Business Computing and IT",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Enterprise Development",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-144"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-128"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-128"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-128"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-128"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-128"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-128"
                },
                {
                    "course_name": "Criminology",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cybersecurity",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-128"
                },
                {
                    "course_name": "Design Engineering",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Management",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Politics",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-128"
                },
                {
                    "course_name": "Electronic Engineering and Computer Science",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-128"
                },
                {
                    "course_name": "Electronic Engineering and Computer Science",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering and Physical Sciences Foundation Programme: Artificial Intelligence and Robotics",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering and Physical Sciences Foundation Programme: Biomedical Engineering",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering and Physical Sciences Foundation Programme: Business and Supply Chain Management",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering and Physical Sciences Foundation Programme: Chemical Engineering",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering and Physical Sciences Foundation Programme: Civil Engineering",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering and Physical Sciences Foundation Programme: Computer Science",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering and Physical Sciences Foundation Programme: Construction Project Management",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering and Physical Sciences Foundation Programme: Cybersecurity",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering and Physical Sciences Foundation Programme: Design Engineering",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering and Physical Sciences Foundation Programme: Electrical and Electronic Engineering",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering and Physical Sciences Foundation Programme: Electronic Engineering and Computer Sciences",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering and Physical Sciences Foundation Programme: Logistics with Supply Chain Management",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering and Physical Sciences Foundation Programme: Mathematics",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering and Physical Sciences Foundation Programme: Mathematics with Economics",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering and Physical Sciences Foundation Programme: Mechanical Engineering",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering and Physical Sciences Foundation Programme: Product Design and Innovation",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering and Physical Sciences Foundation Programme: Quantity Surveying",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Sociology",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Literature",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Foundation Programme in Business and Social Science",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Business Management (Top Up)",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Healthcare Science (Audiology)",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Healthcare Science (Audiology) (Direct entry to Final Year)",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and English",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and International Relations",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Politics",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History BA",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resources and Business Management",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Economics",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Management",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development Management",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Sociology",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law LLB",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Logistics with Supply Chain Management",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-128"
                },
                {
                    "course_name": "Marketing",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Economics",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-128"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "MB ChB",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing Studies (Registered Nurse Adult Nursing)",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing Studies (Registered Nurse Mental Health Nursing)",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Optometry",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "MOptom",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy MPharm",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "MPharm (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physician Associate Practice (Postgraduate Taught)",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Sociology",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics with International Relations",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design and Innovation",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-128"
                },
                {
                    "course_name": "Psychology",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Business",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Marketing",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Sociology",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantity Surveying",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Aston University, Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "2ed73cae07da105918cd3347cdf7dc4b",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-136"
                },
                {
                    "course_name": "Accounting and Management",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-136"
                },
                {
                    "course_name": "Additional Learning Needs - Primary PGCE (Graduates only)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Adult Nursing",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BN (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Adult Nursing (Dispersed Learning route for Powys-based students)",
                    "university": "Bangor University",
                    "location": "Distance Learning (Bangor)",
                    "qualification": "BN (Hons)",
                    "duration": "3 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Adventure Sport Science",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Art (Secondary PGCE - Graduates only)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Banking and Finance",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-136"
                },
                {
                    "course_name": "Banking with Financial Technology (FinTech)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-136"
                },
                {
                    "course_name": "Biology",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "MBiol",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Biology",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-136"
                },
                {
                    "course_name": "Biology (Secondary PGCE - Graduates only)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology (with Foundation Year)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-104"
                },
                {
                    "course_name": "Biology with Biotechnology",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "MBiol",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Biology with Biotechnology",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-136"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Biomedical Science (with Foundation Year)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64-110"
                },
                {
                    "course_name": "Business Data Analytics",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-136"
                },
                {
                    "course_name": "Business Enterprise and Entrepreneurship",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-136"
                },
                {
                    "course_name": "Business Management",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-136"
                },
                {
                    "course_name": "Chemistry (Secondary PGCE - Graduates only)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Childhood and Youth Studies",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-128"
                },
                {
                    "course_name": "Astudiaethau Plentyndod ac Ieuenctid",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-128"
                },
                {
                    "course_name": "Childhood and Youth Studies and Psychology",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-128"
                },
                {
                    "course_name": "Astudiaethau Plentyndod ac Ieuenctid a Chymdeithaseg",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-128"
                },
                {
                    "course_name": "Childhood and Youth Studies and Sociology",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-128"
                },
                {
                    "course_name": "Astudiaethau Plentyndod ac Ieuenctid a Chymraeg",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-128"
                },
                {
                    "course_name": "Children's Nursing",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BN (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Computer Information Systems",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Computer Information Systems for Business",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "MComp (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-143"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Computer Science (with Foundation Year)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-112"
                },
                {
                    "course_name": "Computer Science with Game Design",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Computer Systems Engineering",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-135"
                },
                {
                    "course_name": "Computer Systems Engineering",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Conservation with Forestry",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88-120"
                },
                {
                    "course_name": "Control and Instrumentation Engineering",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-143"
                },
                {
                    "course_name": "Creative Arts",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-128"
                },
                {
                    "course_name": "Creative Technologies",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-127"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Creative Writing and Modern Languages",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Criminal Law",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Criminology and Criminal Justice",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Criminology and Criminal Justice and Social Policy",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Cymraeg (Welsh) and English Literature",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Cymraeg (Welsh) and History",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Cymraeg (Welsh) and Linguistics",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Data Science and Artificial Intelligence",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Data Science and Visualisation",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Dental Hygiene",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Design and Technology (Secondary PGCE - Graduates only)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diagnostic Radiography",
                    "university": "Bangor University",
                    "location": "Wrexham",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Economics",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-136"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-136"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Electronic Engineering (with Foundation Year)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-112"
                },
                {
                    "course_name": "Engineering",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Engineering",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-142"
                },
                {
                    "course_name": "Engineering (with Foundation Year)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-112"
                },
                {
                    "course_name": "English (Secondary PGCE - Graduates only)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and English Literature",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "English Language and English Literature (with Foundation Year)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-96"
                },
                {
                    "course_name": "English Language for Speech and Language Therapy",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "English Language for TEFL",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "English Language with Creative Writing",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "English Literature",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "English Literature and Creative Writing",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "English Literature and Drama",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "English Literature and Music",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Environmental Conservation",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "MEnvSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Environmental Science (with Foundation Year)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-96"
                },
                {
                    "course_name": "Film and Drama",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-128"
                },
                {
                    "course_name": "Film and English Literature",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Forestry",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88-120"
                },
                {
                    "course_name": "Geography",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "MGeog",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Geography",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-136"
                },
                {
                    "course_name": "Geography",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-136"
                },
                {
                    "course_name": "Geography (Secondary PGCE - Graduates only)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geological Oceanography",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Geological Oceanography",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Geological Oceanography (with Foundation Year)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-112"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-111"
                },
                {
                    "course_name": "Heritage, Archaeology and History",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "History",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "History (Secondary PGCE - Graduates only)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History (with Foundation Year)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-96"
                },
                {
                    "course_name": "History and Archaeology",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "History and English Literature",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "History and Music",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Information Technology (Secondary PGCE - Graduates only)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-136"
                },
                {
                    "course_name": "Law",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Law (accelerated)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Law (with Foundation Year)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64-112"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Law with History",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Law with Modern Languages",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "LLB",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Law with Politics",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Law with Psychology",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Law with Sociology",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Law with Welsh",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Learning Disabilities Nursing",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BN (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Linguistics and Modern Languages",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Linguistics and Psychology",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Linguistics and the English Language",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Management with Human Resource Management",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-136"
                },
                {
                    "course_name": "Marine Biology",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Marine Biology",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Marine Biology (with Foundation Year)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-112"
                },
                {
                    "course_name": "Marine Biology and Oceanography",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Marine Biology and Oceanography",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Marine Biology and Zoology",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Marine Biology and Zoology",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Marine Science and Conservation",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Marine Vertebrate Conservation",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Marine Vertebrate Conservation",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Marketing",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-136"
                },
                {
                    "course_name": "Marketing Communications Management",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-136"
                },
                {
                    "course_name": "Mathematics (Secondary PGCE - Graduates only)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Creative Writing",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Media and Drama",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-128"
                },
                {
                    "course_name": "Media and English Literature",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Media and Music",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Media, Film and Journalism",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-128"
                },
                {
                    "course_name": "Media, Film and Journalism (with Foundation Year)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-88"
                },
                {
                    "course_name": "Medical Sciences",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BMedSci H",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Medical Sciences (with Foundation Year)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BMedSci H",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64-112"
                },
                {
                    "course_name": "Medicine",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BMBS",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine: Graduate Entry",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BMBS",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval and Early Modern History",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Mental Health Nursing",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BN (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Mental Health Nursing (Dispersed Learning Route for Powys-based students)",
                    "university": "Bangor University",
                    "location": "Distance Learning (Bangor)",
                    "qualification": "BN (Hons)",
                    "duration": "3 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Midwifery",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BMid (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Modern and Contemporary History",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Modern Languages (Chinese, French, German, Spanish)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Modern Languages (Secondary PGCE - Graduates only)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages (with Foundation Year)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-96"
                },
                {
                    "course_name": "Modern Languages and Criminology and Criminal Justice",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Modern Languages and Cymraeg (Welsh)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Modern Languages and English Literature",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Modern Languages and Film",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Modern Languages and History",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Modern Languages and Media",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Modern Languages and Philosophy, Ethics and Religion",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Modern Languages Studies",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Music",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BMus (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Music",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Music (Secondary PGCE - Graduates only)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music (with Foundation Year)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-96"
                },
                {
                    "course_name": "Music (with Foundation Year)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BMus (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-96"
                },
                {
                    "course_name": "Music and Creative Writing",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Music and Drama",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Music and Film",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Music and Modern Languages",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Ocean and Geophysics",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Ocean Science",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Outdoor Activities (ODA) (Secondary PGCE - Graduates only)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "TAR Cynradd (yn arwain at ddyfarniad Statws Athro Cymwysedig - Graddedigion yn unig)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celf - TAR Uwchradd (yn arwain at ddyfarniad Statws Athro Cymwysedig - Graddedigion yn unig)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioleg - TAR Uwchradd (yn arwain at ddyfarniad Statws Athro Cymwysedig - Graddedigion yn unig)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cemeg - TAR Uwchradd (yn arwain at ddyfarniad Statws Athro Cymwysedig - Graddedigion yn unig)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dylunio a Thechnoleg - TAR Uwchradd (yn arwain at ddyfarniad Statws Athro Cymwysedig - Graddedigion yn unig)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Saesneg - TAR Uwchradd (yn arwain at ddyfarniad Statws Athro Cymwysedig - Graddedigion yn unig)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Daearyddiaeth - TAR Uwchradd (yn arwain at ddyfarniad Statws Athro Cymwysedig - Graddedigion yn unig)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hanes - TAR Uwchradd (yn arwain at ddyfarniad Statws Athro Cymwysedig - Graddedigion yn unig)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Technoleg Gwybodaeth - TAR Uwchradd (yn arwain at ddyfarniad Statws Athro Cymwysedig - Graddedigion yn unig)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathemateg - TAR Uwchradd (yn arwain at ddyfarniad Statws Athro Cymwysedig - Graddedigion yn unig)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ieithoedd Modern - TAR Uwchradd (yn arwain at ddyfarniad Statws Athro Cymwysedig - Graddedigion yn unig)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cerddoriaeth - TAR Uwchradd -(yn arwain at ddyfarniad Statws Athro Cymwysedig - Graddedigion yn unig)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "TAR Uwchradd - Gweithgareddau Awyr Agored (GAA / ODA)(yn arwain at ddyfarniad Statws Athro Cymwysedig - Graddedigion yn unig)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Addysg Gorfforol - TAR Uwchradd (yn arwain at ddyfarniad Statws Athro Cymwysedig - Graddedigion yn unig)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ffiseg - TAR Uwchradd (yn arwain at ddyfarniad Statws Athro Cymwysedig - Graddedigion yn unig)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Addysg Grefyddol - TAR Uwchradd (yn arwain at ddyfarniad Statws Athro Cymwysedig - Graddedigion yn unig)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cymraeg - TAR Uwchradd (yn arwain at ddyfarniad Statws Athro Cymwysedig - Graddedigion yn unig)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Pharmacology (with Foundation Year)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-104"
                },
                {
                    "course_name": "Pharmacy",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "MPharm",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Philosophy, Ethics and Religion",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Physical Education - (Secondary PGCE - Graduates only)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Geography and Oceanography",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "110-128"
                },
                {
                    "course_name": "Physical Oceanography",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Physics (Secondary PGCE - Graduates only)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Politics (with Foundation Year)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-95"
                },
                {
                    "course_name": "Primary Education Leading to Qualified Teacher Status",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Addysg Gynradd yn arwain at Statws Athro Cymwysedig",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Primary PGCE (Graduates only)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design (Welsh-medium course)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-128"
                },
                {
                    "course_name": "Product Design",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-128"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Cymraeg Proffesiynol (Professional Welsh)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Psychology",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Psychology (with Foundation Year)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72-120"
                },
                {
                    "course_name": "Psychology with Clinical & Health Psychology",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Psychology with Forensic Psychology",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Psychology with Neuropsychology",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Religious Education (Secondary PGCE - Graduates only)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Sociology (with Foundation Year)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-96"
                },
                {
                    "course_name": "Sociology and Criminology & Criminal Justice",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Cymdeithaseg and Criminology & Criminal Justice",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Sociology and Social Policy",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Cymdeithaseg gyda Pholisi Cymdeithasol",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Sport and Exercise Psychology",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Sport and Exercise Science (with Foundation Year)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72-112"
                },
                {
                    "course_name": "Sport Science, Physical Education and Coaching",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Tourism Management",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-136"
                },
                {
                    "course_name": "Cymraeg (Welsh)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Cymraeg (i ddechreuwyr) / Welsh (for beginners)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Cymraeg (Welsh) and Philosophy, Ethics and Religion",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Cymraeg (Welsh) a/and Sociology",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Cymraeg (Welsh) and Welsh History",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Cymraeg gydag Ysgrifennu Creadigol (Welsh with Creative Writing)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Cymraeg gyda Newyddiaduraeth (Welsh with Journalism)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Cymraeg, Theatr a'r Cyfryngau (Welsh, Theatre and Media)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Cymraeg (Welsh) and Music",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Wildlife Ecology and Conservation",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Woodland Management and Conservation",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Zoology",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-136"
                },
                {
                    "course_name": "Zoology",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "MZool",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Zoology (with Foundation Year)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64-112"
                },
                {
                    "course_name": "Zoology with Animal Behaviour",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "MZool",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Zoology with Animal Behaviour",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-136"
                },
                {
                    "course_name": "Zoology with Animal Management",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "MZool",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Zoology with Animal Management",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "112-136"
                },
                {
                    "course_name": "Zoology with Conservation",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-136"
                },
                {
                    "course_name": "Zoology with Conservation",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "MZool",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Zoology with Herpetology",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-136"
                },
                {
                    "course_name": "Zoology with Herpetology",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "MZool",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Zoology with Marine Zoology",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "MZool",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Zoology with Marine Zoology",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-136"
                },
                {
                    "course_name": "Zoology with Ornithology",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "MZool",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Zoology with Ornithology",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-136"
                },
                {
                    "course_name": "Zoology with Primatology",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "MZool",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Zoology with Primatology",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-136"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Banking",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting, Banking, Economics, Finance, Management Studies and Marketing",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Additional Learning Needs (Primary) PGCE",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Data Science",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agroforestry and Food Security",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Behaviour Analysis",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Innovation Design",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Linguistics for TEFL",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Marine Geoscience",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art (Secondary PGCE)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Banking (Chartered Banker)",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Banking and Finance",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Banking and Finance",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology (Secondary PGCE)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Data Analytics",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry - PGCE Secondary (leading to the award of Qualified Teacher Status)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Childhood and Youth",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design and Technology - PGCE Secondary (leading to the award of Qualified Teacher Status)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies (with placement)",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "MA",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English (Secondary PGCE)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography (Secondary PGCE)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History (Secondary PGCE)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Technology (Secondary PGCE)",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics (Secondary PGCE)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages (Secondary PGCE)",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music (Secondary PGCE)",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Outdoor Activities (ODA)(Secondary PGCE)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "TAR Cynradd (yn arwain at ddyfarniad Statws Athro Cymwysedig)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Primary (leading to the award of Qualified Teacher Status)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celf - TAR Uwchradd (yn arwain at ddyfarniad Statws Athro Cymwysedig)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioleg - TAR Uwchradd (yn arwain at ddyfarniad Statws Athro Cymwysedig)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cemeg - TAR Uwchradd (yn arwain at ddyfarniad Statws Athro Cymwysedig)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dylunio a Thechnoleg - TAR Uwchradd (yn arwain at ddyfarniad Statws Athro Cymwysedig)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Saesneg - TAR Uwchradd (yn arwain at ddyfarniad Statws Athro Cymwysedig)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Daearyddiaeth - TAR Uwchradd (yn arwain at ddyfarniad Statws Athro Cymwysedig)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hanes - TAR Uwchradd (yn arwain at ddyfarniad Statws Athro Cymwysedig)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Technoleg Gwybodaeth - TAR Uwchradd (yn arwain at ddyfarniad Statws Athro Cymwysedig)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathemateg - TAR Uwchradd (yn arwain at ddyfarniad Statws Athro Cymwysedig)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ieithoedd Modern - TAR Uwchradd (yn arwain at ddyfarniad Statws Athro Cymwysedig)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cerddoriaeth - TAR Uwchradd -(yn arwain at ddyfarniad Statws Athro Cymwysedig)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "TAR Uwchradd - Gweithgareddau Awyr Agored (yn arwain at ddyfarniad Statws Athro Cymwysedig)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Addysg Gorfforol - TAR Uwchradd (yn arwain at ddyfarniad Statws Athro Cymwysedig)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ffiseg - TAR Uwchradd (yn arwain at ddyfarniad Statws Athro Cymwysedig)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Addysg Grefyddol - TAR Uwchradd (yn arwain at ddyfarniad Statws Athro Cymwysedig)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cymraeg - TAR Uwchradd (yn arwain at ddyfarniad Statws Athro Cymwysedig)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Education - PGCE Secondary(leading to the award of Qualified Teacher Status)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics (Secondary PGCE)",
                    "university": "Bangor University",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "PgDip (Initial Qualification)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religious Education - PGCE Secondary (leading to the award of Qualified Teacher Status)",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Clinical Practice",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice (Allied Health Professional)",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Data Science",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced HEMS Practice",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Innovation Design",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Linguistics for TEFL",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Data Analytics",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Childhood and Youth",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "MA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "National Education (Wales) (Welsh-Medium)",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "MA",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "National Education (Wales) (English-Medium)",
                    "university": "Bangor University",
                    "location": "Bangor Campus",
                    "qualification": "MA",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "5588bf75d1a014b039b3b0ea3f644b37",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Business",
                    "university": "Barnet and Southgate College",
                    "location": "Main Site: Wood Street - High Barnet Campus",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business Events Management",
                    "university": "Barnet and Southgate College",
                    "location": "Main Site: Wood Street - High Barnet Campus",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "Barnet and Southgate College",
                    "location": "Main Site: Wood Street - High Barnet Campus",
                    "qualification": "BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Events Management",
                    "university": "Barnet and Southgate College",
                    "location": "Main Site: Wood Street - High Barnet Campus",
                    "qualification": "BA/BSc (H)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "160"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "45b911f08065a0d944b2a33f84ed746c",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Art and Design (Art Practice or 3D pathways)",
                    "university": "Barnfield College, Luton",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Certificate in Education (Lifelong Learning)",
                    "university": "Barnfield College, Luton",
                    "location": "Barnfield College",
                    "qualification": "CertEd",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Music",
                    "university": "Barnfield College, Luton",
                    "location": "Barnfield College",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Public Services",
                    "university": "Barnfield College, Luton",
                    "location": "Barnfield College",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "664ac9646a61cbf2fdae309920f7262d",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Animal Management",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Assistant Practitioner Health and Social Care (HTQ)",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computing For England",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Computing For England Higher National Diploma (HTQ)",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Management (HTQ)",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction: Modern Methods of Construction (HTQ)",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction: Modern Methods of Construction (HTQ)",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology, Communities and Criminal Justice",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Criminology, Communities and Criminal Justice (Top-up)",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Childhood Studies (Top-up)",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering for England (HTQ)",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Esports Education: Coaching, Health and Wellbeing",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Esports Education: Coaching, Health and Wellbeing Top-Up",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Film and Television",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Development",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Games Development",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership and Management in Public Services (Top-Up)",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Practice: Education Studies (Top up)",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Practice: Supporting Young People, Children and Families (Top up)",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Coaching, Physical Education and Health",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Coaching, Physical Education and Health Top-Up",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Supporting Early Years Practice",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "FdEd",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Supporting Education, Behaviour and Additional Needs",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "FdEd",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Supporting Families and Communities",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "FdEd",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teacher Training: Professional Graduate Certificate in Education (Post Compulsory Education)",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "PGCE (Professional)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Computing For England",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Construction and the Built Environment",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Construction Management (HTQ)",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction: Modern Methods of Construction (HTQ)",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Criminology, Communities and Criminal Justice",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "FD",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Criminology, Communities and Criminal Justice (Top-up)",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "BSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (General Engineering)",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering for England (HTQ)",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Esports Education: Coaching, Health and Wellbeing",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "FdA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Esports Education: Coaching, Health and Wellbeing Top-Up",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teacher Training: Professional Graduate Certificate in Education (Post Compulsory Education)",
                    "university": "Barnsley College University Centre",
                    "location": "Barnsley College Higher Education Church Street Campus",
                    "qualification": "PGCE (Professional)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "feb48ed2d6c65efb5036027772356fe0",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Construction Management for England HNC",
                    "university": "Bath College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Management for England HND",
                    "university": "Bath College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical And Electronic Engineering For England HNC",
                    "university": "Bath College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical And Electronic Engineering For England HND",
                    "university": "Bath College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering For England HNC",
                    "university": "Bath College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering For England HND",
                    "university": "Bath College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "278dfbe11547ffb5aeb50f43c4131c68",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Acting",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Acting (Film and Television)",
                    "university": "Bath Spa University",
                    "location": "The Scottish Institute for Theatre, Dance, Film and TV",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-84"
                },
                {
                    "course_name": "Acting (Theatre)",
                    "university": "Bath Spa University",
                    "location": "The Scottish Institute for Theatre, Dance, Film and TV",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-84"
                },
                {
                    "course_name": "Acting and Performance Making",
                    "university": "Bath Spa University",
                    "location": "University Centre Weston (UCW)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Architecture",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Architecture (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Architecture (with Foundation Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Biology",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Biology (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Biology and Education",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Biology and Education (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Bath Spa University",
                    "location": "New City College",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Bath Spa University",
                    "location": "New City College",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Biomedical Science (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Business and Management",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Business and Management (Accounting)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Business and Management (Accounting) - Professional Placement Year",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Business and Management (Entrepreneurship)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Business and Management (Entrepreneurship)",
                    "university": "Bath Spa University",
                    "location": "New City College",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Business and Management (Entrepreneurship)",
                    "university": "Bath Spa University",
                    "location": "New City College",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Business and Management (Entrepreneurship) - Professional Placement Year",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Business and Management (Fashion)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Business and Management (Fashion) (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Business and Management (Festivals and Events)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Business and Management (Festivals and Events) - Professional Placement Year",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Business and Management (Human Resource Management)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Business and Management (Human Resource Management) - Professional Placement Year",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Business and Management (International Business)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Business and Management (International Business) - Professional Placement Year",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Business and Management (Law)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Business and Management (Law) - Professional Placement Year",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Business and Management (Marketing)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Business and Management (Marketing) - Professional Placement Year",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Business and Management (Tourism Management)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Business and Management (Tourism Management) - Professional Placement Year",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Business and Management - Professional Placement Year",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Business and Management and Criminology",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Business and Management and Criminology (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Business and Management and Economics",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Business and Management and Economics with Professional Placement Year",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-144"
                },
                {
                    "course_name": "Business and Management and Media Communications",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Business and Management and Media Communications (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Business Psychology",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Business Psychology (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Commercial Music",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Computing",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Computing (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Contemporary Circus and Physical Theatre",
                    "university": "Bath Spa University",
                    "location": "Circomedia",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Contemporary Circus and Physical Theatre - Top-Up",
                    "university": "Bath Spa University",
                    "location": "Circomedia",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling",
                    "university": "Bath Spa University",
                    "location": "City of Bristol College",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64-80"
                },
                {
                    "course_name": "Counselling (Top Up)",
                    "university": "Bath Spa University",
                    "location": "City of Bristol College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Arts Practice",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Creative Arts Practice (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Creative Arts Practice (with Foundation Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Creative Computing",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Creative Computing (Gaming)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Creative Computing (Gaming) (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Creative Computing (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Creative Computing (Web Technologies)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Creative Computing (Web Technologies) (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Creative Media",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Creative Media (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Creative Music Technology",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Creative Music Technology (Games and Interactive Media)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Creative Therapeutic Practice",
                    "university": "Bath Spa University",
                    "location": "City of Bristol College",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Creative Writing (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Creative Writing and Drama",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Creative Writing and English Literature",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Creative Writing and English Literature (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Creative Writing and Film and Screen Studies",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Creative Writing and Film and Screen Studies (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Creative Writing and Philosophy and Ethics",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Creative Writing and Philosophy and Ethics (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Creative Writing and Publishing",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Creative Writing and Publishing (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Criminology",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Criminology (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Criminology and Law",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Criminology and Law (with Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Criminology and Politics",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Criminology and Politics (with Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Criminology and Psychology",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Criminology and Psychology (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Criminology and Sociology (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Cyber Security (professional placement year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Dance",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Dance (Commercial)",
                    "university": "Bath Spa University",
                    "location": "The Scottish Institute for Theatre, Dance, Film and TV",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-84"
                },
                {
                    "course_name": "Dance (Contemporary)",
                    "university": "Bath Spa University",
                    "location": "The Scottish Institute for Theatre, Dance, Film and TV",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-84"
                },
                {
                    "course_name": "Dance for Commercial Performance",
                    "university": "Bath Spa University",
                    "location": "University Centre Weston (UCW)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Drama",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Drama (Musical Theatre)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Drama and English Literature",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Drama and Film and Screen Studies",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Drama and Psychology",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "Bath Spa University",
                    "location": "City of Bristol College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "Bath Spa University",
                    "location": "New City College",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "Bath Spa University",
                    "location": "New City College",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Early Childhood Studies (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Early Years",
                    "university": "Bath Spa University",
                    "location": "City of Bristol College",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Economics and Law",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Economics and Law with Professional Placement Year",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Education",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Education (Accelerated)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Education (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Education and Drama",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Education and English Literature",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Education and English Literature (with Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Education and Geography",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Education and Geography (with Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Education and History",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Education and History (with Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Education Studies for Teaching Assistants",
                    "university": "Bath Spa University",
                    "location": "New City College",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Education Studies for Teaching Assistants",
                    "university": "Bath Spa University",
                    "location": "New City College",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Educational Psychology",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Educational Psychology (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "English Literature",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "English Literature (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "English Literature and Media Communications",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "English Literature and Media Communications (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "English Literature and Philosophy and Ethics",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "English Literature and Philosophy and Ethics (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "English Literature and Publishing",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "English Literature and Publishing (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Environmental Science (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Fashion Design",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Fashion Design (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Fashion Design (with Foundation Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Fashion Marketing and Management",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Fashion Marketing and Management (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Fashion Photography",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Fashion Photography (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Fashion Photography (with Foundation Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Film and Screen Studies",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Film and Screen Studies (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Film and Screen Studies and Media Communications",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Film and Screen Studies and Media Communications (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Film, Television and Digital Production",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Film, Television and Digital Production (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fine Art (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Fine Art (with Foundation Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Food with Nutrition",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Food with Nutrition (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Forensic Psychology (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Forensic Science with Professional Placement Year",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Games Development",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Games Development (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Geography",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Geography (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Global Development and Sustainability",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Global Development and Sustainability (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Bath Spa University",
                    "location": "University Centre Weston (UCW)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Graphic Design (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Graphic Design (with Foundation Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "History",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "History (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "History and Philosophy and Ethics",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "History and Philosophy and Ethics (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "History and Politics",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-128"
                },
                {
                    "course_name": "History and Politics (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Human Nutrition",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Human Nutrition (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Interior Design",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Interior Design (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Interior Design (with Foundation Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "International Relations and History",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "International Relations and History (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "International Relations and Politics",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "International Relations and Politics (with Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Journalism and Publishing",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Journalism and Publishing (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Law",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Law (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Law and Politics",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Law and Politics (with Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Marketing",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Marketing (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Media Communications",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Media Communications (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Media Communications and Publishing",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Media Communications and Publishing (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Media Communications and Sociology",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Media Communications and Sociology (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Music",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Music (Performance)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Musical Theatre",
                    "university": "Bath Spa University",
                    "location": "The Scottish Institute for Theatre, Dance, Film and TV",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-84"
                },
                {
                    "course_name": "Musical Theatre",
                    "university": "Bath Spa University",
                    "location": "Brighton Academy",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Musical Theatre",
                    "university": "Bath Spa University",
                    "location": "University Centre Weston (UCW)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Philosophy and Ethics and Psychology",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Philosophy and Ethics and Psychology (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Photography",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Photography (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96-128"
                },
                {
                    "course_name": "Photography (with Foundation Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Politics, Philosophy and Economics",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Politics, Philosophy and Economics (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Primary Education (5-11) with QTS",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Product Design",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Product Design (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Product Design (with Foundation Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Professional Dance",
                    "university": "Bath Spa University",
                    "location": "Brighton Academy",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Professional Music: Performance and Production",
                    "university": "Bath Spa University",
                    "location": "University Centre Weston (UCW)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Psychology",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Psychology (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Psychology and Sociology",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Psychology and Sociology (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Sociology",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Sociology (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Sociology and Politics",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Sociology and Politics (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Sport and Exercise Nutrition",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Sport and Exercise Nutrition (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Sports Management",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Sports Management (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Sports Media Production",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Sports Media Production (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Theatre, Festival and Event Production",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Wildlife Conservation",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Wildlife Conservation (Professional Placement Year)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-128"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Arts Management",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children's Publishing",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Commercial Music",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Computing",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dance",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design",
                    "university": "Bath Spa University",
                    "location": "Locksbrook Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design (Graphics)",
                    "university": "Bath Spa University",
                    "location": "Locksbrook Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design (Illustration)",
                    "university": "Bath Spa University",
                    "location": "Locksbrook Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design (Textiles)",
                    "university": "Bath Spa University",
                    "location": "Locksbrook Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design (Visual Communication)",
                    "university": "Bath Spa University",
                    "location": "Locksbrook Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Directing Circus",
                    "university": "Bath Spa University",
                    "location": "Circomedia",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Early Childhood Studies)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Leadership and Management)",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Management",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Bath Spa University",
                    "location": "Locksbrook Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art (Ceramics)",
                    "university": "Bath Spa University",
                    "location": "Locksbrook Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art (Curatorial Practice)",
                    "university": "Bath Spa University",
                    "location": "Locksbrook Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art (Media)",
                    "university": "Bath Spa University",
                    "location": "Locksbrook Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art (Painting)",
                    "university": "Bath Spa University",
                    "location": "Locksbrook Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art (Photography)",
                    "university": "Bath Spa University",
                    "location": "Locksbrook Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Heritage Management",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Inclusive Education",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Business",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal Foundations",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing and Brand Management",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance (Acting)",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance (Creative Producing)",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance (Directing)",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance (Music)",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Practice",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scriptwriting",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sound (Composition and Sonic Art)",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sound Design",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sound Production",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of  Other Languages",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Writing for Young People",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Arts Management",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children's Publishing",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Commercial Music",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Computing",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dance",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design",
                    "university": "Bath Spa University",
                    "location": "Locksbrook Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design (Graphics)",
                    "university": "Bath Spa University",
                    "location": "Locksbrook Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design (Illustration)",
                    "university": "Bath Spa University",
                    "location": "Locksbrook Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design (Textiles)",
                    "university": "Bath Spa University",
                    "location": "Locksbrook Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design (Visual Communication)",
                    "university": "Bath Spa University",
                    "location": "Locksbrook Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Early Childhood Studies)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Leadership and Management)",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Leadership (Teach First)",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Management",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Bath Spa University",
                    "location": "Locksbrook Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art (Ceramics)",
                    "university": "Bath Spa University",
                    "location": "Locksbrook Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art (Curatorial Practice)",
                    "university": "Bath Spa University",
                    "location": "Locksbrook Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art (Media)",
                    "university": "Bath Spa University",
                    "location": "Locksbrook Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art (Painting)",
                    "university": "Bath Spa University",
                    "location": "Locksbrook Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art (Photography)",
                    "university": "Bath Spa University",
                    "location": "Locksbrook Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Heritage Management",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Inclusive Education",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Business",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal Foundations",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing and Brand Management",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nature and Travel Writing",
                    "university": "Bath Spa University",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance (Acting)",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance (Creative Producing)",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance (Directing)",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance (Music)",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Practice",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Practice in Higher Education",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scriptwriting",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sound (Composition and Sonic Art)",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sound Design",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sound Production",
                    "university": "Bath Spa University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specific Learning Difficulties/Dyslexia",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Waldorf Education and Creative Pedagogies (Low Residency)",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "PgDip",
                    "duration": "16 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Writing for Young People",
                    "university": "Bath Spa University",
                    "location": "Newton Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "a2bc9872e97f37452120f8adac1773c8",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance with work placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Management",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Management with work placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MBiochem",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MBiochem",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MBiol (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MBiol (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "Master of Biomedical Science (with honours)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "Master of Biomedical Science (with honours)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business with work placements",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with Environmental Engineering",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with Environmental Engineering with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry for Drug Discovery",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry for Drug Discovery",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry for Drug Discovery with professional placement or study abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry for Drug Discovery with professional placement or study abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Management",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Management",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Management with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Management with professional placement or study abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with professional placement or study abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with professional placement or study abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MCompu (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science & AI with professional placement or study abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MComp (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science & AI with professional placement or study abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science & Mathematics with professional placement or study abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MCompu (H)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Artificial Intelligence",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MComp (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Artificial Intelligence",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Mathematics",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MCompu (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Mathematics",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Mathematics with professional placement or study abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with professional placement or study abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with professional placement or study abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MCompu (H)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Systems Engineering",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Systems Engineering",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Systems Engineering with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Systems Engineering with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Mathematics",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Mathematics with professional placement or study abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Politics",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Politics with professional placement or study abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with professional placement or study abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education with Psychology",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education with Psychology with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering w Space Science & Technology w professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering w Space Science & Technology w professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with Space Science and Technology",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with Space Science and Technology",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Exercise Science",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Exercise Science with Professional Placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrated Design Engineering",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrated Design Engineering",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrated Design Engineering with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrated Design Engineering with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrated Mechanical and Electrical Engineering",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrated Mechanical and Electrical Engineering",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrated Mechanical and Electrical Engineering with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrated Mechanical and Electrical Engineering with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development with Economics",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development with Economics with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management and Modern Languages (French) w study or work abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management and Modern Languages (German) w study or work abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management and Modern Languages (Spanish) w study or work abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management with study or work abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Politics and Modern Languages with study or work abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Marketing with work placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with work placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MMath (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MMath (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Physics",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Physics",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Physics with professional placement or study abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Physics with professional placement or study abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Statistics",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Statistics with professional placement or study abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MMath (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with professional placement or study abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with study abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MMath (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics, Statistics and Data Science",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics, Statistics and Data Science w professional placement or study abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering w Manufacturing & Management w professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering w Manufacturing and Management w professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Manufacturing and Management",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Manufacturing and Management",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical with Automotive Engineering",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical with Automotive Engineering",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical with Automotive Engineering with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical with Automotive Engineering with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages with study or work abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Sciences",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Sciences",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Sciences with professional placement or study abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Sciences with professional placement or study abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MPharmacol",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology with Professional Placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MPharm (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy (Plymouth University campus)",
                    "university": "University of Bath",
                    "location": "University of Plymouth",
                    "qualification": "MPharm (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy with Preparatory Year (Plymouth University campus)",
                    "university": "University of Bath",
                    "location": "University of Plymouth",
                    "qualification": "MPharm (H)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-144"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics w Astrophysics w prof placement, research placement, study abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics w Theoretical Physics w prof placement, research placement, study abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astrophysics with professional placement or study abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astrophysics with research placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with professional placement or study abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with professional placement, research placement or study abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with research placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Theoretical Physics",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Theoretical Physics",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Theoretical Physics with professional placement or study abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Theoretical Physics with research placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations with professional placement or study abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics with Economics",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics with Economics with professional placement or study abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with work placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics Engineering",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics Engineering",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics Engineering with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics Engineering with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Sciences",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Sciences with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work and Applied Social Studies",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Social Policy",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Social Policy with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport (Sports Performance)",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Science with professional placement or study abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Science with professional placement or study abroad",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Management and Coaching",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Management and Coaching with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural and Architectural Engineering",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural and Architectural Engineering",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural and Architectural Engineering with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural and Architectural Engineering with professional placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Quantitative Methods in Social Sciences",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Clinical Psychology",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Economics",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Economics with Banking and Financial Markets",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Forensic Psychology with Placement",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Psychology and Economic Behaviour",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Engineering (Environmental Design)",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence for Engineering and Design",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence for Engineering and Design with professional placement",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive Engineering with Business Management",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive Engineering with Electric Propulsion",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive Technology",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive Technology with Business Management",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology (Healthcare Technologies)",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology (Sustainable Biotechnologies)",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology (with Entrepreneurship)",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate Change, Sustainability and Society",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation of Historic Buildings",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary European Studies with Transatlantic Track (Euromasters)",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary European Studies: Politics, Policy and Society (Euromasters)",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Decarbonisation",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Decarbonisation",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "PgDip",
                    "duration": "8 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drug Discovery",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Business Management",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Entrepreneurship and Management",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Engineering",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "European Social Policy",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance with Banking",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance with Risk Management",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Mathematics with Data Science",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender and Politics",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Challenges: Geopolitics, Inequalities and Mobilities",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Public Health and Policy",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health, Wellbeing and Society",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Innovation and Technology Management",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development Management",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development with Conflict and Humanitarian Action",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development with Economics",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development with Education",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development, Social Justice and Sustainability",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Education and Globalisation",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Security",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interpreting and Translating (Chinese path)",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interpreting and Translating (European path)",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Data Science for Industry",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Building Design",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular Biosciences (Bioinformatics)",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular Biosciences (Medical Biosciences)",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular Biosciences (Microbiology)",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Operations, Logistics and Supply Chain Management",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Studies",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics and Autonomous Systems",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics and Autonomous Systems including optional three-month placement",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociotechnical Futures and Digital Methods",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Management",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics and Data Science",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics and Data Science (Health)",
                    "university": "University of Bath",
                    "location": "Multiple Locations",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Retailing",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability and Management",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL)",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation and Professional Language Skills",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation with Business Interpreting (Chinese)",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Quantitative Methods in Social Sciences",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Clinical Psychology",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate Change, Sustainability and Society",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Associate in Psychology",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Decarbonisation",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Decarbonisation",
                    "university": "University of Bath",
                    "location": "University of Bath",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "European Social Policy",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender and Politics",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Challenges: Geopolitics, Inequalities and Mobilities",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health, Wellbeing and Society",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development Management",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development with Conflict and Humanitarian Action",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development with Economics",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development with Education",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development, Social Justice and Sustainability",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Security",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Studies",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociotechnical Futures and Digital Methods",
                    "university": "University of Bath",
                    "location": "Main Site",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "f14c025da4ee2932f9edd78a437aadb6",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Animal Management",
                    "university": "Bedford College Group",
                    "location": "Shuttleworth College (part of Bedford College)",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Animal Science (Top Up)",
                    "university": "Bedford College Group",
                    "location": "Shuttleworth College (part of Bedford College)",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Child and Adolescent Studies (Top Up)",
                    "university": "Bedford College Group",
                    "location": "Bedford College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Child and Family Studies",
                    "university": "Bedford College Group",
                    "location": "Multiple Locations",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Computing (App Development & Testing) (HTQ)",
                    "university": "Bedford College Group",
                    "location": "Bedford College",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing (Cyber Security) (HTQ)",
                    "university": "Bedford College Group",
                    "location": "Bedford College",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing (General) (HTQ)",
                    "university": "Bedford College Group",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing (Network Engineering) (HTQ)",
                    "university": "Bedford College Group",
                    "location": "Bedford College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Media Production (Film)",
                    "university": "Bedford College Group",
                    "location": "Tresham College - Kettering Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Creative Media Production (Games Design)",
                    "university": "Bedford College Group",
                    "location": "Tresham College - Kettering Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Cyber Security and Networking (BSc) (Level 6 Top-up)",
                    "university": "Bedford College Group",
                    "location": "Bedford College",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Communication Design Animation Industry",
                    "university": "Bedford College Group",
                    "location": "Bedford College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Communication Design Graphics",
                    "university": "Bedford College Group",
                    "location": "Bedford College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Technologies (Top Up)",
                    "university": "Bedford College Group",
                    "location": "Bedford College",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Fashion and Textiles",
                    "university": "Bedford College Group",
                    "location": "Tresham College - Kettering Campus",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Television",
                    "university": "Bedford College Group",
                    "location": "Bedford College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television",
                    "university": "Bedford College Group",
                    "location": "Central Bedfordshire College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Bedford College Group",
                    "location": "Tresham College - Kettering Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Development",
                    "university": "Bedford College Group",
                    "location": "Bedford College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Development",
                    "university": "Bedford College Group",
                    "location": "Central Bedfordshire College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Bedford College Group",
                    "location": "Tresham College - Kettering Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Healthcare Professions' Support for England (2023) (HTQ)",
                    "university": "Bedford College Group",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership and Management HTQ",
                    "university": "Bedford College Group",
                    "location": "Central Bedfordshire College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Lifelong Learning (In-service/ Pre-service)",
                    "university": "Bedford College Group",
                    "location": "Multiple Locations",
                    "qualification": "CertEd",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Lifelong Learning (In-Service/Pre-Service) (PGCE)",
                    "university": "Bedford College Group",
                    "location": "Multiple Locations",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Lifelong Learning (In-service/Pre-service) (ProfGCE)",
                    "university": "Bedford College Group",
                    "location": "Multiple Locations",
                    "qualification": "PCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Performance and Production",
                    "university": "Bedford College Group",
                    "location": "Tresham College - Kettering Campus",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performing Arts",
                    "university": "Bedford College Group",
                    "location": "Central Bedfordshire College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performing Arts",
                    "university": "Bedford College Group",
                    "location": "Tresham College - Kettering Campus",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Photography",
                    "university": "Bedford College Group",
                    "location": "Tresham College - Kettering Campus",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology & Criminal Behaviour (Top Up)",
                    "university": "Bedford College Group",
                    "location": "Bedford College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Psychology and Crime",
                    "university": "Bedford College Group",
                    "location": "Bedford College",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Sport (Outdoor Adventure)",
                    "university": "Bedford College Group",
                    "location": "Shuttleworth College (part of Bedford College)",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Sport Science (Sport Coaching)",
                    "university": "Bedford College Group",
                    "location": "Tresham College - Kettering Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Visual Art & Design Interaction Fashion & Textiles",
                    "university": "Bedford College Group",
                    "location": "Bedford College",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Art & Design Interaction Fine Art",
                    "university": "Bedford College Group",
                    "location": "Bedford College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Art & Design Interaction Interior Design",
                    "university": "Bedford College Group",
                    "location": "Bedford College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Art & Design Interaction Product Design",
                    "university": "Bedford College Group",
                    "location": "Bedford College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Animal Management",
                    "university": "Bedford College Group",
                    "location": "Shuttleworth College (part of Bedford College)",
                    "qualification": "FdSc",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Animal Science (Top Up)",
                    "university": "Bedford College Group",
                    "location": "Shuttleworth College (part of Bedford College)",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Digital Communication Design Photography",
                    "university": "Bedford College Group",
                    "location": "Bedford College",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering HTQ",
                    "university": "Bedford College Group",
                    "location": "Bedford College",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Systems Engineering HTQ",
                    "university": "Bedford College Group",
                    "location": "Bedford College",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Lifelong Learning (In-service/ Pre-service)",
                    "university": "Bedford College Group",
                    "location": "Multiple Locations",
                    "qualification": "CertEd",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Lifelong Learning (In-Service/Pre-Service) (PGCE)",
                    "university": "Bedford College Group",
                    "location": "Multiple Locations",
                    "qualification": "PGCE",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Lifelong Learning (In-service/Pre-service) (ProfGCE)",
                    "university": "Bedford College Group",
                    "location": "Multiple Locations",
                    "qualification": "PCE",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering HTQ",
                    "university": "Bedford College Group",
                    "location": "Bedford College",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Methods of Construction (HTQ)",
                    "university": "Bedford College Group",
                    "location": "Bedford College",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Site Management HTQ",
                    "university": "Bedford College Group",
                    "location": "Bedford College",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "ac96c9e87de0d2a74f6246c5ede68082",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Accounting (With Professional Practice Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Accounting and Finance (with Professional Practice Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Accounting and Finance with Foundation Year",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Accounting with Foundation Year",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Adult Nursing",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Adult Nursing",
                    "university": "University of Bedfordshire",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Adult Nursing (18 months)",
                    "university": "University of Bedfordshire",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agricultural Science (top-up)",
                    "university": "University of Bedfordshire",
                    "location": "Shuttleworth College",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Science (Top Up)",
                    "university": "University of Bedfordshire",
                    "location": "Shuttleworth College",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animation",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Animation (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Applied Early Years Studies (Top up)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Special Educational Needs and Disability (Top-up)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Technology",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Architectural Technology with Foundation Year",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Architectural Technology with Professional Practice Year",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Art and Design",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Art and Design (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Artificial Intelligence and Robotics",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Artificial Intelligence and Robotics (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Associate Ambulance Practice",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "CertHE",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-96"
                },
                {
                    "course_name": "Associate Continuing Healthcare Practitioner",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Automotive Engineering",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Automotive Engineering (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Aviation and Airport Management",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Aviation and Airport Management (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Aviation and Airport Management (With Professional Practice Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Bedfordshire International Foundation Year (Arts)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "Foundation Year",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32-56"
                },
                {
                    "course_name": "Bedfordshire International Foundation Year (Business)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "Foundation Year",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32-56"
                },
                {
                    "course_name": "Bedfordshire International Foundation Year (Computer Science)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "Foundation Year",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32-56"
                },
                {
                    "course_name": "Bedfordshire International Foundation Year (Education)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "Foundation Year",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32-56"
                },
                {
                    "course_name": "Bedfordshire International Foundation Year (Engineering)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "Foundation Year",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32-56"
                },
                {
                    "course_name": "Bedfordshire International Foundation Year (Health and Social Sciences)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "Foundation Year",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32-56"
                },
                {
                    "course_name": "Bedfordshire International Foundation Year (Life Sciences and Psychology)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "Foundation Year",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32-56"
                },
                {
                    "course_name": "Bedfordshire International Foundation Year (Sport)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "Foundation Year",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32-56"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biochemistry (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Biological Science",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Biological Science (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Biological Science (With Professional Practice Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Administration (Top up)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Analytics (with Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of Bedfordshire",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business Management with Business Analytics",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business Management with Business Analytics (with Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Business Management with Digital Marketing",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business Management with Digital Marketing (with Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Business Management with Finance",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business Management with Finance (with Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Business Management with Foundation Year",
                    "university": "University of Bedfordshire",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Business Management with Human Resource",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business Management with Human Resource (with Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Business Management with International Business",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business Management with International Business (with Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Business Management with Law",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business Management with Law with Foundation Year",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Child and Adolescent Studies Top-up",
                    "university": "University of Bedfordshire",
                    "location": "Bedford College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Childhood and Youth Studies",
                    "university": "University of Bedfordshire",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Childhood and Youth Studies with Foundation Year",
                    "university": "University of Bedfordshire",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Children and Young People's Nursing",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Children and Young People's Nursing (18 months)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Exercise Therapy",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Clinical Exercise Therapy (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Clinical Exercise Therapy (With Professional Practice Year)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computer Animation and Visual Effects",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computer Animation and Visual Effects (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Computer Games Development",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Games Development (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Computer Networking",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computer Networking (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computer Science (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Computer Science (With Professional Practice Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Construction Design and Build",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "80-96"
                },
                {
                    "course_name": "Construction Management",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-56"
                },
                {
                    "course_name": "Construction Management",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-96"
                },
                {
                    "course_name": "Construction Management (Top-up)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Management (with Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Construction Quantity Surveying",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "80-96"
                },
                {
                    "course_name": "Construction Site Management",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "80-96"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Criminology and Sociology with Foundation Year",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Criminology with Foundation Year",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cybersecurity",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Cybersecurity (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Early Childhood Education (Graduate Practitioner)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Early Childhood Education (Graduate Practitioner) (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Early Years Studies",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-56"
                },
                {
                    "course_name": "Early Years Studies (with Early Years Educator)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-56"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Economics and Finance (with Professional Practice Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Economics and Finance with Foundation Year",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Education",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Education (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Education Studies and English",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Education Studies and English (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Education with Psychology",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Education with Psychology (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Electronic Engineering (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "English",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "English with TESOL",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English with TESOL (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Events Management with Professional Practice Year",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Fashion Design",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Fashion Design (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Film and Television Production",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Film and Television Production (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Film Production",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Film Production (with Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Food and Nutrition Science",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Food and Nutrition Science (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Food and Nutrition Science (With Professional Practice Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Football Business",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Football Business (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Football Business (With Professional Practice Year)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Football Coaching",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Football Coaching (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Football Coaching (With Professional Practice Year)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Football Studies",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Football Studies (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Football Studies (With Professional Practice Year)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Forensic Psychology (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Forensic Psychology (With Professional Practice Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Forensic Science (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Forensic Science and Criminology",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Forensic Science and Criminology (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Forensic Science and Criminology (With Professional Practice Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Graphic Design (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Human Resource Management  (with Foundation Year) (Accredited)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Human Resource Management (Accredited)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Human Resource Management (With Professional Practice Year) (Accredited)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Human Resource Management with Law (with Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Human Resources Management with Law",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Information Technology",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Information Technology (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Information Technology Management for Business",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Interior Architecture and Design",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Interior Architecture and Design (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "International Business (Luton) (With Professional Practice Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "International Business with Foundation Year",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "International Tourism Management",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "International Tourism Management with Foundation Year",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "International Tourism Management with Professional Practice Year",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "International Tourism with Events Management with Professional Practice Year",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "International Tourism with Hospitality Management",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "International Tourism with Hospitality Management with Foundation Year",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "International Tourism with Hospitality Management with Professional Practice Year",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Law",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Law with Criminology with Foundation Year",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Law with Financial Management",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Law with Financial Management (with Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Law with Foundation Year",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Law with Psychology",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Law with Psychology with Foundation Year",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Make-up for Fashion and Media (Top Up)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Marketing (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Marketing (With Professional Practice Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mechanical Engineering (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Media Production",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Media Production (Top-up)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media Production (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Media, Marketing and Public Relations",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Media, Marketing and Public Relations (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Mental Health Nursing",
                    "university": "University of Bedfordshire",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Mental Health Nursing",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health Nursing (18 months)",
                    "university": "University of Bedfordshire",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery",
                    "university": "University of Bedfordshire",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Midwifery: Registered Midwife (2nd Registration)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Technology Top-up",
                    "university": "University of Bedfordshire",
                    "location": "Bedford College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Musical Theatre (Emil Dale Academy)",
                    "university": "University of Bedfordshire",
                    "location": "Emil Dale Academy",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-96"
                },
                {
                    "course_name": "Musical Theatre Performance (Emil Dale Academy)",
                    "university": "University of Bedfordshire",
                    "location": "Emil Dale Academy",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "80-96"
                },
                {
                    "course_name": "Nursing Associate Practitioner",
                    "university": "University of Bedfordshire",
                    "location": "Multiple Locations",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "70-80"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Operating Department Practice",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Paramedic Science",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Pharmaceutical and Chemical Sciences",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Pharmaceutical and Chemical Sciences (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Pharmacology and Health Science",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Pharmacology and Health Science (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Photography",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Physical Education Secondary (With QTS)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Primary Education (3-7) (With QTS)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Primary Education (With QTS)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Professional Policing (with Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Psychology (With Professional Practice Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology and Criminal Behaviour (Top-up)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology, Counselling and Coaching",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Psychology, Counselling and Coaching (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Psychology, Counselling and Coaching (With Professional Practice Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Public Health",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Social Media Content Creation",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Social Media Content Creation (with Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Social Policy and Criminal Justice",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Social Policy and Criminal Justice (with Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Social Policy and Health and Social Care",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Social Policy and Health and Social Care (with Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Social Policy and Social Research",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Social Policy and Social Research (with Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Bedfordshire",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Social Work (Postgraduate Entry)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Software Engineering (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Software Engineering (With Professional Practice Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Special Educational Needs and Disability",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Special Educational Needs and Disability (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport and Exercise Science (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Sport and Exercise Science (With Professional Practice Year)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport and Physical Education",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport and Physical Education",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport and Physical Education (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Sport and Physical Education (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Sport Journalism",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport Journalism (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Sports Coaching and Development",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sports Coaching and Development (With Foundation Year)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Sports Coaching and Development (With Professional Practice Year)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sports Therapy and Rehabilitation",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Strength and Conditioning",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Travel, Aviation and Tourism Management",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Travel, Aviation and Tourism Management with Foundation Year",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Travel, Aviation and Tourism Management with Professional Practice Year",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Visual Impairment Rehabilitation",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Business Finance",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Business Finance with Data Analytics",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Linguistics  (TESOL)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Linguistics (Teaching English as a Foreign Language)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Psychology (Conversion)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Design",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology with Data Analytics",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology with Project Management",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Childhood, Youth and Family Studies",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MA",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Childhood, Youth and Family Studies: Youth Work Pathway",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MA",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Childhood, Youth and Family Studies: Youth Work Pathway",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MA",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Exercise Physiology",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with placement",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Digital Film Production",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MA",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dance Performance and Choreography",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Social Justice)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Management",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Security in Public Health",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrated Healthcare Practice and Strategic Leadership",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business (with Placement)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "22 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Law",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Data Analytics",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Education (English Language Teaching)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Education (Leadership and Administration)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Education (Teaching)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Social Welfare and Social Development",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "LLM International Dispute Resolution",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology in Public Health",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology with Data Analytics",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology with Project Management",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Activity, Nutrition and Behaviour Change",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Education and Sport (Leadership)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Education and Sport (Pedagogy)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Social Work Practice",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Physical Activity (Executive Leadership)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Physical Activity (Leadership and Management)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strength and Conditioning",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Accounting and Business Finance",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Business Finance with Data Analytics",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Computing and Information Technology",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Computing and Information Technology with Project Management",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive Engineering for Electric Vehicles",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MBA (Executive)",
                    "duration": "2 years",
                    "study_mode": "Part-time weekend",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration (Digital Technology Management)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration (Finance)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration (Hospital and Health Services Management)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration (Hospital and Health Services Management) with Data Analytics",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration (Human Resource Management)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration (Logistics and Supply Chain Management)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration (Marketing)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration (Oil and Gas Management)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration (with Placement)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MBA",
                    "duration": "22 months",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration with Data Analytics",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Networking",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Networking with Project Management",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Project Management",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security with Project Management",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dental Education",
                    "university": "University of Bedfordshire",
                    "location": "Putteridge Bury campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dental Law and Ethics",
                    "university": "University of Bedfordshire",
                    "location": "Putteridge Bury campus",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Leadership)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (National Award for Special Educational Needs Co-ordination)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Practice)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Special Educational Needs)",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with Project Management",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Economics",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Bedfordshire",
                    "location": "Bedford Campus",
                    "qualification": "MSc",
                    "duration": "9 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Systems Management",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Data Analytics",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Resource Management",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations Management",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Social Welfare and Social Development",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tourism Planning and Management",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Education",
                    "university": "University of Bedfordshire",
                    "location": "Putteridge Bury campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management (with Placement)",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MBA",
                    "duration": "22 months",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management with Data Analytics",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Purchasing Logistics and Supply Chain Management with Data Analytics",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Purchasing, Logistics and Supply Chain Management",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering and Applications",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering and Applications with Project Management",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Management",
                    "university": "University of Bedfordshire",
                    "location": "Luton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "fd63efd598087bcff8104b44fd1a0870",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "5daaefea4a4c02dddede6a88900fcdff",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Acting for Screen",
                    "university": "BIMM University",
                    "location": "MetFilm School Leeds",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Acting for Stage, Screen and Digital Media",
                    "university": "BIMM University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Animation",
                    "university": "BIMM University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Content, Media and Film Production",
                    "university": "BIMM University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Creative Technologies - Game Design and Development",
                    "university": "BIMM University",
                    "location": "MetStudios Brighton",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Creative Technologies - Games Art and Technology",
                    "university": "BIMM University",
                    "location": "MetStudios Brighton",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Digital Film Practice - Post Production",
                    "university": "BIMM University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Digital Film Practice - Sound",
                    "university": "BIMM University",
                    "location": "MetFilm School London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Digital Film Practice - Virtual Production",
                    "university": "BIMM University",
                    "location": "MetFilm School London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Digital Film Practice - Visual Effects (VFX)",
                    "university": "BIMM University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Electronic Music Production",
                    "university": "BIMM University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Event Management",
                    "university": "BIMM University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Film and Screen Business",
                    "university": "BIMM University",
                    "location": "MetFilm School London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Film Business & Production",
                    "university": "BIMM University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Filmmaking",
                    "university": "BIMM University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Filmmaking (Top-Up)",
                    "university": "BIMM University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Art and Technology",
                    "university": "BIMM University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Games Design and Development",
                    "university": "BIMM University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Hair and Make-up for Screen and Film",
                    "university": "BIMM University",
                    "location": "MetFilm School Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Music and Sound Production",
                    "university": "BIMM University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Music Business",
                    "university": "BIMM University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Music Business & Event Management",
                    "university": "BIMM University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Music Marketing, Media and Communication",
                    "university": "BIMM University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Music Production",
                    "university": "BIMM University",
                    "location": "BIMM Music Institute London",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Music Production & Music Business",
                    "university": "BIMM University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Musical Theatre and Dance",
                    "university": "BIMM University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Performing Arts",
                    "university": "BIMM University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Performing Arts (Top-up)",
                    "university": "BIMM University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Popular Music Performance",
                    "university": "BIMM University",
                    "location": "BIMM Music Institute London",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Popular Music Performance",
                    "university": "BIMM University",
                    "location": "Multiple Locations",
                    "qualification": "BMus (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Popular Music Performance & Event Management",
                    "university": "BIMM University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Popular Music Performance & Music Business",
                    "university": "BIMM University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Popular Music Performance & Music Production",
                    "university": "BIMM University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Popular Music Performance & Songwriting",
                    "university": "BIMM University",
                    "location": "Multiple Locations",
                    "qualification": "BMus (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Production Design for Screen & Film",
                    "university": "BIMM University",
                    "location": "MetFilm School Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Screen and Film",
                    "university": "BIMM University",
                    "location": "MetFilm School Brighton",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Songwriting",
                    "university": "BIMM University",
                    "location": "BIMM Music Institute London",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Songwriting",
                    "university": "BIMM University",
                    "location": "Multiple Locations",
                    "qualification": "BMus (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Songwriting & Music Business",
                    "university": "BIMM University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Songwriting & Music Production",
                    "university": "BIMM University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Business for the Creative Industries",
                    "university": "BIMM University",
                    "location": "BIMM Music Institute Brighton",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Learning and Teaching in the Creative Industries",
                    "university": "BIMM University",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performing Arts",
                    "university": "BIMM University",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Popular Music Practice",
                    "university": "BIMM University",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Business for the Creative Industries",
                    "university": "BIMM University",
                    "location": "BIMM Music Institute Brighton",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Learning and Teaching in the Creative Industries",
                    "university": "BIMM University",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performing Arts",
                    "university": "BIMM University",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Popular Music Practice",
                    "university": "BIMM University",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "6c5b46488a301a964270ae053eb3f708",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Accounting and Finance with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Accounting with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Ancient History and Archaeology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Ancient History and Archaeology with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Archaeology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Archaeology with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Art History",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Art History and Language (French, German, Italian, Japanese, Korean, Spanish)",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Art History and Language (French, German, Italian, Japanese, Korean, Spanish) with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Art History with Curating",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Art History with Curating with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Art History with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Biomedicine",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biomedicine with Advanced Pathways",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biomedicine with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Bioscience with Management",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Analytics with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business and Human Resource Management",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business and Human Resource Management with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business and Sustainability",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business and Sustainability with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business Computing",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Computing with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business Psychology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Classical Studies",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Classical Studies with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Classics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computing",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computing with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Creative Writing and English",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Creative Writing and English with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Creative Writing with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Criminology and Criminal Justice",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Culinary Industry Management",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BBA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Culinary Industry Management with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BBA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Data Science and Computing",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Data Science and Computing with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Digital Media",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Digital Media and Language (French, German, Italian, Japanese, Korean, Spanish)",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Digital Media and Language (French, German, Italian, Japanese, Korean, Spanish) with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Digital Media with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Economics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Economics and Business",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Economics and Business with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Economics with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "English",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English and Language (French, German, Italian, Japanese, Korean, Spanish)",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English and Language (French, German, Italian, Japanese, Korean, Spanish) with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "English and Linguistics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English and Linguistics with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "English with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Entrepreneurship and Innovation",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Entrepreneurship and Innovation with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Environment and Sustainability",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Environment and Sustainability with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Environment, Culture and Communication",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Environment, Culture and Communication with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Environmental Geoscience",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Film and Media",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Film and Media with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Film, Media and Language (French, German, Italian, Japanese, Korean, Spanish)",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Film, Media and Language (French, German, Italian, Japanese, Korean, Spanish) with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Finance",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Finance with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Financial Economics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Financial Economics with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Geography",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Geography and International Development",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Geography and International Development with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Geography with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Geology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Global Politics and International Relations",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Global Politics and International Relations with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Global Politics and Language (French, German, Italian, Japanese, Korean, Spanish)",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Global Politics and Language (French, German, Italian, Japanese, Korean, Spanish) with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Health and Social Care and Management",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Health and Social Care and Management with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Health and Social Care with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Health and Social Care with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Health and Social Care with Professional Practice",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Health and Social Care with Professional Practice",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Health and Social Care with Professional Practice with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Health and Social Care with Professional Practice with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "History",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "History and Language (French, German, Italian, Japanese, Korean, Spanish)",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "History and Language (French, German, Italian, Japanese, Korean, Spanish) with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "History with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "International Business",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Business with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Japanese Studies",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Japanese Studies with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Japanese Studies with International Experience",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Journalism and Digital Media",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Journalism and Digital Media with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Journalism and Language (French, German, Italian, Japanese, Korean, Spanish)",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Journalism and Language (French, German, Italian, Japanese, Korean, Spanish) with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Journalism and Media",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Journalism and Media with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Language Teaching and Languages (French, German, Italian, Japanese, Korean, Spanish)",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Language Teaching and Languages (French, German, Italian, Japanese, Korean, Spanish) with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Language Teaching and Languages (French, German, Italian, Japanese, Korean, Spanish) with International Experience",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Law",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Law and Business",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Law and Business with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Law and Commercial Law",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Law and Human Rights",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Law and Language (French, German, Italian, Japanese, Korean, Spanish)",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Law and Language (French, German, Italian, Japanese, Korean, Spanish) with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Law and Legal Practice",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Law with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Liberal Arts",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Liberal Arts with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Linguistics and Culture",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Linguistics and Culture with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Linguistics and Language (French, German, Italian, Japanese, Korean, Spanish)",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Linguistics and Language (French, German, Italian, Japanese, Korean, Spanish) with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Linguistics and Language (French, German, Italian, Japanese, Korean, Spanish) with International Experience",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Management",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Management with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Marketing",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Marketing with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Modern Languages (French, German, Italian, Japanese, Spanish)",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Modern Languages (French, German, Italian, Japanese, Spanish) with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Modern Languages (French, German, Italian, Japanese, Spanish) with International Experience",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Natural Sciences",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Natural Sciences with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Performance and Digital Arts",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Performance and Digital Arts with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Philosophy with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Planetary Exploration with Astronomy and Astrobiology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Politics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Politics and Language (French, German, Italian, Japanese, Korean, Spanish)",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Politics and Language (French, German, Italian, Japanese, Korean, Spanish) with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Politics with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Politics, Philosophy and History",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Psychology with Accelerated Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Psychosocial Studies",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychosocial Studies with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Psychosocial Studies with Principles of Psychodynamic Counselling",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychosocial Studies with Principles of Psychodynamic Counselling with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Social and Political Sciences",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Social and Political Sciences with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sociology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sociology and Criminology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sociology and Criminology with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sociology with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Theatre and Arts Management",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Theatre and Arts Management with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Theatre and English",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Theatre and English with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Theatre and Performance",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Theatre and Performance with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Writing and Performance",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Writing and Performance with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Accounting",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History and Archaeology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History and Archaeology with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History and Language (French, German, Italian, Japanese, Korean, Spanish)",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History and Language (French, German, Italian, Japanese, Korean, Spanish) with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History with Curating",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History with Curating with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedicine",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioscience with Management",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Human Resource Management",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Human Resource Management with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Sustainability",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Sustainability with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Computing",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Psychology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing and English",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing and English with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Criminal Justice",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Computing",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media and Language (French, German, Italian, Japanese, Korean, Spanish)",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media and Language (French, German, Italian, Japanese, Korean, Spanish) with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Business",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Business with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Language (French, German, Italian, Japanese, Korean, Spanish)",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Language (French, German, Italian, Japanese, Korean, Spanish) with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Linguistics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Linguistics with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Entrepreneurship and Innovation",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Entrepreneurship and Innovation with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment and Sustainability",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment and Sustainability with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment, Culture and Communication",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment, Culture and Communication with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Geoscience",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Media",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Media with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film, Media and Language (French, German, Italian, Japanese, Korean, Spanish)",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film, Media and Language (French, German, Italian, Japanese, Korean, Spanish) with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Economics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Economics with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography and International Development",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography and International Development with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Politics and International Relations",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Politics and International Relations with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Politics and Language (French, German, Italian, Japanese, Korean, Spanish)",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Politics and Language (French, German, Italian, Japanese, Korean, Spanish) with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Social Care and Management",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Social Care and Management with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Social Care with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Social Care with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Social Care with Professional Practice",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Social Care with Professional Practice",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Social Care with Professional Practice with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Social Care with Professional Practice with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Language (French, German, Italian, Japanese, Korean, Spanish)",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Language (French, German, Italian, Japanese, Korean, Spanish) with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Japanese Studies",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Japanese Studies with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism and Language (French, German, Italian, Japanese, Korean, Spanish)",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism and Language (French, German, Italian, Japanese, Korean, Spanish) with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language Teaching and Languages (French, German, Italian, Japanese, Korean, Spanish)",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language Teaching and Languages (French, German, Italian, Japanese, Korean, Spanish) with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Business",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Commercial Law",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Human Rights",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Language (French, German, Italian, Japanese, Korean, Spanish)",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Legal Practice",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Liberal Arts",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Liberal Arts with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Life Sciences for Subjects Allied to Medicine",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics and Culture",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics and Culture with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics and Language (French, German, Italian, Japanese, Korean, Spanish)",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics and Language (French, German, Italian, Japanese, Korean, Spanish)",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics and Language (French, German, Italian, Japanese, Korean, Spanish) with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages (French, German, Italian, Japanese, Spanish)",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages (French, German, Italian, Japanese, Spanish) with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Sciences",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance and Digital Arts",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance and Digital Arts with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Planetary Exploration with Astronomy and Astrobiology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Planetary Science with Astronomy",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Language (French, German, Italian, Japanese, Korean, Spanish)",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Language (French, German, Italian, Japanese, Korean, Spanish) with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, Philosophy and History",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychodynamic Counselling",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological Studies (Child Development and Education)",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychosocial Studies",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychosocial Studies with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychosocial Studies with Principles of Psychodynamic Counselling",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychosocial Studies with Principles of Psychodynamic Counselling with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social and Political Sciences",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social and Political Sciences with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Criminology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Criminology with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and Arts Management",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and Arts Management with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and English",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and English with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and Performance",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and Performance with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Writing and Performance",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Writing and Performance with Foundation Year",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computing",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "AI, Ethics and Society",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Analytical Bioscience",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Analytical Chemistry",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied AI",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Linguistics and Communication",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Statistics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Statistics and Financial Modelling",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and Heritage",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "Grad Dip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astrobiology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Banking and Finance",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Brain and Cognitive Development",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Research",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Civilisation",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate Change",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognition and Computation",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Neuroscience and Neuropsychology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary History and Politics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Industries Management and Policy",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing and Contemporary Studies",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Law and Criminal Justice",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Culinary Innovation Management",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Culture, Diaspora, Ethnicity",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Curating and Collections Management",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and AI",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Business",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media Culture",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media Design",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media Management",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "Grad Dip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Neuroscience",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Entrepreneurship and Innovation",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment and Sustainability",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "European History",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Screen Media",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Screen Media (with Study Abroad)",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Programming and Curating",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Data Analytics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Economics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French Studies",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "Grad Dip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender and Sexuality Studies",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geographic Data Science",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German Studies",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "Grad Dip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global History: Empires, States and Cultures",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Infectious Diseases",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Political Economy",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Government, Policy and Politics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Clinical Psychological Sciences",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Historical Research",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art with Collections Management",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art with Curating",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Photography",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Photography with Curating",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hospitality Innovation Management",
                    "university": "Birkbeck, University of London",
                    "location": "Le Cordon Bleu",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development and Social Justice",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Security and Global Governance",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Japanese Studies",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "Grad Dip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language Teaching/Teaching English to Speakers of Other Languages (TESOL)",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "Grad Dip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law General",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Commercial Law",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Constitutional Politics, Law and Theory",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Environmental Law, Governance and Policy",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Global Political Economy",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Human Rights",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with International Intellectual Property",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Law and New Technologies",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and Finance",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management Consultancy and Organisational Change",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Business Innovation",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with International Business",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Marketing",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing Analytics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "Grad Dip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical and Health Humanities",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and Comparative Literatures",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Literature and Culture (1900-present)",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Museum Cultures",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Museum Cultures with Collections Management",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Museum Cultures with Curating",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Organizational Governance and Sustainability",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Organizational Psychology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Communication",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, Philosophy and Economics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychoanalytic Studies",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological Research Methods",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychosocial Studies",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public History and Heritage",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Policy and Management",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Qualifying Law Degree",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantitative Finance with Data Science",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Screenwriting",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social and Political Theory",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research and Applied Linguistics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research and Criminology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research and Gender and Sexuality",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research and Law and Legal Studies",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research and Psychosocial Studies",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Latin American Studies",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "Grad Dip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Management",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Management and Marketing",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Management and the Business of Football",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Biology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Molecular Biology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Cities",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and Performance",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre Directing",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MFA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Victorian Studies",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Writing for Screen and Stage",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computing",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "AI, Ethics and Society",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Analytical Bioscience",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Analytical Chemistry",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied AI",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Linguistics and Communication",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Statistics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Statistics and Financial Modelling",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and Heritage",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "Graduate Certificate",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astrobiology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Banking and Finance",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Brain and Cognitive Development",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Career Coaching and Coaching Psychology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Research",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Civilisation",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate Change",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognition and Computation",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Neuroscience and Neuropsychology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary History and Politics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Couple and Individual Psychodynamic Counselling and Psychotherapy",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Couple Psychoanalytic Psychotherapy",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Industries Management and Policy",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing and Contemporary Studies",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Law and Criminal Justice",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Culinary Innovation Management",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Culture, Diaspora, Ethnicity",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Curating and Collections Management",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and AI",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Business",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media Culture",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media Design",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media Management",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Econometrics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "Grad Dip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Neuroscience",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Entrepreneurship and Innovation",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment and Sustainability",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "European History",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Screen Media",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Programming and Curating",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Data Analytics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Economics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Foundation for Counselling and Psychotherapy Training",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "Graduate Certificate",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French Studies",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "Grad Dip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender and Sexuality Studies",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geographic Data Science",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German Studies",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "Grad Dip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global History: Empires, States and Cultures",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Infectious Diseases",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Political Economy",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Government, Policy and Politics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Clinical Psychological Sciences",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Historical Research",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "Graduate Certificate",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art with Collections Management",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art with Curating",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Photography",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Photography with Curating",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development and Social Justice",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Security and Global Governance",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Japanese Studies",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "Grad Dip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language Teaching/Teaching English to Speakers of Other Languages (TESOL)",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "Grad Dip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law General",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Commercial Law",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Constitutional Politics, Law and Theory",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Environmental Law, Governance and Policy",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Global Political Economy",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Human Rights",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with International Intellectual Property",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Law and New Technologies",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistic Studies",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "Graduate Certificate",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and Finance",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management Consultancy and Organisational Change",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Business Innovation",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with International Business",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Marketing",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing Analytics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration (MBA)",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "Grad Dip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "Graduate Certificate",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical and Health Humanities",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and Comparative Literatures",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Literature and Culture (1900-present)",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Museum Cultures",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Museum Cultures with Collections Management",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Museum Cultures with Curating",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neurodiversity Coaching",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Organizational Governance and Sustainability",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Organizational Psychology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Communication",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, Philosophy and Economics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychoanalytic Studies",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychodynamic Counselling and Psychotherapy",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychodynamic Counselling and Psychotherapy with Children and Adolescents",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychodynamics of Human Development",
                    "university": "Birkbeck, University of London",
                    "location": "Multiple Locations",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological Research Methods",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychosocial Studies",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychosocial Studies",
                    "university": "Birkbeck, University of London",
                    "location": "No Locations",
                    "qualification": "Graduate Certificate",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public History and Heritage",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Policy and Management",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Qualifying Law Degree",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantitative Finance with Data Science",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Screenwriting",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social and Political Theory",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research and Applied Linguistics",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research and Criminology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research and Gender and Sexuality",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research and Law and Legal Studies",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research and Psychosocial Studies",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Latin American Studies",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "Grad Dip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Management",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Management and Marketing",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Management and the Business of Football",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics for Data Science",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "Graduate Certificate",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Biology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Molecular Biology",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Cities",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and Performance",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Victorian Studies",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Web Design and Development",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Writing for Screen and Stage",
                    "university": "Birkbeck, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "9906bf9d2f983a9532bd6094b5f342d2",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Accounting and Finance with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Adult Nursing (January)",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Adult Nursing (September)",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Applied Theatre (Community and Education)",
                    "university": "Birmingham City University",
                    "location": "School of Acting, Bournville",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Architectural Technology",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Architectural Technology with Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Architecture",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Architecture with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Art and Design",
                    "university": "Birmingham City University",
                    "location": "Margaret Street",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Art and Design with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Margaret Street",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Art and Design with Creative Technologies",
                    "university": "Birmingham City University",
                    "location": "Margaret Street",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Art and Design with Creative Technologies with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Margaret Street",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Art Business (Level 6 | Top-Up)",
                    "university": "Birmingham City University",
                    "location": "Margaret Street",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Automotive Engineering",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Automotive Engineering with Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biomedical Science with Foundation Year",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Business Administration (Top Up)",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Business and Management",
                    "university": "Birmingham City University",
                    "location": "South and City College Birmingham",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Finance",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Information Technology",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Information Technology with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Business Management",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Business with Marketing",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Child Nursing (January)",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Child Nursing (September)",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Civil Engineering with Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Computer Networks and Security",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Networks and Security with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Computer Science with Artificial Intelligence",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science with Artificial Intelligence with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Computing (Top-Up)",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Computing and Information Technology",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computing and Information Technology with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Conductive Education",
                    "university": "Birmingham City University",
                    "location": "National Institute of Conductive Ed",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Construction Management",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Construction Management with Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Criminology",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology, Policing and Investigation",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Cyber Security with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Diagnostic Radiography",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Digital Animation",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Digital Animation with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Digital Film Production",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Digital Film Production with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Digital Media Computing",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Digital Media Computing with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Early Childhood Studies with Graduate Practitioner Competencies",
                    "university": "Birmingham City University",
                    "location": "University House",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Early Childhood Studies with Graduate Practitioner Competencies with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Early Years",
                    "university": "Birmingham City University",
                    "location": "South and City College Birmingham",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Economics",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Education Studies",
                    "university": "Birmingham City University",
                    "location": "University House",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Education Studies with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Electronic Engineering with Foundation year",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "English",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English and Creative Writing",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English and Journalism",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English Language and Literature",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English Literature",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Events Management",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fashion Business and Promotion",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fashion Business and Promotion with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Fashion Communication",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fashion Communication with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Fashion Design",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fashion Design with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Film and Screenwriting",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Film and Screenwriting with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Filmmaking",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Filmmaking with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Finance and Investment",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Birmingham City University",
                    "location": "Margaret Street",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fine Art with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Margaret Street",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Game Design",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Game Programming",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Game Technical Art",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Game Technology",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Gemmology and Jewellery Studies",
                    "university": "Birmingham City University",
                    "location": "Vittoria Street",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Gemmology and Jewellery Studies with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Vittoria Street",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Graphic Design with Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Horology",
                    "university": "Birmingham City University",
                    "location": "Vittoria Street",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Horology with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Vittoria Street",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Illustration",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Illustration with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Interior Architecture and Design",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Interior Architecture and Design with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "International Business (Top-Up)",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "International Finance (Top Up)",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "International Marketing (Top Up)",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Jewellery and Objects",
                    "university": "Birmingham City University",
                    "location": "Vittoria Street",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Jewellery and Objects with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Vittoria Street",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Jewellery and Silversmithing",
                    "university": "Birmingham City University",
                    "location": "Vittoria Street",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Jewellery and Silversmithing Design for Industry (Level 6 | Top Up)",
                    "university": "Birmingham City University",
                    "location": "Vittoria Street",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Journalism",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Journalism with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Landscape Architecture",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Landscape Architecture with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Law",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Law and Practice",
                    "university": "Birmingham City University",
                    "location": "BMet (Matt Boulton)",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Law with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Law with American Legal Studies",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Law with Business Law",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Law with Criminal Justice",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Learning Disability Nursing",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Marketing",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Marketing with Advertising and Public Relations",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Marketing with Digital Communication",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mechanical Engineering with Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Media and Communication",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Media and Communication",
                    "university": "Birmingham City University",
                    "location": "BMET (James Watt)",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Media and Communication with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Media Production",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Media Production with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Mental Health Nursing (January)",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Mental Health Nursing (September)",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Midwifery (September)",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Midwifery with Public Health (September)",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Music Business",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Music Business with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Music Technology",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing - Adult and Child (Dual Award) (January)",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "Master in Science (with Honours) \u2013 MSci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Nursing - Adult and Child (Dual Award) (September)",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Nursing - Adult and Learning Disabilities (Dual Award)",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Nursing - Adult and Mental Health (Dual Award) (January)",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "Master in Science (with Honours) \u2013 MSci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Nursing - Adult and Mental Health (Dual Award) (September)",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Nursing - Learning Disabilities and Child (Dual Award)",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Nursing - Mental Health and Child (Dual Award) (January)",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Nursing - Mental Health and Child (Dual Award) (September)",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Nursing - Mental Health and Learning Disabilities (Dual Award)",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Nursing Associate",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "24-48"
                },
                {
                    "course_name": "Operating Department Practice",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Paramedic Science",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Photography",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Photography with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Popular Music",
                    "university": "Birmingham City University",
                    "location": "South and City College Birmingham",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Popular Music",
                    "university": "Birmingham City University",
                    "location": "South and City College Birmingham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Primary Education with QTS",
                    "university": "Birmingham City University",
                    "location": "University House",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Product Design",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Product Design with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology and Counselling",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Psychology with Criminology",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Quantity Surveying",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Quantity Surveying with Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Real Estate",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Secondary Physical Education with QTS",
                    "university": "Birmingham City University",
                    "location": "University House / City South Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Social Work",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Sociology",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sociology and Criminology",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sociology and Criminology with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Sound Engineering and Production",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sound Engineering and Production with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Speech and Language Therapy",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport and Exercise Science with Foundation Year",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Sport Business Management",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sports Coaching and Development",
                    "university": "Birmingham City University",
                    "location": "Aston Villa Foundation",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sports Coaching and Physical Education",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sports Coaching and Physical Education with Foundation Year",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Sports Journalism",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sports Journalism with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Sports Therapy",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Stage Management",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Textile Design",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Textile Design with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Therapeutic Radiography",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Visual Effects",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Visual Effects with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "Millennium Point Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Working with Children, Young People and Families",
                    "university": "Birmingham City University",
                    "location": "University House",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Working with Children, Young People and Families with a Foundation Year",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Youth Work and Communities",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance (Masters Stage)",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc",
                    "duration": "6 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Child Psychology",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture (RIBA part 2)",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MArch",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts and Education Practices",
                    "university": "Birmingham City University",
                    "location": "Birmingham School of Art, Margaret Street",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts and Project Management",
                    "university": "Birmingham City University",
                    "location": "Birmingham School of Art, Margaret Street",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive Engineering",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Big Data Analytics",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Building Information Modelling and Digital Construction",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Community Health Nursing Specialist Practitioner",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Journalism",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design and Visualisation",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Management",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Transformation",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (International Education)",
                    "university": "Birmingham City University",
                    "location": "University House",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Design",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Management",
                    "university": "Birmingham City University",
                    "location": "Parkside Building Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Feature Film Development",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Distribution and Marketing",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Investment",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Birmingham City University",
                    "location": "Margaret Street",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Future Media",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gemmology",
                    "university": "Birmingham City University",
                    "location": "School of Jewellery",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "General Practice Nursing",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Business Operations",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Architecture and Design",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Internal Audit Management and Consultancy",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Law",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "LLM",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Resource Management",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International MBA",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MBA",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Professional Accounting (ACCA)",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Jewellery and Related Products",
                    "university": "Birmingham City University",
                    "location": "School of Jewellery",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Jewellery and Related Products",
                    "university": "Birmingham City University",
                    "location": "School of Jewellery",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Landscape Architecture",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Landscape Architecture (Conversion) - MLA",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "LLM Legal Practice",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Logistics and Supply Chain Management",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Luxury Brand Management",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and Entrepreneurship",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and Finance",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and International Business",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and Marketing",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Public Health",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "MPH",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Masters by Research - MRes",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Cultural Studies",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media Production",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing - Adult - Pre-registration",
                    "university": "Birmingham City University",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing - Mental Health - Pre-registration",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Planning Built Environments",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Policing and Intelligence Analysis",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary and Early Years (Physical Education) with QTS - PGCE",
                    "university": "Birmingham City University",
                    "location": "University House",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary and Early Years Education with QTS - PGCE",
                    "university": "Birmingham City University",
                    "location": "University House",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Education with Specialism in Mathematics with QTS - PGCE",
                    "university": "Birmingham City University",
                    "location": "University House",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Education with Specialism in SEN with QTS - PGCE",
                    "university": "Birmingham City University",
                    "location": "University House",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Relations",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantity Surveying",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate Management",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Art and Design - PGCE",
                    "university": "Birmingham City University",
                    "location": "University House",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Computer Science - PGCE",
                    "university": "Birmingham City University",
                    "location": "University House",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Design and Technology: Food, Textiles and Product Design - PGCE",
                    "university": "Birmingham City University",
                    "location": "University House",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Drama - PGCE",
                    "university": "Birmingham City University",
                    "location": "University House",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary English - PGCE",
                    "university": "Birmingham City University",
                    "location": "University House",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Geography - PGCE",
                    "university": "Birmingham City University",
                    "location": "University House",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary History - PGCE",
                    "university": "Birmingham City University",
                    "location": "University House",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Mathematics - PGCE",
                    "university": "Birmingham City University",
                    "location": "University House",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Modern Foreign Languages - PGCE",
                    "university": "Birmingham City University",
                    "location": "University House",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Music - PGCE",
                    "university": "Birmingham City University",
                    "location": "University House",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Religious Education - PGCE",
                    "university": "Birmingham City University",
                    "location": "University House",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Science: Chemistry - PGCE",
                    "university": "Birmingham City University",
                    "location": "University House",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Science: Physics - PGCE",
                    "university": "Birmingham City University",
                    "location": "University House",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Public Health Nurse (Health Visitor)",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Public Health Nurse (School Nurse)",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Complex Needs Rehabilitation Work (Visual Impairment) (Top-up)",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Nutrition",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Textile and Surface Design",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Transforming and Leading in Health Care",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "User Experience Design",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Communication",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Clinical Practice",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc",
                    "duration": "20 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Child Psychology",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Practice (RIBA part 3 exemption)",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture (RIBA part 2)",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MArch",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts and Education Practices",
                    "university": "Birmingham City University",
                    "location": "Birmingham School of Art, Margaret Street",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts and Project Management",
                    "university": "Birmingham City University",
                    "location": "Birmingham School of Art, Margaret Street",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive Engineering",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc",
                    "duration": "20 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Big Data Analytics",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc",
                    "duration": "20 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Building Information Modelling and Digital Construction",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Hypnosis and Related Techniques",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "Advanced Professional Diploma",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc",
                    "duration": "20 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation of the Historic Environment",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc",
                    "duration": "20 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc",
                    "duration": "20 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Journalism",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Management",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Work-based learning",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Transformation",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Birmingham City University",
                    "location": "University House",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "EdD",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Special Needs Education)",
                    "university": "Birmingham City University",
                    "location": "University House",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Birmingham City University",
                    "location": "Margaret Street",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Internal Audit Management and Consultancy",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Internal Auditor Professional \u2013 Certified Internal Auditor (CIA) Pathway",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Resource Management",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "PgDip (Initial Qualification)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Resource Management",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Landscape Architecture",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "LLM Legal Practice",
                    "university": "Birmingham City University",
                    "location": "Curzon Building Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Logistics and Supply Chain Management",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Public Health",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "MPH",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Masters by Research - MRes",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Cultural Studies",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Ultrasound",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Planning Built Environments",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MA",
                    "duration": "20 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc",
                    "duration": "28 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantity Surveying",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc",
                    "duration": "20 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Radiography",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate Management",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc",
                    "duration": "20 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Safeguarding",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Complex Needs Rehabilitation Work (Visual Impairment) (Top-up)",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Nutrition",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Transforming and Leading in Health Care",
                    "university": "Birmingham City University",
                    "location": "City South Campus, Edgbaston",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "User Experience Design",
                    "university": "Birmingham City University",
                    "location": "City Centre Campuses",
                    "qualification": "MSc (PG)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "fe9f199735db0fff63e5ebc7f3149790",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Art and Design (Graphic Design)",
                    "university": "BMet (Birmingham Metropolitan College)",
                    "location": "Matthew Boulton College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Artificial Intelligence (AI) Solutions & Applications",
                    "university": "BMet (Birmingham Metropolitan College)",
                    "location": "Sutton Coldfield College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Business",
                    "university": "BMet (Birmingham Metropolitan College)",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Cloud Computing",
                    "university": "BMet (Birmingham Metropolitan College)",
                    "location": "Sutton Coldfield College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Community Coaching",
                    "university": "BMet (Birmingham Metropolitan College)",
                    "location": "Sutton Coldfield College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Construction Management",
                    "university": "BMet (Birmingham Metropolitan College)",
                    "location": "James Watt College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Counselling and Applied Psychology",
                    "university": "BMet (Birmingham Metropolitan College)",
                    "location": "James Watt College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Cyber Security & Cloud Networking",
                    "university": "BMet (Birmingham Metropolitan College)",
                    "location": "Matthew Boulton College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Digital Marketing And Communications (HTQ)",
                    "university": "BMet (Birmingham Metropolitan College)",
                    "location": "Matthew Boulton College",
                    "qualification": "HNC",
                    "duration": "30 weeks",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Engineering (General Engineering)",
                    "university": "BMet (Birmingham Metropolitan College)",
                    "location": "James Watt College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Foundation Degree Electronic Engineering (Instrumentation & Control)",
                    "university": "BMet (Birmingham Metropolitan College)",
                    "location": "James Watt College",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "1"
                },
                {
                    "course_name": "Foundation Degree Manufacturing Engineering",
                    "university": "BMet (Birmingham Metropolitan College)",
                    "location": "James Watt College",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "HND Counselling & Applied Psychology",
                    "university": "BMet (Birmingham Metropolitan College)",
                    "location": "James Watt College",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "HTQ - HND Digital Technologies (Cloud Networking)",
                    "university": "BMet (Birmingham Metropolitan College)",
                    "location": "Matthew Boulton College",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48-128"
                },
                {
                    "course_name": "HTQ- HND Management in Construction",
                    "university": "BMet (Birmingham Metropolitan College)",
                    "location": "James Watt College",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Software Development & Programming",
                    "university": "BMet (Birmingham Metropolitan College)",
                    "location": "Sutton Coldfield College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "BMet (Birmingham Metropolitan College)",
                    "location": "Sutton Coldfield College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "883a7f5657b0c4a885a848d46f8e0207",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Accounting and Finance (Top-Up)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance (with Foundation Year)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Adult Nursing",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Adult Nursing with Foundation Year",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Applied Health and Social Care (Top-Up)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Humanities (Accelerated Degree)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business and Entrepreneurship",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business and Human Resource Management",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business and Law",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business and Marketing",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management (top-up)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (with Foundation Year)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business, Accounting and Finance",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science with Foundation Year",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA/BSc (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Counselling Studies (Top-Up)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling Studies and Working with Children, Young People & Families",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Counselling Studies and Working with Children, Young People & Families (with Foundation Year)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Counselling, Mental Health and Wellbeing",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Counselling, Mental Health and Wellbeing (with Foundation Year)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Criminology",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology (with Foundation Year)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Drama, Theatre and Applied Performance (Top-up)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Early Childhood Studies (with Foundation Year)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Early Childhood Studies SIPE (Studies in Primary Education) Top Up",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Childhood Studies SIPE (Studies in Primary Education) with Professional Practice Top Up",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Years - Foundation Degree",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Education and Special Educational Needs and Disabilities (SEND)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Education and Special Educational Needs and Disabilities (SEND) with Foundation Year",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "English",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English (with Foundation Year)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Forensic Psychology (with Foundation Year)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Health and Social Care (with Foundation Year)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "History",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "History  (with Foundation Year)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Law",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Law (with Foundation Year)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Mental Health Nursing",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Paramedic Science",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Primary Education  3-11 Foundation Degree Progression Top up with QTS",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Education (3-11) with Foundation Year (QTS)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Primary Education (3-11) with QTS",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Professional Policing with Foundation Year",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Psychology",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology (with Foundation Year)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Psychology and Childhood Studies",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology and Childhood Studies (with Foundation Year)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Psychology and Counselling Studies",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology and Counselling Studies (with Foundation Year)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Psychology with Criminology",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology with Criminology (with Foundation Year)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Secondary Education (Science with Chemistry) 11-16 with QTS with Post 16 Enhancement",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BASc (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Secondary Education 11-16 Humanities* (*History, Geography, RE) with QTS with Post 16 Enhancement",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Secondary Education 11-16 Religious Education with QTS with Post 16 Enhancement",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Secondary Education Biology 11-16 with QTS with Post 16 Enhancement",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Secondary Education English 11-16 with QTS with Post 16 Enhancement",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Secondary Education Mathematics 11-16 with QTS with Post 16 Enhancement",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Secondary Education Physical Education 11-16 with QTS with Post 16 Enhancement",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Secondary Education Physics 11-16 with QTS with Post 16 Enhancement",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport and Exercise Science (Top-up)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Science with Foundation Year",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sport and Exercise Therapy",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport Coaching",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport Coaching (Top Up)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Coaching and Performance (Top Up)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Coaching with Foundation Year",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sport Development with Coaching (Top up)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Studies in Primary Education",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Studies in Primary Education (Top Up)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Studies in Primary Education (with Foundation Year)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Teaching and Learning Foundation Degree",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Theology and Philosophy",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Theology and Philosophy (with Foundation Year)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Working with Children, Young People &  Families",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Working with Children, Young People &  Families (with Foundation Year)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Working with Children, Young People & Families (Top-Up)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Applied Health and Social Care (Top-Up)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BSc (Hons)",
                    "duration": "1.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling Studies (Top-Up)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling Studies and Working with Children, Young People & Families",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "4.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Counselling, Mental Health and Wellbeing",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "4.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "4.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Early Childhood Studies SIPE (Studies in Primary Education) Top Up",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "1.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Years - Foundation Degree",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "FdA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "English",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "4.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Integrative Counselling - Foundation Degree (Professional)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "FD",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Introductory Certificate in Counselling",
                    "university": "Birmingham Newman University",
                    "location": "No Locations",
                    "qualification": "Cert",
                    "duration": "13 weeks",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Studies in Primary Education",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "4.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Teaching and Learning Foundation Degree",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "FD",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Theology and Philosophy",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "4.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Working with Children, Young People &  Families",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "4.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Working with Children, Young People & Families (Top-Up)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "BA (Hons)",
                    "duration": "1 to 2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Adult Nursing",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Humanities",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (conversion)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Social Justice",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Doctor of Philosophy",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Online)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "MA",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrative Counselling",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leading Safeguarding in Education",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration (MBA)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Philosophy",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health Nursing",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Conversion)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Applied Humanities",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Attachment, Trauma & Mental Health",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chaplaincy",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (conversion)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Social Justice",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Doctor of Philosophy",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Online)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrative Psychotherapy",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "MSc",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leading Safeguarding in Education",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Philosophy",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "NPQ SENCO (Special Educational Needs Coordinator)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paediatric and Maternity Chaplaincy",
                    "university": "Birmingham Newman University",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional and Academic Learning in Education",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Conversion)",
                    "university": "Birmingham Newman University",
                    "location": "Birmingham Newman University",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "f0f1eb9404c9134f63ca5a010aa41aa6",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient and Medieval History",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History and Archaeology",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Golf Management Studies",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and History",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence and Computer Science",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence and Computer Science",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts and Sciences",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BASc (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry, Medical",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Communications",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Communications",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Marketing",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Marketing",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with a Modern Language",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with a Modern Language",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Business Management",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Business Management",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Medicinal Chemistry and Drug Discovery",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Medicinal Chemistry and Drug Discovery",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Sustainability",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Sustainability",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with Industrial Experience",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Literature & Civilisation and Philosophy",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Literature and Civilisation",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Engineering",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Software Engineering",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science/Software Engineering",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Cultural and Creative Industries",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dental Hygiene and Therapy",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dentistry",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BDS",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media and Communications",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama and Creative Writing",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama and English Literature",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama and Film",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Politics",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with German",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Spanish",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education and Sociology",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering with Foundation Year",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Linguistics",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Literature",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Philosophy",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Classical Literature & Civilisation",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Creative Writing",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Film",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and History",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and History of Art",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Philosophy",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental and Engineering Geoscience",
                    "university": "University of Birmingham",
                    "location": "Edgbaston Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Environmental Change and Sustainability",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Environmental Change and Sustainability",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Environmental Change and Sustainability",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "EPS Futures",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Creative Writing",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and German",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Italian",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Portuguese",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Russian",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Spanish",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography and Urban & Regional Planning",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography and Urban & Regional Planning",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology and Physical Geography",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology and Physical Geography",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology and Physical Geography",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Italian",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Russian",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Spanish",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and History of Art",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Philosophy",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Politics",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Theology",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Neuroscience",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Sciences",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Human Sciences",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Communications",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Language",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development and Politics",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "136"
                },
                {
                    "course_name": "International Development and Politics",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "International Law and Globalisation",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Development",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "136"
                },
                {
                    "course_name": "International Relations and Development",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "International Relations with Economics",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations with Economics",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations with French",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations with German",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations with Spanish",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Portuguese",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Spanish",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (Graduate Entry)",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Business Studies",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with French Law",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with German Law",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Liberal Arts",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science and Engineering",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science and Engineering",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science and Engineering",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical Economics and Statistics",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Computer Science",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Computer Science",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Computer Science",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Computer Science",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Music",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Philosophy",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Business Management",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Business Management",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Study in Continental Europe",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering (Automotive)",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering (Automotive)",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronic and Robotic Engineering",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronic and Robotic Engineering",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronic and Robotic Engineering",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BEng",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronic and Robotic Engineering",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BEng",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "MB ChB",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery",
                    "university": "University of Birmingham",
                    "location": "Edgbaston Campus",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and English Literature",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and History",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Modern Languages and History of Art",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and Linguistics",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and Music",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages with Business Management",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages with Translation",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Money, Banking and Finance",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BMus (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Sciences",
                    "university": "University of Birmingham",
                    "location": "Edgbaston Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BNurs (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Child)",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BNurs (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BNurs (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Palaeontology and Geology",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Palaeontology and Geology",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Palaeontology and Geology",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "MPharm (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Sociology",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy, Religion and Ethics",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Sciences with Foundation Year",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physician Associate Studies (Postgraduate)",
                    "university": "University of Birmingham",
                    "location": "Edgbaston Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics and Astrophysics",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics and Astrophysics",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics and Astrophysics",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Data Science",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Data Science",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Medical Physics",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Medical Physics",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Particle Physics & Cosmology",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Particle Physics & Cosmology",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Policy, Politics and Economics",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Policy, Politics and Economics",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Philosophy",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Philosophy",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Social Policy",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Social Policy",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Sociology",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Sociology",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, Religion and Philosophy",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Psychological Practice",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Religion",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology in Education BSc (Hons)",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Russian and Portuguese",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian and Spanish",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology and African Studies",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology and Archaeology",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology and Classical Literature and Civilisation",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology and History",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology and Politics",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy and Criminology",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Social Policy and Criminology",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy and Sociology",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Social Policy and Sociology",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work (Postgraduate Entry)",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Sociology and Criminology",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Criminology",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Spanish",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Portuguese",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport, Exercise and Health Sciences",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport, Physical Education and Coaching Science",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religion",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics and Applied Mathematics",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics and Applied Mathematics",
                    "university": "University of Birmingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "e99800e4eb24364cbcd70bd8a829bba8",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "eb75b86a755de8b2b0793ef3d1830fbe",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Archaeology and History",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Archaeology and History (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business (Finance)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business (Finance) (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business (Marketing)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business (Marketing) (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Childhood, Youth and Families in Practice",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Childhood, Youth and Families in Practice",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling Practitioner",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Counselling Practitioner (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Criminology (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Childhood Education in Practice",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Childhood Education in Practice",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Childhood Education in Practice (Graduate Practitioner)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Childhood Education in Practice GRANTHAM",
                    "university": "Bishop Grosseteste University",
                    "location": "Grantham College",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Early Childhood Studies (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education in Practice",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education in Practice",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education in Practice GRANTHAM",
                    "university": "Bishop Grosseteste University",
                    "location": "Grantham College",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Education Studies (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies and English",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Education Studies and English (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies and History",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Education Studies and History (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies and Sociology",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Education Studies and Sociology (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies and Special Educational Needs, Disability and Inclusion (SENDI)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Education Studies and Special Educational Needs, Disability and Inclusion (SENDI) (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies and Sport",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Education Studies and Sport (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies and TESOL",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Education Studies and TESOL with Foundation Year",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies and Theology, Philosophy & Ethics",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Education Studies and Theology, Philosophy & Ethics (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and History",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "English and History (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language & Teaching",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "English Language & Teaching (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "English Literature (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and TESOL",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "English Literature and TESOL with Foundation Year",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health & Social Care",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Health & Social Care (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Social Care Practitioner - GRANTHAM",
                    "university": "Bishop Grosseteste University",
                    "location": "Grantham College",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Wellbeing in Early Childhood",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Health and Wellbeing in Early Childhood (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "History (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Theology, Philosophy & Ethics",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "History and Theology, Philosophy & Ethics (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Military History",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Military History (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Education with QTS",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Primary Teaching Studies",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Teaching Studies with QTS",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Practice in Special Educational Needs and Disability GRANTHAM",
                    "university": "Bishop Grosseteste University",
                    "location": "Grantham College",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Psychology (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Business",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Psychology and Business (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Counselling",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Psychology and Counselling (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Early Childhood Studies",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Psychology and Early Childhood Studies (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Education Studies",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Psychology and Education Studies (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and English",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Psychology and English (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Sociology",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Psychology and Sociology (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Special Educational Needs, Disability and Inclusion",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Psychology and Special Educational Needs, Disability and Inclusion (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Sport",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Psychology and Sport (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sociology (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Sport",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sociology and Sport (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Special Educational Needs, Disability & Inclusion and Theology, Philosophy & Ethics",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Special Educational Needs, Disability & Inclusion and Theology, Philosophy & Ethics (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Special Educational Needs, Disability and Inclusion",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Special Educational Needs, Disability and Inclusion (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Special Educational Needs, Disability and Inclusion and Early Childhood Studies",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Special Educational Needs, Disability and Inclusion and Early Childhood Studies (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Special Educational Needs, Disability and Inclusion and Sociology",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Special Educational Needs, Disability and Inclusion and Sociology (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Special Educational Needs, Disability and Inclusion and Sport",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Special Educational Needs, Disability and Inclusion and Sport (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport, Coaching and Physical Education",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sport, Coaching and Physical Education (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology, Philosophy and Ethics",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Theology, Philosophy and Ethics (with Foundation Year)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Children's Literature and Literacies",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education with TESOL (Teaching English to Speakers of Other Languages)",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Social Care Leadership",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Special Educational Needs and Disability",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religious Studies",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Education",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Social Care Leadership",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Special Educational Needs and Disability",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religious Studies",
                    "university": "Bishop Grosseteste University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "86a0ced829e53c448c37b2668588921b",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Accounting",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Animation and Game Arts",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "BA Hons Special Educational Needs and Disabilities with Inclusion (SEND)",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Business",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Business",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Business with Management",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Children, Young People and Families Practitioners",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Criminology & Criminal Justice",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Criminology and Criminal Justice",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Data Science",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Education Studies",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "FDA Early Childhood Studies HTQ",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "FDA Primary Education Teaching and Learning",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "FDA Special Educational Needs and Disabilities with Inclusion (SEND)",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "HNC Construction Management for England (HTQ)",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "HNC Modern Methods of Construction for England (HTQ)",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Integrative Counselling and Psychotherapy",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Law",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-104"
                },
                {
                    "course_name": "Law (Accelerated Route)",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Networking and Cyber Security",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Photography & Moving Image",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Psychology",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Software Development",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Sports Coaching",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Working with Children and Young People",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Working With Children and Young People",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "LLM Laws",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE (Education and Training)",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "PGCE (Education and Training)",
                    "university": "Blackburn College",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "a23b38f8e2f1b3c8f2444a7814c14446",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Adult Health and Social Care",
                    "university": "Blackpool and the Fylde College",
                    "location": "University Centre",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Adult Health and Social Care",
                    "university": "Blackpool and the Fylde College",
                    "location": "University Centre",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Applied Science",
                    "university": "Blackpool and the Fylde College",
                    "location": "University Centre",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Applied Science (Analytical Science)",
                    "university": "Blackpool and the Fylde College",
                    "location": "University Centre",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Applied Science (Biotechnology)",
                    "university": "Blackpool and the Fylde College",
                    "location": "University Centre",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Applied Science (Environmental Science)",
                    "university": "Blackpool and the Fylde College",
                    "location": "University Centre",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Art & Design (Graphic Design and Illustration)",
                    "university": "Blackpool and the Fylde College",
                    "location": "University Centre",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art & Design (Graphic Design and Illustration)",
                    "university": "Blackpool and the Fylde College",
                    "location": "University Centre",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Art and Design (Art Practice)",
                    "university": "Blackpool and the Fylde College",
                    "location": "University Centre",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Design (Art Practice)",
                    "university": "Blackpool and the Fylde College",
                    "location": "University Centre",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Art and Design (Fashion, Textiles and Costume)",
                    "university": "Blackpool and the Fylde College",
                    "location": "University Centre",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Art and Design (Fashion, Textiles and Costume)",
                    "university": "Blackpool and the Fylde College",
                    "location": "University Centre",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Design (Photography)",
                    "university": "Blackpool and the Fylde College",
                    "location": "University Centre",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Design (Photography)",
                    "university": "Blackpool and the Fylde College",
                    "location": "University Centre",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Automotive and Motorsport Engineering",
                    "university": "Blackpool and the Fylde College",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Automotive and Motorsport Engineering Technology",
                    "university": "Blackpool and the Fylde College",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "36"
                },
                {
                    "course_name": "Automotive and Motorsport Engineering Technology",
                    "university": "Blackpool and the Fylde College",
                    "location": "Main Site",
                    "qualification": "FdEng",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management",
                    "university": "Blackpool and the Fylde College",
                    "location": "University Centre",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Business Management",
                    "university": "Blackpool and the Fylde College",
                    "location": "University Centre",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Children, Young People and Families",
                    "university": "Blackpool and the Fylde College",
                    "location": "University Centre",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Children, Young People and Families",
                    "university": "Blackpool and the Fylde College",
                    "location": "University Centre",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computer Science and Digital Technologies",
                    "university": "Blackpool and the Fylde College",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Computer Science and Digital Technologies",
                    "university": "Blackpool and the Fylde College",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Criminology and Criminal Justice",
                    "university": "Blackpool and the Fylde College",
                    "location": "University Centre",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Criminology and Criminal Justice",
                    "university": "Blackpool and the Fylde College",
                    "location": "University Centre",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "Blackpool and the Fylde College",
                    "location": "University Centre",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Engineering (Aerospace Engineering)",
                    "university": "Blackpool and the Fylde College",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Engineering (Electrical and Electronic Engineering)",
                    "university": "Blackpool and the Fylde College",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Engineering (Industrial Engineering)",
                    "university": "Blackpool and the Fylde College",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Engineering (Mechanical Engineering)",
                    "university": "Blackpool and the Fylde College",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Engineering (Robotics and Automation Engineering)",
                    "university": "Blackpool and the Fylde College",
                    "location": "Main Site",
                    "qualification": "BEng",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "English Studies",
                    "university": "Blackpool and the Fylde College",
                    "location": "University Centre",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Studies",
                    "university": "Blackpool and the Fylde College",
                    "location": "University Centre",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Foundations of Military Leadership",
                    "university": "Blackpool and the Fylde College",
                    "location": "University Centre",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "General Engineering",
                    "university": "Blackpool and the Fylde College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Hospitality Management",
                    "university": "Blackpool and the Fylde College",
                    "location": "University Centre",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Immersive Performance Practice",
                    "university": "Blackpool and the Fylde College",
                    "location": "University Centre",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Marine Electrical and Electronics Engineering",
                    "university": "Blackpool and the Fylde College",
                    "location": "Nautical Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "60"
                },
                {
                    "course_name": "Marine Engineering",
                    "university": "Blackpool and the Fylde College",
                    "location": "Nautical Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "60"
                },
                {
                    "course_name": "Maritime Operations Management (Electro-Technical) (Top Up)",
                    "university": "Blackpool and the Fylde College",
                    "location": "Nautical Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Maritime Operations Management (Marine Engineering) (Top Up)",
                    "university": "Blackpool and the Fylde College",
                    "location": "Nautical Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Maritime Operations Management (Nautical Science) (Top Up)",
                    "university": "Blackpool and the Fylde College",
                    "location": "Nautical Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health and Resilience",
                    "university": "Blackpool and the Fylde College",
                    "location": "University Centre",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Mental Health and Resilience",
                    "university": "Blackpool and the Fylde College",
                    "location": "University Centre",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Music (Sound Design)",
                    "university": "Blackpool and the Fylde College",
                    "location": "University Centre",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Technology",
                    "university": "Blackpool and the Fylde College",
                    "location": "University Centre",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nautical Science",
                    "university": "Blackpool and the Fylde College",
                    "location": "Nautical Campus",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "60"
                },
                {
                    "course_name": "Network Engineering (Cyber Security)",
                    "university": "Blackpool and the Fylde College",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Network Engineering (Systems Administration)",
                    "university": "Blackpool and the Fylde College",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Professional Policing (Pre-Join)",
                    "university": "Blackpool and the Fylde College",
                    "location": "University Centre",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-112"
                },
                {
                    "course_name": "Software Engineering (Game Development)",
                    "university": "Blackpool and the Fylde College",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Software Engineering (Systems Development)",
                    "university": "Blackpool and the Fylde College",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sport (Community Sport and Physical Activities)",
                    "university": "Blackpool and the Fylde College",
                    "location": "University Centre",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Teaching and Learning Support",
                    "university": "Blackpool and the Fylde College",
                    "location": "University Centre",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Aeronautical Engineering",
                    "university": "Blackpool and the Fylde College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Construction Management",
                    "university": "Blackpool and the Fylde College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Blackpool and the Fylde College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "General Engineering",
                    "university": "Blackpool and the Fylde College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Manufacturing Engineering",
                    "university": "Blackpool and the Fylde College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Blackpool and the Fylde College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "66b92c85a339116d379834c4b5e4c750",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accountancy",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Accountancy with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Animation and Illustration",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Animation and Illustration with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Artificial Intelligence with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Automotive Performance Engineering (Motorsport)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Automotive Performance Engineering (Motorsport) with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Biomedical Science with foundation and placement year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Biomedical Science with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Biomedical Science with placement year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of Bolton",
                    "location": "The Growth Company, Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Business Management (Enterprise) top-up",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (Finance) top-up",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (Information Systems) top-up",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (Marketing) top-up",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (People Management) top-up",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (Retail) top-up",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (Supply Chain) top-up",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management top-up",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Certificate of Higher Education Professional and Commercial Dance",
                    "university": "University of Bolton",
                    "location": "Shockout Arts, Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Commercial Surface Design",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Commercial Surface Design with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Community Development and Youth Studies (top-up)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Community Development and Youth Work",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computer Science with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computer Science with foundation year and industrial placement",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computer Science with industrial placement year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computing",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computing (top-up)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing for England (HTQ)",
                    "university": "University of Bolton",
                    "location": "Bolton College, Greater Manchester",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computing with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computing with foundation year and industrial placement",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computing with industrial placement",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Construction and the Built Environment (Civil Engineering)",
                    "university": "University of Bolton",
                    "location": "Bolton College, Greater Manchester",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Contemporary Art Practice",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Contemporary Art Practice with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Crime and Criminal Justice",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Crime and Criminal Justice (Final Year Top-Up)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminological and Forensic Psychology",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminological and Forensic Psychology with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Cyber Security with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Cyber Security with foundation year and industrial placement",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Cyber Security with industrial placement",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Dental Technology",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Dental Technology (top-up)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Management and Marketing",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Digital Media and Content Creation",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Digital Media and Content Creation with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Early Years Childhood Studies",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Early Years Childhood Studies (top-up)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education and Learning",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Education and Learning",
                    "university": "University of Bolton",
                    "location": "The Growth Company, Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "English and Creative Writing",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Fashion (Final Year Top-up)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Production",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Games Art",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Games Art with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Games Design",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Games Design with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Games Programming",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Games Programming with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Graphic Design with Advertising",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Graphic Design with Advertising with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Health and Social Care (Assistant Practitioner (Health) Independent Route)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Social Care (top-up)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Social Care (top-up)",
                    "university": "University of Bolton",
                    "location": "The Growth Company, Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Social Care with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Health Care Studies",
                    "university": "University of Bolton",
                    "location": "The Growth Company, Manchester",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "International Hospitality Management",
                    "university": "University of Bolton",
                    "location": "Bolton College, Greater Manchester",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "LLB Law",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "LLB Law with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Mechatronics (top-up)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Biology",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Medical Biology with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Mental Health, Wellbeing and Counselling",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mental Health, Wellbeing and Counselling with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Modelmaking and Creature Effects",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Modelmaking and Creature Effects with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Motorsport Technology",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Motorsport Technology with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Musical Theatre (top-up) [Shockout, Manchester]",
                    "university": "University of Bolton",
                    "location": "Shockout Arts, Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Musical Theatre [Shockout, Manchester]",
                    "university": "University of Bolton",
                    "location": "Shockout Arts, Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Musical Theatre [Shockout, Manchester]",
                    "university": "University of Bolton",
                    "location": "Shockout Arts, Manchester",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Nursing (Adult) [Stockport NHS Foundation Trust]",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Nursing Associate",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Operating Department Practice",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Paramedic Science (Pre-registration)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Photography",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Photography with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Professional and Commercial Dance (Accelerated Route) [Shockout Arts, Manchester]",
                    "university": "University of Bolton",
                    "location": "Shockout Arts, Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Professional and Commercial Dance (top-up) [Shockout Arts, Manchester]",
                    "university": "University of Bolton",
                    "location": "Shockout Arts, Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional and Commercial Dance [Shockout Arts, Manchester]",
                    "university": "University of Bolton",
                    "location": "Shockout Arts, Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Professional and Commercial Dance [Shockout Arts, Manchester]",
                    "university": "University of Bolton",
                    "location": "Shockout Arts, Manchester",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology and Cognitive Neuroscience",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology and Cognitive Neuroscience with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Psychology with Applications in Education",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology with Applications in Education with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Psychology with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Psychology with Sport and Exercise",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology with Sport and Exercise with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Psychology, Psychotherapy and Counselling",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology, Psychotherapy and Counselling with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Software Engineering (top-up)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Software Engineering with foundation year and industrial placement",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Software Engineering with industrial placement",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Special Make-up Effects for Film and Television",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Special Make-up Effects for Film and Television with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sport Rehabilitation",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport Rehabilitation with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Street and Hip-Hop Vernacular Dance [Shockout Arts, Manchester]",
                    "university": "University of Bolton",
                    "location": "Shockout Arts, Manchester",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Street Dance Artistry and Hip-Hop Theatre (top-up) [Shockout Arts, Manchester]",
                    "university": "University of Bolton",
                    "location": "Shockout Arts, Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable and Electric Vehicle Engineering",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sustainable and Electric Vehicle Engineering with Industrial Placement Year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Visual Effects and Animation",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Accountancy",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "4.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Animation and Illustration",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "4.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "54 months",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BEng (Hon)",
                    "duration": "54 months",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "54 months",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Biomedical Science with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "78 months",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "4.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of Bolton",
                    "location": "The Growth Company, Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "54 months",
                    "study_mode": "Part-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Business Management (Enterprise) top-up",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "1.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (Finance) top-up",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "1.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (Marketing) top-up",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "1.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (People Management) top-up",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "1.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (Retail) top-up",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "1.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (Supply Chain) top-up",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "1.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management top-up",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BEng (Hon)",
                    "duration": "4.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Commercial Surface Design",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "54 months",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Community Development and Youth Studies (top-up)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Community Development and Youth Work",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "4.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "54 months",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computing",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computing (top-up)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction and the Built Environment (Civil Engineering)",
                    "university": "University of Bolton",
                    "location": "Bolton College, Greater Manchester",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Construction Management for England (Construction Design and Build Technician)",
                    "university": "University of Bolton",
                    "location": "Bolton College, Greater Manchester",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Construction Management for England (Construction Site Supervisor)",
                    "university": "University of Bolton",
                    "location": "Bolton College, Greater Manchester",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Contemporary Art Practice",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "54 months",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Crime and Criminal Justice",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "4.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Crime and Criminal Justice (Final Year Top-Up)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminological and Forensic Psychology",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "4.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Dental Technology (top-up)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media and Content Creation",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "54 months",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Early Years Childhood Studies",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "4.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Early Years Childhood Studies",
                    "university": "University of Bolton",
                    "location": "The Growth Company, Manchester",
                    "qualification": "FdA",
                    "duration": "2.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Years Childhood Studies",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "FdA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Years Childhood Studies (top-up)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education and Learning",
                    "university": "University of Bolton",
                    "location": "The Growth Company, Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "4.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BEng (Hon)",
                    "duration": "4.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Games Art",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Games Design",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "54 months",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Games Programming",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "4.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "HND",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Health and Social Care (top-up)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Hospitality Management",
                    "university": "University of Bolton",
                    "location": "Bolton College, Greater Manchester",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "LLB Law",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "LLB (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BEng (Hon)",
                    "duration": "4.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Mechatronics (top-up)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Biology",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Medical Biology with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "78 months",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Mental Health, Wellbeing and Counselling",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "54 months",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "56 months",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Photography",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "4.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "4.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology and Cognitive Neuroscience",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "54 months",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology and Cognitive Neuroscience with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Psychology with Applications in Education",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "4.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology with Applications in Education with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Psychology with Sport and Exercise",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "54 months",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology with Sport and Exercise with foundation year",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Psychology, Psychotherapy and Counselling",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "4.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sport Rehabilitation",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "4.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sustainable and Electric Vehicle Engineering",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "BEng (Hon)",
                    "duration": "54 months",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "ACCA Strategic Professional Level",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "Professional Stage",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accountancy and Financial Management",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Sport and Exercise Psychology",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MRes",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics and Technologies",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Certificate in Education 14+ (Specialism)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "CertEd",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Certificate in Education 14+ (Specialism) [The Growth Company]",
                    "university": "University of Bolton",
                    "location": "The Growth Company, Manchester",
                    "qualification": "CertEd",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Certificate in Education 14+ English and English for Speakers of Other Languages (ESOL)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "CertEd",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Certificate in Education 14+ Mathematics (Numeracy)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "CertEd",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Certificate in Education 14+ Science and Technology",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "CertEd",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Certificate in Education 14+ Special Educational Needs and Disabilities (SEND)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "CertEd",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Certificate in Education 14+ Sport and Outdoor Pursuits",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "CertEd",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cloud and Network Security",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Community Development and Youth Studies",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MA (PG)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Community Nurse Specialist Practice (General Practice Nursing) with integrated V300 (Non-Medical Prescribing)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "PgDip",
                    "duration": "53 weeks",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Community Nurse Specialist Practitioner (District Nursing)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "PgDip",
                    "duration": "53 weeks",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Community Nurse Specialist Practitioner (General Practice Nursing)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "PgDip",
                    "duration": "53 weeks",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MRes",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling and Positive Psychology",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dance (Specialist Title) [Shockout Arts, Manchester]",
                    "university": "University of Bolton",
                    "location": "Shockout Arts, Manchester",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Analytics and Technologies",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Dental Technology",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MA (PG)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Leadership",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MA",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Leadership",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MRes",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electric Vehicle Technology",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Management",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Entrepreneurship and Innovation",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Management",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MRes",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Development",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Analytics and Technologies",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Inclusive Education",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MA (PG)",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Law",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Islamic Economics and Finance",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MRes",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership and Strategy",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MRes",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Logistics and Supply Chain Management",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MRes",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Logistics and Supply Chain Management",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA (Specialist Title) via Creative Practice",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA (Global Healthcare Management)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MBA",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA International (Graduate Entry)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MBA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA Islamic Finance",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MBA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA Master of Business Administration",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MBA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA Master of Business Administration (top-up)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MBA",
                    "duration": "32 weeks",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Motorsport Engineering",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Adult) (Pre-registration)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Adult) Post-registration",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE M Post Graduate Certificate in Education 14+ (Specialism) (Postgraduate Level)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "PGCE",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE M Post Graduate Certificate in Education 14+ (Specialism) (Postgraduate Level) [The Growth Company]",
                    "university": "University of Bolton",
                    "location": "The Growth Company, Manchester",
                    "qualification": "PGCE",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE M Postgraduate Certificate in Education 14+ Arts and Creative Practice (Postgraduate Level)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "PGCE",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE M Postgraduate Certificate in Education 14+ Dance (Postgraduate Level) [Shockout Arts, Manchester]",
                    "university": "University of Bolton",
                    "location": "Shockout Arts, Manchester",
                    "qualification": "PGCE",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE M Postgraduate Certificate in Education 14+ English and English for Speakers of Other Languages (ESOL) (Postgraduate Level)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "PGCE",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE M Postgraduate Certificate in Education 14+ Mathematics (Numeracy) (Postgraduate Level)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "PGCE",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE M Postgraduate Certificate in Education 14+ Performance and Creative Arts (Postgraduate Level) [Shockout, Manchester]",
                    "university": "University of Bolton",
                    "location": "Shockout Arts, Manchester",
                    "qualification": "PGCE",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE M Postgraduate Certificate in Education 14+ Science and Technology (Postgraduate Level)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "PGCE",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE M Postgraduate Certificate in Education 14+ Special Educational Needs and Disabilities (SEND) (Postgraduate Level)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "PGCE",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE M Postgraduate Certificate in Education 14+ Sport and Outdoor Pursuits (Postgraduate Level)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "PGCE",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PgDip Community Nurse Specialist Practitioner (District Nursing) with (V300) integrated non-medical prescribing",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "PgDip",
                    "duration": "53 weeks",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy (Pre-registration)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc (PG)",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Positive Psychology",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional and Commercial Dance",
                    "university": "University of Bolton",
                    "location": "Shockout Arts, Manchester",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Development in Education",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MEd",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Conversion)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "14 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Care, Health and Wellbeing",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Neuroscience",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MA (PG)",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Public Health Nursing (Health Visiting)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "PgDip",
                    "duration": "53 weeks",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Public Health Nursing (School Nursing)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "PgDip",
                    "duration": "53 weeks",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strength and Conditioning",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Systems Engineering (Electronic) and Engineering Management",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Systems Engineering (Electronic) and Engineering Management (Double Award)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Systems Engineering (Mechanical) and Engineering Management",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Systems Engineering (Mechanical) and Engineering Management (Double Award)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Systems Engineering (Mechatronics) and Engineering Management",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Systems Engineering (Mechatronics) and Engineering Management (Double Award)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching and Learning in Higher and Professional Education (Advanced Standing)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MA (PG)",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "ACCA Strategic Professional Level",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "Professional Stage",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accountancy and Financial Management",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice (Acute Medicine)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice (Geriatric Medicine)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice (Mental Health)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice (Oncology)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice (Primary Care)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice (Respiratory)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Sport and Exercise Psychology",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "30 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics and Technologies",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Certificate in Education 14+ (Specialism)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "CertEd",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Certificate in Education 14+ (Specialism) [The Growth Company]",
                    "university": "University of Bolton",
                    "location": "The Growth Company, Manchester",
                    "qualification": "CertEd",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Certificate in Education 14+ Special Educational Needs and Disabilities (SEND)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "CertEd",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Practice",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "48 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cloud and Network Security",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Behavioural Psychotherapies",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Community Development and Youth Studies",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MA (PG)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Community Health Care Nursing (top-up)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc (PG)",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Community Public Health Nursing Top-up",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc (PG)",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dance (Specialist Title) [Shockout Arts, Manchester]",
                    "university": "University of Bolton",
                    "location": "Shockout Arts, Manchester",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Analytics and Technologies",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Dental Technology",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MA (PG)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Leadership",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electric Vehicle Technology",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Management",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Analytics and Technologies",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Inclusive Education",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MA (PG)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Law",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "LLM",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "LLM",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Logistics and Supply Chain Management",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA (Specialist Title) via Creative Practice",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA (Executive)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MBA (Executive)",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA Islamic Finance (Executive)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MBA (Executive)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Motorsport Engineering",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Adult) Post-registration",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE M Post Graduate Certificate in Education 14+ (Specialism) (Postgraduate Level)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "PGCE",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE M Post Graduate Certificate in Education 14+ (Specialism) (Postgraduate Level) [The Growth Company]",
                    "university": "University of Bolton",
                    "location": "The Growth Company, Manchester",
                    "qualification": "PGCE",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE M Postgraduate Certificate in Education 14+ Dance (Postgraduate Level) [Shockout Arts, Manchester]",
                    "university": "University of Bolton",
                    "location": "Shockout Arts, Manchester",
                    "qualification": "PGCE",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE M Postgraduate Certificate in Education 14+ Performance and Creative Arts (Postgraduate Level) [Shockout, Manchester]",
                    "university": "University of Bolton",
                    "location": "Shockout Arts, Manchester",
                    "qualification": "PGCE",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE M Postgraduate Certificate in Education 14+ Science and Technology (Postgraduate Level)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "PGCE",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE M Postgraduate Certificate in Education 14+ Special Educational Needs and Disabilities (SEND) (Postgraduate Level)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "PGCE",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Positive Psychology",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "30 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Development in Education",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MEd",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "30 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Conversion)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "28 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Senior Leader: Health and Social Care",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "PgDip",
                    "duration": "30 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Care, Health and Wellbeing",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Neuroscience",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "30 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strength and Conditioning",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Systems Engineering (Electronic) and Engineering Management",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Systems Engineering (Mechanical) and Engineering Management",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Systems Engineering (Mechatronics) and Engineering Management",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MSc",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching and Learning in Higher and Professional Education (Advanced Standing)",
                    "university": "University of Bolton",
                    "location": "University of Bolton Main Site, Greater Manchester",
                    "qualification": "MA (PG)",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "5a31c4b28f16d7c4cb33249edeb1fa91",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Filmmaking: Post Production",
                    "university": "Boomsatsuma",
                    "location": "Bristol - Tobacco Factory",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Filmmaking: Production",
                    "university": "Boomsatsuma",
                    "location": "Bristol - Tobacco Factory",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Game Design and Production",
                    "university": "Boomsatsuma",
                    "location": "Bristol - Tobacco Factory",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Photography and Print",
                    "university": "Boomsatsuma",
                    "location": "Bristol - Tobacco Factory",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "6135198481df03cd9a5d868ffeb0570a",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Animal Management (Animal Behaviour and Welfare) HNC Level 4",
                    "university": "Boston College",
                    "location": "Boston Campus (Rochford)",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Animal Management (Animal Behaviour and Welfare) HND Top-Up Level 5",
                    "university": "Boston College",
                    "location": "Boston Campus (Rochford)",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management BA (Hons) Top Up Level 6",
                    "university": "Boston College",
                    "location": "Boston Campus (Rochford)",
                    "qualification": "BA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Community Coaching",
                    "university": "Boston College",
                    "location": "Boston Campus (Rochford)",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Computing",
                    "university": "Boston College",
                    "location": "Boston Campus (Rochford)",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Creative Arts BA (Hons) Top-Up Level 6",
                    "university": "Boston College",
                    "location": "Boston Campus (Rochford)",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies BA (Hons) Top-Up Level 6",
                    "university": "Boston College",
                    "location": "Boston Campus (Rochford)",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion and Textiles HND Level 5",
                    "university": "Boston College",
                    "location": "Boston Campus (Rochford)",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Fine Art HND Level 5",
                    "university": "Boston College",
                    "location": "Boston Campus (Rochford)",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Graphic and Digital Design HND Level 5",
                    "university": "Boston College",
                    "location": "Boston Campus (Rochford)",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "International Travel and Tourism Management",
                    "university": "Boston College",
                    "location": "Boston Campus (Rochford)",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Leadership and Management",
                    "university": "Boston College",
                    "location": "Boston Campus (Rochford)",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Photography HND Level 5",
                    "university": "Boston College",
                    "location": "Boston Campus (Rochford)",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Public Services",
                    "university": "Boston College",
                    "location": "Boston Campus (Rochford)",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Sport, Fitness and Wellbeing BSc (Hons) Top-Up Level 6",
                    "university": "Boston College",
                    "location": "Boston College Peter Paine Performance Centre",
                    "qualification": "BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "3146e8ba3147130f6a0727912e6c629e",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "2D Digital Animation and Design Production",
                    "university": "Bournemouth and Poole College",
                    "location": "Poole Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "3D Animation and Visualisation for Film and Games",
                    "university": "Bournemouth and Poole College",
                    "location": "Poole Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "3D Animation and Visualisation for Film and Games (Top Up)",
                    "university": "Bournemouth and Poole College",
                    "location": "Poole Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Sciences",
                    "university": "Bournemouth and Poole College",
                    "location": "Poole Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Cyber Security and Cloud Networking",
                    "university": "Bournemouth and Poole College",
                    "location": "Bournemouth Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Esports Production Broadcasting and Events",
                    "university": "Bournemouth and Poole College",
                    "location": "Poole Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Music and Sound Production",
                    "university": "Bournemouth and Poole College",
                    "location": "Poole Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Performing Arts",
                    "university": "Bournemouth and Poole College",
                    "location": "Poole Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Performing Arts (Top Up)",
                    "university": "Bournemouth and Poole College",
                    "location": "Poole Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "dcd375a55d029e5bd145d91c141a5b9a",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Accounting & Finance",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Accounting & Finance (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Accounting (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Adult Nursing",
                    "university": "Bournemouth University",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Adult Nursing (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Adult Nursing Advanced Standing",
                    "university": "Bournemouth University",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Adult Nursing MSc",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Archaeological, Anthropological & Forensic Sciences",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Archaeology",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Archaeology and Anthropology",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "BA Product Design (with Foundation Year option)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Biological Sciences (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Biomedical Science (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "BSc Product Design (with Foundation Year option)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Business & Management",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Business & Management (Economics)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Business & Management (Economics) (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Business & Management (Finance)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Business & Management (Finance) (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Business & Management (Top-up)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Business & Management (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Business Computing with Analytics",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Business Computing with Analytics (with Foundation Year option)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Children's and Young People's Nursing",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Children's and Young People's Nursing Advanced Standing",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Communication & Media",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Communication & Media (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Computer Animation & Visual Effects",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Computer Animation & Visual Effects (with Foundation Year option)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Computer Science (with Foundation Year option)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Computing",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Computing (with Foundation Year option)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Criminology",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Criminology with Law",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Criminology with Law (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Criminology with Psychology",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Cyber Security Management",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Cyber Security Management (with Foundation Year option)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Cyber Security with Digital Forensics",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Cyber Security with Digital Forensics (with Foundation Year option)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Data Science and Artificial Intelligence",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Data Science and Artificial Intelligence (with Foundation Year option)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Design Engineering",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Design Engineering (with Design Foundation Year option)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Design Engineering (with Engineering Foundation Year option)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "E-Sports Digital Technologies",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Ecology and Wildlife Conservation",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Ecology and Wildlife Conservation (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Economics",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Economics (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "English",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Environmental Science (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Events Management",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Events Management (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Film",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Film & TV Production",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Film & TV Production (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Film (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Film Production and Cinematography (Wiltshire College and University Centre, Salisbury Campus)",
                    "university": "Bournemouth University",
                    "location": "Wiltshire College - Salisbury",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Finance",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Finance (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Forensic Biology",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Forensic Investigation",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Forensic Investigation (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Forensic Science (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Games Design",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Games Design (with Foundation Year option)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Games Programming",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Geography",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Geography (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "History",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "International Finance (Top Up)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "International Hospitality & Business Management",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "International Hospitality & Business Management (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "International Hospitality & Tourism Management (Top-up)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "International Tourism & Business Management",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "International Tourism & Business Management (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "International Tourism & Hospitality Management",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "International Tourism & Hospitality Management (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Law",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Law (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Law with Legal Practice (SQE)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Law with Legal Practice (SQE) (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Law, Media & Creative Industries",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Law, Media & Creative Industries (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Marine Ecology Conservation (Top-Up)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Marketing",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Marketing (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Marketing Communications for Social Change",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Marketing Communications for Social Change (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Marketing Communications with Advertising",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Marketing Communications with Advertising (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Marketing Communications with Digital Strategy",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Marketing Communications with Digital Strategy (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Marketing Communications with Public Relations",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Marketing Communications with Public Relations (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Mechanical Engineering (Integrated Master's - with Foundation Year option)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Mechanical Engineering (Integrated Master's)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Mechanical Engineering (with Foundation Year option)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Mechatronics and Robotics",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Mechatronics and Robotics (Integrated Master's)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Media Production",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Media Production (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Medical Science",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Medical Science (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Mental Health Nursing",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Mental Health Nursing (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Mental Health Nursing Advanced Standing",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Mental Health Nursing MSc",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery",
                    "university": "Bournemouth University",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Midwifery (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Multimedia Journalism",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Multimedia Journalism (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Multimedia Sports Journalism",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Multimedia Sports Journalism (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Music & Sound Production",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Networks and Cyber Security",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Networks and Cyber Security (with Foundation Year option)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Nutrition",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Nutrition (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Occupational Therapy (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Operating Department Practice",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Operating Department Practice (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Paramedic Science",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Photography",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Physician Associate Studies MSc",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physician Associate Studies PGDip",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Product Design",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Product Design",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Psychology",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Psychology (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Psychology in Education",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Psychology in Education (with Foundation Year option)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Psychology with Counselling",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Psychology with Counselling (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Psychology with Forensic Investigation",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Psychology with Forensic Investigation (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Social Work",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Social Work",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Sociology",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Sociology & Criminology",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Sociology & Social Anthropology",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Software Engineering (with Foundation Year option)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Sport and Exercise Science (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Sport Coaching",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Sport Coaching (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Sport Management",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Sport Management (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Sports Therapy",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Tourism Management",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Tourism Management (with Foundation Year)",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "48-72"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "3D Computer Animation",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence for Media",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioarchaeology",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biodiversity Conservation",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cinematography for Film and Television",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical and Developmental Neuropsychology",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Animation and Visual Effects",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security and Human Factors",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Artificial Intelligence",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Effects",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Health",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Directing Film and Television",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Disaster Management",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Project Management",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "Bournemouth University",
                    "location": "Lansdowne Campus",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Foundations of Clinical Psychology",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Centred Artificial Intelligence",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MSc (PG)",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Centred Artificial Intelligence for Games Development",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Technology",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Innovation Management and Entrepreneurship",
                    "university": "Bournemouth University",
                    "location": "Lansdowne Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intellectual Property",
                    "university": "Bournemouth University",
                    "location": "No Locations",
                    "qualification": "LLM",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Accounting & Finance",
                    "university": "Bournemouth University",
                    "location": "Lansdowne Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law",
                    "university": "Bournemouth University",
                    "location": "Lansdowne Campus",
                    "qualification": "LLM",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Finance & Economics",
                    "university": "Bournemouth University",
                    "location": "Lansdowne Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Hospitality and Tourism Management",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management",
                    "university": "Bournemouth University",
                    "location": "Lansdowne Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Political Communication",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tax Law",
                    "university": "Bournemouth University",
                    "location": "Lansdowne Campus",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Investigative Forensic Psychology",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "LLM in Legal Practice",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA Criminology & Criminal Justice",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Business Analytics",
                    "university": "Bournemouth University",
                    "location": "Lansdowne Campus",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Human Resources",
                    "university": "Bournemouth University",
                    "location": "Lansdowne Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing Communications",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing Management",
                    "university": "Bournemouth University",
                    "location": "Lansdowne Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing Management (Digital)",
                    "university": "Bournemouth University",
                    "location": "Lansdowne Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration",
                    "university": "Bournemouth University",
                    "location": "Lansdowne Campus",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communication",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MA",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Imaging with Management",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MPhil - Bournemouth University Business School",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "MPhil",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MPhil - Faculty of Health & Social Sciences",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "MPhil",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MPhil - Faculty of Media & Communication",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "MPhil",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MRes - Bournemouth University Business School",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MRes - Faculty of Media & Communication",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MRes - Health & Social Sciences",
                    "university": "Bournemouth University",
                    "location": "Multiple Locations",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MRes - Science & Technology",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Marketing & User Experience",
                    "university": "Bournemouth University",
                    "location": "Lansdowne Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Multimedia Journalism",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MA",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nutrition and Behaviour",
                    "university": "Bournemouth University",
                    "location": "Lansdowne Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Organisational Project Management",
                    "university": "Bournemouth University",
                    "location": "Lansdowne Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PhD - Bournemouth University Business School",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PhD - Faculty of Health & Social Sciences",
                    "university": "Bournemouth University",
                    "location": "Lansdowne Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PhD \u2013 Faculty of Media & Communication",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PhD \u2013 Faculty of Science & Technology",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Psychology",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MA",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Post Production Editing",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Producing Film and Television",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Production Management",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "Bournemouth University",
                    "location": "Lansdowne Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health with Professional Registration as a Specialist Community Public Health Nurse (Health Visiting)",
                    "university": "Bournemouth University",
                    "location": "Lansdowne Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public International Law",
                    "university": "Bournemouth University",
                    "location": "Lansdowne Campus",
                    "qualification": "LLM",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scriptwriting",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "Bournemouth University",
                    "location": "Lansdowne Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sound Design for Screen",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Management",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Tourism Marketing Management",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Mental Health Practice",
                    "university": "Bournemouth University",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioarchaeology",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biodiversity Conservation",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical and Developmental Neuropsychology",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Corporate Governance",
                    "university": "Bournemouth University",
                    "location": "GTA University Centre",
                    "qualification": "MSc",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing and Publishing",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security and Human Factors",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Artificial Intelligence",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Health",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Doctor of Education Creative & Media",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "EdD",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Project Management",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Foundations of Clinical Psychology",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Centred Artificial Intelligence",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Centred Artificial Intelligence for Games Development",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Technology",
                    "university": "Bournemouth University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intellectual Property",
                    "university": "Bournemouth University",
                    "location": "Multiple Locations",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law",
                    "university": "Bournemouth University",
                    "location": "Lansdowne Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Hospitality and Tourism Management",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Political Communication",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tax Law",
                    "university": "Bournemouth University",
                    "location": "Lansdowne Campus",
                    "qualification": "LLM",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Investigative Forensic Psychology",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "LLM in Legal Practice",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA Criminology & Criminal Justice",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing Communications",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Imaging with Management",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MPhil - Bournemouth University Business School",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "MPhil",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MPhil - Faculty of Health & Social Sciences",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "MPhil",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MPhil - Faculty of Media & Communication",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "MPhil",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MRes - Bournemouth University Business School",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MRes - Faculty of Media & Communication",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MRes - Health & Social Sciences",
                    "university": "Bournemouth University",
                    "location": "Multiple Locations",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MRes - Science & Technology",
                    "university": "Bournemouth University",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nutrition and Behaviour",
                    "university": "Bournemouth University",
                    "location": "Lansdowne Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PhD - Bournemouth University Business School",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PhD - Faculty of Health & Social Sciences",
                    "university": "Bournemouth University",
                    "location": "Lansdowne Campus",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PhD \u2013 Faculty of Media & Communication",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PhD \u2013 Faculty of Science & Technology",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Psychology",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "Bournemouth University",
                    "location": "Lansdowne Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public International Law",
                    "university": "Bournemouth University",
                    "location": "Lansdowne Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Management",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Tourism Marketing Management",
                    "university": "Bournemouth University",
                    "location": "Talbot Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "bdb2c51442f5c6dd8be673424d86aa68",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "af081b7131d6492417fb52992caf08ec",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Art and Design*",
                    "university": "Bradford College",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Cloud Computing",
                    "university": "Bradford College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computing (Cyber Security)",
                    "university": "Bradford College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing (Cyber Security)",
                    "university": "Bradford College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing (Data Analytics)",
                    "university": "Bradford College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Computing (Data Analytics)",
                    "university": "Bradford College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing (HTQ)",
                    "university": "Bradford College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing for England (HTQ) (HND)",
                    "university": "Bradford College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies",
                    "university": "Bradford College",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Engineering (Mechanical Engineering)",
                    "university": "Bradford College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Engineering (Mechanical Engineering)",
                    "university": "Bradford College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Fashion*",
                    "university": "Bradford College",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Film and Media Production for the Creative Industries",
                    "university": "Bradford College",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Film and Media Production for the Creative Industries (Top-up)",
                    "university": "Bradford College",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Make Up With Special Effects for the Creative Industries",
                    "university": "Bradford College",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Music for the Creative Industries",
                    "university": "Bradford College",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Music for the Creative Industries",
                    "university": "Bradford College",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ophthalmic Dispensing*",
                    "university": "Bradford College",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Performance (Top-up)*",
                    "university": "Bradford College",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Performing Arts",
                    "university": "Bradford College",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Social Work",
                    "university": "Bradford College",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Art and Design*",
                    "university": "Bradford College",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Cloud Computing",
                    "university": "Bradford College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computing (Cyber Security)",
                    "university": "Bradford College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing (HTQ)",
                    "university": "Bradford College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing for England (HTQ) (HND)",
                    "university": "Bradford College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Mechanical Engineering)",
                    "university": "Bradford College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Fashion*",
                    "university": "Bradford College",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Film and Media Production for the Creative Industries (Top-up)",
                    "university": "Bradford College",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Make Up With Special Effects for the Creative Industries",
                    "university": "Bradford College",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Music for the Creative Industries",
                    "university": "Bradford College",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Music for the Creative Industries",
                    "university": "Bradford College",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ophthalmic Dispensing*",
                    "university": "Bradford College",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance (Top-up)*",
                    "university": "Bradford College",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Performing Arts",
                    "university": "Bradford College",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "80"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "80331f97b102253b81adeca33ede8bfc",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "25e2e8fae71c3a285cf7f4c75be5a94b",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "c1f1462ba5452e27e94c0b2dd5beacbc",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Medicine",
                    "university": "Brighton and Sussex Medical School",
                    "location": "Medical Teaching Building",
                    "qualification": "BMBS",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Cardiology",
                    "university": "Brighton and Sussex Medical School",
                    "location": "Brighton and Sussex Med Sch",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dementia Studies",
                    "university": "Brighton and Sussex Medical School",
                    "location": "Brighton and Sussex Med Sch",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health",
                    "university": "Brighton and Sussex Medical School",
                    "location": "Brighton and Sussex Med Sch",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Healthcare Leadership and Commissioning",
                    "university": "Brighton and Sussex Medical School",
                    "location": "Brighton and Sussex Med Sch",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Internal Medicine",
                    "university": "Brighton and Sussex Medical School",
                    "location": "Brighton and Sussex Med Sch",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Research",
                    "university": "Brighton and Sussex Medical School",
                    "location": "Brighton and Sussex Med Sch",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paediatrics and Child Health",
                    "university": "Brighton and Sussex Medical School",
                    "location": "Brighton and Sussex Med Sch",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physician Associate Studies",
                    "university": "Brighton and Sussex Medical School",
                    "location": "Brighton and Sussex Med Sch",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "Brighton and Sussex Medical School",
                    "location": "Brighton and Sussex Med Sch",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Cardiology",
                    "university": "Brighton and Sussex Medical School",
                    "location": "Brighton and Sussex Med Sch",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Education",
                    "university": "Brighton and Sussex Medical School",
                    "location": "Multiple Locations",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Professional Studies",
                    "university": "Brighton and Sussex Medical School",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dementia Studies",
                    "university": "Brighton and Sussex Medical School",
                    "location": "Brighton and Sussex Med Sch",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dental Implant Reconstructive Surgery",
                    "university": "Brighton and Sussex Medical School",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diabetes in Primary Care",
                    "university": "Brighton and Sussex Medical School",
                    "location": "Brighton and Sussex Med Sch",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health",
                    "university": "Brighton and Sussex Medical School",
                    "location": "Brighton and Sussex Med Sch",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Healthcare Leadership and Commissioning",
                    "university": "Brighton and Sussex Medical School",
                    "location": "Brighton and Sussex Med Sch",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Internal Medicine",
                    "university": "Brighton and Sussex Medical School",
                    "location": "Brighton and Sussex Med Sch",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Education",
                    "university": "Brighton and Sussex Medical School",
                    "location": "Brighton and Sussex Med Sch",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Research",
                    "university": "Brighton and Sussex Medical School",
                    "location": "Brighton and Sussex Med Sch",
                    "qualification": "MRes",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nuclear Medicine",
                    "university": "Brighton and Sussex Medical School",
                    "location": "Brighton and Sussex Med Sch",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paediatrics and Child Health",
                    "university": "Brighton and Sussex Medical School",
                    "location": "Brighton and Sussex Med Sch",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychiatry",
                    "university": "Brighton and Sussex Medical School",
                    "location": "Brighton and Sussex Med Sch",
                    "qualification": "PgDip",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "Brighton and Sussex Medical School",
                    "location": "Brighton and Sussex Med Sch",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Simulation in Clinical Practice",
                    "university": "Brighton and Sussex Medical School",
                    "location": "Brighton and Sussex Med Sch",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "d17cd50f2afa8698d7ddf4081d4b9b27",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Computing",
                    "university": "City of Bristol College",
                    "location": "College Green",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing",
                    "university": "City of Bristol College",
                    "location": "College Green",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Creative Media Production (Game Design Animation and Vfx)",
                    "university": "City of Bristol College",
                    "location": "College Green",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Creative Media Production (Game Design, Animation and Vfx)",
                    "university": "City of Bristol College",
                    "location": "College Green",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Educational Support",
                    "university": "City of Bristol College",
                    "location": "Ashley Down Centre",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "City of Bristol College",
                    "location": "College Green",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "City of Bristol College",
                    "location": "College Green",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Inclusive Education",
                    "university": "City of Bristol College",
                    "location": "Ashley Down Centre",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Inclusive Practice in Education and Youth Work",
                    "university": "City of Bristol College",
                    "location": "Ashley Down Centre",
                    "qualification": "Cert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Photography",
                    "university": "City of Bristol College",
                    "location": "College Green",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Photography",
                    "university": "City of Bristol College",
                    "location": "College Green",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Youth and Community Work",
                    "university": "City of Bristol College",
                    "location": "Ashley Down Centre",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Construction Management",
                    "university": "City of Bristol College",
                    "location": "No Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "24-48"
                },
                {
                    "course_name": "Engineering",
                    "university": "City of Bristol College",
                    "location": "No Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "24-48"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": [
                {
                    "course_name": "Leadership and Management",
                    "university": "City of Bristol College",
                    "location": "Ashley Down Centre",
                    "qualification": "Extended Diploma",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "f0ac0b208f5299bde0f36ac15263fbb7",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "09830cdc2650df2d0a316f672edc4d3f",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-159"
                },
                {
                    "course_name": "Accounting and Finance with Study Abroad",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-159"
                },
                {
                    "course_name": "Accounting and Management",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-159"
                },
                {
                    "course_name": "Accounting and Management with Study Abroad",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting with Innovation",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "Master of Science (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-159"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-165"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-165"
                },
                {
                    "course_name": "Ancient History",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology with Innovation",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MArts (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Anatomy",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and Anthropology",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-159"
                },
                {
                    "course_name": "Biochemistry with Medical Biochemistry",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry with Medical Biochemistry",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry with Molecular Biology & Biotechnology",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry with Molecular Biology and Biotechnology",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-159"
                },
                {
                    "course_name": "Biology",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "University of Bristol",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-159"
                },
                {
                    "course_name": "Business and Management",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management with Innovation",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management with Study Abroad",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management with Study Abroad in a Modern Language",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer Biology and Immunology",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer Biology and Immunology",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-153"
                },
                {
                    "course_name": "Cellular and Molecular Medicine",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cellular and Molecular Medicine",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-153"
                },
                {
                    "course_name": "Chemical Physics",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Physics",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Physics with Industrial Experience",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Computing",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Computing",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Industrial Experience",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Study Abroad",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Study Abroad in a Modern Language",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Childhood Studies",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Childhood Studies with Quantitative Research Methods",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Childhood Studies with Study Abroad",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literatures and Cultures",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-153"
                },
                {
                    "course_name": "Comparative Literatures and Cultures and French",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-147"
                },
                {
                    "course_name": "Comparative Literatures and Cultures and German",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-147"
                },
                {
                    "course_name": "Comparative Literatures and Cultures and Italian",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-147"
                },
                {
                    "course_name": "Comparative Literatures and Cultures and Portuguese",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-147"
                },
                {
                    "course_name": "Comparative Literatures and Cultures and Russian",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-147"
                },
                {
                    "course_name": "Comparative Literatures and Cultures and Spanish",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-147"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Artificial Intelligence",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Artificial Intelligence",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Innovation",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology with Quantitative Research Methods",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology with Study Abroad",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science with a Year in Industry",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112-165"
                },
                {
                    "course_name": "Dental Therapy",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "Bachelor of Dental Therapy (BDT)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-147"
                },
                {
                    "course_name": "Dentistry - BDS entry",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BDS",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Engineering",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Engineering",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-165"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Accounting",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Accounting with Study Abroad",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Econometrics",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Econometrics with Study Abroad",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance with Study Abroad",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Management",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Management with Study Abroad",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Mathematics",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Politics",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Politics with Study Abroad",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Innovation",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Study Abroad",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Study Abroad in a Modern Language",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies with Study Abroad",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-147"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering with Innovation",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Design with Study in Industry",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Design with Study in Industry",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Mathematics",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Mathematics",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Mathematics with Study Abroad",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Classical Studies",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and French",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and German",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and History",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Italian",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Philosophy",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Portuguese",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Russian",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Spanish",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English with Study Abroad",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Geoscience",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Geoscience",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Geoscience with Study Abroad",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and English",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and French",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and German",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Italian",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Portuguese",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Spanish",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-153"
                },
                {
                    "course_name": "Film and Television with Innovation",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MArts (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-153"
                },
                {
                    "course_name": "Finance",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance with Innovation",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-159"
                },
                {
                    "course_name": "Gateway to Dentistry",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BDS",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-129"
                },
                {
                    "course_name": "Gateway to Medicine",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MB ChB",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gateway to Veterinary Science",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BVSc",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-159"
                },
                {
                    "course_name": "Geography with Innovation",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-159"
                },
                {
                    "course_name": "Geography with Study Abroad",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "104-159"
                },
                {
                    "course_name": "Geography with Study Abroad in a Modern Language",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-159"
                },
                {
                    "course_name": "Geology",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-147"
                },
                {
                    "course_name": "Geology",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-147"
                },
                {
                    "course_name": "Geology with Study Abroad",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-147"
                },
                {
                    "course_name": "History",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-159"
                },
                {
                    "course_name": "History and French",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-159"
                },
                {
                    "course_name": "History and German",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-159"
                },
                {
                    "course_name": "History and Italian",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Portuguese",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Russian",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Spanish",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-147"
                },
                {
                    "course_name": "History of Art and French",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-147"
                },
                {
                    "course_name": "History of Art and German",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-147"
                },
                {
                    "course_name": "History of Art and Italian",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-147"
                },
                {
                    "course_name": "History of Art and Portuguese",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-147"
                },
                {
                    "course_name": "History of Art and Russian",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-147"
                },
                {
                    "course_name": "History of Art and Spanish",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History with Innovation",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MArts (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-159"
                },
                {
                    "course_name": "History with Study Abroad",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management and French",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management and German",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management and Spanish",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-159"
                },
                {
                    "course_name": "International Business Management with Study Abroad",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management with Study Abroad in a Modern Language",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Social and Public Policy",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Social and Public Policy with Study Abroad",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and French",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and German",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Spanish",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Liberal Arts",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Liberal Arts With Study Abroad",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MLibArts",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing with Study Abroad",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing with Study Abroad in a Modern Language",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Computer Science",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Computer Science",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Philosophy",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Philosophy",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Physics",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Physics",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Statistics",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Statistics",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Statistics for Finance",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Study Abroad",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Study Abroad in a Modern Language",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Study Abroad in a Modern Language",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical and Electrical Engineering",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical and Electrical Engineering",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Microbiology",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Microbiology",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-153"
                },
                {
                    "course_name": "Medicine - MBChB Standard entry",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MB ChB",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and French",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and German",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Italian",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Portuguese",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Russian",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Spanish",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music with Innovation",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MArts (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Palaeontology and Evolution",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Palaeontology and Evolution",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology with Study in Industry",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Economics",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Economics with Study Abroad",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and French",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and German",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Italian",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Politics",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Portuguese",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Russian",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Spanish",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Theology",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics and Philosophy",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics and Philosophy",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Computing",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Computing",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "Master in Science (with Honours) \u2013 MSci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Computing with Industrial Experience",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Industrial Experience",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Innovation",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with International Experience",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Study Abroad in a Modern Language",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiological Science",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiological Science",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Plant Sciences",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Plant Sciences",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and French",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and German",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations with Study Abroad",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Italian",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Portuguese",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Russian",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Sociology",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Spanish",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics with Quantitative Research Methods",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics with Quantitative Research Methods",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics with Quantitative Research Methods with Study Abroad",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Neuroscience",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology in Education",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology in Education with Study Abroad",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-147"
                },
                {
                    "course_name": "Psychology with Innovation",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religion and Theology",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religion and Theology with Study Abroad",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MArts (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy and Politics",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy and Sociology",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy with Criminology",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy with Quantitative Research Methods",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy with Study Abroad",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Philosophy",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology with Quantitative Research Methods",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology with Quantitative Research Methods",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology with Quantitative Research Methods with Study Abroad",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology with Study Abroad",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and English",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and Film",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and French",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and German",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and Italian",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and Performance Studies",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and Portuguese",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and Spanish",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre with Innovation",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MArts (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Nursing and Companion Animal Behaviour",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Science",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BVSc",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Science: Accelerated Graduate Entry",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BVSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Virology and Immunology",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-153"
                },
                {
                    "course_name": "Virology and Immunology",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-159"
                },
                {
                    "course_name": "Zoology",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Ancient History",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Childhood Studies",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Classical Studies",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Philosophy",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 to 7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-147"
                },
                {
                    "course_name": "History of Art and French",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 to 7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-147"
                },
                {
                    "course_name": "History of Art and German",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 to 7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-147"
                },
                {
                    "course_name": "History of Art and Italian",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 to 7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-147"
                },
                {
                    "course_name": "History of Art and Portuguese",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 to 7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-147"
                },
                {
                    "course_name": "History of Art and Russian",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 to 7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-147"
                },
                {
                    "course_name": "History of Art and Spanish",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 to 7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 to 7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and French",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 to 7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and German",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 to 7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Italian",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 to 7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and French",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 to 7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and German",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 to 7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Italian",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 to 7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Portuguese",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 to 7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Russian",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 to 7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Spanish",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 to 7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Theology",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and French",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 to 7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and German",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 to 7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Italian",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 to 7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Portuguese",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 to 7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Russian",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 to 7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Spanish",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 to 7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy with Criminology",
                    "university": "University of Bristol",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Digital Media Innovation",
                    "university": "University of Bristol",
                    "location": "Clifton Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health Policy",
                    "university": "University of Bristol",
                    "location": "Clifton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Internet of Things with AI",
                    "university": "University of Bristol",
                    "location": "Clifton Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Global Health Policy",
                    "university": "University of Bristol",
                    "location": "Clifton Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "1947afa2c08caa9389632a3ff4a386c4",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "5acbb807c4fe2f0370ec0aca2b57049e",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "69407c97c657af528ee56c7707298fdb",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Accounting and Finance (3-year degree)",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Accounting and Finance (with integrated Foundation)",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business Economics",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Business Economics (with integrated Foundation)",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Business Management (3 year degree)",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-144"
                },
                {
                    "course_name": "Business Management (including work placement year)",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Business Management (with integrated Foundation)",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business Management with Applied Computing",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Business Management with Entrepreneurship",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Business Management with Entrepreneurship (with integrated Foundation)",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Computing",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Computing (Artificial Intelligence and Robotics)",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Computing (Cyber Security)",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Computing (with integrated Foundation)",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computing with Accounting and Finance",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Computing with Accounting and Finance (with integrated Foundation)",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computing with Business Management",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Computing with Business Management (with integrated Foundation)",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Content Creation, Digital Media and Journalism",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Content Creation, Digital Media and Journalism with integrated Foundation",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics (with integrated Foundation)",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "102-128"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "102-128"
                },
                {
                    "course_name": "History (Flexible Honours)",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "History with Economics",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "History with Economics",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "History with English Literature",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "History with English Literature",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "History with Politics",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "History with Politics",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations (with integrated Foundation)",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Law (with integrated Foundation)",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Law and Criminology",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Law with Business Management",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Medical Sciences (Pre-Med)",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Medicine",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "MB ChB",
                    "duration": "4.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Philosophy, Politics and Economics",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-144"
                },
                {
                    "course_name": "Philosophy, Politics and Economics",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-144"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Psychology (with integrated Foundation)",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Psychology with Applied Computing",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Psychology with Business Management",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Psychology with English Literature",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Security, Intelligence and Cyber",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Security, Intelligence and Cyber with integrated Foundation",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Law (Part-Time)",
                    "university": "University of Buckingham",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "2424148d668aa94a5add7601699048c1",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "7f08c545ca75b85f9d3c5aa5289ac309",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "960e3bf8f787db9dd768a9d08f7aeac5",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Applied Sciences",
                    "university": "Bury College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Community Coaching for England HTQ",
                    "university": "Bury College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Engineering HTQ",
                    "university": "Bury College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Film and Television",
                    "university": "Bury College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Healthcare Practice HTQ",
                    "university": "Bury College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "International Tourism, Hospitality and Events Management",
                    "university": "Bury College",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "International Tourism, Hospitality and Events Management (Top-up)",
                    "university": "Bury College",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Performing Arts: Makeup Artistry in the Creative Industries",
                    "university": "Bury College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Services (with a focus on Law, Criminology, Psychology and Sociology)",
                    "university": "Bury College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Public Services (with a focus on Law, Criminology, Psychology and Sociology)",
                    "university": "Bury College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Salon Management",
                    "university": "Bury College",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Salon Management (Top-up)",
                    "university": "Bury College",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social and Community Work",
                    "university": "Bury College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "Bury College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Working with Children and Families",
                    "university": "Bury College",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "1cd968c3c7d2447580865e14df18e23f",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "80bdd44825867ecad238fc94069403fd",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Architectural Technology for England",
                    "university": "Cambridge Regional College",
                    "location": "Cambridge Campus",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business",
                    "university": "Cambridge Regional College",
                    "location": "Cambridge Campus",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business",
                    "university": "Cambridge Regional College",
                    "location": "Cambridge Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Construction Management for England (Construction Site Supervisor)",
                    "university": "Cambridge Regional College",
                    "location": "Cambridge Campus",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical & Electronic Engineering",
                    "university": "Cambridge Regional College",
                    "location": "Cambridge Campus",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Design",
                    "university": "Cambridge Regional College",
                    "location": "Cambridge Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music (General)",
                    "university": "Cambridge Regional College",
                    "location": "Cambridge Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Music (Production)",
                    "university": "Cambridge Regional College",
                    "location": "Cambridge Campus",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Services",
                    "university": "Cambridge Regional College",
                    "location": "Cambridge Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Public Services",
                    "university": "Cambridge Regional College",
                    "location": "Cambridge Campus",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport",
                    "university": "Cambridge Regional College",
                    "location": "Cambridge Campus",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport",
                    "university": "Cambridge Regional College",
                    "location": "Cambridge Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Architectural Technology for England (HTQ) (PT)",
                    "university": "Cambridge Regional College",
                    "location": "Cambridge Campus",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Construction Management for England (Construction Site Supervisor) HTQ(PT)",
                    "university": "Cambridge Regional College",
                    "location": "Cambridge Campus",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Cambridge Regional College",
                    "location": "Cambridge Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pearson BTEC Level 4 Higher National Certificate in Mechanical Engineering for England (HTQ)",
                    "university": "Cambridge Regional College",
                    "location": "Cambridge Campus",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "7b08e772bd3d45aedf3fa7526f0ed902",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Art & Design",
                    "university": "Cambridge School of Visual and Performing Arts",
                    "location": "Main Site",
                    "qualification": "Foundation Diploma (RQF Level 4)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "BA(Hons) Graphic Design",
                    "university": "Cambridge School of Visual and Performing Arts",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Drama Foundation Classical & Contemporary Acting",
                    "university": "Cambridge School of Visual and Performing Arts",
                    "location": "Main Site",
                    "qualification": "Professional Diploma in Performance",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama Foundation Musical Theatre",
                    "university": "Cambridge School of Visual and Performing Arts",
                    "location": "Main Site",
                    "qualification": "Diploma in Performance",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion",
                    "university": "Cambridge School of Visual and Performing Arts",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Fashion Branding & Creative Communication",
                    "university": "Cambridge School of Visual and Performing Arts",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Music Foundation",
                    "university": "Cambridge School of Visual and Performing Arts",
                    "location": "Main Site",
                    "qualification": "Diploma in Performance",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Art & Design",
                    "university": "Cambridge School of Visual and Performing Arts",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Branding & Creative Communication",
                    "university": "Cambridge School of Visual and Performing Arts",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Design",
                    "university": "Cambridge School of Visual and Performing Arts",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graduate Diploma in Art & Design",
                    "university": "Cambridge School of Visual and Performing Arts",
                    "location": "Main Site",
                    "qualification": "Grad Dip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Communication Illustration & Animation",
                    "university": "Cambridge School of Visual and Performing Arts",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        }
    },
    {
        "id": "70eebd9d2aa4d630159d6aea790cf6eb",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Anglo-Saxon, Norse, and Celtic",
                    "university": "University of Cambridge",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University of Cambridge",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Cambridge",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Asian and Middle Eastern Studies",
                    "university": "University of Cambridge",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering and Biotechnology",
                    "university": "University of Cambridge",
                    "location": "Multiple Locations",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "University of Cambridge",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics (including preliminary year)",
                    "university": "University of Cambridge",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Cambridge",
                    "location": "Multiple Locations",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design",
                    "university": "University of Cambridge",
                    "location": "Multiple Locations",
                    "qualification": "MDes (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Cambridge",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Cambridge",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "University of Cambridge",
                    "location": "Multiple Locations",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "University of Cambridge",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Foundation Year in Arts, Humanities and Social Sciences",
                    "university": "University of Cambridge",
                    "location": "Open application",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Cambridge",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Cambridge",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Modern Languages",
                    "university": "University of Cambridge",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Politics",
                    "university": "University of Cambridge",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art",
                    "university": "University of Cambridge",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human, Social, and Political Sciences",
                    "university": "University of Cambridge",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Land Economy",
                    "university": "University of Cambridge",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Cambridge",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics",
                    "university": "University of Cambridge",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Cambridge",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine",
                    "university": "University of Cambridge",
                    "location": "Multiple Locations",
                    "qualification": "MB",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine (Cambridge Graduate Course)",
                    "university": "University of Cambridge",
                    "location": "Multiple Locations",
                    "qualification": "MB",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern and Medieval Languages",
                    "university": "University of Cambridge",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of Cambridge",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Sciences",
                    "university": "University of Cambridge",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Cambridge",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological and Behavioural Sciences",
                    "university": "University of Cambridge",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology, Religion, and Philosophy of Religion",
                    "university": "University of Cambridge",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Medicine",
                    "university": "University of Cambridge",
                    "location": "Multiple Locations",
                    "qualification": "MB",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Advanced Chemical Engineering",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "11 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice (PHEM)",
                    "university": "University of Cambridge",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Materials for the Energy Transition",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "11 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "African Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "AI Ethics and Society",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MSt",
                    "duration": "22 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "American History",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anglo-Saxon, Norse and Celtic",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anglo-Saxon, Norse and Celtic",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Antarctic Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropocene Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "11 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Mathematics",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MASt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Mathematics and Theoretical Physics",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeological Research",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeological Science",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MArch",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture (Degree Apprenticeship)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MSt",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture and Urban Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture by Thesis",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Asian and Middle Eastern Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Asian and Middle Eastern Studies (Chinese Studies)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "11 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Asian and Middle Eastern Studies (Classical Islamic History and Culture)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "11 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Asian and Middle Eastern Studies (Hebrew and Pre-Modern Jewish History and Culture)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "11 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Asian and Middle Eastern Studies (Hebrew and Semitic Studies)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "11 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Asian and Middle Eastern Studies (Japanese Studies)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "11 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Asian and Middle Eastern Studies (Korean Studies)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "11 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Asian and Middle Eastern Studies (Modern Middle Eastern Studies)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "11 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Asian and Middle Eastern Studies (Muslim-Jewish Relations)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "11 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Asian and Middle Eastern Studies by Research (Chinese Studies)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Asian and Middle Eastern Studies by Research (Japanese Studies)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Asian and Middle Eastern Studies by Research (Korean Studies)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Asian and Middle Eastern Studies by Research (Middle Eastern Studies)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Asian and Middle Eastern Studies by Research (South Asian Studies)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Assyriology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astronomy",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astronomy",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astrophysics",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MASt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Basic and Translational Neuroscience",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Anthropological Science",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "11 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Anthropology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (Babraham Institute)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (Biochemistry) by thesis",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (EBI)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (Genetics) by thesis",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (MRC Laboratory of Molecular Biology)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (MRC Laboratory of Molecular Biology) by thesis",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (MRC Toxicology Unit)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (MRC Toxicology Unit) by thesis",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (Pathology) by thesis",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (Pharmacology) by thesis",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (Physiology, Development and Neuroscience)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (Plant Sciences) by thesis",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (Psychology) by thesis",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (Sanger Institute)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (Sanger Institute) by thesis",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (Stem Cell Biology) by thesis",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (Veterinary Medicine) by thesis",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (Zoology) by thesis",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences (Biomolecular Science) by advanced study",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "11 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences (Cell Science) by advanced study",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "11 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences (Crop Science) by advanced study",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "11 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences (Developmental Biology) by advanced study",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "11 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences (Infection Biology and Molecular Immunology) by advanced study",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "11 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences (Reproduction and Embryogenesis) by advanced study",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "11 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences - BBSRC DTP",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences at the Department of Veterinary Medicine",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biostatistics",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "11 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Doctorate",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "DBS",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer Biology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cardiovascular Research",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering and Biotechnology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Biochemistry",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Neurosciences",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Research, Education and Leadership",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PgCert",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical School MBPhD Programme",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Coaching",
                    "university": "University of Cambridge",
                    "location": "No Locations",
                    "qualification": "MSt",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Neurosciences",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computation, Cognition and Language",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Methods for Materials Science",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Connected Electronic & Photonic Systems EPSRC CDT",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation Leadership",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "11 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Corporate Law (MCL)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "Master of Corporate Law (MCL)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Intensive Science",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Development Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Development Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Humanities",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil (Res)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Humanities",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Doctor of Veterinary Medicine",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Doctoral Training Programme in Medical Research",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD with Integrated Study",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Modern History",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth Sciences",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth Sciences",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic and Social History",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "11 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic Research",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "Diploma",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Data Science",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Arts, Creativity & Education)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Critical Approaches to Children's Literature)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Education, Globalisation and International Development)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Educational Leadership and Improvement)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Knowledge, Power and Politics)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Psychology and Education)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Research in Second Language Education)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Research in Second Language Education)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil (Res)",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Transforming Practice) PACES entry only",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MEd (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Transforming Practice) PGCE entry only",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MEd (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Egyptology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Technologies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "11 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering for Sustainable Development",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "11 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language Assessment",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MSt",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Entrepreneurship",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MSt",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Policy",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "EPSRC CDT in Developing National Capability for Materials 4.0",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "EPSRC CDT in Superconductivity: Enabling Transformative Technologies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "EPSRC CDT PhD in Aerosol Science",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ethics of AI, Data and Algorithms",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Executive Master of Business Administration (EMBA)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MBA (Executive)",
                    "duration": "20 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Screen Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Screen Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Screen Studies by thesis",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Economics",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Foundations of Clinical Psychology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Future Infrastructure and Built Environment",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genetics",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genomic Medicine",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genomic Medicine",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Cambridge",
                    "location": "No Locations",
                    "qualification": "MPhil (Res)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Risk and Resilience",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Haematology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Professionals",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health, Medicine and Society",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hebrew Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "ADV Dip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Heritage Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Philosophy of Science",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Philosophy of Science and Medicine",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art and Architecture",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Holocene Climates",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil (Res)",
                    "duration": "11 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Evolutionary Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human-Inspired Artificial Intelligence",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human-Inspired Artificial Intelligence",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Industrial Systems, Manufacture, and Management",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "11 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Infection and Immunity",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interdisciplinary Nanoscience and Nanotechnology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Land Economy by Research",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Land Economy by Thesis",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Latin American Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Latin American Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Latin American Studies (by thesis only)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MLitt",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Learning Design and Teaching Innovation (Academic Professional Apprenticeship)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PgCert",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics (Theoretical and Applied Linguistics)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics and Language Sciences",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Literature, Culture and Thought",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Literature, Culture and Thought",
                    "university": "University of Cambridge",
                    "location": "No Locations",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Machine Learning and Machine Intelligence",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil (Res)",
                    "duration": "11 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD with Integrated Study",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MRes",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration (MBA)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MBA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Education (Research in Second Language Education)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MEd (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Finance (MFin)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MFin",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Law",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "LLM",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science and Metallurgy",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science and Metallurgy",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical Statistics",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MASt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics of Information (CDT)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Education",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MSt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Genetics",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD with Integrated Study",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (Clinical Biochemistry)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (Clinical Neurosciences)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (CRUK CI)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (CRUK CI)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD with Integrated Study",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (Haematology)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (Medical Genetics)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (Medicine)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (MRC Cognition and Brain Sciences Unit)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (MRC Cognition and Brain Sciences Unit)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD with Integrated Study",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (MRC Epidemiology Unit)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD with Integrated Study",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (MRC Mitochondrial Biology Unit)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD with Integrated Study",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (MRC Mitochondrial Biology Unit)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (Obstetrics and Gynaecology)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (Oncology)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (Paediatrics)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (Psychiatry)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (Radiology)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (Surgery)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval History",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Micro and Nanotechnology Enterprise",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern British History",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern European History",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern South Asian Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular Mechanisms of Human Disease",
                    "university": "University of Cambridge",
                    "location": "No Locations",
                    "qualification": "MPhil",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MPhil in Innovation, Strategy and Organisation",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MPhil in Technology Policy",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MRC DTP iCASE Programme",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD with Integrated Study",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Multi-disciplinary Gender Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Multi-disciplinary Gender Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD with Integrated Study",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD with Integrated Study",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "National Institutes of Health Oxford/Cambridge Programme",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD with Integrated Study",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nuclear Energy",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "11 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Obesity, Endocrinology and Metabolism",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oncology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paediatrics",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pathology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PhD in Land Economy",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Cambridge",
                    "location": "Multiple Locations",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Cambridge",
                    "location": "Multiple Locations",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Photonic and Electronic Systems",
                    "university": "University of Cambridge",
                    "location": "No Locations",
                    "qualification": "MRes",
                    "duration": "11 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MASt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiology, Development and Neuroscience",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Planetary Science and Life in the Universe",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Planning, Growth and Regeneration",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Plant Sciences",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Polar Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Polar Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Thought and Intellectual History",
                    "university": "University of Cambridge",
                    "location": "No Locations",
                    "qualification": "MPhil (Res)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Population Health Sciences",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Practice in Architecture",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychiatry",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health and Primary Care",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Policy",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pure Mathematics and Mathematical Statistics",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MASt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pure Mathematics and Mathematical Statistics",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MASt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantitative Climate and Environmental Science",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Radiology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scientific Computing",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sensor Technologies and Applications",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Slavonic Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropological Research",
                    "university": "University of Cambridge",
                    "location": "Main Site",
                    "qualification": "MPhil (Res)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "11 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology (Political and Economic Sociology)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology (The Sociology of Marginality and Exclusion)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology (The Sociology of Media and Culture)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Stem Cell Biology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Stem Cell Medicine",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategy, Marketing, Operations and Organisational Behaviour",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Surgery",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Business",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PgDip",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Business (PCSB)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Energy Materials Innovation",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching and Learning in Higher Education",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PgCert",
                    "duration": "13 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching Creative Writing",
                    "university": "University of Cambridge",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religious Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology, Religion and Philosophy of Religion",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology, Religion and Philosophy of Religion",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "ADV Dip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MASt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translating Devices and Advanced Therapies Research",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "UKRI AI CDT in Decision Making for Complex Systems",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Medicine",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Science",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Science (Physiology, Development and Neuroscience) by thesis",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "World History",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Writing for Performance",
                    "university": "University of Cambridge",
                    "location": "No Locations",
                    "qualification": "MSt",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Writing for Performance",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MSt",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Writing for Performance (EdX)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MSt",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Accounting",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MAcc",
                    "duration": "2 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anglo-Saxon, Norse and Celtic",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Antarctic Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Criminology and Police Management",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MSt",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Criminology, Penology and Management",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MSt",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Mathematics and Theoretical Physics",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture by Thesis",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Asian and Middle Eastern Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "22 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Asian and Middle Eastern Studies by Research (Chinese Studies)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Asian and Middle Eastern Studies by Research (Japanese Studies)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Asian and Middle Eastern Studies by Research (Korean Studies)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Asian and Middle Eastern Studies by Research (Middle Eastern Studies)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Asian and Middle Eastern Studies by Research (South Asian Studies)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astronomy",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Anthropological Science",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Anthropology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (Babraham Institute)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (Biochemistry) by thesis",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (Genetics) by thesis",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (MRC Laboratory of Molecular Biology)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (MRC Laboratory of Molecular Biology) by thesis",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (MRC Toxicology Unit)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (MRC Toxicology Unit) by thesis",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (Pathology) by thesis",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (Pharmacology) by thesis",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (Physiology, Development and Neuroscience)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (Plant Sciences) by thesis",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (Psychology) by thesis",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (Sanger Institute)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (Sanger Institute) by thesis",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (Stem Cell Biology) by thesis",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (Veterinary Medicine) by thesis",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science (Zoology) by thesis",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences - BBSRC DTP",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences at the Department of Veterinary Medicine",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biostatistics",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Building History",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MSt",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer Biology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering and Biotechnology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate, Environmental and Urban Policy",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MSt",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Biochemistry",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Medicine",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MSt",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Medicine",
                    "university": "University of Cambridge",
                    "location": "No Locations",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Neurosciences",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computation, Cognition and Language",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Engineering",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MSt",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MSt",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Development Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Humanities",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Doctor of Veterinary Medicine",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Doctoral Training Programme in Medical Research",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD with Integrated Study",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth Sciences",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth Sciences",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Data Science",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Educational Leadership and Improvement)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Mathematics Education)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Mathematics Education) PACES entry only",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MEd",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Primary Education)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Primary Education) PACES entry only",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MEd",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Psychology and Education)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "EPSRC CDT in Developing National Capability for Materials 4.0",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "EPSRC CDT in Superconductivity: Enabling Transformative Technologies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "EPSRC CDT PhD in Aerosol Science",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Screen Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Screen Studies by thesis",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Future Infrastructure and Built Environment",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genetics",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genomic Medicine",
                    "university": "University of Cambridge",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "9 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genomic Medicine",
                    "university": "University of Cambridge",
                    "location": "No Locations",
                    "qualification": "PgDip",
                    "duration": "21 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genomic Medicine",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MSt",
                    "duration": "10 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genomic Medicine",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Executive Master of Business Administration (Global EMBA)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MBA",
                    "duration": "20 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Risk and Resilience",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Haematology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Healthcare Innovation",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Cambridge",
                    "location": "No Locations",
                    "qualification": "MSt",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Philosophy of Science",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art and Visual Culture",
                    "university": "University of Cambridge",
                    "location": "No Locations",
                    "qualification": "MSt",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human-Inspired Artificial Intelligence",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human-Inspired Artificial Intelligence",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Infection and Immunity",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interdisciplinary Nanoscience and Nanotechnology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MSt",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Introduction to Child and Adolescent Psychotherapeutic Counselling",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "Postgraduate Award",
                    "duration": "2 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Latin American Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Latin American Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Latin American Studies (by thesis only)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MLitt",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics (Theoretical and Applied Linguistics)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics and Language Sciences",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Literature, Culture and Thought",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD with Integrated Study",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Education (Critical Approaches to Children's Literature)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Education (Mathematics Education)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Education (Primary Education)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Studies in Entrepreneurship",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MSt",
                    "duration": "2 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Studies in Social Innovation (M St)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MSt",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Surgery",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "Master of Surgery",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science and Metallurgy",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science and Metallurgy",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics of Information (CDT)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MD (Doctor of Medicine)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Education",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Education",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Genetics",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD with Integrated Study",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (Clinical Biochemistry)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (Clinical Neurosciences)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (CRUK CI)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD with Integrated Study",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (CRUK CI)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (Haematology)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (Medical Genetics)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (Medicine)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (MRC Cognition and Brain Sciences Unit)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (MRC Epidemiology Unit)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD with Integrated Study",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (MRC Mitochondrial Biology Unit)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD with Integrated Study",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (MRC Mitochondrial Biology Unit)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (Obstetrics and Gynaecology)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (Oncology)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (Paediatrics)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (Psychiatry)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (Radiology)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (Surgery)",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MRC DTP iCASE Programme",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD with Integrated Study",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Multi-disciplinary Gender Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD with Integrated Study",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD with Integrated Study",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oncology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paediatrics",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pathology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PhD in Land Economy",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD with Integrated Study",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiology, Development and Neuroscience",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Plant Sciences",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Polar Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Population Health Sciences",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "22 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Postgraduate Advanced Certificate in Educational Studies: Educational Assessment",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "Postgraduate Advanced Certificate",
                    "duration": "15 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychiatry",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health and Primary Care",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pure Mathematics and Mathematical Statistics",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Radiology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MSt",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Slavonic Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Innovation",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MSt",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Stem Cell Biology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability Leadership",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MSt",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Energy Materials Innovation",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religious Studies",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology, Religion and Philosophy of Religion",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translating Devices and Advanced Therapies Research",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "22 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "UKRI AI CDT in Decision Making for Complex Systems",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Medicine",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Science",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Science (Physiology, Development and Neuroscience) by thesis",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology",
                    "university": "University of Cambridge",
                    "location": "Cambridge University",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "8822b118ce6d849db75e95fba96fdce2",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Accounting and Finance (with Professional Placement)",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Accounting and Finance with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Accounting, Finance & Business Management",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Accounting, Finance & Business Management with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Accounting, Finance & Business Management with Professional Placement",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Animal Science",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Animal Science with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64-72"
                },
                {
                    "course_name": "Applied Criminology",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Applied Criminology with Criminal Investigation",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Applied Criminology with Criminal Investigation with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Applied Criminology with Forensic Investigation",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Applied Criminology with Forensic Investigation with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Applied Criminology with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Archaeology",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Archaeology with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Architectural Engineering",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Architectural Engineering with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Biochemistry and Genetics",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biochemistry and Genetics with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biomedical Engineering with foundation year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-112"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biomedical Science with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Bioscience",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Bioscience with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Building Services Engineering",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Building Services Engineering with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Information Systems",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Information Systems (With Year in Industry)",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Information Systems with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management & Economics",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management & Economics with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management & Economics with Professional Placement",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management & Marketing",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management & Marketing with Professional Placement",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management & Marketing\u202f with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management (with Professional Placement)",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-64"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Chemical Engineering with foundation year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Childhood Studies",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Childhood Studies (SEN) Top up year",
                    "university": "Canterbury Christ Church University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Childhood Studies (Top up)",
                    "university": "Canterbury Christ Church University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Childhood Studies Foundation Degree",
                    "university": "Canterbury Christ Church University",
                    "location": "Multiple Locations",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Childhood Studies SEN Foundation Degree",
                    "university": "Canterbury Christ Church University",
                    "location": "Multiple Locations",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Forensics & Security With Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Computer Forensics and Security",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Forensics and Security (With Year in Industry)",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science (With Year in Industry)",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science with foundation year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Computing",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computing (With Year in Industry)",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computing With Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Countering Terrorism & Extremism with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Countering Terrorism and Extremism",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Creative and Professional Writing",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Creative and Professional Writing with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Criminal Investigation",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminal Investigation with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Data Computing Intelligence",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Data Computing Intelligence (With Year in Industry)",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Data Computing Intelligence with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Diagnostic Radiography",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Early Childhood Studies with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Economics & Finance with Professional Placement",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Economics\u202f& Finance",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Economics\u202f& Finance with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Education Studies",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Education Studies with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Events Management",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Events Management with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Film Production",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Film Production with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Food Science and Nutrition",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Food Science and Nutrition With Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Forensic Investigation",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Forensic Investigation with Criminal Investigation",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Forensic Investigation with Criminal Investigation with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Forensic Investigation with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Foundation Degree Physical Education, Physical Activity and Sport",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Games Design",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Games Design with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "General Engineering  With Foundation Year (BEng)",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-112"
                },
                {
                    "course_name": "General Engineering (BEng)",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Geography",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Geography With Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Graphic Design with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Health & Lifestyle Science",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Health & Lifestyle Science with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "History",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "History with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Human Biology",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Human Biology with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Human Resource Management (with Professional Placement)",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Human Resource Management with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "International Foundation Programme",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "Fd cert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "International Hospitality Management",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Hospitality Management With Foundation year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "International Tourism & Hospitality Management With Foundation year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "International Tourism and Hospitality Management",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "International Tourism Management",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Tourism Management With Foundation year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Law",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Law with Criminology with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Law with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Marketing",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Marketing (with Professional Placement)",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Marketing with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mechanical Engineering with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-112"
                },
                {
                    "course_name": "Media Production",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Media Production with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Mental Health & Wellbeing",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mental Health & Wellbeing",
                    "university": "Canterbury Christ Church University",
                    "location": "South Thames Colleges Group (Kingston College)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88-112"
                },
                {
                    "course_name": "Mental Health & Wellbeing with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "MSc Nursing (Adult)",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "MSc Nursing (Mental Health)",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Production",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Music Production with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "Canterbury Christ Church University",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Child)",
                    "university": "Canterbury Christ Church University",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "Canterbury Christ Church University",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing Associate Foundation Degree",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Operating Department Practice",
                    "university": "Canterbury Christ Church University",
                    "location": "Medway Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paramedic Science",
                    "university": "Canterbury Christ Church University",
                    "location": "Medway Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Photography",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Photography with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Physical Education and Sport and Exercise Science",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Physical Education and Sport and Exercise Science with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Physical Education, Physical Activity & Sport (top Up)",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physician Associate Studies",
                    "university": "Canterbury Christ Church University",
                    "location": "Medway Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Plant Science",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Plant Science with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Politics and Global Sustainability",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Politics and Global Sustainability with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64-80"
                },
                {
                    "course_name": "Popular Music",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Popular Music with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Primary Education (QTS)",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Product Design Engineering",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Product Design Engineering With Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-112"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Professional Policing  with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Psychology",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology (Clinical & Forensic)",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology (Clinical & Forensic) with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Psychology (Sport & Exercise) with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Psychology (Sport and Exercise)",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Public Health",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Public Health & Digital Technology",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Public Health with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Social Work",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Social Science",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sociology and Social Science with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BEng",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Software Engineering (With Year in Industry)",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Software Engineering with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BEng",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport and Exercise Science with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sport Coaching",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport Coaching with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sport Development",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport Development with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sport Management",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport Management with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sport, Coaching & Physical Activity (Top Up)",
                    "university": "Canterbury Christ Church University",
                    "location": "London South East Colleges (Orpington Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Therapy and Rehabilitation",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sports Therapy and Rehabilitation with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Theatre and Performance",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Theatre and Performance with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Theology",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Wildlife Ecology and Conservation Science",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Wildlife Ecology and Conservation Science with Foundation Year",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64-80"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Advanced Manufacturing Systems & Technology",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Manufacturing Systems & Technology with Robotics",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Clinical Psychology",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Exercise and Health Science",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Linguistics",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Career Development",
                    "university": "Canterbury Christ Church University",
                    "location": "Medway Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Psychology",
                    "university": "Canterbury Christ Church University",
                    "location": "Salomons Centre, Tunbridge Wells",
                    "qualification": "DClinPsy",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Behavioural Therapy (High Intensity)",
                    "university": "Canterbury Christ Church University",
                    "location": "Salomons Centre, Tunbridge Wells",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Justice",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cybersecurity Technology",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cybersecurity Technology with Placement",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Intelligence",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Intelligence Course with Placement",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Childhood Education",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Event & Tourism Management",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Media Production",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic and Investigative Psychology",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Design",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Public Health",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Healthcare Practice (Simulation Pathway)",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Heritage",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intercultural Communication",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Law",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Music",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "MMus",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA International",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Cultural Studies",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media, Art and Design",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media, Communications and PR",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval and Early Modern Studies",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health and Wellbeing",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern History",
                    "university": "Canterbury Christ Church University",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Adult Nursing",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Mental Health Nursing",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Further Education",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Primary",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE School Direct Primary",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE School Direct Secondary",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Art And Design",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary English",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Geography",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary History",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Mathematics",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary MFL French",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary MFL French With Spanish",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury College",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary MFL Spanish",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary MFL Spanish With French",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "PGCE (Professional)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Music",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "PGCE (Professional)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Physical Education",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Physical Education With EBacc Subject",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Religious Studies",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Sciences (Biology)",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Sciences (Chemistry)",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Sciences (Physics)",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Security & International Relations",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Speech and Language Therapy",
                    "university": "Canterbury Christ Church University",
                    "location": "Medway Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Surgery (Ophthalmology).",
                    "university": "Canterbury Christ Church University",
                    "location": "Medway Campus",
                    "qualification": "MCh",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Surgery (Orthopaedics and Regenerative Medicine)",
                    "university": "Canterbury Christ Church University",
                    "location": "Medway Campus",
                    "qualification": "MCh",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Surgery (Otorhinolaryngology)",
                    "university": "Canterbury Christ Church University",
                    "location": "Medway Campus",
                    "qualification": "MCh",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Surgery (Urology)",
                    "university": "Canterbury Christ Church University",
                    "location": "Medway Campus",
                    "qualification": "MCh",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL)",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religious Studies",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Communication",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Clinical Practice",
                    "university": "Canterbury Christ Church University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Manufacturing Systems & Technology",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Manufacturing Systems & Technology with Robotics",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Clinical Psychology",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Exercise and Health Science",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Career Development",
                    "university": "Canterbury Christ Church University",
                    "location": "Medway Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Justice",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cybersecurity Technology",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cybersecurity Technology with Placement",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Intelligence",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Intelligence Course with Placement",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Childhood Education",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Doctorate)",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "EdD",
                    "duration": "30 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "PhD",
                    "duration": "66 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Event & Tourism Management",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "MPhil",
                    "duration": "66 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic and Investigative Psychology",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Public Health",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Healthcare Practice",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Healthcare Practice (Simulation Pathway)",
                    "university": "Canterbury Christ Church University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Heritage",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intercultural Communication",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Music",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "MMus",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA Collaborative Leadership",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media, Art and Design",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval and Early Modern Studies",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern History",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Musculoskeletal Ultrasound",
                    "university": "Canterbury Christ Church University",
                    "location": "Medway Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Policing & Security Transformation",
                    "university": "Canterbury Christ Church University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Security & International Relations",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religious Studies",
                    "university": "Canterbury Christ Church University",
                    "location": "Canterbury Campus",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "09f2063ba0aa026be885a507c3beacba",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "7482a717e48f6d357fbf548c31ca5b68",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "57dc066c9ecc8fbdc21faec9617f9f84",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance with a Professional Placement Year",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting with a Professional Placement Year",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History and History",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Software Engineering",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and Ancient History",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and History",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Engineering",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Engineering",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Engineering (Year in Industry)",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Engineering (Year in Industry)",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Studies",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astrophysics",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astrophysics",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cymraeg",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Banking and Finance",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Banking and Finance with a Professional Placement Year",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MBiochem",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry (including Year in Professional Training)",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MBiol",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences (including Professional Training Year)",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science (Including Professional Training Year)",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MBiomedSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics with a Professional Placement Year",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (Human Resource Management)",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (Human Resource Management) with a Professional Placement Year",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (International Management)",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (International Management) with a Professional Placement Year",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (Logistics and Operations)",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (Logistics and Operations) with a Professional Placement Year",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (Marketing)",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (Marketing) with a Professional Placement",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with a Professional Placement Year",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Rheoli Busnes gyda\u2019r Gymraeg",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Rheoli Busnes a Chymraeg gyda Blwyddyn o Leoliad Proffesiynol",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with a Placement year Abroad",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with a Year in Industry",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with a Year in Industry",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil and Environmental Engineering",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil and Environmental Engineering",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil and Environmental Engineering (Year in Industry)",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil and Environmental Engineering (Year in Industry)",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering (Year in Industry)",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering (Year in Industry)",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Year in Industry)",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with a year in Industry",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with a year of Study Abroad",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with a Year of Study Abroad",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Security and Forensics",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Security and Forensics (Year in Industry)",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Security and Forensics with a Year of Study Abroad",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation of Objects in Museums and Archaeology",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Social Policy",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dental Hygiene",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dental Therapy and Dental Hygiene",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diagnostic Radiography and Imaging",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with a Professional Placement Year",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering (Year in Industry)",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering (Year in Industry)",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering with Foundation Year",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Linguistics",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Literature",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and a Modern Language",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and History",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Philosophy",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature with Creative Writing",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Geography",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Geography",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Geography with a Year of Study Abroad",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Geoscience",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Geoscience",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Geoscience with a Year of Study Abroad",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Sustainability Science",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Exploration Geology",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Exploration Geology",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Exploration Geology with a Year of Study Abroad",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Financial Mathematics",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Mathematics with a Professional Placement Year",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Mathematics with a Year Abroad",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology with a Year of Study Abroad",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and a Modern Language",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human and Social Sciences",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Geography",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Geography and Planning",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Geography and Planning with a Professional Placement Year (Accredited)",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Geography with a Professional Placement Year",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc Econ H",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Politics",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc Econ H",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism and Communications",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism, Communications and Politics",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism, Media and English Literature",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Y Gyfraith a'r Gymraeg",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Politics",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics and a Modern Language",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Geography",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Geography",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Geography with a Year of Study Abroad",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MMath",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics Operational Research and Statistics with a Year Abroad",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with a Professional Placement Year",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MMath",
                    "duration": "5 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with a Year Abroad",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MMath (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with a Year Abroad",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Professional Placement",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics, Operational Research & Statistics with Professional Placement",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics, Operational Research and Statistics",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical and Electrical Engineering",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical and Electrical Engineering",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical and Electrical Engineering (Year in Industry)",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical and Electrical Engineering (Year in Industry)",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering (Year in Industry)",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering (Year in Industry)",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communications",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media, Journalism and Culture",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Engineering",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Engineering",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Engineering (Year in Industry)",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Engineering (Year in Industry)",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Pharmacology",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicinal Chemistry",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BMid (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Chinese",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern History and Politics",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BMus (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and a Modern Language",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and English Literature",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music with a Year of Study Abroad",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BMus",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BN (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Adult) Spring Intake",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BN (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Child)",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BN (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BN (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Mental Health) Spring Intake",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BN (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Optometry",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MOptom",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Optometry with a Preliminary Year",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MOptom",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MPharm (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Linguistics",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Politics",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Geography",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Geography",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Geography with a Year of Study Abroad",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics (with Professional Placement)",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics (with Professional Placement)",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astronomy",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astronomy",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astronomy (with Professional Placement)",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astronomy (with Professional Placement)",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Medical Physics",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc Econ H",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and a Modern Language",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Sociology",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc Econ H",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with Professional Placement",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Radiotherapy and Oncology",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religion and Theology",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religion, Philosophy and Ethics",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Science",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Education",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Social Policy",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation with a Placement Year Abroad",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Planning and Development",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Planning and Development with a Professional Placement Year",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Welsh and a Modern Language",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cymraeg ac Addysg",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cymraeg a Llenyddiaeth Saesneg",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cymraeg a Hanes",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cymraeg a Newyddiaduraeth",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Welsh and Linguistics",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cymraeg a Cherddoriaeth",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cymraeg ac Athroniaeth",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cymraeg a Gwleidyddiaeth",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science with a Professional Placement Year",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Healthcare Practice",
                    "university": "Cardiff University",
                    "location": "Heath Park Campus",
                    "qualification": "MSc",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Mechanical Engineering",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied and Experimental Clinical Immunology",
                    "university": "Cardiff University",
                    "location": "Heath Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Bioinformatics and Genetic Epidemiology (MSc)",
                    "university": "Cardiff University",
                    "location": "Heath Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Bioinformatics and Genomics (MSc)",
                    "university": "Cardiff University",
                    "location": "Heath Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeological Science",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Design",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Studies",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MArch",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture (Professional Practice)",
                    "university": "Cardiff University",
                    "location": "No Locations",
                    "qualification": "PgDip (Initial Qualification)",
                    "duration": "1 year",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence with a Professional Placement Year (MSc)",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astrophysics",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bar Training Course",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Big Data Biology",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Broadcast Journalism",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Building and Infrastructure Information Modelling (BIM) for Smart Engineering",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration (MBA)",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration with Artificial Intelligence",
                    "university": "Cardiff University",
                    "location": "No Locations",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with a Professional Placement Year",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Strategy and Entrepreneurship",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer Biology and Therapeutics",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children\u2019s Psychological Disorders",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil and Geoenvironmental Engineering",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil and Water Engineering",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Dentistry (Endodontology)",
                    "university": "Cardiff University",
                    "location": "No Locations",
                    "qualification": "MClinDent",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Dentistry (Prosthodontics)",
                    "university": "Cardiff University",
                    "location": "Heath Park Campus",
                    "qualification": "MClinDent",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Dermatology",
                    "university": "Cardiff University",
                    "location": "Heath Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Optometry",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Compound Semiconductor Electronics",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Compound Semiconductor Physics",
                    "university": "Cardiff University",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational and Data Journalism",
                    "university": "Cardiff University",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Methods in Architecture",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing and IT Management",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing and IT Management with Placement",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing with Placement",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation Practice",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cultural and Creative Industries",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security and Technology",
                    "university": "Cardiff University",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Analytics for Government",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Analytics",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data-Intensive Astrophysics",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data-Intensive Physics",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media and Society",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Energy Systems",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Net Zero",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment and Development",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Design of Buildings",
                    "university": "Cardiff University",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Hazards",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Economics",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Linguistics",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Cultures",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Ecology and Conservation (MSc)",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Heritage",
                    "university": "Cardiff University",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Governance and Devolution",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graduate Diploma in Law (Graduate Diploma)",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "Grad Dip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gravitational Wave Physics",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Rights Law",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Implantology",
                    "university": "Cardiff University",
                    "location": "Heath Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intellectual Property Law",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Criminology and Criminal Justice",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Economics, Banking and Finance",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Resource Management",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Journalism",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Planning and Development",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Planning and Urban Design",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Public Relations and Communications",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism, Media and Communications",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language and Communication Research",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language and Linguistics",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Logistics and Operations Management",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Magazine Journalism",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Manufacturing Engineering, Innovation and Management",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Maritime Policy and Shipping Management",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Design Administration (MDA)",
                    "university": "Cardiff University",
                    "location": "No Locations",
                    "qualification": "MDA",
                    "duration": "1 year",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Education",
                    "university": "Cardiff University",
                    "location": "Heath Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicinal Chemistry",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Language Processing",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "News Journalism",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "Cardiff University",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Operational Research and Applied Statistics",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Operational Research, Applied Statistics and Financial Risk",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Orthodontics",
                    "university": "Cardiff University",
                    "location": "Heath Park Campus",
                    "qualification": "MScD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "Cardiff University",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Communication",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Public Policy",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MScEcon",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pre-Registration Occupational Therapy",
                    "university": "Cardiff University",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "Cardiff University",
                    "location": "Heath Park Campus",
                    "qualification": "MPH",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Radiography",
                    "university": "Cardiff University",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social and Public Policy",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Science Research Methods",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering with a Professional Placement Year (MSc)",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spatial Planning and Development",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Public Health Nursing (MSc)",
                    "university": "Cardiff University",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Physiotherapy (MSc)",
                    "university": "Cardiff University",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Engineering",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability Planning and Environmental Policy",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Building Conservation",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Energy and Environment",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Mega Buildings",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Supply Chain Management",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MTh",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Tissue Engineering and Regenerative Medicine",
                    "university": "Cardiff University",
                    "location": "Heath Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation Studies",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Transport and Planning",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban and Regional Development",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Design",
                    "university": "Cardiff University",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Water in a Changing World",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Welsh and Celtic Studies (Astudiaethau Cymreig a Cheltaidd)",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Welsh Government and Politics",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc (Econ)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Wireless and Microwave Communication Engineering",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Wireless and Microwave Communication Engineering with a Research Year",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Wireless and Microwave Communication Engineering with a Year in Industry",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Clinical Practice",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Healthcare Practice",
                    "university": "Cardiff University",
                    "location": "Heath Park Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeological Science",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture (Professional Practice)",
                    "university": "Cardiff University",
                    "location": "No Locations",
                    "qualification": "PgDip (Initial Qualification)",
                    "duration": "2 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration (MBA)",
                    "university": "Cardiff University",
                    "location": "No Locations",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil and Geoenvironmental Engineering",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil and Water Engineering",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Optometry",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc",
                    "duration": "5 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Pharmacy",
                    "university": "Cardiff University",
                    "location": "Multiple Locations",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive and Behavioural Therapies",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing and IT Management",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Analytics for Government",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc (PG)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Analytics",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Design of Buildings",
                    "university": "Cardiff University",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Linguistics",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Independent/Supplementary Prescribing",
                    "university": "Cardiff University",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "8 months",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language and Communication Research",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language and Linguistics",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal Aspects of Medical Practice",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Design Administration (MDA)",
                    "university": "Cardiff University",
                    "location": "No Locations",
                    "qualification": "MDA",
                    "duration": "2 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Education",
                    "university": "Cardiff University",
                    "location": "Heath Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicinal Chemistry",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "Cardiff University",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Operational Research and Applied Statistics",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Operational Research, Applied Statistics and Financial Risk",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "Cardiff University",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Public Policy",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MScEcon",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "Cardiff University",
                    "location": "Heath Park Campus",
                    "qualification": "MPH",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Radiographic Reporting (PgCert)",
                    "university": "Cardiff University",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Radiography",
                    "university": "Cardiff University",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spatial Planning and Development",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Public Health Nursing (MSc)",
                    "university": "Cardiff University",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Physiotherapy (MSc)",
                    "university": "Cardiff University",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Engineering",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability Planning and Environmental Policy",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Building Conservation",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Energy and Environment",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Mega Buildings",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MTh",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation Studies",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban and Regional Development",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Welsh and Celtic Studies (Astudiaethau Cymreig a Cheltaidd)",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Welsh Government and Politics",
                    "university": "Cardiff University",
                    "location": "Cathays Park Campus",
                    "qualification": "MSc (Econ)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Wireless and Microwave Communication Engineering",
                    "university": "Cardiff University",
                    "location": "Main Site - Cardiff",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "9acc1472784872b4999ccdbd63a59824",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Acting for Screen",
                    "university": "Central Film School London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Acting for Screen with Integrated Foundation Year",
                    "university": "Central Film School London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-64"
                },
                {
                    "course_name": "Practical Filmmaking",
                    "university": "Central Film School London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Practical Filmmaking with Integrated Foundation Year",
                    "university": "Central Film School London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-64"
                },
                {
                    "course_name": "Screenwriting (Scriptwriting)",
                    "university": "Central Film School London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Screenwriting (Scriptwriting) with Integrated Foundation Year",
                    "university": "Central Film School London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-64"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Filmmaking",
                    "university": "Central Film School London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        }
    },
    {
        "id": "e271ae9663821b70e526c7c717c33886",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Business and Marketing",
                    "university": "University of Central Lancashire London",
                    "location": "UCLan London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business and Marketing with Foundation Year",
                    "university": "University of Central Lancashire London",
                    "location": "UCLan London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Computing",
                    "university": "University of Central Lancashire London",
                    "location": "UCLan London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computing with Foundation Year",
                    "university": "University of Central Lancashire London",
                    "location": "UCLan London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "University of Central Lancashire London",
                    "location": "UCLan London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Health and Social Care with Foundation Year",
                    "university": "University of Central Lancashire London",
                    "location": "UCLan London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40"
                },
                {
                    "course_name": "International Business Management",
                    "university": "University of Central Lancashire London",
                    "location": "UCLan London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "International Business Management with Foundation Year",
                    "university": "University of Central Lancashire London",
                    "location": "UCLan London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Mental Health and Wellbeing",
                    "university": "University of Central Lancashire London",
                    "location": "UCLan London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Mental Health and Wellbeing with Foundation Year",
                    "university": "University of Central Lancashire London",
                    "location": "UCLan London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Central Lancashire London",
                    "location": "UCLan London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Psychology with Foundation Year",
                    "university": "University of Central Lancashire London",
                    "location": "UCLan London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Public Health",
                    "university": "University of Central Lancashire London",
                    "location": "UCLan London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Public Health with Foundation Year",
                    "university": "University of Central Lancashire London",
                    "location": "UCLan London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "bafea6542991135590efc791b4071555",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "32f33347c65da8c590139201d45cdde5",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Green Building",
                    "university": "Centre for Alternative Technology",
                    "location": "Centre for Alternative Technology",
                    "qualification": "MSc (PG)",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability and Adaptation",
                    "university": "Centre for Alternative Technology",
                    "location": "Centre for Alternative Technology",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability and Adaptation",
                    "university": "Centre for Alternative Technology",
                    "location": "Centre for Alternative Technology",
                    "qualification": "MSc (PG)",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability and Behaviour Change",
                    "university": "Centre for Alternative Technology",
                    "location": "Centre for Alternative Technology",
                    "qualification": "MSc",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability and Ecology",
                    "university": "Centre for Alternative Technology",
                    "location": "Centre for Alternative Technology",
                    "qualification": "MSc",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability in Energy Provision and Demand Management",
                    "university": "Centre for Alternative Technology",
                    "location": "Centre for Alternative Technology",
                    "qualification": "MSc",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Architecture",
                    "university": "Centre for Alternative Technology",
                    "location": "Centre for Alternative Technology",
                    "qualification": "MArch Studies",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Food and Natural Resources",
                    "university": "Centre for Alternative Technology",
                    "location": "Centre for Alternative Technology",
                    "qualification": "MSci",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Green Building",
                    "university": "Centre for Alternative Technology",
                    "location": "Centre for Alternative Technology",
                    "qualification": "MSc (PG)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability and Adaptation",
                    "university": "Centre for Alternative Technology",
                    "location": "Centre for Alternative Technology",
                    "qualification": "MSc (PG)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability and Adaptation",
                    "university": "Centre for Alternative Technology",
                    "location": "Centre for Alternative Technology",
                    "qualification": "MRes",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability and Behaviour Change",
                    "university": "Centre for Alternative Technology",
                    "location": "Centre for Alternative Technology",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability and Ecology",
                    "university": "Centre for Alternative Technology",
                    "location": "Centre for Alternative Technology",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability in Energy Provision and Demand Management",
                    "university": "Centre for Alternative Technology",
                    "location": "Centre for Alternative Technology",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Architecture",
                    "university": "Centre for Alternative Technology",
                    "location": "Centre for Alternative Technology",
                    "qualification": "MArch Studies",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Food and Natural Resources",
                    "university": "Centre for Alternative Technology",
                    "location": "Centre for Alternative Technology",
                    "qualification": "MSci",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "0eb69ed297e71b8c3d409aa2a6292aaa",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Accounting and Finance (including Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Accounting and Finance (with Professional Placement Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Acting",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Acting (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Adult Nursing",
                    "university": "University of Chester",
                    "location": "Multiple Locations",
                    "qualification": "BN (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Adult Nursing (including Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BN (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Adult Nursing (including Foundation Year)",
                    "university": "University of Chester",
                    "location": "University Centre Warrington",
                    "qualification": "BN (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Adult Nursing (including Foundation Year)",
                    "university": "University of Chester",
                    "location": "University Centre Birkenhead",
                    "qualification": "BN (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Adult Nursing (Postgraduate)",
                    "university": "University of Chester",
                    "location": "Wheeler",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Behaviour",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Animal Behaviour (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Animal Welfare Science",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Animal Welfare Science (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Archaeology (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Archaeology and Forensic Biology",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Archaeology and History",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Archaeology and History (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Biology",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biology (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biomedical Science (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Bioveterinary Science",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Bioveterinary Science (including  Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business and International Tourism Management",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business and International Tourism Management (with a Professional Placement Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management (including Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Management (with Professional Placement Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management and French",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management and Marketing",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management and Marketing (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Management and Marketing (with Professional Placement Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management and Spanish",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Chemical Engineering (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Chemistry (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Children's Nursing",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BN (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Children's Nursing (including Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BN (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Chinese and Global Affairs, Politics and International Relations",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Commercial Music",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Computer Science and Electronic Engineering",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science and Electronic Engineering (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Computer Science with Artificial Intelligence",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science with Artificial Intelligence (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Counselling Skills and Psychology",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Counselling Skills and Sociology",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Creative and Professional Writing",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Creative and Professional Writing (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Creative Writing (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Criminology and Forensic Biology",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology and Forensic Biology (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Criminology and Psychology",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology and Psychology (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Criminology with Counselling Skills",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology with Policing",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology with Policing (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Cybersecurity",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Cybersecurity (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Cybersecurity with Artificial Intelligence",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Cybersecurity with Artificial Intelligence (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Data Science (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Digital Marketing (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Digital Marketing (with Professional Placement Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Drama",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Drama (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Early Childhood and Primary Education",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Early Childhood and Primary Education (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Early Years - Primary Education  (3-7) with QTS",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Education",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Education (Including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "English",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "English Language",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English Language (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English Literature (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Events Management",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Events Management (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Events Management (with Professional Placement Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Events Management and International Tourism",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Events Management and International Tourism (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Events Management and International Tourism (with Professional Placement Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Events Management and Marketing",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Events Management and Marketing (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Events Management and Marketing (with Professional Placement Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Events Management with Business",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Events Management with Business (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Events Management with Business (with Professional Placement Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fashion Communication Marketing (Social Media)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fashion Design",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fashion Design (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Film and Media Studies",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Film and Media Studies (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Finance with Banking",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Finance with Banking (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Finance with Banking (with a Professional Placement Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fine Art (including Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Forensic Biology",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Forensic Biology (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Forensic Psychology (including Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "French and History",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Games Design",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Games Design (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Games Development",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Games Development (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA/BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Geography (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA/BSc (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Geography (with a Year Abroad)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA/BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Geography (with a Year in Industry)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA/BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Global Affairs, Politics and International Relations",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Global Affairs, Politics and International Relations (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Global Affairs, Politics and International Relations and Spanish",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Global Affairs, Politics and International Relations with Economics",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Global Affairs, Politics and International Relations with Economics (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Global Affairs, Politics and International Relations with Geography",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Global Affairs, Politics and International Relations with Geography (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Global Affairs, Politics and International Relations with History",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Global Affairs, Politics and International Relations with History (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Graduate Entry Medicine",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "MB ChB",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Graphic Design (including Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "History",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "History (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "History and Spanish",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Interior Design",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Interior Design (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "International Tourism Management",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Tourism Management (including Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "International Tourism Management (with Professional Placement Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Tourism Management and Marketing",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Tourism Management and Marketing (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "International Tourism Management and Marketing (with Professional Placement Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Journalism",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Journalism (including Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Law",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Law (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Law with Business and Commerce",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Law with Business and Commerce (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Law with Criminal Justice",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Law with Criminal Justice (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Learning Disability Nursing",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BN (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Learning Disability Nursing (including Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BN (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Marine Biology",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Marine Biology (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Marketing (including Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Marketing (with Professional Placement Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mathematics (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Mathematics with Computer Science",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mathematics with Computer Science (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Mathematics with Data Science",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mathematics with Data Science (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Mathematics with Finance",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mathematics with Finance (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Media and Television Production",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Media and Television Production (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Mental Health Nursing",
                    "university": "University of Chester",
                    "location": "Multiple Locations",
                    "qualification": "BN (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mental Health Nursing (including Foundation Year)",
                    "university": "University of Chester",
                    "location": "University Centre Warrington",
                    "qualification": "BN (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Mental Health Nursing (including Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BN (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Mental Health Nursing (Postgraduate)",
                    "university": "University of Chester",
                    "location": "Wheeler",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Midwifery (including Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "MNurs Adult and Children's",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "MNurs",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "MNurs Adult and Mental Health",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "MNurs",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "MNurs Children's and Mental Health",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "MNurs",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "MNurs Learning Disability and Children's",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "MNurs",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "MNurs Learning Disability and Mental Health",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "MNurs",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "MNurs Mental Health and Children's",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "MNurs",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Modern Languages (3 years)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Modern Languages (with a Year Abroad)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Music Journalism",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Journalism (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Music Production",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Production (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Musical Theatre Performance",
                    "university": "University of Chester",
                    "location": "The Hammond",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Nursing Associate",
                    "university": "University of Chester",
                    "location": "University Centre Warrington",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Nutrition and Dietetics",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "PE and Sports Coaching",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "PE and Sports Coaching (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Performing Arts",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Pharmacology (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Philosophy, Ethics and Religion",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Philosophy, Ethics and Religion (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Photography",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Photography (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Physician Associate Studies (Taught Postgraduate)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Policing, Law and Investigation (Top-Up)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Popular Music Performance",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Primary Education (5-11) with QTS",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Product Design",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Product Design (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Professional Dance",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Professional Policing (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Psychology (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Psychology and Sociology",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology and Sociology (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Psychology with Forensic Biology",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology with Spanish",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology with Sport and Exercise Sciences",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology with Sport and Exercise Sciences (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Chester",
                    "location": "University Centre Warrington",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Social Work (including Foundation Year)",
                    "university": "University of Chester",
                    "location": "University Centre Warrington",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sociology (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sociology with Criminology",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sociology with Criminology (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sociology with Global Affairs, Politics and International Relations",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sociology with Global Affairs, Politics and International Relations (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Software Engineering (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Software Engineering (with Year in Industry)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Spanish with Sport and Exercise Science",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport & Exercise Sciences",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Sport & Exercise Sciences (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sport Business Management",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport Business Management (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sport Business Management (with a Professional Placement Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sports Journalism",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sports Journalism (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Theology and Religious Studies",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Theology and Religious Studies (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Zoology",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Zoology (including a Foundation Year)",
                    "university": "University of Chester",
                    "location": "Chester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "feafae748cb896ec73faa7d920e15ac9",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Engineering",
                    "university": "Chesterfield College",
                    "location": "Chesterfield College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Games Development",
                    "university": "Chesterfield College",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performing Arts (Acting)",
                    "university": "Chesterfield College",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Professional Practice in Health and Social Care",
                    "university": "Chesterfield College",
                    "location": "Chesterfield College",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Services",
                    "university": "Chesterfield College",
                    "location": "Chesterfield College",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Engineering",
                    "university": "Chesterfield College",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "See entry requirements"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "35f548e45f93a92ea657891548995ea5",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "4f0e4db956e48cf57e2619e0ea9bfeea",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "3D Animation & Visual Effects",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Acting",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Acting (Italia Conti)",
                    "university": "University of Chichester",
                    "location": "Italia Conti",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Acting for Contemporary Theatre",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Acting for Film",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Adult Nursing",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Advanced Applied Psychology",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Audio Production and Music Technologies",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Business and Human Resource Management",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Business and Management (International Route)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Business Management (Top Up)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and Finance",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Business Management and Marketing",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Business Psychology",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Childhood with Therapeutic Play",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Childhood with Therapeutic Play (with integrated foundation year)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Children\u2019s Health and Wellbeing",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-110"
                },
                {
                    "course_name": "Commercial Music",
                    "university": "University of Chichester",
                    "location": "Platform One",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Computing",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Counselling Psychology",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Counselling Psychology (with Integrated Foundation Year)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Creative Writing and English",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Creative Writing and English (with Integrated Foundation Year)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Creative Writing and History",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Creative Writing and Philosophy & Ethics",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Creative Writing and Screenwriting",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Criminology (with Integrated Foundation Year)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Criminology and Forensic Psychology",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Dance",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Dance (Italia Conti)",
                    "university": "University of Chichester",
                    "location": "Italia Conti",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Dance (Italia Conti)",
                    "university": "University of Chichester",
                    "location": "Italia Conti",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Dance Education and Teaching",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Dance Performance",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Dance Science",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Dance Studies",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dance, Aerial and Physical Theatre",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Developmental and Education Psychology",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Digital Film Production",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "102-104"
                },
                {
                    "course_name": "Digital Film Production and Screenwriting",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Digital Film Technologies",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Drama, Theatre and Directing",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Early Childhood (Top Up)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Early Childhood Studies with Integrated Foundation Year",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Early Years",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Education (with Integrated Foundation Year)",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Education, Special Needs and Disability",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Electronic and Electrical Engineering",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Electronic and Electrical Engineering (with Integrated Foundation Year)",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "English Literature and History",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Esports",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "ESports and Sports Media",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Event Management (Top Up)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance & Accounting (International Route)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Fine Art with Digital Photography",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-119"
                },
                {
                    "course_name": "Fine Art with Printmaking",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Fine Art with Textiles",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Football Coaching and Performance",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Games Design and Development",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "History",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "History (with Integrated Foundation Year)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "International Education",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Introduction to Acting (Italia Conti)",
                    "university": "University of Chichester",
                    "location": "Italia Conti",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Introduction to Dance (Italia Conti)",
                    "university": "University of Chichester",
                    "location": "Italia Conti",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Jazz and Cabaret Performance",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Jazz Performance",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BMus (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Law",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Law (with Integrated Foundation Year)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Law with Business",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Law with Criminology (with Integrated Foundation Year)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Law with International Relations",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Law with International Relations (with Integrated Foundation Year)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Marketing (Top Up)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Mathematics and Secondary Teaching (QTS)",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Mathematics with Statistics",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Mechanical Engineering (with Integrated Foundation Year)",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Media & Communications",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Medieval & Early Modern History",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Music",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Music Performance",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Music Performance (Film Acting)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Music with Jazz Studies",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Music with Musical Theatre",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Music with Teaching",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Musical Theatre (Cabaret)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Musical Theatre (Film Acting)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Musical Theatre (Italia Conti)",
                    "university": "University of Chichester",
                    "location": "Italia Conti",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Musical Theatre (Italia Conti)",
                    "university": "University of Chichester",
                    "location": "Italia Conti",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Musical Theatre (Music)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Musical Theatre (Professional Performance)",
                    "university": "University of Chichester",
                    "location": "Associated Studios Performing Arts Academy",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Musical Theatre (Teaching)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Musical Theatre (Triple Threat)",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Musical Theatre Performance",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Musical Theatre Performance (Italia Conti)",
                    "university": "University of Chichester",
                    "location": "Italia Conti",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Outdoor and Adventure Education",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Painting and Drawing",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Performance",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BMus (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Philosophy and Ethics",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Philosophy and Ethics (with Integrated Foundation Year)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Physical Education (Primary Years)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Physical Education (Secondary QTS)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Physical Education (Secondary Years)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Physical Education and Sports Coaching",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Politics and Contemporary History",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Politics and Philosophy & Ethics",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Politics with International Relations  (with Integrated Foundation Year)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Primary Teaching (QTS)",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Primary Teaching with Early Years with QTS",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Psychology (with Integrated Foundation Year)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Screen Acting and Film Production",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Screenwriting",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Sociology (with Integrated Foundation Year)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "46"
                },
                {
                    "course_name": "Sociology with Criminology",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Sociology with Criminology (with Integrated Foundation Year)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sport and Exercise Psychology",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Sport and Exercise Science (Performance Sailing)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Sport and Exercise Science with Integrated Foundation Year",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sport Business Management",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Sport Coaching",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Sports Media",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Sports Therapy",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Sports Therapy",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Sustainable Fashion",
                    "university": "University of Chichester",
                    "location": "Platform One",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Visual Effects",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Vocal Performance",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BMus (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Digital Marketer Degree Apprenticeship",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Electrical and Electronic Technical Support Engineer Degree Apprenticeship",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Humanistic Counselling",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Manufacturing Engineer Degree Apprenticeship",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "BEng",
                    "duration": "4 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Social Worker Degree Apprenticeship",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "96-112"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Adult Nursing",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Practice",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "Professional Doctorate",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Professional Practice",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Sport and Exercise Nutrition",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Studies",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Choreography and Professional Practices",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Coaching Psychology",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Composition for Film, TV and Games",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Law and Criminal Justice",
                    "university": "University of Chichester",
                    "location": "No Locations",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cultural History",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dance Science",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dance, Somatics and Dance Science",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dance, Theatre and Music",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "MPhil",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dance: Advanced Practice",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "MPhil (Res)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "MPhil (Res)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Management",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Creative Writing (Including Literature)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "MSci",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Politics",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law Conversion with SQE Preparation",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA Devised Theatre",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health Nursing (Pre-Registration)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MRes Sport (named pathways)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Performance",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Teaching",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Musical Theatre",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "MA",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuromarketing",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance (Theatre or Theatre Collectives)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance: Dance (mapdance)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy (Pre-Registration)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Dance",
                    "university": "University of Chichester",
                    "location": "Multiple Locations",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary English",
                    "university": "University of Chichester",
                    "location": "Multiple Locations",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary History",
                    "university": "University of Chichester",
                    "location": "Multiple Locations",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Mathematics",
                    "university": "University of Chichester",
                    "location": "Multiple Locations",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Music",
                    "university": "University of Chichester",
                    "location": "Multiple Locations",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Physical Education",
                    "university": "University of Chichester",
                    "location": "Multiple Locations",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Science (Biology, Chemistry or Physics)",
                    "university": "University of Chichester",
                    "location": "Multiple Locations",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy (including Social Work, Social Care and Early Childhood Studies)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Biomechanics",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Physiology",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Psychology (British Psychological Society stage 1)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport, Exercise and Physical Education",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Performance Analysis",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strength and Conditioning",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "MPhil (Res)",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Practice",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "Professional Doctorate",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Autism Education",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Studies",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Choreography and Professional Practices",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "MA",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cultural History",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dance Science",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dance, Theatre and Music",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "MPhil",
                    "duration": "84 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "MPhil (Res)",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "MPhil (Res)",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Creative Writing (Including Literature)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "PhD",
                    "duration": "84 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Politics",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "PhD",
                    "duration": "84 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Inclusive Special Education",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Performance",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Teaching",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "National Award for Special Educational Needs Coordination",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance (Theatre or Theatre Collectives)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Postgraduate Engineering Business Manager Degree Apprenticeship",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Studies in Education, Growth Mindset",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Studies in Education, Mental Health Awareness in Schools",
                    "university": "University of Chichester",
                    "location": "Bognor Regis Campus, University of Chichester",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "PhD",
                    "duration": "84 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Senior Leadership Apprenticeship and Postgraduate Diploma with additional optional MBA Top Up",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy (including Social Work, Social Care and Early Childhood Studies)",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "PhD",
                    "duration": "84 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Biomechanics",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Physiology",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport, Exercise and Physical Education",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "PhD",
                    "duration": "84 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Performance Analysis",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre",
                    "university": "University of Chichester",
                    "location": "Bishop Otter Campus, Chichester",
                    "qualification": "MPhil (Res)",
                    "duration": "84 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "6843d942794a2b79282d88830528c1ed",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "de7161cc3dd36ff384f1e37e5349eb16",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Additional Needs and Disabilities (Children and Young People)",
                    "university": "City College Norwich (incorporating Easton College)",
                    "location": "Norfolk House",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Animal Science",
                    "university": "City College Norwich (incorporating Easton College)",
                    "location": "Easton College",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Childhood and Youth Studies",
                    "university": "City College Norwich (incorporating Easton College)",
                    "location": "Norfolk House",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Childhood Studies (Top-Up)",
                    "university": "City College Norwich (incorporating Easton College)",
                    "location": "Norfolk House",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Technologies for England - Cyber Security (HTQ)",
                    "university": "City College Norwich (incorporating Easton College)",
                    "location": "Main Campus (Ipswich Road)",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Technologies for England - Software Development and Programming (HTQ)",
                    "university": "City College Norwich (incorporating Easton College)",
                    "location": "Main Campus (Ipswich Road)",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "City College Norwich (incorporating Easton College)",
                    "location": "Norfolk House",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Health Studies (HTQ)",
                    "university": "City College Norwich (incorporating Easton College)",
                    "location": "Norfolk House",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Leadership and Management",
                    "university": "City College Norwich (incorporating Easton College)",
                    "location": "Norfolk House",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Mental Health Practice (HTQ)",
                    "university": "City College Norwich (incorporating Easton College)",
                    "location": "Norfolk House",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Professional Aviation Engineering Practice",
                    "university": "City College Norwich (incorporating Easton College)",
                    "location": "International Aviation Academy Norwich",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Professional Aviation Engineering Practice, with a Foundation Year",
                    "university": "City College Norwich (incorporating Easton College)",
                    "location": "International Aviation Academy Norwich",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "City College Norwich (incorporating Easton College)",
                    "location": "Easton College",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Psychology with Sociology",
                    "university": "City College Norwich (incorporating Easton College)",
                    "location": "Norfolk House",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Sport, Health and Exercise Science",
                    "university": "City College Norwich (incorporating Easton College)",
                    "location": "Easton College",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Wildlife and Conservation",
                    "university": "City College Norwich (incorporating Easton College)",
                    "location": "Easton College",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Youth Work",
                    "university": "City College Norwich (incorporating Easton College)",
                    "location": "Norfolk House",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Zoology",
                    "university": "City College Norwich (incorporating Easton College)",
                    "location": "Easton College",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Civil Engineering",
                    "university": "City College Norwich (incorporating Easton College)",
                    "location": "Main Campus (Ipswich Road)",
                    "qualification": "HNC",
                    "duration": "1.5 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Construction Management (Construction Design and Build Technician) (HTQ)",
                    "university": "City College Norwich (incorporating Easton College)",
                    "location": "Main Campus (Ipswich Road)",
                    "qualification": "HND",
                    "duration": "3 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Construction Management (Construction Design and Build Technician) (HTQ)",
                    "university": "City College Norwich (incorporating Easton College)",
                    "location": "Main Campus (Ipswich Road)",
                    "qualification": "HNC",
                    "duration": "1.5 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Construction Management (Construction Site Supervisor) (HTQ)",
                    "university": "City College Norwich (incorporating Easton College)",
                    "location": "Main Campus (Ipswich Road)",
                    "qualification": "HNC",
                    "duration": "1.5 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Construction Management (Construction Site Supervisor) (HTQ)",
                    "university": "City College Norwich (incorporating Easton College)",
                    "location": "Main Campus (Ipswich Road)",
                    "qualification": "HND",
                    "duration": "3 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Electrical and Electronic Engineering for England (HTQ)",
                    "university": "City College Norwich (incorporating Easton College)",
                    "location": "Main Campus (Ipswich Road)",
                    "qualification": "HNC",
                    "duration": "1.5 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Embedded Electronic Systems (Top Up)",
                    "university": "City College Norwich (incorporating Easton College)",
                    "location": "Main Campus (Ipswich Road)",
                    "qualification": "HND",
                    "duration": "1.5 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Electrical and Electronic) Top-Up",
                    "university": "City College Norwich (incorporating Easton College)",
                    "location": "Main Campus (Ipswich Road)",
                    "qualification": "HND",
                    "duration": "1.5 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Mechanical) Top Up",
                    "university": "City College Norwich (incorporating Easton College)",
                    "location": "Main Campus (Ipswich Road)",
                    "qualification": "HND",
                    "duration": "1.5 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Mechanical Engineering for England (HTQ)",
                    "university": "City College Norwich (incorporating Easton College)",
                    "location": "Main Campus (Ipswich Road)",
                    "qualification": "HNC",
                    "duration": "1.5 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "See entry requirements"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "5dffed58deed820cdf61c36ccedf34d1",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance (Foundation)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance (with Professional Placement)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Actuarial Science",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Actuarial Science (Foundation)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Actuarial Science (with Professional Placement)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Adult and Mental Health Nursing (pre-registration) MSc",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Adult Nursing",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Adult Nursing (pre-registration) MSc",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Aerospace Engineering (with Placement)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Aerospace Engineering (with Placement)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Aerospace Engineering with Foundation",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Banking and International Finance",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Banking and International Finance (with Professional Placement)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical and Healthcare Engineering",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Biomedical and Healthcare Engineering",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Biomedical and Healthcare Engineering (with placement)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Biomedical and Healthcare Engineering with Foundation",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Biomedical and Healthcare Engineering with placement",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics with Finance",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (Foundation)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (with Professional Placement)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Social Purpose",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Social Purpose (with Professional Placement)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management, Digital Innovation and Entrepreneurship",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management, Digital Innovation and Entrepreneurship (with Professional Placement)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business with Finance",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business with Finance (with Professional Placement)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business with Marketing",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business with Marketing (with Professional Placement)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children's Nursing",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Children's Nursing (pre-registration) MSc",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil and Infrastructure Engineering",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Civil and Infrastructure Engineering",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Civil and Infrastructure Engineering (with Placement)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Civil and Infrastructure Engineering (with placement)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Civil and Infrastructure Engineering with Foundation",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Communication, PR and Advertising",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Computer Science",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Computer Science (with Integrated Foundation Year)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computer Science Foundation Programme",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "Foundation Year",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computer Science with Cyber Security",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Computer Science with Cyber Security (with Integrated Foundation Year)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computer Science with Games Technology",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Computer Science with Games Technology (with Integrated Foundation Year)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computer Science with Games Technology (with Integrated Foundation Year)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computer Science with Games Technology (with placement)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Criminology",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Data Analytics and Actuarial Science",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Analytics and Actuarial Science (with Professional Placement)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "City, University of London",
                    "location": "City, University of London",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Data Science (with Integrated Foundation Year)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Diagnostic Radiography",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Economics",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Economics with Accounting",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Energy and Sustainability Engineering",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Energy and Sustainability Engineering",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Energy and Sustainability Engineering (with placement)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Energy and Sustainability Engineering (with placement)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Engineering Systems",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Engineering Systems",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Engineering Systems (with placement)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Engineering Systems with Foundation",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Engineering Systems with placement",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Engineering with Business",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Engineering with Business",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Engineering with Business (with placement)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "128"
                },
                {
                    "course_name": "English",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English with Creative Writing",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English with Publishing",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Finance",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance with Actuarial Science",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance with Actuarial Science (with Professional Placement)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Economics",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Graduate Entry Law LLB",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Healthcare: Nursing Associate (Direct Entry)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "History",
                    "university": "City, University of London",
                    "location": "City, University of London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "History and Politics",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Business (with One Year Study Abroad)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business (with term abroad and placement year)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business (with term abroad)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Political Economy",
                    "university": "City, University of London",
                    "location": "City, University of London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "International Politics",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "International Politics and Sociology",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Introduction to Diagnostic and Therapeutic Radiography",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Introduction to Health Sciences",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Introduction to Optometry",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Investment and Financial Risk Management",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Investment and Financial Risk Management (with Professional Placement)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Journalism, Politics and History",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Law LLB",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Mathematics",
                    "university": "City, University of London",
                    "location": "City, University of London",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Mathematics",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Mathematics (with Placement)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Mathematics (with Placement)",
                    "university": "City, University of London",
                    "location": "City, University of London",
                    "qualification": "MSci",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Mathematics and Finance",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Mathematics and Finance (with Integrated Foundation Year)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Mathematics and Finance (with Placement)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Mathematics Finance and Economics (with Integrated Foundation Year)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Mathematics Foundation Programme",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "Foundation Year",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Mathematics with Business",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Mathematics with Business (with Placement)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Mathematics with Data Science",
                    "university": "City, University of London",
                    "location": "City, University of London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Mathematics with Data Science",
                    "university": "City, University of London",
                    "location": "City, University of London",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Mathematics with Data Science (with Foundation year)",
                    "university": "City, University of London",
                    "location": "City, University of London",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Mathematics with Data Science (with Placement)",
                    "university": "City, University of London",
                    "location": "City, University of London",
                    "qualification": "MSci",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Mathematics with Data Science (with Placement)",
                    "university": "City, University of London",
                    "location": "City, University of London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Mathematics with Finance & Economics",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Mathematics with Finance and Economics (with Placement)",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Mechanical and Design Engineering",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Mechanical and Design Engineering",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Mechanical and Design Engineering with Foundation",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Mechanical and Design Engineering with placement",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Mechanical and Design Engineering with placement",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Media and Communications",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Media Production",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Media, Communication and Sociology",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Mental Health Nursing",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mental Health Nursing (pre-registration) MSc",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery",
                    "university": "City, University of London",
                    "location": "City, University of London",
                    "qualification": "BMid (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Music",
                    "university": "City, University of London",
                    "location": "Multiple Locations",
                    "qualification": "BMus (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Music Performance with Production",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Music, Sound and Technology",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Optometry",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "MOptom",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Politics",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Professional Dance and Musical Theatre",
                    "university": "City, University of London",
                    "location": "Urdang",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Psychology",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Psychology with Criminology",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Sociology",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Sociology with Psychology",
                    "university": "City, University of London",
                    "location": "City, University of London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Speech and Language Therapy",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Therapeutic Radiography and Oncology",
                    "university": "City, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Financial Technology and Innovation",
                    "university": "City, University of London",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media Management",
                    "university": "City, University of London",
                    "location": "City, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Management",
                    "university": "City, University of London",
                    "location": "City, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performing Arts Management and Practice",
                    "university": "City, University of London",
                    "location": "City, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Management and Finance",
                    "university": "City, University of London",
                    "location": "Bayes Business School (formerly Cass)",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        }
    },
    {
        "id": "fed2239a53e84ae5576a2f291efe341a",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Theology and Mission",
                    "university": "Cliff College",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Theology and Mission (Children, Youth and Family Ministry)",
                    "university": "Cliff College",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Theology and Mission (Ministry Among Older People)",
                    "university": "Cliff College",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Theology and Mission",
                    "university": "Cliff College",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Theology and Mission (Children, Youth and Family Ministry)",
                    "university": "Cliff College",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Theology and Mission (Ministry Among Older People)",
                    "university": "Cliff College",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Theology and Mission",
                    "university": "Cliff College",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Missiology",
                    "university": "Cliff College",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Mission",
                    "university": "Cliff College",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "0f2bf493f06e197ca7dfb3f735388a91",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "BA (Hons) Early Childhood Studies",
                    "university": "University Centre Colchester at Colchester Institute",
                    "location": "Colchester Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business and Management",
                    "university": "University Centre Colchester at Colchester Institute",
                    "location": "Colchester Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Counselling and Psychotherapy",
                    "university": "University Centre Colchester at Colchester Institute",
                    "location": "Colchester Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Early Years and Primary Studies",
                    "university": "University Centre Colchester at Colchester Institute",
                    "location": "Colchester Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Person-Centred Counselling",
                    "university": "University Centre Colchester at Colchester Institute",
                    "location": "Colchester Site",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Construction Management (Architectural Technology)",
                    "university": "University Centre Colchester at Colchester Institute",
                    "location": "Colchester Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Construction Management (Quantity Surveying)",
                    "university": "University Centre Colchester at Colchester Institute",
                    "location": "Colchester Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Construction Management (Site Management)",
                    "university": "University Centre Colchester at Colchester Institute",
                    "location": "Colchester Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "80"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": [
                {
                    "course_name": "Education",
                    "university": "University Centre Colchester at Colchester Institute",
                    "location": "Colchester Institute",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "bf50d270420dffde239f3176016cd82c",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "715d444656ef3013d6ee8dc631a05d7b",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "227398120abdaa76b53804fd5fd58bb5",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "71b31ba850848c82fe0a634e3cab512f",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Esports Coaching & Management",
                    "university": "College of Esports",
                    "location": "College of Esports",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Esports Coaching & Management (with Foundation Year)",
                    "university": "College of Esports",
                    "location": "College of Esports",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Games Art",
                    "university": "College of Esports",
                    "location": "College of Esports",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Games Art with Foundation Year",
                    "university": "College of Esports",
                    "location": "College of Esports",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Games Design",
                    "university": "College of Esports",
                    "location": "College of Esports",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Games Design with Foundation Year",
                    "university": "College of Esports",
                    "location": "College of Esports",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Games Development",
                    "university": "College of Esports",
                    "location": "College of Esports",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Games Development with Foundation Year",
                    "university": "College of Esports",
                    "location": "College of Esports",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "International Esports Business",
                    "university": "College of Esports",
                    "location": "College of Esports",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "International Esports Business & Digital Marketing",
                    "university": "College of Esports",
                    "location": "College of Esports",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "International Esports Business & Digital Marketing (with Foundation Year)",
                    "university": "College of Esports",
                    "location": "College of Esports",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "International Esports Business & Digital Media",
                    "university": "College of Esports",
                    "location": "College of Esports",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "International Esports Business & Digital Media (with Foundation Year)",
                    "university": "College of Esports",
                    "location": "College of Esports",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "46"
                },
                {
                    "course_name": "International Esports Business & Events Management",
                    "university": "College of Esports",
                    "location": "College of Esports",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "International Esports Business & Events Management (with Foundation Year)",
                    "university": "College of Esports",
                    "location": "College of Esports",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "International Esports Business (with Foundation Year)",
                    "university": "College of Esports",
                    "location": "College of Esports",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "55467d6da8d28911763ba5a24ddb0c5a",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "f6f8d4a37e9bda92fd1089fb69825544",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Agriculture",
                    "university": "Cornwall College",
                    "location": "Duchy College Stoke Climsland",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Agriculture (Level 6 only)",
                    "university": "Cornwall College",
                    "location": "Duchy College Stoke Climsland",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Behaviour and Welfare",
                    "university": "Cornwall College",
                    "location": "Newquay University Centre Cornwall College",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Animal Behaviour and Welfare",
                    "university": "Cornwall College",
                    "location": "Newquay University Centre Cornwall College",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Animal Behaviour and Welfare with Science Gateway",
                    "university": "Cornwall College",
                    "location": "Newquay University Centre Cornwall College",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Health and Management",
                    "university": "Cornwall College",
                    "location": "Duchy College Stoke Climsland",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Applied Animal Health (Level 6 only)",
                    "university": "Cornwall College",
                    "location": "Duchy College Stoke Climsland",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Equitation Science (Level 6 only)",
                    "university": "Cornwall College",
                    "location": "Duchy College Stoke Climsland",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Marine Zoology",
                    "university": "Cornwall College",
                    "location": "Newquay University Centre Cornwall College",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Applied Marine Zoology (Level 6 only)",
                    "university": "Cornwall College",
                    "location": "Newquay University Centre Cornwall College",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Zoology (Level 6 only)",
                    "university": "Cornwall College",
                    "location": "Newquay University Centre Cornwall College",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Zoology and Conservation",
                    "university": "Cornwall College",
                    "location": "Newquay University Centre Cornwall College",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Applied Zoology and Conservation (inc. sandwich year)",
                    "university": "Cornwall College",
                    "location": "Newquay University Centre Cornwall College",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Art & Design Practice",
                    "university": "Cornwall College",
                    "location": "Cornwall College Camborne",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Art & Design Practice (Level 6 only)",
                    "university": "Cornwall College",
                    "location": "Cornwall College Camborne",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Coastal Adventure",
                    "university": "Cornwall College",
                    "location": "Newquay University Centre Cornwall College",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Counselling Psychology with Criminology",
                    "university": "Cornwall College",
                    "location": "Cornwall College Camborne",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Environmental Resource Management (Level 6 only)",
                    "university": "Cornwall College",
                    "location": "Newquay University Centre Cornwall College",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Equitation, Training and Behaviour",
                    "university": "Cornwall College",
                    "location": "Duchy College Stoke Climsland",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Games Design for Industry",
                    "university": "Cornwall College",
                    "location": "Cornwall College Camborne",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Games Design for Industry (top up)",
                    "university": "Cornwall College",
                    "location": "Cornwall College Camborne",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Garden & Landscape Design",
                    "university": "Cornwall College",
                    "location": "Cornwall College University Centre at the Eden Project",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Garden & Landscape Design",
                    "university": "Cornwall College",
                    "location": "Cornwall College University Centre at the Eden Project",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Golf Club Management",
                    "university": "Cornwall College",
                    "location": "Lindfield",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Horticulture",
                    "university": "Cornwall College",
                    "location": "Cornwall College University Centre at the Eden Project",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Horticulture (Garden & Landscape Design)",
                    "university": "Cornwall College",
                    "location": "Cornwall College University Centre at the Eden Project",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Horticulture (Garden & Landscape Design) (Level 6 only)",
                    "university": "Cornwall College",
                    "location": "Cornwall College University Centre at the Eden Project",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Horticulture (Plant Science)",
                    "university": "Cornwall College",
                    "location": "Cornwall College University Centre at the Eden Project",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Horticulture (Plant Science) (Level 6 only)",
                    "university": "Cornwall College",
                    "location": "Cornwall College University Centre at the Eden Project",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management (with Professional Golf)",
                    "university": "Cornwall College",
                    "location": "Duchy College Stoke Climsland",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "International Golf Management",
                    "university": "Cornwall College",
                    "location": "Lindfield",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "60"
                },
                {
                    "course_name": "International Golf Management",
                    "university": "Cornwall College",
                    "location": "Lindfield",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "International Golf Management (Level 6 only)",
                    "university": "Cornwall College",
                    "location": "Duchy College Stoke Climsland",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Biology with Conservation",
                    "university": "Cornwall College",
                    "location": "Newquay University Centre Cornwall College",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Marine Biology with Conservation",
                    "university": "Cornwall College",
                    "location": "Newquay University Centre Cornwall College",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Marine Biology with Conservation with Science Gateway",
                    "university": "Cornwall College",
                    "location": "Newquay University Centre Cornwall College",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Biology with Oceanography",
                    "university": "Cornwall College",
                    "location": "Newquay University Centre Cornwall College",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Marine Sports Science (Level 6 only)",
                    "university": "Cornwall College",
                    "location": "Newquay University Centre Cornwall College",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Operational Yacht Science",
                    "university": "Cornwall College",
                    "location": "Falmouth Marine School",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Professional Superyacht Engineer",
                    "university": "Cornwall College",
                    "location": "Falmouth Marine School",
                    "qualification": "FdSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Soil Science",
                    "university": "Cornwall College",
                    "location": "Cornwall College University Centre at the Eden Project",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Surf Science",
                    "university": "Cornwall College",
                    "location": "Newquay University Centre Cornwall College",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Surf Science",
                    "university": "Cornwall College",
                    "location": "Newquay University Centre Cornwall College",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Surf Science with Science Gateway",
                    "university": "Cornwall College",
                    "location": "Newquay University Centre Cornwall College",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Tournament Golf",
                    "university": "Cornwall College",
                    "location": "Duchy College Stoke Climsland",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Tournament Golf",
                    "university": "Cornwall College",
                    "location": "Duchy College Stoke Climsland",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "60"
                },
                {
                    "course_name": "Tournament Golf (Level 6 only)",
                    "university": "Cornwall College",
                    "location": "Duchy College Stoke Climsland",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology with  Ecology and Conservation",
                    "university": "Cornwall College",
                    "location": "Newquay University Centre Cornwall College",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Zoology with Ecology and Conservation",
                    "university": "Cornwall College",
                    "location": "Newquay University Centre Cornwall College",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Zoology with Ecology and Conservation with Science Gateway",
                    "university": "Cornwall College",
                    "location": "Newquay University Centre Cornwall College",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Agriculture",
                    "university": "Cornwall College",
                    "location": "Duchy College Stoke Climsland",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Animal Behaviour and Welfare",
                    "university": "Cornwall College",
                    "location": "Newquay University Centre Cornwall College",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Animal Behaviour and Welfare",
                    "university": "Cornwall College",
                    "location": "Newquay University Centre Cornwall College",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Applied Animal Health (Level 6 only)",
                    "university": "Cornwall College",
                    "location": "Duchy College Stoke Climsland",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Zoology (Level 6 only)",
                    "university": "Cornwall College",
                    "location": "Newquay University Centre Cornwall College",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art & Design Practice",
                    "university": "Cornwall College",
                    "location": "Cornwall College Camborne",
                    "qualification": "FdA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Art & Design Practice (Level 6 only)",
                    "university": "Cornwall College",
                    "location": "Cornwall College Camborne",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Garden & Landscape Design",
                    "university": "Cornwall College",
                    "location": "Cornwall College University Centre at the Eden Project",
                    "qualification": "HND",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Garden & Landscape Design",
                    "university": "Cornwall College",
                    "location": "Cornwall College University Centre at the Eden Project",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Golf Club Management",
                    "university": "Cornwall College",
                    "location": "Lindfield",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Horticulture",
                    "university": "Cornwall College",
                    "location": "Cornwall College University Centre at the Eden Project",
                    "qualification": "FdSc",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Horticulture (Garden & Landscape Design)",
                    "university": "Cornwall College",
                    "location": "Cornwall College University Centre at the Eden Project",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Horticulture (Plant Science)",
                    "university": "Cornwall College",
                    "location": "Cornwall College University Centre at the Eden Project",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Marine Biology with Conservation",
                    "university": "Cornwall College",
                    "location": "Newquay University Centre Cornwall College",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Soil Science",
                    "university": "Cornwall College",
                    "location": "Cornwall College University Centre at the Eden Project",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Surf Science",
                    "university": "Cornwall College",
                    "location": "Newquay University Centre Cornwall College",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Zoology with Ecology and Conservation",
                    "university": "Cornwall College",
                    "location": "Newquay University Centre Cornwall College",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "1b47cd34ce1f90dd950dba126ca3773e",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "History of Art",
                    "university": "Courtauld Institute of Art, University of London",
                    "location": "Courtauld Institute of Art",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Art and Business",
                    "university": "Courtauld Institute of Art, University of London",
                    "location": "Courtauld Institute of Art",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        }
    },
    {
        "id": "9ff55416635b657a42a6fad95604f435",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "7b25ffb27388afdf28e1b5bd7b6fd4c0",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Advanced Air Mobility Systems",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Lightweight and Composite Structures",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Materials: Engineering and Industrial Applications",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Motorsport Engineering",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Motorsport Mechatronics",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Computational Engineering",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Dynamics",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Manufacturing",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Materials",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Propulsion - Thermal Power and Propulsion",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Air Transport Management",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Airport Planning and Management",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Artificial Intelligence MSc",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astronautics and Space Engineering",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive Engineering",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive Mechatronics",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Autonomous Vehicle Dynamics and Control",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aviation Digital Technology Management",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Avionic Systems Design option - MSc in Aerospace Vehicle Design",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational and Software Techniques in Engineering",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Fluid Dynamics",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Intelligence for Data Analytics option - MSc in Computational and Software Techniques in Engineering",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer and Machine Vision - Computational Software Techniques in Engineering",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Connected and Autonomous Vehicle Engineering (Automotive)",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Engineering Design option MSc Computational and Software Techniques in Engineering",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering and Management of Manufacturing Systems",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gas Turbine Technology option - Thermal Power and Propulsion",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Product Development and Management",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Maintenance Engineering and Asset Management",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and Information Systems",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Manufacturing Technology and Management",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Metal Additive Manufacturing",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc by Research in Environmental Science / Agrifood",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Power, Propulsion and the Environment - Thermal Power and Propulsion",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pre-Masters in Engineering",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "Master's Diploma",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Rotating Machinery, Engineering and Management - Thermal Power and Propulsion",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Safety and Human Factors in Aviation MSc",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering for Technical Computing option - MSc in Computational and Software Techniques in Engineering",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Thermal Power and Propulsion",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Welding Engineering",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Air Mobility Systems",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Materials: Engineering and Industrial Applications",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Computational Engineering",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Dynamics",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Manufacturing",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Materials",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Airworthiness",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Artificial Intelligence MSc",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astronautics and Space Engineering",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aviation Digital Technology Management",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational and Software Techniques in Engineering",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Fluid Dynamics",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Intelligence for Data Analytics option - MSc in Computational and Software Techniques in Engineering",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer and Machine Vision - Computational Software Techniques in Engineering",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital and Technology Solutions Apprenticeship MSc",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital and Technology Solutions MSc",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Engineering Design option MSc Computational and Software Techniques in Engineering",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering and Management of Manufacturing Systems",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Product Development and Management",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Maintenance Engineering and Asset Management",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and Information Systems",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Manufacturing Technology and Management",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Metal Additive Manufacturing",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc by Research in Environmental Science / Agrifood",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Safety and Accident Investigation MSc",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering for Technical Computing option - MSc in Computational and Software Techniques in Engineering",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Through-Life System Sustainment",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Welding Engineering",
                    "university": "Cranfield University",
                    "location": "Cranfield Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "714a6f652fef437068586aea0bfe62fd",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Air Transport Management (Top-up)",
                    "university": "Craven College",
                    "location": "The Aviation Academy",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Animal Husbandry & Welfare",
                    "university": "Craven College",
                    "location": "Aireville Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Animal Husbandry & Welfare (Top-up)",
                    "university": "Craven College",
                    "location": "Aireville Campus",
                    "qualification": "BA/BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Applied Equine Management",
                    "university": "Craven College",
                    "location": "Auction Mart Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Applied Equine Management (Top-up)",
                    "university": "Craven College",
                    "location": "Auction Mart Campus",
                    "qualification": "BA/BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Aviation Management & Operations",
                    "university": "Craven College",
                    "location": "The Aviation Academy",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Aviation Management & Operations (with Pilot Studies)",
                    "university": "Craven College",
                    "location": "The Aviation Academy",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Conservation Management (Top up)",
                    "university": "Craven College",
                    "location": "Auction Mart Campus",
                    "qualification": "BA/BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Esports",
                    "university": "Craven College",
                    "location": "Aireville Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Performing Arts (Acting)",
                    "university": "Craven College",
                    "location": "Aireville Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sport (Community Sport & Physical Activities)",
                    "university": "Craven College",
                    "location": "Aireville Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Wildlife & Conservation Management",
                    "university": "Craven College",
                    "location": "Aireville Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Young Children's Learning & Development",
                    "university": "Craven College",
                    "location": "Aireville Campus",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Young Children's Learning & Development (Top-up)",
                    "university": "Craven College",
                    "location": "Aireville Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "ba2a47771f7031c61b0be8ee4952cced",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Business & Management",
                    "university": "Croydon University Centre",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Esports",
                    "university": "Croydon University Centre",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "1-48"
                },
                {
                    "course_name": "Games Development",
                    "university": "Croydon University Centre",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Public Health & Social Care",
                    "university": "Croydon University Centre",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Construction & Built Environment",
                    "university": "Croydon University Centre",
                    "location": "University Centre Croydon",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "a13193b05ffe59a5de378849324cf37f",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Animation and Visual Effects",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Brampton Road",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Biomedical Science (with Integrated Foundation Year)",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of Cumbria",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business Management (with Integrated Foundation Year)",
                    "university": "University of Cumbria",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business Management Top Up",
                    "university": "University of Cumbria",
                    "location": "Lancaster",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Cumbria",
                    "location": "Multiple Locations",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Science with Sandwich Placement",
                    "university": "University of Cumbria",
                    "location": "Multiple Locations",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Criminology and Forensic Investigation",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Criminology and Forensic Investigation with Integrated Foundation Year",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Diagnostic Radiography",
                    "university": "University of Cumbria",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Diagnostic Radiography (Top up)",
                    "university": "University of Cumbria",
                    "location": "Lancaster",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecology and Wildlife Conservation",
                    "university": "University of Cumbria",
                    "location": "Ambleside",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Ecology and Wildlife Conservation Integrated Foundation Year with Sandwich Placement",
                    "university": "University of Cumbria",
                    "location": "Ambleside",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Ecology and Wildlife Conservation with Integrated Foundation Year",
                    "university": "University of Cumbria",
                    "location": "Ambleside",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Ecology and Wildlife Conservation with Sandwich Placement Year",
                    "university": "University of Cumbria",
                    "location": "Ambleside",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Education Studies",
                    "university": "University of Cumbria",
                    "location": "London - Tower Hamlets",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-88"
                },
                {
                    "course_name": "Education Studies",
                    "university": "University of Cumbria",
                    "location": "London - Tower Hamlets",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Education Studies (Top up)",
                    "university": "University of Cumbria",
                    "location": "London - Tower Hamlets",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Environmental Science with Integrated Foundation Year",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Film and Television",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Brampton Road",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Film and Television (with integrated foundation year)",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Brampton Road",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Brampton Road",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Fine Art (with integrated foundation year)",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Brampton Road",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Forest  Management",
                    "university": "University of Cumbria",
                    "location": "Ambleside",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Forest Management (with Sandwich Year)",
                    "university": "University of Cumbria",
                    "location": "Ambleside",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Forestry (Top-up)",
                    "university": "University of Cumbria",
                    "location": "Ambleside",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Design",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Brampton Road",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Games Design (with integrated foundation year)",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Brampton Road",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Cumbria",
                    "location": "Ambleside",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Geography (with integrated foundation year)",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Global Business Management (top-up)",
                    "university": "University of Cumbria",
                    "location": "London - Tower Hamlets",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Brampton Road",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Graphic Design (with integrated foundation year)",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Brampton Road",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "University of Cumbria",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Health and Social Care (with Integrated Foundation Year)",
                    "university": "University of Cumbria",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Illustration",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Brampton Road",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Illustration (with integrated foundation year)",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Brampton Road",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "International Business and Communication Top Up",
                    "university": "University of Cumbria",
                    "location": "Lancaster",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management",
                    "university": "University of Cumbria",
                    "location": "Lancaster",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "International Business Management (Top-up)",
                    "university": "University of Cumbria",
                    "location": "Lancaster",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management with Integrated Foundation Year",
                    "university": "University of Cumbria",
                    "location": "Lancaster",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Law",
                    "university": "University of Cumbria",
                    "location": "Multiple Locations",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Law (with integrated foundation year)",
                    "university": "University of Cumbria",
                    "location": "Multiple Locations",
                    "qualification": "LLB",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Mechanical Engineering (with Integrated Foundation Year)",
                    "university": "University of Cumbria",
                    "location": "Barrow-in-Furness",
                    "qualification": "BEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Mechanical Engineering with Year in Industry (with Integrated Foundation Year)",
                    "university": "University of Cumbria",
                    "location": "Barrow-in-Furness",
                    "qualification": "BEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Midwifery",
                    "university": "University of Cumbria",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "MSc Nursing/Registered Nurse: Adult Nursing",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Nursing/Registered Nurse: Mental Health Nursing",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing Associate",
                    "university": "University of Cumbria",
                    "location": "Multiple Locations",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Nursing/Registered Nurse: Adult Nursing",
                    "university": "University of Cumbria",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Nursing/Registered Nurse: Children's Nursing",
                    "university": "University of Cumbria",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Nursing/Registered Nurse: Learning Disabilities Nursing",
                    "university": "University of Cumbria",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Nursing/Registered Nurse: Mental Health Nursing",
                    "university": "University of Cumbria",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "University of Cumbria",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Outdoor Adventure and Environmental Studies",
                    "university": "University of Cumbria",
                    "location": "Ambleside",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Outdoor Adventure and Environmental Studies with Integrated Foundation Year",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Outdoor and Experiential Learning (Top-up)",
                    "university": "University of Cumbria",
                    "location": "Ambleside",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Outdoor Leadership",
                    "university": "University of Cumbria",
                    "location": "Ambleside",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Outdoor Leadership with Integrated Foundation Year",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Paramedic Science",
                    "university": "University of Cumbria",
                    "location": "Lancaster",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Photography",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Brampton Road",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Photography (with integrated foundation year)",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Brampton Road",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Pre-Hospital Emergency Care",
                    "university": "University of Cumbria",
                    "location": "Lancaster",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Primary and Early Years Education (3-11) with QTS",
                    "university": "University of Cumbria",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Primary Education: Inclusion with SEND with QTS (5-11 Years)",
                    "university": "University of Cumbria",
                    "location": "Lancaster",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Professional Policing (with integrated foundation year)",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Project Management (Top up)",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Psychology (with integrated foundation year)",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Psychology with Counselling and Psychotherapy",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Psychology with Counselling and Psychotherapy Integrated Foundation Year",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Security, Intelligence and Investigative Practice",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Security, Intelligence and Investigative Practice (with integrated foundation year)",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Sport and Exercise Therapy Top Up",
                    "university": "University of Cumbria",
                    "location": "Lancaster",
                    "qualification": "BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Coaching and Physical Education",
                    "university": "University of Cumbria",
                    "location": "Lancaster",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Sport Coaching and Physical Education (Top up)",
                    "university": "University of Cumbria",
                    "location": "Lancaster",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Coaching and Physical Education (with integrated foundation year)",
                    "university": "University of Cumbria",
                    "location": "Lancaster",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sport Conditioning and Performance Analysis",
                    "university": "University of Cumbria",
                    "location": "Lancaster",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Sport Conditioning and Performance Analysis with Integrated Foundation Year",
                    "university": "University of Cumbria",
                    "location": "Lancaster",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sport Rehabilitation",
                    "university": "University of Cumbria",
                    "location": "Lancaster",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Sport Rehabilitation (with integrated foundation year)",
                    "university": "University of Cumbria",
                    "location": "Lancaster",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "User Experience and User Interface (UX/UI) Design",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Brampton Road",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Wildlife Media",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Brampton Road",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Wildlife Media (with integrated foundation year)",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Brampton Road",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Woodland Ecology and  Conservation (with Sandwich placement)",
                    "university": "University of Cumbria",
                    "location": "Ambleside",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Woodland Ecology and Conservation",
                    "university": "University of Cumbria",
                    "location": "Ambleside",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Woodland Ecology and Conservation (with Integrated Foundation Year)",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Zoology",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Zoology (with integrated foundation year)",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Zoology (with Sandwich Year)",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Psychology",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Zoology (with Sandwich Year)",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96-112"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Creative Practice",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Brampton Road",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology, Risk and Safeguarding",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "MSci",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management",
                    "university": "University of Cumbria",
                    "location": "Lancaster",
                    "qualification": "MSci",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Literature Romanticism and the English Lake District",
                    "university": "University of Cumbria",
                    "location": "Ambleside",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration (London)",
                    "university": "University of Cumbria",
                    "location": "London - Tower Hamlets",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master's of Business Administration (Lancaster)",
                    "university": "University of Cumbria",
                    "location": "Lancaster",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Imaging (Ultrasound)",
                    "university": "University of Cumbria",
                    "location": "Lancaster",
                    "qualification": "MSci",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery (Pre Registration)",
                    "university": "University of Cumbria",
                    "location": "Multiple Locations",
                    "qualification": "MSci",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing/Registered Nurse: Adult Nursing",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "MSci",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing/Registered Nurse: Mental Health Nursing",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "MSci",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Outdoor and Experiential Learning (Ambleside)",
                    "university": "University of Cumbria",
                    "location": "Ambleside",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Outdoor and Experiential Learning (London)",
                    "university": "University of Cumbria",
                    "location": "London - Tower Hamlets",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy (Pre-Registration)",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "MSci",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Education with QTS: General Primary (5-11) Years (PGCE)",
                    "university": "University of Cumbria",
                    "location": "Multiple Locations",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Education with QTS: General Primary with Maths (PGCE)",
                    "university": "University of Cumbria",
                    "location": "Lancaster",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Education with QTS: General Primary with Physical Education (PGCE)",
                    "university": "University of Cumbria",
                    "location": "Lancaster",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Education with QTS: Lower Primary (3-7) Years (PGCE)",
                    "university": "University of Cumbria",
                    "location": "Multiple Locations",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Education: Inclusion with SEND (PGCE)",
                    "university": "University of Cumbria",
                    "location": "Lancaster",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology: Environment, Health and Wellbeing",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology: Interpersonal Violence and Abuse Studies",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "MSci",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology: Legal and Criminological",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology: Research Methods and Statistics",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Art and Design with QTS (PGCE)",
                    "university": "University of Cumbria",
                    "location": "Multiple Locations",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Biology with QTS (PGCE)",
                    "university": "University of Cumbria",
                    "location": "Multiple Locations",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Chemistry with QTS (PGCE)",
                    "university": "University of Cumbria",
                    "location": "Multiple Locations",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Computer Science and ICT with QTS (PGCE)",
                    "university": "University of Cumbria",
                    "location": "Multiple Locations",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary English with QTS (PGCE)",
                    "university": "University of Cumbria",
                    "location": "Multiple Locations",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Geography with QTS (PGCE)",
                    "university": "University of Cumbria",
                    "location": "Multiple Locations",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary History with QTS (PGCE)",
                    "university": "University of Cumbria",
                    "location": "Multiple Locations",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Mathematics with QTS (PGCE)",
                    "university": "University of Cumbria",
                    "location": "Multiple Locations",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Modern Foreign Languages with QTS (PGCE)",
                    "university": "University of Cumbria",
                    "location": "Multiple Locations",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Physical Education with QTS (PGCE)",
                    "university": "University of Cumbria",
                    "location": "Multiple Locations",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Physics with QTS (PGCE)",
                    "university": "University of Cumbria",
                    "location": "School Direct",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Religious Education with QTS (PGCE)",
                    "university": "University of Cumbria",
                    "location": "Multiple Locations",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Tourism Management",
                    "university": "University of Cumbria",
                    "location": "Ambleside",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Tourism Management",
                    "university": "University of Cumbria",
                    "location": "Ambleside",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Counselling and Psychotherapy",
                    "university": "University of Cumbria",
                    "location": "Lancaster",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Doctor of Business Administration",
                    "university": "University of Cumbria",
                    "location": "Ambleside",
                    "qualification": "DBA",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Professional Practice",
                    "university": "University of Cumbria",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Professional Practice (With Pathways)",
                    "university": "University of Cumbria",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management",
                    "university": "University of Cumbria",
                    "location": "Lancaster",
                    "qualification": "MSci",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration (London)",
                    "university": "University of Cumbria",
                    "location": "London - Tower Hamlets",
                    "qualification": "MBA",
                    "duration": "1.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master's of Business Administration (Lancaster)",
                    "university": "University of Cumbria",
                    "location": "Lancaster",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Imaging",
                    "university": "University of Cumbria",
                    "location": "Lancaster",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Imaging (Ultrasound)",
                    "university": "University of Cumbria",
                    "location": "Lancaster",
                    "qualification": "MSci",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Imaging: Magnetic Resonance Imaging",
                    "university": "University of Cumbria",
                    "location": "Lancaster",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Non-QTS Online (PGCE)",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "University of Cumbria",
                    "location": "Lancaster",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Outdoor and Experiential Learning (Ambleside)",
                    "university": "University of Cumbria",
                    "location": "Ambleside",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Leadership",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "MSci",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology: Environment, Health and Wellbeing",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology: Interpersonal Violence and Abuse Studies",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "MSci",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology: Legal and Criminological",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology: Research Methods and Statistics",
                    "university": "University of Cumbria",
                    "location": "Carlisle - Fusehill Street",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Physics with QTS (PGCE)",
                    "university": "University of Cumbria",
                    "location": "Lancaster",
                    "qualification": "PGCE",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Tourism Management",
                    "university": "University of Cumbria",
                    "location": "Ambleside",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "0d0592d9003bcc406c9ba0b1a9dd4bf7",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "ae68ae2b139325653831d2b59f3f0ff6",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Electronic Music Production",
                    "university": "dBs Institute",
                    "location": "DBS Institute Bristol",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Electronic Music Production",
                    "university": "dBs Institute",
                    "location": "DBS Institute Plymouth",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Electronic Music Production",
                    "university": "dBs Institute",
                    "location": "DBS Institute Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Game Art",
                    "university": "dBs Institute",
                    "location": "DBS Institute Bristol",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Game Art",
                    "university": "dBs Institute",
                    "location": "DBS Institute Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Live Sound",
                    "university": "dBs Institute",
                    "location": "DBS Institute Bristol",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Live Sound",
                    "university": "dBs Institute",
                    "location": "DBS Institute Manchester",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Music & Sound for Film & TV",
                    "university": "dBs Institute",
                    "location": "DBS Institute Bristol",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Music & Sound for Film & TV",
                    "university": "dBs Institute",
                    "location": "DBS Institute Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Music Production & Sound Engineering",
                    "university": "dBs Institute",
                    "location": "DBS Institute Bristol",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Music Production & Sound Engineering",
                    "university": "dBs Institute",
                    "location": "DBS Institute Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Music Production & Sound Engineering",
                    "university": "dBs Institute",
                    "location": "DBS Institute Plymouth",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "989e38ec6373ed680b55940abedc839e",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "eb4e0754b62c17ecae3cd96ca125695f",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Animal Management",
                    "university": "Derby College",
                    "location": "Broomfield Hall",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Animal Management (Top Up)",
                    "university": "Derby College",
                    "location": "Broomfield Hall",
                    "qualification": "BA/BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Animal Management (Zoo and Wildlife)",
                    "university": "Derby College",
                    "location": "Broomfield Hall",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Animal Management (Zoo and Wildlife) (Top Up)",
                    "university": "Derby College",
                    "location": "Broomfield Hall",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Justice (Security and Offender Management)",
                    "university": "Derby College",
                    "location": "Broomfield Hall",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Engineering (Top Up)",
                    "university": "Derby College",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Equine Science, Management and Training",
                    "university": "Derby College",
                    "location": "Broomfield Hall",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Equine Science, Management and Training (top up)",
                    "university": "Derby College",
                    "location": "Broomfield Hall",
                    "qualification": "BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "General Engineering",
                    "university": "Derby College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrated Engineering",
                    "university": "Derby College",
                    "location": "Main Site",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Security and Offender Management (Top-Up)",
                    "university": "Derby College",
                    "location": "Broomfield Hall",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Software Development",
                    "university": "Derby College",
                    "location": "Joseph Wright Centre",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sports Coaching - Performance and Education",
                    "university": "Derby College",
                    "location": "Broomfield Hall",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "46ab8e4d97d28ae9f3fe1ec43f726352",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "ad456172e48c249052e93601b42b2241",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Animal Management",
                    "university": "Dudley College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Animal Management",
                    "university": "Dudley College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Computer Games Design",
                    "university": "Dudley College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Computer Games Design",
                    "university": "Dudley College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sport & Exercise Science",
                    "university": "Dudley College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sport & Exercise Science",
                    "university": "Dudley College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "0192718951494a15a04353b3cf1d402e",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accountancy",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BAcc (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accountancy",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BAcc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accountancy and Mathematics",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Adult Nursing",
                    "university": "University of Dundee",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Adult Nursing",
                    "university": "University of Dundee",
                    "location": "Multiple Locations",
                    "qualification": "MSc (PG)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Adult Nursing",
                    "university": "University of Dundee",
                    "location": "Multiple Locations",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anatomical Sciences",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animation",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BDes (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Finance in Practice",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MArch Studies",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art & Design (General Foundation)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art & Philosophy",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry (with a year in industry)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological and Biomedical Sciences (joint degree with National University of Singapore)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Chemistry and Drug Discovery",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Chemistry and Drug Discovery (with a year in industry)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences (with a year in industry)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences (with a year in industry)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics with Marketing",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics with Marketing",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics with Marketing and Geography",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics with Marketing and History",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics with Marketing and Politics",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics with Marketing and Psychology",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics with Marketing with French",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics with Marketing with Spanish",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management in Practice",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Child Nursing",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Child Nursing",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Child Nursing",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil and Structural Engineering",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil and Structural Engineering",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Community Education",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Data Science and AI)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Data Science and AI) with Industrial Placement",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (User Experience and Design)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (User Experience and Design) with Industrial Placement",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Industrial Placement",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and History",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and International Relations",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Politics",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with French",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Spanish",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Creative Writing",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and European Languages",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Film Studies",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and History",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Mathematics",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Philosophy",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Politics",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Psychology",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English with French",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English with Spanish",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science (Associate Articulation Route with Dundee and Angus College)",
                    "university": "University of Dundee",
                    "location": "Dundee and Angus College",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science and Geography",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Sustainability",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Sustainability and Geography",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "European Philosophy",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "European Philosophy with French",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "European Philosophy with Spanish",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BFin (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Economics",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Economics",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Economics with French",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Economics with Spanish",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art (Digital Media, Drawing and Painting, Printmaking, Sculpture)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Anthropology",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gateway to Medicine",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MB ChB",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography and Economics",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography and Environmental Science",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography and History",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography and Planning",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography and Politics",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography and Psychology",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with French",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with Spanish",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geopolitics",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BDes (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and European Languages",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and International Relations",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Philosophy",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Politics",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Psychology",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History with French",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History with Spanish",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Humanities (Associate Articulation Route with Dundee and Angus College)",
                    "university": "University of Dundee",
                    "location": "Dundee and Angus College",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Illustration",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BDes (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior & Environmental Design",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BDes (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Environmental Sustainability",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and International Relations",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Management",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business in Practice",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with French",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with French",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Marketing",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Marketing",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Marketing in Practice",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Spanish",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Spanish",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Finance",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BIFin (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and European Languages",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Politics",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Islamic Accounting and Finance",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Jewellery & Metal Design",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BDes (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (Eng/NI)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (Eng/NI) - LLB  - Accelerated, Graduate Entry",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "LLB",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (Eng/NI) with Energy Law",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (Eng/NI) with French",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (Eng/NI) with Spanish",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (Scots and English Dual Qualifying) with Energy Law",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (Scots and English) Dual Qualifying",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (Scots)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (Scots) - LLB (Accelerated, Graduate Entry)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "LLB",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (Scots) with Energy Law",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (Scots) with French",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (Scots) with Spanish",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Liberal Arts",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Life Sciences with Dundee and Angus College",
                    "university": "University of Dundee",
                    "location": "Dundee and Angus College",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA Arts & Social Sciences",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (UG)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical Biology",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MMath",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Astrophysics",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Economics",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Financial Economics",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Physics",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Physics",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Psychology",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Renewables",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health Nursing",
                    "university": "University of Dundee",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health Nursing",
                    "university": "University of Dundee",
                    "location": "Multiple Locations",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health Nursing",
                    "university": "University of Dundee",
                    "location": "Multiple Locations",
                    "qualification": "MSc (PG)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology (with a year in industry)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience (with a year in industry)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oral Health Sciences",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology (with a year in industry)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and European Languages",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Film",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and International Relations",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Politics",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Psychology",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy with French",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy with Spanish",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Renewable Energy Science",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Renewable Energy Science",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and European Languages",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Psychology",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics with French",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics with Spanish",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Education (PGDE)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with French",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with French",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with Spanish",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with Spanish",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scottish Historical Studies",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scottish Historical Studies with French",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scottish Historical Studies with Spanish",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Education (Chemistry) PGDE",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Education (Dual Qualification) PGDE",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Education (English) PGDE",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Education (Home Economics) PGDE",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Education (Mathematics) PGDE",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Education (Physics) PGDE",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Engineering with Architecture",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Textile Design",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "BDes (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Planning",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accountancy & Finance PhD",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accountancy MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance PhD",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting, Management and Strategy MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science with International Business MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anatomy & Human Identification (Centre for Anatomy and Human Identification (CAHID)) MSc(Res)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anatomy & Human Identification (Centre for Anatomy and Human Identification (CAHID)) PhD",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anatomy & Human Identification (Centre for Anatomy and Human Identification (CAHID)) PhD (4 Year)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anatomy and Advanced Forensic Anthropology (MSc)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anatomy and Advanced Forensic Anthropology PGDip",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animation and VFX (MSc)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Mathematics PhD",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Neuroscience (MSc)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture PhD",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture PhD (4 years)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture RIBA Part 2 MArch (Hons)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MArch (Hons)",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture with Urban Planning RIBA Part 2 MArch (Hons)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MArch (Hons)",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archives and Records Management (PhD)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archives and Records Management (PhD) part time",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "60 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art & Design MDes(Res) (full time)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MDes(Res)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art & Design MFA (Res) (full time)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "Master of Fine Art (Research), MFA(Res)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art & Design PhD (3 years)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Data Science (MSc)",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical and Molecular Sciences (MSc)",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical and Molecular Sciences with Business (MSc)",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical and Molecular Sciences with Entrepreneurship (MSc)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical and Molecular Sciences with Management MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical and Molecular Sciences with Marketing MSc",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering PhD (3 Years)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering PhD (4 Years)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business (non-graduating) (research)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "Postgraduate Credits",
                    "duration": "6 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics and Big Data MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics and Finance MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics and International Business MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics and Management MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics and Marketing MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Research Methods MRes",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Centre for Anatomy and Human Identification (Non-Graduating) (Research)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "Postgraduate Credits",
                    "duration": "6 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Centre for Energy, Petroleum and Mineral Law and Policy (CEPMLP) PhD",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Centre for Energy, Petroleum and Mineral Law and Policy (CEPMLP) Phd (4 years)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering MPhil (Res)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MPhil",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering MSc",
                    "university": "University of Dundee",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering MSc (Res)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering PhD",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with Future Construction Technologies MSc",
                    "university": "University of Dundee",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with Low Carbon Infrastructure MSc",
                    "university": "University of Dundee",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with Marine Renewable Energy MSc",
                    "university": "University of Dundee",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Community Education PhD",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (MSc)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing (Non-Graduating) (Research)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "Postgraduate Credits",
                    "duration": "6 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing PhD",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Corporate and Commercial Law LLM",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Corporate Sustainability, Human Rights and ESG Law (LLM)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cross-Border Disputes (Dual Qualifying Programme)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cross-Border Disputes LLM",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Curatorial Practice (Art & Design) MFA",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MFA (PG)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Engineering MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dental Public Health PGCert",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dental Public Health PGDip",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dentistry (non-clinical)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dentistry (non-clinical)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MDSc (Res)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dentistry (non-graduating) (research)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "Postgraduate Credits",
                    "duration": "6 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dentistry (with clinical component) 4 Year",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dentistry (with clinical component) 5 Year",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "60 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design for Business MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Developmental Psychology MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital and Social Media Marketing MSc",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics PhD",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics PhD",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Teaching English to Speakers of Other Languages - TESOL) MEd",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MEd",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education PhD (3 years)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education, Social Work and Community Education (Non-Graduating) (Research)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "Postgraduate Credits",
                    "duration": "6 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Assistive Technology MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Psychology MSc",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Endodontics (DClinDent)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "DClinDent",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English (PhD)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Entrepreneurship, Innovation and International Business  MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Entrepreneurship, Innovation and Islamic Finance MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Entrepreneurship, Innovation and Management MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Law (LLM)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science (PhD)",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science MSc (Research)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance (MSc)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Banking (MSc)",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Investment (MSc)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Risk Analysis (MSc)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art (MFA)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MFA (PG)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Anthropology (MSc)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Anthropology PGDip",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Archaeology and Anthropology (MSc)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Archaeology and Anthropology PGDip",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Art and Facial Imaging MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Dentistry (MSc)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Odontology (MFOdont)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MFOdont",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Science (Leverhulme Research Centre for Forensic Science) PhD",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Science (Leverhulme Research Centre for Forensic Science) PhD (4 Year)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography MSc (Research)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography PhD (3 years)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography PhD (4 years)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global and Local Social Work MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Data Science for Applied Precision Medicine MSc",
                    "university": "University of Dundee",
                    "location": "Ninewells Hospital and Medical School",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History (PhD)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Anatomy MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Clinical Embryology and Assisted Conception MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Humanities (non-graduating) (research)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "Postgraduate Credits",
                    "duration": "6 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Inclusion Oral Health PGCert",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Industrial Engineering and Digital Marketing",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Industrial Engineering and Entrepreneurship",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Industrial Engineering and International Finance MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Industrial Engineering and Management MSc",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Industrial Engineering and Renewables MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Industrial Engineering and Sustainability MSc",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Technology and International Business MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Accounting MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Banking and Finance MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Banking MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Banking, Finance and Investment Management MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Banking, Finance, Risk and Regulation MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Finance MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Human Resource Management MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Investment MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Management MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Marketing MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Strategy MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business, Accounting and Finance MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business, Marketing and Human Resource Management MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law (Dual Qualifying) LLM",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law LLM",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Energy Commerce and Finance MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Energy Commerce MSc",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Energy Dispute Resolution and Avoidance",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Energy Studies and Energy Economics (MSc)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Energy Studies and Energy Finance (MSc)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Energy Studies and Oil and Gas Economics (MSc)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Finance (MFin)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MFin",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Finance and Investment Management",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MFin",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Finance, Risk and Regulation",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MFin",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Marketing (MSc)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Marketing and Branding MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Marketing and Finance MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Marketing and Management MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Mineral Resources Management MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Minerals and Energy Law and Policy (LLM)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Oil and Gas Law and Policy LLM",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Oil and Gas Management MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Islamic Banking and Finance MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Islamic Banking, Finance and International Business MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Islamic Finance and the Digital Economy MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Islamic Finance MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Islamic Finance PhD 3 Years",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Islamic Finance PhD 4 Years",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (General) LLM",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (Non-Graduating) (Research)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "Postgraduate Credits",
                    "duration": "6 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law - LLM (Research)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "LLM (Res)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law PgCert",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law PhD (3 year)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law PhD (4 years)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leading Learning & Teaching in Education MEd",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MEd",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leverhulme Research Centre for Forensic Science MSc (Research)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Life Sciences (non-graduating) (research)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "Postgraduate Credits",
                    "duration": "6 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Life Sciences MSc (Res)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Life Sciences PhD",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Life Sciences PhD (4 Year)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management & Marketing PhD",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and Finance MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and International Human Resource Management MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and Marketing MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management PhD",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management, Strategy and Leadership MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Dental Public Health (MDPH)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MDPH",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics (PhD)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering PhD",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Art MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Education MMEd",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "MMEd",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Imaging (MSc)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine (EMBL Joint Programme) PhD",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine (MD)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MD",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine (non-graduating) (research)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "Postgraduate Credits",
                    "duration": "6 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine (PhD 4 Year Programme)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine (PhD)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine MSc (Res)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing & Health Sciences (non-graduating) (research)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "Postgraduate Credits",
                    "duration": "6 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing & Health Sciences MPhil (Res)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MPhil (Res)",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing & Health Sciences PhD (3 years)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing & Health Sciences PhD (4 years)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing and Health (MSc)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Orthopaedic Surgery (MCh Orth)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MChOrth",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCert Medical Education",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGDip Medical Education",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy (PhD)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics PhD",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics & International Relations PhD (3 years)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics & International Relations PhD (4 years)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design (MSc)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Accountancy (MSc)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Legal Practice (PGDip)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Prosthodontics",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "DClinDent",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological Research Methods MSc",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Conversion) MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (non-graduating) (research)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "Postgraduate Credits",
                    "duration": "6 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology of Language MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology of Mental Health (MSc)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology PhD (3 years)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology PhD (4 years)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health (Palliative Care Research) MPH",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MPH",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health MPH",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MPH",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science & Engineering (non-graduating) (research)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "Postgraduate Credits",
                    "duration": "6 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science & Health Communication (MSc)",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research PgDip",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Sciences (non-graduating (research)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "Postgraduate Credits",
                    "duration": "6 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "22 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work PhD (3 years)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spatial Planning with Sustainable Urban Design MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability and Environmental Modelling MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability and Renewables MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability and Water Security MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability: Climate Change and Transitions to Net-Zero Economies MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "The Low Carbon Just Transition LLM",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Planning PhD (3 years)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Anatomy & Human Identification (Centre for Anatomy and Human Identification (CAHID)) MSc(Res)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anatomy & Human Identification (Centre for Anatomy and Human Identification (CAHID)) PhD (4 Year) (Part-Time)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "72 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anatomy & Human Identification (Centre for Anatomy and Human Identification (CAHID)) PhD (Part-Time)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archives and Records Management (PhD) part time",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art & Design (Professional Doctorate) DProf",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "DProf",
                    "duration": "48 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art & Design PhD (3 years) (part time)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration (Professional Doctorate) DProf",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "DProf",
                    "duration": "48 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Centre for Energy, Petroleum and Mineral Law and Policy (CEPMLP) Phd (4 years) (part time)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "72 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering MSc (part time)",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with Future Construction Technologies MSc (part time)",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with Low Carbon Infrastructure MSc (part time)",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with Marine Renewable Energy MSc (part time)",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Community Education PhD (Part-Time)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing PhD (Part-Time)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dentistry (non-clinical) (Part-Time)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dentistry (non-clinical) (Part-Time)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MDSc (Res)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dentistry (with clinical component) 4 Year (Part-Time)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design for Business MSc (part time)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design for Healthcare [part-time] MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Development Psychology part time",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics (Part-Time) PhD",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (3 years) (part time) PhD",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Assistive Technology MSc (part time)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English (PhD) (Part-Time)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science MSc (Res) (part time)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic and Legal Odontology (MSc) Part Time",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic and Legal Odontology (PGCert)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic and Legal Odontology (PGDip)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography MSc (Res) (part time)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography PhD (3 years) (part time)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography PhD (4 years) (part time)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "72 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History (PhD) (Part-Time)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Industrial Engineering and Digital Marketing (part time)",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Industrial Engineering and Entrepreneurship (part time)",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Industrial Engineering and International Finance (MSc)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Industrial Engineering and Management (part time)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Industrial Engineering and Renewables MSc (part time)",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Industrial Engineering and Sustainability MSc (part time)",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law PhD (Part-Time)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leverhulme Research Centre for Forensic Science MSc (Research) (Part-Time)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Life Sciences MSc (Res) (Part-Time)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Life Sciences PhD (Part-Time)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics (PhD) (Part-Time)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine (MD) (Part-Time)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MD",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine (PhD) (Part-Time)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine MSc (Res) (part time)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing & Health Sciences PhD (3 Year) (Part-Time)",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "72 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PhD Mechanical Engineering  (part-time)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy (part time)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics & International Relations PhD (3 years) (part time)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics & International Relations PhD (4 years) (Part-Time)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "72 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design (MSc) part time",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Legal Practice (PGDip) Part Time",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological Research Methods part time",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (3 years) (part-time)",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (4 years) (part-time)",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "80 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Conversion) [part-time] MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology of Language part time",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology of Mental Health (part-time) (MSc)",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health (Palliative Care Research) [part-time] MPH",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MPH",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health (part time) MPH",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MPH",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research (part time) MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research PgDip (part time)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work (3 years) (part time) PhD",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spatial Planning with Sustainable Urban Design (part-time) MSc",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability MSc (part time)",
                    "university": "University of Dundee",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Planning PhD (3 years) (Part-Time)",
                    "university": "University of Dundee",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "43ef12ecd34ff4f4cfd23a62011676b2",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance with Foundation",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad and foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance with Placement Year",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance with Study Abroad",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting with Placement Year",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting with Study Abroad",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History and Archaeology",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient, Medieval and Modern History",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and Archaeology",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and Sociology",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology with Foundation",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology with Foundation",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and Ancient Civilisations",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology with Foundation",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology with Foundation",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences with Foundation",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biosciences",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MBiol",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management with Foundation",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management with Placement Year",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management with Study Abroad",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry (Industrial)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry (International)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies (with Year Abroad)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies (with Year Abroad) with Foundation",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad and foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Civilisation",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics with Foundation",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate Science",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Combined Honours in Social Sciences",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Combined Honours in Social Sciences with Foundation",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Mathematics",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Mathematics",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Foundation",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology with Foundation",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth Sciences",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Mathematics",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Politics",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Foundation",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Management",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Management with Placement Year",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Management with Study Abroad",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Placement Year",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Study Abroad",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies - Geography",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies - Psychology",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies - Sociology",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies with Foundation",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Aeronautical)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Bioengineering)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Civil)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Civil)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Electrical)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Electrical)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Electronic)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Electronic)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Mechanical)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Mechanical)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Renewable Energy)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and History",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Philosophy",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature with Foundation",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Geoscience",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance with Foundation",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance with Placement Year",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance with Study Abroad",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "General Engineering",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "General Engineering",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with Foundation",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geophysics",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geoscience",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Human Sciences",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Human Sciences with Foundation",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Historical Archaeology",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History with Foundation",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Japanese Studies (with Year Abroad)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Japanese Studies (with Year Abroad) with Foundation",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad and foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Foundation",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Liberal Arts",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Liberal Arts with Foundation",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing and Management",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing and Management with Foundation",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing and Management with Placement Year",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing and Management with Study Abroad",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MMath",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Physics",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Physics",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Statistics",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Statistics",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MMath (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern European Languages and History (with Year Abroad)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and Cultures (with Year Abroad)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Philosophy",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music with Foundation",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Sciences",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Sciences",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Politics",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Psychology",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Theology",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy with Foundation",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy, Politics and Economics",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics and Astronomy",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics with Foundation",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Education",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Primary Education with Foundation",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological and Behavioural Science",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological and Behavioural Science with Foundation",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Anthropology",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with Foundation",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religion, Society and Culture",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology with Foundation",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Sciences",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport, Exercise and Physical Activity",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport, Exercise and Physical Activity with Foundation",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religion",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religion with Foundation",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology, Ministry and Mission",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology, Mission and Ministry",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "Cert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology, Mission and Ministry",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "Diploma",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Arts and Film",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science",
                    "university": "Durham University",
                    "location": "Durham University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science (Artificial Intelligence)",
                    "university": "Durham University",
                    "location": "Durham University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Mechanical Engineering",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Behavioural Economics",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Behavioural Science",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioarchaeology",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology",
                    "university": "Durham University",
                    "location": "Durham University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate, Risk and Society",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate, Risk and Society",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Neuroscience",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conflict Prevention and Peacebuilding",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation of Archaeological and Museum Objects (Diss)",
                    "university": "Durham University",
                    "location": "Durham University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation of Archaeological and Museum Objects (PP)",
                    "university": "Durham University",
                    "location": "Durham University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Corporate Law",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Criminal Justice",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Defence, Development and Diplomacy",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Developmental Psychopathology",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Leadership and Change",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Engineering",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Engineering Management",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literary Studies",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental and Natural Resource Economics",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Hazards and Risk",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Hazards and Risk",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Humanities",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "European Trade and Commercial Law",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance (Accounting and Finance)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance (Corporate and International Finance)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance (Economics and Finance)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance (Finance and Investment)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance (Financial Technology and Banking)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Archaeology and Anthropology",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global and Planetary Health",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global History",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Bioarchaeology and Palaeopathology",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intellectual Property Law",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intercultural Communication and Education",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Cultural Heritage Management",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Dispute Resolution",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Environmental Law",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law and Governance",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations (East Asia)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Trade and Commercial Law",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Languages, Literatures and Cultures",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Finance",
                    "university": "Durham University",
                    "location": "Durham University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Entrepreneurship)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Supply Chain Logistics)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and Finance",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Data Science",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MDS",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Data Science (Bioinformatics and Biological Modelling)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Data Science (Digital Humanities)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Data Science (Earth and Environment)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Data Science (Health)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Data Science (Heritage)",
                    "university": "Durham University",
                    "location": "Durham University",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Data Science (Social Analytics)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Laws",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical Sciences",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Anthropology",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Humanities",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Law and Ethics",
                    "university": "Durham University",
                    "location": "Durham University",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval and Early Modern Studies",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Museum and Artefact Studies",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "Grad Dip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Activity, Health and Society",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Plant Biotechnology and Enterprise",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations (Political Theory)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations of the Middle East",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Policy",
                    "university": "Durham University",
                    "location": "Durham University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Renewable and Sustainable Energy",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Research Methods (Behavioural Science)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Research Methods (Developmental Psychology)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Research Methods (Politics, International Relations, Security)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Risk, Security and Politics",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scientific Computing and Data Analysis (Astrophysics)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scientific Computing and Data Analysis (Computer Vision and Robotics)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scientific Computing and Data Analysis (Earth and Environmental Sciences)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scientific Computing and Data Analysis (Financial Technology)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social and Economic History (Research Methods)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research Methods",
                    "university": "Durham University",
                    "location": "Durham University",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSW",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Socio-Cultural Anthropology",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability, Energy and Development",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "TESOL and Applied Linguistics",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Ministry",
                    "university": "Durham University",
                    "location": "Cranmer Hall",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Ministry",
                    "university": "Durham University",
                    "location": "Cranmer Hall",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Ministry",
                    "university": "Durham University",
                    "location": "Cranmer Hall",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religion",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religion",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "Grad Dip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation Studies",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Culture",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Archaeology",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioarchaeology",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration (Online)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate, Risk and Society",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate, Risk and Society",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation of Archaeological and Museum Objects (Diss)",
                    "university": "Durham University",
                    "location": "Durham University",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation of Archaeological and Museum Objects (PP)",
                    "university": "Durham University",
                    "location": "Durham University",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Criminal Justice",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literary Studies",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Hazards and Risk",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Hazards and Risk",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Humanities",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Archaeology and Anthropology",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global and Planetary Health",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global History",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intercultural Communication and Education",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Cultural Heritage Management",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Anthropology",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Humanities",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Law and Ethics",
                    "university": "Durham University",
                    "location": "Durham University",
                    "qualification": "LLM",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval and Early Modern Studies",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Museum and Artefact Studies",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "Grad Dip",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Activity, Health and Society",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations (Political Theory)",
                    "university": "Durham University",
                    "location": "Durham University",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations of the Middle East",
                    "university": "Durham University",
                    "location": "Durham University",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Risk, Security and Politics",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social and Economic History (Research Methods)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research Methods",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Socio-Cultural Anthropology",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Supply Chain Management (Executive)",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "Executive Master's",
                    "duration": "2 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability, Energy and Development",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "TESOL and Applied Linguistics",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Ministry",
                    "university": "Durham University",
                    "location": "Cranmer Hall",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Ministry",
                    "university": "Durham University",
                    "location": "Cranmer Hall",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Ministry",
                    "university": "Durham University",
                    "location": "Cranmer Hall",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religion",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religion",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "Grad Dip",
                    "duration": "21 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation Studies",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Culture",
                    "university": "Durham University",
                    "location": "Durham City",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "14ec1f1a804d1139ef7b44c7d4e02234",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "BA (Hons) Counselling Studies (Top-up)",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "BA (Hons) Design (Top-up)",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "BA (Hons) Film & Media Enterprise",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "BA (Hons) Visual Arts",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "BSc (Hons) Business Computing",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "240"
                },
                {
                    "course_name": "BSc (Hons) Cyber Security",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "240"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Early Childhood Studies (0-8 years)",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "FdA Business and Management",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "FdA Film & Media Production",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "FdA Graphic Design",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "FdA Integrative Counselling",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "FdA Performing Arts Practice",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "FdA Policing the Community and Criminology",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fda Visual Arts",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "FdSc Business Computing",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "FdSc Cyber Security",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "FdSc Healthcare Practice",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "FdSc Leadership Principles in Health and Social Care",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "FdSc Sports Coaching and Development",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Management",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "240"
                },
                {
                    "course_name": "Podiatry",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Popular Music",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Public and Community Services",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Roots and Popular Music",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Social Work",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Sport and Exercise Development",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Tourism and Event Management",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                }
            ],
            "Part-time": [
                {
                    "course_name": "BA (Hons) Counselling Studies (Top-up) (part-time)",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction and the Built Environment (Civil Engineering)",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Electrical/Electronic Engineering",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "FdA Business and Management",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "FdA Housing & Community Studies",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "FdA Integrative Counselling",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "HNC Construction Management",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "HNC Mechanical Engineering",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "240"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": [
                {
                    "course_name": "Strategic People Management",
                    "university": "New College Durham",
                    "location": "Main Site",
                    "qualification": "AdvPgDip",
                    "duration": "1.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "7e7d0595a4e29fd4e08074dcd4d8860a",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "564529000ae51b51abd72e37988b1804",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "cc4ebcb38483716a367146e0d1390234",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting  and Management",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance (Final Year Entry)",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance with a Year in Industry",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Management with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Management with a Year in Industry",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Actuarial Science",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Actuarial Science with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Actuarial Science with a Year in Industry",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "American History",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "American History",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "American History with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "American Studies",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "American Studies with a Foundation Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "American Studies with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "American Studies with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology, Anthropology and Art History",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology, Anthropology and Art History with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology, Anthropology, and Art History with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry with a Foundation Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences with a Foundation Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science with a Placement Year in NHS",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedicine",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedicine with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedicine with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Broadcast and Multimedia Journalism",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Broadcast and Multimedia Journalism with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Broadcast and Multimedia Journalism with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics and Management",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics and Management with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics and Management with a Year in Industry",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Human Resource Management",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Human Resource Management with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Human Resource Management with a Year in Industry",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Finance and Economics",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Finance and Economics with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Finance and Economics with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Information Systems",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Information Systems with a Year in Industry",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with a Year in Industry",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with a Foundation Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with a Year in Industry",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children and Young People's Nursing",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Psychology",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Psychology with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Psychology with Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Systems Engineering",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Systems Engineering with a year in Industry",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MCompu (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science with a Foundation Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science with a Year in Industry",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science with Cyber Security",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science with Cyber Security",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MCompu (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science with Cyber Security with a Year in Industry",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science with Software Development (Final Year Entry)",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science with Study Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MCompu (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing and English Literature",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing and English Literature with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing and English Literature with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Developmental Psychology",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Developmental Psychology with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Developmental Psychology with Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama and Creative Writing",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama and Creative Writing with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama and Creative Writing with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama with a Foundation Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecology and Conservation",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecology and Conservation with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecology and Conservation with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Accountancy",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Accountancy with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Accountancy with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics, Behaviour and Data Science",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics, Behaviour and Data Science with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics, Behaviour and Data Science with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education, Childhood and Culture",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education, Childhood and Culture with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Education, Learning and Teaching",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education, Learning and Teaching with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education, Special Educational Needs, and Inclusion",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education, Special Educational Needs, and Inclusion with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering with a year in Industry",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering with a Year in Industry",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Engineering",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Engineering",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Engineering with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Engineering with a Year in Industry",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Engineering with a year in Industry",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering with a Foundation Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering with a Year in Industry",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering with a Year in Industry",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and American Literature",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and American Literature",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and American Literature with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Drama",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Drama with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Drama with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Philosophy",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Philosophy with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Philosophy with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature with a Foundation Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature with Creative Writing",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature with Creative Writing with a Foundation Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature with Creative Writing with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature with Creative Writing with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Sciences",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Sciences",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Sciences with a Foundation Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Sciences with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Sciences with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Production",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Production with a Foundation Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Production with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Production with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Studies",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Studies with a Foundation Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Studies with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Studies with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies and English Literature",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies and English Literature with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies and English Literature with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Management",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Management with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Management with a Year in Industry",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with Global Development",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with Global Development with a Foundation Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with Global Development with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with Global Development with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology with Geography",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology with Geography",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology with Geography with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology with Geography with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geophysics",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geophysics",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geophysics with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geophysics with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Communication with Business Management",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Communication with Business Management with a Foundation Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Communication with Business Management with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Communication with Business Management with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development with a Foundation Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development with Economics",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development with Economics with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development with Economics with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development with Politics",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development with Politics with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development with Politics with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Film Studies",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Film Studies with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Film Studies with a year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and History of Art",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and History of Art with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and History of Art with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Politics",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Politics with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Politics with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art and Literature",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art and Literature with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art and Literature with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art with a Foundation Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art with Gallery & Museum Studies",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art with Gallery and Museum Studies with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art with Gallery and Museum Studies with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History with a Foundation Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Geography",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Geography with Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management (Final Year Entry)",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management with a Year in Industry",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Modern History",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Modern History with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Modern Language",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Politics",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Politics with a Foundation Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Politics with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Politics with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations with Modern History with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with American Law",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with European Legal Systems",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Liberal Arts",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Liberal Arts with a Foundation Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Liberal Arts with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Liberal Arts with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Literature and History",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Literature and History with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Literature and History with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing and Management",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing and Management (Final Year Entry)",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing and Management with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing and Management with a Year in Industry",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MMath (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Statistics",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with a Foundation Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MMath (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MMath (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering  with a Year in Industry",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with a year in Industry",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media Studies",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media Studies with a Foundation Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media Studies with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media Studies with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicinal Chemistry",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicinal Chemistry",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicinal Chemistry with a Year in Industry",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern History",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern History with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern History with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Language",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Language with a Foundation Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Language with Management Studies",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages with Management Studies",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular Biology and Genetics",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular Biology and Genetics with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular Biology and Genetics with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paramedic Science",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology and Drug Discovery",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology and Drug Discovery with Foundation Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MPharm (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy with a Preparatory Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MPharm (H)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Politics",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Politics with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Politics with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy with a Foundation Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy, Politics and Economics",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy, Politics and Economics with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy, Politics and Economics with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Activity and Health",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Activity and Health with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Education",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Education with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Education, Sport and Health",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Education, Sport and Health with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "Master of Physics (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with a Foundation Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with a year abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with a Year in Industry",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Psychology",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Psychology with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Psychology with Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work (Postgraduate Entry)",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Speech and Language Therapy",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Development",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Development with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Development",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Development with a Placement Year",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Development with a Year Abroad",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-153"
                },
                {
                    "course_name": "Translation and Interpreting with Modern Languages",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation, Media and Modern Language (with a year abroad)",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation, Media and Modern Languages",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Adult Nursing - Preregistration",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computing Science",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Organic Chemistry",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agriculture and Rural Development",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "American Studies",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Ecology and Conservation",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Translation Studies",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Banking and Finance",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Behavioural Economics and Data Science",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Brand Leadership",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Broadcast and Digital Journalism International",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Broadcast and Digital Journalism UK",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics and Management",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate Change and Global Development",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Education",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Science",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Neuroscience",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conflict, Governance and Global Development",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing (Non-Fiction)",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing Poetry",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing Prose Fiction",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing Scriptwriting",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cultural Heritage and Museum Studies",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science for Biology",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science for Environmental Science",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Developmental Science",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dietetics (pre-registration)",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Business and Management",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Modern History",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Data Science",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and International Relations",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Learning, Pedagogy and Assessment)",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education and Development",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Leadership and Management",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Engineering",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Engineering with Environmental Management",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment and Global Development",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Assessment and Management",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "46 weeks",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Sciences",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "46 weeks",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Evolution and Genomics",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Production",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Economics",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Management",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender Studies",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Business Management",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development Management",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Intercultural Communication",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Media Economics",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Plant Health",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Social Development",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Globalisation, Business and Sustainable Development",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Impact Evaluation for Global Development",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Technology and Intellectual Property Law",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Accounting and Financial Management",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Economics",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Finance and Economics",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial and Business Law",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Dispute Resolution",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations Double Degree with Ritsumeikan University, Japan",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Security",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Trade Law",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Literary Translation",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing and Management",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics Education",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media, Culture and Society",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical and Health Humanities",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval History",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health Nursing - Preregistration",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern and Contemporary Writing",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern History",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular Medicine",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Product Drug Discovery",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Therapy (pre-registration)",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Organisational Psychology",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy (pre-registration)",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Plant Genetics and Crop Improvement",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Policy, Behaviour and Data for Global Development",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Policy and Public Management",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social and Applied Psychology",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Supply Chain Operations and Logistics Management",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL)",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "The Arts of Africa, Oceania and the Americas",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre Directing (Text and Production)",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "World Art Studies",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Computing Science",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Professional Practice",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "American Studies",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Ecology and Conservation",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Translation Studies",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Broadcast and Digital Journalism UK",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Education",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Neuroscience",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing Poetry",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing Prose Fiction",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing Scriptwriting",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cultural Heritage and Museum Studies",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Developmental Science",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Modern History",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Learning, Pedagogy and Assessment)",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Leadership and Management",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Assessment and Management",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender Studies",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Intercultural Communication",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Technology and Intellectual Property Law",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial and Business Law",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Trade Law",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Literary Translation",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration (Executive)",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MBA (Executive)",
                    "duration": "27 months",
                    "study_mode": "Part-time day and evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media, Culture and Society",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical and Health Humanities",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval History",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern and Contemporary Writing",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern History",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Plant Genetics and Crop Improvement",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Policy and Public Management",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social and Applied Psychology",
                    "university": "University of East Anglia UEA",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL)",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre Directing (Text and Production)",
                    "university": "University of East Anglia UEA",
                    "location": "University of East Anglia",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "eb6bffd3643c592108c66be8a449c0af",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "430f0e2cf57c5599fafa621500b63430",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "21e611296c5e250c13fa3ed3e97d4613",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Art & Design (Fashion and Textiles)",
                    "university": "East Riding College",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Art & Design (Fashion) Top Up",
                    "university": "East Riding College",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Certificate in Education (Professional Diploma in Teaching - Further Education and Skills)",
                    "university": "East Riding College",
                    "location": "Multiple Locations",
                    "qualification": "CertEd",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing",
                    "university": "East Riding College",
                    "location": "Bridlington",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "East Riding College",
                    "location": "Beverley Campus",
                    "qualification": "FdEd",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Early Childhood Studies Top Up",
                    "university": "East Riding College",
                    "location": "Beverley Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Innovation and Entrepreneurship (Top up)",
                    "university": "East Riding College",
                    "location": "Beverley Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrative Counselling",
                    "university": "East Riding College",
                    "location": "Beverley Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Learning Support",
                    "university": "East Riding College",
                    "location": "Beverley Campus",
                    "qualification": "FdEd",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Manufacturing Engineering for England",
                    "university": "East Riding College",
                    "location": "Beverley Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Graduate Certificate in Education (Professional Diploma in Teaching - Further Education and Skills)",
                    "university": "East Riding College",
                    "location": "Multiple Locations",
                    "qualification": "PGCE (Professional)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Sector Management",
                    "university": "East Riding College",
                    "location": "Bridlington",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sport, Physical Education and Coaching",
                    "university": "East Riding College",
                    "location": "Bridlington",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Certificate in Education (Professional Diploma in Teaching - Further Education and Skills)",
                    "university": "East Riding College",
                    "location": "Multiple Locations",
                    "qualification": "CertEd",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing",
                    "university": "East Riding College",
                    "location": "Bridlington",
                    "qualification": "FdSc",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Construction Management for England (Construction Design and Build Technician) (HTQ)",
                    "university": "East Riding College",
                    "location": "Beverley Campus",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Learning Support",
                    "university": "East Riding College",
                    "location": "Beverley Campus",
                    "qualification": "FdEd",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Manufacturing Engineering for England",
                    "university": "East Riding College",
                    "location": "Beverley Campus",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Methods of Construction for England (HTQ)",
                    "university": "East Riding College",
                    "location": "Beverley Campus",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Graduate Certificate in Education (Professional Diploma in Teaching - Further Education and Skills)",
                    "university": "East Riding College",
                    "location": "Multiple Locations",
                    "qualification": "PGCE (Professional)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "d365ab139628dc53c140f673c82cf88d",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Animation",
                    "university": "East Sussex College",
                    "location": "Lewes",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management",
                    "university": "East Sussex College",
                    "location": "Hastings (Station Plaza)",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management (Top-Up)",
                    "university": "East Sussex College",
                    "location": "Hastings (Station Plaza)",
                    "qualification": "BA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "240"
                },
                {
                    "course_name": "Computing and Systems Development (HTQ)",
                    "university": "East Sussex College",
                    "location": "Hastings (Ore Valley)",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Creative Media Production",
                    "university": "East Sussex College",
                    "location": "Hastings (Ore Valley)",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Design",
                    "university": "East Sussex College",
                    "location": "Lewes",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Design (Top-up)",
                    "university": "East Sussex College",
                    "location": "Lewes",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "240"
                },
                {
                    "course_name": "Designer Maker",
                    "university": "East Sussex College",
                    "location": "Hastings (Station Plaza)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Early Years Care and Education",
                    "university": "East Sussex College",
                    "location": "Hastings (Station Plaza)",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Early Years Care and Education (Top Up)",
                    "university": "East Sussex College",
                    "location": "Hastings (Station Plaza)",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "East Sussex College",
                    "location": "Hastings (Ore Valley)",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "East Sussex College",
                    "location": "Hastings (Ore Valley)",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Fine Art",
                    "university": "East Sussex College",
                    "location": "Hastings (Station Plaza)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Illustration with Design",
                    "university": "East Sussex College",
                    "location": "Hastings (Station Plaza)",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Integrated Health, Social Care and Wellbeing",
                    "university": "East Sussex College",
                    "location": "Hastings (Station Plaza)",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-96"
                },
                {
                    "course_name": "Integrated Health, Social Care and Wellbeing (Top-Up)",
                    "university": "East Sussex College",
                    "location": "Hastings (Station Plaza)",
                    "qualification": "BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "East Sussex College",
                    "location": "Hastings (Ore Valley)",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "East Sussex College",
                    "location": "Hastings (Ore Valley)",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Music Production & Creative Recording",
                    "university": "East Sussex College",
                    "location": "Eastbourne",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Person-Centred Counselling",
                    "university": "East Sussex College",
                    "location": "Multiple Locations",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Person-Centred Counselling (Top-up)",
                    "university": "East Sussex College",
                    "location": "Eastbourne",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Photography & Moving Image",
                    "university": "East Sussex College",
                    "location": "Hastings (Station Plaza)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Social & Community Work",
                    "university": "East Sussex College",
                    "location": "Hastings (Station Plaza)",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Business Management",
                    "university": "East Sussex College",
                    "location": "Hastings (Station Plaza)",
                    "qualification": "BA",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management (Top-Up)",
                    "university": "East Sussex College",
                    "location": "Hastings (Station Plaza)",
                    "qualification": "BA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "240"
                },
                {
                    "course_name": "Construction",
                    "university": "East Sussex College",
                    "location": "Eastbourne",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction",
                    "university": "East Sussex College",
                    "location": "Eastbourne",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Designer Maker",
                    "university": "East Sussex College",
                    "location": "Hastings (Station Plaza)",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "East Sussex College",
                    "location": "Hastings (Ore Valley)",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "East Sussex College",
                    "location": "Hastings (Ore Valley)",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Fine Art",
                    "university": "East Sussex College",
                    "location": "Hastings (Station Plaza)",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Illustration with Design",
                    "university": "East Sussex College",
                    "location": "Hastings (Station Plaza)",
                    "qualification": "BA",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "East Sussex College",
                    "location": "Hastings (Ore Valley)",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "East Sussex College",
                    "location": "Hastings (Ore Valley)",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Person-Centred Counselling (Top-up)",
                    "university": "East Sussex College",
                    "location": "Eastbourne",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Photography & Moving Image",
                    "university": "East Sussex College",
                    "location": "Hastings (Station Plaza)",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "5c22ea2c4bd668ebd804f2c7fcdf786c",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting",
                    "university": "Edinburgh Napier University",
                    "location": "Craiglockhart Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "Edinburgh Napier University",
                    "location": "Craiglockhart Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting with Corporate Finance",
                    "university": "Edinburgh Napier University",
                    "location": "Craiglockhart Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Acting and English",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal & Conservation Biology",
                    "university": "Edinburgh Napier University",
                    "location": "Sighthill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animation For Games (Top Up)",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Microbiology",
                    "university": "Edinburgh Napier University",
                    "location": "Sighthill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Technology",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science",
                    "university": "Edinburgh Napier University",
                    "location": "Sighthill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology - Professional Graduate Diploma in Education (PGDE Only)",
                    "university": "Edinburgh Napier University",
                    "location": "Sighthill",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Edinburgh Napier University",
                    "location": "Sighthill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Building Surveying (with Work Placement)",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "Edinburgh Napier University",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Entrepreneurship",
                    "university": "Edinburgh Napier University",
                    "location": "Craiglockhart Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Human Resource Management",
                    "university": "Edinburgh Napier University",
                    "location": "Craiglockhart Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Marketing",
                    "university": "Edinburgh Napier University",
                    "location": "Craiglockhart Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry - Professional Graduate Diploma in Education (PGDE - Graduates Only)",
                    "university": "Edinburgh Napier University",
                    "location": "Sighthill",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil & Transportation Engineering",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Artificial Intelligence)",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Systems And Networks",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Edinburgh Napier University",
                    "location": "Sighthill",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security and Forensics",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media and Interaction Design",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media and Interaction Design Global",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy and Environmental Engineering",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English - Professional Graduate Diploma in Education (PGDE)",
                    "university": "Edinburgh Napier University",
                    "location": "Sighthill",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Film",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Services",
                    "university": "Edinburgh Napier University",
                    "location": "Craiglockhart Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Football Coaching, Performance and Development",
                    "university": "Edinburgh Napier University",
                    "location": "Sighthill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Development",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BDes (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Informatics for Business",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intercultural Business Communication and Marketing Management",
                    "university": "Edinburgh Napier University",
                    "location": "Craiglockhart Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intercultural Business Communication and Tourism Management",
                    "university": "Edinburgh Napier University",
                    "location": "Craiglockhart Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior and Spatial Design",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BDes (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management",
                    "university": "Edinburgh Napier University",
                    "location": "Craiglockhart Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Festival and Event Management",
                    "university": "Edinburgh Napier University",
                    "location": "Craiglockhart Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Hospitality Management",
                    "university": "Edinburgh Napier University",
                    "location": "Craiglockhart Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Hospitality Management and Festival & Event",
                    "university": "Edinburgh Napier University",
                    "location": "Craiglockhart Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tourism and Airline Management",
                    "university": "Edinburgh Napier University",
                    "location": "Craiglockhart Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tourism Management",
                    "university": "Edinburgh Napier University",
                    "location": "Craiglockhart Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Edinburgh Napier University",
                    "location": "Craiglockhart Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law Graduate Entry",
                    "university": "Edinburgh Napier University",
                    "location": "Craiglockhart Campus",
                    "qualification": "LLB",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine and Freshwater Biology",
                    "university": "Edinburgh Napier University",
                    "location": "Sighthill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing Management",
                    "university": "Edinburgh Napier University",
                    "location": "Craiglockhart Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing with Digital Media",
                    "university": "Edinburgh Napier University",
                    "location": "Craiglockhart Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mass Communications",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mass Communications, Advertising and Public Relations",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics - Professional Graduate Diploma in Education (PGDE - Graduates Only)",
                    "university": "Edinburgh Napier University",
                    "location": "Sighthill",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery",
                    "university": "Edinburgh Napier University",
                    "location": "Sighthill",
                    "qualification": "BMid",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "Edinburgh Napier University",
                    "location": "Sighthill",
                    "qualification": "BN",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Child Health)",
                    "university": "Edinburgh Napier University",
                    "location": "Sighthill",
                    "qualification": "BN",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Learning Disability)",
                    "university": "Edinburgh Napier University",
                    "location": "Sighthill",
                    "qualification": "BN",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "Edinburgh Napier University",
                    "location": "Sighthill",
                    "qualification": "BN",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Photography",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Activity and Health",
                    "university": "Edinburgh Napier University",
                    "location": "Sighthill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics - Professional Graduate Diploma in Education (PGDE - Graduates Only)",
                    "university": "Edinburgh Napier University",
                    "location": "Sighthill",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Policing and Criminology",
                    "university": "Edinburgh Napier University",
                    "location": "Sighthill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BDes (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Edinburgh Napier University",
                    "location": "Sighthill",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Edinburgh Napier University",
                    "location": "Sighthill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with Sociology",
                    "university": "Edinburgh Napier University",
                    "location": "Sighthill",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantity Surveying (with Work Placement)",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate Surveying (With Work Placement)",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Sciences",
                    "university": "Edinburgh Napier University",
                    "location": "Sighthill",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "MEng",
                    "duration": "5.5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sound Design",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "Edinburgh Napier University",
                    "location": "Sighthill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Coaching",
                    "university": "Edinburgh Napier University",
                    "location": "Sighthill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Stage and Screen Acting",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Television",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "User Experience (UX) Design",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Web Design and Development",
                    "university": "Edinburgh Napier University",
                    "location": "Merchiston Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "35267595b7769efd07ec68dfb3aa7d2f",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Business",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Acoustics and Music Technology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anatomy and Development",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient and Medieval History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History and Greek",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History and Latin",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient Mediterranean Civilisations",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animation",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Mathematics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MMath (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Mathematics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Sport Science",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arabic and Ancient Greek",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arabic and Business",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arabic and French",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arabic and History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arabic and Persian",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arabic and Politics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arabic and Social Anthropology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arabic and Spanish",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arabic with Islamic and Middle Eastern Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and Ancient History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and Social Anthropology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural History and Archaeology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural History and Heritage",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence & Computer Science",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astrophysics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astrophysics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences (Biochemistry)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences (Biotechnology)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences (Cell Biology)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences (Developmental, Regeneration and Stem Cells)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences (Ecology)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences (Evolutionary Biology)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences (Genetics)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences (Immunology)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences (Molecular Biology)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences (Molecular Genetics)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences (Plant Science)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences (Zoology)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences with Management",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Economics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Law",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business with Decision Analytics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business with Enterprise and Innovation",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business with Human Resource Management",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business with Marketing",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business with Strategic Economics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic and Archaeology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic and English Language",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic and English Literature",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic and French",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic and Linguistics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic and Scandinavian Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic and Scottish History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic and Scottish Literature",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Physics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MChemPhys",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Physics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MChem (H)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese and French",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese and German",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese and History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese and Linguistics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese and Russian Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese and Spanish",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Archaeology and Ancient History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Archaeology and Greek",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Archaeology and Latin",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and English Language",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Linguistics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Science",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Science (Humanities)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Physics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Physics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Mathematics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Physics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Divinity",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MDiv",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Divinity (BD Ordinary or Honours)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Divinity and Classics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth Sciences",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MEarthSci",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth Sciences",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth Sciences and Physical Geography",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MEarthSci",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth Sciences and Physical Geography",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecological and Environmental Sciences",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecological and Environmental Sciences with Management",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Accounting",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Mathematics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Politics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Statistics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Finance",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Management Science",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Mechanical Engineering",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Mechanical Engineering",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronics and Computer Science",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronics and Computer Science",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronics and Electrical Engineering",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronics and Electrical Engineering",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Scottish Literature",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Literature",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Classics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Geoscience",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Business",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Business",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Classics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and English Language",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and English Literature",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and German",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and History of Art",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Italian",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Linguistics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Philosophy",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Politics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Portuguese",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Russian Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Scandinavian Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Social Policy",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Spanish",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geophysics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geophysics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MEarthPhys",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geophysics and Geology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MEarthPhys",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geophysics and Geology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geophysics and Geology with Professional Placement",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MEarthPhys",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geophysics and Meteorology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geophysics and Meteorology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MEarthPhys",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geophysics and Meteorology with Professional Placement",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MEarthPhys",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geophysics with Professional Placement",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MEarthPhys",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Business",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Classics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and English Language",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and English Literature",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and History of Art",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Linguistics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Philosophy",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Politics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Portuguese",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Russian Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Scandinavian Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Social Policy",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Spanish",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Law",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Government, Policy and Society",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Government, Policy and Society with Quantitative Methods",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Greek Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health in Social Science",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Archaeology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Classics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Economics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and History of Art",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Politics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Scottish History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art and Architectural History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art and Chinese Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art and English Literature",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art and History of Music",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art and Scottish Literature",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Illustration",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Infectious Diseases",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Informatics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MInf",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interdisciplinary Futures",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Design",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Chinese",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with French",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with German",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Italian",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Japanese",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Spanish",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Foundation Programme (1 Year) Art & Design",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Foundation Programme (1 Year) Humanities & Social Sciences",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations with Quantitative Methods",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Islamic Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Classics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and English Language",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and English Literature",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and History of Art",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Linguistics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Philosophy",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Politics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Spanish",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Japanese",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Japanese and Linguistics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Jewellery and Silversmithing",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Landscape Architecture",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Latin Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (Graduate Entry)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLB",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Accountancy",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Business",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Celtic",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and French",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and German",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and International Relations",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Politics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Social Anthropology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Social Policy",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Sociology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Spanish",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Learning in Communities",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics and English Language",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics and Social Anthropology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Social Work (MSW) [Postgraduate Entry]",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSW",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical Physics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical Physics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MMath",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Business",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Music",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Physics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Statistics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Sciences",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicinal and Biological Chemistry",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MChem (H)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicinal and Biological Chemistry",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MB ChB",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Middle Eastern Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BMus (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BNurs (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance Costume",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Persian and English Literature",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Persian and Middle Eastern Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Persian and Social Anthropology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Persian Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGDE Art and Design (Graduates only)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGDE Biology (Graduates only)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGDE Chemistry (Graduates only)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGDE Chinese (Graduates only)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGDE Design and Technology (Graduates only)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGDE Drama (Graduates only)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGDE English (Graduates only)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGDE French (Graduates only)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGDE Geography (Graduates only)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGDE German (Graduates only)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGDE History (Graduates only)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGDE Maths (Graduates only)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGDE Music (Graduates only)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGDE Physical Education (Graduates only)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGDE Physics (Graduates only)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGDE Primary (Graduates only)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Economics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and English Language",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and English Literature",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Greek",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Linguistics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Mathematics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Politics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Psychology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Scottish Literature",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Theology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Education",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with a Year Abroad",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Meteorology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Meteorology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics with Quantitative Methods",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, Philosophy and Economics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese and English Language",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese and English Literature",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese and Linguistics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese and Philosophy",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese and Scottish Literature",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Education with Gaelic (Fluent Speakers)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Education with Gaelic (Learners)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Business",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Economics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Linguistics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religious Studies (MA)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religious Studies and English Literature",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religious Studies and Scottish Literature",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Reproductive Biology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian Studies and Classics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian Studies and English Language",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian Studies and English Literature",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian Studies and History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian Studies and History of Art",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian Studies and Linguistics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian Studies and Philosophy",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian Studies and Politics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian Studies and Scandinavian Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian Studies and Social Policy",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian Studies and Spanish",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scandinavian Studies (Danish, Norwegian, Swedish)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scandinavian Studies and Classics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scandinavian Studies and English Language",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scandinavian Studies and English Literature",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scandinavian Studies and History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scandinavian Studies and Linguistics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scandinavian Studies and Philosophy",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scandinavian Studies and Politics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scandinavian Studies and Social Policy",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scandinavian Studies and Spanish",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scottish Ethnology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scottish Ethnology and Archaeology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scottish Ethnology and Celtic",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scottish Ethnology and English Language",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scottish Ethnology and English Literature",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scottish Ethnology and Scandinavian Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scottish Ethnology and Scottish History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scottish Literature",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scottish Literature and Classics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scottish Literature and History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scottish Literature and Scottish History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scottish Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology and Politics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology and Social Policy",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology with Development",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy and Economics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy and Law",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy and Politics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy and Sociology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy with Quantitative Methods",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Politics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Psychology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Social Anthropology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology with Quantitative Methods",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Business",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Classics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and English Literature",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and History of Art",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Linguistics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Philosophy",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Politics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Portuguese",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Management",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural and Fire Safety Engineering",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural and Fire Safety Engineering",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Engineering with Architecture",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Engineering with Architecture",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Development",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Textiles",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Medicine",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BVMS",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Medicine (Graduate Entry)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "BVMS",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting (PhD with Integrated Study)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD with Integrated Study",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Financial Management",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Acoustics and Music Technology",
                    "university": "The University of Edinburgh",
                    "location": "Edinburgh College of Art",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Chemical Engineering",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Nursing",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Power Engineering",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Social Work Studies (Mental Health Officer Award)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PgCert",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Sustainable Design",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Technology for Financial Computing",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Africa and International Development",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "African Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agriculture and Food Security",
                    "university": "The University of Edinburgh",
                    "location": "Royal (Dick) School of Veterinary Studies",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "American History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Analysis",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Analytical Chemistry (MSc)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anatomical Sciences (Biomedical Sciences)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Breeding and Genetics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied and Computational Mathematics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Animal Behaviour and Animal Welfare",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Environmental Hydrogeology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Linguistics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Psychology (Healthcare) for Children and Young People",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural and Urban Design",
                    "university": "The University of Edinburgh",
                    "location": "Edinburgh College of Art",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Conservation",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural History",
                    "university": "The University of Edinburgh",
                    "location": "Edinburgh College of Art",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture by Design",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture, Landscape and Environment",
                    "university": "The University of Edinburgh",
                    "location": "Edinburgh College of Art",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture, Master of (ARB/RIBA part 2)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MArch",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art",
                    "university": "The University of Edinburgh",
                    "location": "Edinburgh College of Art",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astrobiology and Planetary Sciences",
                    "university": "The University of Edinburgh",
                    "location": "King's Buildings",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astrophysics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Atmospheric and Environmental Sciences",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Banking Innovation and Risk Analytics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biblical Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MTh",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biodiversity and Taxonomy of Plants",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioengineering",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioinformatics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "The University of Edinburgh",
                    "location": "King's Buildings",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences (with Internship)",
                    "university": "The University of Edinburgh",
                    "location": "King's Buildings",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences (Based in China)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences (Life sciences)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration, Master of (MBA)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MBA (International)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD with Integrated Study",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer (Edinburgh Cancer Research Centre)",
                    "university": "The University of Edinburgh",
                    "location": "Multiple Locations",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cardiovascular Biology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cardiovascular Science",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Centre for Doctoral Training in Artificial Intelligence for Biomedical Innovation",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD with Integrated Study",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Centre for Doctoral Training in Designing Responsible Natural Language Processing",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD with Integrated Study",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Centre for Doctoral Training in Machine Learning Systems",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD with Integrated Study",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Child Life and Health",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Child Protection Data Futures",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Child Protection Data Futures (Online Learning)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Circular Economy",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics (MScR)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics (PhD)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate Change Finance and Investment",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate Change Management",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Anatomy",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical and Health Psychology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Brain Sciences",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Education",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Psychology",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "DClinPsy",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Veterinary Sciences",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Science",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Collections and Curating Practices",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Commercial Law",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Education and International Development",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Private Law",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Public Policy",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Composition",
                    "university": "The University of Edinburgh",
                    "location": "Edinburgh College of Art",
                    "qualification": "MMus (Comp)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Applied Mathematics",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Mathematical Finance",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Condensed Matter",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conflict, Security and Development",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary Art Practice",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary Art Theory",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Corporate Law",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling (Interpersonal Dialogue)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MCoun",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling Studies (PhD)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Industries",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Music Practice",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Law and Criminal Justice",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Criminal Justice",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cultural Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cultural Studies",
                    "university": "The University of Edinburgh",
                    "location": "Edinburgh College of Art",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security, Privacy and Trust",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security, Privacy and Trust",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dance Science and Education",
                    "university": "The University of Edinburgh",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data and Artificial Intelligence Ethics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data and Artificial Intelligence Ethics (Online Learning)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science for Biology",
                    "university": "The University of Edinburgh",
                    "location": "King's Buildings",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data, Inequality and Society (Online Learning)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design",
                    "university": "The University of Edinburgh",
                    "location": "Edinburgh College of Art",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design and Digital Media",
                    "university": "The University of Edinburgh",
                    "location": "Edinburgh College of Art",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design for Change",
                    "university": "The University of Edinburgh",
                    "location": "Edinburgh College of Art",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Informatics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Developmental Linguistics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Developmental Science",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Communications",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Design and Manufacture",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Sociology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Doctor of Medicine",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Doctor of Veterinary Medicine",
                    "university": "The University of Edinburgh",
                    "location": "Royal (Dick) School of Veterinary Studies",
                    "qualification": "DVetMed",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drug Discovery and Translational Biology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth Observation and Geoinformation Management",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "East Asian Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecological Economics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecology, Evolution and Biodiversity",
                    "university": "The University of Edinburgh",
                    "location": "King's Buildings",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic and Social History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic and Social History",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD with Integrated Study",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics / Economics (Econometrics) / Economics (Finance)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "The University of Edinburgh",
                    "location": "Moray House School of Education and Sport",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "The University of Edinburgh",
                    "location": "Moray House School of Education and Sport",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Futures",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Futures (Online Learning)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Power Engineering",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Systems",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy, Society and Sustainability",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "The University of Edinburgh",
                    "location": "Multiple Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering with Integrated Study",
                    "university": "The University of Edinburgh",
                    "location": "King's Buildings",
                    "qualification": "PhD with Integrated Study",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature: Literature and Modernity: 1900 to the Present",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature: Literature and Society: Enlightenment, Romantic and Victorian",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Entrepreneurship and Innovation",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment and Development",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment, Culture and Society",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Protection and Management",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Sustainability",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "EPCC: High Performance Computing, Computational & Data Science, Software Engineering",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ethics and Practical Theology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "European Law",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "European Masters in Landscape Architecture",
                    "university": "The University of Edinburgh",
                    "location": "Edinburgh College of Art",
                    "qualification": "European Master's",
                    "duration": "2 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "European Theatre",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Evolution of Language and Cognition",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Evolutionary Genetics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Directing",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film, Exhibition and Curation",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD with Integrated Study",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Investment (MSc)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance, Technology and Policy",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Modelling and Optimization",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Technology",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fire Engineering Science",
                    "university": "The University of Edinburgh",
                    "location": "King's Buildings",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Security",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French (MScR)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French (PhD)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Functional Genetics and Development",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Future Governance (Online Learning)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Future Infrastructure, Sustainability and Climate Change",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genetics and Genomics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genetics and Molecular Medicine (MRC Human Genetics Unit)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genomics and Experimental Medicine",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "GeoEnergy",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geographical Information Science",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology and Geophysics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "GeoSciences (Individual Project - Taught Pathway)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "GeoSciences (Individual Project)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geriatric Medicine",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German (MScR)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German (PhD)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Crime, Justice and Security",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Environment and Climate Change Law",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Environment, Politics and Society",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health",
                    "university": "The University of Edinburgh",
                    "location": "Multiple Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health Policy",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health Policy",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Mental Health and Society",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Premodern Art: History, Heritage and Curation",
                    "university": "The University of Edinburgh",
                    "location": "Edinburgh College of Art",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Strategy & Sustainability",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Globalised Muslim World, The",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Humanities and Arts",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health in Social Science",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hebrew and Old Testament Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "High Performance Computing",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "High Performance Computing with Data Science",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art, Theory and Display",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Christianity",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Anatomy",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Cognitive Neuropsychology",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Complex Trait Genetics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Geography and Environmental Sciences",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Osteoarchaeology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Rights",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Illustration",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Inclusive Education",
                    "university": "The University of Edinburgh",
                    "location": "Moray House School of Education and Sport",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Infection and Immunity",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Infection Medicine",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Infectious Diseases",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Inflammation",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Informatics: AIAI: Foundations and Applications of Artificial Intelligence, Automated Reasoning, Agents, Data Intensive Research",
                    "university": "The University of Edinburgh",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Informatics: ANC: Machine Learning, Computational Neuroscience, Computational Biology",
                    "university": "The University of Edinburgh",
                    "location": "Multiple Locations",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Informatics: ICSA: Computer Architecture, Compilation and System software, Networks and Communication",
                    "university": "The University of Edinburgh",
                    "location": "Multiple Locations",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Informatics: ILCC: Language Processing, Speech Technology, Information Retrieval, Cognition",
                    "university": "The University of Edinburgh",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Informatics: IPAB: Robotics, Computer Vision, Computer Graphics and Animation",
                    "university": "The University of Edinburgh",
                    "location": "Multiple Locations",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Informatics: LFCS: Theory and Foundations of Computer Science, Databases, Software and Systems Modelling",
                    "university": "The University of Edinburgh",
                    "location": "Multiple Locations",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Infrastructure and the Environment",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Innovation, Technology and the Law",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrated Micro and Nano Systems (IMNS)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrative Biomedical Sciences (Based in China)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrative Biomedical Sciences (Based in China)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrative Physiology (Biomedical Sciences - Centre for Discovery Brain Sciences)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intellectual History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intellectual Property Law",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior, Architectural and Spatial Design",
                    "university": "The University of Edinburgh",
                    "location": "Edinburgh College of Art",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intermediality",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD with Integrated Study",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intermediality: Literature, Film and the Other Arts",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International and European Politics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Banking Law and Finance",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Economic Law",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Resource Management",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Master of Science in Fire Safety Engineering",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Islamic and Middle Eastern Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Islamic and Middle Eastern Studies (IMES)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Islamic and Middle Eastern Studies (IMES)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Islamic Studies and Christian-Muslim Relations",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Japanese",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Japanese",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Korean Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Korean Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Landscape and Wellbeing",
                    "university": "The University of Edinburgh",
                    "location": "Edinburgh College of Art",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Landscape Architecture",
                    "university": "The University of Edinburgh",
                    "location": "Edinburgh College of Art",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Landscape Architecture",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MLA",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language and Intercultural Communication",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language Education",
                    "university": "The University of Edinburgh",
                    "location": "Moray House School of Education and Sport",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Late Antique, Islamic and Byzantine Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLM (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics and English Language",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (MScR)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management of Bioeconomy, Innovation and Governance",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management Science and Analytics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD with Integrated Study",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Systems and Policies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials and Processes",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Chemistry",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical Economics and Econometrics",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical Physics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical Physics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics Education",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Anthropology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Informatics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Law and Ethics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Sciences",
                    "university": "The University of Edinburgh",
                    "location": "Western General Hospital",
                    "qualification": "MMedSci",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicinal and Biological Chemistry",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mediterranean Archaeology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health in Children and Young People: Psychological Approaches",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mind, Language and Embodied Cognition",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern and Contemporary Art: History, Curating, Criticism",
                    "university": "The University of Edinburgh",
                    "location": "Edinburgh College of Art",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Musical Composition",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Narrative Futures: Art, Data, Society",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Narrative Futures: Art, Data, Society (Online Learning)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nationalism in Global Perspective",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience (Biomedical Sciences - Centre for Discovery Brain Sciences)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience (Integrative Neuroscience)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "New Testament and Christian Origins",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nuclear Physics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing with Pre-Registration (Adult)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MN",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Offshore Renewable Energy (Industrial Doctorate Centre)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "EngD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Operational Research",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Operational Research with Computational Optimisation",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Operational Research with Data Science",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Operational Research with Risk",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Optimization and Operational Research",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Orthopaedic and Trauma Medicine",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Palaeontology and Geobiology",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Particle and Nuclear Physics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Particle Physics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pathology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance Psychology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Activity for Health",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Planetary Health",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Population Health Sciences",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Probability and Stochastics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Graduate Diploma in Education (Primary)",
                    "university": "The University of Edinburgh",
                    "location": "Moray House School of Education and Sport",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Graduate Diploma in Education (Secondary)",
                    "university": "The University of Edinburgh",
                    "location": "Moray House School of Education and Sport",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Legal Practice",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychiatry",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological Research",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological Therapies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology of Individual Differences",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology of Language",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology of Mental Health (Conversion)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychotherapy and Counselling",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "DPsychotherapy",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health, Master of",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MPH",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Policy",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantitative Genetics and Genome Analysis",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Regenerative Medicine",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Regenerative Medicine and Tissue Repair",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religion and Literature",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MTh",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religious Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religious Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Reproductive Health",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Reproductive Sciences",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Respiratory Medicine",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scandinavian Studies MScR",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scandinavian Studies PhD",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science and Religion",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MPhil (Res)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science and Religion",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science and Technology in Society",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science and Technology Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science Communication and Public Engagement",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scottish Ethnology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scottish History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scottish History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sensor and Imaging Systems",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Service Management and Design",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Service Management and Design (Online Learning)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Signal Processing and Communications",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social and Political Science",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Psychology",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work, Master of",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSW",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Socio-cultural Studies",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Global Change",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Soils and Sustainability",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sound Design",
                    "university": "The University of Edinburgh",
                    "location": "Edinburgh College of Art",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sound Design",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "South Asian Studies",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish, Portuguese and Latin American Studies (Hispanic Studies)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish, Portuguese and Latin American Studies (Hispanic Studies) PhD",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Speech and Language Processing",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Policy, Management and International Development",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport, Physical Education and Health Sciences",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport, Physical Education and Health Sciences",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics and Operational Research",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics with Data Science",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strength and Conditioning",
                    "university": "The University of Edinburgh",
                    "location": "Moray House School of Education and Sport",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Surgery",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Energy Systems",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Lands and Cities",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Synthetic Biology and Biotechnology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Systematic Theology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Systems and Synthetic Biology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL)",
                    "university": "The University of Edinburgh",
                    "location": "Moray House School of Education and Sport",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "The Middle East in Global Politics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religious Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MTh",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology in History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MTh",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Strategies and Design",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "World Christianity",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "World Christianity",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Accounting (PhD with Integrated Study)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD with Integrated Study",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Acoustics and Music Technology",
                    "university": "The University of Edinburgh",
                    "location": "Edinburgh College of Art",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Nursing",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "African Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agriculture and Food Security",
                    "university": "The University of Edinburgh",
                    "location": "Royal (Dick) School of Veterinary Studies",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "American History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Analysis",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anatomical Sciences (Biomedical Sciences)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Breeding and Genetics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied and Computational Mathematics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Animal Behaviour and Animal Welfare",
                    "university": "The University of Edinburgh",
                    "location": "Royal (Dick) School of Veterinary Studies",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Conservation Genetics with Wildlife Forensics (Online Learning)",
                    "university": "The University of Edinburgh",
                    "location": "Royal (Dick) School of Veterinary Studies",
                    "qualification": "PgDip",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Environmental Hydrogeology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Linguistics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Medical Image Analysis (Online Learning)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Poultry Science (Online Learning)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural History",
                    "university": "The University of Edinburgh",
                    "location": "Edinburgh College of Art",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture by Design",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture, Landscape and Environment",
                    "university": "The University of Edinburgh",
                    "location": "Edinburgh College of Art",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art",
                    "university": "The University of Edinburgh",
                    "location": "Edinburgh College of Art",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Atmospheric and Environmental Sciences",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biblical Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MTh",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biodiversity and Taxonomy of Plants",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioengineering",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer (Edinburgh Cancer Research Centre)",
                    "university": "The University of Edinburgh",
                    "location": "Multiple Locations",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cardiovascular Science",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Centre for Doctoral Training in Artificial Intelligence for Biomedical Innovation",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD with Integrated Study",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Centre for Doctoral Training in Designing Responsible Natural Language Processing",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD with Integrated Study",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Child Life and Health",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Child Protection Data Futures",
                    "university": "The University of Edinburgh",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Child Protection Data Futures (Online Learning)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Circular Economy",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics (MScR)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics (PhD)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate Change Management",
                    "university": "The University of Edinburgh",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical and Health Psychology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Animal Behaviour (Online Learning)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Brain Sciences",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Education",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Education (Online Learning)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "Professional Development Diploma",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Management of Pain (Online Learning)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Microbiology and Infectious Diseases (Online Learning)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Ophthalmology (Online Learning)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "ChM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Trials (Online Learning)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "Professional Development Diploma",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Veterinary Sciences",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Science",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Collections and Curating Practices",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Commercial Law",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Private Law",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Public Policy",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Composition",
                    "university": "The University of Edinburgh",
                    "location": "Edinburgh College of Art",
                    "qualification": "MMus (Comp)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Mathematical Finance",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conflict, Security and Development",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary Art Practice",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary Art Theory",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Corporate Law",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PgDip",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling Studies",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling Studies (PhD)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Music Practice",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Law and Criminal Justice",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Criminal Justice",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cultural Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cultural Studies",
                    "university": "The University of Edinburgh",
                    "location": "Edinburgh College of Art",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security, Privacy and Trust",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data and Artificial Intelligence Ethics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data and Artificial Intelligence Ethics (Online Learning)",
                    "university": "The University of Edinburgh",
                    "location": "Multiple Locations",
                    "qualification": "PgCert",
                    "duration": "9 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data and Decision Analytics (Online Learning)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science for Health and Social Care (Online Learning)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data, Inequality and Society (Online Learning)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design",
                    "university": "The University of Edinburgh",
                    "location": "Edinburgh College of Art",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design and Digital Media",
                    "university": "The University of Edinburgh",
                    "location": "Edinburgh College of Art",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Developmental Linguistics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Developmental Science",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Communications",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Education (Online Learning)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PgDip",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Sociology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Doctor of Medicine",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MD",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth Observation and Geoinformation Management",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "East Asian Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecological Economics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecology, Evolution and Biodiversity",
                    "university": "The University of Edinburgh",
                    "location": "King's Buildings",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic and Social History",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic and Social History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics / Economics (Econometrics) / Economics (Finance)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "The University of Edinburgh",
                    "location": "Moray House School of Education and Sport",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Futures",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Futures (Online Learning)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Systems",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy, Society and Sustainability",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "The University of Edinburgh",
                    "location": "Multiple Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering with Integrated Study",
                    "university": "The University of Edinburgh",
                    "location": "King's Buildings",
                    "qualification": "PhD with Integrated Study",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature: Literature and Modernity: 1900 to the Present",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature: Literature and Society: Enlightenment, Romantic and Victorian",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment and Development",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment, Culture and Society",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Protection and Management",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Sustainability",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "EPCC: High Performance Computing, Computational & Data Science, Software Engineering",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Epidemiology (Online Learning)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ethics and Practical Theology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "European Law",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "European Theatre",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Evolution of Language and Cognition",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Evolutionary Genetics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film, Exhibition and Curation",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD with Integrated Study",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Modelling and Optimization",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Safety (Online Learning)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Security",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French (MScR)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French (PhD)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Functional Genetics and Development",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Future Governance (Online Learning)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Future Infrastructure, Sustainability and Climate Change",
                    "university": "The University of Edinburgh",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genetics and Genomics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genetics and Molecular Medicine (MRC Human Genetics Unit)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genomics and Experimental Medicine",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "GeoEnergy",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geographical Information Science",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology and Geophysics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "GeoSciences (Individual Project)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geriatric Medicine",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German (MScR)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German (PhD)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Crime, Justice and Security",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Environment and Climate Change Law",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Environment, Politics and Society",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health",
                    "university": "The University of Edinburgh",
                    "location": "Multiple Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health and Infectious Diseases (Online Learning)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health Policy",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health Policy",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Mental Health and Society",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Premodern Art: History, Heritage and Curation",
                    "university": "The University of Edinburgh",
                    "location": "Edinburgh College of Art",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Globalised Muslim World, The",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Humanities and Arts",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health in Social Science",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hebrew and Old Testament Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "High Performance Computing",
                    "university": "The University of Edinburgh",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "High Performance Computing with Data Science",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art, Theory and Display",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Christianity",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Cognitive Neuropsychology",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Complex Trait Genetics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Geography and Environmental Sciences",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Rights",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Inclusive Education",
                    "university": "The University of Edinburgh",
                    "location": "Moray House School of Education and Sport",
                    "qualification": "PgDip",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Infection and Immunity",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Infection Medicine",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Inflammation",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Informatics: AIAI: Foundations and Applications of Artificial Intelligence, Automated Reasoning, Agents, Data Intensive Research",
                    "university": "The University of Edinburgh",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Informatics: ANC: Machine Learning, Computational Neuroscience, Computational Biology",
                    "university": "The University of Edinburgh",
                    "location": "Multiple Locations",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Informatics: ICSA: Computer Architecture, Compilation and System software, Networks and Communication",
                    "university": "The University of Edinburgh",
                    "location": "Multiple Locations",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Informatics: ILCC: Language Processing, Speech Technology, Information Retrieval, Cognition",
                    "university": "The University of Edinburgh",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Informatics: IPAB: Robotics, Computer Vision, Computer Graphics and Animation",
                    "university": "The University of Edinburgh",
                    "location": "Multiple Locations",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Informatics: LFCS: Theory and Foundations of Computer Science, Databases, Software and Systems Modelling",
                    "university": "The University of Edinburgh",
                    "location": "Multiple Locations",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Infrastructure and the Environment",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Innovation, Technology and the Law",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrated Micro and Nano Systems (IMNS)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrative Physiology (Biomedical Sciences - Centre for Discovery Brain Sciences)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intellectual History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intellectual Property Law",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intermediality",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD with Integrated Study",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intermediality: Literature, Film and the Other Arts",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Economic Law",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Islamic and Middle Eastern Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Islamic and Middle Eastern Studies (IMES)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Islamic and Middle Eastern Studies (IMES)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Islamic Studies and Christian-Muslim Relations",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Japanese",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Japanese",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Korean Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Landscape and Wellbeing",
                    "university": "The University of Edinburgh",
                    "location": "Edinburgh College of Art",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Landscape Architecture",
                    "university": "The University of Edinburgh",
                    "location": "Edinburgh College of Art",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Late Antique, Islamic and Byzantine Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLM (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership and Learning",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MEd",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics and English Language",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD with Integrated Study",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Systems and Policies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials and Processes",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical Economics and Econometrics",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical Physics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "96 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics Education",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "96 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Informatics",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Law and Ethics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Sciences",
                    "university": "The University of Edinburgh",
                    "location": "Western General Hospital",
                    "qualification": "MMedSci",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mediterranean Archaeology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health in Children and Young People: Psychological Approaches (Online Learning)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mind, Language and Embodied Cognition",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern and Contemporary Art: History, Curating, Criticism",
                    "university": "The University of Edinburgh",
                    "location": "Edinburgh College of Art",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Musical Composition",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Narrative Futures: Art, Data, Society",
                    "university": "The University of Edinburgh",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Narrative Futures: Art, Data, Society (Online Learning)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience (Biomedical Sciences - Centre for Discovery Brain Sciences)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "New Testament and Christian Origins",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Operational Research",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Operational Research with Computational Optimisation",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Operational Research with Data Science",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Operational Research with Risk",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Optimization and Operational Research",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "96 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Orthopaedic and Trauma Medicine",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paediatric Emergency Medicine (Online Learning)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Palaeontology and Geobiology",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pathology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Patient Safety and Clinical Human Factors (Online Learning)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PET-MR Principles and Applications (Online Learning)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "Professional Development Diploma",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Activity for Health",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PgDip",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Planetary Health",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Population Health Sciences",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Probability and Stochastics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "96 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Legal Practice",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PgDip",
                    "duration": "21 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychiatry",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological Research",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological Therapies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology of Individual Differences",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology of Language",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology of Mental Health (Conversion)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychotherapy and Counselling",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "DPsychotherapy",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Policy",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantitative Genetics and Genome Analysis",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Regenerative Medicine",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religion and Literature",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MTh",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religious Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religious Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Reproductive Health",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Respiratory Medicine",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scandinavian Studies MScR",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scandinavian Studies PhD",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science and Religion",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science and Religion",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science and Technology Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scottish Ethnology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scottish History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scottish History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Service Management and Design",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PgCert",
                    "duration": "9 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Service Management and Design (Online Learning)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "9 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Simulation Based Clinical Education",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PgCert",
                    "duration": "9 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social and Political Science",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Justice and Community Action (Online Learning)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PgDip",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Psychology",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Socio-cultural Studies",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Soils and Sustainability",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sound Design",
                    "university": "The University of Edinburgh",
                    "location": "Edinburgh College of Art",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sound Design",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "South Asian Studies",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish, Portuguese and Latin American Studies (Hispanic Studies)",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish, Portuguese and Latin American Studies (Hispanic Studies) PhD",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Speech and Language Processing",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport, Physical Education and Health Sciences",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "96 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics and Operational Research",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics with Data Science",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Surgery",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Surgical Writing and Evidence Based Practice (Online Learning)",
                    "university": "The University of Edinburgh",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Lands and Cities",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PgCert",
                    "duration": "9 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Systematic Theology",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "The Middle East in Global Politics",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religious Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MTh",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology in History",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MTh",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation Studies",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Strategies and Design",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "World Christianity",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "World Christianity",
                    "university": "The University of Edinburgh",
                    "location": "Central area campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "872f3adc53476000a1bd315669469fb6",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Character Creation for Animation, Games & VFX",
                    "university": "Escape Studios",
                    "location": "Escape Studios",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Character Creation for Animation, Games & VFX (Integrated Masters)",
                    "university": "Escape Studios",
                    "location": "Escape Studios",
                    "qualification": "Master in Science (with Honours) \u2013 MSci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Concept Art and Experience Design",
                    "university": "Escape Studios",
                    "location": "Escape Studios",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Concept Art and Experience Design (Integrated Masters)",
                    "university": "Escape Studios",
                    "location": "Escape Studios",
                    "qualification": "MArt",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Programming for Games and VFX",
                    "university": "Escape Studios",
                    "location": "Escape Studios",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Programming for Games and VFX (Integrated Masters)",
                    "university": "Escape Studios",
                    "location": "Escape Studios",
                    "qualification": "Master in Science (with Honours) \u2013 MSci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Technical Art for Games & VFX",
                    "university": "Escape Studios",
                    "location": "Escape Studios",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Technical Art for Games & VFX (Integrated Masters)",
                    "university": "Escape Studios",
                    "location": "Escape Studios",
                    "qualification": "Master in Science (with Honours) \u2013 MSci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "The Art of Computer Animation (2D)",
                    "university": "Escape Studios",
                    "location": "Escape Studios",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "The Art of Computer Animation (2D) (Integrated Masters)",
                    "university": "Escape Studios",
                    "location": "Escape Studios",
                    "qualification": "MArts",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "The Art of Computer Animation (3D)",
                    "university": "Escape Studios",
                    "location": "Escape Studios",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "The Art of Computer Animation (3D) (Integrated Masters)",
                    "university": "Escape Studios",
                    "location": "Escape Studios",
                    "qualification": "MArt",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "The Art of Motion Graphics",
                    "university": "Escape Studios",
                    "location": "Escape Studios",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "The Art of Motion Graphics (Integrated Masters)",
                    "university": "Escape Studios",
                    "location": "Escape Studios",
                    "qualification": "MArt",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "The Art of Video Games",
                    "university": "Escape Studios",
                    "location": "Escape Studios",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "The Art of Video Games (Integrated Masters)",
                    "university": "Escape Studios",
                    "location": "Escape Studios",
                    "qualification": "MArt",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "The Art of Visual Effects",
                    "university": "Escape Studios",
                    "location": "Escape Studios",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "The Art of Visual Effects (Integrated Masters)",
                    "university": "Escape Studios",
                    "location": "Escape Studios",
                    "qualification": "MArt",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Video Games Design",
                    "university": "Escape Studios",
                    "location": "Escape Studios",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Video Games Design (Integrated Masters)",
                    "university": "Escape Studios",
                    "location": "Escape Studios",
                    "qualification": "Master in Science (with Honours) \u2013 MSci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "3D Animation",
                    "university": "Escape Studios",
                    "location": "Escape Studios",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Character & Creature Creation",
                    "university": "Escape Studios",
                    "location": "Escape Studios",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Concept Art and Experience Design",
                    "university": "Escape Studios",
                    "location": "Escape Studios",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Game Art",
                    "university": "Escape Studios",
                    "location": "Escape Studios",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Game Design",
                    "university": "Escape Studios",
                    "location": "Escape Studios",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Motion Graphics",
                    "university": "Escape Studios",
                    "location": "Escape Studios",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Technical Art for Games and VFX",
                    "university": "Escape Studios",
                    "location": "Escape Studios",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Effects Production (3D)",
                    "university": "Escape Studios",
                    "location": "Escape Studios",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Effects Production (Compositing)",
                    "university": "Escape Studios",
                    "location": "Escape Studios",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        }
    },
    {
        "id": "a10cd047eed73fe23fbe98370adb5456",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Management",
                    "university": "ESCP Business School",
                    "location": "Main Site - London",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "94a647439606a7f403ef6467dd507bbe",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "62999c069d3b0f49eb87db784faa79fd",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Bachelor of Science Global Political Science: Diplomacy",
                    "university": "European School of Economics",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bachelor of Science Global Political Science: Leadership",
                    "university": "European School of Economics",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bachelor of Science Global Political Science: Trade",
                    "university": "European School of Economics",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration : Finance",
                    "university": "European School of Economics",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration : Management",
                    "university": "European School of Economics",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration : Marketing",
                    "university": "European School of Economics",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration : Media and Communications",
                    "university": "European School of Economics",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration : Sports Management",
                    "university": "European School of Economics",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "2cc29e1f1dc03fc46452d1f9ce32ef39",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Aircraft Engineering",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "FdEng",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Applied Computing",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Applied Computing",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Architectural Technology for England(HTQ)",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Assistant Practitioner",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Building Services Engineering",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management (Top-Up)",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Civil Engineering (top-up)",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Coaching and Fitness",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Construction Management for England(HTQ)",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Electrical and Electronic Engineering for England (HTQ)",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering for England (HTQ)",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Esports Production and Management",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Esports Production and Management",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Football Coaching and Development",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "HNC in Quantity Surveying for England (HTQ)",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Manufacturing Engineering for England (HTQ)",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Manufacturing Engineering for England (HTQ)",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Mechanical Engineering for England (HTQ)",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Mechanical Engineering for England (HTQ)",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Therapy",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sports Therapy (Top Up)",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Applied Computing",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Technology for England(HTQ)",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Building Services Engineering",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management (Top-Up)",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Civil Engineering (top-up)",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Coaching and Fitness",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Construction Management for England(HTQ)",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Electrical and Electronic Engineering for England (HTQ)",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Electrical and Electronic Engineering for England (HTQ)",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Esports Production and Management",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Esports Production and Management",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "HNC in Quantity Surveying for England (HTQ)",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Manufacturing Engineering for England (HTQ)",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Manufacturing Engineering for England (HTQ)",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Mechanical Engineering for England (HTQ)",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Mechanical Engineering for England (HTQ)",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Therapy",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sports Therapy (Top Up)",
                    "university": "Exeter College",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "005a5c9262ec5d4e559907475c10c00b",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Business",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Business with Industrial Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Business with Year Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance with Industrial Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance with Year Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Adult Nursing",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History and Archaeology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History and Archaeology with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History and Archaeology with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History and Archaeology with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Behaviour",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Behaviour",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Behaviour with Professional Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Behaviour with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Psychology (Clinical)",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arabic and Islamic Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "MArabic",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arabic and Politics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeological Science",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeological Science with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeological Science with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeological Science with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and Anthropology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and Anthropology with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and Anthropology with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and Anthropology with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology with Forensic Science",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology with Forensic Science with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology with Forensic Science with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology with Forensic Science with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History & Visual Culture and Classical Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History & Visual Culture and Classical Studies with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History & Visual Culture and Drama",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History & Visual Culture and Drama with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History & Visual Culture and Drama with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History & Visual Culture and Drama with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History & Visual Culture and English",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History & Visual Culture and English with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History & Visual Culture and English with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History & Visual Culture and English with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History & Visual Culture and Film & Television Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History & Visual Culture and Film & Television Studies with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History & Visual Culture and Film & Television Studies with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History & Visual Culture and Film & Television Studies with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History & Visual Culture and History",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History & Visual Culture and History with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History & Visual Culture and History with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History & Visual Culture and History with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History & Visual Culture with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History & Visual Culture with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History and Visual Culture",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History and Visual Culture and Classical Studies with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History and Visual Culture and Classical Studies with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History and Visual Culture and Modern Languages",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History and Visual Culture and Modern Languages with International Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History and Visual Culture with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry with Industrial Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological and Medicinal Chemistry",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological and Medicinal Chemistry",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological and Medicinal Chemistry with Industrial Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological and Medicinal Chemistry with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences with Professional Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences with Professional Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "BSc Criminology with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics with Industrial Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Environment",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Environment with Industrial Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Environment with Year Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Laws",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BBL(Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Laws with Industrial Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BBL(Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Laws with Professional Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BBL(Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management with Industrial Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management with Year Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics with Industrial Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics with Year Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business with Industrial Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business with Year Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with Foundation Year",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with Foundation Year",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with Year in Industry",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with Year in Industry",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies and English",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies and English with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies and English with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies and English with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies and Modern Languages",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies and Modern Languages with International Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies and Philosophy",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies and Philosophy with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies and Philosophy with Employment Experience  Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies and Philosophy with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies and Religion",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies and Religion with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies and Religion with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies and Religion with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literatures and Cultures",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literatures and Cultures and Modern Languages",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literatures and Cultures and Modern Languages with International Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literatures and Cultures with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literatures and Cultures with Employment Experience UK",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literatures and Cultures with Year Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Mathematics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Mathematics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Mathematics with Industrial Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Foundation Year",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Industrial Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation Biology and Ecology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation Biology and Ecology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation Biology and Ecology with Professional Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation Biology and Ecology with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Crime and Data Science",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Crime and Data Science with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Crime and Data Science with Industrial Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Crime and Data Science with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "Msci (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science with Foundation Year",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science with Industrial Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama and Film & Television Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama and Film & Television Studies with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama and Film & Television Studies with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama and Film & Television Studies with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dual LLB / Juris Doctor (JD) with the Chinese University of Hong Kong",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance with Industrial Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance with Year Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Politics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Politics with Industrial Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Politics with Year Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Econometrics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Econometrics with Industrial Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Econometrics with Year Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Industrial Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Year Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with Foundation Year",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with Year in Industry",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with Year in Industry",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering and Entrepreneurship",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering and Entrepreneurship",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering and Entrepreneurship with Year in Industry",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering and Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering and Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering and Management with Year in Industry",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering and Management with Year in Industry",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Geology and Geotechnics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Geology and Geotechnics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering with Foundation Year",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Creative Writing",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Creative Writing with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Creative Writing with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Creative Writing with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Drama",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Drama with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Drama with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Drama with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Film & Television Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Film & Television Studies with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Film & Television Studies with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Film & Television Studies with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and History",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and History with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and History with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and History with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Media and Communications",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Media and Communications with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Media and Communications with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Media and Communications with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Modern Languages",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Modern Languages with International Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Law and French Law/Master 1 (Matrise)",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English with Study in North America",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Geoscience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Geoscience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Humanities",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Humanities with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Humanities with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Humanities with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Sciences with Professional Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Evolutionary Biology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Evolutionary Biology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Evolutionary Biology with Professional Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Evolutionary Biology with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Exercise and Sport Sciences",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Television Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Television Studies with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Television Studies with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Television Studies with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Studies and Media and Communications",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Studies and Media and Communications with Emp Exp",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Studies and Media and Communications with Emp Exp Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Studies and Media and Communications with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Studies and Modern Languages",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Studies and Modern Languages with International Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance with Industrial Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance with Year Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance: Business Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance: Business Management with Industrial Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance: Business Management with Year Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance: Data Science",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance: Data Science with Industrial Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance: Data Science with Year Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance: Investment Banking",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance: Investment Banking with Industrial Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance: Investment Banking with Year Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Flexible Combined Honours",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Flexible Combined Honours with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA/BSc (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Flexible Combined Honours with UK Work Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA/BSc (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Flexible Combined Honours with Work Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA/BSc (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with Applied GIS",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with Applied GIS with Professional Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with Applied GIS with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with Professional Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with Professional Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with Professional Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA/BSc (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA/BSc (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Politics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Politics with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Politics with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graduate Entry LLB",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Ancient History",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Ancient History with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Ancient History with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Ancient History with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Archaeology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Archaeology with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Archaeology with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Archaeology with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Business",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Business with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Business with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Business with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Geography",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Geography with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Geography with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Geography with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and International Relations",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and International Relations with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and International Relations with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and International Relations with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Modern Languages",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Modern Languages with International Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Politics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Politics with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Politics with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Politics with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Modern Languages",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Modern Languages with Year Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Modern Languages",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Modern Languages with International Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Business",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Business with Industrial Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Business with Professional Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with European Study",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Liberal Arts",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Liberal Arts with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Liberal Arts with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Liberal Arts with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "LLB Engineering and Law",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "LLB Law (Brickfields 1+2 partnership)",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "LLB Law (Brickfields 2+1 partnership)",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "LLB (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "LLB Law with Professional Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Biology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Biology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Biology with Professional Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Biology with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Science",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Science with Professional Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Science with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing and Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing and Management with Industrial Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing and Management with Year Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "MMath",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Data Science",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "Msci (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Data Science",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with a Year in Industry",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "MMath (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with a Year in Industry",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "MMath (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Accounting",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "Msci (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Accounting",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Accounting and a Year in Industry",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Accounting and a Year in Industry",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Economics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Economics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Economics and a Year in Industry",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Economics and a Year in Industry",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Finance",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Finance",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Finance and a Year in Industry",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Finance and a Year in Industry",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with International Study",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "MMath",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Management and a Year in Industry",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Management and a Year in Industry",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Professional Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "MMath",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Maths with Foundation Year",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Foundation Year",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Year in Industry",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Year in Industry",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communications",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communications and Modern Languages",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communications and Modern Languages with International Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communications with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communications with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communications with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Imaging (Diagnostic Radiography)",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BMBS",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Middle East Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mining Engineering",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mining Engineering",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and Arabic",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and Arabic with International Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and Latin",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and Latin with International Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages with International Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Sciences",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Sciences",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Sciences with Foundation Year",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Sciences with International Study",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience with Professional Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and History",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and History with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and History with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and History with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Modern Languages",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Modern Languages with International Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Politics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Politics with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Politics with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Sociology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Sociology with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Sociology with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Theology with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy, Religion and Ethics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy, Religion and Ethics with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Biophysics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "Master of Physics (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Biophysics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Foundation Year",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Professional Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Professional Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Quantum Technology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "Master of Physics (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Quantum Technology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics & International Relations with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Geography",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Geography with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Geography with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations (with Study Abroad)",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Management with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Manangement with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Modern Languages",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Modern Languages with International Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Sociology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Sociology with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, Philosophy and Economics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, Philosophy and Economics with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with Professional Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religion, Culture and Society",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religion, Culture and Society with Industrial Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religion, Culture and Society with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religion, Culture and Society with Work Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Renewable Energy Engineering",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Renewable Energy Engineering",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Renewable Energy Engineering with Foundation year",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Renewable Energy Engineering with Industrial Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Resource and Exploration Geology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Resource and Exploration Geology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics and Artificial Intelligence",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics and Artificial Intelligence",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics and Artificial Intelligence",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Data Science",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Data Science with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Data Science with Industrial Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Data Science with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Anthropology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Anthropology with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Anthropology with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Anthropology with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Criminology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Criminology with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Criminology with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Modern Languages",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Modern Languages with International Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Medical Sciences",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Medical Sciences with Professional Training Year",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Systems Engineering",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Systems Engineering",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religion",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religion with Employment Experience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religion with Employment Experience Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religion with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "Master of Physics (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology with Professional Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology with Study Abroad",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Biological Sciences",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Biological Sciences",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Biological Sciences and Business",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practitioner (Degree Apprenticeship)",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science with Business",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Materials",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Quantitative Methods (AQM) in Social Sciences",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Behaviour",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthrozoology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Data Science (Ecology and Evolution)",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Data Science (Environment and Sustainability)",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Data Science (Renewable Energy)",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Data Science and Modelling",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Data Science and Statistics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Security and Strategy",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Social Data Science",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arabic and Islamic Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History and Visual Culture",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioarchaeology: Forensic Anthropology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioarchaeology: Human Osteology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioarchaeology: Zooarchaeology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Data and Artificial Intelligence",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Ancient History",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Ancient History",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Education",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Pharmacy",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Psychology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "DClinPsy",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Commercial Law",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Complex Living Systems",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conflict, Security and Development",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation and Biodiversity",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation Science and Policy",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cornish Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Arts in Education",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creativity: Innovation and Business Strategy",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Critical Human Geographies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cultures and Environments of Health",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Curation: Contemporary Art and Cultural Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security Analytics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science with Artificial Intelligence",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diagnostic Radiographer (Degree Apprenticeship)",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Doctor of Medicine",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics (Pathway to PhD)",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics: Behavioural Insights",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Leadership and Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Child and Community Psychology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "DEdPsy",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Research",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Power and Smart Grids",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Business Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Business Management with Industrial Placement",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literary Studies (various pathways)",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Entrepreneurship and Innovation Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment and Human Health",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Truro Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Intelligence",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Intelligence",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ethnopolitical Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "European Politics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Evolutionary and Behavioural Ecology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Experimental Archaeology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Exploration and Mining Geology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Screen Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Data Science",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Investment",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Marketing",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Analysis and Fund Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Economics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Technology (FinTech)",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender Studies (Middle East and Islamic World)",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genomic Medicine",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genomic Medicine (Data Science)",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genomic Medicine (Online)",
                    "university": "University of Exeter",
                    "location": "Online Study",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography (Human and Physical)",
                    "university": "University of Exeter",
                    "location": "Multiple Locations",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geotechnical Engineering",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Governance",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Literatures and Cultures",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Security Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Sustainability Solutions",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graduate Law",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gulf Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Wellbeing",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Data Science",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Research Methods",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Healthcare and Medicine",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MPhil (Res)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Healthcare Leadership and Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interactive Storytelling Design: Video Games & Beyond",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intercultural Communication and Education",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intercultural Communication and International Business",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intercultural Communication and Migration",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interdisciplinary Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business (Online)",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Education",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Film Business",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Finance and Banking",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Resource Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Arabic",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Supply Chain Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Islamic Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Island Biodiversity and Conservation",
                    "university": "University of Exeter",
                    "location": "Jersey International Centre of Advanced Studies",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Kurdish Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Kurdish Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Business",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Business: Finance and Accounting",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Business: Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Technology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal Practice",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Lusophone Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Magic and Occult Science",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine and Coastal Sustainability",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Environmental Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Vertebrate Ecology and Conservation",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Maritime Historical Studies",
                    "university": "University of Exeter",
                    "location": "Multiple Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing and Business Analytics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing and Financial Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Laws",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Public Health (MPH)",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Public Health (online)",
                    "university": "University of Exeter",
                    "location": "Online Study",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical Finance",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Exeter",
                    "location": "Multiple Locations",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communications",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical History",
                    "university": "University of Exeter",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Imaging",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Mycology and Fungal Immunology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval Studies",
                    "university": "University of Exeter",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Middle East Politics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Middle East Politics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Middle East Politics and Arabic",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Middle East Politics and International Relations",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Middle East Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Middle East Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Minerals Processing",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mining and Minerals Engineering",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mining Engineering",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mining Environmental Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Money, Banking and Finance",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Clinical Psychology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Construction Design Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Economics: Development Economics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Geographical Information Science",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Neuroscience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Sports Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Sustainable Business Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Sustainable Business Management: Environment",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Sustainable Business Management: Tourism",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Sciences",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing",
                    "university": "University of Exeter",
                    "location": "Multiple Locations",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Offshore Renewable Energy",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "EngD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Operations Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paediatric Exercise and Health",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Palestine Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Palestine Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance Practice",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Sociology of Science",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy: Ethics and Society",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy: Mind, Body and Culture",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy: Science and the Natural World",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Thought",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations of the Middle East",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics of Energy, Infrastructure and Environment",
                    "university": "University of Exeter",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological Research Methods",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Conversion Online)",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Conversion)",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Administration",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MPA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Policy",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Publishing",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Renewable Energy",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Renewable Energy Engineering",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Roman Archaeology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Screen Acting and Performance",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Security and Data Science",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Security, Conflict and Human Rights",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Senior People Professional (Degree Apprenticeship)",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social and Organisational Psychology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Media and Digital Marketing",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Media Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Science Research",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Society and Culture",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Society and Culture: Meaning, Making, Consuming",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Society and Culture: Science and Technology Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Society, Culture and Media",
                    "university": "University of Exeter",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Socio-Legal Research",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Special Educational Needs",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Medicine",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Health Sciences",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Health Sciences",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategy and Security",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Development",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Engineering",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Finance and Climate Change",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Exeter campuses",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Futures",
                    "university": "University of Exeter",
                    "location": "Multiple Locations",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Futures",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Systems Thinking Practitioner (Degree Apprenticeship)",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL)",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Technology and Education Futures",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Technology, Creativity and Thinking in Education",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "The Exeter MBA",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MBA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre Practice",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religion",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religion",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Analytics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Water Engineering",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Weather and Climate Science",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Biological Sciences",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Biological Sciences and Business",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science with Business",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Quantitative Methods (AQM) in Social Sciences",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Behaviour",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA (Res)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Security and Strategy",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Social Data Science",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioarchaeology: Forensic Anthropology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioarchaeology: Human Osteology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioarchaeology: Zooarchaeology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Ancient History",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Associate in Psychology (Degree Apprenticeship)",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "1.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Education",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Pharmacy",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MSc (PG)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Practice",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "DClinPrac",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Research",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "DClinRes",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Commercial Law",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conflict, Security and Development",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation and Biodiversity",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Exeter",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creativity: Innovation and Business Strategy",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Critical Human Geographies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cultures and Environments of Health",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Research",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literary Studies (various pathways)",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment and Human Health",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Truro Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Evolutionary and Behavioural Ecology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Experimental Archaeology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Screen Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender Studies (Middle East and Islamic World)",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genomic Medicine",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genomic Medicine (Data Science)",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genomic Medicine (Online)",
                    "university": "University of Exeter",
                    "location": "Online Study",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geotechnical Engineering",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Governance",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Literatures and Cultures",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Security Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Sustainability Solutions",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gulf Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Data Science",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Research Methods",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Healthcare Leadership and Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PgDip",
                    "duration": "21 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interactive Storytelling Design: Video Games & Beyond",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intercultural Communication and Education",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intercultural Communication and International Business",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intercultural Communication and Migration",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interdisciplinary Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Arabic",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Islamic Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Island Biodiversity and Conservation",
                    "university": "University of Exeter",
                    "location": "Jersey International Centre of Advanced Studies",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Kurdish Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language and Literacy in Education",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Business",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Business: Finance and Accounting",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Business: Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Technology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal Practice",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Lusophone Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Magic and Occult Science",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Environmental Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Maritime Historical Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Laws",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Public Health (MPH)",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communications",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical History",
                    "university": "University of Exeter",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Imaging",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Mycology (online)",
                    "university": "University of Exeter",
                    "location": "Online Study",
                    "qualification": "MSc",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Mycology and Fungal Immunology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval Studies",
                    "university": "University of Exeter",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Middle East Politics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Middle East Politics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Middle East Politics and Arabic",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Middle East Politics and International Relations",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Middle East Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Middle East Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mining Engineering",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "PgDip",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mining Engineering (Professional)",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mining Environmental Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Clinical Psychology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Geographical Information Science",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Neuroscience",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paediatric Exercise and Health",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Palestine Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Palestine Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance Practice",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Sociology of Science",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy: Ethics and Society",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy: Mind, Body and Culture",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy: Science and the Natural World",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Thought",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations of the Middle East",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Practice Certificate in Independent and Supplementary Prescribing",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MSc",
                    "duration": "6 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological Research Methods",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological Therapies Practice and Research",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Conversion Online)",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Conversion)",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Administration",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MPA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Policy",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Publishing",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Roman Archaeology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Screen Acting and Performance",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Security and Data Science",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Senior Leader (Healthcare)(Degree Apprenticeship)",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Senior Leader Degree Apprenticeship",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social and Organisational Psychology",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Media and Digital Marketing",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Media Management",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Science Research",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Society and Culture",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Society and Culture: Meaning, Making, Consuming",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Society and Culture: Science and Technology Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Socio-Legal Research",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Medicine",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Health Sciences",
                    "university": "University of Exeter",
                    "location": "University of Exeter - St Luke's Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Development",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Futures",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Penryn Campus, Cornwall",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre Practice",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religion",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation Studies",
                    "university": "University of Exeter",
                    "location": "University of Exeter - Streatham Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "1bf4052a0561a98fb106443c57319fee",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Acting",
                    "university": "Falmouth University",
                    "location": "Fourth Monkey, London",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Acting",
                    "university": "Falmouth University",
                    "location": "Fourth Monkey, London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Acting",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Acting & Theatre Making",
                    "university": "Falmouth University",
                    "location": "Fourth Monkey, London",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Acting with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Animation",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Animation with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Animation with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Architecture",
                    "university": "Falmouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Business & Digital Marketing",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Business & Digital Marketing with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Business Entrepreneurship & Innovation",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Business Entrepreneurship & Innovation with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Business Management",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Business Management",
                    "university": "Falmouth University",
                    "location": "Bodmin College",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Business Management with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Commercial Photography",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Commercial Photography with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Commercial Photography with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Computer Science with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Computer Science with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Computing for Games",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Computing for Games with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Computing for Games with Professional Practice",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Costume Design for Film & Television",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Costume Design for Film & Television with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Costume Design for Film & Television with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Creative & Professional Writing",
                    "university": "Falmouth University",
                    "location": "Bodmin College",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Creative Advertising",
                    "university": "Falmouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Creative Advertising with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Creative Advertising with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Creative Events Management",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Creative Events Management with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Creative Music Technology",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Creative Music Technology",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Creative Music Technology with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Falmouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Creative Writing with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Dance & Choreography",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Dance & Choreography with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Digital Media",
                    "university": "Falmouth University",
                    "location": "Bodmin College",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Digital Media (Level 6 Top-Up)",
                    "university": "Falmouth University",
                    "location": "Bodmin College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Documentary & Editorial Photography",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Documentary & Editorial Photography with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Documentary & Editorial Photography with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Drawing",
                    "university": "Falmouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Drawing with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Drawing with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "English & Creative Writing",
                    "university": "Falmouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "English & Creative Writing with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Esports & Livestreaming",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Esports & Livestreaming with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Esports & Livestreaming with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fashion Design",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fashion Design with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Fashion Design with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fashion Marketing",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fashion Marketing with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Fashion Marketing with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fashion Photography",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fashion Photography with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Fashion Photography with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fashion Styling & Art Direction",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fashion Styling & Art Direction with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Fashion Styling & Art Direction with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Film",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Film with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Film with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Falmouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fine Art with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Fine Art with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Game Animation",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Game Animation with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Game Animation with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Game Art",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Game Art with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Game Art with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Game Development: Art",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Game Development: Art with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Game Development: Art with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Game Development: Design",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Game Development: Design with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Game Development: Design with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Game Development: Programming",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Game Development: Programming with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Game Development: Programming with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Game Development: Writing",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Game Development: Writing with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Game Development: Writing with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Falmouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Graphic Design with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Graphic Design with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Illustration",
                    "university": "Falmouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Illustration with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Illustration with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Interior Architecture",
                    "university": "Falmouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Interior Architecture with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Interior Design",
                    "university": "Falmouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Interior Design with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Journalism & Creative Writing",
                    "university": "Falmouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Journalism & Creative Writing with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Marine & Natural History Photography with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Marine and Natural History Photography",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Marine and Natural History Photography with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Marketing Communications",
                    "university": "Falmouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Marketing Communications with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Marketing Communications with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Media Production",
                    "university": "Falmouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Media Production with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Media Production with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Music",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Music",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Music Business",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Music Business with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Music with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Musical Theatre",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Musical Theatre with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Photography",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Photography with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Photography with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Popular Music",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Popular Music",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Popular Music with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Post Production & Visual Effects",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Post Production & Visual Effects with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Post Production & Visual Effects with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Professional Music (Business)",
                    "university": "Falmouth University",
                    "location": "WaterBear, Sheffield",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Professional Music (Business)",
                    "university": "Falmouth University",
                    "location": "WaterBear, Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Professional Music (Electronic Music)",
                    "university": "Falmouth University",
                    "location": "WaterBear, Sheffield",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Professional Music (Electronic Music)",
                    "university": "Falmouth University",
                    "location": "WaterBear, Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Professional Music (Electronic Music) with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "WaterBear, Sheffield",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Professional Music (Electronic Music) with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "WaterBear, Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Professional Music (Performance)",
                    "university": "Falmouth University",
                    "location": "WaterBear, Sheffield",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Professional Music (Performance)",
                    "university": "Falmouth University",
                    "location": "WaterBear, Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Professional Music (Performance) with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "WaterBear, Sheffield",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Professional Music (Performance) with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "WaterBear, Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Professional Music (Production)",
                    "university": "Falmouth University",
                    "location": "WaterBear, Sheffield",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Professional Music (Production)",
                    "university": "Falmouth University",
                    "location": "WaterBear, Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Professional Music (Production) with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "WaterBear, Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Professional Music (Production) with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "WaterBear, Sheffield",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Professional Music (Songwriting)",
                    "university": "Falmouth University",
                    "location": "WaterBear, Sheffield",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Professional Music (Songwriting)",
                    "university": "Falmouth University",
                    "location": "WaterBear, Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Professional Music (Songwriting) with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "WaterBear, Brighton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Professional Music (Songwriting) with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "WaterBear, Sheffield",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Robotics",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Robotics with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Software Engineering with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Software Engineering with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Sound Design",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Sound Design with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Sustainable Festival Management",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Sustainable Festival Management with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Sustainable Product Design",
                    "university": "Falmouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Sustainable Product Design with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Sustainable Tourism Management",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Sustainable Tourism Management with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Technical Theatre Arts",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Technical Theatre Arts with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Television & Film Production",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Television & Film Production with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Television & Film Production with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Textile Design",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Textile Design with Integrated Foundation Year",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Textile Design with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Theatre & Performance",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Theatre & Performance with Professional Placement",
                    "university": "Falmouth University",
                    "location": "Penryn Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "08044aa21e9fe35f3982f842f9309422",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Aeronautical Engineering",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Aeronautical Engineering",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Broadcast Media and Film Production - Two-year Accelerated Degree",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business and Events Management - Two-year Accelerated Degree",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business and Psychology - Two-year Accelerated Degree",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Management - Two-year Accelerated Degree",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Computing (Top-up)",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing and Systems Development",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computing and Systems Development",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Criminology with Sociology - Two-year Accelerated Degree",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Early Years Education and Practice (Top-up)",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Early Years, Education and Practice (Work Based)",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Education (Learning Support) (Top-up)",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Engineering  (Aeronautical) (Top-up)",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Engineering  (Electrical & Electronic) (Top-up)",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Engineering (Mechanical) (Top-up)",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "English Literature with Criminology - Two-year Accelerated Degree",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "English Literature with Sociology - Two-year Accelerated Degree",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Environmental Sciences and Sustainability - Two-year Accelerated Degree",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Fashion and Textiles with Business",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Game Design and Development - Two-year Accelerated Degree",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Graphic Design - Two-year Accelerated Degree",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Illustration - Two-year Accelerated Degree",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Learning Support",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Modern History and English Literature - Two-year Accelerated Degree",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Modern History with Criminology - Two-year Accelerated Degree",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Modern History with Psychology - Two-year Accelerated Degree",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Modern History with Sociology - Two-year Accelerated Degree",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Photography - Two-year Accelerated Degree",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Psychology with Criminology - Two-year Accelerated Degree",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Psychology with English Literature - Two-year Accelerated Degree",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Psychology with Sociology - Two-year Accelerated Degree",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sport Science and Performance Therapy",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sports Science (Human Performance) (Top-up)",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Therapeutic Counselling (Top-up)",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Engineering  (Aeronautical) (Top-up)",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Engineering  (Electrical & Electronic) (Top-up)",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Engineering (Mechanical) (Top-up)",
                    "university": "University Centre Farnborough",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "72"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "246759322ab0653830f5f32d9175a635",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Art Direction For Fashion (Accelerated Degree)",
                    "university": "Fashion Retail Academy",
                    "location": "Fashion Retail Academy",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Buying & Merchandising for Fashion (Accelerated Degree)",
                    "university": "Fashion Retail Academy",
                    "location": "Fashion Retail Academy",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Data Analytics for Retail",
                    "university": "Fashion Retail Academy",
                    "location": "Fashion Retail Academy",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fashion Business (Accelerated Degree)",
                    "university": "Fashion Retail Academy",
                    "location": "Fashion Retail Academy",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fashion Communication & Styling (Accelerated Degree)",
                    "university": "Fashion Retail Academy",
                    "location": "Fashion Retail Academy",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Marketing & Communication for Fashion (Accelerated Degree)",
                    "university": "Fashion Retail Academy",
                    "location": "Fashion Retail Academy",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Sustainability In Fashion (Accelerated Degree)",
                    "university": "Fashion Retail Academy",
                    "location": "Fashion Retail Academy",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "User Experience Design for Retail (Accelerated Degree)",
                    "university": "Fashion Retail Academy",
                    "location": "Fashion Retail Academy",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "International Fashion Marketing & Communications",
                    "university": "Fashion Retail Academy",
                    "location": "Fashion Retail Academy",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        }
    },
    {
        "id": "80e5aee603108971b5fda539bc0c968a",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Children, Young People and their Services",
                    "university": "Furness College",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Education",
                    "university": "Furness College",
                    "location": "Main Site",
                    "qualification": "BA Edu (H)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "180-240"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "eca97546b2045a1e2b2c02e5e116140d",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Audio Engineering and Production",
                    "university": "Futureworks",
                    "location": "Futureworks (Manchester)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Digital Animation with Illustration",
                    "university": "Futureworks",
                    "location": "Futureworks (Manchester)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Game and Interactive Audio",
                    "university": "Futureworks",
                    "location": "Futureworks (Manchester)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Game Art",
                    "university": "Futureworks",
                    "location": "Futureworks (Manchester)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Games Design",
                    "university": "Futureworks",
                    "location": "Futureworks (Manchester)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Independent Filmmaking",
                    "university": "Futureworks",
                    "location": "Futureworks (Manchester)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Music Production",
                    "university": "Futureworks",
                    "location": "Futureworks (Manchester)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Post Production for Film and TV",
                    "university": "Futureworks",
                    "location": "Futureworks (Manchester)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Visual Effects",
                    "university": "Futureworks",
                    "location": "Futureworks (Manchester)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "003ae2fa3d3f12646d351c7aa997445e",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Acting (Professional Performance Practice)",
                    "university": "Gateshead College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Dance (Professional Performance Practice)",
                    "university": "Gateshead College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Digital Technologies (Cyber Security & Cloud Networking) (HTQ)",
                    "university": "Gateshead College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Digital Technologies (Data Analytics) (HTQ)",
                    "university": "Gateshead College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Games Production",
                    "university": "Gateshead College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Live Events (Professional Performance Practice)",
                    "university": "Gateshead College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Music Performance (Professional Practice)",
                    "university": "Gateshead College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Music Production (Professional Performance Practice)",
                    "university": "Gateshead College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "86efdf460368ffad5e933a43d5e0234c",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "3D Animation and Visualisation",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Accountancy",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "135-144"
                },
                {
                    "course_name": "Accountancy (GCU Pathways)",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "75"
                },
                {
                    "course_name": "AI and Data Science",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Applied Psychology",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "141-144"
                },
                {
                    "course_name": "Audio Technology",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Biological Sciences (Direct Entry)",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "102-112"
                },
                {
                    "course_name": "Building Services Engineering",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Building Surveying",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Building Surveying (GCU Pathways)",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "54"
                },
                {
                    "course_name": "Business Management",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-114"
                },
                {
                    "course_name": "Business Management (GCU Pathways)",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "75"
                },
                {
                    "course_name": "Computing",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computing (GCU Pathways)",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "54"
                },
                {
                    "course_name": "Construction Management",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Construction Management (GCU Pathways)",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "54"
                },
                {
                    "course_name": "Cyber Security & Networks",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Cyber Security and Networks (GCU Pathways)",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "54"
                },
                {
                    "course_name": "Diagnostic Imaging",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "102-112"
                },
                {
                    "course_name": "Digital Design (3rd year entry)",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Security and Forensics",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Electrical and Electronic Engineering (GCU Pathway)",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "54"
                },
                {
                    "course_name": "Electrical Power Engineering",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Electrical Power Engineering",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Electrical Power Engineering (GCU Pathway)",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "54"
                },
                {
                    "course_name": "Environmental Civil Engineering",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Environmental Management",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Fashion Design with Business",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "135-144"
                },
                {
                    "course_name": "Finance Investment and Risk (GCU Pathways)",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "75"
                },
                {
                    "course_name": "Finance, Investment & Risk",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "108-112"
                },
                {
                    "course_name": "Food Science (3rd Year Entry)",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Investigation",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Games Development",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Human Nutrition and Dietetics",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "102-112"
                },
                {
                    "course_name": "International Business",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-114"
                },
                {
                    "course_name": "International Business and HRM (3rd year entry)",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and HRM (GCU Pathways)",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "75"
                },
                {
                    "course_name": "International Fashion Branding",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "135-144"
                },
                {
                    "course_name": "International Marketing",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-129"
                },
                {
                    "course_name": "International Tourism and Events Management",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-114"
                },
                {
                    "course_name": "International Tourism and Events Management (GCU Pathways)",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "75"
                },
                {
                    "course_name": "Law",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-144"
                },
                {
                    "course_name": "Law (Fast-Track)",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "LLB",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Mechanical Engineering (GCU Pathways)",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "54"
                },
                {
                    "course_name": "Media and Communication",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-114"
                },
                {
                    "course_name": "Multimedia Journalism",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-114"
                },
                {
                    "course_name": "Nursing Studies (Adult)",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64-72"
                },
                {
                    "course_name": "Nursing Studies (Child)",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64-72"
                },
                {
                    "course_name": "Nursing Studies (Learning Disability)",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64-72"
                },
                {
                    "course_name": "Nursing Studies (Mental Health)",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64-72"
                },
                {
                    "course_name": "Nursing Studies Hons (Adult)",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "102-112"
                },
                {
                    "course_name": "Nursing Studies Hons (Child)",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "102-112"
                },
                {
                    "course_name": "Nursing Studies Hons (Learning Disability)",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "102-112"
                },
                {
                    "course_name": "Nursing Studies Hons (Mental Health)",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "102-112"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "102-112"
                },
                {
                    "course_name": "Optometry (Direct Entry)",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Optometry with Independent Prescribing",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "MOptom",
                    "duration": "5 years",
                    "study_mode": "Full-time with advanced practice",
                    "ucas_points": "144-152"
                },
                {
                    "course_name": "Oral Health Science",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Orthoptics",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "102-112"
                },
                {
                    "course_name": "Paramedic Science",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "102-112"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "102-112"
                },
                {
                    "course_name": "Pharmacology (GCU Pathways)",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "54"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-152"
                },
                {
                    "course_name": "Podiatry",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "102-112"
                },
                {
                    "course_name": "Quantity Surveying",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Quantity Surveying (GCU Pathways)",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "54"
                },
                {
                    "course_name": "Radiotherapy and Oncology",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "102-112"
                },
                {
                    "course_name": "Risk Management",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "108-112"
                },
                {
                    "course_name": "Risk Management (GCU Pathways)",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "75"
                },
                {
                    "course_name": "Social Sciences",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-129"
                },
                {
                    "course_name": "Social Work",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "108-112"
                },
                {
                    "course_name": "Social Work (Postgraduate Entry)",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Software Development",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "3D Design for Virtual Environments",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting, Finance and Regulation (inc. fast-track)",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting, Finance and Regulation with Professional Practice",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Physiotherapy Practice",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Practice",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Data Science in Engineering",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Instrumentation and Control",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Big Data Technologies",
                    "university": "Glasgow Caledonian University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate Justice",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Glasgow Caledonian University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling Psychology",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "DPsych",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diagnostic Imaging",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diagnostic Radiography (Pre-Registration)",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media and Content Creation",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Doctorate of Business Administration (DBA)",
                    "university": "Glasgow Caledonian University",
                    "location": "GCU London",
                    "qualification": "DBA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Power Engineering",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Management (Waste, Energy, Water, Oil and Gas)",
                    "university": "Glasgow Caledonian University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion and Lifestyle Marketing",
                    "university": "Glasgow Caledonian University",
                    "location": "GCU London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Technology",
                    "university": "Glasgow Caledonian University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Master of Business Administration (MBA)",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global MBA Masters of Business Administration (with additional pathways in Public Health or Insurance)",
                    "university": "Glasgow Caledonian University",
                    "location": "GCU London",
                    "qualification": "MBA",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "DPsych",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management with Professional Practice",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Rights",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Rights with Professional Practice",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Insurance and Sustainable Risk Management",
                    "university": "Glasgow Caledonian University",
                    "location": "GCU London",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Banking and Finance",
                    "university": "Glasgow Caledonian University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management with Professional Practice",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law with Professional Practice",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Construction Project Management",
                    "university": "Glasgow Caledonian University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Economic and Social Justice",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Economic and Social Justice with Professional Practice",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Fashion Marketing",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Fashion Marketing with Professional Practice",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Resource Management",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Resource Management with Professional Practice",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management and Business Development",
                    "university": "Glasgow Caledonian University",
                    "location": "GCU London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management and Business Development with Professional Practice",
                    "university": "Glasgow Caledonian University",
                    "location": "GCU London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Marketing",
                    "university": "Glasgow Caledonian University",
                    "location": "GCU London",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Marketing with Professional Practice",
                    "university": "Glasgow Caledonian University",
                    "location": "GCU London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Operations and Supply Chain Management",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Operations and Supply Chain Management with Professional Practice",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tourism and Events Management",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tourism and Events Management with Professional Practice",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Investigative Ophthalmology and Vision Research",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Luxury Brand Management",
                    "university": "Glasgow Caledonian University",
                    "location": "GCU London",
                    "qualification": "MBA",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Luxury Marketing",
                    "university": "Glasgow Caledonian University",
                    "location": "GCU London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Luxury Marketing with Professional Practice",
                    "university": "Glasgow Caledonian University",
                    "location": "GCU London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing with Professional Practice",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Public Health",
                    "university": "Glasgow Caledonian University",
                    "location": "Multiple Locations",
                    "qualification": "MPH",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Bioscience",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Multimedia Journalism",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing Studies Adult (Pre-registration)",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "28 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing: Advancing Professional Practice",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Therapy (pre-registration)",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy (pre-registration)",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantity Surveying",
                    "university": "Glasgow Caledonian University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Risk Management",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Risk Management with Professional Practice",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Innovation",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Innovation with Professional Practice",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports and Exercise Psychology",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "DPsych",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Fashion Business",
                    "university": "Glasgow Caledonian University",
                    "location": "GCU London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Fashion Business with Professional Practice",
                    "university": "Glasgow Caledonian University",
                    "location": "GCU London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Television Fiction Writing",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "User Experience and Interaction Design",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Physiotherapy Practice",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Practice",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Big Data Technologies",
                    "university": "Glasgow Caledonian University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate Justice",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Glasgow Caledonian University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diagnostic Imaging",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diagnostic Radiography (Pre-Registration)",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Power Engineering",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Management (Waste, Energy, Water, Oil and Gas)",
                    "university": "Glasgow Caledonian University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion and Lifestyle Marketing",
                    "university": "Glasgow Caledonian University",
                    "location": "GCU London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Technology",
                    "university": "Glasgow Caledonian University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global MBA Masters of Business Administration (with additional pathways in Public Health or Insurance)",
                    "university": "Glasgow Caledonian University",
                    "location": "GCU London",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "2.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Insurance and Sustainable Risk Management",
                    "university": "Glasgow Caledonian University",
                    "location": "GCU London",
                    "qualification": "MSc",
                    "duration": "2.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Banking and Finance",
                    "university": "Glasgow Caledonian University",
                    "location": "GCU London",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Construction Project Management",
                    "university": "Glasgow Caledonian University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management and Business Development",
                    "university": "Glasgow Caledonian University",
                    "location": "GCU London",
                    "qualification": "MSc",
                    "duration": "2.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Marketing",
                    "university": "Glasgow Caledonian University",
                    "location": "GCU London",
                    "qualification": "MSc",
                    "duration": "2.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Investigative Ophthalmology and Vision Research",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Luxury Brand Management",
                    "university": "Glasgow Caledonian University",
                    "location": "GCU London",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Luxury Marketing",
                    "university": "Glasgow Caledonian University",
                    "location": "GCU London",
                    "qualification": "MSc",
                    "duration": "2.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Public Health",
                    "university": "Glasgow Caledonian University",
                    "location": "Multiple Locations",
                    "qualification": "MPH",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Ultrasound",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing: Advancing Professional Practice",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantity Surveying",
                    "university": "Glasgow Caledonian University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Fashion Business",
                    "university": "Glasgow Caledonian University",
                    "location": "GCU London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Television Fiction Writing",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theory of Podiatric Surgery",
                    "university": "Glasgow Caledonian University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "User Experience and Interaction Design",
                    "university": "Glasgow Caledonian University",
                    "location": "Glasgow Caledonian University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "f04f638b7bbc6e8cce33939f1ecec58e",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Contemporary Art Practice (top-up)",
                    "university": "City of Glasgow College",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Practice (Top-up)",
                    "university": "City of Glasgow College",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Photography (top-up)",
                    "university": "City of Glasgow College",
                    "location": "City Campus",
                    "qualification": "BA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Rehabilitation (top-up)",
                    "university": "City of Glasgow College",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Television (top-up)",
                    "university": "City of Glasgow College",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "b3a66d34d2496f76fba341faef538c60",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "3D Modelling",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "114-128"
                },
                {
                    "course_name": "Architecture",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "BArch (Hons)",
                    "duration": "3 to 5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "114-128"
                },
                {
                    "course_name": "Communication Design",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "114-128"
                },
                {
                    "course_name": "Fashion Design",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "114-128"
                },
                {
                    "course_name": "Fashion Narrative",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "114-128"
                },
                {
                    "course_name": "Fine Art - Painting and Printmaking",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "114-128"
                },
                {
                    "course_name": "Fine Art - Photography",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "114-128"
                },
                {
                    "course_name": "Fine Art - Sculpture and Environmental Art",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "114-128"
                },
                {
                    "course_name": "Games and Virtual Reality",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "114-128"
                },
                {
                    "course_name": "Interaction Design",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "114-128"
                },
                {
                    "course_name": "Interior Design",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "114-128"
                },
                {
                    "course_name": "International Foundation (Art and Design)",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "114-128"
                },
                {
                    "course_name": "Product Design",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "BDes (Hon)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "114-128"
                },
                {
                    "course_name": "Silversmithing & Jewellery Design",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "114-128"
                },
                {
                    "course_name": "Sound for the Moving Image",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "BDes (Hon)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "114-128"
                },
                {
                    "course_name": "Textile Design",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "114-128"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Architectural Studies",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "MArch",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication Design",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "MDes",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Curatorial Practice (Contemporary Art)",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Innovation and Circular Economy",
                    "university": "Glasgow School of Art",
                    "location": "GSA Highlands and Islands Creative Campus",
                    "qualification": "MDes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Innovation and Citizenship",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "MDes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Innovation and Environmental Design",
                    "university": "Glasgow School of Art",
                    "location": "GSA Highlands and Islands Creative Campus",
                    "qualification": "MDes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Innovation and Future Heritage",
                    "university": "Glasgow School of Art",
                    "location": "GSA Highlands and Islands Creative Campus",
                    "qualification": "MDes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Innovation and Interaction Design",
                    "university": "Glasgow School of Art",
                    "location": "GSA Highlands and Islands Creative Campus",
                    "qualification": "MDes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Innovation and Service Design",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "MDes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Innovation and Transformation Design",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "MDes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diploma in Architecture",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "DipArch",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion and Textiles",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "MDes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art Practice",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphics Illustration Photography",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "MDes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Heritage Visualisation",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Design",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "MDes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Fine Art",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "MFA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Visualisation and Human Anatomy",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design Engineering",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Serious Games and Virtual Reality",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sound for the Moving Image",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "MDes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Diploma in Architecture",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "DipArch",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Serious Games and Virtual Reality",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sound for the Moving Image",
                    "university": "Glasgow School of Art",
                    "location": "Garnethill Campus",
                    "qualification": "MDes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "2c2dd76c4ac9e0613d9ac50c0b9f6bb6",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Art and Design: Fine Art",
                    "university": "Gloucestershire College",
                    "location": "Gloucester",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Art and Design: Graphic Design",
                    "university": "Gloucestershire College",
                    "location": "Gloucester",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Art and Design: Graphic Design",
                    "university": "Gloucestershire College",
                    "location": "Gloucester",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business",
                    "university": "Gloucestershire College",
                    "location": "Cheltenham",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business",
                    "university": "Gloucestershire College",
                    "location": "Cheltenham",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computing",
                    "university": "Gloucestershire College",
                    "location": "Cheltenham",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computing HTQ",
                    "university": "Gloucestershire College",
                    "location": "Cheltenham",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Creative Media Production: Film and Moving Image",
                    "university": "Gloucestershire College",
                    "location": "Gloucester",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Creative Media Production: Film and Moving Image",
                    "university": "Gloucestershire College",
                    "location": "Gloucester",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Early Years",
                    "university": "Gloucestershire College",
                    "location": "Gloucester",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Fashion and Textiles",
                    "university": "Gloucestershire College",
                    "location": "Cheltenham",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Fashion and Textiles",
                    "university": "Gloucestershire College",
                    "location": "Cheltenham",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Film Production with Applied Technology Top Up Degree",
                    "university": "Gloucestershire College",
                    "location": "Gloucester",
                    "qualification": "BA/BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Games Development",
                    "university": "Gloucestershire College",
                    "location": "Cheltenham",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Games Development",
                    "university": "Gloucestershire College",
                    "location": "Cheltenham",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Health and Social Care Healthcare Professions' Support HTQ",
                    "university": "Gloucestershire College",
                    "location": "Gloucester",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Music",
                    "university": "Gloucestershire College",
                    "location": "Gloucester",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Music",
                    "university": "Gloucestershire College",
                    "location": "Gloucester",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Performing Arts",
                    "university": "Gloucestershire College",
                    "location": "Gloucester",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Performing Arts",
                    "university": "Gloucestershire College",
                    "location": "Gloucester",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Photography",
                    "university": "Gloucestershire College",
                    "location": "Cheltenham",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Photography",
                    "university": "Gloucestershire College",
                    "location": "Cheltenham",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Therapeutic Counselling",
                    "university": "Gloucestershire College",
                    "location": "Cheltenham",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Business",
                    "university": "Gloucestershire College",
                    "location": "Cheltenham",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Construction and the Built Environment HTQ",
                    "university": "Gloucestershire College",
                    "location": "Cheltenham",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "5fbedff2be2018a773b2c016d1b3c91f",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Business Management",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Accounting and Business Management (Level 6)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Business Management (with placement)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Accounting and Finance (Level 6)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance (with Foundation)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Accounting and Finance (with placement)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Animation",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Animation (with Foundation)",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Gloucestershire",
                    "location": "Francis Close Hall - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Biology",
                    "university": "University of Gloucestershire",
                    "location": "Francis Close Hall - Cheltenham",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Biology (with placement)",
                    "university": "University of Gloucestershire",
                    "location": "Francis Close Hall - Cheltenham",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Gloucestershire",
                    "location": "Francis Close Hall - Cheltenham",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Biomedical Science (with placement)",
                    "university": "University of Gloucestershire",
                    "location": "Francis Close Hall - Cheltenham",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Business and Management",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business and Management (Digital Business)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business and Management (Enterprise and Innovation)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business and Management (Human Resource Management)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business and Management (Law)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business and Management (with Foundation)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business and Management (with placement)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business and Marketing Management",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business and Marketing Management (with placement)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business Management and Strategy (Level 6)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and Strategy (Level 6)",
                    "university": "University of Gloucestershire",
                    "location": "Yeovil College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical and Health Psychology",
                    "university": "University of Gloucestershire",
                    "location": "City Campus - Gloucester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Communications and Media",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Communications and Media (with Placement)",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Computer Games Design",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Computer Games Design (with Foundation)",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computer Games Design (with placement)",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Computer Games Programming",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Computer Games Programming (with Foundation)",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computer Games Programming (with placement)",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Computer Science (with Foundation)",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computer Science (with placement)",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Computing (Level 6)",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Gloucestershire",
                    "location": "Francis Close Hall - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Gloucestershire",
                    "location": "Francis Close Hall - Cheltenham",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Criminology and Psychology",
                    "university": "University of Gloucestershire",
                    "location": "City Campus - Gloucester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "University of Gloucestershire",
                    "location": "Francis Close Hall - Cheltenham",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Cyber Security (New College Swindon)",
                    "university": "University of Gloucestershire",
                    "location": "New College Swindon",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Cyber Security (with Foundation)",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Cyber Security (with placement)",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Dance",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Dance (Level 6)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science (HTQ)",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Diagnostic Radiography",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Drama and Performance Practice",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Drama and Performance Practice (Level 6)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "University of Gloucestershire",
                    "location": "City Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Ecology and Environmental Science",
                    "university": "University of Gloucestershire",
                    "location": "Francis Close Hall - Cheltenham",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Ecology and Environmental Science (with placement)",
                    "university": "University of Gloucestershire",
                    "location": "Francis Close Hall - Cheltenham",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Education, Inclusion and Special Educational Needs",
                    "university": "University of Gloucestershire",
                    "location": "City Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Education, Inclusion and Special Educational Needs (with Foundation)",
                    "university": "University of Gloucestershire",
                    "location": "Francis Close Hall - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Education, Inclusion and Special Educational Needs (with placement)",
                    "university": "University of Gloucestershire",
                    "location": "City Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Gloucestershire",
                    "location": "Francis Close Hall - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "English Literature and Creative Writing",
                    "university": "University of Gloucestershire",
                    "location": "Francis Close Hall - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Events Management",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Events Management (with Foundation)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Events Management (with placement)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Film and Television Production (Level 6) (Institute of Technology)",
                    "university": "University of Gloucestershire",
                    "location": "Institute of Technology (Swindon)",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Production",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Gloucestershire",
                    "location": "Francis Close Hall - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Fine Art (with Foundation)",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "University of Gloucestershire",
                    "location": "City Campus - Gloucester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Games Art",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Games Art (with placement)",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Gloucestershire",
                    "location": "Francis Close Hall - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Gloucestershire",
                    "location": "Francis Close Hall - Cheltenham",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Geography (fast-track)",
                    "university": "University of Gloucestershire",
                    "location": "Francis Close Hall - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Geography (fast-track)",
                    "university": "University of Gloucestershire",
                    "location": "Francis Close Hall - Cheltenham",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Geography (with placement)",
                    "university": "University of Gloucestershire",
                    "location": "Francis Close Hall - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Geography (with placement)",
                    "university": "University of Gloucestershire",
                    "location": "Francis Close Hall - Cheltenham",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Graphic Design (with Foundation)",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "University of Gloucestershire",
                    "location": "City Campus - Gloucester",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Healthcare Science (Ophthalmic Imaging)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Healthcare Science (Vascular Science)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "History",
                    "university": "University of Gloucestershire",
                    "location": "Francis Close Hall - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Human Geography",
                    "university": "University of Gloucestershire",
                    "location": "Francis Close Hall - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Human Geography (with placement)",
                    "university": "University of Gloucestershire",
                    "location": "Francis Close Hall - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Illustration",
                    "university": "University of Gloucestershire",
                    "location": "Francis Close Hall - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Illustration (with Foundation)",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Interior Design",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Interior Design (with Foundation)",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "International Business Management",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "International Business Management (with Foundation)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "International Business Management (with placement)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "International Hospitality and Tourism Management",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "International Hospitality and Tourism Management (with placement)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Journalism",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Journalism and Communications (Level 6)",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Landscape Architecture",
                    "university": "University of Gloucestershire",
                    "location": "Francis Close Hall - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Landscape Architecture (with Foundation)",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Law",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Law (Corporate Law)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Law (Individual Rights)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Law (with Foundation)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Law (with placement)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Marketing (Advertising and Branding)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Marketing (Consumer Cultures)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Marketing (Digital Innovation)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Marketing (with Foundation)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Marketing (with placement)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Mechatronics Engineering",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Mechatronics Engineering (with Foundation)",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Mechatronics Engineering (with placement)",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Business",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Music Business (with placement)",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing (Adult) (Blended Learning)",
                    "university": "University of Gloucestershire",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing (Learning Disabilities)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Operating Department Practice",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Paramedic Science",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Performing Arts",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Performing Arts (Level 6)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Photography",
                    "university": "University of Gloucestershire",
                    "location": "Francis Close Hall - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Photography (with Foundation)",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Photography: Editorial and Advertising",
                    "university": "University of Gloucestershire",
                    "location": "Francis Close Hall - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Photography: Editorial and Advertising (with Foundation)",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Photojournalism and Documentary Photography",
                    "university": "University of Gloucestershire",
                    "location": "Francis Close Hall - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Photojournalism and Documentary Photography (with Foundation)",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Physical Education",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Physical Education (with Foundation)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Popular Music",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Primary Education with QTS (ages 3-7)",
                    "university": "University of Gloucestershire",
                    "location": "City Campus - Gloucester",
                    "qualification": "BEd (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Primary Education with QTS (ages 5-11)",
                    "university": "University of Gloucestershire",
                    "location": "City Campus - Gloucester",
                    "qualification": "BEd (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Primary Education with QTS (Maths Specialism, ages 5-11)",
                    "university": "University of Gloucestershire",
                    "location": "City Campus - Gloucester",
                    "qualification": "BEd (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "University of Gloucestershire",
                    "location": "Francis Close Hall - Cheltenham",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Gloucestershire",
                    "location": "City Campus - Gloucester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Psychology, Health and Wellbeing (Level 6)",
                    "university": "University of Gloucestershire",
                    "location": "City Campus - Gloucester",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religion, Philosophy and Ethics",
                    "university": "University of Gloucestershire",
                    "location": "Francis Close Hall - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Gloucestershire",
                    "location": "City Campus - Gloucester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Gloucestershire",
                    "location": "Yeovil College",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Gloucestershire",
                    "location": "Francis Close Hall - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Sound and Music Production",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Sport and Exercise Sciences",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Sport and Exercise Sciences (with Foundation)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sport Business Management",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sport Business Management (High Performance Leadership)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sport Business Management (with Foundation)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sport Business Management (with placement)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sport, Coaching and Physical Education",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Sport, Coaching and Physical Education (with Foundation)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sports Journalism",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Sports Journalism (with placement)",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Sports Journalism and Communications (Level 6)",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Performance",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Sports Therapy",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Sports Therapy (with Foundation)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Strength, Conditioning and Rehabilitation",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Strength, Conditioning and Rehabilitation (with Foundation)",
                    "university": "University of Gloucestershire",
                    "location": "Oxstalls Campus - Gloucester",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Television Production",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Visual Communication (Level 6)",
                    "university": "University of Gloucestershire",
                    "location": "Park Campus - Cheltenham",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Wildlife Conservation Biology",
                    "university": "University of Gloucestershire",
                    "location": "Francis Close Hall - Cheltenham",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Wildlife Conservation Biology (with placement)",
                    "university": "University of Gloucestershire",
                    "location": "Francis Close Hall - Cheltenham",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Working with Children, Young People and Families",
                    "university": "University of Gloucestershire",
                    "location": "City Campus - Gloucester",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Zoology",
                    "university": "University of Gloucestershire",
                    "location": "Francis Close Hall - Cheltenham",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Zoology (with placement)",
                    "university": "University of Gloucestershire",
                    "location": "Francis Close Hall - Cheltenham",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "6e1ed1eef369a03d6e78403cd6282c1d",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Anthropology",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and Sociology",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology with Foundation Year (Integrated Degree)",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology, Media and Digital Practice",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Psychosocial and Psychotherapy Studies",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts Management",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Computing (Entrepreneurship)",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Computing (Entrepreneurship) with Foundation Year (Integrated Degree)",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Artificial Intelligence and Data Science)",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Cybersecurity)",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Integrated Degree)",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Software Engineering)",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (User Experience)",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Computing",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Computing with Foundation Year (Integrated Degree)",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Curating",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Arts Computing",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diverse Dance Styles",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Drama and Theatre Arts",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama and Theatre Arts with Acting",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama and Theatre Arts with Design and Technical Practice",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama: Applied Theatre and Community Performance",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama: Musical Theatre",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Data Science",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Law",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Marketing",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English (with Integrated Foundation Year)",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English with Creative Writing",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English with Creative Writing (with Integrated Foundation Year)",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment and Social Change",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art (Extension Degree)",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art and History of Art",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Development",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History (with Integrated Foundation Year)",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History with Politics",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History with Politics (with Integrated Foundation Year)",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations (with Integrated Foundation Year)",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Law with Criminal Justice and Human Rights",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Politics and Human Rights",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Economics",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Marketing",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communications",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communications with Foundation Year (Integrated Degree)",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BMus (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music with Foundation Year (Integrated Degree)",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BMus (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics (with Integrated Foundation Year)",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations (with Integrated Foundation Year)",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, Philosophy and Economics",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, Philosophy and Economics (with Integrated Foundation Year)",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Popular Music",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BMus (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Promotional Media: PR, Advertising and Branding",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with Clinical Psychology",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with Cognitive Neuroscience",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with Forensic Psychology",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with Foundation Year (Integrated Degree)",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social and Community Work",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Science, Community Development and Youth Work",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Social Work (Postgraduate Entry)",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology (with Integrated Foundation Year)",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology with Criminology",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology with Criminology (with Integrated Foundation Year)",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Anthropology",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 to 6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Psychosocial and Psychotherapy Studies",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Computing (Entrepreneurship)",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 to 7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 to 8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Artificial Intelligence and Data Science)",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 to 8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Cybersecurity)",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 to 8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Software Engineering)",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 to 8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (User Experience)",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 to 8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Computing",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama and Theatre Arts with Acting",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama: Applied Theatre and Community Performance",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 to 6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 to 6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Development",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 to 7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 to 6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 to 6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History with Politics",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 to 6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BMus (Hon)",
                    "duration": "4 to 6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Popular Music",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BMus (Hon)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Goldsmiths, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Advanced Practices",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Practices",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and Museum Practice",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Anthropology and Community and Youth Work",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Anthropology and Community Development/Community Arts",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Theatre: Drama in Educational, Community and Social Contexts",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "Grad Dip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Ecology",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA (PG)",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Politics",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art Practice and Learning",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art Psychotherapy",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art Psychotherapy",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artists' Film and Moving Image",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts Administration and Cultural Policy",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts Administration and Cultural Policy: Music Pathway",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts and Computational Technology",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts and Learning",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Black British History",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Black British Literature",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Brands, Communication and Culture",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children's Literature",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children's Literature: Children's Book Illustration",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Behavioural Therapy",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communities and Social Justice",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Arts",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Arts",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MFA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Cognitive Neuroscience",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Linguistics",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Games Programming",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Games: Art and Design",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary Art History",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "Grad Dip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary Art Theory",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling and Psychotherapy",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative & Cultural Entrepreneurship",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative and Cultural Entrepreneurship",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative and Cultural Entrepreneurship: Computing Pathway",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative and Cultural Entrepreneurship: Design Management Pathway",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA (PG)",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative and Cultural Entrepreneurship: Design Pathway",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative and Cultural Entrepreneurship: Fashion Management Pathway",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA (PG)",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative and Cultural Entrepreneurship: Fashion Pathway",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative and Cultural Entrepreneurship: Leadership Pathway",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative and Cultural Entrepreneurship: Media and Communications Pathway",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative and Cultural Entrepreneurship: Music Pathway",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative and Cultural Entrepreneurship: Social Innovation Pathway",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative and Cultural Entrepreneurship: Theatre and Performance Pathway",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative and Life Writing",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Practice",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MFA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Practice",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MMus",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing and Education",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Justice",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Justice and Human Rights",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cultural Studies",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Curating",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MFA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dance Movement Psychotherapy",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dance Movement Psychotherapy",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Artificial Intelligence",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design: Expanded Practice",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Designing Education",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Journalism",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media - Theory",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dramaturgy and Writing for Performance",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education: Culture, Language and Identity",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English, Comparative Literature or Linguistics",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Events and Experience Management",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Screen Studies",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Screen Studies",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Filmmaking (Cinematography)",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Filmmaking (Directing Fiction)",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Filmmaking (Editing)",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Filmmaking (Producing)",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Filmmaking (Screen Documentary)",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Filmmaking (Sound Recording, Post-Production and Design)",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MFA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Foundations in Clinical Psychology and Health Services",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games and Playful Design",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender, Media and Culture",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Politics: Theory and Activism",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Rights, Culture and Social Justice",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Rights",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Retail Business, Sustainability and Technologies",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Investigative Journalism",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Literary and Critical Theory",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Literary Studies",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Luxury Brand Management",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management of Innovation",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing and Consumer Behaviour",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing and Technology",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communications",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communications",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communications",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media, Communications and Cultural Studies",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media, Culture and Social Sciences",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "Grad Dip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "Grad Dip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music (Audiovisual Cultures)",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music, Mind and Brain",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Musical Theatre",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance Making",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Photography and Urban Cultures",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Photography Practice",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Communications",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Communications",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Popular Music",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MMus",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Promotional Media: Public Relations, Advertising and Marketing",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Promotional Media: Public Relations, Advertising and Marketing",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychodynamic Counselling and Psychotherapy",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Conversion)",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (IMS)",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology of the Arts, Neuroaesthetics and Creativity",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Race, Media and Social Justice",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Race, Media and Social Justice",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religious Studies",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Research Architecture",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Research Architecture",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Research Methods in Psychology",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Script Writing",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Justice in Education",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work and Social Policy",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology (Inventive Urban Research)",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation (by Practice)",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Understanding Domestic Violence and Sexual Abuse",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "User Experience Engineering",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Virtual and Augmented Reality",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Anthropology",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Anthropology",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Anthropology",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Culture",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Cultures",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Sociology",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "World Performances, Theatres and Cultures",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Practices",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Practices",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and Museum Practice",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Anthropology and Community and Youth Work",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Anthropology and Community Development/Community Arts",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Theatre: Drama in Educational, Community and Social Contexts",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Politics",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art Practice and Learning",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art Psychotherapy",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art Psychotherapy",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artists' Film and Moving Image",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts Administration and Cultural Policy",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts Administration and Cultural Policy: Music Pathway",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts and Computational Technology",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts and Learning",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Black British History",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Black British Literature",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Brands, Communication and Culture",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children's Literature",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children's Literature: Children's Book Illustration",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communities and Social Justice",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Arts",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Arts",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MFA (PG)",
                    "duration": "3 years",
                    "study_mode": "Mixed mode",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Cognitive Neuroscience",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Linguistics",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Games Programming",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Games: Art and Design",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary Art History",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "Grad Dip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary Art Theory",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling and Psychotherapy",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative & Cultural Entrepreneurship",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative and Cultural Entrepreneurship",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative and Cultural Entrepreneurship: Computing Pathway",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative and Cultural Entrepreneurship: Design Management Pathway",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA (PG)",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative and Cultural Entrepreneurship: Design Pathway",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative and Cultural Entrepreneurship: Fashion Management Pathway",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA (PG)",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative and Cultural Entrepreneurship: Fashion Pathway",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative and Cultural Entrepreneurship: Leadership Pathway",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative and Cultural Entrepreneurship: Media and Communications Pathway",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative and Cultural Entrepreneurship: Music Pathway",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative and Cultural Entrepreneurship: Social Innovation Pathway",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative and Cultural Entrepreneurship: Theatre and Performance Pathway",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative and Life Writing",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Practice",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MFA (PG)",
                    "duration": "3 years",
                    "study_mode": "Mixed mode",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Practice",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MMus",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing and Education",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cultural Studies",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Curating",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MFA (PG)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dance Movement Psychotherapy",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dance Movement Psychotherapy",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Artificial Intelligence",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Journalism",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media - Theory",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dramaturgy and Writing for Performance",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education: Culture, Language and Identity",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English, Comparative Literature or Linguistics",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Events and Experience Management",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Screen Studies",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Screen Studies",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MFA (PG)",
                    "duration": "3 years",
                    "study_mode": "Variable",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Foundation in Art and Psychodynamic Thinking",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "level 6",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Foundation in Dance Movement Psychotherapy",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "level 6",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Foundations in Clinical Psychology and Health Services",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games and Playful Design",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender, Media and Culture",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Politics: Theory and Activism",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Rights, Culture and Social Justice",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Humanistic and Psychodynamic Counselling",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "Graduate Certificate",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Retail Business, Sustainability and Technologies",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Literary and Critical Theory",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Literary Studies",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Luxury Brand Management",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing and Consumer Behaviour",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communications",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communications",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communications",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media, Communications and Cultural Studies",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PhD",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Museums and Galleries Entrepreneurship",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PgCert",
                    "duration": "10 weeks",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music (Audiovisual Cultures)",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music, Mind and Brain",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Musical Theatre",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance Making",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Photography and Urban Cultures",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Communications",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Communications",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Popular Music",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MMus",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Promotional Media: Public Relations, Advertising and Marketing",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Promotional Media: Public Relations, Advertising and Marketing",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychodynamic Counselling and Psychotherapy",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Conversion)",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (IMS)",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology of the Arts, Neuroaesthetics and Creativity",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Race, Media and Social Justice",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Race, Media and Social Justice",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religious Studies",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Research Architecture",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Research Architecture",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Research Methods in Psychology",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Justice in Education",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work and Social Policy",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology (Inventive Urban Research)",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation (by Practice)",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Understanding Domestic Violence and Sexual Abuse",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "User Experience Engineering",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Virtual and Augmented Reality",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Anthropology",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Anthropology",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Anthropology",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Culture",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Cultures",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Sociology",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "World Performances, Theatres and Cultures",
                    "university": "Goldsmiths, University of London",
                    "location": "Goldsmiths, University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "48cd2e3386ea4bcd542ce456e0c31de0",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Applied Computing",
                    "university": "Gower College Swansea",
                    "location": "Tycoch campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business Management",
                    "university": "Gower College Swansea",
                    "location": "Tycoch campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business Management (Accounting) (Top up)",
                    "university": "Gower College Swansea",
                    "location": "Sketty Hall Business School",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (Marketing) (Top up)",
                    "university": "Gower College Swansea",
                    "location": "Sketty Hall Business School",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Childhood Development Practice",
                    "university": "Gower College Swansea",
                    "location": "Tycoch campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Education, Mental Health and ALN",
                    "university": "Gower College Swansea",
                    "location": "Tycoch campus",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Gower College Swansea",
                    "location": "Tycoch campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Esports",
                    "university": "Gower College Swansea",
                    "location": "Tycoch campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Gower College Swansea",
                    "location": "Tycoch campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sport Coaching and Performance",
                    "university": "Gower College Swansea",
                    "location": "Tycoch campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "cdb85bcb38043d45fafa7710a5bdf7f7",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Business Analytics",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Accounting and Finance (Extended)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Accounting and Finance (Year 2 & 3 Direct Entry)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agriculture (Final Year Entry)",
                    "university": "University of Greenwich",
                    "location": "Hadlow College (Part of North Kent College)",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Animal Management (Final Year Entry)",
                    "university": "University of Greenwich",
                    "location": "Hadlow College (Part of North Kent College)",
                    "qualification": "BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Animation",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Applied Behavioural Science and Welfare",
                    "university": "University of Greenwich",
                    "location": "Hadlow College (Part of North Kent College)",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Applied Biomedical Science",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Aquaculture and Fisheries Management (Final Year Entry)",
                    "university": "University of Greenwich",
                    "location": "Hadlow College (Part of North Kent College)",
                    "qualification": "BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Architecture with Foundation Year",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "BA (Hons) Primary Education with Qualified Teacher Status [QTS] (5-11)*",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Avery Hill Campus)",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "BA H Business Management (Finance)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "BA H Business Management (Marketing)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biology",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Biology (Extended)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Biomedical Science (Extended)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Computing",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business Economics",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Entrepreneurship and Innovation",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Logistics and Supply Chain Management",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Logistics and Supply Chain Management (Year 2 & 3 Entry)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (Extended)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management (Year 2 & 3 Direct Entry)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and Leadership",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business Psychology",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Business Studies",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Studies (Year 2 & 3 Direct Entry)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business with Accounting (Year 3 Entry)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business with Finance  (Year 2 & 3 Direct Entry)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business with Marketing (Year 2 Entry)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "CertHE Construction Design and Build Technician",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "CertHE Construction Site Supervisor",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "CertHE Engineering Manufacturing Technician",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "CertHE in Sports Coaching",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Avery Hill Campus)",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Chemical Engineering (Extended)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BEng",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Greenwich",
                    "location": "London South East Colleges (Orpington Campus)",
                    "qualification": "FdEng",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Civil Engineering (Extended)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Civil Engineering Extended",
                    "university": "University of Greenwich",
                    "location": "London South East Colleges (Orpington Campus)",
                    "qualification": "FdEng",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Civil Engineering with Placement",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Computer Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Computer Engineering (Extended)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Computer Science  (Cyber Security)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Computer Science (Artificial Intelligence)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Computer Science (Data Science)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Computer Science (Games)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Computer Science (Networking)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Computing",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Computing",
                    "university": "University of Greenwich",
                    "location": "London South East Colleges (Bexley Campus)",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computing (Artificial Intelligence)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Computing (Extended)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Computing (Information Systems)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Computing (Network Systems)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Computing for Emerging Technologies",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Construction Management",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Writing and English Literature",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology and Criminal Justice",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology and Criminal Psychology",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology and Criminal Psychology (Extended)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Cyber Security & Digital Forensics",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Digital Marketing and Advertising",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Drama",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Drama and English Literature",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Drama and English Literature with International Study",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Drama with International Study",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Early Years",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Early Years (Top Up)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Economics (Year 2 & 3 Direct Entry)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education & Special Educational Needs (Flourish Learning Trust)",
                    "university": "University of Greenwich",
                    "location": "Flourish Learning Trust",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Electrical and Electronic Engineering (Extended)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Electrical and Electronic Engineering Technology",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Electronic and Electrical Engineering",
                    "university": "University of Greenwich",
                    "location": "London South East Colleges (Orpington Campus)",
                    "qualification": "FdEng",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Electronic and Electrical Engineering Extended",
                    "university": "University of Greenwich",
                    "location": "London South East Colleges (Orpington Campus)",
                    "qualification": "FdEng",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Engineering Management",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Engineering Management (Extended)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "64"
                },
                {
                    "course_name": "English Language and Literature",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English Language and TESOL",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English Literature with Creative Writing",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Entrepreneurship and Innovation",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Events Management & Innovation",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Film & Television Production",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Film Studies",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Finance and Investment Banking",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Finance and Investment Banking (Year 2 & 3 Direct Entry)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Mathematics",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Food and Drink Engineer",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Forensic and Digital Investigation",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Forensic and Digital Investigation with Industrial Placement",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Forensic Science (Extended)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Forensic Science with Criminology",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Forensic Science with Criminology (Extended)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Forensic Science with Criminology and Industrial Placement",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Forensic Science with Industrial Placement",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Forensic Toxicology",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Games Development",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Graphic and Digital Design",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Graphic and Digital Design",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Health and Social care (Top-Up)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "History and English",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "History and Politics",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "History and Sociology",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Horticulture - Commercial",
                    "university": "University of Greenwich",
                    "location": "Hadlow College (Part of North Kent College)",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Horticulture - Commercial (Final Year Entry)",
                    "university": "University of Greenwich",
                    "location": "Hadlow College (Part of North Kent College)",
                    "qualification": "BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Human Nutrition and Health",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Human Nutrition and Health (Extended)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Human Resource Management and Development",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Business with Language",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Tourism and Hospitality Management",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Landscape Architecture",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Languages and International Relations",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Law",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Law (Extended)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "LLB",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Law in Practice",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Law Senior Status",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Learning Disabilities Nursing",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Avery Hill Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "MA Social Work",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing and Brand Management",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Mathematics and Computer Science",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Mathematics with International Study",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "MBiol Biology",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MBiol",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Greenwich",
                    "location": "London South East Colleges (Orpington Campus)",
                    "qualification": "FdEng",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Mechanical Engineering (Extended)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Mechanical Engineering Technology",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Media and Communications",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Midwifery",
                    "university": "University of Greenwich",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "MSc Nursing (Adult Nursing)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Avery Hill Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Nursing (Children's Nursing)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Avery Hill Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Nursing (Learning Disabilities)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Avery Hill Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Nursing (Mental Health Nursing)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Avery Hill Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Sound Production",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing (Adult Nursing)",
                    "university": "University of Greenwich",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing (Children's Nursing)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Avery Hill Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing (Mental Health Nursing)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Avery Hill Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Operating Department Practitioner",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Avery Hill Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Paramedic Science",
                    "university": "University of Greenwich",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Pharmaceutical Sciences",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Pharmaceutical Sciences (Extended)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Physical Education and Sport",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Avery Hill Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Avery Hill Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Primary Education Studies (2 Year Accelerated Degree)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Avery Hill Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Primary Education with QTS (Year 2 & 3 Only)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Avery Hill Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Dance and Musical Theatre",
                    "university": "University of Greenwich",
                    "location": "Bird College",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Psychology (Extended)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Psychology with Counselling",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Public Health",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Public Health (Extended)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Quantity Surveying",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sociology and Criminology",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sociology and Psychology",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Speech and Language Therapy",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Avery Hill Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Avery Hill Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Statistics and Data Analytics",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Study Abroad",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "LLB",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Sustainability Management",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Zoo Husbandry and Management",
                    "university": "University of Greenwich",
                    "location": "Hadlow College (Part of North Kent College)",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Accounting and Business Analytics",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Applied Behavioural Science and Welfare",
                    "university": "University of Greenwich",
                    "location": "Hadlow College (Part of North Kent College)",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Computing",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business Entrepreneurship and Innovation",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Logistics and Supply Chain Management",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Psychology",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Business Studies",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Studies (Year 2 & 3 Direct Entry)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "CertHE Construction Design and Build Technician",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "CertHE",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "CertHE Construction Site Supervisor",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "CertHE",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "CertHE Engineering Manufacturing Technician",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "CertHE",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MEng",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Chemical Engineering (Extended)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BEng",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Greenwich",
                    "location": "London South East Colleges (Orpington Campus)",
                    "qualification": "FdEng",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MEng",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BSc",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Civil Engineering (Extended)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Civil Engineering Extended",
                    "university": "University of Greenwich",
                    "location": "London South East Colleges (Orpington Campus)",
                    "qualification": "FdEng",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computer Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MEng",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Computer Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Engineering (Extended)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Computer Science  (Cyber Security)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Computer Science (Artificial Intelligence)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Computer Science (Data Science)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Computer Science (Games)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Computer Science (Networking)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Computing",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Computing",
                    "university": "University of Greenwich",
                    "location": "London South East Colleges (Bexley Campus)",
                    "qualification": "FdSc",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computing (Information Systems)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Computing (Network Systems)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Computing for Emerging Technologies",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Construction Management",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Writing and English Literature",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology and Criminal Psychology",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Cyber Security & Digital Forensics",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Digital Marketing and Advertising",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Drama",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Drama and English Literature",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Early Years",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Early Years (Top Up)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MEng",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Electrical and Electronic Engineering (Extended)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Electrical and Electronic Engineering Technology",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Electronic and Electrical Engineering",
                    "university": "University of Greenwich",
                    "location": "London South East Colleges (Orpington Campus)",
                    "qualification": "FdEng",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Electronic and Electrical Engineering Extended",
                    "university": "University of Greenwich",
                    "location": "London South East Colleges (Orpington Campus)",
                    "qualification": "FdEng",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Engineering Management",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Engineering Management (Extended)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "English Language and Literature",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English Language and TESOL",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English Literature with Creative Writing",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Film Studies",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Finance and Investment Banking",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Financial Mathematics",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Food and Drink Engineer",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "DipHE",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Graphic and Digital Design",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Health and Social care (Top-Up)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "History and English",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "History and Politics",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "History and Sociology",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Horticulture - Commercial",
                    "university": "University of Greenwich",
                    "location": "Hadlow College (Part of North Kent College)",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Human Nutrition and Health",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Tourism and Hospitality Management",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Languages and International Relations",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Law",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "MA Social Work",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MA (PG)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing and Brand Management",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Mathematics and Computer Science",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MEng",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Greenwich",
                    "location": "London South East Colleges (Orpington Campus)",
                    "qualification": "FdEng",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Mechanical Engineering (Extended)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Mechanical Engineering Technology",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Media and Communications",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Operating Department Practitioner",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Avery Hill Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Pharmaceutical Sciences",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Physical Education and Sport",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Avery Hill Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Psychology (Extended)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Psychology with Counselling",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Public Health",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Quantity Surveying",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sociology and Criminology",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sociology and Psychology",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Avery Hill Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Statistics and Data Analytics",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Chemical Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Chemical Engineering with Industrial Practice",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agriculture for Sustainable Development",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Artificial Intelligence for Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Criminology",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Food Safety and Quality Management",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Food Safety and Quality Management with Industrial Practice",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Linguistics",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture (Part 2)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MArch",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Big Data and Business Intelligence",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Child and Adolescent Psychology",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with Industrial Practice",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Forensics and Cyber Security",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Network Engineering)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing and Information Systems",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Management and Economics",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Criminal Psychology",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science (FinTech)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and its Applications",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and its Applications (Medway)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Arts",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing Management",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering with Industrial Practice, MSc",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering, MSc",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Power Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Power Engineering with Industrial Practice",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Management",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Management with Industrial Practice",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Executive Master of Business Administration",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MBA (Executive)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Production",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Investment",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Management and Risk",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fintech and Finance",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Innovation",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Innovation with Industrial Placement",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Formulation Science",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Environmental Change",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Environmental Change With Industrial Practice",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Public Health",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Shipping Management",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Sustainable Development",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global with Placement",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International and Commercial Law",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Banking and Finance",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Events Management",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tourism and Hospitality Management",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interprofessional Healthcare Simulation",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Avery Hill Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Landscape Architecture",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MLA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Landscape Architecture",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law, Regulation and the International Financial System",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Logistics and Supply Chain Management",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management of Business Information Technology",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing Management",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical and Manufacturing Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical and Manufacturing Engineering with Industrial Practice",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Global Health Management",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Avery Hill Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Sound Design",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Resources",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Psychology",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Primary Education (3-11) (Provider-led University-based) with QTS*",
                    "university": "University of Greenwich",
                    "location": "Avery Hill Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Sciences",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Sciences with Industrial Practice",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Conversion Degree)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate Development and Investment",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science, MSc by Research",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc (Res)",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary PGCE with QTS* (11-19) (Provider-led) (Mathematics)",
                    "university": "University of Greenwich",
                    "location": "Avery Hill Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary PGCE with QTS* (11-19) (Provider-led) (Physical Education)",
                    "university": "University of Greenwich",
                    "location": "Avery Hill Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary PGCE with QTS* (11-19) (Provider-led) (Science with Biology)",
                    "university": "University of Greenwich",
                    "location": "Avery Hill Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary PGCE with QTS* (11-19) (Provider-led) (Science with Chemistry)",
                    "university": "University of Greenwich",
                    "location": "Avery Hill Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary PGCE with QTS* (11-19) (Provider-led) (Science with Physics)",
                    "university": "University of Greenwich",
                    "location": "Avery Hill Campus",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Public Health Nursing (Health Visiting and School Nursing)",
                    "university": "University of Greenwich",
                    "location": "Avery Hill Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Practitioner (District Nursing)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Avery Hill Campus)",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Psychology",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Tourism and Hospitality Management",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strength and Conditioning",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Avery Hill Campus)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Building Design and Engineering",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Water, Waste and Environmental Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Water, Waste and Environmental Engineering with Industrial Practice",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Web Design and Content Planning",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Chemical Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice",
                    "university": "University of Greenwich",
                    "location": "Avery Hill Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Artificial Intelligence for Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Criminology",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Food Safety and Quality Management",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Linguistics",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Practice (ARB/RIBA Part 3 Exemption)",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture (Part 2)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MArch",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Big Data and Business Intelligence",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Child and Adolescent Psychology",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Forensics and Cyber Security",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Network Engineering)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing and Information Systems",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Management and Economics",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Greenwich",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Criminal Psychology",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science (FinTech)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and its Applications",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and its Applications (Medway)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering, MSc",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Power Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc (Res)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Management",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Mixed mode",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Executive Master of Business Administration",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MBA (Executive)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Production",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Investment",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Management and Risk",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Innovation",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Formulation Science",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Public Health",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Shipping Management",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Sustainable Development",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International and Commercial Law",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Banking and Finance",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Events Management",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tourism and Hospitality Management",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interprofessional Healthcare Simulation",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Avery Hill Campus)",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Landscape Architecture",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Landscape Architecture",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MLA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law, Regulation and the International Financial System",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Logistics and Supply Chain Management",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management of Business Information Technology",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical and Manufacturing Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Sound Design",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Resources",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Psychology",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Sciences",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Conversion Degree)",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate Development and Investment",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science, MSc by Research",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Public Health Nursing (Health Visiting and School Nursing)",
                    "university": "University of Greenwich",
                    "location": "Avery Hill Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Psychology",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Greenwich Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Tourism and Hospitality Management",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strength and Conditioning",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Avery Hill Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Therapeutic Counselling",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Water, Waste and Environmental Engineering",
                    "university": "University of Greenwich",
                    "location": "University of Greenwich (Medway Campus)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Web Design and Content Planning",
                    "university": "University of Greenwich",
                    "location": "Greenwich Maritime Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "04c38b471fd762841c9fb9c8bce0d4d3",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "d665cfb16c129aeb3ae908f229348270",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Animal Management",
                    "university": "Halesowen College",
                    "location": "Whittingham Road",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Animal Management",
                    "university": "Halesowen College",
                    "location": "Whittingham Road",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Science: Forensic Science",
                    "university": "Halesowen College",
                    "location": "Whittingham Road",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Art & Design: Photography",
                    "university": "Halesowen College",
                    "location": "Whittingham Road",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art & Design: Photography",
                    "university": "Halesowen College",
                    "location": "Whittingham Road",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computing",
                    "university": "Halesowen College",
                    "location": "Whittingham Road",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing",
                    "university": "Halesowen College",
                    "location": "Whittingham Road",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Early Childhood Education and Care",
                    "university": "Halesowen College",
                    "location": "Whittingham Road",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Engineering",
                    "university": "Halesowen College",
                    "location": "Whittingham Road",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Esports (Management & Production)",
                    "university": "Halesowen College",
                    "location": "Whittingham Road",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Healthcare Practice",
                    "university": "Halesowen College",
                    "location": "Whittingham Road",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Hospitality Management",
                    "university": "Halesowen College",
                    "location": "Whittingham Road",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sport and Exercise Sciences",
                    "university": "Halesowen College",
                    "location": "Whittingham Road",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Sciences",
                    "university": "Halesowen College",
                    "location": "Whittingham Road",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Applied Science: Forensic Science",
                    "university": "Halesowen College",
                    "location": "Whittingham Road",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computing",
                    "university": "Halesowen College",
                    "location": "Whittingham Road",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing",
                    "university": "Halesowen College",
                    "location": "Whittingham Road",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Creative Media",
                    "university": "Halesowen College",
                    "location": "Whittingham Road",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Creative Media",
                    "university": "Halesowen College",
                    "location": "Whittingham Road",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "Halesowen College",
                    "location": "Whittingham Road",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "49456bfdf8f9af31c8c4fe2155060505",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "AERONAUTICAL ENGINEERING HNC (HTQ)",
                    "university": "Harlow College",
                    "location": "Stansted Airport College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "AERONAUTICAL ENGINEERING HND (HTQ)",
                    "university": "Harlow College",
                    "location": "Stansted Airport College",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computing for England (Cyber Security Pathway) (HTQ)",
                    "university": "Harlow College",
                    "location": "Harlow College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Computing for England (Cyber Security Pathway) (HTQ)",
                    "university": "Harlow College",
                    "location": "Harlow College",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "CONSTRUCTION MANAGEMENT HNC (HTQ)",
                    "university": "Harlow College",
                    "location": "Harlow College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "CONSTRUCTION MANAGEMENT HND (HTQ)",
                    "university": "Harlow College",
                    "location": "Harlow College",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "COUNSELLING AND APPLIED PSYCHOLOGY HNC (HTQ)",
                    "university": "Harlow College",
                    "location": "Harlow College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "COUNSELLING AND APPLIED PSYCHOLOGY HND (HTQ)",
                    "university": "Harlow College",
                    "location": "Harlow College",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "LEADERSHIP AND MANAGEMENT HNC (HTQ)",
                    "university": "Harlow College",
                    "location": "Harlow College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "LEADERSHIP AND MANAGEMENT HND (HTQ)",
                    "university": "Harlow College",
                    "location": "Harlow College",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                }
            ],
            "Part-time": [
                {
                    "course_name": "ENGINEERING FOR ENGLAND HNC (HTQ)",
                    "university": "Harlow College",
                    "location": "Harlow College",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "48"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "5ecd84028a219c0e528c21cd5a524410",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Agri-Business (top-up)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agri-Business (top-up: ordinary)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agri-Food Marketing with Business (top-up)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agri-Food Marketing with Business (top-up: ordinary)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agribusiness Management (with Placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "88-104"
                },
                {
                    "course_name": "Agricultural Engineering (with Placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Agricultural Engineering (with Placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Agriculture (+ Foundation year) (with placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agriculture (top up: ordinary)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agriculture (top-up)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agriculture (with Placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Agriculture (with Placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Agriculture with Animal Science (top-up)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agriculture with Animal Science (top-up: ordinary)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agriculture with Crop Management (top-up)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agriculture with Crop Management (top-up: ordinary)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agriculture with Crop Production Science (with Placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Agriculture with Farm Business Management  (top-up)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agriculture with Farm Business Management  (top-up: ordinary)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agriculture with Farm Business Management (with Placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Agriculture with Farm Technology (with Placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Agriculture with Livestock Production Science (with Placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Agriculture with Mechanisation (top-up)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agriculture with Mechanisation (top-up: ordinary)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agriculture with Policy and Environment",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Animal Behaviour and Welfare (Clinical) (with Placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-104"
                },
                {
                    "course_name": "Applied Animal Science (+ Foundation Year) (with placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Animal Science (with placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-104"
                },
                {
                    "course_name": "Applied Data Science",
                    "university": "Harper Adams University",
                    "location": "Harper Adams University - Station Quarter",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Applied Data Science",
                    "university": "Harper Adams University",
                    "location": "Harper Adams University - Station Quarter",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Applied Zoology (+ Foundation year) (with placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Applied Zoology (with Placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Automotive Engineering (with Placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Automotive Engineering (with Placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Business Management  (with Placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "88-104"
                },
                {
                    "course_name": "Business Management (+ Foundation year) (with placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Marketing (top-up)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Marketing (top-up: ordinary)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Countryside Management (+ Foundation Year) (with Placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Countryside Management (with Placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "64-80"
                },
                {
                    "course_name": "Digital Business Management",
                    "university": "Harper Adams University",
                    "location": "Harper Adams University - Station Quarter",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Environmental Management and Sustainability (with placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "88-104"
                },
                {
                    "course_name": "Food Business Management (with Placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "88-104"
                },
                {
                    "course_name": "Food Science and Innovation  (+ Foundation year) (with placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Science and Innovation (with Placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "88-104"
                },
                {
                    "course_name": "Food Technology and Product Development (top-up)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering (+ Foundation year) (with Placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering (with Placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Mechanical Engineering (with Placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Robotics Automation and Mechatronic Engineering",
                    "university": "Harper Adams University",
                    "location": "Harper Adams University - Station Quarter",
                    "qualification": "FdEng",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Robotics, Automation and Mechatronics Engineering",
                    "university": "Harper Adams University",
                    "location": "Harper Adams University - Station Quarter",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Rural Enterprise and Land Management (with Placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Rural Property and Real Estate (with Placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Veterinary Bioscience (+ Foundation year): Harper and Keele Veterinary School Pathway",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "Bachelor of Veterinary Medicine and Surgery \u2013 BVetMS",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Bioscience (accelerated)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Bioscience (with Placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-104"
                },
                {
                    "course_name": "Veterinary Nursing  (top-up)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Nursing (with Placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Veterinary Nursing with Companion Animal Behaviour (with Placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Veterinary Nursing with Small Animal Rehabilitation (with Placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Veterinary Physiotherapy",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Wildlife Conservation and Ecology (with placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "88-104"
                },
                {
                    "course_name": "Wildlife Conservation and Environmental Management (top-up)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Wildlife Conservation and Environmental Management (top-up: ordinary)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology with Entomology (with Placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Zoology with Environmental Management (with Placement)",
                    "university": "Harper Adams University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "2f1b49bd5623fe5dcdfce89a93211af3",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Veterinary Medicine and Surgery",
                    "university": "Harper and Keele Veterinary School",
                    "location": "Harper and Keele Veterinary School",
                    "qualification": "Bachelor of Veterinary Medicine and Surgery \u2013 BVetMS",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "3fb80fcdd82302203c8e8f0265c971d6",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Agriculture",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Agriculture (Top-up Level 6)",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agriculture (with Foundation Year)",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Agriculture with Crop Science",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Agriculture with Livestock Science",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Animal Behaviour and Training (Clinical)",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Animal Behaviour and Training (Clinical) (with Foundation Year)",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Animal Behaviour and Training with Canine (Clinical)",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Animal Behaviour and Training with Canine (Clinical) (with Foundation Year)",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Animal Behaviour and Welfare",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Animal Behaviour and Welfare (Top-up Level 6)",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Behaviour and Welfare (with Foundation Year)",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Animal Training and Performance (Top-up Level 6)",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Biological Sciences (with Foundation Year)",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Bioveterinary Science",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Bioveterinary Science (Top-up Level 6)",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioveterinary Science (with Foundation Year)",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Business Management",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Canine Training and Performance (Top-up Level 6)",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Environmental Science (with Foundation Year)",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Equestrian Sports Coaching",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Equestrian Sports Science",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Equestrian Sports Science (with Foundation Year)",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Equine Behaviour and Welfare",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Equine Behaviour and Welfare with Foundation Year",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Equine Business Management",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Equine Business Management (Top-up Level 6)",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Equine Business Management (with Foundation Year)",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Equine Dental Science",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Equine Dental Science (with Foundation Year)",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Equine Performance",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64-80"
                },
                {
                    "course_name": "Equine Performance and Rehabilitation",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Equine Performance and Rehabilitation (with Foundation Year)",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Equine Science",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Equine Science (Top-up Level 6)",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Equine Science (with Foundation Year)",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Equine Veterinary Nursing",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Human-Animal Interaction with Psychology",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Human-Animal Interaction with Psychology (with Foundation Year)",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Integrated Masters in Equine Science",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128"
                },
                {
                    "course_name": "International Horseracing Business",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Horseracing Business (Top-up Level 6)",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Horseracing Business with Foundation Year",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Physical Education and School Sport",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Physical Education and School Sport (with Foundation Year)",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Racehorse Performance and Rehabilitation",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Racehorse Performance and Rehabilitation with Foundation Year",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Sport and Exercise Nutrition",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Sport and Exercise Nutrition (with Foundation Year)",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Sport and Exercise Sciences",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Sport and Exercise Sciences (with Foundation Year)",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Sport Performance (Top-up Level 6)",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Performance and Coaching",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64-80"
                },
                {
                    "course_name": "Sports Business Management",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sports Business Management (with Foundation Year)",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Sports Coaching",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sports Coaching (with Foundation Year)",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Sports Therapy",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sports Therapy (with Foundation Year)",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Strength and Conditioning",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Strength and Conditioning (With Foundation Year)",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Veterinary Biosciences",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Veterinary Nursing",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Zoology",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Zoology (Top-up Level 6)",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology (with Foundation Year)",
                    "university": "Hartpury University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Animal Assisted Counselling and Psychotherapy",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Science",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Science (Livestock)",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "1 year",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Animal Behaviour and Welfare",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Equine Science",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Performance Analysis in Sport",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biodiversity Conservation",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Equestrian Science",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Equitation Science",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Management",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "MBM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Coaching",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Management",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Therapy (Conversion)",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strength and Conditioning",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Agriculture",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Equine Veterinary Nursing (Critical Care)",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Equine Veterinary Nursing (Diagnostic Imaging and Rehabilitation)",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Veterinary Nursing",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Veterinary Nursing (Clinical Nursing and Client Support)",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Veterinary Nursing (Perioperative Nursing and Patient Safety)",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Assisted Counselling and Psychotherapy",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Science",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Science (Livestock)",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Animal Behaviour and Welfare",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Equine Science",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Performance Analysis in Sport",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "PgDip",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biodiversity Conservation",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Equestrian Science",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "MRes",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Equine Behaviour and Welfare",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Equine Performance and Rehabilitation",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Equitation Science",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration Top-up",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "MRes",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Coaching",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Management",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Therapy (Conversion)",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strength and Conditioning",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Agriculture",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Physiotherapy",
                    "university": "Hartpury University",
                    "location": "Hartpury University",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "50bffd1bd4e8bb8ecf61d816067a3206",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "e77ad9ebce36f1581e71f4c0657aa209",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Education and Professional Practice",
                    "university": "New City College (incorporating Havering College)",
                    "location": "Ardleigh Green",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Higher National Certificate in Computing (HTQ)",
                    "university": "New City College (incorporating Havering College)",
                    "location": "Poplar",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Integrative Counselling",
                    "university": "New City College (incorporating Havering College)",
                    "location": "Ardleigh Green",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sports Science & Tottenham Hotspur Coaching Development Programme",
                    "university": "New City College (incorporating Havering College)",
                    "location": "Wingletye Lane",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Sports Science & Tottenham Hotspur Coaching Development Programme",
                    "university": "New City College (incorporating Havering College)",
                    "location": "Wingletye Lane",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Post Graduate Certificate in Education (PGCE) in Post-Compulsory Education and Training (PCET)",
                    "university": "New City College (incorporating Havering College)",
                    "location": "Ardleigh Green",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        }
    },
    {
        "id": "f9c5415685df2ace1029bb40448dd997",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Primary and Early Years Education with Qualified Teacher Status (School Based)",
                    "university": "Haybridge Alliance School Centred Initial Teacher Training",
                    "location": "Haybridge High School and Sixth Form",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "57be4f7c9f229be8292d187e472125d7",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "33e82a53d6ee9ed47a27051e41715e10",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "db6fc2d1ee7890333837a8b9b6bca559",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Business Management",
                    "university": "Heart of Worcestershire College",
                    "location": "Bromsgrove",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-168"
                },
                {
                    "course_name": "Computing for England",
                    "university": "Heart of Worcestershire College",
                    "location": "Worcester",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Digital Media & Games Design (subject to change on revalidation)",
                    "university": "Heart of Worcestershire College",
                    "location": "Bromsgrove",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Early Years: (0-8) Pathway (FT)",
                    "university": "Heart of Worcestershire College",
                    "location": "Multiple Locations",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Early Years: 0-8 (Professional Practice) Pathway (FT)",
                    "university": "Heart of Worcestershire College",
                    "location": "Multiple Locations",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Sport & Exercise Science (Coaching Science)",
                    "university": "Heart of Worcestershire College",
                    "location": "Redditch",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sports Coaching and Leadership Management",
                    "university": "Heart of Worcestershire College",
                    "location": "Worcester",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Digital Media & Games Design (subject to change on revalidation)",
                    "university": "Heart of Worcestershire College",
                    "location": "Bromsgrove",
                    "qualification": "FdA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": [
                {
                    "course_name": "Advanced Diploma in People Management",
                    "university": "Heart of Worcestershire College",
                    "location": "Worcester",
                    "qualification": "AdvPgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Diploma in People Management - Flexible Learning",
                    "university": "Heart of Worcestershire College",
                    "location": "Worcester",
                    "qualification": "AdvPgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Management and Leadership",
                    "university": "Heart of Worcestershire College",
                    "location": "Worcester",
                    "qualification": "Advanced Professional Diploma",
                    "duration": "9 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "bf0e2418d456a19dee08278b1f01377d",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Artist Blacksmithing",
                    "university": "Hereford College of Arts",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Contemporary Design Crafts",
                    "university": "Hereford College of Arts",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Contemporary Design Crafts (Top Up)",
                    "university": "Hereford College of Arts",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Content Creation",
                    "university": "Hereford College of Arts",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Hereford College of Arts",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Fine Art (Top Up)",
                    "university": "Hereford College of Arts",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Design",
                    "university": "Hereford College of Arts",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Graphic and Media Design",
                    "university": "Hereford College of Arts",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Graphic and Media Design (Top Up)",
                    "university": "Hereford College of Arts",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Illustration",
                    "university": "Hereford College of Arts",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Illustration (Top Up)",
                    "university": "Hereford College of Arts",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Illustration and Animation",
                    "university": "Hereford College of Arts",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Illustration and Animation (Top Up)",
                    "university": "Hereford College of Arts",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Jewellery Design",
                    "university": "Hereford College of Arts",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Jewellery Design (Top-Up)",
                    "university": "Hereford College of Arts",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performing Arts",
                    "university": "Hereford College of Arts",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Photography",
                    "university": "Hereford College of Arts",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Photography (Top Up)",
                    "university": "Hereford College of Arts",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Popular Music",
                    "university": "Hereford College of Arts",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Popular Music (Top Up)",
                    "university": "Hereford College of Arts",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Textile Design",
                    "university": "Hereford College of Arts",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Textile Design (Top Up)",
                    "university": "Hereford College of Arts",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Contemporary Crafts",
                    "university": "Hereford College of Arts",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Curating",
                    "university": "Hereford College of Arts",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Hereford College of Arts",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "45 weeks",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Photography",
                    "university": "Hereford College of Arts",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        }
    },
    {
        "id": "54876a868820f9f734dbd1a99da83561",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "87a6a8c17137d182c6cebf2ca8ffcee1",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accountancy",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accountancy (Accelerated)",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accountancy and Finance",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accountancy and Finance (Accelerated)",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance with Accelerator Programme",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Actuarial Science",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Actuarial Science and Diploma in Industrial Training",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Modern Languages & Translating: Chinese/French",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Modern Languages & Translating: Chinese/German",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Modern Languages & Translating: Chinese/Spanish",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Modern Languages & Translating: French/German",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Modern Languages & Translating: French/Spanish",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Modern Languages & Translating: German/Spanish",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Engineering",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Engineering",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences (Cell and Molecular Biology)",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences (Human Health)",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MBiol",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Brewing and Distilling",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Brewing and Distilling and Diploma in Industrial Training",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "British Sign Language (Interpreting, Translating and Applied Language Studies)",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "British Sign Language and Applied Language Studies",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BBA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Finance",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business with Accelerator Programme",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MChem (H)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Placement",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MChem (H)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Combined Studies",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication Design",
                    "university": "Heriot-Watt University",
                    "location": "Scottish Borders",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Artificial Intelligence)",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Cyber Security)",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Data Science)",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Data Science) and Diploma in Industrial Training",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Games Programming)",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Software Engineering)",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Diploma in Industrial Training",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Systems",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Systems (Games Programming)",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Systems and Diploma in Industrial Training",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Sciences",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design for Textiles (Fashion, Interior, Art)",
                    "university": "Heriot-Watt University",
                    "location": "Scottish Borders",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics (Accelerated)",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Business Management",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical & Electronic Engineering",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering with Accelerator Programme",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion",
                    "university": "Heriot-Watt University",
                    "location": "Scottish Borders",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Branding and Promotion",
                    "university": "Heriot-Watt University",
                    "location": "Scottish Borders",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Mathematics",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Development and Production",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography, Society and Environment",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Architecture and Design",
                    "university": "Heriot-Watt University",
                    "location": "Scottish Borders",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management (Accelerated)",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management with Year Abroad",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Biology",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing (Accelerated)",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical Physics",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical, Statistical and Actuarial Sciences",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical, Statistical and Actuarial Sciences and Diploma in Industrial Training",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MMath",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Computer Science",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Finance",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Finance and Diploma in Industrial Training",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with French",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with German",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Spanish",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Statistics",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering and Energy Engineering",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering and Energy Engineering",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages (Interpreting and Translating) BSL/Chinese",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages (Interpreting and Translating) BSL/French",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages (Interpreting and Translating) BSL/German",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages (Interpreting and Translating) BSL/Spanish",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages (Interpreting and Translating) Chinese/French",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages (Interpreting and Translating) Chinese/German",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages (Interpreting and Translating) Chinese/Spanish",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages (Interpreting and Translating) French/German",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages (Interpreting and Translating) French/Spanish",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages (Interpreting and Translating) German/Spanish",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages for Business Management (Beginner Pathway)",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Modern Languages for Business Management with Chinese",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages for Business Management with French",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages for Business Management with German",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages for Business Management with Spanish",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages, Communication and Cultures (Beginner Pathway)",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Modern Languages, Communication and Cultures with Chinese",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages, Communication and Cultures with French",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages, Communication and Cultures with German",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages, Communication and Cultures with Spanish",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Economics",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Marketing",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with Accelerator Programme",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with Management",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantity Surveying",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics, Autonomous and Interactive Systems",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics, Autonomous and Interactive Systems",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science with Accelerator Programme",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports and Exercise Science (Accelerated)",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistical Data Science",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Engineering",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Engineering",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Technology with Accelerator Programme",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Advanced Structural Engineering",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering and Construction Management",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering With Industry Placement",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate Change (Managing The Marine Environment)",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Commercial Management and Quantity Surveying",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Commercial Management and Quantity Surveying with Industry Placement (Two years)",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Project Management with Industry Placement",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Management",
                    "university": "Heriot-Watt University",
                    "location": "Scottish Borders",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Design and Innovation",
                    "university": "Heriot-Watt University",
                    "location": "Scottish Borders",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion and Textiles Design",
                    "university": "Heriot-Watt University",
                    "location": "Scottish Borders Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion and Textiles Design with Placement",
                    "university": "Heriot-Watt University",
                    "location": "Scottish Borders",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion and Textiles Management",
                    "university": "Heriot-Watt University",
                    "location": "Scottish Borders Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Environmental Change and Policy",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Architecture and Design",
                    "university": "Heriot-Watt University",
                    "location": "Scottish Borders Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Marine Science",
                    "university": "Heriot-Watt University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Renewable Energy",
                    "university": "Heriot-Watt University",
                    "location": "Orkney Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Architecture",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MArch Studies",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mature Field Management",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Petroleum Engineering",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Renewable and Sustainable Energy Transition",
                    "university": "Heriot-Watt University",
                    "location": "Orkney Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Reservoir Evaluation and Management",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spatial Planning and Real Estate Development",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Subsurface Energy Systems",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Strategies and Design",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Structural Engineering",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering and Construction Management",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate Change (Managing The Marine Environment)",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Commercial Management and Quantity Surveying",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Management",
                    "university": "Heriot-Watt University",
                    "location": "Scottish Borders",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Design and Innovation",
                    "university": "Heriot-Watt University",
                    "location": "Scottish Borders",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion and Textiles Design",
                    "university": "Heriot-Watt University",
                    "location": "Scottish Borders Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion and Textiles Management",
                    "university": "Heriot-Watt University",
                    "location": "Scottish Borders Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Architecture and Design",
                    "university": "Heriot-Watt University",
                    "location": "Scottish Borders Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Marine Science",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spatial Planning and Real Estate Development",
                    "university": "Heriot-Watt University",
                    "location": "Edinburgh Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "bd7fde711e560d291b791473cd396fb3",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Digital Technologies",
                    "university": "Hertford Regional College",
                    "location": "Broxbourne",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Film & Television",
                    "university": "Hertford Regional College",
                    "location": "Broxbourne",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Hertford Regional College",
                    "location": "Ware",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Game Development",
                    "university": "Hertford Regional College",
                    "location": "Ware",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Hospitality and Event Management",
                    "university": "Hertford Regional College",
                    "location": "Broxbourne",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Music",
                    "university": "Hertford Regional College",
                    "location": "Broxbourne",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Performing Arts",
                    "university": "Hertford Regional College",
                    "location": "Broxbourne",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Photography",
                    "university": "Hertford Regional College",
                    "location": "Ware",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Teacher Education",
                    "university": "Hertford Regional College",
                    "location": "Broxbourne",
                    "qualification": "DET",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teacher Education and Training",
                    "university": "Hertford Regional College",
                    "location": "Ware",
                    "qualification": "Cert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "71ef3920b052e3bba54e82fabc2b4f9d",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Early Childhood and Education",
                    "university": "Holy Cross Sixth Form College and University Centre",
                    "location": "Holy Cross University Centre",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Early Childhood and Special Educational Needs (SEN)",
                    "university": "Holy Cross Sixth Form College and University Centre",
                    "location": "Holy Cross University Centre",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Education and Special Educational Needs",
                    "university": "Holy Cross Sixth Form College and University Centre",
                    "location": "Holy Cross University Centre",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "96"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "5089a2ba20bd53de17aa6024c940184e",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Animal Management",
                    "university": "Hopwood Hall College",
                    "location": "Middleton",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Art & Design",
                    "university": "Hopwood Hall College",
                    "location": "Rochdale Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Computing",
                    "university": "Hopwood Hall College",
                    "location": "Middleton",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Creative Media Production - Film and TV",
                    "university": "Hopwood Hall College",
                    "location": "Rochdale Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Creative Media Production - Film Production",
                    "university": "Hopwood Hall College",
                    "location": "Rochdale Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Creative Media Production - Games Art and Development",
                    "university": "Hopwood Hall College",
                    "location": "Rochdale Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Creative Media Production - Games Art and Development",
                    "university": "Hopwood Hall College",
                    "location": "Rochdale Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "Hopwood Hall College",
                    "location": "Middleton",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Sports Coaching and Performance Analysis",
                    "university": "Hopwood Hall College",
                    "location": "Middleton",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Zoo Science and Wildlife Conservation",
                    "university": "Hopwood Hall College",
                    "location": "Middleton",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "3cc85b3bb1acdcf44d25bbdb0b9d3961",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Acting and Performance",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Acting, Performance and English Literature",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Applied Computing (Top-Up)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Psychology",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MPsyc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Architectural Technology",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Architecture / Architecture (International) (RIBA Part 1)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Automotive and Motorsport Engineering",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Automotive and Motorsport Engineering",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Automotive and Motorsport Engineering with Foundation Year",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Biochemistry with Research Placement",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Biology (Molecular and Cellular)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Biology (Molecular and Cellular) with Research Placement",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Biomedicine",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Biomedicine with Research Placement",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Broadcast Journalism",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business Accounting (Top-Up)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration and Management (Top-Up)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Digital Marketing Management",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Business Economics",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Business Management with Finance",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Business with Financial Services (Top-Up)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Certificate in Education (Lifelong Learning) Pre-service",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "CertEd",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Chemical Engineering (Top-up)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering and Chemistry",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Chemical Engineering with Chemistry",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Chemical Engineering with Chemistry",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MChem",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Chemistry (Top-up)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Forensic Science",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Chemistry with Industrial Experience",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MChem",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Childhood, Education and Inclusion",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Civil Engineering with Foundation Year",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Science with Artificial Intelligence",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Science with Artificial Intelligence with Foundation Year",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Computer Science with Cyber Security",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Science with Cyber Security with Foundation Year",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Computer Science with Foundation Year",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Computer Science with Games Programming",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Science with Games Programming with Foundation Year",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Computing",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computing with Foundation Year",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Costume with Textiles",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Creative Music Production",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Crime and Criminal Justice",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Crime and Investigation",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Criminology with Law",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Early Childhood Education and Care",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Education (Top-up)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education with Psychology",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Education, Human Resource Development and Training",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Education, Human Resource Development and Training (Top-Up)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Communication Engineering",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Electronic and Communication Engineering (Top-Up)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Communication Engineering with Foundation Year",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Electronic and Electrical Engineering",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Electronic and Electrical Engineering",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Electronic and Electrical Engineering with Foundation Year",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Electronic Engineering (Top-Up)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering and Computer Systems",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Electronic Engineering and Computer Systems with Foundation Year",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Engineering Foundation Year (General)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "English",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "English Literature and History",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "English Literature with Creative Writing",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fashion Design",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fashion Marketing",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Film Making",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Film Studies and English Literature",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Fine Art and Illustration",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Forensic and Analytical Science",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Forensic and Analytical Science",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Forensic and Analytical Science with Industrial Experience",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Games Development (Art)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Games Development (Design)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Games Development (Production)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Global Marketing (Top-Up)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Graphic Design and Animation",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Health Foundation Pathway leading to a BSc(Hons) Degree",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "History",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Human Resource Management (Top-Up)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Illustration",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Information Technology",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Information Technology (Top-Up)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Design",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "International Accountancy (Top-Up)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "International Business (Top-Up)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Trade and Investment (Top-Up)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Law",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Law and Practice",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Lifelong Learning pre-service (Northern College)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "CertEd",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Lifelong Learning pre-service (Scarborough Sixth Form College)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "CertEd",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Mechanical Engineering (Top-Up)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Foundation Year",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Mechatronic Engineering",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Mechatronic Engineering",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Mechatronic Engineering with Foundation Year",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Media and Film Studies",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Medical Biochemistry",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Medical Biochemistry with Research Placement",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Medical Genetics",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Medical Genetics with Research Placement",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Midwifery Studies",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "MLaw (incorporating LLB(Hons)) Law and Practice",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MLaw",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Music",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BMus (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Music and Sound for Screen",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Music Journalism",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Music Performance",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BMus (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Music Technology and Composition",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BMus (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Musical Theatre",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Natural Sciences",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Nursing (Blended Learning) (Adult)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Nursing (Blended Learning) (Child)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Nursing (Blended Learning) (Learning Disability)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Nursing (Blended Learning) (Mental Health)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Nursing (Child)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Nursing (Learning Disability)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Nursing (Pre-Registration) Adult",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Pre-Registration) Adult (Blended Learning)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Pre-Registration) Child",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Pre-Registration) Learning Disability",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Pre-Registration) Mental Health",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing Associate",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Operating Department Practice",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Optometry",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MOptom",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Paramedic Science",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Pharmaceutical Chemistry",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Pharmaceutical Chemistry",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Pharmaceutical Chemistry (Top-up)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Pharmacology with Research Placement",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Pharmacy",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MPharm",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Photography",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Physical Education and Youth Sport Coaching",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Podiatry",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Popular Music",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BMus (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Primary and Early Years Education with QTS",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Primary Education Studies (Non QTS Accelerated Degree)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Psychology with Counselling",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Psychology with Criminology",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Quantity Surveying",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Science Extended Degree leading to a BSc(Hons) Degree",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Social Work (Postgraduate)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work (Undergraduate)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Software Engineering with Foundation Year",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sound Engineering and Music Production",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Special Educational Needs, Disabilities and Inclusion (Top-Up)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Speech and Language Therapy",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Sport, Exercise and Nutrition",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Sports Journalism",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "TESOL (Top-Up)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "TESOL and Younger Learners (Top-up)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Certificate in Education (Lifelong Learning) In-service",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "CertEd",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Continuing Professional Development for Pharmacy Technicians",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "No award",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Early Years)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Education (Early Years) (Top-up)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education and Professional Development",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance (Professional Practice)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Project Management in Construction",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "All Doctorates at Huddersfield",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Analytical Chemistry",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animation Production",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Health Sciences",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Health Sciences",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Design",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture and Architecture International (RIBA part 2)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MArch",
                    "duration": "3 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture and the Built Environment",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture and the Built Environment",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture and the Built Environment",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Design",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Design",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Design",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive Engineering",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Banking and Finance",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Banking and Finance (Professional Practice)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical and Analytical Science",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management Studies",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management Studies",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management Studies",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Intelligence and Analytics",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Intelligence and Analytics (Professional Practice)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Career Development and Employability",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Process Engineering and Safety",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Pharmacy Practice with Community Placement",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Pharmacy Practice with Hospital Placement",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Pharmacy Practice with Research Project",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognition and Neuroscience",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication, Cultural and Media Studies",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication, Cultural and Media Studies",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Informatics",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Informatics",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary Fine Art",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Music Production",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security and Digital Forensics",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Analytics",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing (Professional Practice)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Doctor of Education",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "EdD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama, Dance and Performance",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama, Dance and Performance",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics (Professional Practice)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Econometrics",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Econometrics",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Econometrics",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Early Childhood Studies)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Leadership and Management)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Mentoring and Coaching)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Special Educational Needs)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Teaching and Learning)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Technology Enhanced Learning)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Vocational Education and Training)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Youth and Community)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Automotive Engineering",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Control Systems and Instrumentation",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Management",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Enterprise",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "EntD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion and Textiles",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion and Textiles",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion and Textiles",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Design",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Marketing",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance (Professional Practice)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fintech",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fintech (Professional Practice)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Science (Forensic Biology)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Science (Forensic Chemistry)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Higher Education",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Childhood",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Illustration",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Systems Management",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Design",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business (Professional Practice)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Law",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Entrepreneurship",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Entrepreneurship (Professional Practice)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Humanitarian Challenges (Professional Practice)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Marketing",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Marketing (Professional Practice)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Project Management",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Project Management (Professional Practice)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Tourism and Hospitality",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Tourism and Hospitality (Professional Practice)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Corporate and Commercial Law",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Investigative Psychology",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism, Media and Film",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership, Communication and Humanitarian Challenges",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership, Communication and Humanitarian Challenges (Professional Practice)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Lifelong Learning PGCE pre-service",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Lifelong Learning PGCE pre-service (Northern College)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Logistics and Supply Chain Management",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Logistics and Supply Chain Management (Professional Practice)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA by Research at Huddersfield",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Professional Practice)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Communication",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Communication (Professional Practice)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Entrepreneurship",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Entrepreneurship (Professional Practice)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Human Resource Management",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Human Resource Management (Professional Practice)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with International Business",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with International Business (Professional Practice)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Leadership",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Leadership (Professional Practice)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Marketing",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Marketing (Professional Practice)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Tourism and Hospitality",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Tourism and Hospitality (Professional Practice)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing (Professional Practice)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing with Brand Management",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing with Brand Management (Professional Practice)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Public Health",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MPH",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Public Health (Advanced Practitioner)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MPH",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Public Health (Global)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MPH",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Public Health (Global) Advanced Practitioner",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MPH",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Public Health (Management and Leadership)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MPH",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc by Research at Huddersfield",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Performance",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MMus (Perf)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oil and Gas Engineering",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paramedic Science (Pre-Registration)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical and Analytical Science",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Photography",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Podiatry",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Popular Music Practice",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MMus",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary and Early Years Education PGCE with QTS",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Education PGCE with QTS",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management and Operations Management",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management and Operations Management (Professional Practice)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Art and Design PGCE with QTS",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Computing PGCE with QTS",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Drama PGCE with QTS",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary English PGCE with QTS",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Geography PGCE with QTS",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary History PGCE with QTS",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Mathematics PGCE with QTS",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Music PGCE with QTS",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Physical Education PGCE with QTS",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Science with Biology PGCE with QTS",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Science with Chemistry PGCE with QTS",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Science with Physics PGCE with QTS",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Communication and Leadership",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Communication and Leadership (Professional Practice)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Communication, Leadership and Sustainability",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Communication, Leadership and Sustainability (Professional Practice)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic People Management",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic People Management",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Supply Chain Management with Humanitarian Challenges",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Supply Chain Management with Humanitarian Challenges (Professional Practice)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Business Leadership",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Business Leadership (Professional Practice)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Supply Chain Management",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Supply Chain Management (Professional Practice)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL) (with Placement)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Textiles",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theory of Podiatric Surgery",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Acute Care",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Project Management in Construction",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "28 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "All Doctorates at Huddersfield",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Analytical Chemistry",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Health Sciences",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Health Sciences",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture and the Built Environment",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture and the Built Environment",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture and the Built Environment",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Design",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Design",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Design",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management Studies",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management Studies",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management Studies",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Career Development and Employability",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication, Cultural and Media Studies",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication, Cultural and Media Studies",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Informatics",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Informatics",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Music Production",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Critical Care",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Doctor of Business Administration",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "DBA",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Doctor of Education",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "EdD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Doctor of Public Administration",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "DPA",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama, Dance and Performance",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama, Dance and Performance",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Years Initial Teacher Training (EYTS) (Graduate Employment Route)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "Professional Qualification",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Econometrics",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Econometrics",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Econometrics",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Early Childhood Studies)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Leadership and Management)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Mentoring and Coaching)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Special Educational Needs)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Teaching and Learning)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Technology Enhanced Learning)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Vocational Education and Training)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Youth and Community)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Emergency Care",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Enterprise",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "EntD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion and Textiles",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion and Textiles",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion and Textiles",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Professional Education",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Studies",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Higher Education",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Childhood",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Lifelong Learning PGCE pre-service",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Long Term Conditions",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA by Research at Huddersfield",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Podiatric Surgery (Advanced Clinical Practice)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Public Administration",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MPA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Public Health",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MPH",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Public Health (Advanced Practitioner)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MPH",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Public Health (Global)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MPH",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Public Health (Global) Advanced Practitioner",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MPH",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc by Research at Huddersfield",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Performance",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MMus (Perf)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Palliative and End-of-Life Care",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical and Analytical Science",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Podiatry",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Popular Music Practice",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MMus",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching in Lifelong Learning (Top-Up)",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theory of Podiatric Surgery",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urgent Care",
                    "university": "University of Huddersfield",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "dfc1c9926de43685c8be4513f18cd85d",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Assistant Practitioner in Health and Social Care",
                    "university": "Hugh Baird College",
                    "location": "University Centre",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business and Management",
                    "university": "Hugh Baird College",
                    "location": "University Centre",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management with Foundation Year",
                    "university": "Hugh Baird College",
                    "location": "University Centre",
                    "qualification": "FdA",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children, Schools and Families (Graduate Practitioner)",
                    "university": "Hugh Baird College",
                    "location": "University Centre",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children, Young People and their Services with Foundation Year",
                    "university": "Hugh Baird College",
                    "location": "University Centre",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Computing",
                    "university": "Hugh Baird College",
                    "location": "University Centre",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Creative Make Up Design and Practice",
                    "university": "Hugh Baird College",
                    "location": "Bootle Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Make Up Design and Practice Foundation Degree",
                    "university": "Hugh Baird College",
                    "location": "Bootle Campus",
                    "qualification": "FdA",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Hugh Baird College",
                    "location": "University Centre",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Criminology (Top Up)",
                    "university": "Hugh Baird College",
                    "location": "Bootle Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Imaging and Photography",
                    "university": "Hugh Baird College",
                    "location": "Bootle Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Imaging and Photography Foundation Degree",
                    "university": "Hugh Baird College",
                    "location": "Bootle Campus",
                    "qualification": "FdA",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Advanced Manufacturing) with Foundation Year",
                    "university": "Hugh Baird College",
                    "location": "University Centre",
                    "qualification": "FdEng",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion and Textiles",
                    "university": "Hugh Baird College",
                    "location": "Bootle Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion and Textiles Foundation Degree",
                    "university": "Hugh Baird College",
                    "location": "University Centre",
                    "qualification": "FdA",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Design (Top Up)",
                    "university": "Hugh Baird College",
                    "location": "University Centre",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "240"
                },
                {
                    "course_name": "Games Design Foundation Degree",
                    "university": "Hugh Baird College",
                    "location": "Bootle Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Hugh Baird College",
                    "location": "University Centre",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Design Foundation Degree",
                    "university": "Hugh Baird College",
                    "location": "University Centre",
                    "qualification": "FdA",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "Hugh Baird College",
                    "location": "University Centre",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Social Care with Foundation Year",
                    "university": "Hugh Baird College",
                    "location": "University Centre",
                    "qualification": "FdA",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health, Nutrition and Physical Activity",
                    "university": "Hugh Baird College",
                    "location": "Bootle Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Mental Health and Wellbeing with Foundation Year",
                    "university": "Hugh Baird College",
                    "location": "University Centre",
                    "qualification": "FdA",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Moving Image Production",
                    "university": "Hugh Baird College",
                    "location": "University Centre",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Moving Image Production Foundation Degree",
                    "university": "Hugh Baird College",
                    "location": "University Centre",
                    "qualification": "FdA",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Merchandising and Promotional Design",
                    "university": "Hugh Baird College",
                    "location": "University Centre",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Merchandising and Promotional Design Foundation Degree",
                    "university": "Hugh Baird College",
                    "location": "University Centre",
                    "qualification": "FdA",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "77e0ecd01f2377959098992b997af994",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Advanced Beauty Therapy and Aesthetics",
                    "university": "Hull College",
                    "location": "Main Site",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Art & Design",
                    "university": "Hull College",
                    "location": "Hull School of Art and Design",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Criminology with Social Justice",
                    "university": "Hull College",
                    "location": "Main Site",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Criminology with Social Justice (Top-up)",
                    "university": "Hull College",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "1 year",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "FDSc Foundation Degree in Renewable Energy and Technologies",
                    "university": "Hull College",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Hull College",
                    "location": "Hull School of Art and Design",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Fine Art (Top-up)",
                    "university": "Hull College",
                    "location": "Hull School of Art and Design",
                    "qualification": "BA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hospitality Management HNC (subject to approval)",
                    "university": "Hull College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Performing Arts",
                    "university": "Hull College",
                    "location": "Hull School of Art and Design",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Young Children's Learning & Development",
                    "university": "Hull College",
                    "location": "Main Site",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Young Children's Learning & Development (BA Top-Up)",
                    "university": "Hull College",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Bachelor  of Engineering - Engineering Technology",
                    "university": "Hull College",
                    "location": "Main Site",
                    "qualification": "BEng",
                    "duration": "1 year",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Creative Practice",
                    "university": "Hull College",
                    "location": "Hull School of Art and Design",
                    "qualification": "MA (Gen)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Graduate Certificate in Education (Pre Service)",
                    "university": "Hull College",
                    "location": "Main Site",
                    "qualification": "PGCE (Professional)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Masters in Education",
                    "university": "Hull College",
                    "location": "Main Site",
                    "qualification": "MEd",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Graduate Certificate in Education (Pre Service)",
                    "university": "Hull College",
                    "location": "No Locations",
                    "qualification": "PGCE (Professional)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "3997ecd7acc0994e55d4eaeeda8f0163",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Physician Associate Studies (Postgraduate)",
                    "university": "Hull York Medical School",
                    "location": "Main Site, University of Hull",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "02ee2036e15edc5754db488ca134402a",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Accounting (with placement year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Accounting (with study abroad)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Accounting and Financial Management",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Accounting and Financial Management (with placement year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Accounting and Financial Management (with study abroad)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Acting",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Biochemistry (with Foundation Year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Biology",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Biology (with foundation year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Biomedical Engineering (with Foundation Year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Biomedical Engineering (with Industrial Placement)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Biomedical Engineering (with Industrial Placement)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biomedical Science (with foundation year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "British Politics and Legislative Studies",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128"
                },
                {
                    "course_name": "BSc Marine Biology with Study Abroad",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Business Economics (with Placement Year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Business Economics (with Study Abroad)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management (with foundation year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Business Management (with placement year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management (with study abroad)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Accounting",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Accounting (with placement year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Accounting (with study abroad)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Entrepreneurship",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Entrepreneurship (with placement year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Entrepreneurship (with study abroad)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Financial Management",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Financial Management (with placement year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Financial Management (with study abroad)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with HRM",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with HRM (with placement year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with HRM (with study abroad)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Logistics and Supply Chain",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Logistics and Supply Chain (with placement year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Logistics and Supply Chain (with study abroad)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Marketing",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Marketing (with placement year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Marketing (with study abroad)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Chemical Engineering (with foundation year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Chemical Engineering (with Industrial Placement)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Chemical Engineering (with Industrial Placement)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Computer Science (Artificial Intelligence)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science (Artificial Intelligence)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Computer Science (Artificial Intelligence) with Foundation Year",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Computer Science (Artificial Intelligence) with Industrial Placement",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science (Artificial Intelligence) with Study Abroad",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science (Software Engineering)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science (Software Engineering)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Computer Science (Software Engineering) with Foundation Year",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Computer Science (Software Engineering) with Industrial Placement",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science (Software Engineering) with Study Abroad",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science (with foundation year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Computer Science for Games Programming",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Computer Science for Games Programming",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science for Games Programming with Foundation Year",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Computer Science for Games Programming with Industrial Experience",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science for Games Programming with Study Abroad",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science with Industrial Placement",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science with Study Abroad",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Creative Writing and English",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Creative Writing and Film Studies",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology (with Foundation Year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology with Forensic Science",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology with Law",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology with Psychology",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Diagnostic Radiography",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Drama",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Early Childhood Education and Care (Top up)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Earth and Environmental Science",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Earth and Environmental Science (with Foundation Year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Earth and Environmental Science (with Placement Year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Earth and Environmental Science (with Study Abroad)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Economics (with Placement Year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Economics (with Study Abroad)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Education and Childhood Studies (Top up)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BEd (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Education Studies with Foundation Year",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Electrical and Electronic Engineering (with Foundation Year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Electrical and Electronic Engineering (with Industrial Placement)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Electrical and Electronic Engineering (with Industrial Placement)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128"
                },
                {
                    "course_name": "English",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "English (with Foundation Year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Environmental Science (with Foundation Year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Environmental Science (with Placement Year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Environmental Science (with Study Abroad)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Film Studies",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Film Studies (with Foundation Year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Financial Management",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Financial Management (with placement year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Financial Management (with study abroad)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Forensic Science (with Foundation Year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Game Design",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Game Design with a Year in Industry",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Games Design and Creative Writing",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Geography (with foundation year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Geography (with Foundation Year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Geography (with Industrial Placement)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Geography (with Industrial Placement)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Geography (with Study Abroad)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Geography (with Study Abroad)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "History",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "History (with Foundation Year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "History and Politics",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Business (with placement year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Business (with study abroad)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Law",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Law (European Legal Studies)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Law (with foundation year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Law and Legislative Studies (incl year in Westminster)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Law Senior Status",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Business Management",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Law with Business Management (with Study Abroad)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Law with Criminology (with Study Abroad)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Law with Politics",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Law with Politics (with Study Abroad)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Marine Biology",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Marine Biology (with foundation year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Marketing (with placement year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Marketing (with study abroad)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Marketing and Management",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Marketing and Management (with Placement Year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Marketing and Management (with Study Abroad)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Mathematics (with Foundation Year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Mathematics with Data Science",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Mathematics with Data Science (with Foundation Year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Mechanical Engineering (with foundation year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Mechanical Engineering (with Industrial Placement)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Mechanical Engineering (with Industrial Placement)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Media Production",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Media Studies",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Media Studies (with Foundation Year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Midwifery",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Midwifery",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MSc (PG)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery (Short Programme for Registered Nurses)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Music (Community & Education)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Music (Performance)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Music (Popular Music)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Music (Songwriting)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Music Production",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing (Child)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Nursing (Learning Disability)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Operating Department Practice",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Paramedic Science",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Philosophy (with Foundation Year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Physics (with foundation year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Politics",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Politics (with foundation year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Politics, Philosophy and Economics",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Primary Teaching Studies",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Professional Practice in Early Years 0-8 (Foundation Degree)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "FdEd",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Psychology (with foundation year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Psychology with Criminology",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Psychology with Criminology (with Foundation Year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Robotics and Artificial Intelligence",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Robotics and Artificial Intelligence",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Robotics and Artificial Intelligence with Foundation Year",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Robotics and Artificial Intelligence with Industrial Placement",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Robotics and Artificial Intelligence with Study Abroad",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Secondary Teaching Studies (Computer Science)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Secondary Teaching Studies (English)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Secondary Teaching Studies (Geography)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Secondary Teaching Studies (History)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Secondary Teaching Studies (Mathematics)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Secondary Teaching Studies (Physics)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Social Work (Postgraduate)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Nutrition (with Foundation Year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport and Exercise Science (with Foundation Year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Sport Rehabilitation",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport Rehabilitation (with Foundation Year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Sports Coaching and Performance Science",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sports Coaching and Performance Science (with Foundation Year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "War and Politics",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Working with Children, Young People and Families",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Zoology",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Zoology (with foundation year)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "LLM International Law (Global Sustainability and the Law)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "LLM International Law (Human Rights, Conflict and Security)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA Coaching and Mentoring in Education",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA Criminal Justice and Crime Control",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA Criminal Justice and Crime Control (Contemporary Slavery)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA Early Childhood and Education",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA Education",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA English Literature",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA History",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA Immersive Design in Extended Realities (XR)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA in Creative Writing and English Literature",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA in Higher Education Practice",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA in Professional Practice in Education",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA International Politics",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA Leadership and Management in Education",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA Social Justice and Community Engagement",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA Special Educational Needs, Disabilities and Inclusion",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA Strategy and International Security",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA TESOL (Teaching English to Speakers of Other Languages)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA Theatre Making",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MMUS Music (Pathways: Musicology, Composition, Performance, Technology)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MMus",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Accounting and Finance",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Advanced Chemical Engineering",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Advanced Computer Science",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Artificial Intelligence and Data Science",
                    "university": "University of Hull",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Artificial Intelligence for Engineering",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Artificial Intelligence for Geospatial Data Analysis",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Artificial Intelligence for Healthcare",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Biomedical Engineering",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Biomedical Science",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Business Management",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Clinical Applications of Psychology",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Computer Science for Games Programming",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Digital Marketing & Advertising",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Energy Engineering",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Environmental Change, Management and Monitoring",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Financial Management",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Flood Risk Management",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Health and Climate Change",
                    "university": "University of Hull",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Human Resource Management",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Logistics and Supply Chain Management",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Marketing Management",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Mathematics",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Mechanical Engineering",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Nutrition and Dietetics (pre-registration)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Renewable Energy and Low Carbon Solutions",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Social Research",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work (Postgraduate)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Executive MBA (EMBA)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global MBA",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA Coaching and Mentoring in Education",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA Criminal Justice and Crime Control",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA Criminal Justice and Crime Control (Contemporary Slavery)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA Early Childhood and Education",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA Education",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA English Literature",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA History",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA in Creative Writing and English Literature",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA in Higher Education Practice",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA in Professional Practice in Education",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA Leadership and Management in Education",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA Social Justice and Community Engagement",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA Special Educational Needs, Disabilities and Inclusion",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA TESOL (Teaching English to Speakers of Other Languages)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MMUS Music (Pathways: Musicology, Composition, Performance, Technology)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MMus",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Advanced Clinical Practice",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Business Management",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Flood Risk Management",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MSc",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Health and Climate Change",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Human Resource Management",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Renewable Energy and Low Carbon Solutions",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Social Research",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc/MRes Environmental Management (in partnership with NEBOSH)",
                    "university": "University of Hull",
                    "location": "No Locations",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PG Cert Clinical Assessment and Examination",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCert Practice Assessor (Community)",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCert Practice Educator",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PgDip Advanced Clinical Assessment and Examination",
                    "university": "University of Hull",
                    "location": "The University of Hull",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "0492cd6bb6e087d680825f12302a8ac9",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "5fac796291a198b2c1b5d81e99483a50",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Drafting Legislation, Regulation, and Policy",
                    "university": "Institute of Advanced Legal Studies, School of Advanced Study, University of London",
                    "location": "Institute of Advanced Legal Studies",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MPhil/PhD with the Institute of Advanced Legal Studies",
                    "university": "Institute of Advanced Legal Studies, School of Advanced Study, University of London",
                    "location": "Institute of Advanced Legal Studies",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Drafting Legislation, Regulation, and Policy",
                    "university": "Institute of Advanced Legal Studies, School of Advanced Study, University of London",
                    "location": "Institute of Advanced Legal Studies",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MPhil/PhD with the Institute of Advanced Legal Studies",
                    "university": "Institute of Advanced Legal Studies, School of Advanced Study, University of London",
                    "location": "Institute of Advanced Legal Studies",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "3205ee1e5f4ec163f9a2454fce40a375",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "045f2a731230ec9f1886b71444610842",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "MPhil/PhD in Commonwealth Studies",
                    "university": "Institute of Commonwealth Studies, School of Advanced Study, University of London",
                    "location": "Institute of Commonwealth Studies",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "MPhil/PhD in Commonwealth Studies",
                    "university": "Institute of Commonwealth Studies, School of Advanced Study, University of London",
                    "location": "Institute of Commonwealth Studies",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "8841cdf79ede1c716a225c6ca699b7d5",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "3b9927b9477adf366bf288fe97aa233d",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "637707b9c45c114eec30f3d824ab3252",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "History of the Book",
                    "university": "Institute of English Studies, School of Advanced Study, University of London",
                    "location": "Institute of English Studies",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MPhil/PhD with the Institute of English Studies",
                    "university": "Institute of English Studies, School of Advanced Study, University of London",
                    "location": "Institute of English Studies",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "History of the Book",
                    "university": "Institute of English Studies, School of Advanced Study, University of London",
                    "location": "Institute of English Studies",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MPhil/PhD with the Institute of English Studies",
                    "university": "Institute of English Studies, School of Advanced Study, University of London",
                    "location": "Institute of English Studies",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "01ca4b43fafbb5fe80a07a7c5ec1dedc",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "History, Place and Community",
                    "university": "Institute of Historical Research, School of Advanced Study, University of London",
                    "location": "Institute of Historical Research",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MPhil/PhD with the Institute of Historical Research",
                    "university": "Institute of Historical Research, School of Advanced Study, University of London",
                    "location": "Institute of Historical Research",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "History, Place and Community",
                    "university": "Institute of Historical Research, School of Advanced Study, University of London",
                    "location": "Institute of Historical Research",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MPhil/PhD with the Institute of Historical Research",
                    "university": "Institute of Historical Research, School of Advanced Study, University of London",
                    "location": "Institute of Historical Research",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "752f02d6bc23bca624f3c9080e746c4e",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Languages, Cultures and Societies",
                    "university": "Institute of Languages, Cultures and Societies, School of Advanced Study, University of London",
                    "location": "Institute of Modern Languages Research",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Latin American and Caribbean Studies",
                    "university": "Institute of Languages, Cultures and Societies, School of Advanced Study, University of London",
                    "location": "Institute of Modern Languages Research",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Languages, Cultures and Societies",
                    "university": "Institute of Languages, Cultures and Societies, School of Advanced Study, University of London",
                    "location": "Institute of Modern Languages Research",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Latin American and Caribbean Studies",
                    "university": "Institute of Languages, Cultures and Societies, School of Advanced Study, University of London",
                    "location": "Institute of Modern Languages Research",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "067431c8263082e9578acd41a9b29904",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Business",
                    "university": "International Business College Manchester",
                    "location": "International Business College Manchester",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business",
                    "university": "International Business College Manchester",
                    "location": "International Business College Manchester",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computing",
                    "university": "International Business College Manchester",
                    "location": "International Business College Manchester",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computing",
                    "university": "International Business College Manchester",
                    "location": "International Business College Manchester",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Hospitality Management",
                    "university": "International Business College Manchester",
                    "location": "International Business College Manchester",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Hospitality Management",
                    "university": "International Business College Manchester",
                    "location": "International Business College Manchester",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Business",
                    "university": "International Business College Manchester",
                    "location": "International Business College Manchester",
                    "qualification": "HND",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business",
                    "university": "International Business College Manchester",
                    "location": "International Business College Manchester",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "eea0bc0fc73473f4dccfda246db387b7",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "40a218ec9467bb21d229f3cd620a6ddf",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "3e57925028bce7400d1b484d78c2f984",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": [
                {
                    "course_name": "Theology",
                    "university": "Irish Baptist College",
                    "location": "Irish Baptist College",
                    "qualification": "MTh",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "2fcd1b700750ec6e22828948229fc199",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Hawza Studies",
                    "university": "Islamic College for Advanced Studies",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Islamic Studies",
                    "university": "Islamic College for Advanced Studies",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Islamic studies",
                    "university": "Islamic College for Advanced Studies",
                    "location": "Main Site",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Qur'anic Arabic",
                    "university": "Islamic College for Advanced Studies",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Islamic Law",
                    "university": "Islamic College for Advanced Studies",
                    "location": "Islamic College for Advanced Studies",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Islamic Studies",
                    "university": "Islamic College for Advanced Studies",
                    "location": "Islamic College for Advanced Studies",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Islamic Studies - Distance Learning",
                    "university": "Islamic College for Advanced Studies",
                    "location": "Islamic College for Advanced Studies",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Islamic Law",
                    "university": "Islamic College for Advanced Studies",
                    "location": "Islamic College for Advanced Studies",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Islamic Studies",
                    "university": "Islamic College for Advanced Studies",
                    "location": "Islamic College for Advanced Studies",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Islamic Studies - Distance Learning",
                    "university": "Islamic College for Advanced Studies",
                    "location": "Islamic College for Advanced Studies",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "b3f8fed92a47e56731d1da127410723a",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Fashion Business",
                    "university": "Istituto Marangoni London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Fashion Business and Buying",
                    "university": "Istituto Marangoni London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Fashion Business, Digital Communication and Media",
                    "university": "Istituto Marangoni London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Fashion Design",
                    "university": "Istituto Marangoni London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Fashion Design and Accessories",
                    "university": "Istituto Marangoni London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Fashion Design and Marketing",
                    "university": "Istituto Marangoni London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Fashion Styling and Creative Direction",
                    "university": "Istituto Marangoni London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Foundation in Fashion and Design",
                    "university": "Istituto Marangoni London",
                    "location": "Main Site",
                    "qualification": "No award",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Design",
                    "university": "Istituto Marangoni London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Product Design",
                    "university": "Istituto Marangoni London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Visual Design",
                    "university": "Istituto Marangoni London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Digital Transformation and Leadership for Fashion",
                    "university": "Istituto Marangoni London",
                    "location": "Istituto Marangoni London",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion and Luxury Brand Management",
                    "university": "Istituto Marangoni London",
                    "location": "Istituto Marangoni London",
                    "qualification": "MA",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Buying and Merchandising",
                    "university": "Istituto Marangoni London",
                    "location": "Istituto Marangoni London",
                    "qualification": "MA",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Design",
                    "university": "Istituto Marangoni London",
                    "location": "Istituto Marangoni London",
                    "qualification": "MA",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Promotion Communication and Digital Media",
                    "university": "Istituto Marangoni London",
                    "location": "Istituto Marangoni London",
                    "qualification": "MA",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Styling, Creative Direction and Digital Content",
                    "university": "Istituto Marangoni London",
                    "location": "Istituto Marangoni London",
                    "qualification": "MA",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Design",
                    "university": "Istituto Marangoni London",
                    "location": "Istituto Marangoni London",
                    "qualification": "MA",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Jewellery Design",
                    "university": "Istituto Marangoni London",
                    "location": "Istituto Marangoni London",
                    "qualification": "MA",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Luxury Accessories Design",
                    "university": "Istituto Marangoni London",
                    "location": "Istituto Marangoni London",
                    "qualification": "MA",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design",
                    "university": "Istituto Marangoni London",
                    "location": "Istituto Marangoni London",
                    "qualification": "MA",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        }
    },
    {
        "id": "03504e8a1eb06eee9c0bb39d7cb9f617",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Fashion: Design & Accessories",
                    "university": "JCA | London Fashion Academy",
                    "location": "Boston Manor Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                },
                {
                    "course_name": "Fashion: Design, Branding & Entrepreneurship",
                    "university": "JCA | London Fashion Academy",
                    "location": "Mayfair Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-120"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Fashion Entrepreneurship in Design & Brand Innovation",
                    "university": "JCA | London Fashion Academy",
                    "location": "Mayfair Campus",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        }
    },
    {
        "id": "213d34d328c0eda86d06fa4e4470681f",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Accounting and Finance with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Accounting with Business Analytics",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Accounting with Business Analytics with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Astrophysics with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Astrophysics with Integrated Master's",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Bachelor's Business Degree with a Blended Learning Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Bachelor's Humanities Degree with a Blended Learning Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Bachelor's Science Degree with a Blended Learning Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "40-56"
                },
                {
                    "course_name": "Bachelor's Social Science Degree with a Blended Learning Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "40-56"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Biochemistry and Neuroscience",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Biochemistry with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Biochemistry with Integrated Masters",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Bioengineering (Regenerative Medicine)",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Bioengineering (Regenerative Medicine) with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Biology",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Biology and Forensic Science",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Biology with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3.5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Biomedical Science with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Bioveterinary Science",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich with time abroad",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Bioveterinary Science with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Business Management",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management and Accounting",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management and Accounting with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Business Management and Economics",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management and Economics with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Business Management and Finance",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management and Finance with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Business Management and Marketing",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management and Marketing with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Business Management and Media",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management and Music Production",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Analytics",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Analytics with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Business Management with Entrepreneurship",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Events",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Business Management with Hospitality",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Human Resources",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Human Resources with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Business Management with International Tourism",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Supply Chain Management",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Sustainability",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Psychology",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Business Psychology with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Chemistry and Forensic Science",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Chemistry and Mathematics",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Chemistry with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "MChem",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Chemistry with Integrated Masters",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "MChem",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Chemistry with Materials Chemistry with Integrated Master's (MChem)",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Chemistry with Medicinal Chemistry",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Chemistry with Medicinal Chemistry with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "MChem",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Chemistry with Medicinal Chemistry with Integrated Masters",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "MChem",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Computer Science (Artificial Intelligence)",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Computer Science (Cyber Security)",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Computer Science (Digital Forensics)",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Computer Science (Digital Forensics) with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Computer Science (Games)",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Computer Science (Software Engineering)",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Computer Science (Top-up Degree) BSc",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Computer Science and Mathematics",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Computer Science and Music Production",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Neuroscience",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Computer Science and Physics",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Computer Science with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "MComp (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Computer Science with Integrated Masters",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "MComp (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Creative Writing with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Criminology",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology and Criminal Justice",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology and Criminal Justice with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Criminology and History",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Data Science",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Data Science with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Digital Media",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Digital Media with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Ecology and Conservation",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Ecology and Conservation with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Ecology and Conservation with Integrated Masters",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Economics",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Economics and Finance with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Economics BA",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Economics BA with a Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Economics BSc with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Education",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Education and English Literature",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Education and History",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Education and Philosophy",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Education and Sociology",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Education with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "English",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English and Creative Writing",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English and Creative Writing with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "English Literature and Film Studies",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English Literature and History",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English Literature and Philosophy",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Environmental Science and Geography",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Environmental Science with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Esports Business Management",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Esports Business Management with a Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Film Studies",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Film Studies and Creative Writing",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Film Studies and Creative Writing with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Film Studies and Media",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Film Studies and Music Production",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Film Studies with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Forensic Psychology with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Forensic Science and Criminology",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Forensic Science and Criminology with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Forensic Science and Neuroscience",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Forensic Science with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Forensic Science with Integrated Masters",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Forensic Science with Integrated Masters with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Game Design BA",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Game Design with a Foundation Year BA",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Geography (Human and Physical)",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Geography (Human)",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Geography (Physical)",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Geography (Physical) with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Geography with Business Management",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Geography with Business Management with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Geography with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Geology",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Geology (Applied Geophysics)",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Geology (Environmental Geoscience)",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Geology (Volcanology)",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Geology and Physical Geography",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Geology and Physical Geography with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Geology with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "MGeol",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "History",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "History and International Relations",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "History and Politics",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "History with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Human Biology and Psychology",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Human Biology with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "International Business Management",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Business Management with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "International Relations",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Relations and Politics",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Relations with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Law",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Law (Graduate Entry)",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Business",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Law with Business with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Law with Criminology with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Law with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Law with Politics",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Law with Politics with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Law with Professional Legal Practice",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Law with Professional Legal Practice with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Marketing",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Marketing and Media",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Marketing with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Mathematics (Applied Mathematics)",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Mathematics (Pure Mathematics)",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Mathematics (with Statistics)",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Mathematics and Physics",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Mathematics with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "MMath (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Mathematics with Integrated Masters",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "MMath (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Media and Communications",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Media and Communications with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Media and Music Production",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Media and Sociology",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Media with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Medicine",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "MB ChB",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "MB ChB",
                    "duration": "6 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology and Immunology",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Microbiology and Immunology BSc with a Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Microbiology and Immunology Integrated Masters",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "Master in Science (with Honours) \u2013 MSci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Microbiology and Immunology MSci with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "Master in Science (with Honours) \u2013 MSci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Midwifery",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Midwifery with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BMid (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Music Production and Sound Design",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Music Production and Sound Design with a Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Music Production with a Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Neuroscience",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Neuroscience with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Neuroscience with Psychology BSc",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Neuroscience with Psychology with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing (Adult) with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BN (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Nursing (Children's)",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing (Children's) with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BN (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing (Mental Health) with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BN (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Occupational Therapy - BSc",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Occupational Therapy with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Paramedic Science with Integrated Master's",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Paramedic Science with Integrated Masters with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Pharmaceutical and Cosmetic Science",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Pharmaceutical and Cosmetic Science with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Pharmaceutical Science",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Pharmaceutical Science with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Pharmacology with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Pharmacology with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Pharmacy",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "MPharm (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Pharmacy with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "MPharm (H)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Philosophy and Politics",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Philosophy and Psychology",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Philosophy with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Physician Associate Studies MSc",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Physics with Astrophysics with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Physics with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Physics with Renewable Energy",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Physics with Renewable Energy with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Physiotherapy with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Physiotherapy with Integrated Master's",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Politics",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Politics with Economics",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Politics with Economics with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Politics with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Psychology",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Psychology and Criminology",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Psychology and Education",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Psychology and Education with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Psychology and Sociology",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Psychology with Counselling",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Psychology with Counselling with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Psychology with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Psychology with Neuroscience BSc",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Psychology with Neuroscience with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Psychology with Placement Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Radiography (Diagnostic Imaging)",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Radiography (Diagnostic Imaging) with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Rehabilitation and Exercise Science",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Rehabilitation and Exercise Science with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Social and Political Sciences",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Social and Political Sciences with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Social Work",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Social Work with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Sociology",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sociology with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Speech and Language Therapy",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "Master in Science (with Honours) \u2013 MSci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Speech and Language Therapy with Integrated Master's (pre-registration) with a Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Sport and Exercise Psychology",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Sport and Exercise Psychology with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Sport and Exercise Science with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Sports Business Management",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sports Business Management with a Foundation year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Sustainability and Environment Management with Foundation Year",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Sustainability and Environmental Management",
                    "university": "Keele University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Social Work",
                    "university": "Keele University",
                    "location": "Keele Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        }
    },
    {
        "id": "d55e331046d4f307fdf101f880466bb8",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Medicine",
                    "university": "Kent and Medway Medical School",
                    "location": "Canterbury",
                    "qualification": "BMBS",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "8377d9e9da3aee2606e19204191861ef",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Accounting and Finance with a Foundation Year",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Accounting and Finance with a Year Abroad",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Accounting and Finance with a year in Industry",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Actuarial Science",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Actuarial Science with a Foundation Year",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Actuarial Science with a Year in Industry",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Ancient History / Ancient History with Archaeology (Optional Pathway)",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Animal Biology and Wildlife Conservation",
                    "university": "University of Kent",
                    "location": "Canterbury College (East Kent College)",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Science (Top-up)",
                    "university": "University of Kent",
                    "location": "Canterbury College (East Kent College)",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-136"
                },
                {
                    "course_name": "Architecture with a Foundation Year",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Astronomy, Space Science and Astrophysics",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Astronomy, Space Science and Astrophysics (MPhys 4 years)",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Astronomy, Space Science and Astrophysics with a Foundation Year",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Astronomy, Space Science and Astrophysics with a year Abroad (MPhys 4 years)",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Astronomy, Space Science and Astrophysics with a Year in Industry",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Biochemistry with a Foundation Year",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Biochemistry with a Year Abroad",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Biochemistry with a Year in Professional Practice",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Biology",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Biology with a Foundation Year",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Biology with a Year Abroad",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Biology with a Year in Professional Practice",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Biomedical Engineering including a Foundation Year",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Biomedical Engineering with a Year in Industry",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Biomedical Science with a Foundation Year",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Biomedical Science with a Year Abroad",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Biomedical Science with a Year in Professional Practice",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Business (Top-up)",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics and Management",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Business Analytics and Management with a Foundation Year",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Business Analytics and Management with a Year Abroad",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Business Analytics and Management with a Year in Industry",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Business and Management",
                    "university": "University of Kent",
                    "location": "University of Kent at Medway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Business and Management",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Business and Management with a Foundation Year",
                    "university": "University of Kent",
                    "location": "University of Kent at Medway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Business and Management with a Foundation Year",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Business and Management with a Year Abroad",
                    "university": "University of Kent",
                    "location": "University of Kent at Medway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Business and Management with a Year Abroad",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Business and Management with a Year in Industry",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Business and Management with a year in Industry",
                    "university": "University of Kent",
                    "location": "University of Kent at Medway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Business and Marketing",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Business and Marketing with a Foundation Year",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Business and Marketing with a Year Abroad",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Business and Marketing with a Year in Industry",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Business Information Technology",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Business Information Technology with a Year in Industry",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Chemistry (MChem 4 years)",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Chemistry with a Foundation Year",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Chemistry with a Year Abroad",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Chemistry with a Year in Industry",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Classical Studies / Classical Studies with Archaeology (Optional Pathway)",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Computer Science (Artificial Intelligence)",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Computer Science (Artificial Intelligence) with a Year in Industry",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Computer Science (Cyber Security)",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Computer Science (Cyber Security) with a Year in Industry",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Computer Science with a Year in Industry",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Criminology and Criminal Justice",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Data Science with a Year in Industry",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Digital Design",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Digital Design with a Year Abroad (4 years)",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Digital Design with a Year in Industry (4 years)",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Drama and Film",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Drama, Theatre and Performing Arts",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Ecology and Conservation",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Ecology and Conservation with a Year Abroad",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Ecology and Conservation with a Year in Professional Practice",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Economics and Finance with a Foundation Year",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Economics and Finance with a Year in Industry",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Economics and Management",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Economics and Management with a Foundation Year",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Economics and Management with a Year in Industry",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Economics and Politics",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Economics and Politics with a Year in Industry",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Economics with a Foundation Year",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Economics with a Year Abroad",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Economics with a Year in Industry",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Electrical and Electronic Engineering BEng",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Electrical and Electronic Engineering with a Foundation Year",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Electrical and Electronic Engineering with a Year in Industry BEng",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Electronic and Computer Engineering",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Electronic and Computer Engineering including a Foundation Year (4 years)",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Electronic and Computer Engineering with a Year in Industry (4 years)",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Electronic and Computer Systems (Top-up)",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Literatures",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "English Literature and Creative Writing",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Film",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Film and Media",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Finance and Investment",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Finance and Investment with a Foundation Year",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Finance and Investment with a Year Abroad",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Finance and Investment with a Year in Industry",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Forensic Science (MSci 4 years)",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Forensic Science with a Foundation Year (4 years)",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Forensic Science with a Year Abroad",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Forensic Science with a Year in Industry",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "History",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "International Business with a Foundation Year",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "International Business with a Year Abroad",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "International Business with a year in Industry",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "International Legal Studies with a Year Abroad",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Law",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Law (Graduate Entry)",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (Integrated Masters in Solicitors\u2019 Practice)",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "MLaw",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Law and Criminology",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Law and Politics",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Law with a Foundation Year",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Mathematics with a Foundation Year (4 years)",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Mathematics with a Year in Industry",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Mechanical Engineering including a Foundation Year",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Mechanical Engineering with a Year in Industry",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Media",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Military History",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Modern Languages",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Modern Languages with a Year Abroad",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Physics (4 years)",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Physics with a Foundation Year (4 years)",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Physics with a Year Abroad (4 years)",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Physics with a Year in Industry",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Physics with Astrophysics (4 years)",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Physics with Astrophysics with a Foundation Year",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Physics with Astrophysics with a Year Abroad (4 years)",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Physics with Astrophysics with a Year in Industry",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Politics and International Relations with a Year in Continental Europe",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Politics and International Relations with a Year in Industry",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Politics and International Relations with a Year in North America",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Politics and International Relations with a Year in the Asia-Pacific",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Psychology with a Foundation Year",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Psychology with a Year Abroad",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Psychology with a Year in Professional Practice",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Psychology with Business Psychology",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Psychology with Business Psychology with a Foundation Year",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Psychology with Business Psychology with a Year Abroad",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Psychology with Business Psychology with a Year in Professional Practice",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Psychology with Clinical Psychology",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Psychology with Clinical Psychology with a Foundation Year",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Psychology with Clinical Psychology with a Year Abroad",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Psychology with Clinical Psychology with a Year in Professional Practice",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Psychology with Forensic Psychology",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Kent",
                    "location": "University of Kent at Medway",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Kent",
                    "location": "University of Kent at Medway",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Software Engineering with a Year in Industry",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Spatial and Interior Design",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Sport and Exercise Science with a Foundation Year",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Sport and Exercise Science with a Year in Industry",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Sports Therapy and Rehabilitation",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Sports Therapy and Rehabilitation with a Foundation Year",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Sports Therapy and Rehabilitation with a Year in Industry",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Actuarial Science",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Actuarial Science",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agri-Environmental Economics",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "American Studies",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Actuarial Science",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Actuarial Science (Integrated Master's)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Health Research",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Psychology",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD with Integrated Study",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Conservation",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Visualisation",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture and the Sustainable Environment",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biodiversity Management",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedicine",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology and Bioengineering",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "University of Kent",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cell Biology",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical and Archaeological Studies",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Psychology - MSc",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Psychology and Neuropsychology",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature (Canterbury)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Politics (Canterbury)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Biology",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Artificial Intelligence)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Cyber Security)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation Science",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing (Canterbury)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Arts",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Arts (by Research)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing and Analytics",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Doctorate in Cultural and Global Criminology",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Data Science (Double Award)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering (by Research)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and American Literature (Canterbury)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Social Science",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film (Practice as Research)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance, Investment and Risk",
                    "university": "University of Kent",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Management",
                    "university": "University of Kent",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French (Canterbury and Paris)",
                    "university": "University of Kent",
                    "location": "Multiple Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French (Canterbury only)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Comparative Literature (Canterbury)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genetics",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Comparative Literature",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Healthcare Management",
                    "university": "University of Kent",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Logistics and Supply Chain Management",
                    "university": "University of Kent",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Infectious Diseases",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Management",
                    "university": "University of Kent",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Conflict Analysis (Canterbury)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Negotiation and Conflict Resolution",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations (Canterbury)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations with International Law",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Social Policy",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PgDip",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "LLM (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Kent",
                    "location": "Medway campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management Science",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Kent",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval and Early Modern Studies",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval and Early Modern Studies (Canterbury)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Migration Studies",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern History",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Organisational and Business Psychology",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Peace and Conflict Studies (International Joint Award)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance and Theatre Making",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy",
                    "university": "University of Kent",
                    "location": "Medway campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy (Biochemistry and Cell Biology)",
                    "university": "University of Kent",
                    "location": "Medway campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy (Chemistry and Drug Delivery)",
                    "university": "University of Kent",
                    "location": "Medway campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy (Health Services Research)",
                    "university": "University of Kent",
                    "location": "Medway campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy (Pharmacology and Physiology)",
                    "university": "University of Kent",
                    "location": "Medway campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Poetry as Practice",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Poetry: Text, Practice as Research",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political and Social Thought",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Postcolonial Studies (Canterbury)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Promotional Media",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Reproductive Medicine (Science and Ethics)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Security and Terrorism",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Psychology",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Psychology",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research Methods",
                    "university": "University of Kent",
                    "location": "Multiple Locations",
                    "qualification": "PgCert",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Socio-Legal Studies",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "LLM (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Science (by Research and Thesis)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport, Exercise and Health Science - SportD",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "Professional Doctorate",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport, Exercise Science and Sports Therapy",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Project Management",
                    "university": "University of Kent",
                    "location": "Medway campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Engineering",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Finance and Investment",
                    "university": "University of Kent",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Text and Event in Early Modern Europe",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Text, Practice and Research",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "The Contemporary Novel (Practice as Research)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Planning & Resilience",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Studies",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Accounting",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Actuarial Science",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agri-Environmental Economics",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "American Studies",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Health Research",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Conservation",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Practice",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PgDip (Initial Qualification)",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture and the Sustainable Environment",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biodiversity Management",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedicine",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology and Bioengineering",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cell Biology",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical and Archaeological Studies",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Psychology - MSc",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Psychology and Neuropsychology",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature (Canterbury)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Politics (Canterbury)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Biology",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Artificial Intelligence)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Cyber Security)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation Science",
                    "university": "University of Kent",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing (Canterbury)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Arts",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Arts (by Research)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering (by Research)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and American Literature (Canterbury)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Social Science",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film (Practice as Research)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French (Canterbury and Paris)",
                    "university": "University of Kent",
                    "location": "Multiple Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French (Canterbury only)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Comparative Literature (Canterbury)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genetics",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Comparative Literature",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Kent",
                    "location": "Medway campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Infectious Diseases",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Conflict Analysis (Canterbury)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Negotiation and Conflict Resolution",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations (Canterbury)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations with International Law",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Social Policy",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "LLM (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PgDip",
                    "duration": "20 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management Science",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval and Early Modern Studies",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval and Early Modern Studies (Canterbury)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Migration Studies",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern History",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Organisational and Business Psychology",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance and Theatre Making",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy",
                    "university": "University of Kent",
                    "location": "Medway campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy (Biochemistry and Cell Biology)",
                    "university": "University of Kent",
                    "location": "Medway campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy (Chemistry and Drug Delivery)",
                    "university": "University of Kent",
                    "location": "Medway campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy (Health Services Research)",
                    "university": "University of Kent",
                    "location": "Medway campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy (Pharmacology and Physiology)",
                    "university": "University of Kent",
                    "location": "Medway campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Poetry as Practice",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Poetry: Text, Practice as Research",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political and Social Thought",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Postcolonial Studies (Canterbury)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Promotional Media",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Reproductive Medicine (Science and Ethics)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Security and Terrorism",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Psychology",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Psychology",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research Methods",
                    "university": "University of Kent",
                    "location": "Multiple Locations",
                    "qualification": "PgCert",
                    "duration": "21 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Socio-Legal Studies",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "LLM (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Science (by Research and Thesis)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport, Exercise and Health Science - SportD",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "Professional Doctorate",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport, Exercise Science and Sports Therapy",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Engineering",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Text, Practice and Research",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "The Contemporary Novel (Practice as Research)",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Planning & Resilience",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Studies",
                    "university": "University of Kent",
                    "location": "Canterbury campus",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "9dff00577cfacc4017d64ff613577051",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "3e920dbf33f8e319ac7795165340ee15",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "e68824cd3757edbcb8ea60960a5f7a46",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Animal Behaviour Welfare Conservation (Top Up)",
                    "university": "Kingston Maurward College",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Behaviour, Welfare and Conservation",
                    "university": "Kingston Maurward College",
                    "location": "Main Site",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Dairy Herd Management",
                    "university": "Kingston Maurward College",
                    "location": "Main Site",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Equine Management",
                    "university": "Kingston Maurward College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Marine Ecology and Conservation",
                    "university": "Kingston Maurward College",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Outdoor Adventure Management",
                    "university": "Kingston Maurward College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Public Services Management",
                    "university": "Kingston Maurward College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Rural Business Management",
                    "university": "Kingston Maurward College",
                    "location": "Main Site",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Rural Business Management Top Up",
                    "university": "Kingston Maurward College",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "d8b88adfc623fc865de5a31452a67746",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "3D Design Innovation",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "3D Design Innovation with Professional Placement",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Accounting and Finance (including Foundation Year)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Accounting and Finance with Professional Placement Year",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Adult and Mental Health Nursing",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Adult Nursing",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Adult Nursing MSc",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Aerospace Engineering (Foundation)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Aerospace Engineering (Space Technology)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Aerospace Engineering (Space Technology)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Aerospace Engineering (Space Technology)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Aerospace Engineering (Space Technology)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Aerospace Engineering (Space Technology) Foundation",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Aircraft Engineering (top-up)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Architecture",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Aviation Engineering",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Aviation Engineering with Professional Placement",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Aviation Operations with Commercial Pilot Training",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Aviation Operations with Commercial Pilot Training (with Professional Placement)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Biochemistry (including Foundation Year)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Biochemistry with Professional Placement",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Biological Sciences with Foundation Year",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Biological Sciences with Professional Placement",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Biomedical Science (Foundation)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Biomedical Science with Professional Placement",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Building Surveying",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Building Surveying with Professional Placement",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Business",
                    "university": "Kingston University",
                    "location": "Kingston College",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Business and Accounting",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Business and Accounting (including Foundation Year)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business and Accounting with Professional Placement Year",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Business Economics",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Business Economics (4 years full time with Professional Placement)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Business Economics including Foundation Year",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Business Management (including Foundation Year)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management and Digital Innovation",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Business Management and Digital Innovation (including Foundation Year)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management and Digital Innovation with Professional Placement Year",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Business Management and Entrepreneurship",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Business Management and Entrepreneurship (including Foundation)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management and Entrepreneurship and Professional Placement Year",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Business Management and Marketing",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Business Management and Marketing (including Foundation year)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management and Marketing with Professional Placement Year",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Business Management with Professional Placement Year",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Business Psychology",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Business Psychology  including Foundation Year",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Psychology (4 years full time with Professional Placement)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Chemistry (Foundation)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Chemistry with Professional Placement",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Children's and Learning Disabilities Nursing",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Children's Nursing",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "MEng (Eur)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Civil Engineering (with Foundation Year)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Civil Engineering with Professional Placement",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "MEng (Eur)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Civil Engineering with Professional Placement",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "MEng (Eur)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Computer Science (including Foundation Year)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Computer Science and Artificial Intelligence",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Computer Science and Artificial Intelligence with Foundation",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Computer Science and Artificial Intelligence with Professional Placement",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Computer Science with Professional Placement",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Construction Project Management (with Professional Placement)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Creative and Cultural Industries: Art Direction",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Creative and Cultural Industries: Design Marketing",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Creative and Cultural Industries: Events and Experiences Design",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Creative Writing Including Foundation",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Creative Writing Including Placement",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Criminal Justice",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Criminal Justice (including Foundation Year)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Criminal Justice with Professional Placement Year",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Criminology",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Criminology (4 years full time with Professional Placement)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Criminology and Forensic Psychology",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Criminology and Forensic Psychology (4 years full time with Professional Placement)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Criminology and Forensic Psychology Including Foundation",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Criminology and Sociology (4 years full time with Professional Placement)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Criminology and Sociology Including Foundation",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Criminology Including Foundation",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Cyber Security & Digital Forensics",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Cyber Security & Digital Forensics (including Foundation Year)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Cyber Security & Digital Forensics with Professional Placement",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Dance",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Dance and Drama",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Digital Media Technology",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Digital Media Technology (including Foundation Year)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Digital Media Technology with Professional Placement",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Drama",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Drama including Foundation Year",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Early Years (with Early Years Educator) Foundation Degree",
                    "university": "Kingston University",
                    "location": "Multiple Locations",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Early Years Foundation Degree",
                    "university": "Kingston University",
                    "location": "Multiple Locations",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Early Years: Teaching and Learning (Top-Up)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Early Years: Teaching and Learning - Top-Up (Graduate Practitioner)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Economics",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Economics (4 years full time with Professional Placement)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Economics including Foundation Year",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Electrical and Electronic Engineering with Professional Placement",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Electrical and Electronic Engineering with Professional Placement",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Electronic Products Engineering",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Electronic Products Engineering",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Electronic Products Engineering with Professional Placement",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Electronic Products Engineering with Professional Placement",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "English",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "English including Foundation Year (4yrs)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Environmental Science (Including Foundation Year)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Environmental Science with Hazards and Disasters",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Environmental Science with Hazards and Disasters (including Foundation Year)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Environmental Science with Hazards and Disasters (with Professional Placement)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Environmental Science with Professional Placement",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Fashion",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Fashion Promotion and Communication with Placement Year",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Fashion Promotion and Communications",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Fashion with Professional Placement",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Filmmaking",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Financial Economics",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Financial Economics (4 years full time with Professional Placement)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Financial Economics including Foundation Year",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Fine Art and Art History",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Forensic Psychology (4 years full time with Professional Placement)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Forensic Psychology including Foundation Year",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Forensic Science (Foundation)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Forensic Science with Professional Placement",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Foundation Degree Nursing Associate",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Game Development",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Game Development (with Foundation Year)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Game Development with Professional Placement",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Geography",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Geography (including Foundation Year)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Geography with Professional Placement",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Hand Embroidery",
                    "university": "Kingston University",
                    "location": "Royal School of Needlework",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Historic Building Conservation Foundation Degree",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Illustration Animation",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Interaction Design",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Interaction Design with Professional Placement",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Interior Design",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "International Business",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "International Business (including Foundation Year)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "International Business with Professional Placement Year",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Journalism",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Journalism and Media",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Journalism and Media (4 years full time with Professional Placement)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Journalism and Media Including Foundation",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Journalism including Foundation Year",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Learning Disabilities Nursing",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "LLB International Law",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "LLB International Law & Additional Year",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "LLB Law",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "LLB Law & Additional Year",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "LLB Law and Criminal Justice",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "LLB Law and Criminal Justice (4 years full time with Professional Placement)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Marketing",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Marketing (including Foundation Year)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Marketing and Advertising",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Marketing and Advertising (including Foundation Year)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Marketing and Advertising with Professional Placement Year",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Marketing with Professional Placement Year",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Maternity Support Worker",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Mechanical Engineering (Automotive Engineering)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Mechanical Engineering (Automotive Engineering)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Mechanical Engineering (Automotive Engineering) Foundation",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Mechanical Engineering (Automotive Engineering) with Professional Placement",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Mechanical Engineering (Automotive Engineering) with Professional Placement",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Mechanical Engineering (Foundation)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Mechanical Engineering with Professional Placement",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Mechanical Engineering with Professional Placement",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Media & Communication",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Media & Communication including Foundation Year",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Media Skills (Top-Up)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Mental Health and Learning Disabilities Nursing",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Mental Health Nursing",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Mental Health Nursing MSc",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery for Registered Nurses (BMid)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BMid (Hon)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery for Registered Nurses (MMid)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "MMid",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery with Registered Midwife",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BMid (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "MMid Midwifery with Registered Midwife for Graduates",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "MMid",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Technology",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Pharmaceutical and Chemical Sciences Foundation Degree (Pre-Pharmacy)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Pharmaceutical Science",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Pharmaceutical Science (4-year FT)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "MPharmSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Pharmaceutical Science with Professional Placement",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Pharmaceutical Science with Regulatory Affairs",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Pharmaceutical Science with Regulatory Affairs (with Professional Placement)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Pharmaceutical Sciences (Foundation)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Pharmacology (Foundation)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Pharmacy MPharm",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "MPharm (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Pharmacy MPharm (with Preparatory Year)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "MPharm (H)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-88"
                },
                {
                    "course_name": "Photography",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Primary Education (QTS)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Product and Furniture Design",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Psychology",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Psychology (4 years full time with Professional Placement)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Psychology including Foundation Year",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Psychology with Counselling",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Psychology with Counselling (including Foundation Year)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Psychology with Counselling with Professional Placement Year",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Psychology with Criminology",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Psychology with Criminology (4 years full time with Professional Placement)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Psychology with Criminology Including Foundation",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Quantity Surveying",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Quantity Surveying (with Placement)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Real Estate Management",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Real Estate Management (including Foundation Year)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Real Estate Management with Professional Placement Year",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Robotic Engineering & Artificial Intelligence",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Robotic Engineering & Artificial Intelligence",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Robotic Engineering & Artificial Intelligence with Professional Placement",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Robotic Engineering and Artificial Intelligence with Professional Placement",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Social Work",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Social Work Masters (Postgraduate)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "MSW",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Sociology (4 years full time with Professional Placement)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Sociology including Foundation Year",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Special Educational Needs and Inclusive Practice Foundation degree",
                    "university": "Kingston University",
                    "location": "Multiple Locations",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Special Educational Needs and Inclusive Practice Top-Up",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Sport and Exercise Science (Coaching and Physical Education)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Sport and Exercise Science (Coaching and Physical Education) including Foundation Year",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sport and Exercise Science (Coaching and Physical Education) with Professional Placement",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Sport and Exercise Science (Nutrition)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Sport and Exercise Science (Nutrition) with Foundation Year",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sport and Exercise Science (Nutrition) with Professional Placement",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Sport and Exercise Science (with Foundation)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sport and Exercise Science (with Professional Placement)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Working with Children and Young People",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Adult Nursing",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Product Design Engineering and Manufacturing",
                    "university": "Kingston University",
                    "location": "Roehampton Vale",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "Kingston University",
                    "location": "Roehampton Vale",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aesthetics and Art Theory",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Analytical Chemistry",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animation",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture (ARB/RIBA part 2)",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MArch",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art Business",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Banking and Finance",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science: Haematology",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science: Haematology (with Professional Placement)",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science: Haematology with Management Studies",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science: Medical Microbiology",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science: Medical Microbiology (with Professional Placement)",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science: Medical Microbiology with Management Studies",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Building Surveying",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration: Cyber for Business Leaders",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer Biology",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Applications of Psychology",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Leadership",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Composing for Film and Television",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MMus",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Animation",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Practice",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "Master's Diploma",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MFA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing and Publishing",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology with Forensic Psychology",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Curating Contemporary Design",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Kingston University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science Conversion",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing and Analytics",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronics and Robotics",
                    "university": "Kingston University",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Projects and Systems Management",
                    "university": "Kingston University",
                    "location": "Roehampton Vale",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Management",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Management (Energy)",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Making",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Technology",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MFA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Science (Analysis/Toxicology)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Development (Design)",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Development (Programming)",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Human Resource Management",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Historic Building Conservation",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Illustration",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Systems",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Innovation Management and Entrepreneurship",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Design",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MSc",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Investment and Financial Risk Management",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism",
                    "university": "Kingston University",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Landscape and Urbanism",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Landscape Architecture",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Landscape Architecture",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MLA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law LLM",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Logistics and Supply Chain Management",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Magazine Journalism",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management in Construction",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management in Construction (Civil Engineering)",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing and Brand Management",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing Communications and Advertising",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Kingston University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronic Systems",
                    "university": "Kingston University",
                    "location": "Roehampton Vale",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communication",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health Nursing",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery (Registered Midwife for Registered Nurses)",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MMid",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery (Registered Midwife)",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MMid",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mobile Networks and Media Streaming",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern European Philosophy",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Museum and Gallery Studies",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Education",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Performance",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MMus",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Network and Information Security",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Network and Information Security with Management Studies",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Networking and Data Communications",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Networking and Data Communications with Management Studies",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational and Business Psychology",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Analysis",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Analysis with Management Studies",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Science",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Science with Management Studies",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MPhilStud",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Contemporary Critical Theory",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Photography",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pre-Masters Programme",
                    "university": "Kingston University",
                    "location": "No Locations",
                    "qualification": "Master's Programme",
                    "duration": "6 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Teaching leading to Qualified Teacher Status (QTS) PGCE",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product and Furniture Design",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management (Distance Learning)",
                    "university": "Kingston University",
                    "location": "No Locations",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management for Creative Practitioners",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Relations and Corporate Communications",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Publishing",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantity Surveying",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Renewable Energy Engineering",
                    "university": "Kingston University",
                    "location": "Roehampton Vale",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Teaching leading to Qualified Teacher Status (QTS) PGCE: Citizenship with Social Science",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Teaching leading to Qualified Teacher Status (QTS) PGCE: English",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Teaching leading to Qualified Teacher Status (QTS) PGCE: Mathematics",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Teaching leading to Qualified Teacher Status (QTS) PGCE: Physical Education",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Teaching leading to Qualified Teacher Status (QTS) PGCE: Science (Biology)",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Teaching leading to Qualified Teacher Status (QTS) PGCE: Science (Chemistry)",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Teaching leading to Qualified Teacher Status (QTS) PGCE: Science (Physics)",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MSW",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering with Management Studies",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Design and Construction Management",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Design and Construction Management with Sustainability",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Design",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Fashion: Business and Practices",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "User Experience Design",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Clinical Practice",
                    "university": "Kingston University",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Product Design Engineering and Manufacturing",
                    "university": "Kingston University",
                    "location": "Roehampton Vale",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Social Work",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "Kingston University",
                    "location": "Roehampton Vale",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aesthetics and Art Theory",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Analytical Chemistry",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animation",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art Business",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Assessment Only route leading to Qualified Teacher Status (Primary)",
                    "university": "Kingston University",
                    "location": "No Locations",
                    "qualification": "Additional Teaching Qualification",
                    "duration": "3 months",
                    "study_mode": "Work-based learning",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Assessment Only route leading to Qualified Teacher Status (Secondary)",
                    "university": "Kingston University",
                    "location": "No Locations",
                    "qualification": "Additional Teaching Qualification",
                    "duration": "3 months",
                    "study_mode": "Work-based learning",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Banking and Finance",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science: Haematology",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science: Haematology with Management Studies",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science: Medical Microbiology",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science: Medical Microbiology with Management Studies",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Building Surveying",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration: Cyber for Business Leaders",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer Biology",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Applications of Psychology",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Leadership",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Composing for Film and Television",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MMus",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Animation",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MFA (PG)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing and Publishing",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology with Forensic Psychology",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Curating Contemporary Design",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Kingston University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science Conversion",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Doctor of Education",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "EdD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Years Teacher Birth to 5 leading to Early Years Teacher Status (EYTS) PGCE",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronics and Robotics",
                    "university": "Kingston University",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Projects and Systems Management",
                    "university": "Kingston University",
                    "location": "Roehampton Vale",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Management",
                    "university": "Kingston University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Management (Energy)",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Making",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MFA (PG)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Science (Analysis/Toxicology)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Development (Design)",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Development (Programming)",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Human Resource Management",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Healthcare Practice",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Historic Building Conservation",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management (top-up)",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Illustration",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Systems",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Design",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism",
                    "university": "Kingston University",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Landscape and Urbanism",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Landscape Architecture",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Landscape Architecture",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MLA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law LLM",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership and Management in Health (Distance Learning)",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Magazine Journalism",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management in Construction",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management in Construction (Civil Engineering)",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Kingston University",
                    "location": "Roehampton Vale",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronic Systems",
                    "university": "Kingston University",
                    "location": "Roehampton Vale",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communication",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mobile Networks and Media Streaming",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern European Philosophy",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Museum and Gallery Studies",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Education",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Performance",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MMus",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Network and Information Security",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Network and Information Security with Management Studies",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Networking and Data Communications",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Networking and Data Communications with Management Studies",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational and Business Psychology",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Analysis",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Analysis with Management Studies",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MPhilStud",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Contemporary Critical Theory",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Photography",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Postgraduate Certificate in Education PGCE (Top Up)",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Part-time weekend",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Practice Education",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product and Furniture Design",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Practice in Architecture",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "PgDip",
                    "duration": "11 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management for Creative Practitioners",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Publishing",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantity Surveying",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Renewable Energy Engineering",
                    "university": "Kingston University",
                    "location": "Roehampton Vale",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "Kingston University",
                    "location": "Kingston Hill",
                    "qualification": "MSW",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering with Management Studies",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Special Educational Needs and Inclusion",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Design and Construction Management",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Design and Construction Management with Sustainability",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Design",
                    "university": "Kingston University",
                    "location": "Knights Park",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Technology (Maritime Operations)",
                    "university": "Kingston University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "User Experience Design",
                    "university": "Kingston University",
                    "location": "Penrhyn Road Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "eef2b4a49847b9c3f379ddfb11ed4be4",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Graphic Design for Surfaces & Interiors",
                    "university": "KLC School of Design",
                    "location": "West Dean: London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Interior Design",
                    "university": "KLC School of Design",
                    "location": "West Dean: London",
                    "qualification": "DipHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Design",
                    "university": "KLC School of Design",
                    "location": "West Dean: London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "8ccb7cffc4ab98df88f36e87cfe890f8",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "6f234b610a4abe154821b5af512bfb29",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance (Industry)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Management",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Management (Industry)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting, Finance and Mathematics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting, Finance and Mathematics (Industry)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advertising and Marketing",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc (UG)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology with Psychology",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology with Psychology (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedicine",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedicine",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedicine (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedicine (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics (Industry)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics (Industry)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (Entrepreneurship)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (Entrepreneurship) (Industry)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (Entrepreneurship) (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (Industry)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with Placement Year",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with Placement Year",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry (with Industrial Placement)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MChem",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and English Literature",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and Film",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and French Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and German Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and History",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and Linguistics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and Mathematics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and Philosophy",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and Politics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and Spanish Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (with Industrial Experience)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Mathematics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Mathematics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Mathematics (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and French Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Law",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Psychology",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Sociology (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security (with Industrial Experience)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science (with Industrial Experience)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama, Theatre and Performance",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama, Theatre and Performance (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama, Theatre and Performance (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth and Environmental Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth and Environmental Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth and Environmental Science (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth and Environmental Science (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth and Environmental Science (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecology and Conservation",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecology and Conservation (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecology and Conservation (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics (Industry)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics (Industry)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance (Industry)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics, Politics and International Relations",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics, Politics and International Relations (Industry)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering with Placement Year",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering with Placement Year",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering with Placement Year",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering with Placement Year",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Chinese Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Creative Writing",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Creative Writing (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Creative Writing (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and French Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and German Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Linguistics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Linguistics (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Linguistics (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Literature",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Literature (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Literature (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Spanish Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Creative Writing",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Creative Writing (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Creative Writing (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and History",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and History (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BASc (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and History (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Philosophy",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Philosophy (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Philosophy (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Politics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Politics (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Politics (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature with Creative Writing",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature with Creative Writing (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature with Creative Writing (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Creative Writing",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Creative Writing (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Creative Writing (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and English Literature",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and English Literature (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and English Literature (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Philosophy",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Philosophy (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Philosophy (Study abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Sociology",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Sociology (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Sociology (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Theatre",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Theatre (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Theatre (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film, Media and Cultural Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film, Media and Cultural Studies (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film, Media and Cultural Studies (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance (Industry)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art and Creative Writing",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art and Creative Writing (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art and Creative Writing (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art and Design",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art and Design (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art and Design (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art and Film",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art and Film (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art and Film (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art and Theatre",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art and Theatre (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art and Theatre (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French Studies and Computing",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French Studies and English Literature",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French Studies and Film",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French Studies and Geography",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French Studies and German Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French Studies and History",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French Studies and Linguistics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French Studies and Mathematics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French Studies and Philosophy",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French Studies and Politics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French Studies and Spanish Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French Studies and Theatre",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French Studies with Italian",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MArts (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MArts (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography and Economics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography and Economics (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German Studies and Computing",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German Studies and English Literature",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German Studies and Film",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German Studies and Geography",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German Studies and History",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German Studies and Linguistics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German Studies and Mathematics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German Studies and Philosophy",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German Studies and Politics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German Studies and Spanish Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German Studies and Theatre",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German Studies with Italian",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Religions",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Religions (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Religions (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Religions and International Relations",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Religions and International Relations (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Religions and International Relations (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Religions and Philosophy",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Religions and Philosophy (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Religions and Philosophy (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Religions and Politics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Religions and Politics (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Religions and Politics (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and International Relations",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and International Relations (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and International Relations (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Philosophy",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Philosophy (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Philosophy (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Politics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Politics (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Politics (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History, Philosophy and Politics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History, Philosophy and Politics (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History, Philosophy and Politics (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Geography",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Geography (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Geography (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management (Canada - Brock University)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management (France)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management (Germany)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management (Italy)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management (Mexico)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management (Spain)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management (Industry)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language Sciences",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language Sciences (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language Sciences (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (Clinical Learning)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (International Law)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Politics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics and Philosophy",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics and Philosophy (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics and Philosophy (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and French Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and German Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and Human Resources",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and Human Resources (Industry)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and Human Resources (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and Information Technology",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and Information Technology (Industry)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and Spanish Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management, Politics and International Relations (Industry)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing and Design",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing Management",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing with Psychology",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Philosophy",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Philosophy (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Economics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Economics (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Finance",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Finance (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Statistics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Statistics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Statistics (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Statistics (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics, Operational Research, Statistics and Economics (MORSE)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics, Operational Research, Statistics and Economics (MORSE) (Industry)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Placement Year",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Placement Year",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronic Engineering",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronic Engineering",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronic Engineering (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronic Engineering (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronic Engineering with Placement Year",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronic Engineering with Placement Year",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Cultural Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Cultural Studies (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Cultural Studies (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine and Surgery",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MB ChB",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine with a Gateway Year",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MB ChB",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval and Early Modern Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval and Early Modern Studies (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval and Early Modern Studies (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages (4 years)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and Cultures",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MLang",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Sciences",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Sciences",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Sciences  (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Sciences (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Sciences (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nuclear Engineering",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nuclear Engineering",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nuclear Engineering (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nuclear Engineering (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nuclear Engineering with Placement Year",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nuclear Engineering with Placement Year",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Peace Studies and International Relations",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Peace Studies and International Relations (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Peace Studies and International Relations (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy  (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Politics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Politics (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Politics (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy, Politics and Economics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy, Politics and Economics (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy, Politics and Economics (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Geography",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Geography",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Geography (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Geography (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Geography (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics  (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Sociology",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Sociology (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Sociology (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, International Relations and Management",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Chinese Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and French Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and German Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Linguistics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Management",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Spanish Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology  (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering (with Industrial Experience)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish Studies and Computing",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish Studies and English Literature",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish Studies and Film",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish Studies and Geography",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish Studies and History",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish Studies and Linguistics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish Studies and Mathematics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish Studies and Philosophy",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish Studies and Politics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish Studies and Theatre",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish Studies with Italian",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports and Exercise Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports and Exercise Science (Study Abroad 4yr)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and Chinese Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and Creative Writing",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and Creative Writing (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and Creative Writing (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and English Literature",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and English Literature (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and English Literature (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics with Mathematics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics with Mathematics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics with Mathematics (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology (Placement Year)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology (Study Abroad)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Marketing Management",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Marketing Management",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Mechanical Engineering",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Linguistics and TESOL",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Social Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture (Master of)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MArch Studies",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts Management",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical and Life Sciences",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedicine",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Psychology",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "DClinPsy",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Research",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication Systems",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communications Systems",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conflict Resolution and Peace Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation and Biodiversity",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary Arts",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing (modular)",
                    "university": "Lancaster University",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing with English Literary Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Justice and Social Research Methods",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Criminal Justice",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Social Research Methods",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Management",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Developmental Disorders",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Developmental Psychology",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Business, Innovation and Management",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Humanities",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diplomacy and Foreign Policy",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diplomacy and International Law",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diplomacy and Religion",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Discourse Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecology",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD with Integrated Study",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Research (Independent Study)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Project Management",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literary Research",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literary Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literary Studies with Creative Writing",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Creative Writing",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment and Development",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment and Law",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment and Society",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment, Culture and Society",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Management",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Management",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Challenges for the 21st Century (Distance Learning)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Security (Distance Learning)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Linguistics and Speech Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender Studies and English",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Media and Society",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Data Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Data Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Research",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resources and Consulting",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Innovation and Entrepreneurship",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intercultural Business Communication",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International and Military History",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Strategy",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial and Corporate Law",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Rights Law",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law and International Relations",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Security",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Investment Management and Financial Analysis",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language and Linguistics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Languages and Cultures",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Languages and Cultures",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "LLM (Res)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics (by thesis and coursework)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Logistics and Supply Chain Management",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Entrepreneurship and Strategy)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Organisation, Work and Technology)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD with Integrated Study",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Project Management",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Cultural Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Ethics and Law",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Sciences",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Statistics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MD",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Money, Banking and Finance",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nanoscience",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Sciences",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Sciences",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Organisation, Work and Technology",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Organisational Health and Well-Being",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Religion",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Plant Sciences (by research)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Ecology",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Philosophy",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, Philosophy and Management",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological Research Methods",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Behavioural Analytics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology of Advertising",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Policy",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Policy (Distance Learning)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religion and Conflict",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religious Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religious Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religious Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social and Behavioural Sciences in Medicine",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Statistics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports and Exercise Sciences",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD with Integrated Study",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics and Artificial Intelligence",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics and Epidemiology",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics and Operational Research",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics and Operational Research (STOR-i)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Water Management",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre for Social, Political and Environmental Change",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Volcanology and the Environment",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "War and Diplomacy",
                    "university": "Lancaster University",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Mechanical Engineering",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anaesthesia and Peri-Operative Sciences",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Linguistics and TESOL",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Social Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical and Life Sciences",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedicine",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration (Executive)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MBA (Executive)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Psychology",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "DClinPsy",
                    "duration": "52 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Research",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Research",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Research",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication Systems",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communications Systems",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conflict Resolution and Peace Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation and Biodiversity",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Corpus Linguistics (Distance)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Corpus Linguistics (Distance)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing (modular)",
                    "university": "Lancaster University",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing with English Literary Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Justice and Social Research Methods",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Criminal Justice",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Social Research Methods",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security Executive MBA",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Developmental Disorders",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Developmental Psychology",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Humanities",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diplomacy and Foreign Policy",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diplomacy and International Law",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diplomacy and Religion",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Discourse Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "E-Research and Technology Enhanced Learning",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecology",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Research (Independent Study)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literary Research",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literary Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literary Studies with Creative Writing",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Creative Writing",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment and Development",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment and Law",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment and Society",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment, Culture and Society",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Management",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Flood and Coastal Risk Management",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Security (Distance Learning)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender Studies and English",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Data Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Economics and Policy",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Economics and Policy",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Research",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Higher Education (Research, Evaluation and Enhancement)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International and Military History",
                    "university": "Lancaster University",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial and Corporate Law",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Rights Law",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law and International Relations",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Masters Program for Managers",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "International Master's",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Security",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language and Linguistics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language Testing (Distance)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Languages and Cultures",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "LLM (Res)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership and Management (Health Care)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership Practice",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership Practice (Apprenticeship Pathway)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics (by thesis and coursework)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Project Management",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Cultural Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Education",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Leadership",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Sciences",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Sciences",
                    "university": "Lancaster University",
                    "location": "No Locations",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Sciences",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Organisation Development",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Palliative Care",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Religion",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Plant Sciences (by research)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Philosophy",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological Research Methods",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology of Advertising",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Regional and Local History (Distance Learning)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religion and Conflict",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religious Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religious Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religious Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Statistics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics and Epidemiology",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Water Management",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "TESOL (Teaching English to Speakers of Other Languages) (Distance)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "TESOL (Teaching English to Speakers of Other Languages) with Corpus Linguistics (Distance)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "TESOL (Teaching English to Speakers of Other Languages) with Language Testing (Distance)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre for Social, Political and Environmental Change",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre Studies",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theory and Practice of Management",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theory and Practice of Management (IDPM)",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Volcanology and the Environment",
                    "university": "Lancaster University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "f1778459b00b88d5ddd52c97d66895de",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "c4bd5470f9e2ab967aadfb13e4a95ebf",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Acting for Screen",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Acting for Screen (with placement year)",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Animation",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Animation (with placement year)",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Comic and Concept Art",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Comic and Concept Art (with placement year)",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Creative Writing (with placement year)",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fashion Design",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fashion Design (with placement year)",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fashion Marketing",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fashion Marketing (with placement year)",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fashion Photography",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fashion Photography (with placement year)",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Filmmaking",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Filmmaking (with placement year)",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fine Art (with placement year)",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Games Art",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Games Art (with placement year)",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Games Design",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Games Design (with placement year)",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Graphic Design (with placement year)",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Illustration",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Illustration (with placement year)",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Marketing Communications",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Marketing Communications (with placement year)",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Music Production",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Music Production (with placement year)",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Photography",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Photography (with placement year)",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Popular Music Performance",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BMus (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Popular Music Performance (with placement year)",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BMus (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Textile Design",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Textile Design (with placement year)",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Visual Communication",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Visual Communication (with placement year)",
                    "university": "Leeds Arts University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Animation",
                    "university": "Leeds Arts University",
                    "location": "Blenheim Walk",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Practice",
                    "university": "Leeds Arts University",
                    "location": "Blenheim Walk",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Fashion",
                    "university": "Leeds Arts University",
                    "location": "Blenheim Walk",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Leeds Arts University",
                    "location": "Blenheim Walk",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Leeds Arts University",
                    "location": "Blenheim Walk",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Illustration with Graphic Novel",
                    "university": "Leeds Arts University",
                    "location": "Blenheim Walk",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Photography",
                    "university": "Leeds Arts University",
                    "location": "Blenheim Walk",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Animation",
                    "university": "Leeds Arts University",
                    "location": "Blenheim Walk",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Practice",
                    "university": "Leeds Arts University",
                    "location": "Blenheim Walk",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Fashion",
                    "university": "Leeds Arts University",
                    "location": "Blenheim Walk",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Leeds Arts University",
                    "location": "Blenheim Walk",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Leeds Arts University",
                    "location": "Blenheim Walk",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Illustration with Graphic Novel",
                    "university": "Leeds Arts University",
                    "location": "Blenheim Walk",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Photography",
                    "university": "Leeds Arts University",
                    "location": "Blenheim Walk",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "dec2b59b0f9e3f9e6910c7f543b43b17",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting & Finance",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Acting",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Adult Nursing",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Applied Sport Studies in Tennis",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Applied Sports Studies",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Applied Sports Studies in Athletics",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Applied Sports Studies in Cricket",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Applied Sports Studies in Football",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Applied Sports Studies in Netball",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Applied Sports Studies in Rugby League",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Architectural Technology",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Architecture",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Building Services Engineering",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Building Services Engineering",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Building Surveying",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Business & Management",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Accounting with Finance",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Economics",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Business Management with Enterprise",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Finance",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Law",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Marketing",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Sustainable Development",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Studies",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Childhood Development and Playwork",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Childhood Studies",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Construction Management",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Counselling and Mental Health",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Criminology",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology with Psychology",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Cyber Security & Digital Forensics",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Cyber Security & Digital Forensics",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Data Science & Artificial Intelligence",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BA/BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Dietetics",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Economics & Finance",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Education Studies",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Electronic and Electrical Engineering",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Engineering Management",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Creative Writing",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English and History",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "English Literature",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Events Management",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fashion Design",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fashion Marketing",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Filmmaking",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Games Art",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Games Design",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Geography",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Geography & Environmental Science",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Health & Exercise Science",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "History",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "History & Media",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "History and Politics",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Hospitality Business Management",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Hospitality Business Management with Marketing",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Housing Studies",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Human Geography",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Human Geography & Planning",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Human Resource Management & Business",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Illustration",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Interior Architecture and Design",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "International Business",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Relations",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Relations with Global Development",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Relations with Human Rights",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Relations with Politics",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Tourism Management",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "International Tourism Management with Marketing",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Journalism",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Landscape Architecture & Design",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Law",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Law (Senior Status)",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Marketing",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Marketing & Public Relations",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Marketing and Advertising",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Media and English",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Media, Communication, Cultures",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "MEng Software Engineering",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Mental Health Nursing",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Music Industries Management",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Music Performance and Production",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Music Production",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Music Technology",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nutrition",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Performing Arts",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Physical Education",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Physical Education with Outdoor Education",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Politics",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Politics with Global Development",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Politics with Human Rights",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Politics with International Relations",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Primary Education (3-7) with QTS",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Primary Education (5-11) with QTS",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Primary Education (Accelerated Degree)",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Product Design",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Project Management",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Psychology in Education",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Quantity Surveying",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Real Estate and Property Management",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Robotics and Automation",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Science of Sport Performance",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Social Care, Justice and Recovery",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Social Psychology",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Social Work",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Social Work (Postgraduate Entry)",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sociology and Criminology",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sociology and Psychological Studies",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Speech and Language Therapy",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Sport and Exercise Nutrition",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Sport Business Management",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Sport Coaching",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Sport Marketing",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Sports & Exercise Therapy",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Sports Journalism",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Working with Children, Young People and Families",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Accounting & Finance",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Acting",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Architectural Technology",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Architecture",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Building Services Engineering",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MEng",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Building Services Engineering",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Building Surveying",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Business & Management",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Accounting with Finance",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Enterprise",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Finance",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Law",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Marketing",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Studies",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Childhood Development and Playwork",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Childhood Studies",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Construction Management",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Counselling and Mental Health",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Criminology",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology with Psychology",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Data Science & Artificial Intelligence",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BA/BSc",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Education Studies",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Educational Psychology",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Electronic and Electrical Engineering",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Engineering Management",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Creative Writing",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English and History",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "English Literature",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Fashion Marketing",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Health & Exercise Science",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BSc",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "History",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "History & Media",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "History and Politics",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Hospitality Business Management",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Hospitality Business Management with Marketing",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Housing Studies",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Human Resource Management & Business",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Illustration",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Relations",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Relations with Global Development",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Relations with Human Rights",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Relations with Politics",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Landscape Architecture & Design",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Media and English",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Media, Communication, Cultures",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "MEng Software Engineering",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MEng",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Music Industries Management",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Music Production",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Music Technology",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Performing Arts",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Politics",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Politics with Global Development",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Politics with Human Rights",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Politics with International Relations",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Product Design",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Project Management",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Psychology in Education",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Quantity Surveying",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Real Estate and Property Management",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Robotics and Automation",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Science of Sport Performance",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Social Care, Justice and Recovery",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Social Psychology",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Social Work (Postgraduate Entry)",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA (PG)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sociology and Criminology",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sociology and Psychological Studies",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Sport and Exercise Nutrition",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Sport Business Management",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Sport Coaching",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Sport Marketing",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-120"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "3D Design",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Cyber Security & Digital forensics",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MA/MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Engineering Management",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MArch Studies",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture Futures",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Audio & Acoustics",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MBA (Executive)",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Child and Adolescent Mental Health",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Choreography",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Community Specialist Practitioner (District Nursing)",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Management",
                    "university": "Leeds Beckett University",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Corporate Governance",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Learning: Innovation & Inclusion for Social Change",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Technology",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dietetics",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Documentary Filmmaking",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "Leeds Beckett University",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Entrepreneurship and Business Development",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Health",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Expanded Spatial Practices",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Curation & Exhibition",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graduate Master of Business Administration",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MBA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Community Care",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Safety",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Housing, Regeneration and Urban Management",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management (Top-Up)",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information and Technology",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrative Counselling",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interdisciplinary Psychology",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Banking and Investment",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Communication",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Events Management",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Finance & Investment",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Hospitality Management",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Hospitality Management",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Hospitality Management",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Rights Practice",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Marketing",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Trade and Finance",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Landscape Architecture",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Landscape Architecture",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Learning & People Development",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal Practice Course",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and Finance",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and Human Resources",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and International Business",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and Leadership",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and Marketing",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing & Digital Strategy",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Culture",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Biochemistry",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Microbiology",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music for the Moving Image",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Production",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nutrition in Practice",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Therapy (pre-registration)",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Peace and Development",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Education & Youth Sport",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Education & Youth Sport",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy (pre-registration)",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Popular Music and Culture",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Education with Qualified Teacher Status PGCE",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PGCE",
                    "duration": "45 weeks",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (conversion award)",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology of Sport and Exercise",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health (Health Promotion)",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "30 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Policy",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Relations and Strategic Communication",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Responsible Tourism Management",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Responsible Tourism Management",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Screenwriting: Feature Film & Continuing Series",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Education",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PGCE",
                    "duration": "45 weeks",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social History",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sound and Music for Interactive Games",
                    "university": "Leeds Beckett University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sound Design",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Public Health Nursing (Health Visiting)",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Public Health Nursing (School Nursing)",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Speech and Language Therapy (Pre-Registration)",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport & Exercise Biomechanics",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport & Exercise Medicine",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport & Exercise Nutrition",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport & Exercise Physiology",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport & Exercise Science",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Biomechanics",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Medicine",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Nutrition",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Physiology",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Psychology",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Business Management",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Business Management",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Coaching",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Coaching",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgDip",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Development & Management",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Development & Management",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Development and Management",
                    "university": "Leeds Beckett University",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Performance Analysis",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Performance Analysis",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports & Exercise Therapy",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Events Management",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Events Management",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgDip",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Injury Management",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "PgCert",
                    "duration": "18 weeks",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Project Management",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Sport Marketing",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Sport Marketing",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strength and Conditioning",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Supply chain management and logistics",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Therapeutic Play Skills",
                    "university": "Leeds Beckett University",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Town and Regional Planning",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban design",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Youth Work & Community Development",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "3D Design",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Acoustics (Top-Up)",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "30 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Cyber Security & Digital forensics",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MA/MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Engineering Management",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Professional Practice",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "PgDip",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MArch Studies",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture Futures",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Audio & Acoustics",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Building Services Engineering",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MBA (Executive)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Certificate in Professional Digital Marketing",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "CiM",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Child and Adolescent Mental Health",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Choreography",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Coaching and Mentoring for Education Practitioners",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Law and Dispute Resolution",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Management",
                    "university": "Leeds Beckett University",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Corporate Governance",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Corporate Governance Top-Up",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling and Psychotherapy",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Learning: Innovation & Inclusion for Social Change",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Technology",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dietetics (Top-Up)",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diploma in Professional Digital Marketing",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "CiM",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "Leeds Beckett University",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Entrepreneurship and Business Development",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Health",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Expanded Spatial Practices",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "30 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Curation & Exhibition",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Community Care",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Safety",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Housing and Neighbourhood Management",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "Advanced Professional Diploma",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Housing, Regeneration and Urban Management",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management Top-Up",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information and Technology",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrative Counselling",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrative Counselling (Top up)",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interdisciplinary Psychology",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Banking and Investment",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Events Management",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Finance & Investment",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Hospitality Management",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "28 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Hospitality Management",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Hospitality Management",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Rights Practice",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Marketing",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Trade and Finance",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interpersonal and Counselling Skills",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Landscape Architecture",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Landscape Architecture",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Learning & People Development",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Learning & People Development",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Learning & People Development Top-Up",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal Practice Course",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and Finance",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and Human Resources",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and International Business",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and Leadership",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and Marketing",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing & Digital Strategy",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Biochemistry",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Microbiology",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health Practice",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "PgCert",
                    "duration": "9 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music for the Moving Image",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Production",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nutrition in Practice",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Therapy (pre-registration)",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Peace and Development",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Education & Youth Sport",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Education & Youth Sport",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Education & Youth Sport",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Play Therapy",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Popular Music and Culture",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Education with Qualified Teacher Status PGCE",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PGCE",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (conversion award)",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology of Sport and Exercise",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychotherapy",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health (Health Promotion)",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "30 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Policy",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Relations and Strategic Communication",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Responsible Tourism Management",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Responsible Tourism Management",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgDip",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Screenwriting: Feature Film & Continuing Series",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary Education",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PGCE",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social History",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sound and Music for Interactive Games",
                    "university": "Leeds Beckett University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sound Design",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport & Exercise Biomechanics",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport & Exercise Biomechanics",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport & Exercise Medicine",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport & Exercise Nutrition",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport & Exercise Nutrition",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport & Exercise Physiology",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport & Exercise Physiology",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport & Exercise Psychology",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport & Exercise Science",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Biomechanics",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Medicine",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Nutrition",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Physiology",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Psychology",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Business Management",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Business Management",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Business Management",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Coaching",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgDip",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Coaching",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Coaching",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Development & Management",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Development & Management",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Development and Management",
                    "university": "Leeds Beckett University",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Performance Analysis",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Performance Analysis",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Performance Analysis",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports & Exercise Therapy",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Events Management",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Events Management",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgDip",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Injury Management",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "PgCert",
                    "duration": "18 weeks",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Project Management",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Sport Marketing",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Sport Marketing",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Sport Marketing",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strength and Conditioning",
                    "university": "Leeds Beckett University",
                    "location": "Headingley Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Therapeutic Play Skills",
                    "university": "Leeds Beckett University",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Town and Regional Planning",
                    "university": "Leeds Beckett University",
                    "location": "City CampusC",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban design",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Youth Work & Community Development",
                    "university": "Leeds Beckett University",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "0bc06c07e55313499bed10bf6377ef9f",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Acting",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Quarry Hill Campus",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Acting (Top-Up)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Quarry Hill Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Management and Behaviour",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Temple Newsam Home Farm",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Animation",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Quarry Hill Campus",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Biomedical & Pharmaceutical Sciences (Top Up)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical and Pharmaceutical Sciences",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Biomedical and Pharmaceutical Sciences with Foundation Year",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "FD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Business, Enterprise and Management (HTQ)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business, Enterprise and Management (Top Up)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children and Young People's Care and Education (Top Up)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science  (HTQ)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computer Science (Top-Up)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Concept Art",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Quarry Hill Campus",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Content Creation and Promotion",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Quarry Hill Campus",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Creative Hair and Media Makeup",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Quarry Hill Campus",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Creative Hair and Media Makeup (Fashion and Editorial)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Quarry Hill Campus",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Creative Hair and Media Makeup (Fashion and Editorial) (Top-Up)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Quarry Hill Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Hair and Media Makeup (Film & TV and Prosthetics)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Quarry Hill Campus",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Creative Hair and Media Makeup (Film, TV and Prosthetics) (Top-Up)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Quarry Hill Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Hair and Media Makeup (Top-Up)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Quarry Hill Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security (HTQ)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Cyber Security (Top-Up)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Film & Screen Media",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Quarry Hill Campus",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Film and Screen Media (Top-Up)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Quarry Hill Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Game Art (Top-Up)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Game Development and Production (Top-Up)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Game Programming (Top-Up)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Design and Illustration",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Quarry Hill Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Design and Illustration",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Quarry Hill Campus",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-80"
                },
                {
                    "course_name": "Health and Social Care (Top Up)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Wellbeing",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Multiple Locations",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Healthcare Assistant Practitioner (HTQ)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Multiple Locations",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Law",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Leadership and Management",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Leadership and Management (Top Up)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Manufacturing Engineering",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Physical Education and Sports Coaching (HTQ)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Physical Education and Sports Coaching (Top-Up)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Production Arts (Design Realisation for Events, Stage and Screen)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Quarry Hill Campus",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Production Arts (Technical Theatre & Stage Management)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Quarry Hill Campus",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sport Performance and Exercise",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sport Performance and Exercise (Top-Up)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Supporting Teaching and Learning",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Keighley College",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Travel and Tourism Management",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Travel and Tourism Management (Top-Up)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Business, Enterprise and Management (HTQ)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Part-time evening",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business, Enterprise and Management (Top Up)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "BA (Hons)",
                    "duration": "1.5 years",
                    "study_mode": "Part-time evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children and Young People's Care and Education (Top Up)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "BA (Hons)",
                    "duration": "1.5 years",
                    "study_mode": "Part-time day/evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science  (HTQ)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "FD",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computer Science (Top-Up)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security (HTQ)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "FD",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Cyber Security (Top-Up)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Health and Social Care (Top Up)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "BA (Hons)",
                    "duration": "1.5 years",
                    "study_mode": "Part-time day/evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Play Specialism (HTQ)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Leadership and Management",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Part-time evening",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Leadership and Management (Top Up)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "BA (Hons)",
                    "duration": "1.5 years",
                    "study_mode": "Part-time evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Manufacturing Engineering",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Travel and Tourism Management",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Travel and Tourism Management (Top-Up)",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Mabgate",
                    "qualification": "BA (Hons)",
                    "duration": "1.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Creative Practice",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Creative Practice",
                    "university": "University Centre Leeds, Leeds City College",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "31922d3f478f508c8f287dd9ccc914b6",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "1298c62ad13f5848e2f1c42ef8724de2",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Accounting and Finance with Foundation Year in Business",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Broadcast and Digital Journalism",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Broadcast and Digital Journalism with Foundation Year",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Building Surveying",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-136"
                },
                {
                    "course_name": "Business and Marketing",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Business and Marketing with Foundation Year in Business",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business Management",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Business Management and Law",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Business Management and Law with Foundation Year",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business Management with Foundation Year in Business",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Computer Science with Artificial Intelligence",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Computer Science with Artificial Intelligence with Foundation Year",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Computer Science with Cyber Security",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Computer Science with Cybersecurity with Foundation Year",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Computer Science with Foundation Year in Computing",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Computer Science with Games Development",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Computer Science with Games Development with Foundation Year",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Construction Management",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-136"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-136"
                },
                {
                    "course_name": "Counselling Psychology",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Counselling Psychology with Foundation Year in Psychology",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Criminology",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-136"
                },
                {
                    "course_name": "Criminology and Law",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-136"
                },
                {
                    "course_name": "Criminology and Law with Foundation Year in Legal Issues",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-136"
                },
                {
                    "course_name": "Criminology and Sociology with Foundation Year  in Criminology, Policing and Sociology",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Criminology with Foundation Year in Criminology, Policing and Sociology",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Criminology with Police Studies",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-136"
                },
                {
                    "course_name": "Criminology with Police Studies With Foundation Year in Criminology, Policing and Sociology",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Digital Marketing with Foundation Year in Business",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Digital Media Production",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-128"
                },
                {
                    "course_name": "Early Childhood Studies with Foundation Year in Education and Childhood",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Education, Mental Health and SEND",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-128"
                },
                {
                    "course_name": "Education, Mental Health and SEND with Foundation Year",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "English and Creative Writing",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-136"
                },
                {
                    "course_name": "English and Creative Writing with Foundation Year in Arts and Communication",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Film",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Film with Foundation Year in Arts and Communication",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Forensic Psychology with Foundation Year in Psychology",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Games Design",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Games Design With Foundation Year",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-136"
                },
                {
                    "course_name": "Health and Social Care with Foundation Year in Health",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "International Business",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Law",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Law with Foundation Year in Legal Issues",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Nursing (Child)",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Nursing (Learning Disabilities)",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Philosophy, Ethics and Religion",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-136"
                },
                {
                    "course_name": "Philosophy, Ethics and Religion with Foundation Year in Arts and Communication",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Photography",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Physical Education and School Sport",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-136"
                },
                {
                    "course_name": "Physical Education and School Sport with Foundation Year in Sport and Exercise",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Physical Education and Sports Coaching",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-136"
                },
                {
                    "course_name": "Physical Education and Sports Coaching with Foundation Year in Sport and Exercise",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Policing and Investigation",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-136"
                },
                {
                    "course_name": "Policing and Investigation with Foundation Year",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Primary Education (Early Years 3-7) with QTS",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Primary Education (Later Years 5-11) with QTS",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Primary Education Early Years (3-7) QTS with Foundation Year in Education and Childhood",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Primary Education Later Years (5-11) QTS with Foundation Year in Education and Childhood",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Primary Education: Early Years (3-7) with QTS Bradford College",
                    "university": "Leeds Trinity University",
                    "location": "Bradford College",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-136"
                },
                {
                    "course_name": "Primary Education: Later Years (5-11) with QTS Bradford College",
                    "university": "Leeds Trinity University",
                    "location": "Bradford College",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-136"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-136"
                },
                {
                    "course_name": "Professional Policing with Foundation Year",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Psychology",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Psychology and Child Development",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Psychology and Child Development with Foundation Year in Psychology",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Psychology with Foundation Year in Psychology",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Quantity Surveying",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-136"
                },
                {
                    "course_name": "Sport and Exercise Sciences",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-136"
                },
                {
                    "course_name": "Sport and Exercise Sciences with Foundation Year in Sport and Exercise",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sport Business Management",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-136"
                },
                {
                    "course_name": "Sport Psychology",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Sport Psychology with Foundation Year in Psychology",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sports Journalism",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Sports Journalism with Foundation Year in Journalism Studies",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sports Therapy and Rehabilitation",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-136"
                },
                {
                    "course_name": "Sports Therapy and Rehabilitation with Foundation Year in Sport and Exercise",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Working with Children, Young People and Families",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-128"
                },
                {
                    "course_name": "Working with Children, Young People and Families with Foundation Year in Education and Childhood",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Education, Mental Health and SEND",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96-128"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Sport and Exercise Sciences",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-136"
                },
                {
                    "course_name": "Sports Therapy and Rehabilitation",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-136"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Construction Project Management",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Artificial Intelligence",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "Leeds Trinity University",
                    "location": "Leeds Trinity University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Conversion)",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Data Science and Artificial Intelligence",
                    "university": "Leeds Trinity University",
                    "location": "Leeds City Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Conversion)",
                    "university": "Leeds Trinity University",
                    "location": "Horsforth Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "837fe0e970fff8dc417d0f1c7cb7a76d",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "144"
                },
                {
                    "course_name": "Actuarial Mathematics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "136-186"
                },
                {
                    "course_name": "Advanced Psychology",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MPsyc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144"
                },
                {
                    "course_name": "Aeronautical and Aerospace Engineering",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144-152"
                },
                {
                    "course_name": "Aeronautical and Aerospace Engineering",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144-152"
                },
                {
                    "course_name": "Aeronautical and Aerospace Engineering (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "144-152"
                },
                {
                    "course_name": "Aeronautical and Aerospace Engineering (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "144-152"
                },
                {
                    "course_name": "Ancient History",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Ancient History and English",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Ancient History and History",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Ancient History and Philosophy",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Arabic and Chinese",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Arabic and Japanese",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "136-144"
                },
                {
                    "course_name": "Arabic and Portuguese",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Arabic and Russian",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Arabic, Islamic, Middle Eastern and North African Studies",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Architectural Engineering",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-160"
                },
                {
                    "course_name": "Architectural Engineering",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-160"
                },
                {
                    "course_name": "Architectural Engineering (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "144-160"
                },
                {
                    "course_name": "Architectural Engineering (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "136-160"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-160"
                },
                {
                    "course_name": "Architecture (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "144-160"
                },
                {
                    "course_name": "Art and Design",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Arts and Humanities with Foundation Year",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Audiology",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive Engineering",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144-165"
                },
                {
                    "course_name": "Automotive Engineering",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-165"
                },
                {
                    "course_name": "Automotive Engineering (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "144-165"
                },
                {
                    "course_name": "Automotive Engineering (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "144-165"
                },
                {
                    "course_name": "Banking and Finance",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MBiol (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MBiol (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences (Biotechnology with Enterprise)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MBiol (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences (Biotechnology with Enterprise)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MBiol (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology and History and Philosophy of Science",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Biomedical Sciences",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MBiol (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and the Human Resource",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Marketing",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Studies with Foundation Year",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-152"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-152"
                },
                {
                    "course_name": "Chemical Engineering (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "144-152"
                },
                {
                    "course_name": "Chemical Engineering (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "144-152"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-152"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-147"
                },
                {
                    "course_name": "Chemistry and Mathematics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-147"
                },
                {
                    "course_name": "Chemistry and Mathematics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "144-147"
                },
                {
                    "course_name": "Chemistry with a Year in Industry",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "136-152"
                },
                {
                    "course_name": "Chemistry with Study Abroad",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "136-152"
                },
                {
                    "course_name": "Childhood Studies",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Chinese (Modern)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Chinese and Italian B",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Chinese and Japanese Studies",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Chinese and Portuguese",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Chinese and Russian",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Chinese and Thai Studies",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Civil and Environmental Engineering",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-160"
                },
                {
                    "course_name": "Civil and Environmental Engineering",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-160"
                },
                {
                    "course_name": "Civil and Environmental Engineering (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "136-160"
                },
                {
                    "course_name": "Civil and Environmental Engineering (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "144-152"
                },
                {
                    "course_name": "Civil and Structural Engineering",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-160"
                },
                {
                    "course_name": "Civil and Structural Engineering",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-160"
                },
                {
                    "course_name": "Civil and Structural Engineering (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "136-160"
                },
                {
                    "course_name": "Civil and Structural Engineering (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "144-152"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144-160"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-160"
                },
                {
                    "course_name": "Civil Engineering (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "136-160"
                },
                {
                    "course_name": "Civil Engineering (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "144-160"
                },
                {
                    "course_name": "Civil Engineering with Transport",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-160"
                },
                {
                    "course_name": "Classical Civilisation",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Classical Literature and English",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Classical Literature and Philosophy",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Communication and Media",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "136-144"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-160"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144-160"
                },
                {
                    "course_name": "Computer Science (Artificial Intelligence)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-160"
                },
                {
                    "course_name": "Computer Science (Artificial Intelligence) (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "144-160"
                },
                {
                    "course_name": "Computer Science (High-Performance Graphics and Games Engineering)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-160"
                },
                {
                    "course_name": "Computer Science (High-Performance Graphics and Games Engineering) (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "144-160"
                },
                {
                    "course_name": "Computer Science (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "144-160"
                },
                {
                    "course_name": "Computer Science (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "144-160"
                },
                {
                    "course_name": "Criminal Justice and Criminology",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-144"
                },
                {
                    "course_name": "Cultural and Media Studies",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dental Hygiene and Dental Therapy",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Dental Surgery",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BChD",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144"
                },
                {
                    "course_name": "Diagnostic Radiography",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "54-144"
                },
                {
                    "course_name": "Digital Media",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "136-144"
                },
                {
                    "course_name": "East Asian Studies",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "East Asian Studies and Chinese",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "East Asian Studies and International Relations",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "East Asian Studies and Japanese",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Ecology and Conservation Biology",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MBiol (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecology and Conservation Biology",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Geography",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144-152"
                },
                {
                    "course_name": "Economics and History",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144"
                },
                {
                    "course_name": "Economics and Mathematics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144-186"
                },
                {
                    "course_name": "Economics and Politics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144"
                },
                {
                    "course_name": "Education",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Electronic and Electrical Engineering",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-152"
                },
                {
                    "course_name": "Electronic and Electrical Engineering",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144-152"
                },
                {
                    "course_name": "Electronic and Electrical Engineering (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "144-152"
                },
                {
                    "course_name": "Electronic and Electrical Engineering (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "144-152"
                },
                {
                    "course_name": "Electronics and Computer Engineering",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-152"
                },
                {
                    "course_name": "Electronics and Computer Engineering",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-152"
                },
                {
                    "course_name": "Electronics and Computer Engineering (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "144-152"
                },
                {
                    "course_name": "Electronics and Computer Engineering (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "144-147"
                },
                {
                    "course_name": "English and Comparative Literature",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "English and Film Studies",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "English and History",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "136"
                },
                {
                    "course_name": "English and History of Art",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "English and Music",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "136"
                },
                {
                    "course_name": "English and Philosophy",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "English and Sociology",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "136"
                },
                {
                    "course_name": "English Language and Linguistics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "136"
                },
                {
                    "course_name": "English Language and Literature",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "136"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "136"
                },
                {
                    "course_name": "English Literature with Creative Writing",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144"
                },
                {
                    "course_name": "English, Theatre and Performance",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Environment and Business",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Environmental Geoscience",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Fashion Design Innovation",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Fashion Marketing",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Film Studies",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "96-174"
                },
                {
                    "course_name": "Film, Photography and Media",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-144"
                },
                {
                    "course_name": "Financial Mathematics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-186"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Fine Art with Contemporary Cultural Theory",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Fine Art with History of Art",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Food Science",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Food Science",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Food Science and Nutrition",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128-147"
                },
                {
                    "course_name": "Food Science and Nutrition",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "French",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gateway Year to Medicine",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MB ChB",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "54-112"
                },
                {
                    "course_name": "Genetics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MBiol (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genetics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "136-144"
                },
                {
                    "course_name": "Geography with Environmental Mathematics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "136-144"
                },
                {
                    "course_name": "Geography with Transport Studies",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-144"
                },
                {
                    "course_name": "Geology",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Geophysical and Atmospheric Sciences",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "German",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Global Creative Industries",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Graphic and Communication Design",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Healthcare Science (Cardiac Physiology)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "54-144"
                },
                {
                    "course_name": "History",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "136"
                },
                {
                    "course_name": "History and Philosophy",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "History and Sociology",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "History of Art",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "History of Art with Cultural Studies",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Human Physiology, Physical Activity & Health",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MBiol (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Human Physiology, Physical Activity & Health",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interdisciplinary Science with Foundation Year",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Finance",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Marketing",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "International History and Politics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-144"
                },
                {
                    "course_name": "Islamic, Middle Eastern and North African Studies",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Italian",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Italian B and Japanese",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "136-144"
                },
                {
                    "course_name": "Italian B and Portuguese",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Italian B and Russian A",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Japanese",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Japanese and Russian",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Journalism",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "136-144"
                },
                {
                    "course_name": "Law",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "152"
                },
                {
                    "course_name": "Law (graduate programme)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with French Law",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144"
                },
                {
                    "course_name": "Law with German Law",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144"
                },
                {
                    "course_name": "Law with Hispanic Law",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144"
                },
                {
                    "course_name": "Liberal Arts",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144"
                },
                {
                    "course_name": "Linguistics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Linguistics and Philosophy",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "136-144"
                },
                {
                    "course_name": "Materials Science and Engineering",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "136-147"
                },
                {
                    "course_name": "Materials Science and Engineering",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "136-147"
                },
                {
                    "course_name": "Materials Science and Engineering (Industrial) BEng",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "136-147"
                },
                {
                    "course_name": "Materials Science and Engineering (Industrial) MEng, BEng",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "136-147"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "136-186"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MMath",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "136-186"
                },
                {
                    "course_name": "Mathematics and Music",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Mathematics and Philosophy",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Mathematics and Statistics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "136-186"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144-165"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144-165"
                },
                {
                    "course_name": "Mechanical Engineering (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "144-165"
                },
                {
                    "course_name": "Mechanical Engineering (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "144-165"
                },
                {
                    "course_name": "Mechatronics and Robotics Engineering",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-147"
                },
                {
                    "course_name": "Mechatronics and Robotics Engineering",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144-147"
                },
                {
                    "course_name": "Mechatronics and Robotics Engineering (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "144-147"
                },
                {
                    "course_name": "Mechatronics and Robotics Engineering (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "144-147"
                },
                {
                    "course_name": "Medical Engineering",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-152"
                },
                {
                    "course_name": "Medical Engineering",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-152"
                },
                {
                    "course_name": "Medical Engineering (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "144-152"
                },
                {
                    "course_name": "Medical Engineering (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "144-152"
                },
                {
                    "course_name": "Medical Ultrasound (Sonography)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicinal Chemistry",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-147"
                },
                {
                    "course_name": "Medicinal Chemistry",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-152"
                },
                {
                    "course_name": "Medicinal Chemistry with a Year in Industry",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "136-152"
                },
                {
                    "course_name": "Medicinal Chemistry with Study Abroad",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "136-152"
                },
                {
                    "course_name": "Medicine",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MB ChB",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MBiol (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Middle Eastern Studies and Politics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Midwifery",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-174"
                },
                {
                    "course_name": "Modern Languages",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Modern Languages and Business",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Modern Languages and Economics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144"
                },
                {
                    "course_name": "Modern Languages and English",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Modern Languages and Film Studies",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Modern Languages and History",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Modern Languages and International Relations",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Modern Languages and Linguistics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Modern Languages and Philosophy",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Modern Languages and Politics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Music",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "136-144"
                },
                {
                    "course_name": "Music (Performance)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BMus (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "136-144"
                },
                {
                    "course_name": "Music and Music Psychology",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MArts (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "136-144"
                },
                {
                    "course_name": "Music and Music Psychology",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-144"
                },
                {
                    "course_name": "Music and Music Technology",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "136-147"
                },
                {
                    "course_name": "Music with Enterprise",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-144"
                },
                {
                    "course_name": "Music, Multimedia and Electronics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "136-147"
                },
                {
                    "course_name": "Natural Sciences",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "152-153"
                },
                {
                    "course_name": "Natural Sciences",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MNatSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "152-153"
                },
                {
                    "course_name": "Natural Sciences (International)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "152-153"
                },
                {
                    "course_name": "Natural Sciences (International)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MNatSci",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "152-153"
                },
                {
                    "course_name": "Natural Sciences (with Industrial Experience)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "152-153"
                },
                {
                    "course_name": "Natural Sciences (with Industrial Experience)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MNatSci",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "152-153"
                },
                {
                    "course_name": "Neuroscience",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing (Child)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-150"
                },
                {
                    "course_name": "Nutrition",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-165"
                },
                {
                    "course_name": "Nutrition",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "136-153"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MBiol (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "136-144"
                },
                {
                    "course_name": "Philosophy and Physics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Philosophy and Politics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "136-144"
                },
                {
                    "course_name": "Philosophy, Ethics and Religion",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-144"
                },
                {
                    "course_name": "Philosophy, Politics and Economics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144"
                },
                {
                    "course_name": "Philosophy, Psychology and Scientific Thought",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "136-144"
                },
                {
                    "course_name": "Physician Associate Studies (Postgraduate)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Physics (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Physics (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Physics (International)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Physics (International)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Physics with Astrophysics (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Physics with Astrophysics (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Physics with Astrophysics (International)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Physics with Astrophysics (International)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Politics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "136-144"
                },
                {
                    "course_name": "Portuguese and Russian A",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Portuguese and Russian B",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Product Design",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144"
                },
                {
                    "course_name": "Product Design (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "144"
                },
                {
                    "course_name": "Professional Studies",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "144"
                },
                {
                    "course_name": "Psychology with Education",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Religion, Theology and Society",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Social and Political Sciences",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "136-144"
                },
                {
                    "course_name": "Social Policy, Sociology and Crime",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Social Science",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Spanish",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Spanish, Portuguese and Latin American Studies",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Sport and Exercise Sciences",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Studies in Science with Foundation Year",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Sustainability and Environmental Management",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Sustainable Fashion",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Textile Innovation & Sustainability",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Thai Studies",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Theatre and Performance",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Theatre and Performance with Enterprise",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Theoretical Physics (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Theoretical Physics (Industrial)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Theoretical Physics (International)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Theoretical Physics (International)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Zoology",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MBiol (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Business Management and Leadership",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Child and Family Studies",
                    "university": "University of Leeds",
                    "location": "No Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interdisciplinary Studies with Preparation for Higher Education",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Learning and Teaching with SEND",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Studies",
                    "university": "University of Leeds",
                    "location": "No Locations",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Chemical Engineering",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science (Artificial Intelligence)",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science (Cloud Computing)",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science (Data Analytics)",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Mechanical Engineering",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc (Eng)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advertising and Design",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Air Quality Solutions with Data Science",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Mathematics",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Psychology of Music",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Theatre and Social Change",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Translation Studies",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arabic, Islamic and Middle Eastern Studies",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture Design",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Design",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art Gallery and Museum Studies",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts Management and Heritage Studies",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astrophysics",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astrophysics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Audiences, Engagement, Participation",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Audiovisual Translation and Localisation",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive Engineering",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc (Eng)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Banking and International Finance",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biodiversity and Conservation",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biodiversity and Conservation with African Field Course",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical and Healthcare Ethics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biopharmaceutical Development",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biopharmaceutical Development (Industrial Placement)",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "20 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioscience",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology with Business Enterprise",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MBA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics and Decision Sciences",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Public Service Interpreting and Translation Studies",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer Biology and Therapy",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cardiovascular Health and Disease",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Childhood Studies",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate and Atmospheric Science",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MRes",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate Futures: Science, Society and Politics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Embryology and Assisted Reproduction Technology",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Development and Disorders MSc",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Colour",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication and Media",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communications and Signal Processing",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc (Eng)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conference Interpreting",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conference Interpreting and Translation Studies",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conflict, Development and Peacebuilding",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Consumer Analytics and Marketing Strategy",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Corporate Communications, Marketing and Public Relations",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Justice and Criminal Law",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Justice and Criminology",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Critical and Cultural Theory",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Critical and Experimental Composition",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MMus",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Culture, Creativity and Entrepreneurship",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Analytics and Human Resource Management",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Analytics",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dental Materials",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dental Public Health",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diagnostic Imaging (Medical Ultrasound)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital and Automated Chemistry",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Design Futures",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drug Discovery and Development",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecological Economics",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Engineering and Renewable Energy Systems",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc (Eng)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc (Eng)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Embedded Systems Engineering",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc (Eng)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy and Environmental Engineering",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc (Eng)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Geology",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Project Management",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc (Eng)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering, Technology and Business Management",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Enterprise and Entrepreneurship",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Data Science and Analytics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Engineering and Project Management",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc (Eng)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Exploration Geophysics",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion, Enterprise and Society",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film, Photography and Media",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Investment",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Mathematics",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Risk Management",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Product Innovation",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Science",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Science (Food Biotechnology)",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Science and Nutrition",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender Studies",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geographical Information Science",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geotechnical Engineering",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc (Eng)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Conservation Science",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development and Education",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Ethical Challenges",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Fashion Management",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Governance and Diplomacy",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Performance and Cultural Industries",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Political Economy",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Security Challenges",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Strategy and Innovation Management",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Supply Chain Management",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Informatics with Data Science",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "PgDip",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "High-Performance Graphics and Games Engineering",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Health, Medicine and Society",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Inequalities and Social Science",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Infection, Immunity and Human Disease",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intellectual Property Law",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Banking and Finance Law",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Law",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Communication",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Construction Management and Engineering",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc (Eng)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Corporate Law",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Health",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International History",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Rights Law",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Journalism",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law and Global Governance",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Marketing Management",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Trade Law",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Finance",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Social Justice",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics for English Language Teaching",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management Consulting",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management of Information Systems and Digital Innovation",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing Management with Advertising",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science and Engineering",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronics and Robotics Engineering",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media Industries",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Engineering",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc (Eng)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Imaging",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "PgCert",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MRes",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval Studies",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mobile Computing and Communications Networks",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern History",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular Medicine",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Wellbeing",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Management",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Musicology",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nutrition",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oral Surgery",
                    "university": "University of Leeds",
                    "location": "Multiple Locations",
                    "qualification": "PgCert",
                    "duration": "4 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Organizational Psychology and Business",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paediatric Dentistry",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "Professional Doctorate",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MMus",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance Design",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Plant Science and Biotechnology",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Communication",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Science",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Postcolonial Studies",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Precision Medicine: Genomic Data Science",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Language and Intercultural Studies",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Promotional Media",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological Approaches to Health",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health (International)",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "PgCert",
                    "duration": "4 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Railway Engineering with Project Management",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc (Eng)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Railway Operations, Management and Policy",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religion",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science and Technology for Sustainability",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science, History and Society",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social and Cultural History",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social and Public Policy",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social History of Art",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Society, Culture and Media",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Medicine",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport, Exercise and Rehabilitation",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics with Applications to Finance",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Engineering",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc (Eng)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability and Business",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability and Consultancy",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability in Transport",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability, Environment and Development",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Agriculture and Food Production",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Cities",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Food Systems and Food Security",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (China)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL) Studies",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Terrorism and Insurgency",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Textile Sustainability and Innovation",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Transport Economics",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Transport Infrastructure: Design and Construction",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc (Eng)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Transport Planning",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc (Eng)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Transport Planning and Engineering",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc (Eng)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Transport Planning and the Environment",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Data Science and Analytics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Water, Sanitation and Health Engineering",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc (Eng)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Work and Employment Relations (WERD)",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Writing for Performance and Digital Media",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice (Apprenticeship)",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Air Quality Solutions with Data Science",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Mathematics",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Psychology of Music",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Theatre and Social Change",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Translation Studies",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arabic, Islamic and Middle Eastern Studies",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art Gallery and Museum Studies",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts Management and Heritage Studies",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astrophysics",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Audiences, Engagement, Participation",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "PgCert",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Audiovisual Translation and Localisation",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biodiversity and Conservation",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biodiversity and Conservation with African Field Course",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical and Healthcare Ethics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cardiac Device and Rhythm Management",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "PgCert",
                    "duration": "9 months",
                    "study_mode": "Part-time block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cardiovascular Health and Disease",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Assessment",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Education",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication and Media",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Critical and Cultural Theory",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Critical and Experimental Composition",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MMus",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Culture, Creativity and Entrepreneurship",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Analytics for Health",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diagnostic Imaging (Medical Ultrasound)",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Echocardiography",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Geology",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film, Photography and Media",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geotechnical Engineering Degree Apprenticeship",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Ethical Challenges",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Fashion Management",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Informatics with Data Science",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "PgDip",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Research",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "PgDip",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Health, Medicine and Society",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Communication",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Journalism",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics for English Language Teaching",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media Industries",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Imaging",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval Studies",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern History",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Wellbeing",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Management",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Musicology",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oral Surgery",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "8 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paediatric Dentistry",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "Professional Doctorate",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MMus",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance Design",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy Practice",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "PgDip",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Communication",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Postcolonial Studies",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Language and Intercultural Studies",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Promotional Media",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychotherapy and Counselling",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MA",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religion",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science, History and Society",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social and Cultural History",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social History of Art",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Medicine",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability in Transport",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Systemic Family Therapy MSc",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Systemic Practice",
                    "university": "University of Leeds",
                    "location": "Multiple Locations",
                    "qualification": "No award",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Transport Economics",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Transport Planning",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc (Eng)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Transport Planning and Engineering",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc (Eng)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Transport Planning and the Environment",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Work and Employment Relations (WERD)",
                    "university": "University of Leeds",
                    "location": "University of Leeds",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Writing for Performance and Digital Media",
                    "university": "University of Leeds",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "f52afcff940348f95336b48ddf3a14db",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Aeronautical Engineering HTQ Higher National Diploma",
                    "university": "Leicester College",
                    "location": "Abbey Park Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Artistic Make Up and Special Effects",
                    "university": "Leicester College",
                    "location": "Freemen's Park Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Computing (Software/Network Engineering) HTQ Higher National Diploma",
                    "university": "Leicester College",
                    "location": "St Margaret\u2019s Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Construction and the Built Environment HTQ HNC/D",
                    "university": "Leicester College",
                    "location": "Freemen's Park Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Counselling and Applied Psychology",
                    "university": "Leicester College",
                    "location": "Abbey Park Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Engineering (Mechanical/Electrical) HTQ HNC/D",
                    "university": "Leicester College",
                    "location": "Abbey Park Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Fashion/Footwear Design",
                    "university": "Leicester College",
                    "location": "St Margaret\u2019s Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Fine Art Higher National Diploma (HND)",
                    "university": "Leicester College",
                    "location": "St Margaret\u2019s Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Graphic Design and eMedia",
                    "university": "Leicester College",
                    "location": "St Margaret\u2019s Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "International Travel and Tourism Management (Blended Learning)",
                    "university": "Leicester College",
                    "location": "Abbey Park Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Leadership and Management HTQ HND",
                    "university": "Leicester College",
                    "location": "Abbey Park Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Music Performance",
                    "university": "Leicester College",
                    "location": "Abbey Park Campus",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Music Technology",
                    "university": "Leicester College",
                    "location": "Abbey Park Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Photography",
                    "university": "Leicester College",
                    "location": "St Margaret\u2019s Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Professional Studies (Creative Industries)",
                    "university": "Leicester College",
                    "location": "St Margaret\u2019s Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Tourism and Events Management",
                    "university": "Leicester College",
                    "location": "Abbey Park Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Tourism and Events Management (Top-Up)",
                    "university": "Leicester College",
                    "location": "Abbey Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Construction Management (Top-Up)",
                    "university": "Leicester College",
                    "location": "Freemen's Park Campus",
                    "qualification": "BSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education and Training Level 6 Professional Graduate Certificate",
                    "university": "Leicester College",
                    "location": "St Margaret\u2019s Campus",
                    "qualification": "PGCE (Professional)",
                    "duration": "2 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music (Performance or Production)",
                    "university": "Leicester College",
                    "location": "Abbey Park Campus",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Photography",
                    "university": "Leicester College",
                    "location": "St Margaret\u2019s Campus",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "fe2ca066e3c015aae5f3445e3d598115",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Aeronautical Engineering For England (HTQ)",
                    "university": "Lincoln College University Centre",
                    "location": "ASI Newark",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Aeronautical Engineering For England (HTQ)",
                    "university": "Lincoln College University Centre",
                    "location": "ASI Newark",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Animal Management",
                    "university": "Lincoln College University Centre",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Applied Animal Management (Top-Up)",
                    "university": "Lincoln College University Centre",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business",
                    "university": "Lincoln College University Centre",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Clinical Herbalism",
                    "university": "Lincoln College University Centre",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Commercial Photography and Digital Media",
                    "university": "Lincoln College University Centre",
                    "location": "Christ's Hospital Terrace",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Complementary Healthcare and Wellbeing",
                    "university": "Lincoln College University Centre",
                    "location": "Main Site",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Complementary Studies (Herbalism)",
                    "university": "Lincoln College University Centre",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Lincoln College University Centre",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Digital Learning Design",
                    "university": "Lincoln College University Centre",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering For England (HTQ)",
                    "university": "Lincoln College University Centre",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Engineering",
                    "university": "Lincoln College University Centre",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Travel and Tourism Management",
                    "university": "Lincoln College University Centre",
                    "location": "ASI Newark",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Mechanical Engineering for England (HTQ)",
                    "university": "Lincoln College University Centre",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Musical Instrument Craft (Guitar Making and Repair)",
                    "university": "Lincoln College University Centre",
                    "location": "Newark College",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Musical Instrument Craft (Piano Tuning and Repair)",
                    "university": "Lincoln College University Centre",
                    "location": "Newark College",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Musical Instrument Craft (Violin Making and Repair)",
                    "university": "Lincoln College University Centre",
                    "location": "Newark College",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Musical Instrument Craft (Woodwind Making and Repair)",
                    "university": "Lincoln College University Centre",
                    "location": "Newark College",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "Lincoln College University Centre",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Social Science",
                    "university": "Lincoln College University Centre",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Social Science (Criminology and Law)",
                    "university": "Lincoln College University Centre",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sports Coaching, Education and Development",
                    "university": "Lincoln College University Centre",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sports Coaching, Education and Development Top Up",
                    "university": "Lincoln College University Centre",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Therapy Top Up",
                    "university": "Lincoln College University Centre",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "b332ac815dba2e1b2e89fdbe42994b2b",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Applied Sciences (Biology)",
                    "university": "City of Liverpool College University Centre",
                    "location": "Clarence St",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Applied Sciences (Biology)",
                    "university": "City of Liverpool College University Centre",
                    "location": "Clarence St",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Sciences (Chemistry)",
                    "university": "City of Liverpool College University Centre",
                    "location": "Clarence St",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Applied Sciences (Chemistry)",
                    "university": "City of Liverpool College University Centre",
                    "location": "Clarence St",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Design (Fashion and Textiles)",
                    "university": "City of Liverpool College University Centre",
                    "location": "Arts Centre",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Art and Design (Fashion)",
                    "university": "City of Liverpool College University Centre",
                    "location": "Arts Centre",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Design (Fashion/Photography) (Top-Up)",
                    "university": "City of Liverpool College University Centre",
                    "location": "Arts Centre",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Design (Photography)",
                    "university": "City of Liverpool College University Centre",
                    "location": "Arts Centre",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Design (Photography)",
                    "university": "City of Liverpool College University Centre",
                    "location": "Arts Centre",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Business",
                    "university": "City of Liverpool College University Centre",
                    "location": "Clarence St",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business",
                    "university": "City of Liverpool College University Centre",
                    "location": "Clarence St",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Computing (Top-Up)",
                    "university": "City of Liverpool College University Centre",
                    "location": "Arts Centre",
                    "qualification": "BA/BSc (H)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing for England",
                    "university": "City of Liverpool College University Centre",
                    "location": "Arts Centre",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Computing for England",
                    "university": "City of Liverpool College University Centre",
                    "location": "Arts Centre",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Childhood Studies (Top-Up)",
                    "university": "City of Liverpool College University Centre",
                    "location": "The Learning Exchange, Roscoe Street",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Years Practice, Foundation Degree",
                    "university": "City of Liverpool College University Centre",
                    "location": "The Learning Exchange, Roscoe Street",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Engineering (Electrical and Electronic Engineering)",
                    "university": "City of Liverpool College University Centre",
                    "location": "Vauxhall Road Centre",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Engineering (General Engineering)",
                    "university": "City of Liverpool College University Centre",
                    "location": "Vauxhall Road Centre",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Manufacturing Engineering)",
                    "university": "City of Liverpool College University Centre",
                    "location": "Vauxhall Road Centre",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Games Design and Virtual Media Production",
                    "university": "City of Liverpool College University Centre",
                    "location": "Arts Centre",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Healthcare Practice (Integrated Health and Social Care)",
                    "university": "City of Liverpool College University Centre",
                    "location": "The Learning Exchange, Roscoe Street",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Healthcare Practice for England (Integrated Health and Social Care)",
                    "university": "City of Liverpool College University Centre",
                    "location": "The Learning Exchange, Roscoe Street",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Music (General)",
                    "university": "City of Liverpool College University Centre",
                    "location": "Arts Centre",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Music (Performance)",
                    "university": "City of Liverpool College University Centre",
                    "location": "Arts Centre",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music (Popular / Production) (Top-Up)",
                    "university": "City of Liverpool College University Centre",
                    "location": "Arts Centre",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music (Production)",
                    "university": "City of Liverpool College University Centre",
                    "location": "Arts Centre",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music (Technology)",
                    "university": "City of Liverpool College University Centre",
                    "location": "Arts Centre",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Performing Arts (Acting)",
                    "university": "City of Liverpool College University Centre",
                    "location": "Arts Centre",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Performing Arts (Acting)",
                    "university": "City of Liverpool College University Centre",
                    "location": "Arts Centre",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performing Arts (Acting/Dance) (Top-Up)",
                    "university": "City of Liverpool College University Centre",
                    "location": "Arts Centre",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performing Arts (Dance)",
                    "university": "City of Liverpool College University Centre",
                    "location": "Arts Centre",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Performing Arts (Dance)",
                    "university": "City of Liverpool College University Centre",
                    "location": "Arts Centre",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Science (Coaching Science)",
                    "university": "City of Liverpool College University Centre",
                    "location": "The Learning Exchange, Roscoe Street",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Science (Coaching Science)",
                    "university": "City of Liverpool College University Centre",
                    "location": "The Learning Exchange, Roscoe Street",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Coaching (Top-Up)",
                    "university": "City of Liverpool College University Centre",
                    "location": "The Learning Exchange, Roscoe Street",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Tourism, Leisure and Hospitality, Foundation Degree",
                    "university": "City of Liverpool College University Centre",
                    "location": "Duke St",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "65abb54b49be8380b4e8cd3c23627b1b",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting & Finance",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Accounting & Finance\tand\tSport & Physical Education (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Accounting & Finance (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Accounting & Finance and Business Management",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Accounting & Finance and Business Management (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Accounting & Finance and Computer Science",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Accounting & Finance and Computer Science (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Accounting & Finance and Economics",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Accounting & Finance and Economics (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Accounting & Finance and Information Technology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Accounting & Finance and Information Technology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Accounting & Finance and Law",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Accounting & Finance and Mathematics",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Accounting & Finance and Mathematics (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Accounting & Finance and Sport & Physical Education",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Accounting and Finance and Law (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Acting and Contemporary Fashion Design",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Acting and Contemporary Fashion Design (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Acting and Creative Writing",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Acting and Creative Writing (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Acting and Digital Creativity",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-112"
                },
                {
                    "course_name": "Acting and Digital Creativity (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Acting and Drama",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Acting and Drama (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Acting and Education",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Acting and Education (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Acting and English Language",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Acting and English Language (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Acting and Film & Visual Culture",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Acting and Film & Visual Culture (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Acting and International Relations",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Acting and International Relations (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Acting and Media & Communication",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Acting and Media & Communication (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Acting and Music",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Acting and Music (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Acting and Musical Theatre",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Acting and Musical Theatre (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Acting and Philosophy & Ethics",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Acting and Philosophy & Ethics (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Acting and Politics",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Acting and Politics (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Acting and Psychology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Acting and Psychology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Acting and Sociology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Acting and Sociology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Art History and Business Management",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Art History and Contemporary Craft",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Art History and Contemporary Fashion Design",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Art History and Creative Industries Business Management",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Art History and Creative Writing",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Art History and Early Childhood",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Art History and Education",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Art History and Film & Visual Culture",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Art History and Media & Communication",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Art History and Philosophy & Ethics",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Art History and Psychology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Art History and Religion, Theology & Spirituality",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Art History and Sociology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Artificial Intelligence (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Artificial Intelligence (with Year in Industry)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Arts & Wellbeing",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business Management",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business Management (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Management and Childhood & Youth",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business Management and Childhood & Youth (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Management and Computer Science",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business Management and Computer Science (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Management and Digital Marketing",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management and Digital Marketing (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Management and Economics",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business Management and Economics (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Management and Film & Visual Culture",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business Management and Film & Visual Culture (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Management and Food & Nutritional Sciences",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business Management and Food & Nutritional Sciences (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Management and Geography",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business Management and Geography (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Management and History",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business Management and History (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Management and Human Geography",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business Management and Human Geography (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Management and Information Technology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business Management and Information Technology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Management and International Relations",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business Management and International Relations (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Management and Law",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business Management and Law (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Management and Mathematics",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business Management and Mathematics (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Management and Music Production",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business Management and Music Production (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Management and Musical Theatre",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business Management and Musical Theatre (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Management and Sport & Exercise Science",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business Management and Sport & Exercise Science (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Management and Sport & Physical Education",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business Management and Sport & Physical Education (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Management and Tourism",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business Management and Tourism (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Childhood & Youth and Contemporary Craft",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Childhood & Youth and Contemporary Craft (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Childhood & Youth and Creative Writing",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Childhood & Youth and Creative Writing (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Childhood & Youth and Drama",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Childhood & Youth and Drama (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Childhood & Youth and Early Childhood",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Childhood & Youth and Early Childhood (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Childhood & Youth and Education",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Childhood & Youth and Education (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Childhood & Youth and Psychology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Childhood & Youth and Psychology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Childhood & Youth and Religion, Theology & Spirituality",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Childhood & Youth and Religion, Theology & Spirituality (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Childhood & Youth and Social Care",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Childhood & Youth and Social Care (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Childhood & Youth and Sociology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Childhood & Youth and Sociology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Childhood & Youth and Sport & Physical Education",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Childhood & Youth and Sport & Physical Education (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Computer Science (with Foundation Year and Year in Industry)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Computer Science (with Year in Industry)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Computer Science and Creative Industries Business Management",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Computer Science and Creative Industries Business Management (with foundation year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Computer Science and Criminology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Computer Science and Criminology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Computer Science and Digital Marketing",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science and Digital Marketing (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Computer Science and Geography",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Computer Science and Geography (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Computer Science and Global Philosophies & Worldviews",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Computer Science and Global Philosophies & Worldviews (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Computer Science and History",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Computer Science and History (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Computer Science and Music Production",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Computer Science and Music Production (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Computer Science and Philosophy & Ethics",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Computer Science and Philosophy & Ethics (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Conservation Biology and Digital Marketing",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Conservation Biology and Digital Marketing (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Conservation Biology and Geography",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Conservation Biology and Geography (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Contemporary Craft and Contemporary Fashion Design",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Contemporary Craft and Contemporary Fashion Design (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Contemporary Craft and Creative Writing",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Contemporary Craft and Creative Writing (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Contemporary Craft and Dance",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Contemporary Craft and Dance (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Contemporary Craft and Digital Marketing",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Contemporary Craft and Digital Marketing (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Contemporary Craft and Early Childhood",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Contemporary Craft and Early Childhood (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Contemporary Craft and English Language",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Contemporary Craft and English Language (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Contemporary Craft and English Literature",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Contemporary Craft and English Literature (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Contemporary Craft and Film & Visual Culture",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Contemporary Craft and Film & Visual Culture (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Contemporary Craft and Health & Wellbeing",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Contemporary Craft and Health & Wellbeing (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Contemporary Craft and Music",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Contemporary Craft and Music (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Contemporary Craft and Music Production",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Contemporary Craft and Music Production (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Contemporary Craft and Musical Theatre",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Contemporary Craft and Musical Theatre (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Contemporary Craft and Special Educational Needs",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Contemporary Craft and Special Educational Needs (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Contemporary Craft and Stage Design & Scenography",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Contemporary Craft and Stage Design & Scenography (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Contemporary Fashion Design and Creative Industries Business Management",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Contemporary Fashion Design and Creative Industries Business Management (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Contemporary Fashion Design and Digital Marketing",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Contemporary Fashion Design and Digital Marketing (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Creative Industries Business Management and Creative Writing",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Industries Business Management and Creative Writing (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Creative Industries Business Management and Dance",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Industries Business Management and Dance (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Creative Industries Business Management and English Language",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Industries Business Management and English Language (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Creative Industries Business Management and English Literature",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Industries Business Management and English Literature (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Creative Industries Business Management and Film & Visual Culture",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Industries Business Management and Film & Visual Culture (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Creative Industries Business Management and Music",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Industries Business Management and Music (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Creative Industries Business Management and Music Production",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Industries Business Management and Music Production (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Creative Industries Business Management and Musical Theatre",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Industries Business Management and Musical Theatre (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Creative Industries Business Management and Stage Design & Scenography",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Industries Business Management and Stage Design & Scenography (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Creative Industries Business Management and Tourism",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Industries Business Management and Tourism (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Creative Writing and Criminology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Writing and Criminology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Creative Writing and Dance",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Writing and Dance (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Creative Writing and Drama",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Writing and Drama (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Creative Writing and English Literature",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Writing and English Literature (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Creative Writing and Global Philosophy & Worldviews",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Creative Writing and Global Philosophy & Worldviews (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Creative Writing and Media & Communication",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Writing and Media & Communication (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Creative Writing and Philosophy & Ethics",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Writing and Philosophy & Ethics (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Creative Writing and Psychology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Writing and Psychology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Criminology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Criminology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Criminology and Early Childhood",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Criminology and Early Childhood (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Criminology and Education",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Criminology and Education (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Criminology and Film & Visual Culture",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Criminology and Film & Visual Culture (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Criminology and Human Biology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Criminology and Human Biology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Criminology and Law",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Criminology and Law (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Criminology and Media & Communication",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Criminology and Media & Communication (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Criminology and Philosophy & Ethics",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Criminology and Philosophy & Ethics (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Criminology and Psychology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Criminology and Psychology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Criminology and Religion, Theology & Spirituality",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology and Religion, Theology & Spirituality (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Criminology and Social Care",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Criminology and Social Care (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Criminology and Sociology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Dance and Drama",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Dance and Drama (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Dance and Early Childhood",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Dance and Early Childhood (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Dance and Education",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Dance and Musical Theatre",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Dance and Musical Theatre (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Dance and Sport & Physical Education",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Dance and Sport & Physical Education (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Digital Creativity and Digital Marketing",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Digital Creativity and Digital Marketing (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Digital Marketing and Drama",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Digital Marketing and Drama (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Digital Marketing and Early Childhood",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Digital Marketing and Early Childhood (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Digital Marketing and Economics",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Digital Marketing and Economics (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Digital Marketing and English Language",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Digital Marketing and English Language (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Digital Marketing and Film & Visual Culture",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Digital Marketing and Film & Visual Culture (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Digital Marketing and Food & Nutritional Sciences",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Digital Marketing and Food & Nutritional Sciences (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Digital Marketing and Information Technology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Digital Marketing and Information Technology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Digital Marketing and Law",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Digital Marketing and Law (with Foundation year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Digital Marketing and Mathematics",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Digital Marketing and Mathematics (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Digital Marketing and Media & Communication",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Digital Marketing and Media & Communication (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Digital Marketing and Musical Theatre",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Digital Marketing and Musical Theatre (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Digital Marketing and Philosophy & Ethics",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Digital Marketing and Philosophy & Ethics (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Digital Marketing and Politics",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Digital Marketing and Politics (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Digital Marketing and Psychology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Digital Marketing and Psychology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Digital Marketing and Religion, Theology & Spirituality",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Digital Marketing and Religion, Theology & Spirituality (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Digital Marketing and Sociology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Digital Marketing and Sociology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Digital Marketing and Sport & Physical  Education (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Digital Marketing and Sport & Physical Education",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Drama & Theatre",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Drama & Theatre (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Drama and English Literature",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Drama and English Literature (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Drama and Film & Visual Culture",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Drama and Film & Visual Culture (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Drama and History",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Drama and History (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Drama and Music Production",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Drama and Music Production (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Drama and Musical Theatre",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Drama and Musical Theatre (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Drama and Religion, Theology & Spirituality",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Drama and Religion, Theology & Spirituality (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Drama and Sociology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Drama and Sociology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Early Childhood",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Early Childhood (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Early Childhood and Education",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Early Childhood and Education (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Early Childhood and Global Philosophy & Worldviews",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Early Childhood and Global Philosophy & Worldviews (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Early Childhood and Health & Wellbeing",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Early Childhood and Health & Wellbeing (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Early Childhood and Psychology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Early Childhood and Psychology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Early Childhood and Social Care",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Early Childhood and Social Care (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Early Childhood and Special Educational Needs",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Early Childhood and Special Educational Needs (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Early Education Primary (3-7) with QTS",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Economics",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Economics (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Economics and History",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Economics and History (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Economics and Philosophy & Ethics",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Economics and Philosophy & Ethics (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Economics and Politics",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Economics and Politics (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Economics and Sport & Exercise Science",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Economics and Sport & Exercise Science (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Education",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Education (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Education and English Language",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Education and English Language (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Education and English Literature",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Education and English Literature (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Education and Food & Nutritional Sciences",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Education and Food & Nutritional Sciences (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Education and Global Philosophy & Worldviews",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Education and Global Philosophy & Worldviews (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Education and Mathematics",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Education and Mathematics (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Education and Psychology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Education and Psychology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Education and Religion, Theology & Spirituality",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Education and Religion, Theology & Spirituality (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Education and Special Educational Needs",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Education and Special Educational Needs (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Education and Sport & Physical Education",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Education and Sport & Physical Education (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Electronic & Computer Engineering",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Electronic & Computer Engineering (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Electronic & Computer Engineering (with Year in Industry)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Language",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Language (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "English Language and English Literature",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Language and English Literature (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "English Language and Global Philosophy & Worldviews",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English Language and Global Philosophy & Worldviews (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "English Literature",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Literature (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "English Literature and Film & Visual Culture",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Literature and Film & Visual Culture (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "English Literature and Law",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Literature and Law (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "English Literature and Media & Communication",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Literature and Media & Communication (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "English Literature and Philosophy & Ethics",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Literature and Philosophy & Ethics (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "English Literature and Politics",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Literature and Politics (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "English Literature and Psychology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Literature and Psychology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "English Literature and Religion, Theology & Spirituality",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English Literature and Religion, Theology & Spirituality (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Environmental Science (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Film & Visual Culture and Global Philosophy & Worldviews",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Film & Visual Culture and Global Philosophy & Worldviews (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Film & Visual Culture and Media & Communication",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Film & Visual Culture and Media & Communication (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Film & Visual Culture and Music Production",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Film & Visual Culture and Music Production (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Film & Visual Culture and Philosophy & Ethics",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Film & Visual Culture and Philosophy & Ethics (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Film, TV, Radio & Media Production",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Film, TV, Radio & Media Production (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Fine Art (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Food & Nutritional Sciences",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Food & Nutritional Sciences and Health & Wellbeing",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Food & Nutritional Sciences and Health & Wellbeing (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Food & Nutritional Sciences and Human Biology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Food & Nutritional Sciences and Human Biology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Food & Nutritional Sciences and Media & Communication",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Food & Nutritional Sciences and Media & Communication (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Food & Nutritional Sciences and Psychology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Food & Nutritional Sciences and Psychology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Food & Nutritional Sciences and Sport & Exercise Science",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Food & Nutritional Sciences and Sport & Exercise Science (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Game Art & Design",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Game Art & Design (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Geography",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Geography (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Geography and Religion, Theology & Spirituality",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Geography and Religion, Theology & Spirituality (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Geography and Sport & Physical Education",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Geography and Sport & Physical Education (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Global Philosophies & Worldviews & Philosophy & Ethics (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Global Philosophies & Worldviews and Philosophy & Ethics",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Global Philosophies & Worldviews and Sociology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Global Philosophies & Worldviews and Sociology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Global Philosophies & Worldviews and Sport & Physical Education",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Global Philosophies & Worldviews and Sport & Physical Education (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Global Philosophy & Worldviews and International Relations",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Global Philosophy & Worldviews and International Relations (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Global Philosophy & Worldviews and Media & Communication",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Global Philosophy & Worldviews and Media & Communication (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Global Philosophy & Worldviews and Politics",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Global Philosophy & Worldviews and Politics (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Global Philosophy & Worldviews and Psychology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Global Philosophy & Worldviews and Psychology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Global Philosophy & Worldviews and Social Care",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Global Philosophy & Worldviews and Social Care (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Graphic Design (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Health & Social Care",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Health & Social Care (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Health & Wellbeing and Human Biology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Health & Wellbeing and Human Biology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Health & Wellbeing and Psychology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Health & Wellbeing and Psychology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Health & Wellbeing and Religion, Theology & Spirituality",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Health & wellbeing and Religion, Theology & Spirituality (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Health & Wellbeing and Social Care",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Health & Wellbeing and Social Care (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Health & Wellbeing and Sport & Physical Education",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Health & Wellbeing and Sport & Physical Education (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "History",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "History (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "History and Law",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "History and Law (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "History and Media & Communication",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "History and Media & Communication (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "History and Philosophy & Ethics",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "History and Philosophy & Ethics (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "History and Politics",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "History and Politics (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "History and Religion, Theology & Spirituality",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "History and Religion, Theology & Spirituality (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "History and Sociology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "History and Sociology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Human Biology and Sport & Exercise Science",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Human Biology and Sport & Exercise Science (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Information Technology and Media & Communication",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Information Technology and Media & Communication (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Information Technology and Music Production",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Information Technology and Music Production (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "International Relations and Tourism",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "International Relations and Tourism (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Law",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Law (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Law and Philosophy & Ethics",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Law and Philosophy & Ethics (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Law and Politics",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Law and Politics (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Law and Psychology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Law and Psychology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Mathematics (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Mathematics (with Year in Industry)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Media & Communication and Religion, Theology & Spirituality",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Media & Communication and Religion, Theology & Spirituality (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Media & Communication and Sociology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Media & Communication and Sociology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Media & Communication and Special Educational Needs",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Media & Communication and Special Educational Needs (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Media & Communication and Tourism",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Media & Communication and Tourism (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Music",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Music and Music Production",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music and Music Production (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Music and Politics",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music and Politics (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Music and Psychology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music and Psychology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Music Production",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Production (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Music Production and Musical Theatre",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Production and Musical Theatre (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Musical Theatre and Psychology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Musical Theatre and Psychology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Philosophy & Ethics and Religion, Theology & Spirituality",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Philosophy & Ethics and Religion, Theology & Spirituality (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Philosophy & Ethics and Sociology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Philosophy & Ethics and Sociology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Philosophy (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Politics & International Relations",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Politics & International Relations (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Politics and Religion, Theology & Spirituality",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Politics and Religion, Theology & Spirituality (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Politics and Sociology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Politics and Sociology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Primary Education with QTS  (5-11)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Psychology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Psychology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Psychology and Religion, Theology & Spirituality",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Psychology and Religion, Theology & Spirituality (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Psychology and Sociology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Psychology and Sociology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Psychology and Special Educational Needs",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Psychology and Special Educational Needs (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Psychology and Sport & Exercise Science",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Psychology and Sport & Exercise Science (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Psychology in Business",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology in Business (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Psychology in Education",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Psychology in Education (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Religion, Theology & Spirituality and Social Care",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Religion, Theology & Spirituality and Special Educational Needs",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Religion, Theology & Spirituality and Special Educational Needs (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Religion, Worldviews & Spirituality",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Religion, Worldviews & Spirituality (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Robotics",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Robotics (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Robotics (with Year in Industry and Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Robotics (with Year in Industry)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Social Care and Religion, Theology & Spirituality (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Social Care and Sociology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Social Care and Sociology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Social Care and Special Educational Needs",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Social Care and Special Educational Needs (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Social Care and Sport & Physical Education",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Social Care and Sport & Physical Education (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Social Work",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Social Work (MA) (PG)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sociology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BEng",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Software Engineering (with Foundation Year and Year in Industry)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Software Engineering (with Foundation Year and Year in Industry)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Software Engineering (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Software Engineering (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Software Engineering (with Year in Industry)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Software Engineering (with Year in Industry)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Special Educational Needs & Disability Studies",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Special Educational Needs & Disability Studies (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Special Educational Needs and\tSport & Physical Education (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Special Educational Needs and Sport & Physical Education",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sport & Exercise Nutrition",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sport & Exercise Nutrition (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sport & Exercise Science",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sport & Exercise Science (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sport & Physical Education",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sport & Physical Education (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sport Management",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sport Psychology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sport Psychology (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sport Rehabilitation",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Tourism Management",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Tourism Management (with Foundation Year)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Advanced Computer Science",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Mentoring and Coaching",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Musculoskeletal (MSK) Practice",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Developmental Psychology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Sustainability",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognition and Aesthetics",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary Performance",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Music Practice",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dance (Professional Practice)",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Disability and Interdisciplinary Professional Practice",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Disability Studies",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Childhood",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecology & Environmental Management",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Leadership and Management",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature (MA by Research)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health & Social Care",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Criminology and Security Studies",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Cyber Law and Policy (LLM by Research)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "LLM (Res)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Terrorism and Extremism",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "ResM (Research Masters)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Masters in Business Administration (MBA)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Migration and Refugee Law",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "LLM (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Musculoskeletal (MSK) Practice",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MBA",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics & International Relations",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics Engineering",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MSW",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Socio-cultural Disability Studies",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Special Educational Needs and Inclusion Studies",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Medicine, Exercise and Health",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology, Philosophy & Religion (by Research)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Transnational Crime",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "ResM (Research Masters)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Youth Work and Community Development",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MA",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Mentoring and Coaching",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Musculoskeletal (MSK) Practice",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Developmental Psychology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Sustainability",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognition and Aesthetics",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary Performance",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "MA",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Music Practice",
                    "university": "Liverpool Hope University",
                    "location": "Creative Campus",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dance (Professional Practice)",
                    "university": "Liverpool Hope University",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Disability and Interdisciplinary Professional Practice",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Disability Studies",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MA",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Childhood",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecology & Environmental Management",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MA",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Leadership and Management",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature (MA by Research)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MA",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Criminology and Security Studies",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Cyber Law and Policy (LLM by Research)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "LLM (Res)",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Terrorism and Extremism",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "ResM (Research Masters)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Masters in Business Administration (MBA)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Migration and Refugee Law",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "LLM (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Musculoskeletal (MSK) Practice",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MBA",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics & International Relations",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MA",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MSc",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MA",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Socio-cultural Disability Studies",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MA",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Special Educational Needs and Inclusion Studies",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Medicine, Exercise and Health",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology, Philosophy & Religion (by Research)",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MA",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Transnational Crime",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "ResM (Research Masters)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Understanding Autism & Inclusive Learning",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Youth Work and Community Development",
                    "university": "Liverpool Hope University",
                    "location": "Hope Park",
                    "qualification": "MA",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "cd26ecbb4d4e1869b938d709e7bee82d",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "3f8e874038f61848017c55aa74c4a5a3",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "0e9276342704549e7f69f7fc663d44b2",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "c1590be3172f19888458b9b87b367b44",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Actuarial Mathematics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Actuarial Mathematics with a Year Abroad",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering with Pilot Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering with Pilot Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering with Pilot Studies with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering with Pilot Studies with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anatomy and Human Biology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MBiol (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anatomy and Human Biology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History and Archaeology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History with Catalan",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History with Chinese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History with French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History with German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History with Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History with Portuguese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History with Spanish",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology (Arts)",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology (Science)",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology of Ancient Civilisations",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology with Catalan",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology with Catalan",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology with Chinese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology with Chinese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology with French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology with French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology with German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology with German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology with Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology with Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology with Portuguese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology with Portuguese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology with Spanish",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology with Spanish",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Engineering",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Engineering",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture (Design Studies)",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astrophysics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astrophysics with a Year Abroad",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MPhys (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MBiol (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MBiol (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences Foundation (1+3)",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MBiol (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioveterinary Science",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MBiol (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioveterinary Science",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Hispanic Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business with Catalan",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business with Chinese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business with French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business with German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business with Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business with Portuguese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business with Spanish",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Sciences (Foundation) (1+3)",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with a Year Abroad",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with a Year Abroad",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Research in Industry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and Ancient History",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and Archaeology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and Business",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and Classical Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and Classics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and Communication and Media",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and Criminology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and Economics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and Egyptology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and English",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and English Language",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and English Literature",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and Film Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and Hispanic Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and History",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and International Relations",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and Irish Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and Italian Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and Law",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and Music",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and Music Technology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and Philosophy",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and Politics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and Popular Music",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and Social Policy",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and Sociology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil and Structural Engineering",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies and Archaeology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies with Catalan",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies with Chinese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies with French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies with German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies with Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies with Portuguese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies with Spanish",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Archaeology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics with Catalan",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics with Chinese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics with French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics with German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics with Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics with Portuguese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics with Spanish",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate Science",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication and Business",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication and Media",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication and Media and Game Design Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication and Media and Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication and Media with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication and Media with Catalan",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication and Media with Chinese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication and Media with French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication and Media with German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication and Media with Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication and Media with Portuguese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication and Media with Spanish",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication, Media and Politics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication, Media and Popular Music",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Foundation) (1+3)",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (with a Year in Industry)",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Electronic Engineering",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Electronic Engineering",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Electronic Engineering with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Software Development",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Software Development with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology with Catalan",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology with Chinese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology with French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology with German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology with Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology with Portuguese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology with Social Policy",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology with Sociology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology with Spanish",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dental Surgery",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BDS",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dental Therapy",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diagnostic Radiography",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth Sciences (Foundation) (1+3)",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Catalan",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Chinese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Portuguese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Spanish",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Egyptology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Egyptology and Ancient History",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Egyptology and Archaeology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Egyptology and Classics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Egyptology with Catalan",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Egyptology with Chinese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Egyptology with French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Egyptology with German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Egyptology with Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Egyptology with Portuguese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Egyptology with Spanish",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering with a Year Abroad",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering with a Year Abroad",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Engineering and Electronics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Engineering and Electronics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Engineering and Electronics with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Engineering and Electronics with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Foundation",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Business Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Communication and Media",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Hispanic Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and History",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Philosophy",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Communication and Media",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Hispanic Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Italian Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Communication and Media",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Hispanic Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and History",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Italian Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Philosophy",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature with Drama Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English with Catalan",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English with Chinese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English with French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English with German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English with Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English with Portuguese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English with Spanish",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English with World Literature",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment and Planning",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Geoscience",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Evolutionary Anthropology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies and Communication and Media",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies and English",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies with Catalan",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies with Chinese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies with French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies with German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies with Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies with Portuguese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies with Spanish",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Data Analytics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Data Analytics with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Computing",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Computing with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Foundation to Human and Animal Health Professions (Dental Therapy) (Year 0)",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Foundation to Human and Animal Health Professions (Dentistry) (Year 0)",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BDS",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Foundation to Human and Animal Health Professions (Diagnostic Radiography) (Year 0)",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Foundation to Human and Animal Health Professions (Medicine) (Year 0)",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MB ChB",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Foundation to Human and Animal Health Professions (Nursing) (Year 0)",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BN (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Foundation to Human and Animal Health Professions (Occupational Therapy) (Year 0)",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Foundation to Human and Animal Health Professions (Orthoptics) (Year 0)",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Foundation to Human and Animal Health Professions (Physiotherapy) (Year 0)",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Foundation to Human and Animal Health Professions (Therapeutic Radiotherapy and Oncology) (Year 0)",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Foundation to Human and Animal Health Professions (Veterinary Science) (Year 0)",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BVSc",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Ancient History",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Archaeology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Classical Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Classics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Communication and Media",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Criminology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Economics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Egyptology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Film Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Hispanic Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French with Catalan",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French with Chinese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French with German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French with Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French with Portuguese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French with Spanish",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Game Design",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography (Foundation) (1+3)",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography (Science)",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography and Oceanography",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography and Planning",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MESci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology and Geophysics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology with Physical Geography",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Ancient History",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Archaeology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Classical Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Classics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Communication and Media",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Criminology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Economics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Egyptology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Film Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Hispanic Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German with Catalan",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German with Chinese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German with French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German with Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German with Portuguese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German with Spanish",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies (Spanish, Catalan, Portuguese, Latin American Studies)",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies and Ancient History",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies and Archaeology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies and Classical Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies and Classics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies and Communication and Media",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies and Criminology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies and Economics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies and Egyptology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies and Film Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies and Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies with Chinese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies with French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies with German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies with Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Ancient History",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Archaeology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Criminology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Film Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Hispanic Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Politics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History with Catalan",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History with Chinese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History with French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History with German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History with Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History with Portuguese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History with Spanish",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Hispanic Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations with Catalan",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations with Chinese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations with French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations with German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations with Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations with Portuguese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations with Spanish",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Irish Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Irish Studies and French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Irish Studies and German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Irish Studies and Hispanic Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Irish Studies with Catalan",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Irish Studies with Chinese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Irish Studies with French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Irish Studies with German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Irish Studies with Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Irish Studies with Portuguese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Irish Studies with Spanish",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Ancient History",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Archaeology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Classical Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Classics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Criminology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Economics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Egyptology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and English",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Film Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and International Relations",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Irish Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian with Catalan",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian with Chinese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian with French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian with German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian with Portuguese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian with Spanish",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Business",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Criminology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Hispanic Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and International Relations",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with a Year Abroad",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Business",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Catalan",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Chinese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Philosophy",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Politics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Portuguese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Spanish",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Biology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Biology with Oceanography",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical Physics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MMath",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical Physics with a Year Abroad",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MMath (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical Sciences (Foundation) (1+3)",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MMath",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Computer Science",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Computer Science with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Economics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Music Technology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Philosophy",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Statistics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Statistics with a Year Abroad",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with a Year Abroad",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MMath (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with a Year Abroad",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Finance",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Finance with a Year Abroad",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Languages",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Ocean and Climate Sciences",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronics and Robotic Systems",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronics and Robotic Systems",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronics and Robotic Systems with a Year Abroad",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronics and Robotic Systems with a Year Abroad",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronics and Robotic Systems with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronics and Robotic Systems with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Culture",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media, Data and Society",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicinal Chemistry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicinal Chemistry with a Year Abroad",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicinal Chemistry with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicinal Chemistry with Pharmacology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicinal Chemistry with Pharmacology with a Year Abroad",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MB ChB",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine (Graduate Entry)",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MB ChB",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology and Infection",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology and Infection",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MBiol (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages (Triple Subject)",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Hispanic Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Technology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Technology with Catalan",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Technology with Chinese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Technology with French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Technology with German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Technology with Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Technology with Portuguese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Technology with Spanish",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Technology and French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Technology and German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Technology and Hispanic Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Technology and Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music with Catalan",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music with Chinese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music with French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music with German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music with Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music with Portuguese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music with Spanish",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music/Popular Music",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BN (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ocean Sciences",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Orthoptics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MBiol (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MBiol (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Hispanic Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and History",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Politics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy with Catalan",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy with Chinese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy with French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy with German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy with Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy with Portuguese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy with Spanish",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy, Politics and Economics with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy, Politics, and Economics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Sciences (Foundation) (1+3)",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics and Mathematics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics and Mathematics with a Year Abroad",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with a Year Abroad",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with a Year Abroad",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astronomy",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astronomy with a Year Abroad",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Geophysics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Medical Applications",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Medical Applications with a Year Abroad",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Nuclear Science",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Nuclear Science with a Year Abroad",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Economics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Film Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Hispanic Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics with Catalan",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics with Chinese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics with French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics with German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics with Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics with Portuguese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics with Spanish",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Popular Music",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Popular Music and French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Popular Music and German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Popular Music and Hispanic Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Popular Music and Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Popular Music with Catalan",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Popular Music with Chinese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Popular Music with French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Popular Music with German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Popular Music with Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Popular Music with Portuguese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Popular Music with Spanish",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design Engineering",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design Engineering",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design Engineering with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design Engineering with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (2+2 programme with Foundation Element)",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Screen Industries and Entertainment",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy and Criminology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy and French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy and German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy and Hispanic Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy and Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Business Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and English",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Hispanic Studies",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and History",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Politics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Social Policy",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology with Catalan",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology with Chinese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology with Criminology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology with French",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology with German",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology with Italian",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology with Portuguese",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology with Social Policy",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology with Spanish",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics with a Year Abroad",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MPhys (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Therapeutic Radiography and Oncology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Town and Regional Planning",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MPlan",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Planning",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Science",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BVSc",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MBiol (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Adult Nursing with Registered Nurse Status",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "27 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Aerospace Engineering",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc (Eng)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Architecture",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Biological Sciences",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Manufacturing Systems and Technology",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc (Eng)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Marketing",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Mechanical Engineering",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc (Eng)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "AI for Digital Business",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Linguistics and Teaching English to Speakers of Other Languages",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archives",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archives and Records Management (Digital Pathway) - MARM",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archives and Records Management (International Pathway) - MARMI",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archives and Records Management - MARM",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art, Philosophy and Cultural Institutions",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Basque Studies - Modern Languages and Cultures",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Big Data and High Performance Computing",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Big Data and High Performance Computing with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioinformatics",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering (Healthcare)",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering with Management",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering with Management (Healthcare)",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences and Translational Medicine",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Building Information Modelling and Digital Transformation (BIM-DT)",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics and Big Data",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer Biology and Therapy",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Catalan Studies - Modern Languages and Cultures",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies - Modern Languages and Cultures",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese-English Translation and Interpreting",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Ancient History",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Ancient History",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate Resilience and Environmental Sustainability in Architecture (CRESTA)",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Sciences",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication and Media",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative and Critical Writing",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminological Research",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Artificial Intelligence",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Artificial Intelligence with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Communication",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science for Economics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diagnostic Radiography (Pre-registration)",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Chemistry: AI, Machine Learning, Automation and Robotics",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing and Analytics",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media, Data and Society",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Doctor of Clinical Psychology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "DClinPsychol",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic Policy and Data Analytics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Egyptology",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Egyptology",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Emerging Infections and Pandemics",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Endodontics",
                    "university": "University of Liverpool",
                    "location": "Online",
                    "qualification": "DDSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy and Power Systems",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy and Power Systems with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English: Modern and Contemporary Literature",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English: Renaissance and Eighteenth-Century Literature",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English: Science Fiction Studies",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English: Victorian Literature",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Entrepreneurship and Innovation Management",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment and Climate Change",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Assessment and Management",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Sciences",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies - Modern Languages and Cultures",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Investment Management",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Mathematics",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Technology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Football Industries",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French Studies - Modern Languages and Cultures",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geographic Data Science",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German Studies - Modern Languages and Cultures",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Healthcare Ethics",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Media and Politics",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Green Finance and Sustainability Accounting",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Data Science",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health, Cultures and Societies",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies - Modern Languages and Cultures",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History (Cultural History)",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History (Eighteenth-Century Worlds)",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History (Medieval and Renaissance Studies)",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History (Twentieth-Century History)",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Housing and Community Planning",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Evolution",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management (CIPD Accredited)",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Infection and Immunity",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Commercial Law",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Rights Law",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Security",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Security",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Slavery Studies",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Investigative and Forensic Psychology",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian Studies - Modern Languages and Cultures",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (General) LLM",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law, Medicine and Healthcare LLM",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master in Management",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MIM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Public Health",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MPH",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical Sciences",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA (The Liverpool MBA) on campus",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering Design with Management",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Management",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media, Culture and Everyday Life",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health Nursing with Registered Nurse Status",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microelectronic Systems",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microelectronic Systems with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc (Eng)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and Cultures",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Money and Banking",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Audiovisual Media",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Industry Studies",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Management",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Performance and Education",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MMus",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational and Organisational Psychology",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Therapy (Pre-registration)",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Operations and Supply Chain Management",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Orthodontics",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "DDSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Orthoptics (Pre-registration)",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paediatric Dentistry",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "DDSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Palaeoanthropology",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Palliative and End of Life Care",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology and Toxicology",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy (Public Policy)",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy (Pre-registration)",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Science and International Relations",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese Studies - Modern Languages and Cultures",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design and Management",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc (Eng)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Conversion)",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Radiometrics: Instrumentation and Modelling",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Research Methods in Psychology",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Researching Crisis and Change in Human Geography",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research Methods",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociolinguistics - Modern Languages and Cultures",
                    "university": "University of Liverpool",
                    "location": "No Locations",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish Studies - Modern Languages and Cultures",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Communication",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Civil and Structural Engineering",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc (Eng)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Heritage Management",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Urban Planning",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Urban Planning",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MCD",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching of English to Speakers of Other Languages (TESOL)",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Telecommunications and Wireless Systems",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc (Eng)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Telecommunications and Wireless Systems with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc (Eng)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Computer Science with a Year in Industry",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Therapeutic Radiography and Oncology (Pre-registration)",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "27 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation Studies - Modern Languages and Cultures",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Design and Planning",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MCD",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Physiotherapy",
                    "university": "University of Liverpool",
                    "location": "Leahurst Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Computer Science",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Practice in Healthcare",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "AI for Digital Business",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archives",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archives and Records Management (Digital Pathway) - MARM",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archives and Records Management (International Pathway) - MARMI",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archives and Records Management - MARM",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "PgDip",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art, Philosophy and Cultural Institutions",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Basque Studies - Modern Languages and Cultures",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Big Data and High Performance Computing",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioinformatics",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bovine Reproduction DBR",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Building Information Modelling and Digital Transformation (BIM-DT)",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics and Big Data",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Catalan Studies - Modern Languages and Cultures",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies - Modern Languages and Cultures",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese-English Translation and Interpreting",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Ancient History",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Ancient History",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Anatomy with Education",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "8 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Sciences",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication and Media",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative and Critical Writing",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminological Research",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Communication",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media, Data and Society",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Egyptology",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Egyptology",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English: Modern and Contemporary Literature",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English: Renaissance and Eighteenth-Century Literature",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English: Science Fiction Studies",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English: Victorian Literature",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Assessment and Management",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies - Modern Languages and Cultures",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Technology",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French Studies - Modern Languages and Cultures",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geographic Data Science",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German Studies - Modern Languages and Cultures",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Healthcare Ethics",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Media and Politics",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Data Science",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health, Cultures and Societies",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies - Modern Languages and Cultures",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History (Cultural History)",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History (Eighteenth-Century Worlds)",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History (Medieval and Renaissance Studies)",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History (Twentieth-Century History)",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Housing and Community Planning",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Evolution",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Rights Law",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Security",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Security",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Slavery Studies",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian Studies - Modern Languages and Cultures",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (General) LLM",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law, Medicine and Healthcare LLM",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Public Health",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MPH",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical Sciences",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA (The Liverpool MBA) on campus",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media, Culture and Everyday Life",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microelectronic Systems",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and Cultures",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Industry Studies",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Operations and Supply Chain Management",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Palaeoanthropology",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Palliative and End of Life Care",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology and Toxicology",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy (Public Policy)",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Science and International Relations",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese Studies - Modern Languages and Cultures",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Doctorate in Health",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "DProf",
                    "duration": "56 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Radiometrics: Instrumentation and Modelling",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Radiotherapy",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Researching Crisis and Change in Human Geography",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research Methods",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociolinguistics - Modern Languages and Cultures",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish Studies - Modern Languages and Cultures",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Communication",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Civil and Structural Engineering",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc (Eng)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Heritage Management",
                    "university": "University of Liverpool",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Urban Planning",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Urban Planning",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MCD",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Telecommunications and Wireless Systems",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MSc (Eng)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation Studies - Modern Languages and Cultures",
                    "university": "University of Liverpool",
                    "location": "City Centre Campus",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Business Management",
                    "university": "University of Liverpool",
                    "location": "Online",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Physiotherapy",
                    "university": "University of Liverpool",
                    "location": "Leahurst Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "401697a5241829780b53e82214825107",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Acting and Performance-",
                    "university": "LMA",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "BA (Hons) Business and Management for the Creative Industries",
                    "university": "LMA",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "BA (Hons) Music Production",
                    "university": "LMA",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Dance Performance",
                    "university": "LMA",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Digital Film & TV Production",
                    "university": "LMA",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Digital Games Art",
                    "university": "LMA",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Music Performance & Industry",
                    "university": "LMA",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Musical Theatre",
                    "university": "LMA",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Creative Practice - Acting",
                    "university": "LMA",
                    "location": "Multiple Locations",
                    "qualification": "MA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Practice - Dance",
                    "university": "LMA",
                    "location": "Multiple Locations",
                    "qualification": "MA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Practice - Film & TV",
                    "university": "LMA",
                    "location": "Multiple Locations",
                    "qualification": "MA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Practice - Games & Concept Art",
                    "university": "LMA",
                    "location": "Multiple Locations",
                    "qualification": "MA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Practice - Music",
                    "university": "LMA",
                    "location": "Multiple Locations",
                    "qualification": "MA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Practice - Musical Theatre",
                    "university": "LMA",
                    "location": "Multiple Locations",
                    "qualification": "MA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        }
    },
    {
        "id": "90969d9a3acbfe3c2d67ac7bb646f9de",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Global Masters in Management",
                    "university": "London Business School, University of London",
                    "location": "London Business School",
                    "qualification": "MIM",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "LBS Sloan Masters in Leadership and Strategy",
                    "university": "London Business School, University of London",
                    "location": "London Business School",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Masters in Analytics and Management",
                    "university": "London Business School, University of London",
                    "location": "London Business School",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Masters in Finance",
                    "university": "London Business School, University of London",
                    "location": "London Business School",
                    "qualification": "MFin",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Masters in Financial Analysis",
                    "university": "London Business School, University of London",
                    "location": "London Business School",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Masters in Management",
                    "university": "London Business School, University of London",
                    "location": "London Business School",
                    "qualification": "MIM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA (Masters of Business Administration)",
                    "university": "London Business School, University of London",
                    "location": "London Business School",
                    "qualification": "MBA",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "One-year MBA",
                    "university": "London Business School, University of London",
                    "location": "London Business School",
                    "qualification": "MBA",
                    "duration": "11 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Executive MBA (Masters of Business Administration)",
                    "university": "London Business School, University of London",
                    "location": "Multiple Locations",
                    "qualification": "MBA (Executive)",
                    "duration": "20 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global-EMBA (Executive Masters of Business Administration)",
                    "university": "London Business School, University of London",
                    "location": "London Business School",
                    "qualification": "MBA (Executive)",
                    "duration": "20 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Masters in Finance",
                    "university": "London Business School, University of London",
                    "location": "London Business School",
                    "qualification": "MFin",
                    "duration": "22 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "80f05ab2193c13fb5423f947af79ba3f",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "b8f0ed4d513eecb71f23fd95c124db56",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "BSc (Hons) Business Management with Foundation Year",
                    "university": "London College of Business Studies",
                    "location": "Ilford",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "HNC Business",
                    "university": "London College of Business Studies",
                    "location": "Ilford",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "15589f2689d2a1326311b009638502cc",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "40e223bd85532c2c8658ac543742e930",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "2947021cf336a6e43759bb2830658d80",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Filmmaking",
                    "university": "London Film School",
                    "location": "London Film School",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Film Business",
                    "university": "London Film School",
                    "location": "London Film School",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Screenwriting",
                    "university": "London Film School",
                    "location": "London Film School",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        }
    },
    {
        "id": "5cfd86a64427ed3c413c2aaa40f776f1",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Accounting and Finance (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Accounting and Financial Services (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advertising, Marketing Communications and Public Relations",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Airline, Airport and Aviation Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Airline, Airport and Aviation Management (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Architectural Technology",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Architecture",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Architecture (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Architecture (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Design (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Artificial Intelligence and Robotics",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BEng",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Artificial Intelligence and Robotics (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Banking and Finance",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Banking and Finance (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biological Science",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BEng",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Biomedical Engineering (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biomedical Science (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Business Computer Systems (top-up)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business Management (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Business Management (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and Human Resource Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business Management and Marketing",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business Management and Marketing (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Chemistry",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Networking and Cloud Security",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Computer Networking and Cloud Security (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Computer Networking and Cyber Security",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Computer Networking and Cyber Security (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Computer Science",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computer Science (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Computer Systems Engineering and Robotics",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Computer Systems Engineering and Robotics (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Computing",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Computing (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Construction Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Creative Writing and English Literature",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Creative Writing and English Literature (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Criminology",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Criminology and International Security",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology and Law",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology and Policing",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology and Psychology",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Cyber Security and Forensic Computing (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Data Science (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Dietetics",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Dietetics and Nutrition (4 years)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Digital Forensics and Cyber Security",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Digital Forensics and Cyber Security (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Digital Media",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Digital Media (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Early Childhood Studies (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Early Childhood Studies (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Economics (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Economics, Finance and International Business",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Education",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Electronic Engineering and Internet of Things",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Electronic Engineering and Internet of Things (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Events Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Events Management (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Fashion",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fashion (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Fashion (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Marketing and Business Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Fashion Marketing and Journalism",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fashion Marketing and Journalism (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Fashion Photography",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fashion Photography (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Fashion Photography (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Textiles",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fashion Textiles (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Film and Television Production",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Film and Television Production (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Film and Television Studies",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Film and Television Studies (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Fine Art",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fine Art (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Fine Art (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Forensic Science (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Games Animation, Modelling and Effects",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Games Animation, Modelling and Effects (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Games Programming",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Games Programming (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Graphic Design (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Graphic Design (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Health and Social Care (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Health and Social Care (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Herbal Medicinal Science (Top-Up)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Nutrition",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Human Nutrition (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Illustration and Animation",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Illustration and Animation (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Illustration and Animation (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Architecture and Design",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Interior Architecture and Design (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Interior Architecture and Design (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Design",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Interior Design (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Interior Design (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Interior Design and Decoration",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Interior Design and Decoration (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Interior Design and Decoration (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "International Business Management (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "International Business Management (Top up)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "International Relations (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "International Relations and Politics",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Journalism",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Journalism (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Journalism, Film and Television Studies",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Journalism, Film and Television Studies (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Law",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Law (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Law (with International Relations)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Leadership in Communities",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "LLB (Criminal Law)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "LLB Law",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Marketing",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Marketing (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Mathematical Sciences",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Mathematical Sciences (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Mathematics",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Mathematics (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Media and Communications",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Media and Communications (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Media and Marketing",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Medical Bioscience",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Music Business",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Music Business (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Music Technology and Production",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Music Technology and Production (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "114"
                },
                {
                    "course_name": "Pharmaceutical Science",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Pharmaceutical Science",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Pharmaceutical Science (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Pharmacology (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Photography",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Photography (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Photography (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Primary Education (two-year accelerated degree)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Product and Furniture Design",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Product and Furniture Design (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Psychology",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Psychology (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Psychology and Sociology",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Quantity Surveying and Commercial Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Real Estate",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Social Work",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Social Work (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Social Work (Postgraduate Entry)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sociology (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Software Engineering (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sport and Exercise Science (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Sport Psychology and Coaching",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Sport Psychology and Coaching (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Sports Therapy",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sports Therapy (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Textiles",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Textiles (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Textiles (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and Film",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Theatre and Film (Top-Up)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and Performance",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Theatre and Performance (Top-Up)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Tourism and Travel Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Tourism and Travel Management (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Tourism and Travel Management (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Youth Studies",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Accounting and Finance (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA",
                    "duration": "5 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Accounting and Financial Services (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advertising, Marketing Communications and Public Relations",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Airline, Airport and Aviation Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Airline, Airport and Aviation Management (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Architectural Technology",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Architecture",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Architecture (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Architecture (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Design (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Artificial Intelligence and Robotics",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BEng",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Artificial Intelligence and Robotics (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BEng",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Banking and Finance",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Banking and Finance (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "5 to 7 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biological Science",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BEng",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Biomedical Engineering (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BEng",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biomedical Science (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Business Computer Systems (top-up)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business Management (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Business Management (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and Human Resource Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business Management and Marketing",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business Management and Marketing (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Chemistry",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Networking and Cloud Security",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Computer Networking and Cloud Security (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BEng (Hon)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Computer Networking and Cyber Security",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Computer Networking and Cyber Security (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Computer Science",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computer Science (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Computer Systems Engineering and Robotics",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Computer Systems Engineering and Robotics (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BEng (Hon)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Computing",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Computing (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Construction Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Creative Writing and English Literature",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Creative Writing and English Literature (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Criminology",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology and International Security",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology and Law",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology and Policing",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology and Psychology",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Cyber Security and Forensic Computing (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Data Science (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Digital Forensics and Cyber Security",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Digital Forensics and Cyber Security (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Digital Media",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Digital Media (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Early Childhood Studies (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Economics (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "5 to 7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 to 6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Economics, Finance and International Business",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Education",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering and Internet of Things",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BEng (Hon)",
                    "duration": "3 to 6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Electronic Engineering and Internet of Things (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BEng (Hon)",
                    "duration": "5 to 8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Events Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Events Management (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Fashion",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fashion (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Fashion (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Marketing and Business Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Fashion Marketing and Journalism",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fashion Marketing and Journalism (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Fashion Photography",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA",
                    "duration": "6 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fashion Photography (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Fashion Photography (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Textiles",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fashion Textiles (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Film and Television Production",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Film and Television Production (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Film and Television Studies",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Film and Television Studies (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Fine Art",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fine Art (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Fine Art (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Forensic Science (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Games Animation, Modelling and Effects",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Games Animation, Modelling and Effects (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Games Programming",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Games Programming (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Graphic Design (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Graphic Design (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Health and Social Care (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Health and Social Care (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Herbal Medicinal Science (Top-Up)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Nutrition",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Human Nutrition (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Illustration and Animation",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Illustration and Animation (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Illustration and Animation (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Architecture and Design",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Interior Architecture and Design (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Interior Architecture and Design (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Design",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Interior Design (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Interior Design (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Interior Design and Decoration",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Interior Design and Decoration (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Interior Design and Decoration (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "International Business Management (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "International Business Management (Top up)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "International Relations and Politics",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Journalism",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Journalism (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Journalism, Film and Television Studies",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Journalism, Film and Television Studies (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Law",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Law (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Law (with International Relations)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Leadership in Communities",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 to 6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "LLB (Criminal Law)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "LLB Law",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Marketing",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Marketing (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Mathematical Sciences",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Mathematical Sciences (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Mathematics",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Mathematics (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Media and Communications",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Media and Communications (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Media and Marketing",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Medical Bioscience",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Music Business",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Music Business (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 to 6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Music Technology and Production",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BSc",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Music Technology and Production (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Pharmaceutical Science",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSci",
                    "duration": "8 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Pharmaceutical Science",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Pharmaceutical Science (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Pharmacology (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Photography",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Photography (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Photography (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product and Furniture Design",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Product and Furniture Design (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Psychology",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Psychology and Sociology",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Quantity Surveying and Commercial Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Real Estate",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sociology",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Software Engineering (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BEng (Hon)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sport and Exercise Science (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Sport Psychology and Coaching",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Sport Psychology and Coaching (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Sports Therapy",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sports Therapy (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Textiles",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Textiles (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Textiles (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "BA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and Film",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Theatre and Film (Top-Up)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and Performance (Top-Up)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Tourism and Travel Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Tourism and Travel Management (including foundation year)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Tourism and Travel Management (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Youth Studies",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Addiction and Mental Health",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Airline and Airport Corporate Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anti-Bribery and Anti-Corruption Law and Compliance",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "AdvPgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arabic Language Teaching",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture RIBA 2 MArch",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "MArch",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Blood Science",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer Immunotherapy",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Child and Adolescent Mental Health",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with Project Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Networking and Cyber Security",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Networking and Cyber Security with Work Experience",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conference Interpreting",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conference Interpreting",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Project Management MSc",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Corporate Social Responsibility and Sustainability",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Psychopathology",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cryptography",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security MSc",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Analytics",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dietetics and Nutrition",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Doctor of Business Administration",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "Doctoral Programme",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Documentary Film Production",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language Teaching",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental, Sustainable and Regeneration Design MA",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Executive MBA",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "Executive Master's",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Marketing and Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Arts",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "MFA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Science",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Furniture Design",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Human Resource Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Human Resource Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graduate Diploma in Law",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "Grad Dip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Social Care Leadership and Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Nutrition",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Rights and International Conflict",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Rights Law",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Design",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Accounting and Finance",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Banking and Finance",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management with Project Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Events, Leisure and Tourism Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Trade and Finance",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interpreting",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interpreting",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Jewellery and Silversmithing",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration - MBA",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Organised Crime and Global Security",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Peace, Conflict and Diplomacy",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Science and Drug Delivery Systems",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy (Pre-registration)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management MSc",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Conversion)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology of Mental Health",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Art and Performative Practices",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantity Surveying",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics with Artificial Intelligence",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialised Translation",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Therapy",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Textile Design",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation Technology",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Communication: Graphic Design",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Communication: Illustration",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Woman and Child Abuse",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Youth and Community Work (Advanced Practice)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Youth and Community Work (with JNC Recognition)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Addiction and Mental Health",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time day and evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Airline and Airport Corporate Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anti-Bribery and Anti-Corruption Law and Compliance",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "AdvPgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arabic Language Teaching",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture: Professional Practice in Architecture (RIBA 3)",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "MA",
                    "duration": "17 weeks",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Blood Science",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer Immunotherapy",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Child and Adolescent Mental Health",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time day and evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with Project Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Networking and Cyber Security",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Networking and Cyber Security with Work Experience",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "36 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conference Interpreting",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conference Interpreting",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Project Management MSc",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Corporate Social Responsibility and Sustainability",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Crime, Policy and Security",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "Professional Doctorate",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Psychopathology",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cryptography",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security MSc",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Analytics",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Doctor of Business Administration",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "Doctoral Programme",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Documentary Film Production",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language Teaching",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time day/evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental, Sustainable and Regeneration Design MA",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Executive MBA",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "Executive Master's",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Marketing and Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Arts",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "MFA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Science",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Furniture Design",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Human Resource Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Human Resource Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "PgDip",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graduate Diploma in Law",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "Grad Dip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Social Care Leadership and Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Nutrition",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Rights and International Conflict",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Rights Law",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "PgCert",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Design",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Accounting and Finance",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Banking and Finance",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time day and evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management with Project Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Events, Leisure and Tourism Management",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Trade and Finance",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interpreting",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interpreting",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Jewellery and Silversmithing",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "LLM Legal Practice (Top-up)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time day/evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time day and evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration - MBA",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Organised Crime and Global Security",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Peace, Conflict and Diplomacy",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Science and Drug Delivery Systems",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management MSc",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Conversion)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Art and Performative Practices",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantity Surveying",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics with Artificial Intelligence",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialised Translation",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Therapy",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Textile Design",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation Technology",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Communication: Graphic Design",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Communication: Illustration",
                    "university": "London Metropolitan University",
                    "location": "Aldgate",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Woman and Child Abuse",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Youth and Community Work (Advanced Practice)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Youth and Community Work (with JNC Recognition)",
                    "university": "London Metropolitan University",
                    "location": "Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "e523f2de9c7f2faa36cd0e544669ca56",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "8a452bd2c13b5f8b6ceaeb7c1afa4064",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "560b990cf62a099db7d74b70c7445c45",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Business Management",
                    "university": "London School of Management Education",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration",
                    "university": "London School of Management Education",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Education",
                    "university": "London School of Management Education",
                    "location": "Main Site",
                    "qualification": "MEd",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        }
    },
    {
        "id": "6a00632847eeb6df2f9f069cb502bbe7",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "22f18b1e3f47e1401617ad253f7a5b7f",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "16d90a3232c514a5c2ca6f2d90bad505",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "2c1d17cf8ba3d1828687c1b89fea51f9",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Applied Sports Science",
                    "university": "Loughborough College",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "BA (Hons) Business and Management",
                    "university": "Loughborough College",
                    "location": "Loughborough College",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "BA (Hons) Events Management and Marketing",
                    "university": "Loughborough College",
                    "location": "Loughborough College",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "BSC (Hons) Psychology and Criminology",
                    "university": "Loughborough College",
                    "location": "Loughborough College",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business (Top Up)  Blended Learning",
                    "university": "Loughborough College",
                    "location": "Loughborough College",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Blended Learning",
                    "university": "Loughborough College",
                    "location": "Loughborough College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Engineering (Electrical/Electronic)",
                    "university": "Loughborough College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Engineering (Mechanical)",
                    "university": "Loughborough College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Public Services",
                    "university": "Loughborough College",
                    "location": "Loughborough College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Sport",
                    "university": "Loughborough College",
                    "location": "Loughborough College",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport",
                    "university": "Loughborough College",
                    "location": "Loughborough College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sport & Exercise Science - Coaching Science Pathway Top Up   (Blended Learning)",
                    "university": "Loughborough College",
                    "location": "Loughborough College",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport & Exercise Science - General Pathway  Top Up (Blended Learning)",
                    "university": "Loughborough College",
                    "location": "Loughborough College",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Science (Blended Learning)",
                    "university": "Loughborough College",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sport and Exercise Therapy",
                    "university": "Loughborough College",
                    "location": "Loughborough College",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport Coaching with Development (Blended Learning)",
                    "university": "Loughborough College",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Coaching",
                    "university": "Loughborough College",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sports Management",
                    "university": "Loughborough College",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sports Management",
                    "university": "Loughborough College",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Strength and Conditioning",
                    "university": "Loughborough College",
                    "location": "Loughborough College",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sustainable Engineering",
                    "university": "Loughborough College",
                    "location": "Main Site",
                    "qualification": "BEng",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Children & Young People",
                    "university": "Loughborough College",
                    "location": "Main Site",
                    "qualification": "FD",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Education Studies (Top Up)",
                    "university": "Loughborough College",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Electrical/Electronic)",
                    "university": "Loughborough College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Engineering (General)",
                    "university": "Loughborough College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Engineering (Mechanical)",
                    "university": "Loughborough College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "5a82e17df01d9c3a32ac9cd67fd99097",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aeronautical Engineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aeronautical Engineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aeronautical Engineering (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aeronautical Engineering (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aeronautical Engineering with a Foundation Year",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Engineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Engineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Engineering (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Engineering (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BArch (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive Engineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive Engineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive Engineering (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive Engineering (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive Engineering with a Foundation Year",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive Materials",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive Materials",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive Materials (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive Materials (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioengineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioengineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioengineering (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioengineering (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioengineering with a Foundation Year",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences with a Foundation Year",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomaterials Engineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomaterials Engineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomaterials Engineering (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomaterials Engineering (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business with a Foundation Year (Elite Athlete Pathway)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with Foundation Year",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with a Foundation Year",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Computing",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-144"
                },
                {
                    "course_name": "Chemistry with Computing",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Chemistry with Computing (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-144"
                },
                {
                    "course_name": "Chemistry with Computing (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering and Architectural Engineering with a Foundation Year",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Commercial Management and Quantity Surveying",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Commercial Management and Quantity Surveying (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer and Electronic Engineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-168"
                },
                {
                    "course_name": "Computer and Electronic Engineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-152"
                },
                {
                    "course_name": "Computer and Electronic Engineering (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-168"
                },
                {
                    "course_name": "Computer and Electronic Engineering (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-152"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Artificial Intelligence",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Artificial Intelligence",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Artificial Intelligence (with Placement Year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Artificial Intelligence (with Placement Year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Mathematics",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Mathematics",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Mathematics (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Mathematics (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with a Foundation Year",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing and Management",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing and Management",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing and Management (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing and Management (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Engineering Management",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Engineering Management (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Sociology (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Design (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Economics",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Management",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Management (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with a Foundation Year (Elite Athlete Pathway)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-168"
                },
                {
                    "course_name": "Electrical and Electronic Engineering (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-168"
                },
                {
                    "course_name": "Electrical and Electronic Engineering (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering (with a Foundation Year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Engineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-168"
                },
                {
                    "course_name": "Energy Engineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-152"
                },
                {
                    "course_name": "Energy Engineering (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-168"
                },
                {
                    "course_name": "Energy Engineering (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-168"
                },
                {
                    "course_name": "Engineering Management",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Management (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Management with a Foundation Year",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Physics",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Physics",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Physics (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Physics (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Sport Science",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Sport Science (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-136"
                },
                {
                    "course_name": "English Literature (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-136"
                },
                {
                    "course_name": "English with Business Studies",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English with Business Studies (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English with Creative Writing",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English with Creative Writing (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Design and Technology",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Design and Technology (with Placement Year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Mathematics",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Mathematics (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography and Management",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography and Management (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography and Sport Science",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography and Sport Science (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with a Foundation Year",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Geography with Economics",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with Economics (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Design (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and International Relations",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and International Relations (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Politics",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Politics (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Biology",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Biology (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Biology with a Foundation Year",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Industrial Design",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Industrial Design (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Technology Management for Business (ITMB)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Technology Management for Business (ITMB)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Technology Management for Business (ITMB) (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Technology Management for Business (ITMB) (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Liberal Arts",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Liberal Arts (with Placement Year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Management (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Engineering with Foundation Year",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science and Engineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science and Engineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science and Engineering (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science and Engineering (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MMath",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MMath",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Accounting and Financial Management",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Accounting and Financial Management (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Physics",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Physics",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Physics (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Physics (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Sport Science",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Sport Science (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with a Foundation Year",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Economics",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Economics (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Statistics",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Statistics (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with a Foundation Year",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communication",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communication (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicinal and Pharmaceutical Chemistry",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicinal and Pharmaceutical Chemistry",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicinal and Pharmaceutical Chemistry (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicinal and Pharmaceutical Chemistry (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Sciences",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Sciences",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Sciences (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Sciences (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Sciences with a Foundation Year",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with a Foundation Year",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Computing",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Computing",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Computing (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Computing (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Theoretical Physics",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Theoretical Physics",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Theoretical Physics (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Theoretical Physics (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, Philosophy and Economics",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, Philosophy and Economics (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design and Technology",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design and Technology (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design and Technology with a Foundation Year",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Product Design Engineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design Engineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design Engineering (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design Engineering (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design Engineering with a Foundation Year",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology in Education",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology in Education (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with a Foundation Year (Elite Athlete Pathway)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with Criminology",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with Criminology (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics, Mechatronics and Control Engineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-152"
                },
                {
                    "course_name": "Robotics, Mechatronics and Control Engineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-168"
                },
                {
                    "course_name": "Robotics, Mechatronics and Control Engineering (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-168"
                },
                {
                    "course_name": "Robotics, Mechatronics and Control Engineering (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-152"
                },
                {
                    "course_name": "Social Sciences and Humanities with a Foundation Year",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Psychology",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Psychology (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Science (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Management",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Management (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Science, Coaching and Physical Education",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Science, Coaching and Physical Education (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport with a Foundation Year (Elite Athlete Pathway)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Technology",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Technology",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-152"
                },
                {
                    "course_name": "Sports Technology (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Technology (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-152"
                },
                {
                    "course_name": "Textile Design",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Textile Design (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Planning",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MPlan",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Planning",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Planning (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Planning (with placement year)",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MPlan",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Chemical Engineering",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Chemical Engineering with Information Technology and Management",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Manufacturing Engineering and Management",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Materials Science and Engineering",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Physics",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aeronautical and Automotive Engineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Analytical and Pharmaceutical Science",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Analytical Chemistry",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "PgCert",
                    "duration": "6 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Sport Performance Analysis",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MArch (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture, Building and Civil Engineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MPhil (Res)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence and Data Analytics",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive Engineering",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Economics",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Psychology",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate Change Politics and Policy",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate Change Science and Management",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Exercise Physiology",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication and Media",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication, Media and Development",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Management",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Project Management with Building Information Modelling",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Project Management with Sustainability",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary Literature and Culture",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing and the Writing Industries",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology, Sociology and Social Policy",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security and Data Analytics",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Driven Design",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design and Branding",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design and Creative Arts",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Innovation",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Innovation",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Innovation Management",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Creative Media",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Entrepreneurship",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Finance",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Innovation Management",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media and Creative Industries",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Technologies",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diplomacy and International Governance",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diplomacy and International Relations",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diplomacy and Sport",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diplomacy, International Business and Trade",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diplomacy, International Politics and Trade",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Design",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Entrepreneurship and Innovation Management",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Entrepreneurship, Finance and Innovation",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Monitoring, Research and Management",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ergonomics and Human Factors",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Exercise Rehabilitation",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Global Trading",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Management",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Mathematics",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography and Environment",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Design and Visualisation",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Factors and Ergonomics for Patient Safety",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Industrial Mathematical Modelling",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Systems and Digital Innovation",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Innovation and Entrepreneurship",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrated Industrial Design",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Financial and Political Relations",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Resource Management",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management and Emerging Economies",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Project Management",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations, Politics and History",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Security",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Sustainable Development",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical Sciences",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics Education",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical, Electrical and Manufacturing Engineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communication",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Creative Industries",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Musculoskeletal Sport Science and Health",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Net Zero Building Services Engineering",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Science and Medicinal Chemistry",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "PgDip",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Education",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiology and Nutrition of Sport and Exercise",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Polymer Science and Engineering",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology of Exercise Rehabilitation",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Renewable Energy Systems Technology",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Risk, Governance and International Management",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Security, Peace-building and Diplomacy",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Service Design Innovation",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Science Research",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Analytics and Technologies",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Psychology",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Biomechanics",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Business",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Business and Innovation",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Business and Leadership",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Management",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Management, Politics and International Development",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Marketing",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Rehabilitation",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport, Exercise and Health Sciences",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Engineering",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Storytelling",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Communication",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strength and Conditioning",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Management",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Sport Business",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Systems Engineering",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "The Loughborough Full-Time MBA",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "User Experience and Service Design",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "User Experience Design",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Work Psychology",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Manufacturing Engineering and Management",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Materials Science and Engineering",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aeronautical and Automotive Engineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Analytical and Pharmaceutical Science",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Analytical Chemistry",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Sport Performance Analysis",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture (Practice-based)",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture, Building and Civil Engineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "MPhil (Res)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence and Data Analytics",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Economics",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Psychology",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate Change Politics and Policy",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate Change Science and Management",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Exercise Physiology",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication and Media",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication, Media and Development",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Management",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Project Management with Building Information Modelling",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary Literature and Culture",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing and the Writing Industries",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology, Sociology and Social Policy",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security and Data Analytics",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Driven Design",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design and Branding",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design and Creative Arts",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Innovation",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Innovation",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Innovation Management",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Creative Media",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Entrepreneurship",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Finance",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Innovation Management",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media and Creative Industries",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Technologies",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diplomacy and International Governance",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diplomacy and International Relations",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diplomacy and Sport",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diplomacy, International Business and Trade",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diplomacy, International Politics and Trade",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Design",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Entrepreneurship and Innovation Management",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Entrepreneurship, Finance and Innovation",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Monitoring, Research and Management",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ergonomics and Human Factors",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Exercise Rehabilitation",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography and Environment",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Factors and Ergonomics for Patient Safety",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Systems and Digital Innovation",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Infrastructure Asset Management",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Innovation and Entrepreneurship",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Financial and Political Relations",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Resource Management",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management and Emerging Economies",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Project Management",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations, Politics and History",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Security",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Sustainable Development",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership in Sport",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical Sciences",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics Education",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Qualified Teacher Status (QTS)",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical, Electrical and Manufacturing Engineering",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communication",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Creative Industries",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Musculoskeletal Sport Science and Health",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Net Zero Building Services Engineering",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Science and Medicinal Chemistry",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Education with Qualified Teacher Status (QTS)",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiology and Nutrition of Sport and Exercise",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Polymer Science and Engineering",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology of Exercise Rehabilitation",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Risk, Governance and International Management",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Security, Peace-building and Diplomacy",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Service Design Innovation",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Science Research",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Analytics and Technologies",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Psychology",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Biomechanics",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Business",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Business and Innovation",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Business and Leadership",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Management",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Management, Politics and International Development",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Marketing",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Rehabilitation",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport, Exercise and Health Sciences",
                    "university": "Loughborough University",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Engineering",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Storytelling",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Leadership",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "PgDip (Initial Qualification)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strength and Conditioning",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Sport Business",
                    "university": "Loughborough University",
                    "location": "Loughborough University London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Systems Engineering",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "User Experience and Service Design",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Work Psychology",
                    "university": "Loughborough University",
                    "location": "Loughborough University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "6131713d7e82271551b5ae32dfea546b",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "923b5a0b7d620b26f86fcfef7d3e12d8",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BAEcon",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting with Industrial/Professional Experience",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Actuarial Science and Mathematics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Adult Nursing",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BNurs (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Adult Nursing (Postgraduate)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering with an Integrated Foundation Year",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering with Industrial Experience",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering with Management",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "American Studies (3 Years)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "American Studies (4 Years)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History and Archaeology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History and History",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arabic and French",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arabic and German",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arabic and Italian",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arabic and Portuguese",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arabic and Russian",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arabic and Spanish",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arabic Studies",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and Anthropology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and History",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture with Foundation Year",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History and Arabic",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History and Chinese",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History and English Literature",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History and French",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History and German",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History and History",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History and Italian",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History and Japanese",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History and Portuguese",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History and Russian",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History and Spanish",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry (4 years) [MSci]",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry with a Modern Language",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry with Entrepreneurship",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry with Industrial/Professional Experience (4 years)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology (4 years) [MSci]",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology with a Modern Language",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology with Entrepreneurship",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology with Industrial / Professional Experience (4 years)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology with Science & Society",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology with Science & Society with Industrial/Professional Experience",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences (4 years) [MSci]",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences with a Modern Language",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences with Entrepreneurship",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences with Industrial / Professional Experience (4 years)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biosciences with a Foundation Year",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology (4 years) [MSci]",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology with Entrepreneurship",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology with Industrial/Professional Experience",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Accounting with Industrial/Professional Experience - Flying Start Degree Programme",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with an Integrated Foundation Year",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with Energy and the Environment",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with Industrial Experience",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with Study in Europe",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with an Integrated Foundation Year",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Industrial Experience",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with International Study",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Medicinal Chemistry",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Medicinal Chemistry",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children's Nursing",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BNurs (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese and Japanese",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil and Structural Engineering",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil and Structural Engineering with Industrial Experience",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering (Enterprise)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with an Integrated Foundation Year",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with Industrial Experience",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Neuroscience and Psychology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Religion and Social Anthropology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Mathematics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Mathematics with Industrial Experience (4 years)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with an Integrated Foundation Year",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Industrial Experience (4 years)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative and Cultural Industries",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Data Analytics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BASS",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology with International Study",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dental Hygiene and Therapy",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dentistry (BDS first-year entry)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BDS",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dentistry (BDS pre-dental year entry)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BDS",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Development Studies",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BAEcon",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Development Studies and Data Analytics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BAEcon",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media, Culture and Society",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama and English Literature",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama and Film Studies",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth and Planetary Science with a Research Placement",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEarthSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth and Planetary Science with an Integrated Foundation Year",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEarthSci",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth and Planetary Sciences",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth and Planetary Sciences",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEarthSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth and Planetary Sciences with Industrial Experience",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEarthSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth and Planetary Sciences with International Study",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEarthSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "East Asian Studies",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "East Asian Studies with International Study",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BAEcon",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Data Analytics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BAEcon",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BAEcon",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Philosophy",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BAEcon",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Politics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BAEcon",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Sociology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BAEcon",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education, Leadership and Culture",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Psychology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering with Industrial Experience",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering with Industrial Experience (4 years)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical, Electronic & Mechatronic Engineering with an Integrated Foundation Year",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Arabic",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Chinese",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and English Literature",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and French",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and German",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Japanese",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Russian",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Spanish",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and American Studies",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and French",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and German",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and History",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Italian",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Spanish",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature with Creative Writing",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Management",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Management with Placement",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEnvSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science with a Research Placement",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEnvSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science with an Integrated Foundation Year",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science with Industrial Experience",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEnvSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science with International Study",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEnvSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Buying & Merchandising",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Management",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Marketing",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Product Innovation",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies and Arabic",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies and Archaeology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies and Chinese",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies and East Asian Studies",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies and English Language",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies and English Literature",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies and French",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies and German",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies and History",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies and History of Art",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies and Italian",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies and Japanese",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies and Linguistics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies and Middle Eastern Studies",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies and Music",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies and Portuguese",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies and Russian",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies and Spanish",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BAEcon",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Chinese",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and German",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Italian",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Japanese",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Portuguese",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Russian",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Spanish",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French Studies",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genetics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genetics (4 years) [MSci]",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genetics with a Modern Language (4 years)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genetics with Entrepreneurship",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genetics with Industrial/ Professional Experience (4 years)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with International Study",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with International Study",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with Professional Placement",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with Professional Placement",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Chinese",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Italian",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Japanese",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Portuguese",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Russian",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Spanish",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German Studies",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development with International Study",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health (Intercalated)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Healthcare Science (Audiology)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and American Studies",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Arabic",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and French",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and German",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Italian",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Portuguese",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Russian",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Sociology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Spanish",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Immunology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Immunology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Immunology with a Modern Language",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Immunology with Entrepreneurship",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Immunology with Industrial/Professional Experience",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Technology Management for Business (ITMB)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Technology Management for Business with Industrial Experience (ITMB)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business, Finance and Economics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business, Finance and Economics with Industrial/Professional Experience",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Disaster Management and Humanitarian Response",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Disaster Management and Humanitarian Response and Arabic",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Disaster Management and Humanitarian Response and Chinese",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Disaster Management and Humanitarian Response and French",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Disaster Management and Humanitarian Response and Spanish",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Spanish",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian Studies",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Japanese Studies",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Latin and English Literature",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Latin and French",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Latin and Italian",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Latin and Linguistics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Latin and Spanish",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with International Study",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Politics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Liberal Arts",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Liberal Arts with International Study",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Life Sciences",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Life Sciences with a Modern Language",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Life Sciences with Entrepreneurship",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Life Sciences with Industrial/Professional Experience (4 years)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics and Arabic",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics and Chinese",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics and French",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics and German",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics and Italian",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics and Japanese",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics and Portuguese",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics and Russian",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics and Social Anthropology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics and Sociology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics and Spanish",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA Social Work (Postgraduate Entry)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Accounting and Finance)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Accounting and Finance) with Industrial/Professional Experience",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Human Resources)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Human Resources) with Industrial/Professional Experience",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Innovation, Strategy and Entrepreneurship)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Innovation, Strategy and Entrepreneurship) with Industrial/Professional Experience",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (International Business Economics)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (International Business Economics) with Industrial/Professional Experience",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Marketing)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (Marketing) with Industrial/Professional Experience",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Industrial/Professional Experience",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Planning",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MPlan",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Planning with Professional Placement",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MPlan",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Planning with Real Estate",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MPlan",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Planning with Real Estate with Professional Placement",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MPlan",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science and Engineering",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science and Engineering",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science and Engineering with Biomaterials",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science and Engineering with Corrosion",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science and Engineering with Metallurgy",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science and Engineering with Nanomaterials",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science and Engineering with Polymers",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science and Engineering with Textile Technology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science with an Integrated Foundation Year",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MMath",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Philosophy",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Physics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Physics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "Master of Mathematics and Physics",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Statistics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Statistics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MMath",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with an Integrated Foundation Year",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Finance",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Placement Year",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with an Integrated Foundation Year",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Industrial Experience (5 years)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Management",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Management",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronic Engineering",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronic Engineering",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronic Engineering with Industrial Experience (4 years)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronic Engineering with Industrial Experience (5 years)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Biochemistry",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Biochemistry (4 years) [MSci]",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Biochemistry with Industrial/Professional Experience",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Physiology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Physiology (4 years) [MSci]",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Physiology with a Modern Language (4 years)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Physiology with Entrepreneurship",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Physiology with Industrial/Professional Experience (4 years)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MB ChB",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine (6 years including foundation year)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MB ChB",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine (Graduate Entry)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MB ChB",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health Nursing",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BNurs (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology (4 years) [MSci]",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology with a Modern Language (4 years)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology with Entrepreneurship",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology with Industrial/Professional Experience (4 years)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Middle Eastern Studies",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BMid (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern History with Economics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Language and Business & Management (Arabic)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Language and Business & Management (Chinese)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Language and Business & Management (French) (4 years)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Language and Business & Management (German) (4 years)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Language and Business & Management (Italian) (4 years)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Language and Business & Management (Japanese)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Language and Business & Management (Portuguese) (4 years)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Language and Business & Management (Russian) (4 years)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Language and Business & Management (Spanish) (4 years)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular Biology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular Biology (4 years) [MSci]",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular Biology with a Modern Language (4 years)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular Biology with Entrepreneurship",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular Biology with Industrial/Professional Experience",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BMus (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Drama",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience (4 years) [MSci]",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience with a Modern Language (4 years)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience with Entrepreneurship",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience with Industrial/Professional Experience (4 years)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Optometry",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology (4 years) [MSci]",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology with a Modern Language (4 years)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology with Entrepreneurship",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology with Industrial/Professional Experience (4 years)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MPharm (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy with a Foundation Year",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MPharm (H)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Criminology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BASS",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Politics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BASS",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Religion",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physician Associate Studies (PGDip)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physician Associate Studies (Postgraduate MSc)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with an Integrated Foundation Year",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Study in Europe",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Theoretical Physics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Theoretical Physics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Planning and Real Estate",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Planning and Real Estate with Professional Placement",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Arabic",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Chinese",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Criminology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BASS",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Data Analytics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and French",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and German",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSocSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Italian",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Japanese",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Modern History",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Portuguese",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Russian",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Social Anthropology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BASS",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Sociology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BASS",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Spanish",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, Philosophy and Economics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religions, Theology and Ethics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian and Chinese",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian and Japanese",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian and Spanish",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian Studies",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSocSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology and Criminology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSocSci H",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology and Data Analytics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology and Philosophy",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSocSci H",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology and Sociology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSocSci H",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSocSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Arabic",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Chinese",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Criminology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSocSci H",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Data Analytics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and French",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and German",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Italian",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Japanese",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Philosophy",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSocSci H",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Portuguese",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Russian",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Spanish",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Chinese",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Japanese",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Portuguese",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish, Portuguese and Latin American Studies (4 years)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Speech and Language Therapy",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology (4 years) [MSci]",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology with a Modern Language (4 years)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology with Entrepreneurship",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology with Industrial/Professional Experience (4 years)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Adult Nursing",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Chemical Engineering",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Optometric Practice",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Control and Systems Engineering",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Control and Systems Engineering with Extended Research",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Electrical Power Systems Engineering",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Engineering Materials",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Leadership for Professional Practice (Nursing)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Leadership for Professional Practice (Social Work)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Manufacturing Technology and Systems Management",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Process Integration and Design",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropological Research",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Mathematics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MArch",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture and Adaptive Reuse",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture and Urbanism",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art Gallery and Museum Studies",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts Management, Policy and Practice",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts, Culture and the Environment",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Audiology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Audiology (Paediatric Pathway)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioinformatics and Systems Biology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomaterials",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology and Enterprise",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analysis and Strategic Management",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics: Operational Research and Risk Analysis",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Psychology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer Research and Molecular Biomedicine",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cardiovascular Health and Disease (Research)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cell Biology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Ancient History",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate Change: Science, Society and Solutions",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical and Health Psychology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Biochemistry",
                    "university": "University of Manchester",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Data Science",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Dentistry (Paediatric Dentistry)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Immunology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Neuroscience and Neuropsychology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Commercial Project Management",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communications and Signal Processing",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communications and Signal Processing with Extended Research",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Composition (Electroacoustic Music and Interactive Media)",
                    "university": "University of Manchester",
                    "location": "No Locations",
                    "qualification": "MusM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Composition (Instrumental and Vocal Composition)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MusM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational and Corpus Linguistics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Corporate Governance",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Corrosion Control Engineering",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative and Cultural Industries",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology (Social Statistics)",
                    "university": "University of Manchester",
                    "location": "No Locations",
                    "qualification": "MRes",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science (Applied Urban Analytics)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science (Business and Management)",
                    "university": "University of Manchester",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science (Computer Science Data Informatics)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science (Earth and Environmental Analytics)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science (Mathematics)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science (Social Analytics)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Deaf Education",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Deaf Education (International Pathway)",
                    "university": "University of Manchester",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Development Economics and Policy",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Development Finance",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Developmental Biology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Development",
                    "university": "University of Manchester",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media, Culture and Society",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media, Culture and Society",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Technologies, Communication and Education",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Data Science",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education for a Sustainable Environment",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Leadership",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Power Systems Engineering",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Endodontics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc (Clin)",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Endodontics (Dental Specialties)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Project Management",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineers Teach Physics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PGCE (Professional)",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and American Studies",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Governance",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Impact Assessment and Management",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Monitoring, Modelling and Reconstruction",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Experimental Psychology with Data Science",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Economics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fixed and Removable Prosthodontics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc (Clin)",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fixed and Removable Prosthodontics (Dental Specialties)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology and Mental Health",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender, Sexuality and Culture",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genomic Medicine",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "8 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geographical Information Science",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geoscience for Sustainable Energy",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development (Development Management)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development (Environment and Climate Change)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development (Globalisation, Trade & Industry)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development (Migration, Mobility & Displacement)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development (Politics, Governance and Development Policy)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development (Poverty and Inequality)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Urban Development and Planning",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Green Infrastructure",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Data Science",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "6 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Informatics (UCL/UoM Joint Award)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Healthcare Ethics and Law",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Healthcare Ethics and Law (Intercalated)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Healthcare Ethics Postgraduate Certificate",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Healthcare Law Postgraduate Certificate",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Heritage Studies",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Science, Technology and Medicine",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Development (International Development)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management (International Development)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management and Industrial Relations",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Rights - Law/Political Science Pathway",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Rights - Political Science",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Humanitarian Practice",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Humanitarianism and Conflict Response",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Infection Biology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Innovation Management and Entrepreneurship",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intellectual Property Law",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intercultural Communication",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Commercial Law",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Management",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development: Public Policy and Management",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Disaster Management",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Economic Law",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Education",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Education (Curriculum and Pedagogy)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Education (Equity, Diversity and Inclusion)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Education (Globalisation and International Development)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Education (Higher Education)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Education (TESOL)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Fashion Marketing",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Fashion Retailing",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Fashion Retailing (Entrepreneurship and Innovation)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Financial Law",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Resource Management and Comparative Industrial Relations",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Political Economy",
                    "university": "University of Manchester",
                    "location": "Multiple Locations",
                    "qualification": "MA (Res)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Investigative Ophthalmology and Vision Sciences",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Library and Archive Studies",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and Implementation of Development Projects",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and Information Systems: Change and Development",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management of Projects",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Manchester Full-Time MBA",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Landscape Architecture",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MLA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Public Health (on campus)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MPH",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical Finance",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering Design",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical and Molecular Virology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Imaging Science",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Microbiology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Physics in Cancer Radiation Therapy",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "6 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Sciences",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval and Early Modern Studies",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Model Based Drug Development",
                    "university": "University of Manchester",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Model-based Drug Development - Pharmacokinetic and Pharmacodynamic Modelling",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern and Contemporary Literature",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and Cultures",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular Pathology of Cancer",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music (Ethnomusicology)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MusM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music (Musicology)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MusM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music (Performance Studies)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MusM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nanomaterials",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nanomedicine by Research",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroimaging for Clinical and Cognitive Neuroscience",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nuclear Science",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nuclear Science and Technology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Hygiene",
                    "university": "University of Manchester",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Medicine",
                    "university": "University of Manchester",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oncology Research",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Operations, Project and Supply Chain Management",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oral and Maxillofacial Surgery (Dental Specialties)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Organisational Change and Development",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Organisational Psychology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Orthodontics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc (Clin)",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Peace and Conflict Studies",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Periodontology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc (Clin)",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Petroleum Geoscience",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Primary",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Primary School Direct 5-11",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Biology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Business Education",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Chemistry",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Economics and Business Education",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary English",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary French",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Geography",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary German",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary History",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Mathematics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Mathematics with Economics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Physics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Physics with Maths",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary School Direct (English)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary School Direct (French)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary School Direct (Geography)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary School Direct (German)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary School Direct (History)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary School Direct (Mathematics)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary School Direct (Spanish)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary School Direct Science: Biology (11-16 or 11-18)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary School Direct Science: Chemistry (11-16 or 11-18)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary School Direct Science: Physics (11-16 or 11-18)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary School Direct: Business Education (14-19)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Spanish",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physician Associate Studies",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Planning",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Playwriting",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Economy",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Science - Democracy and Elections",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Science - European Politics and Policy",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Science - Governance and Public Policy",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Science - Philosophy and Political Theory",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Science - Political Theory Pathway",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pollution and Environmental Control",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Polymer Materials Science and Engineering",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology of Education",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEd",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public International Law",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pure Mathematics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pure Mathematics and Mathematical Logic",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantitative Finance",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate Asset Management",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate Development",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Reliability Engineering and Asset Management",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Reliability Engineering and Asset Management",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religions and Theology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Renewable Energy and Clean Technology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Renewable Energy and Clean Technology with Extended Research",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Reproduction and Pregnancy (Research)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Research Methods with Education",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Research Methods with Human Geography",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Research Methods with International Development",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Research Methods with Planning and Environmental Management",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics with Extended Research",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science and Health Communication",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Screenwriting",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Secondary School Direct Economics and Business Education 14-19",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Security and International Law",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Security and International Law",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Change, Environment and Sustainability",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Network Analysis",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research Methods and Statistics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociological Research",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Practice (Cancer)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Engineering",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Subsurface Energy Engineering",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Business",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "TESOL",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Textile Technology (Technical Textiles)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Thermal Power & Fluid Engineering",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Tissue Engineering for Regenerative Medicine",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation and Interpreting Studies",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Transnational Dispute Resolution",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Design and International Planning",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Regeneration and Development",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Studies",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Anthropology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Clinical Optometric Practice",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice (Paediatrics)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Leadership for Professional Practice (Allied Health Profession)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Leadership for Professional Practice (Nursing)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Leadership for Professional Practice (Social Work)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropological Research",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Mathematics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Approaches to Mental Health Theory CPD",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Approved Mental Health Professional Practice",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture and Urbanism",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art Gallery and Museum Studies",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts Management, Policy and Practice",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts, Culture and the Environment",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Audiology (Paediatric Pathway)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Audiology Short Courses",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "Certificate of Professional Development",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Ancient History",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate Change: Science, Society and Solutions",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical and Health Psychology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Biochemistry",
                    "university": "University of Manchester",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Data Science",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Immunology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Composition (Electroacoustic Music and Interactive Media)",
                    "university": "University of Manchester",
                    "location": "No Locations",
                    "qualification": "MusM",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Composition (Instrumental and Vocal Composition)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MusM",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational and Corpus Linguistics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative and Cultural Industries",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology (Social Statistics)",
                    "university": "University of Manchester",
                    "location": "No Locations",
                    "qualification": "MRes",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Deaf Education",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Development Economics and Policy",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Development Finance",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Development",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media, Culture and Society",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media, Culture and Society",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Technologies, Communication and Education",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education for a Sustainable Environment",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and American Studies",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Governance",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Impact Assessment and Management",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Monitoring, Modelling and Reconstruction",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Economics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Management",
                    "university": "University of Manchester",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fixed and Removable Prosthodontics (Dental Specialties)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology and Mental Health",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender, Sexuality and Culture",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genomic Medicine",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "16 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geographical Information Science",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development (Development Management)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development (Environment and Climate Change)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development (Globalisation, Trade & Industry)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development (Migration, Mobility & Displacement)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development (Politics, Governance and Development Policy)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development (Poverty and Inequality)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Urban Development and Planning",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Green Infrastructure",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Data Science",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Informatics (UCL/UoM Joint Award)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Healthcare Ethics and Law",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Heritage Studies",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Science, Technology and Medicine",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Development (International Development)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management (International Development)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Rights - Law/Political Science Pathway",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Rights - Political Science",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Humanitarian Practice",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Humanitarianism and Conflict Response",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Innovation Management and Entrepreneurship",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intercultural Communication",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development: Public Policy and Management",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Disaster Management",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Political Economy",
                    "university": "University of Manchester",
                    "location": "Multiple Locations",
                    "qualification": "MA (Res)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Investigative Ophthalmology and Vision Sciences",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Library and Archive Studies",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and Implementation of Development Projects",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and Information Systems: Change and Development",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical and Molecular Virology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Imaging Science",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Microbiology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Physics in Cancer Radiation Therapy",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval and Early Modern Studies",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern and Contemporary Literature",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and Cultures",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music (Ethnomusicology)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MusM",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music (Musicology)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MusM",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music (Performance Studies)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MusM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nuclear Science and Technology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Hygiene",
                    "university": "University of Manchester",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Hygiene - Personal Professional Development",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "Certificate of Professional Development",
                    "duration": "6 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Medicine",
                    "university": "University of Manchester",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oral and Maxillofacial Surgery",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc (Clin)",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oral and Maxillofacial Surgery (Dental Specialties)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Organisational Change and Development",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Peace and Conflict Studies",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PG Credit Genomic Medicine CPD",
                    "university": "University of Manchester",
                    "location": "No Locations",
                    "qualification": "Certificate of Professional Development",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Technology and Quality Assurance",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Planning",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Economy",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Science - Democracy and Elections",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Science - European Politics and Policy",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Science - Governance and Public Policy",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Science - Philosophy and Political Theory",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Science - Political Theory Pathway",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Mental Health Care",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology of Education",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MEd",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public International Law",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pure Mathematics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pure Mathematics and Mathematical Logic",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate Asset Management",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate Development",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Reliability Engineering and Asset Management",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Reliability Engineering and Asset Management",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religions and Theology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science and Health Communication",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Security and International Law",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Security and International Law",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Change, Environment and Sustainability",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research Methods and Statistics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociological Research",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Practice (Cancer)",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "TESOL",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation and Interpreting Studies",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Transnational Dispute Resolution",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Design and International Planning",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Regeneration and Development",
                    "university": "University of Manchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "4716305cd9c72e3da9eb9a3665992d1c",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Pharmacy",
                    "university": "Medway School of Pharmacy",
                    "location": "Main Site",
                    "qualification": "MPharm (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "952ac91d344c550e934e7748df3d11a2",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "51765d11fea8c2589738c84344a8391e",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Airport and Airline Management",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Airport and Airline Passenger Management (Top-Up)",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Audio and Music Production",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Audio and Music Production",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business Management",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "FD",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business Management (Top-Up)",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing (Top-up)",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Management for England",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Media Production (Games Development)",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Media Production (Games Development)",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Culinary Arts and Management",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Culinary Arts Management (Top-Up)",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Childhood Practice",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Early Childhood Professional Studies (Top-up)",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education and Training",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "CertEd",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education and Training",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "PGCE (Professional)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Electrical and Electronic Engineering for England)",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Electrical and Electronic Engineering for England)",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Electrical and Electronic Engineering) (Top-up)",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Manufacturing Engineering) (Top-up)",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Mechanical Engineering for England)",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Mechanical Engineering for England)",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Mechanical Engineering) (Top-up)",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Operations Engineering for England)",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Operations Engineering)",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Operations Engineering) (Top-up)",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Video Production",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health, Wellbeing and Social Care",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "36-48"
                },
                {
                    "course_name": "Health, Wellbeing and Social Care",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "36-48"
                },
                {
                    "course_name": "Health, Wellbeing and Social Care (Top-up)",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hospitality Management",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Hospitality Management (Top-up)",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social and Community Studies",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social and Community Studies",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "BA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Hair and Makeup Artistry for Creative Industries",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Hair and Makeup Artistry for Creative Industries",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sports Coaching, Development  and Fitness",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sports Coaching, Development and Fitness (Top-up)",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Therapy with Rehabilitation",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sports Therapy with Rehabilitation (Top-up)",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching and Learning",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Teaching and Learning (Top-Up)",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Travel and Tourism",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Business Management",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business Management (Top-Up)",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing (Top-up)",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Culinary Arts and Management",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Early Childhood Practice",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Early Childhood Professional Studies (Top-up)",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education and Training",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "CertEd",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education and Training",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "PGCE (Professional)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Electrical and Electronic Engineering) (Top-up)",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Manufacturing Engineering) (Top-up)",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Mechanical Engineering) (Top-up)",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Operations Engineering) (Top-up)",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hospitality Management",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Hospitality Management (Top-up)",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching and Learning",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Travel and Tourism",
                    "university": "Middlesbrough College",
                    "location": "Middlesbrough College",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "6194d5d7beda689fd093a87e1e1651bf",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Fashion and Textiles",
                    "university": "MidKent College (Inc Medway School of Arts)",
                    "location": "Medway School of Arts",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fine Art Practice",
                    "university": "MidKent College (Inc Medway School of Arts)",
                    "location": "Medway School of Arts",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "MidKent College (Inc Medway School of Arts)",
                    "location": "Medway School of Arts",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Makeup & Hair Design for the Arts",
                    "university": "MidKent College (Inc Medway School of Arts)",
                    "location": "Medway School of Arts",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Photography",
                    "university": "MidKent College (Inc Medway School of Arts)",
                    "location": "Medway School of Arts",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "5018a07e649d55a6b3e06a36b93c5318",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Leadership & Management (HTQ)",
                    "university": "Morley College London",
                    "location": "North Kensington Centre for Skills",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "48-64"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "698c2e2a5382c504e8047387002b1358",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Animal Behavioural  Science & Welfare",
                    "university": "Moulton College",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Animal Behavioural , Science & Welfare (Top Up)",
                    "university": "Moulton College",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Behavioural Science & Welfare",
                    "university": "Moulton College",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Applied Zoology",
                    "university": "Moulton College",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Applied Zoology",
                    "university": "Moulton College",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Applied Zoology ( Top up)",
                    "university": "Moulton College",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership & Management",
                    "university": "Moulton College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership & Management",
                    "university": "Moulton College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sport Science - specialising in Coaching Science",
                    "university": "Moulton College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sport Science - specialising in Exercise , Health and Lifestyle",
                    "university": "Moulton College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sports Performance and Coaching (Top Up)",
                    "university": "Moulton College",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Animal Behavioural  Science & Welfare",
                    "university": "Moulton College",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Animal Behavioural , Science & Welfare (Top Up)",
                    "university": "Moulton College",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Behavioural Science & Welfare",
                    "university": "Moulton College",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Applied Zoology",
                    "university": "Moulton College",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Applied Zoology",
                    "university": "Moulton College",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Applied Zoology ( Top up)",
                    "university": "Moulton College",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Management (Top Up)",
                    "university": "Moulton College",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Management Site Supervisor",
                    "university": "Moulton College",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Performance and Coaching (Top Up)",
                    "university": "Moulton College",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "c6924766c0d064a863b524bd7973a724",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Creative Producing",
                    "university": "Mountview Academy of Theatre Arts",
                    "location": "Mountview Academy of Theatre Arts",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Musical Direction",
                    "university": "Mountview Academy of Theatre Arts",
                    "location": "Mountview Academy of Theatre Arts",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance (Acting)",
                    "university": "Mountview Academy of Theatre Arts",
                    "location": "Mountview Academy of Theatre Arts",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance (Musical Theatre)",
                    "university": "Mountview Academy of Theatre Arts",
                    "location": "Mountview Academy of Theatre Arts",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Site-Specific Theatre Practice",
                    "university": "Mountview Academy of Theatre Arts",
                    "location": "Mountview Academy of Theatre Arts",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Stage and Production Management",
                    "university": "Mountview Academy of Theatre Arts",
                    "location": "Mountview Academy of Theatre Arts",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre Directing",
                    "university": "Mountview Academy of Theatre Arts",
                    "location": "Mountview Academy of Theatre Arts",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Theatre for Community and Education",
                    "university": "Mountview Academy of Theatre Arts",
                    "location": "Mountview Academy of Theatre Arts",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "fa4fab2f6ca57a8c9929a0984c6838b3",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Agricultural Machinery Engineering",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Agricultural Machinery Engineering (Top-up)",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agriculture",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Agriculture",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Animal Science and Behaviour",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Animal Science and Conservation (Top-up)",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Science and Welfare (Health Care Management)",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Animal Science and Welfare (Zoo Conservation)",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Arboriculture",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "Fd cert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Arboriculture and Tree Management",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Arboriculture and Urban Forestry",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Basketball Coaching and Performance",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Ecology and Conservation Management",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Equine Management",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Equine Science",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Equine Science and Management",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Football Coaching and Performance",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Football Coaching and Performance",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Golf Coaching and Performance",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Golf Coaching and Performance",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Golf Coaching and Performance (Top-up)",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Golf Management",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Golf Management",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Golf Management (Top-up)",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Horticultural Science and Management (Top-up)",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Horticulture",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "Fd cert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Horticulture and Applied Plant Science",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Motorsport Competition Car Technology",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Motorsport Competition Car Technology (Top-up)",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Motorsport Management and Logistics",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Motorsport Management and Logistics (Top-up)",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Land Management (Top-up)",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Veterinary Nursing",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "Cert",
                    "duration": "1 year",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agriculture",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "Fd cert",
                    "duration": "1.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Animal Science",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "Fd cert",
                    "duration": "1.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Countryside Management",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "Fd cert",
                    "duration": "1.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Equine Science and Management",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Horticulture",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "Fd cert",
                    "duration": "1.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sportsturf",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "Fd cert",
                    "duration": "1.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Animal Science",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "PGCE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Equine Science",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Animal Science",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Equine Science",
                    "university": "University Centre Myerscough",
                    "location": "Preston Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "11b588b16edc2d435bf58eb18a9538d1",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "5dae6c817474a18c1dde0abb5779fd94",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Assistant Camera (Focus Pulling and Loading)",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Assistant Directing and Floor Managing",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "PgDip",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cameras for Television Production",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cameras, Sound and Vision Mixing for Television Production",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "PgDip",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cinematography",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Composing for Film and Television",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Business",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Directing and Producing Science and Natural History",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Directing and Producing Television Entertainment",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Directing Animation",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Directing Commercials",
                    "university": "National Film and Television School",
                    "location": "No Locations",
                    "qualification": "PgDip",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Directing Documentary",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Directing Fiction",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Editing",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies Programming and Curation",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Design and Development",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Location Management for Film and TV Production",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "Cert",
                    "duration": "12 weeks",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Location Sound Recording for Film and Television",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "PgDip",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing, Distribution, Sales and Exhibition",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Model Making For Film and Animation",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "PgDip",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Motion Graphics and Titles",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "PgDip",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Producing",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Production Design",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Production Management for Film and Television",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "PgDip",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Screenwriting",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Script Supervision and Continuity for Film and Television",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "Cert",
                    "duration": "3 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sound Design for Film and Television",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sound for Television Production",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Production",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Vision Mixing for Television Production",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Effects (VFX)",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Casting",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "Cert",
                    "duration": "24 weeks",
                    "study_mode": "Part-time evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Indie Games Development",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "Cert",
                    "duration": "6 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Location Management for Film and TV Production",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "Cert",
                    "duration": "6 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "NFTS Cymru Wales: Screenwriting: Finding Your Voice",
                    "university": "National Film and Television School",
                    "location": "No Locations",
                    "qualification": "Cert",
                    "duration": "23 weeks",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "NFTS Leeds: Screenwriting: Finding Your Voice",
                    "university": "National Film and Television School",
                    "location": "No Locations",
                    "qualification": "Cert",
                    "duration": "23 weeks",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "NFTS Leeds: Script Supervision for Drama",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "Cert",
                    "duration": "6 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "NFTS Scotland Certificate: Location Management for Film and TV Production (Blended)",
                    "university": "National Film and Television School",
                    "location": "No Locations",
                    "qualification": "Cert",
                    "duration": "24 weeks",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "NFTS Scotland Certificate: Producing Your First Feature",
                    "university": "National Film and Television School",
                    "location": "No Locations",
                    "qualification": "Cert",
                    "duration": "6 months",
                    "study_mode": "Part-time evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "NFTS Scotland Certificate: Screenwriting - Finding Your Voice",
                    "university": "National Film and Television School",
                    "location": "No Locations",
                    "qualification": "Cert",
                    "duration": "6 months",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Post Production Supervision",
                    "university": "National Film and Television School",
                    "location": "No Locations",
                    "qualification": "Cert",
                    "duration": "6 months",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Producing Your First Feature",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "Cert",
                    "duration": "6 months",
                    "study_mode": "Part-time evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Screenwriting: Finding Your Voice",
                    "university": "National Film and Television School",
                    "location": "No Locations",
                    "qualification": "Cert",
                    "duration": "6 months",
                    "study_mode": "Part-time evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Script Development",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "PgDip",
                    "duration": "14 months",
                    "study_mode": "Part-time day/evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "TV Drama: Creating The Bible",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "Cert",
                    "duration": "8 weeks",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Unscripted Development",
                    "university": "National Film and Television School",
                    "location": "No Locations",
                    "qualification": "PgDip",
                    "duration": "12 months",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Virtual Production (Filmmaking with real-time technologies)",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "Cert",
                    "duration": "24 weeks",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Writing and Producing Comedy",
                    "university": "National Film and Television School",
                    "location": "National Film and Television School",
                    "qualification": "PgDip",
                    "duration": "18 months",
                    "study_mode": "Part-time evening",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "3025a5a51b97755682d1075e377a00eb",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Theology",
                    "university": "Nazarene Theological College",
                    "location": "Nazarene Theological College",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Theology",
                    "university": "Nazarene Theological College",
                    "location": "Nazarene Theological College",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology (Biblical Studies)",
                    "university": "Nazarene Theological College",
                    "location": "Nazarene Theological College",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Theology (Biblical Studies) (Online)",
                    "university": "Nazarene Theological College",
                    "location": "Nazarene Theological College",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Theology (Online)",
                    "university": "Nazarene Theological College",
                    "location": "Nazarene Theological College",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Theology (Online)",
                    "university": "Nazarene Theological College",
                    "location": "Nazarene Theological College",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology (Practical Theology)",
                    "university": "Nazarene Theological College",
                    "location": "Nazarene Theological College",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Theology (Practical Theology) (Online)",
                    "university": "Nazarene Theological College",
                    "location": "Nazarene Theological College",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Theology (Youth And Community)",
                    "university": "Nazarene Theological College",
                    "location": "Nazarene Theological College",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Theology (Youth And Community) (Online)",
                    "university": "Nazarene Theological College",
                    "location": "Nazarene Theological College",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Theology",
                    "university": "Nazarene Theological College",
                    "location": "Nazarene Theological College",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Theology (Biblical Studies)",
                    "university": "Nazarene Theological College",
                    "location": "Nazarene Theological College",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Theology (Biblical Studies) (Online)",
                    "university": "Nazarene Theological College",
                    "location": "Nazarene Theological College",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Theology (Online)",
                    "university": "Nazarene Theological College",
                    "location": "Nazarene Theological College",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Theology (Practical Theology)",
                    "university": "Nazarene Theological College",
                    "location": "Nazarene Theological College",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Theology (Practical Theology) (Online)",
                    "university": "Nazarene Theological College",
                    "location": "Nazarene Theological College",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "fdeb9988ffa9ac77a60cf95a7e071d8a",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Advanced Manufacturing Engineering",
                    "university": "Nelson and Colne College Group",
                    "location": "Nelson Campus",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Manufacturing Engineering",
                    "university": "Nelson and Colne College Group",
                    "location": "Nelson Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Aeronautical Engineering",
                    "university": "Nelson and Colne College Group",
                    "location": "Nelson Campus",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aeronautical Engineering",
                    "university": "Nelson and Colne College Group",
                    "location": "Nelson Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Alcohol and Substance Misuse Work",
                    "university": "Nelson and Colne College Group",
                    "location": "Accrington Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computer Information Systems",
                    "university": "Nelson and Colne College Group",
                    "location": "Nelson Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computer Programming",
                    "university": "Nelson and Colne College Group",
                    "location": "Nelson Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computing",
                    "university": "Nelson and Colne College Group",
                    "location": "Nelson Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computing",
                    "university": "Nelson and Colne College Group",
                    "location": "Nelson Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Counselling and Psychotherapy (Top up)",
                    "university": "Nelson and Colne College Group",
                    "location": "Accrington Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling Studies",
                    "university": "Nelson and Colne College Group",
                    "location": "Accrington Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Creative Digital Media",
                    "university": "Nelson and Colne College Group",
                    "location": "Accrington Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Criminology and Criminal Justice",
                    "university": "Nelson and Colne College Group",
                    "location": "Accrington Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Cyber Security and Networking",
                    "university": "Nelson and Colne College Group",
                    "location": "Nelson Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Early Years",
                    "university": "Nelson and Colne College Group",
                    "location": "Nelson Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Education (Top up)",
                    "university": "Nelson and Colne College Group",
                    "location": "Accrington Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Nelson and Colne College Group",
                    "location": "Nelson Campus",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Nelson and Colne College Group",
                    "location": "Nelson Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Electromechanical Maintenance Engineering",
                    "university": "Nelson and Colne College Group",
                    "location": "Nelson Campus",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electromechanical Maintenance Engineering",
                    "university": "Nelson and Colne College Group",
                    "location": "Nelson Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Engineering",
                    "university": "Nelson and Colne College Group",
                    "location": "Nelson Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Engineering",
                    "university": "Nelson and Colne College Group",
                    "location": "Nelson Campus",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Applied Social Studies",
                    "university": "Nelson and Colne College Group",
                    "location": "Accrington Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "International Business Management",
                    "university": "Nelson and Colne College Group",
                    "location": "Accrington Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Nelson and Colne College Group",
                    "location": "Nelson Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Nelson and Colne College Group",
                    "location": "Nelson Campus",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health Work",
                    "university": "Nelson and Colne College Group",
                    "location": "Accrington Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Paralegal Studies",
                    "university": "Nelson and Colne College Group",
                    "location": "Accrington Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Professional Healthcare Management",
                    "university": "Nelson and Colne College Group",
                    "location": "Accrington Campus",
                    "qualification": "FdA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Professional Healthcare Management",
                    "university": "Nelson and Colne College Group",
                    "location": "Accrington Campus",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Professional Healthcare Management and Informatics",
                    "university": "Nelson and Colne College Group",
                    "location": "Accrington Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sport Coaching and Performance",
                    "university": "Nelson and Colne College Group",
                    "location": "Accrington Campus",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Sport Coaching and Performance",
                    "university": "Nelson and Colne College Group",
                    "location": "Accrington Campus",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sustainability in the Built Environment",
                    "university": "Nelson and Colne College Group",
                    "location": "Accrington Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sustainable Business Management",
                    "university": "Nelson and Colne College Group",
                    "location": "Nelson Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sustainable Business Management",
                    "university": "Nelson and Colne College Group",
                    "location": "Nelson Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Sustainable Construction",
                    "university": "Nelson and Colne College Group",
                    "location": "Accrington Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sustainable Construction Design",
                    "university": "Nelson and Colne College Group",
                    "location": "Accrington Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sustainable Construction Design",
                    "university": "Nelson and Colne College Group",
                    "location": "Accrington Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sustainable Construction Management",
                    "university": "Nelson and Colne College Group",
                    "location": "Accrington Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sustainable Construction Management",
                    "university": "Nelson and Colne College Group",
                    "location": "Accrington Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sustainable Industrial Systems",
                    "university": "Nelson and Colne College Group",
                    "location": "Accrington Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sustainable Technologies",
                    "university": "Nelson and Colne College Group",
                    "location": "Accrington Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "36"
                },
                {
                    "course_name": "Sustainable Transport",
                    "university": "Nelson and Colne College Group",
                    "location": "Accrington Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Teaching and Learning Support",
                    "university": "Nelson and Colne College Group",
                    "location": "Nelson Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Working with Children, Young People and Families",
                    "university": "Nelson and Colne College Group",
                    "location": "Accrington Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Electrical and Electronic Engineering (Top up)",
                    "university": "Nelson and Colne College Group",
                    "location": "Nelson Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Variable",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Manufacturing Engineering (Top up)",
                    "university": "Nelson and Colne College Group",
                    "location": "Nelson Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Variable",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Construction Management",
                    "university": "Nelson and Colne College Group",
                    "location": "Accrington Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Variable",
                    "ucas_points": "96"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "86be655d86caa1017f5ec78bd5b86265",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Animal Management & Conservation",
                    "university": "NESCOT",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Welfare and Management",
                    "university": "NESCOT",
                    "location": "Main Site",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Digital Design",
                    "university": "NESCOT",
                    "location": "Main Site",
                    "qualification": "Fd Dip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Entrepreneurship (Top-up)",
                    "university": "NESCOT",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling Studies (Top-Up)",
                    "university": "NESCOT",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Years Pedagogy (0-8 Years)",
                    "university": "NESCOT",
                    "location": "Main Site",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies",
                    "university": "NESCOT",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "HealthCare Play Specialism",
                    "university": "NESCOT",
                    "location": "Main Site",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Internet of Things",
                    "university": "NESCOT",
                    "location": "Main Site",
                    "qualification": "FD",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Music Performance & Production",
                    "university": "NESCOT",
                    "location": "Main Site",
                    "qualification": "Diploma",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Osteopathic Medicine MOst",
                    "university": "NESCOT",
                    "location": "Main Site",
                    "qualification": "MOst",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Professional Certificate in Education (FE and Skills Sector)",
                    "university": "NESCOT",
                    "location": "Main Site",
                    "qualification": "CertEd",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Diploma in Performance",
                    "university": "NESCOT",
                    "location": "Main Site",
                    "qualification": "Prof Dip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Professional Graduate Certificate in Education (PGCE) (FE and Skills Sector)",
                    "university": "NESCOT",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Practice Working with Children & Young people (0-19)",
                    "university": "NESCOT",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychodynamic Counselling",
                    "university": "NESCOT",
                    "location": "Main Site",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Supporting Teaching and Learning",
                    "university": "NESCOT",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "d987f13e7a7edd66c029287174e3bf93",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Construction Management (BSc)",
                    "university": "New Model Institute for Technology and Engineering (NMITE)",
                    "location": "NMITE Skylon",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Construction Management (BSc) with Foundation Year",
                    "university": "New Model Institute for Technology and Engineering (NMITE)",
                    "location": "NMITE Blackfriars",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Integrated Engineering (BEng)",
                    "university": "New Model Institute for Technology and Engineering (NMITE)",
                    "location": "NMITE Blackfriars",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Integrated Engineering (BEng) with Foundation Year",
                    "university": "New Model Institute for Technology and Engineering (NMITE)",
                    "location": "NMITE Blackfriars",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Integrated Engineering (MEng)",
                    "university": "New Model Institute for Technology and Engineering (NMITE)",
                    "location": "NMITE Blackfriars",
                    "qualification": "MEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Integrated Engineering (MEng) with Foundation Year",
                    "university": "New Model Institute for Technology and Engineering (NMITE)",
                    "location": "NMITE Blackfriars",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Mechanical Engineering (MEng)",
                    "university": "New Model Institute for Technology and Engineering (NMITE)",
                    "location": "NMITE Blackfriars",
                    "qualification": "MEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Mechanical Engineering (MEng) with Foundation Year",
                    "university": "New Model Institute for Technology and Engineering (NMITE)",
                    "location": "NMITE Blackfriars",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "See entry requirements"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "ce74f045904c223088123936913d53e6",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "92adeb277394e55f68dae2dbb06c3464",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Ministry and Mission",
                    "university": "Newbold College",
                    "location": "Newbold College",
                    "qualification": "PgCert",
                    "duration": "4 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology",
                    "university": "Newbold College",
                    "location": "Newbold College",
                    "qualification": "MA",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Ministry and Mission",
                    "university": "Newbold College",
                    "location": "Newbold College",
                    "qualification": "PgCert",
                    "duration": "8 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pastoral Counselling",
                    "university": "Newbold College",
                    "location": "Newbold College",
                    "qualification": "PgCert",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology",
                    "university": "Newbold College",
                    "location": "Newbold College",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Youth Ministry",
                    "university": "Newbold College",
                    "location": "Newbold College",
                    "qualification": "PgCert",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "179b5d4c52ccc806585bb74fb0d2ea2b",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Acting for Stage and Screen",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 to 3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-64"
                },
                {
                    "course_name": "Advanced Principles in Media Makeup (Top-up)",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Skin Techniques (Top-up)",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Techniques in Media and Semi Permanent Make-up",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aesthetic Principles and Clinical Practice",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aircraft Maintenance Engineering",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Airline and Airport Management",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Animation",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Applied Physical Education and Sports Coaching (Top-up)",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management (Top-up)",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children and Young People",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Children and Young People (Top-up)",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Commercial Dance",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48-64"
                },
                {
                    "course_name": "Computing (Top-up)",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling Skills and Therapeutic Communication",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling Skills and Therapeutic Communication (Top-up)",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology, Policing and Criminal Law",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Culinary Arts",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Culinary Arts Management (Top-up)",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dance",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-64"
                },
                {
                    "course_name": "Design Practice (Top-up)",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Electronic Engineering (Top-up)",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Engineering",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "FdEng",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Energy Engineering (Top-up)",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "FdEng",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Engineering Management [Top-up]",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Design and Innovative Technologies",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Fashion Marketing and Communication",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Film, TV and Media Production",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Film, TV and Media Production (Top-up)",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Games Technologies",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Games Technologies (Top-up)",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Communication",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Graphic Communication (Top-up)",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Healthcare Practice",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Healthcare Practice",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Illustration",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Integrated Health, Social Care and Well-being",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Integrated Health, Social Care and Well-being (Top-up)",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior and Spatial Design",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Interior and Spatial Design (Top-up)",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Live Performance Technical Production",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-64"
                },
                {
                    "course_name": "Mechanical Manufacturing Engineering (Top-up)",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Sound Production",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Musical Theatre",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48-64"
                },
                {
                    "course_name": "Musical Theatre",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48-64"
                },
                {
                    "course_name": "Musical Theatre (Top-up)",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Networking and Cyber Security",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Photography",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Physical Education and Coaching",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Popular Music Performance",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "BMus (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-64"
                },
                {
                    "course_name": "Psychology with Sociology",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Psychology with Sociology (Top-up)",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health and Health Improvement (Top-up)",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sports Performance Conditioning and Rehabilitation",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Tourism and Events Management",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Travel, Hospitality and Events Management (Top-up)",
                    "university": "Newcastle College University Centre",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "5a4fc021e8d2e3292c23617bed251e48",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "ed5254ffdf00b69108805e5c16b1a8b8",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Early Childhood Education and Care",
                    "university": "Norland",
                    "location": "Norland (Main Site)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "bb34b6163b04ab7f66922b1d22c90cf7",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "North Hertfordshire College",
                    "location": "Hitchin",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "bf03c20cfefaece4d9e6fb3c2865cd9f",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "1138901e9ffb0b755a27ed26af7308da",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "b46f01e1a60dab6748f452da34b095f7",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "243c74f56627bbc7080e82f8e252541f",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Nutrition Science and Practice",
                    "university": "Northern College of Acupuncture",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Chinese Herbal Medicine",
                    "university": "Northern College of Acupuncture",
                    "location": "York",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "03e561175f4db89f4cd9c896520efdc6",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Acting for Stage and Screen",
                    "university": "The Northern School of Art",
                    "location": "The Northern School of Art",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Animation",
                    "university": "The Northern School of Art",
                    "location": "The Northern School of Art",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Costume Interpretation with Design",
                    "university": "The Northern School of Art",
                    "location": "The Northern School of Art",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Digital Design and Advertising",
                    "university": "The Northern School of Art",
                    "location": "The Northern School of Art",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Fashion",
                    "university": "The Northern School of Art",
                    "location": "The Northern School of Art",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Film, TV & Theatre Production",
                    "university": "The Northern School of Art",
                    "location": "The Northern School of Art",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Fine Art",
                    "university": "The Northern School of Art",
                    "location": "The Northern School of Art",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "The Northern School of Art",
                    "location": "The Northern School of Art",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Illustration for Commercial Application",
                    "university": "The Northern School of Art",
                    "location": "The Northern School of Art",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Model Making & Visual FX",
                    "university": "The Northern School of Art",
                    "location": "The Northern School of Art",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Photography",
                    "university": "The Northern School of Art",
                    "location": "The Northern School of Art",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Production Design for Stage & Screen",
                    "university": "The Northern School of Art",
                    "location": "The Northern School of Art",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Textiles & Surface Design",
                    "university": "The Northern School of Art",
                    "location": "The Northern School of Art",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Arts Practice",
                    "university": "The Northern School of Art",
                    "location": "The Northern School of Art",
                    "qualification": "MArt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design History",
                    "university": "The Northern School of Art",
                    "location": "The Northern School of Art",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Practice",
                    "university": "The Northern School of Art",
                    "location": "The Northern School of Art",
                    "qualification": "MArt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Directing & Theatre Making",
                    "university": "The Northern School of Art",
                    "location": "The Northern School of Art",
                    "qualification": "MArts",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        }
    },
    {
        "id": "0b1808702f8e8c0080905c717d24573e",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "23b12a2fa3e95cb03d7296cad3b2e6f4",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Accounting and Finance (Completion Award)",
                    "university": "Northumbria University, Newcastle",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Accounting and Finance Completion Award",
                    "university": "Northumbria University, Newcastle",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Accounting, Finance and Economics Foundation Year",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "American Studies",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Applied Sciences Foundation Year",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Architecture",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Architecture and Built Environment Foundation Year",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Automotive Engineering",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Automotive Engineering",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biology",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Building Surveying",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business and Analytics",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business and Entrepreneurship",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business and Human Resource Management",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business and Management Foundation Year",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business and Marketing",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business and Supply Chain Management",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Computing",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Enterprise and Innovation Management",
                    "university": "Northumbria University, Newcastle",
                    "location": "Northumbria University London Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Business Enterprise and Innovation Management Completion Award",
                    "university": "Northumbria University, Newcastle",
                    "location": "Northumbria University London Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Business Management",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Childhood and Early Years Studies",
                    "university": "Northumbria University, Newcastle",
                    "location": "Coach Lane Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer and Information Technology (with Foundation Year)",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computing with Artificial Intelligence Technology",
                    "university": "Northumbria University, Newcastle",
                    "location": "Northumbria University London Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computing with Cyber Security Technology",
                    "university": "Northumbria University, Newcastle",
                    "location": "Northumbria University London Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computing with Data Science and Big Data Technology",
                    "university": "Northumbria University, Newcastle",
                    "location": "Northumbria University London Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computing with Information Technology Management",
                    "university": "Northumbria University, Newcastle",
                    "location": "Northumbria University London Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computing with Technology",
                    "university": "Northumbria University, Newcastle",
                    "location": "Northumbria University London Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Construction Management",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology and Forensic Science",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Data Science",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Design Foundation Year",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Digital Design",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Economics",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English Language and Literature",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English Language Studies",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English Literature",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English Literature and American Studies (Newcastle)",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English Literature and Creative Writing",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English Literature and History",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fashion",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fashion Communication",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fashion Design and Marketing",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Film",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Film and TV Production",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Finance and Investment Management",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Food Science and Nutrition",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Foundation Year Film, Media, Theatre and Performance",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Foundation Year Fine Art",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Foundation year in Law",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Furniture and Product Design",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Games Computing",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Geography",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Geography",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Geography and Environmental Sciences Foundation Year",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Global Business Management",
                    "university": "Northumbria University, Newcastle",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Global Business Management (Finance)",
                    "university": "Northumbria University, Newcastle",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Global Business Management (Finance) Completion Award",
                    "university": "Northumbria University, Newcastle",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Global Business Management (Human Resources)",
                    "university": "Northumbria University, Newcastle",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Global Business Management (Human Resources) Completion Award",
                    "university": "Northumbria University, Newcastle",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Global Business Management (Logistics and Supply Chain)",
                    "university": "Northumbria University, Newcastle",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Global Business Management (Logistics and Supply Chain) Completion Award",
                    "university": "Northumbria University, Newcastle",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Global Business Management (Marketing)",
                    "university": "Northumbria University, Newcastle",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Global Business Management (Marketing) Completion Award",
                    "university": "Northumbria University, Newcastle",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Global Business Management Completion Award",
                    "university": "Northumbria University, Newcastle",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Guidance and Counselling",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Health, Education and Social Care Foundation Year",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "History",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "History and American Studies (Newcastle)",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "History and Politics",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Humanities Foundation Year",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Interior Architecture",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Interior Design",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Banking and Finance",
                    "university": "Northumbria University, Newcastle",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "International Banking and Finance Completion Award",
                    "university": "Northumbria University, Newcastle",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "International Business Management",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "International Business Management with French",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120"
                },
                {
                    "course_name": "International Business Management with Spanish",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120"
                },
                {
                    "course_name": "International Relations and Politics",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Tourism, Hospitality and Events",
                    "university": "Northumbria University, Newcastle",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "International Tourism, Hospitality and Events Completion",
                    "university": "Northumbria University, Newcastle",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Law/MLaw",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Marketing",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Mass Communication",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Mass Communication and Business",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "MMath (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mathematics Physics and Electrical Engineering Foundation Year",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Mechanical and Civil Engineering Foundation Year",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 to 5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Media and Communication",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Midwifery Studies",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Music",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Music Foundation Year",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Networks and Cyber Security",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing Science Registered Nurse (Child)",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing Science, Registered Nurse (Adult)",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing Science, Registered Nurse (Learning Disabilities)",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing Science, Registered Nurse (Mental Health)",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "Northumbria University, Newcastle",
                    "location": "Coach Lane Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Operating Department Practice",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Physical Geography",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Physics",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Physics",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "Northumbria University, Newcastle",
                    "location": "Coach Lane Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Primary Education (3-7 and 7-11)",
                    "university": "Northumbria University, Newcastle",
                    "location": "Coach Lane Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Product Design",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Psychology Foundation Year",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Psychology with Criminology",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Quantity Surveying",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Real Estate",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Social Sciences Foundation Year",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Social Work",
                    "university": "Northumbria University, Newcastle",
                    "location": "Coach Lane Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sociology",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Sport Coaching",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport Foundation Year",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Sport Management",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Sport, Exercise and Nutrition",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Theatre and Performance",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Tourism and Events Management",
                    "university": "Northumbria University, Newcastle",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "67ac32bf1d910b3a10ab214fcf3c4e69",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Acting",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Acting with Diploma in Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Acting with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Animation",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Animation (with Integrated Foundation Year)",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Animation (with Integrated Foundation Year) with Diploma in Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Animation (with Integrated Foundation Year) with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Animation and Visual Effects (VFX)",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Animation and Visual Effects (VFX) with Diploma in Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Animation and Visual Effects (VFX) with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Animation and Visual Effects (with Integrated Foundation Year)",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Animation and Visual Effects (with Integrated Foundation Year) with Diploma in Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Animation and Visual Effects (with Integrated Foundation Year) with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Animation with Diploma in Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Animation with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Architecture",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Architecture (with Integrated Foundation Year)",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Computing with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Technology and Robotics",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Technology and Robotics with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Design for Publishing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Design for Publishing (with Integrated Foundation Year)",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Design for Publishing (with Integrated Foundation Year) with Diploma in Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Design for Publishing (with Integrated Foundation Year) with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Design for Publishing with Diploma in Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Design for Publishing with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Electronic Music and Sound Production",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Esports",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Fashion",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Fashion (with Integrated Foundation Year)",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Fashion (with Integrated Foundation Year) with Diploma in Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Fashion (with Integrated Foundation Year) with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Fashion Communication and Promotion",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Fashion Communication and Promotion (with Integrated Foundation Year)",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Fashion Communication and Promotion (with Integrated Foundation Year) with Diploma in Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Fashion Communication and Promotion (with Integrated Foundation Year) with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Fashion Communication and Promotion with Diploma in Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Fashion Communication and Promotion with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Fashion Marketing and Business",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Fashion Marketing and Business (with Integrated Foundation Year)",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Fashion Marketing and Business (with Integrated Foundation Year) with Diploma in Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Fashion Marketing and Business (with Integrated Foundation Year) with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Fashion Marketing and Business with Diploma in Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Fashion Marketing and Business with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Fashion with Diploma in Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Fashion with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Film and Moving Image Production",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Film and Moving Image Production (with Integrated Foundation Year)",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Film and Moving Image Production (with Integrated Foundation Year) with Diploma in Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Film and Moving Image Production (with Integrated Foundation Year) with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Film and Moving Image Production with Diploma in Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Film and Moving Image Production with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Fine Art (with Integrated Foundation Year)",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Fine Art (with Integrated Foundation Year) with Diploma in Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Fine Art (with Integrated Foundation Year) with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Fine Art with Diploma in Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Fine Art with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Games Art and Design",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Games Art and Design (with Integrated Foundation Year)",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Games Art and Design (with Integrated Foundation Year) with Diploma in Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Games Art and Design (with Integrated Foundation Year) with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Games Art and Design with Diploma in Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Games Art and Design with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Games Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Games Development (with Integrated Foundation Year)",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Games Development (with Integrated Foundation Year) with Diploma in Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Games Development (with Integrated Foundation Year) with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Games Development with Diploma in Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Games Development with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Graphic Communication",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Graphic Communication (with Integrated Foundation Year)",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Graphic Communication (with Integrated Foundation Year) with Diploma in Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Graphic Communication (with Integrated Foundation Year) with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Graphic Communication with Diploma in Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Graphic Communication with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Graphic Design (with Integrated Foundation Year)",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Graphic Design (with Integrated Foundation Year) with Diploma in Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Graphic Design (with Integrated Foundation Year) with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Graphic Design with Diploma in Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Graphic Design with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Illustration",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Illustration (with Integrated Foundation Year)",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Illustration (with Integrated Foundation Year) with Diploma in Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Illustration (with Integrated Foundation Year) with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Illustration with Diploma in Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Illustration with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Interior Design",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Interior Design (with Integrated Foundation Year)",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Interior Design (with Integrated Foundation Year) with Diploma in Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Interior Design (with Integrated Foundation Year) with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Interior Design with Diploma in Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Interior Design with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Marketing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Photography",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Photography (with Integrated Foundation Year)",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Photography (with Integrated Foundation Year) with Diploma in Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Photography (with Integrated Foundation Year) with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Photography with Diploma in Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Photography with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Psychology",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Textile Design",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Textile Design (with Integrated Foundation Year)",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Textile Design (with Integrated Foundation Year) with Diploma in Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Textile Design (with Integrated Foundation Year) with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Textile Design with Diploma in Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Textile Design with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "User Experience Design (UX)",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "User Experience Design (with Integrated Foundation Year)",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "User Experience Design (with Integrated Foundation Year) with Diploma in Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "User Experience Design (with Integrated Foundation Year) with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "User Experience Design with Diploma in Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "User Experience Design with Diploma in Creative Professional Development",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Advertising",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animation",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "MArch",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication Design",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and AI in Creative Industries",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Illustration",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interaction Design",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Service Design",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Textile Design",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "User Experience Design",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advertising",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animation",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "MArch",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication Design",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Education",
                    "university": "Norwich University of the Arts",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and AI in Creative Industries",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Computing",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Illustration",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interaction Design",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Service Design",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Textile Design",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "User Experience Design",
                    "university": "Norwich University of the Arts",
                    "location": "Norwich University of the Arts",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "1b3b204d309f0d0aece25f46f23896b5",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "b98c936ecef4a03486c04e144facef74",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "13c20d19874f1aa1007468180865ef13",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Theology",
                    "university": "Oak Hill Theological College",
                    "location": "Oak Hill College",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology",
                    "university": "Oak Hill Theological College",
                    "location": "Oak Hill College",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology",
                    "university": "Oak Hill Theological College",
                    "location": "Oak Hill College",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Theology",
                    "university": "Oak Hill Theological College",
                    "location": "Oak Hill College",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology",
                    "university": "Oak Hill Theological College",
                    "location": "Oak Hill College",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology",
                    "university": "Oak Hill Theological College",
                    "location": "Oak Hill College",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "2fd9052aeb7e5602f6f7c32ca9635a5a",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Computing for England - Cyber Security (HTQ)",
                    "university": "Oaklands College",
                    "location": "Welwyn Garden City Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computing for England - Network Engineering (HTQ)",
                    "university": "Oaklands College",
                    "location": "Welwyn Garden City Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Construction Management for England (HTQ)",
                    "university": "Oaklands College",
                    "location": "Welwyn Garden City Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Digital Technologies for England - Artificial Intelligence (HTQ)",
                    "university": "Oaklands College",
                    "location": "Welwyn Garden City Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Electrical & Electronic Engineering (HTQ)",
                    "university": "Oaklands College",
                    "location": "Welwyn Garden City Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Healthcare Professions Support for England (HTQ)",
                    "university": "Oaklands College",
                    "location": "Welwyn Garden City Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Mechanical Engineering (HTQ)",
                    "university": "Oaklands College",
                    "location": "Welwyn Garden City Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Modern Methods of Construction (HTQ)",
                    "university": "Oaklands College",
                    "location": "Welwyn Garden City Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "f3dca0910a385b6df50b6e2542545762",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "4e107a79e92dc35af405927d292f32c8",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "14fd9c1c741fb6d24c9fd2fae6ac0fbd",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "41c5ddfc7248aad65b3eaa601340c58d",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Economics",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Animal Behaviour and Welfare (Solihull College and University Centre)",
                    "university": "Oxford Brookes University",
                    "location": "Solihull College",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Animal Behaviour and Welfare (Solihull College and University Centre)",
                    "university": "Oxford Brookes University",
                    "location": "Solihull College",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Therapy and Rehabilitation (Abingdon and Witney College)",
                    "university": "Oxford Brookes University",
                    "location": "Abingdon and Witney College",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Animal Therapy and Rehabilitation (Top Up) (Abingdon and Witney College)",
                    "university": "Oxford Brookes University",
                    "location": "Abingdon and Witney College",
                    "qualification": "BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Anthropology and Communication, Media and Culture (joint honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Anthropology and English Literature",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Anthropology and Geography (joint honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Anthropology and History (joint honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Anthropology and History of Art (joint honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Anthropology and International Relations (joint honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Anthropology and Philosophy (joint honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Anthropology and Sociology (joint honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Architecture",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Automotive Engineering with Electric Vehicles",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Automotive Engineering with Electric Vehicles",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biodiversity and Wildlife Conservation",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Biodiversity and Wildlife Conservation (MBiol)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "MBiol (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-136"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Biological Sciences (Genetics and Genomics)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Biological Sciences (Genetics and Genomics) (MBiol)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "MBiol (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-136"
                },
                {
                    "course_name": "Biological Sciences (Human Biosciences)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Biological Sciences (Human Biosciences) (MBiol)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "MBiol (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-136"
                },
                {
                    "course_name": "Biological Sciences (MBiol)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "MBiol (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-136"
                },
                {
                    "course_name": "Biological Sciences (Zoology)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Biological Sciences (Zoology) (MBiol)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "MBiol (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-136"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Building Surveying",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Built Environment Foundation",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business and Finance",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business and Law",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business and Management",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business and Management Practice (Abingdon and Witney College)",
                    "university": "Oxford Brookes University",
                    "location": "Abingdon and Witney College",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management Practice (Solihull College and University Centre)",
                    "university": "Oxford Brookes University",
                    "location": "Solihull College",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management Practice (Top-Up) (Abingdon and Witney College)",
                    "university": "Oxford Brookes University",
                    "location": "Abingdon and Witney College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management Practice (Top-Up) (Solihull College - Blossomfield campus)",
                    "university": "Oxford Brookes University",
                    "location": "Solihull College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Marketing Management",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Business and Marketing Management (Final Year Entry)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business English (Final Year Entry)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Finance (Final Year Entry)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (Final Year Entry)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and Geography",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management and International Relations",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business, Enterprise and Entrepreneurship",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business, Finance with Accounting (Final Year Entry)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication, Media and Culture",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Communication, Media and Culture and English Literature",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Communication, Media and Culture and International Relations (joint honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Communication, Media and Culture and Politics (joint honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Communication, Media and Culture and Sociology (joint honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science for Cyber Security",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computing - (Final Year Entry)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Foundation",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Criminology and Anthropology",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Criminology and Education Studies",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Criminology and English Literature",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Criminology and History",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Criminology and International Relations",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Criminology and Law",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Criminology and Politics",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Digital Media Production",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Drama, Theatre and Acting",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Early Childhood Studies (Abingdon and Witney College)",
                    "university": "Oxford Brookes University",
                    "location": "Abingdon and Witney College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Childhood Studies (New College Swindon)",
                    "university": "Oxford Brookes University",
                    "location": "New College Swindon",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Childhood Studies (Solihull College and University Centre)",
                    "university": "Oxford Brookes University",
                    "location": "Solihull College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Childhood Studies (Top-Up) (Wiltshire College and University Centre, Trowbridge Campus)",
                    "university": "Oxford Brookes University",
                    "location": "Wiltshire College and University Centre",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Years (Abingdon and Witney College)",
                    "university": "Oxford Brookes University",
                    "location": "Abingdon and Witney College",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early years (New College Swindon)",
                    "university": "Oxford Brookes University",
                    "location": "New College Swindon",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Years (Solihull College and University Centre)",
                    "university": "Oxford Brookes University",
                    "location": "Solihull College",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Economics, Finance and International Business",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Economics, Politics and International Relations",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Education and Lifelong Learning (Activate Learning - City of Oxford College)",
                    "university": "Oxford Brookes University",
                    "location": "Activate Learning-City of Oxford College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education and Lifelong Learning (New College Swindon)",
                    "university": "Oxford Brookes University",
                    "location": "New College Swindon",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Education Studies and English Literature",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Education Studies and Sociology",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Educational Practice (New College Swindon)",
                    "university": "Oxford Brookes University",
                    "location": "New College Swindon",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "16"
                },
                {
                    "course_name": "Electrical and Electronic Engineering (Solihull College and University Centre)",
                    "university": "Oxford Brookes University",
                    "location": "Solihull College",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering (Solihull College and University Centre)",
                    "university": "Oxford Brookes University",
                    "location": "Solihull College",
                    "qualification": "FdEng",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electro-Mechanical Engineering",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Electronic Engineering BEng (Final Year Entry)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Foundation",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "English Literature",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "English Literature and History",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Literature and History of Art",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Literature and Philosophy",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Literature with Creative Writing",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Equine Science",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Events Management",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Film",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fine Art and History of Art",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Foundation in Business",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Foundation in Humanities",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Foundation in Law",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Furniture: Design and Make (Activate Learning - City of Oxford College)",
                    "university": "Oxford Brookes University",
                    "location": "Activate Learning-City of Oxford College",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Furniture: Design and Make (Activate Learning - City of Oxford College)",
                    "university": "Oxford Brookes University",
                    "location": "Activate Learning-City of Oxford College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Geography and History (joint honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Geography and International Relations (joint honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Health and Social Care (New College Swindon)",
                    "university": "Oxford Brookes University",
                    "location": "New College Swindon",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "History and History of Art (joint honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "History and International Relations (joint honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "History and Politics (joint honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "History of Art",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "History of Art and Sociology (joint honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Illustration for Comics (New College Swindon)",
                    "university": "Oxford Brookes University",
                    "location": "New College Swindon",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Information Technology for Business",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Information Technology for Business (Final Year Entry)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Architecture",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "International Business  (Final Year Entry)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Foundation Arts, Humanities and Law",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "International Foundation Business and Technology",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "International Hospitality and Tourism Management (Final Year Entry)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Philosophy",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "International Relations and Politics",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "International Relations and Sociology",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "International Tourism and Hospitality Management",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Japanese Studies",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Law",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Law (Final Year Entry)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Business",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Liberal Arts",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Life Sciences Foundation Year",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 to 6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Manufacturing Engineering (New College Swindon)",
                    "university": "Oxford Brookes University",
                    "location": "New College Swindon",
                    "qualification": "FdEng",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Manufacturing Engineering (Swindon & Wiltshire Institute of Technology,  New College Swindon)",
                    "university": "Oxford Brookes University",
                    "location": "New College Swindon",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing and Events Management",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Marketing Management",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Mechanical Engineering (Solihull College and University Centre)",
                    "university": "Oxford Brookes University",
                    "location": "Solihull College",
                    "qualification": "FdEng",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Mechanical Engineering Design",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mechanical Engineering Design (Solihull College and University Centre)",
                    "university": "Oxford Brookes University",
                    "location": "Solihull College",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media, Journalism and Publishing",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Medical Science",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Medical Sciences (Final Year Entry)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Modern Languages with Translation (French, Japanese, Korean, Mandarin, Spanish, English as a Foreign Language)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Motorsport Engineering",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Motorsport Engineering",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Motorsport Engineering (Wiltshire College and University Centre, Castle Combe Race Circuit)",
                    "university": "Oxford Brookes University",
                    "location": "Wiltshire College and University Centre",
                    "qualification": "FdEng",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "16"
                },
                {
                    "course_name": "Motorsport Technology",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Motorsport Technology (Top-up) (Wiltshire College and University Centre - Castle Coombe Campus)",
                    "university": "Oxford Brookes University",
                    "location": "Wiltshire College and University Centre",
                    "qualification": "BEng",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Nursing (Adult) (Swindon Campus)",
                    "university": "Oxford Brookes University",
                    "location": "Swindon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Nursing (Children's)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Nursing Associate",
                    "university": "Oxford Brookes University",
                    "location": "Swindon Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Nutrition",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Nutrition Science (Final Year Entry)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Paramedic Science",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Philosophy and Politics (Joint Honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Philosophy and Sociology (Joint Honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Photography",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Politics and Sociology (Joint Honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Primary Teacher Education (Campus-based)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Professional Business and Management Practice (Solihull College and University Centre)",
                    "university": "Oxford Brookes University",
                    "location": "Solihull College",
                    "qualification": "BA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Development in Health and Social Care (New College Swindon)",
                    "university": "Oxford Brookes University",
                    "location": "New College Swindon",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Property Development and Planning",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Psychology",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Quantity Surveying and Commercial Management",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Real Estate",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Social Work",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sociology",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sport, Coaching and Physical Education",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sport, Physical Activity & Health Promotion",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sports Coaching, Fitness and Rehabilitation (Activate Learning - City of Oxford College)",
                    "university": "Oxford Brookes University",
                    "location": "Activate Learning-City of Oxford College",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Coaching, Fitness and Rehabilitation (Activate Learning - City of Oxford College)",
                    "university": "Oxford Brookes University",
                    "location": "Activate Learning-City of Oxford College",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Design, Planning and Development",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Accounting and Economics",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Animal Therapy and Rehabilitation (Abingdon and Witney College)",
                    "university": "Oxford Brookes University",
                    "location": "Abingdon and Witney College",
                    "qualification": "FdSc",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Animal Therapy and Rehabilitation (Top Up) (Abingdon and Witney College)",
                    "university": "Oxford Brookes University",
                    "location": "Abingdon and Witney College",
                    "qualification": "BSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Anthropology and Communication, Media and Culture (joint honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Anthropology and English Literature",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Anthropology and Geography (joint honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Anthropology and History (joint honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Anthropology and History of Art (joint honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Anthropology and International Relations (joint honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Anthropology and Philosophy (joint honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Anthropology and Sociology (joint honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Architecture",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "9 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Automotive Engineering with Electric Vehicles",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Automotive Engineering with Electric Vehicles",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "10 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Biodiversity and Wildlife Conservation",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Biodiversity and Wildlife Conservation (MBiol)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "MBiol (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112-136"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Biological Sciences (Genetics and Genomics)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Biological Sciences (Genetics and Genomics) (MBiol)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "MBiol (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112-136"
                },
                {
                    "course_name": "Biological Sciences (Human Biosciences)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Biological Sciences (Human Biosciences) (MBiol)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "MBiol (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112-136"
                },
                {
                    "course_name": "Biological Sciences (MBiol)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "MBiol (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112-136"
                },
                {
                    "course_name": "Biological Sciences (Zoology)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Biological Sciences (Zoology) (MBiol)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "MBiol (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112-136"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Building Surveying",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Built Environment Foundation",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business and Finance",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business and Law",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business and Management",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business and Marketing Management",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Business and Marketing Management (Final Year Entry)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (Final Year Entry)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and Geography",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management and International Relations",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business, Enterprise and Entrepreneurship",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Communication, Media and Culture",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Communication, Media and Culture and English Literature",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Communication, Media and Culture and International Relations (joint honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Communication, Media and Culture and Politics (joint honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Communication, Media and Culture and Sociology (joint honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computer Science for Cyber Security",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Computing - (Final Year Entry)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Foundation",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Criminology and Anthropology",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Criminology and Education Studies",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Criminology and English Literature",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Criminology and History",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Criminology and International Relations",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Criminology and Law",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Criminology and Politics",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Digital Media Production",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Drama, Theatre and Acting",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Economics",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Economics, Finance and International Business",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Economics, Politics and International Relations",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Education Studies",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Education Studies and English Literature",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Education Studies and Sociology",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Electrical and Electronic Engineering (Solihull College and University Centre)",
                    "university": "Oxford Brookes University",
                    "location": "Solihull College",
                    "qualification": "FdEng",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering (Solihull College and University Centre)",
                    "university": "Oxford Brookes University",
                    "location": "Solihull College",
                    "qualification": "BEng (Hon)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electro-Mechanical Engineering",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Electronic Engineering BEng (Final Year Entry)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Foundation",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "English Literature",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "English Literature and History",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Literature and History of Art",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time block-release",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Literature and Philosophy",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Literature with Creative Writing",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Events Management",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Film",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fine Art and History of Art",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Foundation in Humanities",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Foundation in Law",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Geography",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Geography and History (joint honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Geography and International Relations (joint honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "History",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "History and History of Art (joint honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "History and International Relations (joint honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "History and Politics (joint honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "History of Art",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "History of Art and Sociology (joint honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Information Technology for Business",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Information Technology for Business (Final Year Entry)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Architecture",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "International Business  (Final Year Entry)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Relations and Philosophy",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "International Relations and Politics",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "International Relations and Sociology",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "International Tourism and Hospitality Management",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Japanese Studies",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Law",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Law (Final Year Entry)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Business",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Liberal Arts",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Life Sciences Foundation Year",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "8 to 12 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Marketing and Events Management",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Marketing Management",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "10 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Mechanical Engineering Design",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Media, Journalism and Publishing",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Medical Science",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Modern Languages with Translation (French, Japanese, Korean, Mandarin, Spanish, English as a Foreign Language)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Motorsport Engineering",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "10 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Motorsport Engineering",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Motorsport Technology",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Nursing (Adult) (Swindon Campus)",
                    "university": "Oxford Brookes University",
                    "location": "Swindon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Nutrition",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Philosophy and Politics (Joint Honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Philosophy and Sociology (Joint Honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Photography",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Politics and Sociology (Joint Honours)",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Professional Business and Management Practice (Solihull College and University Centre)",
                    "university": "Oxford Brookes University",
                    "location": "Solihull College",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Property Development and Planning",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Psychology",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Quantity Surveying and Commercial Management",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Real Estate",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Social Work",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sociology",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sport, Coaching and Physical Education",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sport, Physical Activity & Health Promotion",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Urban Design, Planning and Development",
                    "university": "Oxford Brookes University",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "41e7a0677b0a37a5f04a6e71687150e1",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Ancient and Modern History",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient and Modern History with Foundation Year",
                    "university": "University of Oxford",
                    "location": "Open Application",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arabic",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and Anthropology",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Asian and Middle Eastern Studies with Classics",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Assyriology",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Beginners' German and Linguistics",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Beginners' Italian and Czech (with Slovak)",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Beginners' Italian and Linguistics",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Beginners' Italian and Modern Greek",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Beginners' Italian and Portuguese",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Beginners' Italian and Russian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Beginners' Italian and Spanish",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Beginners' Modern Greek and Czech (with Slovak)",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Beginners' Modern Greek and Linguistics",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Beginners' Portuguese and Czech (with Slovak)",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Beginners' Portuguese and Linguistics",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Beginners' Portuguese and Modern Greek",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Beginners' Portuguese and Russian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry (Molecular and Cellular)",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "MBiochem",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "MBiol (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering with Foundation Year",
                    "university": "University of Oxford",
                    "location": "Open Application",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "MBiomedSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with Foundation Year",
                    "university": "University of Oxford",
                    "location": "Open Application",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Foundation Year",
                    "university": "University of Oxford",
                    "location": "Open Application",
                    "qualification": "MChem (H)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with Foundation Year",
                    "university": "University of Oxford",
                    "location": "Open Application",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Archaeology and Ancient History",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Archaeology and Ancient History with Foundation Year",
                    "university": "University of Oxford",
                    "location": "Open Application",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Beginners' Czech (with Slovak) Option A",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Beginners' Czech (with Slovak) Option B",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Beginners' German Option A",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Beginners' German Option B",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Beginners' Italian Option A",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Beginners' Italian Option B",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Beginners' Modern Greek Option A",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Beginners' Modern Greek Option B",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Beginners' Portuguese Option A",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Beginners' Portuguese Option B",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Czech (with Slovak) Option A",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Czech (with Slovak) Option A with Beginners' Latin or Greek",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Czech (with Slovak) Option B",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and English",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and English with Beginner's Latin and Greek with Foundation Year",
                    "university": "University of Oxford",
                    "location": "Open Application",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and English with Beginners' Latin or Greek",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and English with Foundation Year",
                    "university": "University of Oxford",
                    "location": "Open Application",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and French Option A",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and French Option A with Beginners' Latin or Greek",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and French Option B",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and German Option A",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and German Option A with Beginners' Latin or Greek",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and German Option B",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Italian Option A",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Italian Option A with Beginners' Latin or Greek",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Italian Option B",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Modern Greek Option A",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Modern Greek Option A with Beginners' Latin or Greek",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Modern Greek Option B",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Portuguese Option A",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Portuguese Option A with Beginners' Latin or Greek",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Portuguese Option B",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Russian Option A",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Russian Option A with Beginners' Latin or Greek",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Russian Option B",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Spanish Option A",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Spanish Option A with Beginners' Latin or Greek",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Spanish Option B",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics with Asian and Middle Eastern Studies",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics with Foundation Year",
                    "university": "University of Oxford",
                    "location": "Open Application",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "MComp",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Philosophy",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "MComp",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Czech (with Slovak) and Arabic",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Czech (with Slovak) and Beginners' German",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Czech (with Slovak) and Beginners' Russian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Czech (with Slovak) and Hebrew",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Czech (with Slovak) and Persian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Czech (with Slovak) and Turkish",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth Sciences",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "MESc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Management",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Egyptology",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Engineering",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Engineering with Foundation Year",
                    "university": "University of Oxford",
                    "location": "Open Application",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering with Foundation Year",
                    "university": "University of Oxford",
                    "location": "Open Application",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Beginners' Czech (with Slovak)",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Beginners' German",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Beginners' Italian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Beginners' Modern Greek",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Beginners' Portuguese",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Czech (with Slovak)",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and French",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and German",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Italian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Modern Greek",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Portuguese",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Russian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Spanish",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Literature",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Literature with Foundation Year",
                    "university": "University of Oxford",
                    "location": "Open Application",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Experimental Psychology",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BFA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Arabic",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Beginners' Czech (with Slovak)",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Beginners' German",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Beginners' Italian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Beginners' Modern Greek",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Beginners' Portuguese",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Beginners' Russian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Czech (with Slovak)",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and German",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Hebrew",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Italian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Linguistics",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Modern Greek",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Persian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Portuguese",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Russian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Spanish",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Turkish",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French with Polish",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Arabic",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Beginners' Czech (with Slovak)",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Beginners' Italian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Beginners' Modern Greek",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Beginners' Portuguese",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Beginners' Russian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Czech (with Slovak)",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Hebrew",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Italian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Linguistics",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Modern Greek",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Persian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Portuguese",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Russian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Spanish",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Turkish",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German with Polish",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hebrew",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Beginners' Czech (with Slovak)",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Beginners' German",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Beginners' Italian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Beginners' Modern Greek",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Beginners' Portuguese",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Czech (with Slovak)",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Economics",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and English",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and English with Foundation Year",
                    "university": "University of Oxford",
                    "location": "Open Application",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and French",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and German",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Italian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Modern Greek",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Politics",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Portuguese",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Russian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Spanish",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History with Foundation Year",
                    "university": "University of Oxford",
                    "location": "Open Application",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Sciences",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Engineering",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Engineering with Foundation Year",
                    "university": "University of Oxford",
                    "location": "Open Application",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Arabic",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Beginners' Czech (with Slovak)",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Beginners' German",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Beginners' Modern Greek",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Beginners' Portuguese",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Beginners' Russian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Czech (with Slovak)",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Hebrew",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Linguistics",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Modern Greek",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Persian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Portuguese",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Russian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Spanish",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Turkish",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Japanese",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Jewish Studies",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with European Law",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Foundation Year",
                    "university": "University of Oxford",
                    "location": "Open Application",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with French Law",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with German Law",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Italian Law",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Spanish Law",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science with Foundation Year",
                    "university": "University of Oxford",
                    "location": "Open Application",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Computer Science",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "MMath",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Philosophy",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "MMath",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics/Mathematics and Statistics",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "MMath",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Foundation Year",
                    "university": "University of Oxford",
                    "location": "Open Application",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BMBCh",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine (Fast-track, Graduate Entry only)",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BMBCh",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Greek and Arabic",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Greek and Beginners' Czech (with Slovak)",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Greek and Beginners' German",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Greek and Beginners' Russian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Greek and Czech (with Slovak)",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Greek and Hebrew",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Greek and Linguistics",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Greek and Persian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Greek and Turkish",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Persian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Beginners' Czech (with Slovak)",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Beginners' German",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Beginners' Italian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Beginners' Modern Greek",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Beginners' Portuguese",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Czech (with Slovak)",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and French",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and German",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Italian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Linguistics",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Modern Greek",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Portuguese",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Russian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Spanish",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Theology",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Theology with Foundation Year",
                    "university": "University of Oxford",
                    "location": "Open Application",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy, Politics and Economics",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy, Politics and Economics with Foundation Year",
                    "university": "University of Oxford",
                    "location": "Open Application",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics and Philosophy",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "MPhysPhil",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese and Arabic",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese and Beginners' Czech (with Slovak)",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese and Beginners' German",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese and Beginners' Modern Greek",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese and Beginners' Russian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese and Czech (with Slovak)",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese and Hebrew",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese and Linguistics",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese and Modern Greek",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese and Persian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese and Russian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Portuguese and Turkish",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Linguistics",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Philosophy",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religion and Asian and Middle Eastern Studies",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian and Arabic",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian and Beginners' Czech (with Slovak)",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian and Beginners' German",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian and Beginners' Modern Greek",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian and Czech (with Slovak)",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian and Hebrew",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian and Linguistics",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian and Modern Greek",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian and Persian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian and Turkish",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian with Polish",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sanskrit",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Arabic",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Beginners' Czech (with Slovak)",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Beginners' German",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Beginners' Modern Greek",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Beginners' Portuguese",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Beginners' Russian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Czech (with Slovak)",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Hebrew",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Linguistics",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Modern Greek",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Persian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Portuguese",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Russian",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Turkish",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish with Polish",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religion",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religion with Foundation Year",
                    "university": "University of Oxford",
                    "location": "Open Application",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Turkish",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Advanced Computer Science",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "African Studies",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient Philosophy",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Cancer Science",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Digital Health",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Linguistics and Second Language Acquisition",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Theology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MTh",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Theology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeological Science",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeological Science",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "11 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology (Full-time, Social Sciences)",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Area Studies",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Asian and Middle Eastern Studies",
                    "university": "University of Oxford",
                    "location": "No Locations",
                    "qualification": "MSt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astrophysics",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Atmospheric, Oceanic and Planetary Physics",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Atomic and Laser Physics",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Autonomous Intelligent Machines and Systems (EPSRC Centre for Doctoral Training)",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bachelor of Civil Law",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "BCL",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bible Interpretation",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry (Skaggs-Oxford Programme)",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biodiversity, Conservation and Nature Recovery",
                    "university": "University of Oxford",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences (NIH-OxCam)",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Buddhist Studies",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer Science",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic Studies",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Celtic Studies",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Archaeology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Archaeology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Armenian Studies",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Hebrew Studies",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Indian Religion",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Languages and Literature",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical and Therapeutic Neuroscience",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Embryology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Epidemiology and Medical Statistics",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Medicine",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Neurosciences",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Psychology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DClinPsych",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Behavioural Therapy",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "PgCert",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature and Critical Translation",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Social Policy",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPhil (Res)",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Condensed Matter Physics",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary Chinese Studies",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Criminal Justice",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cuneiform Studies",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Development Studies",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Scholarship",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "11 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diplomatic Studies",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth Sciences",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Eastern Christian Studies",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic and Social History",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "11 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics (combined)",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics for Development",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Child Development and Education)",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Comparative and International Education)",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Digital and Social Change)",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Higher Education)",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Research Design and Methodology)",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Egyptology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Systems",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Science",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English (1550-1700)",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English (1700-1830)",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English (1830-1914)",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English (1900-Present)",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English (650-1550)",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and American Studies",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English as an Additional Language",
                    "university": "University of Oxford",
                    "location": "No Locations",
                    "qualification": "PgDip",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Studies (Medieval Period)",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Change and Management",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPhil",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Evidence Based Social Intervention and Policy Evaluation",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Evidence-Based Health Care (Teaching and Education)",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Experimental Psychology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Aesthetics",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Economics",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MFA (PG)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genomic Medicine",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genomic Medicine and Statistics",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography and the Environment",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global and Area Studies",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global and Imperial History",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Governance and Diplomacy",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health Science and Epidemiology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Greek and or Latin Languages and Literature",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Greek and or Roman History",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Service Improvement and Evaluation",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art and Visual Culture",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Science, Medicine and Technology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "11 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Inflammatory and Musculoskeletal Disease",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information, Communication and the Social Sciences",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrated Immunology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Health and Tropical Medicine",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Wildlife Conservation Practice",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "PgDip",
                    "duration": "8 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Islamic Art and Architecture",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Islamic Studies and History",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Japanese Studies",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Jewish Studies",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Jewish Studies in the Graeco-Roman Period",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Judaism and Christianity in the Graeco-Roman World",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Korean Studies",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Late Antique and Byzantine Studies",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Latin American Studies",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPhil",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Finance",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics, Philology and Phonetics",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics, Philology and Phonetics",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Magister Juris",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MJur",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MBA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration: Oxford 1 + 1 MBA Programme",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical and Computational Finance",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical and Theoretical Physics",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical Modelling and Scientific Computing",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical Sciences",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Foundations of Computer Science",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics of Random Systems: Analysis, Modelling and Algorithms (Centre for Doctoral Training)",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Anthropology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Physics with Radiobiology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Sciences",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine: Graduate Entry",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "BMBCh",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval and Modern Languages",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval Studies",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Migration Studies",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Migration Studies",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modelling for Global Health",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Chinese Studies",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern South Asian Studies",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular and Cellular Medicine",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular Cell Biology in Health and Disease",
                    "university": "University of Oxford",
                    "location": "No Locations",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Musculoskeletal Sciences",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc (Res)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music (Composition)",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music (Musicology)",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music (Performance)",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nature, Society and Environmental Governance",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPhil",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience (1+3)",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oncology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paediatrics",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Particle Physics",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "CertEd",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophical Theology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "BPhil",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy of Physics",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiology, Anatomy and Genetics",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Theory Research",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics (Comparative Government)",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics (European Politics and Society)",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics (Political Theory)",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics Research",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Population Health",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Health Care",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychiatry",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Policy",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPP",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Policy",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Policy Research",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Refugee and Forced Migration Studies",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian and East European Studies",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Slavonic Studies",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Data Science",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Intervention and Policy Evaluation",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Science of the Internet",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Socio-Legal Research",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPhil",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Socio-Legal Studies",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Demography",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistical Science",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc (Res)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics and Machine Learning (EPSRC Centre for Doctoral Training)",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Study of Religions",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Surgical Sciences",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability, Enterprise and the Environment",
                    "university": "University of Oxford",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Syriac Studies",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religion",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religion",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical and Computational Chemistry",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Tibetan and Himalayan Studies",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Traditional China",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Traditional East Asia",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translational Health Sciences",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual, Material and Museum Anthropology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPhil",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Water Science, Policy and Management",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MPhil",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Women's and Reproductive Health",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Women's, Gender, and Sexuality Studies",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "World Literatures in English",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Yiddish Studies",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Ancient History",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Landscape Archaeology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Theology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MTh",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Theology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology (Part-Time)",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural History",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural History",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "PgCert",
                    "duration": "11 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence for Business",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "PgDip",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Languages and Literature",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Medicine",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Neurosciences",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc (Res)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Trials",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Behavioural Therapy",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Behavioural Therapy",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Criminal Justice",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "21 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Doctor of Medicine",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DM",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecological Survey Techniques",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Oxford",
                    "location": "University",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Research Design and Methodology)",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Assessment",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Systems",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Science",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Local History",
                    "university": "University of Oxford",
                    "location": "University of Oxford Department for Continuing Education",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Local History",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Enhanced Cognitive Behavioural Therapy",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Evidence Based Health Care",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Evidence Based Health Care",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Evidence Based Health Care (Medical Statistics)",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Evidence Based Health Care (Systematic Reviews)",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Executive MBA",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MBA (Executive)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Experimental and Translational Therapeutics",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Experimental Psychology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Strategy",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "PgDip",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MFA (PG)",
                    "duration": "21 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography and the Environment",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Business",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "PgDip",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health Research",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "PgDip",
                    "duration": "14 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Healthcare Leadership",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Research",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Historical Studies",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "21 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Design",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "21 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information, Communication and the Social Sciences",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrated Immunology (Part-time)",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intellectual Property",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "9 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intellectual Property Law and Practice",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "PgDip",
                    "duration": "10 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Rights Law",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Oxford",
                    "location": "University",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Learning and Teaching",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "10 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics, Philology and Phonetics",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Literature and Arts",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Literature and Arts",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Major Programme Management",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Education",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Sciences",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval and Modern Languages",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Migration Studies",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mindfulness Based Cognitive Therapy",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Musculoskeletal Sciences",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nanotechnology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "PgCert",
                    "duration": "10 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nanotechnology for Medicine and Health Care",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Organisational Leadership",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "PgDip",
                    "duration": "13 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paediatric Infectious Diseases",
                    "university": "University of Oxford",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Patient Safety and Quality Improvement",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Population Health",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Practical Ethics",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Precision Cancer Medicine",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Health Care",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychiatry",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychodynamic Counselling",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "PgCert",
                    "duration": "9 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychodynamic Practice",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSt",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Policy",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Qualitative Health Research Methods",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sleep Medicine",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "PgDip",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Data Science",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "7 years",
                    "study_mode": "Mixed mode",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Intervention and Policy Evaluation",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Science of the Internet",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "22 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Socio-Legal Studies",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software and Systems Security",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics and Epidemiology",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "PgCert",
                    "duration": "7 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics and Machine Learning (EPSRC Centre for Doctoral Training)",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategy and Innovation",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "PgDip",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Surgical Science and Practice",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Urban Development",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Urban Development",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Taxation",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teacher Education",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching Evidence-Based Health Care",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religion",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and Religion",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "PgDip",
                    "duration": "21 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translational Health Sciences",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Women's and Reproductive Health",
                    "university": "University of Oxford",
                    "location": "University of Oxford",
                    "qualification": "DPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "c90b8469c70156bfa1006416ae07af23",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Early Years (Foundation Degree)",
                    "university": "Peter Symonds' College",
                    "location": "Peter Symonds College - Adult and Higher Education",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Early Years (Top-Up)",
                    "university": "Peter Symonds' College",
                    "location": "Peter Symonds College - Adult and Higher Education",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Humanistic and Integrative Counselling (Top-Up)",
                    "university": "Peter Symonds' College",
                    "location": "Peter Symonds College - Adult and Higher Education",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Humanistic Counselling based on Transactional Analysis",
                    "university": "Peter Symonds' College",
                    "location": "Peter Symonds College - Adult and Higher Education",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Special Educational Needs & Disability (Top-Up)",
                    "university": "Peter Symonds' College",
                    "location": "Peter Symonds College - Adult and Higher Education",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Teaching and Learning (Top Up)",
                    "university": "Peter Symonds' College",
                    "location": "Peter Symonds College - Adult and Higher Education",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "6bb4260f9383ab00e8d967063cda9162",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Acting for Stage & Screen",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Adult Care Leadership and Management",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Animal Management (Animal Behaviour and Welfare)",
                    "university": "University Centre Peterborough",
                    "location": "Stamford College",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Animal Management and Welfare (Top-Up)",
                    "university": "University Centre Peterborough",
                    "location": "Stamford College",
                    "qualification": "BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business Management",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Management (Human Resources)",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Management (Management Accounting)",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Management (Marketing)",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Community Coaching for England (HTQ)",
                    "university": "University Centre Peterborough",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Criminology",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Digital Arts",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Digital Arts (Graphic Design)",
                    "university": "University Centre Peterborough",
                    "location": "Stamford College",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Digital Arts (Photography)",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Digital Marketing and Communications",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Digital Marketing and Communications",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Digital Technologies for England (Cloud Networking) (HTQ)",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Digital Technologies for England (Cyber Security) (HTQ)",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Digital Technologies for England (Data Analytics) (HTQ)",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Digital Technologies for England (Software Development and Programming) (HTQ)",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Early Childhood Studies (Top-Up)",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Education Studies (Top-up)",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "English Literature",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "English Literature (Creative Writing)",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "History and Archaeology",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Primary Education",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Primary Education (Top-Up)",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Psychosocial Studies",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Sociology",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Sport and Exercise Science (Coaching)",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Acting for Stage & Screen",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Animal Management (Animal Behaviour and Welfare)",
                    "university": "University Centre Peterborough",
                    "location": "Stamford College",
                    "qualification": "HND",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Animal Management and Welfare (Top-Up)",
                    "university": "University Centre Peterborough",
                    "location": "Stamford College",
                    "qualification": "BSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Management (Human Resources)",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Management (Management Accounting)",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Management (Marketing)",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Criminology",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Digital Arts",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Digital Technologies for England (Cloud Networking) (HTQ)",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "HND",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Digital Technologies for England (Cyber Security) (HTQ)",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "HND",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Digital Technologies for England (Data Analytics) (HTQ)",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "HND",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Digital Technologies for England (Software Development and Programming) (HTQ)",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "HND",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "English Literature",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "English Literature (Creative Writing)",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "History and Archaeology",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Psychosocial Studies",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Sociology",
                    "university": "University Centre Peterborough",
                    "location": "University Centre Peterborough",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "88"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "1a03877fac8c4764ac48808f9e5ab330",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Animal Conservation",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Art & Contemporary Craft",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Creative Media",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Electronics & Communication",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "FdEng",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Engineering",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Healthcare Assistant Practitioner",
                    "university": "Petroc",
                    "location": "Multiple Locations",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Human Behavioural Studies",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Human Biosciences",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Illustration",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Integrated Technologies Engineering",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Professional Development (Business & Management)",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Professional Development (Childhood Studies)",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Professional Development (Health & Social Care)",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Psychological Studies",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "FdEng",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sustainable Environmental Management",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Teaching and Learning",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Animal Conservation",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Art & Contemporary Craft",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "FdA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "FdA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Construction",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "FdA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Engineering",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Human Behavioural Studies",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Human Biosciences",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Illustration",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "FdA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Integrated Technologies Engineering",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Professional Development (Business & Management)",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Professional Development (Childhood Studies)",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Professional Development (Creative Industries)",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Professional Development (Health & Social Care)",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Psychological Studies",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sustainable Environmental Management",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Teaching and Learning",
                    "university": "Petroc",
                    "location": "North Devon Campus",
                    "qualification": "FdA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "4b1a10efa6953da9f99fab744cc747bd",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Applied Animal Behaviour & Welfare (Top-up)",
                    "university": "Plumpton College",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Animal Behaviour and Conservation",
                    "university": "Plumpton College",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Applied Zoology and Animal Conservation (Top-up)",
                    "university": "Plumpton College",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Wine Business",
                    "university": "Plumpton College",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Rural Land and Business Management",
                    "university": "Plumpton College",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sustainable Agriculture",
                    "university": "Plumpton College",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sustainable Horticulture Management",
                    "university": "Plumpton College",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Sustainable Land Management",
                    "university": "Plumpton College",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Veterinary Nursing",
                    "university": "Plumpton College",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Veterinary Physiotherapy",
                    "university": "Plumpton College",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Viticulture & Oenology",
                    "university": "Plumpton College",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Wine Business",
                    "university": "Plumpton College",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Wine Production",
                    "university": "Plumpton College",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Rural Land and Business Management",
                    "university": "Plumpton College",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Block-release",
                    "ucas_points": "104"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Viticulture & Oenology",
                    "university": "Plumpton College",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Viticulture & Oenology",
                    "university": "Plumpton College",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "30 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "af1b8eef7a9c98c2a24703ffa03858c0",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Acting for Stage and Screen",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Computer Science (top-up)",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Forensic Science",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Applied Sciences",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Civil Engineering (Top up)",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Community and Public Service Management",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Community and Public Service Management (Healthcare)",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Community and Public Services",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Computer Systems Development",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Construction",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Construction & Digital Innovation",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Creative Media Production",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Dance",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Dance",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Film and Creative Media Production (Top Up)",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Production",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Game Design and Production",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Hospitality and Hotel Management",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Integrated Technologies Engineering",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interactive Digital Immersion",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tourism Management",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Marine Autonomous Vehicles",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Marine Engineering",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Music Practitioner",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Music Practitioner (Top-Up)",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Naval Architecture",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Software Development",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sports Rehabilitation (with pathways)",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Therapy and Injury Rehabilitation",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Strength Conditioning and Sports Coaching",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Civil Engineering",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Civil Engineering PT",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Community and Public Services",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computer Systems Development",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Construction",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Construction & Digital Innovation",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Electrical & Electronic Engineering",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Marine Engineering",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Marine Engineering PT",
                    "university": "City College Plymouth",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "a7d18cd0460d48ca3642eec39d7aa318",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accountancy and Financial Management (Top-up)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting with Finance",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Accounting with Finance with Foundation Year",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Accounting with International Finance (Top-up)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Adult Nursing (Postgraduate Entry)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MN",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Dental Nursing Professional Practice (Top-up)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animation",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Applied Languages (Chinese, French, German, Spanish, English as a Foreign Language)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Biology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Biomedical Science with Human Biosciences (Dual Degree)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Building Surveying",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Business",
                    "university": "University of Portsmouth",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-56"
                },
                {
                    "course_name": "Business and Human Resource Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Business and Human Resource Management with Foundation Year",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Business and Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Business and Management",
                    "university": "University of Portsmouth",
                    "location": "University of Portsmouth London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Business and Management (Top Up)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management with Foundation Year",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Business and Supply Chain Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Business and Supply Chain Management with Foundation Year",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Business with Business Communication (Top-up)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Childhood and Youth Studies",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Childhood and Youth Studies with Criminology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Childhood and Youth Studies with Psychology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Cognitive Behavioural Therapy",
                    "university": "University of Portsmouth",
                    "location": "South Hampshire College Group - Eastleigh Campus",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Cognitive Behavioural Therapy (Top-up)",
                    "university": "University of Portsmouth",
                    "location": "South Hampshire College Group - Eastleigh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Animation and Visual Effects",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Games Art",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Computer Games Design",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Computer Games Production",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Computer Games Technology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Computer Networks and Security",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Portsmouth",
                    "location": "University of Portsmouth London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computing",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computing",
                    "university": "University of Portsmouth",
                    "location": "City of Portsmouth College",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-56"
                },
                {
                    "course_name": "Computing",
                    "university": "University of Portsmouth",
                    "location": "Isle of Wight College",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "24"
                },
                {
                    "course_name": "Construction Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Counter Terrorism, Intelligence and Cybercrime (Dual Degree)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Creative Computing",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Creative Computing",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Creative Media Technologies",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Creative Media Technologies (L6 Top-Up)",
                    "university": "University of Portsmouth",
                    "location": "Chichester College",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Music Technology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Creative Music Technology (Top-Up)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Criminology and Cybercrime",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Criminology and Forensic Investigation",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Criminology and Psychology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Cyber Security and Forensic Computing",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Data Science and Analytics",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Dental Hygiene",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Dental Hygiene and Dental Therapy",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Dental Nursing",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Diagnostic Radiography and Medical Imaging",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Early Years Care and Education",
                    "university": "University of Portsmouth",
                    "location": "Isle of Wight College",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth Science",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Economics and Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Economics and Management with Foundation Year",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Economics, Finance and Banking",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Economics, Finance and Banking",
                    "university": "University of Portsmouth",
                    "location": "University of Portsmouth London",
                    "qualification": "BSc Econ H",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Education Studies (Top-Up)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Power Engineering",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Engineering and Technology with Foundation Year",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56-64"
                },
                {
                    "course_name": "English and Creative Writing",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "English Language and Linguistics",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Entrepreneurship and Business",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Entrepreneurship and Business with Foundation Year",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Environmental Science and Management (Dual Degree)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Fashion Design",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Fashion Marketing",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Film Production",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Film Studies",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Finance with Business Communication (Top-up)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Further Education and Skills",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "CertEd",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Gestalt Counselling",
                    "university": "University of Portsmouth",
                    "location": "South Hampshire College Group - Eastleigh Campus",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Gestalt Counselling (Top-up)",
                    "university": "University of Portsmouth",
                    "location": "South Hampshire College Group - Eastleigh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Communication and Media (Dual Degree)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Global Sport Management (Dual Degree)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "History",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "History and Politics",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Humanistic Counselling",
                    "university": "University of Portsmouth",
                    "location": "South Hampshire College Group - Eastleigh Campus",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Humanistic Counselling (Top-up)",
                    "university": "University of Portsmouth",
                    "location": "South Hampshire College Group - Eastleigh Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Illustration",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Interior Architecture and Design",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "International Business Communication (Top-up)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "International Development and Languages (Chinese, French, Spanish)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "International Development and Languages with Foundation Year",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "International Development with Foundation Year",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "International Enterprise and Business Communication (Top-Up)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Resources and Business Communication (Top-Up)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "International Relations and Languages (Chinese, French, German, Spanish)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "International Relations and Politics",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "International Relations and Politics with Foundation Year",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "International Relations with Foundation Year",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "International Trade and Business Communication (Top-up)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Journalism with Creative Writing",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Journalism with Media Studies",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Language Studies",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-104"
                },
                {
                    "course_name": "Law",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Law with Business",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Law with Business with Foundation Year",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Law with Criminology with Foundation Year",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Law with Foundation Year",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Law with Legal Practice",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Learning Support",
                    "university": "University of Portsmouth",
                    "location": "Multiple Locations",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Biology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Marine Environmental Science",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Portsmouth",
                    "location": "University of Portsmouth London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Marketing with Foundation Year",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Mathematics for Finance and Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Mathematics with Machine Learning",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Mathematics with Statistics",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Mechanical and Manufacturing Engineering",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Media and Communication with Foundation Year",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-56"
                },
                {
                    "course_name": "Media Studies",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Mental Health Nursing (Postgraduate Entry)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MN",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "96-104"
                },
                {
                    "course_name": "Music Technology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BN (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BN (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Operating Department Practice",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Palaeontology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Paramedic Science",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Pharmacy",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MPharm (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Photography",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Physician Associate Studies (Postgraduate Entry)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MPhys (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Physics, Astrophysics and Cosmology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MPhys (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Physics, Astrophysics and Cosmology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Politics",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Politics with Foundation Year",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Post-Production for Film and Television",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Primary Education with Qualified Teacher Status",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BEd (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Product Design and Innovation",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Property Development",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Psychological Sciences (Dual Degree)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Quantity Surveying",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Renewable Energy Engineering",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Renewable Energy Engineering",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Robotics and Artificial Intelligence",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Science with Foundation Year",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Screenwriting",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Social Work (Postgraduate Entry)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Sociology with Criminology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Sociology with Psychology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Sport and Exercise Psychology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Sport Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Sport Management (Top-up)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Management with Foundation Year",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Sport, Health and Exercise Sciences (Dual Degree)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Sports Coaching (Top-up)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Virtual Production",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Dental Nursing Professional Practice (Top-up)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Biomedical Science (Degree Apprenticeship)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Architecture (Degree Apprenticeship)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Building Surveying (Degree Apprenticeship)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time day-release",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Business Leadership and Management (Degree Apprenticeship)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Part-time day-release",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Civil Engineering (Degree Apprenticeship)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Games Production",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Construction",
                    "university": "University of Portsmouth",
                    "location": "South Hampshire College Group - Eastleigh Campus",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "24"
                },
                {
                    "course_name": "Construction Management (Top Up) (Degree Apprenticeship)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dental Development Programme",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "No award",
                    "duration": "23 weeks",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing (Degree Apprenticeship)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "42 months",
                    "study_mode": "Part-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Education Studies (Top-Up)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "15 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Portsmouth",
                    "location": "Isle of Wight College - CECAMM Centre",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Electronic Engineering (Top-Up) (Degree Apprenticeship)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "13 months",
                    "study_mode": "Part-time day-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Further Education and Skills",
                    "university": "University of Portsmouth",
                    "location": "Multiple Locations",
                    "qualification": "CertEd",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "General Engineering",
                    "university": "University of Portsmouth",
                    "location": "Isle of Wight College - CECAMM Centre",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Local Dental Anaesthesia",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "No award",
                    "duration": "12 weeks",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical and Manufacturing Engineering (Top-up) (Degree Apprenticeship)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "13 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Portsmouth",
                    "location": "Isle of Wight College - CECAMM Centre",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Operating Department Practice (Degree Apprenticeship)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Block-release",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Partnership Degree Programme",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "2 to 8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Studies (Distance Learning)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "CertHE",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management (Degree Apprenticeship)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Quantity Surveying (Degree Apprenticeship)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time day-release",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Space Systems Engineering (Degree Apprenticeship)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104-112"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Data Analytics",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Adult Nursing",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MN",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Manufacturing",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Aquatic Biology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Linguistics",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Linguistics and TESOL",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Linguistics and TESOL (with Professional Experience)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Sport and Exercise Performance",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MArch",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence and Machine Learning",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Building Information Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Communication for International Leadership",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Communication for International Leadership (with Professional Experience)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Exercise Physiology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Coastal and Marine Resource Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Network Administration and Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "University of Portsmouth",
                    "location": "University of Portsmouth London",
                    "qualification": "MSc",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Corporate Governance and Law/Grad.CG",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Industries",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Technologies",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Technologies",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Psychology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Crisis and Disaster Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security and Forensic Information Technology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cybercrime, Terrorism and Security",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Analytics",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Business Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Economy",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "University of Portsmouth",
                    "location": "University of Portsmouth London",
                    "qualification": "MA",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic Crime",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics, Finance and Banking",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies (with Professional Experience)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Leadership and Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Leadership and Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "6 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Leadership and Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Leadership and Management (with Professional Experience)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy and Power Systems Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Geology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Management",
                    "university": "University of Portsmouth",
                    "location": "University of Portsmouth London",
                    "qualification": "MSc",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Marketing",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Production",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Technology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Accounting",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Investigation",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geographical Information Systems",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Humanities and Social Sciences",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Illustration",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Systems",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Systems",
                    "university": "University of Portsmouth",
                    "location": "University of Portsmouth London",
                    "qualification": "MSc (PG)",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Innovation Management and Entrepreneurship",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Architecture and Design",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "14 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Management",
                    "university": "University of Portsmouth",
                    "location": "University of Portsmouth London",
                    "qualification": "MSc",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Criminal Justice and Intelligence",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Finance and Banking",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Resource Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Marketing",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Logistics and Supply Chain Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA Global (1 Year)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA Global (2 Years)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA Senior Leadership (Top Up)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "6 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communication",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Biotechnology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health Nursing",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MN",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Photography",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy (Pre-Registration)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Postgraduate Teaching Apprenticeship (Secondary)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "No award",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Prescribing for Nurses: Independent and Supplementary (V300) (Level 7)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "No award",
                    "duration": "8 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantity Surveying",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Risk, Crisis and Resilience Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science and Health",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Psychology (BPS accredited)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Procurement",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strength, Conditioning and Rehabilitation",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Systems Engineering",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "TESOL",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre: Socially Engaged Practice",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation Studies",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "User Experience Design",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Water and Environmental Engineering",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Accounting and Data Analytics",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "30 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "30 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Manufacturing",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Aquatic Biology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Linguistics",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Linguistics and TESOL",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Sport and Exercise Performance",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MArch",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture and Professional Practice (Degree Apprenticeship)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MArch",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Building Information Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "28 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Exercise Physiology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Coastal and Marine Resource Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Network Administration and Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conscious Sedation for Dentistry",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "28 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Corporate Governance and Law/Grad.CG",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "30 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Industries",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Technologies",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Technologies",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Psychology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Crisis and Disaster Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security and Forensic Information Technology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cybercrime, Terrorism and Security",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Analytics",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Business Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic Crime",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics, Finance and Banking",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Leadership and Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Leadership and Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Leadership and Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Leadership and Management (Degree Apprenticeship)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering (2 Year)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering (3 Year)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy and Power Systems Management (2 Year)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy and Power Systems Management (3 Year)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Geology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Marketing",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Production",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Final Examination in Professional Practice (Part 3) Architecture",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "Short Course Certificate",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Accounting",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "30 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Investigation",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geographical Information Systems",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Development (Top-up)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "9 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Development and Training Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management (Top-up)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "9 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Humanities and Social Sciences",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Independent Prescribing for Pharmacists",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "8 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Systems",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Innovation Management and Entrepreneurship",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Architecture and Design",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Criminal Justice and Intelligence",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Marketing",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Professional Practice (Part 3) Architecture",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "Professional Qualification",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Politics",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "30 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership and Management (Top-up)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA Senior Leadership (Top Up)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "9 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communication",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Biotechnology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Health and Safety Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Health, Safety and Environmental Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Partnership Masters Programme",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Variable",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Photography",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantity Surveying",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Risk and Safety Leadership (Degree Apprenticeship)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Risk, Crisis and Resilience Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science and Health",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Senior Leader Apprenticeship (Educational Leadership and Management)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Senior Leadership (Senior Leader Apprenticeship)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Psychology (BPS accredited)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Management",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strength, Conditioning and Rehabilitation",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Systems Engineering",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Systems Engineering (Degree Apprenticeship)",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "TESOL",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre: Socially Engaged Practice",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation Studies",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "User Experience Design",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Water and Environmental Engineering",
                    "university": "University of Portsmouth",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "28 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "29058922d8f200c5ed1d60db63f0e5d6",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Acting and Performance",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Associate Degree - Psychology and Sociology",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Newbattle Abbey College",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Analytics",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-108"
                },
                {
                    "course_name": "Business Management with Digital Marketing",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-108"
                },
                {
                    "course_name": "Business Management with Finance",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-108"
                },
                {
                    "course_name": "Business Management with Human Resource Management",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-108"
                },
                {
                    "course_name": "Costume Design and Construction",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative and Cultural Industries",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-108"
                },
                {
                    "course_name": "Diagnostic Radiography",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "Master of Diagnostic Radiography \u2013 MDRad",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dietetics",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MDiet",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing and Public Relations",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-108"
                },
                {
                    "course_name": "Drama",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies (Primary)",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Media",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "International Hospitality, Tourism and Events Management",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Nursing",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MNurs",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communications",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing BSc (Hons)",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-108"
                },
                {
                    "course_name": "Nutrition",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MNutr",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MOccTh",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paramedic Science",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGDE Secondary (Business Education)",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "PGDE Secondary (Home Economics)",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "PGDE Secondary (Religious, Moral and Philosophical Studies)",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MPhysio",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Podiatry",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MPod",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Sociology",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Relations and Marketing Communications",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Sociology",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Speech and Language Therapy",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSpchLangTher",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and Film",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Therapeutic Radiography",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MRad",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance with CIMA",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance with CIMA with Professional Practice",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advancing Practice in Occupational Therapy (Post-Registration)",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Arts and Social Practice",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art Psychotherapy",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts, Festival and Cultural Management",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics with Professional Practice",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diagnostic Radiography (Pre-Registration)",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc",
                    "duration": "26 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dietetics (Pre-Registration)",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Campaigning and Content Creation",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Doctor of Philosophy (PhD)",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dramatherapy",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Film Industries",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management and Leadership",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management and Leadership with Professional Practice",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Marketing",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Marketing with Professional Practice",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media, Management and the Creative Industries",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health and Psychosocial Support",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Therapy",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Therapy (Pre-registration)",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc",
                    "duration": "2.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Person-Centred Practice",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy (Pre-registration)",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc",
                    "duration": "2.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Podiatry (Pre-Registration)",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Communications and Public Affairs",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sexual and Reproductive Health",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Speech and Language Therapy (Pre-Registration)",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc",
                    "duration": "2.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Communication and Public Relations",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Therapeutic Radiography (Pre-Registration)",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Applied Arts and Social Practice",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts, Festival and Cultural Management",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Campaigning and Content Creation",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Doctor of Philosophy (PhD)",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Film Industries",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management and Leadership",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Marketing",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media, Management and the Creative Industries",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health and Psychosocial Support",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Communications and Public Affairs",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sexual and Reproductive Health",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Communication and Public Relations",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theory of Podiatric Surgery",
                    "university": "Queen Margaret University, Edinburgh",
                    "location": "Queen Margaret University",
                    "qualification": "MSc (PG)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "0646709b32b5364374c87551db73759d",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accountancy",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance with Integrated Foundation Year",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Management",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Management with Integrated Foundation Year (4 years)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Actuarial Science",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Actuarial Science with Professional Placement",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Actuarial Science with Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering with Industrial Experience",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering with Industrial Experience",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry with a Year in Industry/Research",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences with Foundation",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering with Industrial Experience",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering with Industrial Experience",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "BSc Management for Social Change",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with a Year in Industry",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Integrated Foundation Year",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business with Law",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with Industrial Experience",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with Industrial Experience",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Sciences with Foundation",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with a Year in Industry/Research",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature and Film",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature and Film with Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature and Linguistics",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature and Linguistics with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Artificial Intelligence",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Artificial Intelligence with Industrial Experience",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Artificial Intelligence with Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Industrial Experience",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Management (ITMB)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Management (ITMB) with Industrial Experience",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Management (ITMB) with Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Systems Engineering",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Systems Engineering with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Systems Engineering with Industrial Experience",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dentistry",
                    "university": "Queen Mary University of London",
                    "location": "Whitechapel",
                    "qualification": "BDS",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design, Innovation and Creative Engineering",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design, Innovation and Creative Engineering",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design, Innovation and Creative Engineering  with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design, Innovation and Creative Engineering with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design, Innovation and Creative Engineering with Industrial Experience",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama and History",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama and History with Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama with Creative Writing",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama with Creative Writing with Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance with a Year in Industry",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance with a Year in Industry and Integrated Foundation Year (September start)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance with Integrated Foundation Year (September start)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance with Year Abroad and Integrated Foundation Year (September start)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and International Finance",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and International Finance with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and International Finance with a Year in Industry",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and International Finance with a Year in Industry and Integrated Foundation Year (September start)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and International Finance with Integrated Foundation Year (September start)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and International Finance with Year Abroad and Integrated Foundation Year (September start)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad and foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and International Relations",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and International Relations with a Year in Industry",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and International Relations with a Year in Industry and Integrated Foundation Year (September start)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and International Relations with Integrated Foundation Year (September Start)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and International Relations with Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and International Relations with Year Abroad and Integrated Foundation Year (September Start)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad and foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Politics",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Politics with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Politics with a Year in Industry",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Politics with a Year in Industry and Integrated Foundation Year (September start)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Politics with Integrated Foundation Year(September start)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Politics with Year Abroad and Integrated Foundation Year (September start)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad and foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with a Year in Industry",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with a Year in Industry and Integrated Foundation Year (September start)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Integrated Foundation Year (September start)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Year Abroad and Integrated Foundation Year (September start)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics, Finance and Management",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics, Finance and Management with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics, Finance and Management with a Year in Industry",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics, Finance and Management with a Year in Industry and Integrated Foundation Year (September start)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics, Finance and Management with Integrated Foundation Year (September start)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics, Finance and Management with Year Abroad and Integrated Foundation Year (September start)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad and foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics, Statistics and Mathematics",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics, Statistics and Mathematics with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics, Statistics and Mathematics with a Year in Industry",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics, Statistics and Mathematics with a Year in Industry and Integrated Foundation Year (September start)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics, Statistics and Mathematics with Integrated Foundation Year (September start)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics, Statistics and Mathematics with Year Abroad and Integrated Foundation Year (September start)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad and foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering with Industrial Experience",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with Foundation",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering with Foundation",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Drama",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Drama with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and European Law (4 years)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Film",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Film with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and French Law/Master 1 (Maitrise)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and History",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and History with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Linguistics",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Linguistics with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language with Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Linguistics",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Linguistics with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English with Creative Writing",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English with Creative Writing with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science with Business Management",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science with Business Management with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Drama",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Drama with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and French",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and German",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Hispanic Studies",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Russian",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance with a Year Abroad and Integrated Foundation Year (September start)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad and foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance with a Year in Industry",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance with a Year in Industry and Integrated Foundation Year (September start)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance with Integrated Foundation Year (September start)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Mathematics",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Mathematics with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Mathematics with Professional Placement",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Comparative Literature",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Linguistics (4 years)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Politics (4 years)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French with Business Management (4 years)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with Business Management",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with Business Management with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Comparative Literature",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Linguistics (4 years)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Politics (4 years)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German with Business Management (4 years)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Law",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies and Comparative Literature",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies and Linguistics (4 years)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies and Politics",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies with Business Management",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and International Relations",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and International Relations with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Politics",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Politics with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Geography",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Geography with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Analytics",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Analytics with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Analytics with a Year Abroad and Integrated Foundation Year",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad and foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Analytics with a Year in Industry",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Analytics with a Year in Industry and Integrated Foundation Year",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad and foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Analytics with Integrated Foundation Year",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations with Business Management",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations with Business Management and a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (Senior Status)",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Climate Justice",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Politics",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Business",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Liberal Arts",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Liberal Arts with Integrated Foundation Year",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Liberal Arts with Integrated Foundation Year and Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Liberal Arts with Integrated Foundation Year and Year in Industry",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Liberal Arts with Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Liberal Arts with Year in Industry",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics with Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing and Management",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing and Management with a Year in Industry",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing and Management with Integrated Foundation Year",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science and Engineering",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science and Engineering",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science and Engineering  with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science and Engineering with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science and Engineering with Industrial Experience",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science and Engineering with Industrial Experience",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science with Foundation",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Statistics",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Statistics with a Professional Placement",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Statistics with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Finance & Accounting",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Finance & Accounting with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Finance & Accounting with Professional Placement",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Foundation",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Management",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Management with a Professional Placement",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Management with Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Professional Placement",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Industrial Experience",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Industrial Experience",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Genetics",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Genetics with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine",
                    "university": "Queen Mary University of London",
                    "location": "Whitechapel",
                    "qualification": "MB BS",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine (Graduate Entry)",
                    "university": "Queen Mary University of London",
                    "location": "Whitechapel",
                    "qualification": "MB BS",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MEng Design, Innovation and Creative Engineering with Industrial Experience",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern and Contemporary History",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern and Contemporary History with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oral Health",
                    "university": "Queen Mary University of London",
                    "location": "Whitechapel",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-152"
                },
                {
                    "course_name": "Pharmaceutical Chemistry",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Chemistry with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Chemistry with a Year in Industry/Research",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology and Innovative Therapeutics",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology and Innovative Therapeutics with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology and Innovative Therapeutics with Year in Industry",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Foundation",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Professional Experience",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Professional Experience",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Sociology",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Sociology with Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics with Business Management",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics with Business Management and Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics Engineering",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics Engineering",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics Engineering with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics Engineering with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics Engineering with Industrial Experience",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics Engineering with Industrial Experience",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian and Comparative Literature",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian and Linguistics",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian and Politics",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian with Business Management",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology with a Year Abroad",
                    "university": "Queen Mary University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Actuarial Science and Data Analytics",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Aerospace Engineering MSc",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Chemical Engineering",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Electronic and Electrical Engineering",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Materials Science and Engineering",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Mechanical Engineering",
                    "university": "Queen Mary University of London",
                    "location": "Whitechapel Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Statistics and Data Science",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence and Machine Learning in Science",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence for Drug Discovery",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence in the Biosciences",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astrophysics",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biodiversity and Conservation",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioinformatics",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering (Conversion)",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology and Synthetic Biology",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Research",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Games",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Conversion)",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Analytics",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Artificial Intelligence by Conversion",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering by Research",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Management",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Mathematics",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Machine Learning for Visual Data Analytics",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular Cell Biology",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics (EuroMasters)",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Plant and Fungal Taxonomy Diversity and Conservation",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Conversion)",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology: Mental Health Sciences",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Risk Analytics",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics and Artificial Intelligence",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sound and Music Computing",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Energy Systems",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Actuarial Science and Data Analytics",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Electronic and Electrical Engineering",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence for Drug Discovery",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astrophysics",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioinformatics",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Research",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Conversion)",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Analytics",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Mathematics",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Machine Learning for Visual Data Analytics",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Risk Analytics",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sound and Music Computing",
                    "university": "Queen Mary University of London",
                    "location": "Mile End",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "2607f183691598cc1308b4d71d2d58f1",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting with French",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting with Spanish",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Actuarial Science and Risk Management",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Accounting with Placement",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MAcc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136-160"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering (with a Year in Industry)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering (with a Year in Industry)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agricultural Technology",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agricultural Technology with Professional Studies",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and Archaeology",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and English",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and French",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and History",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and International Relations",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and Irish",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and Philosophy",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and Politics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and Spanish",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Mathematics and Physics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Mathematics and Physics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology & Palaeoecology",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology & Palaeoecology and Geography",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and History",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and Irish",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology with French",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology with Portuguese",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology with Spanish",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Audio Engineering",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry with Professional Studies",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry with Professional Studies",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences (Foundation degree delivered at Belfast Metropolitan College, Titanic Quarter campus)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences with Professional Studies",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences with Professional Studies",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Information Technology including Professional Experience",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Placement",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering (with a Year in Industry)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering (with a Year in Industry)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry (with a Year in Industry)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry (with a Year in Industry)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Study Abroad",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering (with a Year in Industry)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering (with a Year in Industry)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Engineering",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Engineering",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Engineering (with Year in Industry)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Engineering (with Year in Industry)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science including Professional Experience",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science including Professional Experience",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing and Information Technology",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Social Policy",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science with Year of Professional Experience",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Dentistry",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BDS",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama and English",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Accounting",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Finance",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with French",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Spanish",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Education Studies and Sociology",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-144"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering (with a Year in Industry)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering (with a Year in Industry)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Film Studies",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and French",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and History",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Irish",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Linguistics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Philosophy",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Politics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Sociology",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Spanish",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English with Creative Writing",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental and Civil Engineering",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental and Civil Engineering (with a Year in Industry)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Management",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Management with Professional Studies",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "European Planning",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MPlan",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Theatre Making",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies and Production",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance (with a Year in Industry)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Risk Management (with a Year in Industry)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Science and Nutrition",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Science and Nutrition",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Science and Nutrition with Professional Studies",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Science and Nutrition with Professional Studies",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and History",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and International Relations",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Irish",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Politics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Portuguese",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Spanish",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with a Language",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and International Relations",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Irish",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Philosophy",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Politics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Sociology",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Spanish",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Biology",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with French",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with German",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Mandarin",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Portuguese",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Spanish",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations & Conflict Studies",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Irish",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Politics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Spanish",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Irish",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Irish and Politics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Irish and Portuguese",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Irish and Spanish",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with French",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Politics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Spanish",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Liberal Arts",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MLibArts",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "LLB Law (Senior Status)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Biology",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Biology",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Biology with Professional Studies",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Biology with Professional Studies",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MMath (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Computer Science",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Computer Science",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Statistics & Operational Research",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MMath (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Statistics & Operational Research",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Finance",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with French",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Spanish",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering (Foundation degree delivered at Belfast Metropolitan College, Millfield campus)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "FdEng",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering (with a Year in Industry)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering (with a Year in Industry)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Broadcast Production",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicinal Chemistry",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicinal Chemistry",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicinal Chemistry (with a Year in Industry)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicinal Chemistry (with a Year in Industry)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MB",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology with Professional Studies",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology with Professional Studies",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BMus (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Audio Production",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Sound Design",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Performance",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Biotechnology",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Biotechnology (Sandwich)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Sciences",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Sciences (Sandwich)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MPharm (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Politics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with French",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with French",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Medical Applications",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Medical Applications",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Spanish",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Spanish",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Planning, Environment and Development",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Spanish",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, Philosophy and Economics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Nursing (Adult) (February)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Nursing (Adult) (September)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Nursing (Children & Young People's) (February)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Nursing (Children and Young People's) (September)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Nursing (Learning Disability) (September)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Nursing (Mental Health)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy and Sociology",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSW (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work (Relevant Graduate Route)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSW (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Portuguese Studies",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Engineering with Architecture",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Engineering with Architecture (with a Year in Industry)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology with Professional Studies",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology with Professional Studies",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Actuarial Science",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Food Safety",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Professional and Clinical Practice (Nursing)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Professional Practice (Nursing)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Behaviour and Welfare",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropological Studies",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Architecture and Design",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Behaviour Analysis",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Cyber Security",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Cyber Security (with Professional Internship)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Developmental Psychology",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and Palaeoecology",
                    "university": "Queen's University Belfast",
                    "location": "Multiple Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MArch",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts and Humanities",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts Management",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts Management and Cultural Policy",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Autism Spectrum Disorders",
                    "university": "Queen's University Belfast",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioinformatics and Computational Genomics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "Queen's University Belfast",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biopharmaceutical Engineering",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biopharmaceutical Engineering with a Year in Industry",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Building Information Modelling Project Management",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business for Agri-Food and Rural Enterprise",
                    "university": "Queen's University Belfast",
                    "location": "Multiple Locations",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer Medicine",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Caring for Children and Young People with Complex Health Needs",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children's Rights",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "City Planning and Design",
                    "university": "Queen's University Belfast",
                    "location": "Multiple Locations",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Queen's University Belfast",
                    "location": "Multiple Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate Change",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "5 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Anatomy",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Health Psychology",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognition and Culture",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Queen's University Belfast",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conflict Transformation and Social Justice",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction and Project Management",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction and Project Management with Industrial Internship",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Criminal Justice",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Analytics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dentistry",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Doctorate in Clinical Psychology (DClinPsy)",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "DClinPsy",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Doctorate in Educational, Child and Adolescent Psychology (DECAP)",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "Doctoral Programme",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecological Management and Conservation Biology",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (English)",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Information Technology and Computing)",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Mathematics)",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Modern Languages)",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Religious Education)",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Science)",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Social Science)",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Leadership",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Studies",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MEd",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Queen's University Belfast",
                    "location": "Multiple Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronics with Professional Internship",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English - Creative Writing",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English - Literary Studies",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English - Poetry",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Engineering",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Experimental Medicine",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Analytics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Risk Management",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Safety and Biotechnology",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geopolitics",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MPH",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Security and Borders",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hydrogen Energy Systems",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "12 weeks",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Inclusion and Special Educational Needs",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MEd",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Industrial Pharmaceutics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intellectual Property Law",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial and Business Law",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Rights Law",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Public Policy",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Studies",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interpreting",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interpreting",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Irish and Celtic",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Irish Studies",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Technology",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership for Sustainable Development",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership for Sustainable Rural Development",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Lusophone Studies",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration (with Internship)",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Masters in Law",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MLaw",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Management",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Management and Industrial Internship",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Broadcast",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Broadcast Production",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular Biology and Biotechnology",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Net Zero Engineering",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Net Zero Engineering with Year in Industry",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing and Midwifery",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "One Health: Parasitology & Infection Biology",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Analysis",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Analysis with Industrial Placement",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Analysis with International Industrial Placement",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy",
                    "university": "Queen's University Belfast",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Queen's University Belfast",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Queen's University Belfast",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Planning",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Planning and Development",
                    "university": "Queen's University Belfast",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Nursing (Adult Nursing)",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Nursing (Children and Young People)",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Nursing (Learning Disability)",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Nursing (Mental Health)",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological Science",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MPH",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public History",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Policy",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Science Research",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Global Inequality",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Development",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages and Applied Linguistics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Violence, Terrorism and Security",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Youth Justice",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Accounting",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Professional and Clinical Practice (Nursing)",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Professional Practice (Nursing)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropological Studies",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Behaviour Analysis",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Cyber Security",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Developmental Psychology",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and Palaeoecology",
                    "university": "Queen's University Belfast",
                    "location": "Multiple Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture (Practice-Based)",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture (Professional Practice Architecture)",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts and Humanities",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts Management",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts Management and Cultural Policy",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Autism Spectrum Disorders",
                    "university": "Queen's University Belfast",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "Queen's University Belfast",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biopharmaceutical Engineering",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biopharmaceutical Engineering with a Year in Industry",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Building Information Modelling Project Management",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Building Information Modelling Project Management Higher Level Apprenticeship",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business for Agri-Food and Rural Enterprise",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Caring for Children and Young People with Complex Health Needs",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children's Rights",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children's Rights and Participation",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cinematic Architecture",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "City Planning and Design",
                    "university": "Queen's University Belfast",
                    "location": "Multiple Locations",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "City Planning and Design Higher Level Apprenticeship",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Queen's University Belfast",
                    "location": "Multiple Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate Change",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "9 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Education",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Health Psychology",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognition and Culture",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Behavioural Practice",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Behavioural Psychotherapy",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Queen's University Belfast",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conflict Transformation and Social Justice",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction and Project Management",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction and Project Management Higher Level Apprenticeship",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Criminal Justice",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Analytics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dentistry",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Leadership",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Studies",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MEd",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Queen's University Belfast",
                    "location": "Multiple Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Management",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English - Creative Writing",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English - Literary Studies",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English - Poetry",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Engineering",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Safety and Biotechnology",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geopolitics",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PgDip",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hydrogen Energy Systems",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Inclusion and Special Educational Needs",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MEd",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Independent Prescribing",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intellectual Property Law",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial and Business Law",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Rights Law",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Public Policy",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Studies",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interpreting",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Irish and Celtic",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Irish Studies",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Technology",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Lusophone Studies",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Masters in Law",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MLaw",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Management",
                    "university": "Queen's University Belfast",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Management and Industrial Internship",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Management Higher Level Apprenticeship",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Broadcast",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Broadcast Production",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health and Mental Capacity Law",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Net Zero Engineering",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Net Zero Engineering (Distance Learning)",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Net Zero Engineering with Year in Industry",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing and Midwifery",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Palliative Care",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Analysis",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Analysis Higher Level Apprenticeship",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Analysis with Industrial Placement",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Analysis with International Industrial Placement",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy",
                    "university": "Queen's University Belfast",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Queen's University Belfast",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Queen's University Belfast",
                    "location": "Multiple Locations",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Planning",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Planning and Development",
                    "university": "Queen's University Belfast",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Planning and Development Higher Level Apprenticeship",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Prescribing for Pharmacists",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological Science",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public History",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Policy",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Science Research",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Global Inequality",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Development (Part Time)",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Cognitive Behavioural Therapy",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Substance Use and Substance Use Disorders",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Systemic Practice and Family Therapy",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Systemic Psychotherapy",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages and Applied Linguistics",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Violence, Terrorism and Security",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Youth Justice",
                    "university": "Queen's University Belfast",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Youth Justice & Children's Rights",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zero Carbon Engineering",
                    "university": "Queen's University Belfast",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "5b6246531656de903cb9ba341c75f385",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "3D Animation",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "3D Animation with Integrated Foundation Year",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Advertising & Brand Design with Integrated Foundation Year",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-64"
                },
                {
                    "course_name": "Advertising and Brand Design",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Animation",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Animation with Integrated Foundation Year",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Architecture (ARB/RIBA Part 1)",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Architecture with Integrated Foundation Year",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Broadcast Engineering",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business Management with Fashion",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business Management with Fashion with Integrated Foundation Year",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-56"
                },
                {
                    "course_name": "Business Management with Integrated Foundation Year",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32-56"
                },
                {
                    "course_name": "Character Design and Concept Art",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Computing",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Digital Content Creation",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Digital Content Creation with Integrated Foundation Year",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-64"
                },
                {
                    "course_name": "Digital Film Production",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA/BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Digital Marketing with Integrated Foundation Year",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-64"
                },
                {
                    "course_name": "Digital Photography",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Digital Television Production",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Editing & Post Production",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Fashion",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Fashion Buying and Brand Management",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Fashion Buying and Brand Management with Integrated Foundation Year",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-64"
                },
                {
                    "course_name": "Fashion Promotion & Communication",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Games Art",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Games Art with Integrated Foundation Year",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Games Design",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Games Design with Integrated Foundation Year",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Games Development",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Games Development with Integrated Foundation Year",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Games Programming",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Illustration for Communication",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Interior Design Environment Architectures (ARB/RIBA Part 1)",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Interior Design Environment Architectures with Integrated Foundation Year",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Motion Graphics",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Music and Sound Production",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Product Design",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Urban Landscape Architecture",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Urban Landscape Architecture with Integrated Foundation Year",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "User-Experience and User-Interface (UX/UI) Design",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Architectural Visualisation",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security Management",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Communication",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Management",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Photography",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "MFA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion: Business and Entrepreneurship",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion: Digital Futures",
                    "university": "Ravensbourne University London",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Illustration",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Fashion Marketing",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Luxury Brand Management",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Fashion: Business and Entrepreneurship",
                    "university": "Ravensbourne University London",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "82e2dfd64df8326e2839034e9c93cb99",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Business - Flying Start Degree Programme",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance (with Placement Experience)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance with Year Abroad",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Management",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Management with Foundation",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Management With Placement Experience",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Management with Year Abroad",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agricultural Business Management",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agricultural Business Management with Foundation",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agricultural Business Management with International Foundation Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agricultural Business Management with International Foundation Year (January entry)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agricultural Business Management with Placement Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agriculture",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agriculture with Foundation",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agriculture with International Foundation Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agriculture with Placement Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History and Archaeology",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History and Archaeology with Professional Placement",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History and Archaeology with Study Year Abroad",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History and History",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Psychology (Clinical)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and Anthropology",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and Anthropology with Professional Placement",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and Anthropology with Study Year Abroad",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and History",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and History with Professional Placement",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and History with Study Year Abroad",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology with Professional Placement",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology with Study Year Abroad",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Engineering",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Engineering",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Engineering with International Foundation Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Art and Creative Writing",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and English Literature",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Film",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and History of Art",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Philosophy",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Psychology",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry (MSci)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry with Foundation",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry with Professional Experience",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry with Professional Experience (MSci)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences with Foundation",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences with Professional Experience",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering with Professional Experience",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science (MSci)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science with Foundation",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science with Professional Experience",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science with Professional Experience (MSci)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioveterinary Sciences",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioveterinary Sciences with Foundation",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioveterinary Sciences with Placement Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "BSc Real Estate Development and Planning",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Building Surveying",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Building Surveying with International Foundation Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Building Surveying with International Foundation Year (January entry)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management (Data Analytics & Digital Business)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management (Data Analytics & Digital Business) with Placement Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management (Data Analytics & Digital Business) with Year Abroad",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management (Entrepreneurship and Innovation)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management (Entrepreneurship and Innovation) with Placement Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management (Entrepreneurship and Innovation) with Year Abroad",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management (Human Resources and Organisational Behaviour)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management (Human Resources and Organisational Behaviour) with Placement Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management (Human Resources and Organisational Behaviour) with Year Abroad",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management (Marketing)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management (Marketing) with placement year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management (Marketing) with Year Abroad",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management with Foundation",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management with Placement Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management with Year Abroad",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics with Placement Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry (MChem)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with a Year in Industry or Research",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with a Year in Industry/Research (MChem)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Cosmetic Science",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Cosmetic Science with a Year in Industry",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Cosmetic Science with Foundation Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Foundation",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children's Development and Learning",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies and English Literature",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Industrial Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Management",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Management and Surveying",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Management and Surveying with International Foundation Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Management and Surveying with International Foundation Year (January entry)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Management with International Foundation Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Management with International Foundation Year (January entry)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Consumer Behaviour and Marketing",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Consumer Behaviour and Marketing with Foundation",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Consumer Behaviour and Marketing with International Foundation\u00a0Year (January entry)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Consumer Behaviour and Marketing with International Foundation\u00a0Year - September Entry",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Consumer Behaviour and Marketing with Placement Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing and Film",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing and Film and Theatre",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing and Theatre",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecology and Wildlife Conservation",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecology and Wildlife Conservation with Foundation",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecology and Wildlife Conservation with Professional Experience",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance with International Foundation Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance with International Foundation Year\u00a0(January entry)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with International Foundation Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with International Foundation Year (January entry)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Placement Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Placement Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Linguistics",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Linguistics with Foundation",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Linguistics with Placement Experience",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Literature",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Film",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Film & Theatre",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Politics",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature with Creative Writing",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature with Foundation",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Management and Sustainability",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Management and Sustainability with Foundation",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Management and Sustainability with Placement Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science with International Foundation Year (September Entry)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science with Professional Experience",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science with Study Year Abroad",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ethics, Value and Philosophy",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ethics, Value and Philosophy with Placement Experience",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Television",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Theatre",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance (FinTech)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance (FinTech) with Placement Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance (FinTech) with Year Abroad",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance (International Business)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance (International Business) with Placement Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance (International Business) with Year Abroad",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance (Investments)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance (Investments) with Placement Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance (Investments) with Year Abroad",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance (Sustainable Finance)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance (Sustainable Finance) with Placement Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance (Sustainable Finance) with Year Abroad",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance with Foundation",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance with Placement Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance with Year Abroad",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Business and Marketing",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Business and Marketing with Foundation",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Business and Marketing with International Foundation Year (January entry)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Business and Marketing with International Foundation Year (September Entry)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Business and Marketing with Placement Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Science",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Science with Foundation",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Science with Industrial Training",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Science with International Foundation Year (September Entry)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Technology with Bio Processing",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Technology with Bio-Processing with Industrial Training",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and International Development",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography (Human and Physical)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography (Human and Physical) with Professional Experience",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography (Human)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography (Human) with Professional Experience",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography (Physical)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography (Physical) with Professional Experience",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography and Economics (Regional Science)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography and Economics (Regional Science) with Professional Experience",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Communication",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Economics",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and English Literature",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and International Relations",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Philosophy",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Politics",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Management",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Management with a Modern Language",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Management with a Modern Language with Placement Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Management with a Modern Language with Year Abroad",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Management with Placement Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Management with Year Abroad",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development with Placement Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Foundation Programme (January entry)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "Fd cert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Foundation Programme (September Entry)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "Fd cert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Economics",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Economics with Placement Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Foundation",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Law with International Business",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with International Foundation Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics (MMath)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "MMath",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics (MMath) with a Placement Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "MMath",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Economics",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Economics with a Placement Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Statistics with Data Science",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Statistics with Data Science with a Placement Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with a Placement Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Computer Science",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Computer Science with Placement Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Finance and Investment Banking",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Finance and Investment Banking with a Placement Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Finance and Investment Banking with International Foundation Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with International Foundation Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science (MSci)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science with Foundation",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science with Professional Experience",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Science with Professional Experience (MSci)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Meteorology and Climate",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Meteorology and Climate with a year in Oklahoma",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "MMet",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Meteorology and Climate with International Foundation Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology (MSci)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology with Foundation",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology with Professional Experience",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology with Professional Experience (MSci)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MLaw (four-year integrated Masters)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "MLaw",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages (French/German/Italian/Spanish)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages (French/German/Italian/Spanish)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages (French/German/Italian/Spanish) and Business",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages (French/German/Italian/Spanish) and Economics",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages (French/German/Italian/Spanish) and English Language",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages (French/German/Italian/Spanish) and English Literature",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages (French/German/Italian/Spanish) and History",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages (French/German/Italian/Spanish) and International Relations",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Museum and Classical Studies",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Museum Studies and Archaeology",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Museum Studies and Archaeology with International Foundation Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Museum Studies and Archaeology with International Foundation Year\u00a0(January entry)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Museum Studies and Archaeology with Professional Placement",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Museum Studies and Archaeology with Study Year Abroad",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nutrition",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nutrition and Food Science",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nutrition and Food Science with Professional Training",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nutrition with Foundation",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nutrition with Professional Training",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Chemistry",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Chemistry with a Year in Industry/Research",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Chemistry with Foundation",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology with a Year in Industry",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology with Foundation",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "MPharm (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy with Preparatory Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "MPharm (H)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Classical Studies",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Classical Studies with Placement Experience",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and English Literature",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and English Literature with Placement Experience",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and International Relations",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and International Relations with Placement Experience",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Politics",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Politics with Placement Experience",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy with Placement Experience",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy, Business and Ethics",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy, Business and Ethics with Placement Experience",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy, Politics and Economics",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy, Politics and Economics with Placement Experience",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physician Associate Studies (MPAS, Undergraduate Entry)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "Master of Physician Associate Studies \u2013 MPAS",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Economics",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Economics with Placement Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations with International Foundation Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations with International Foundation Year\u00a0(January entry)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations with Placement Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Education",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Education with Art",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Education with English",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Education with Mathematics",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Education with Music",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Language Sciences",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Philosophy",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with Foundation",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with International Foundation Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with Neuroscience",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with Professional Placement",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantity Surveying",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantity Surveying with International Foundation Year (January entry)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantity Surveying with International Foundation Year (September Entry)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate Finance",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate with Foundation",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Criminology",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Criminology with Placement Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology with Placement Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and International Development",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Speech and Language Therapy",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre & Performance",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "War, Peace and International Relations",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "War, Peace and International Relations with Placement Year",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology with Foundation",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology with Professional Experience",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting, Financial Management and Digital Business",
                    "university": "University of Reading",
                    "location": "Henley Business School",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Legal Studies",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agricultural and Food Economics",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MRes",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agricultural Economics",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agriculture and Development",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agriculture, Ecology and Environment",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MRes",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied International Development",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Linguistics",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Meteorology and Climate",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Meteorology and Climate with Management",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Atmosphere, Ocean and Climate",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Behavioural Finance",
                    "university": "University of Reading",
                    "location": "Henley Business School",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedicine (by Research)",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Research",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children's Literature",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MRes",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Ancient History",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Neuroscience",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication Design: Book Design Pathway",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication Design: Graphic Design Pathway",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication Design: Information Design Pathway",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication Design: Typeface Design Pathway",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication for Development",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conflict and International Security",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Cost Management",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Management",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Management with Industry Placement",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Consumer Behaviour",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Corporate Finance",
                    "university": "University of Reading",
                    "location": "Henley Business School",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Advanced Computing",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design and Management of Sustainable Built Environments",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Development Finance",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dietetics (Pre-registration)",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Business and Data Analytics",
                    "university": "University of Reading",
                    "location": "Henley Business School",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Innovation",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Years Practice (EYTS)",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecological Survey Skills with Placement",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Reading",
                    "location": "London Road Campus",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Entomology",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Entrepreneurship and Innovation",
                    "university": "University of Reading",
                    "location": "Henley Business School",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment, Climate Change and Development",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Management",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Creative Enterprise",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "University of Reading",
                    "location": "Henley Business School",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Financial Technology (FinTech)",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Risk Management",
                    "university": "University of Reading",
                    "location": "Henley Business School",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MFA (PG)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Economics and Marketing",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Science",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Security and Development",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Technology (Quality Assurance)",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Accounting and Finance",
                    "university": "University of Reading",
                    "location": "Henley Business School",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Reading",
                    "location": "Henley Business School",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Digital Transformation",
                    "university": "University of Reading",
                    "location": "Henley Business School",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Finance",
                    "university": "University of Reading",
                    "location": "Henley Business School",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law with Intellectual Property Law and Management",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law with International Banking law and Financial Regulation",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law with International Corporate Finance",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Resource Management",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Diplomacy",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Investment Management",
                    "university": "University of Reading",
                    "location": "Henley Business School",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language Sciences",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "LLM by Research Thesis",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA by Film, Theatre and Television",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA Creative Writing",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA English Literature",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Reading",
                    "location": "Henley Business School",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing (Digital Marketing)",
                    "university": "University of Reading",
                    "location": "Henley Business School",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing (International Marketing)",
                    "university": "University of Reading",
                    "location": "Henley Business School",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing (Sustainable Marketing)",
                    "university": "University of Reading",
                    "location": "Henley Business School",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Architecture",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MArch",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MRes Law",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MRes",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Applied Economics",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Climate Change and Artificial Intelligence (AI)",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Climate Change, Sustainable Business and Green Finance",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Project Management with Industry Placement",
                    "university": "University of Reading",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Public Policy",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nutrition and Food Science",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Primary Education",
                    "university": "University of Reading",
                    "location": "London Road Campus",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Primary Postgraduate Certificate in Education",
                    "university": "University of Reading",
                    "location": "London Road Campus",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Primary Postgraduate Certificate in Education (3-7)",
                    "university": "University of Reading",
                    "location": "London Road Campus",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Primary Postgraduate Certificate in Education with Special Educational Needs (SEN)",
                    "university": "University of Reading",
                    "location": "London Road Campus",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Art and Design",
                    "university": "University of Reading",
                    "location": "London Road Campus",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Geography",
                    "university": "University of Reading",
                    "location": "London Road Campus",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary History",
                    "university": "University of Reading",
                    "location": "London Road Campus",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Mathematics",
                    "university": "University of Reading",
                    "location": "London Road Campus",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Modern Foreign Languages (French, German, Spanish)",
                    "university": "University of Reading",
                    "location": "London Road Campus",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Physical Education",
                    "university": "University of Reading",
                    "location": "London Road Campus",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Physics with Mathematics",
                    "university": "University of Reading",
                    "location": "London Road Campus",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Science (Biology)",
                    "university": "University of Reading",
                    "location": "London Road Campus",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Science (Chemistry)",
                    "university": "University of Reading",
                    "location": "London Road Campus",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Science (Physics)",
                    "university": "University of Reading",
                    "location": "London Road Campus",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Secondary Science: Engineers Teach Physics",
                    "university": "University of Reading",
                    "location": "London Road Campus",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MA (Res)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physician Associate",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MRes",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Professional Graduate Certificate in Education with QTS (PgCE)",
                    "university": "University of Reading",
                    "location": "London Road Campus",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Professional Graduate Certificate in Education with QTS (PgCE) (3-7)",
                    "university": "University of Reading",
                    "location": "London Road Campus",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Professional Graduate Certificate in Education with QTS (PgCE) with Special Educational Needs (SEN)",
                    "university": "University of Reading",
                    "location": "London Road Campus",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology Conversion",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate",
                    "university": "University of Reading",
                    "location": "Henley Business School",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate Finance",
                    "university": "University of Reading",
                    "location": "Henley Business School",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Renewable Energy: Technology and Sustainability",
                    "university": "University of Reading",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Research Methods in Psychology",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spatial Planning and Development or Research",
                    "university": "University of Reading",
                    "location": "Henley Business School",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Speech and Language Therapy",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theory and Practice in Clinical Psychology (with Clinical or Research Placement)",
                    "university": "University of Reading",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Typography and Graphic Communication",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MRes",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Legal Studies",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "LLM",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agricultural Economics",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agriculture and Development",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agriculture, Ecology and Environment",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MRes",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied International Development",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Linguistics",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Meteorology and Climate",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Meteorology and Climate with Management",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Atmosphere, Ocean and Climate",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Research",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children's Literature",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MRes",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Ancient History",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Coaching for Behavioural Change",
                    "university": "University of Reading",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "30 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication Design: Book Design Pathway",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication Design: Graphic Design Pathway",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication Design: Information Design Pathway",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication Design: Typeface Design Pathway",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication for Development",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conflict and International Security",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Cost Management",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Management",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Advanced Computing",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design and Management of Sustainable Built Environments",
                    "university": "University of Reading",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Development Finance",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Business and Data Analytics",
                    "university": "University of Reading",
                    "location": "Henley Business School",
                    "qualification": "MSc",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Innovation",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Years Practice (EYTS)",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Reading",
                    "location": "London Road Campus",
                    "qualification": "MA",
                    "duration": "63 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment, Climate Change and Development",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Management",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MFA (PG)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Flexible CPD Advancing Healthcare Practice",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc (PG)",
                    "duration": "9 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Economics and Marketing",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Science",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Security and Development",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Technology (Quality Assurance)",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MA",
                    "duration": "60 months",
                    "study_mode": "Mixed mode",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Independent Prescribing for Allied Health Professionals",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "PgCert",
                    "duration": "6 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Independent Prescribing for Nurses (Levels 6 and 7)",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "PgCert",
                    "duration": "6 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Independent Prescribing for Pharmacists",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "PgCert",
                    "duration": "6 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "LLM",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law with Intellectual Property Law and Management",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law with International Banking law and Financial Regulation",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "LLM",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law with International Corporate Finance",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Diplomacy",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Introduction to Cognitive Behavioural Therapy (non-clinical)",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language Sciences",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "LLM by Research Thesis",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "LLM",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA by Film, Theatre and Television",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA English Literature",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MRes Law",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MRes",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Public Policy",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nutrition and Food Science",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy Practice",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "PgDip",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy Practice",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "PgCert",
                    "duration": "14 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MA (Res)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MRes",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Postgraduate Certificate in Education (Reflective Practice) Top-Up",
                    "university": "University of Reading",
                    "location": "London Road Campus",
                    "qualification": "PGCE",
                    "duration": "10 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology Conversion",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate (flexible study)",
                    "university": "University of Reading",
                    "location": "Henley Business School",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Mixed mode",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate Investment and Finance (flexible study)",
                    "university": "University of Reading",
                    "location": "Henley Business School",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Mixed mode",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Reflective Practice for Prescribers",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "PgCert",
                    "duration": "3 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Renewable Energy: Technology and Sustainability",
                    "university": "University of Reading",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spatial Planning and Development or Research",
                    "university": "University of Reading",
                    "location": "Henley Business School",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL)",
                    "university": "University of Reading",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "The Henley Executive",
                    "university": "University of Reading",
                    "location": "Henley Business School",
                    "qualification": "MBA (Executive)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "The Henley Flexible Executive",
                    "university": "University of Reading",
                    "location": "Henley Business School",
                    "qualification": "MBA (Executive)",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theory and Practice in Clinical Psychology (with Clinical or Research Placement)",
                    "university": "University of Reading",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Typeface Design",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MRes",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Typography and Graphic Communication",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "MRes",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Work-Based Learning",
                    "university": "University of Reading",
                    "location": "University of Reading",
                    "qualification": "PgCert",
                    "duration": "3 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "a2009ae88e97362524bc26fd23c87c9d",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "179df5c9691fec226d30060e2c6b3e3d",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Animal Management (Behaviour & Welfare)",
                    "university": "University Centre Reaseheath",
                    "location": "University Centre Reaseheath",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "63-64"
                },
                {
                    "course_name": "Animal Management (Behaviour and Welfare)",
                    "university": "University Centre Reaseheath",
                    "location": "University Centre Reaseheath",
                    "qualification": "Cert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "63-64"
                },
                {
                    "course_name": "Animal Management (Behaviour and Welfare) (Top-Up)",
                    "university": "University Centre Reaseheath",
                    "location": "University Centre Reaseheath",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Zoology",
                    "university": "University Centre Reaseheath",
                    "location": "University Centre Reaseheath",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Applied Zoology with Placement Year",
                    "university": "University Centre Reaseheath",
                    "location": "University Centre Reaseheath",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Canine Behaviour and Training",
                    "university": "University Centre Reaseheath",
                    "location": "University Centre Reaseheath",
                    "qualification": "Cert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "63-64"
                },
                {
                    "course_name": "Canine Behaviour and Training",
                    "university": "University Centre Reaseheath",
                    "location": "University Centre Reaseheath",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Canine Clinical Behaviour (Top-Up)",
                    "university": "University Centre Reaseheath",
                    "location": "University Centre Reaseheath",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Conservation",
                    "university": "University Centre Reaseheath",
                    "location": "University Centre Reaseheath",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "63-64"
                },
                {
                    "course_name": "Equine Behaviour and Welfare (Top-Up)",
                    "university": "University Centre Reaseheath",
                    "location": "University Centre Reaseheath",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Equine Training and Rehabilitation",
                    "university": "University Centre Reaseheath",
                    "location": "University Centre Reaseheath",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Landscape Architecture",
                    "university": "University Centre Reaseheath",
                    "location": "University Centre Reaseheath",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Landscape Architecture",
                    "university": "University Centre Reaseheath",
                    "location": "University Centre Reaseheath",
                    "qualification": "Cert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Landscape Architecture",
                    "university": "University Centre Reaseheath",
                    "location": "University Centre Reaseheath",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Landscape Architecture with Placement Year",
                    "university": "University Centre Reaseheath",
                    "location": "University Centre Reaseheath",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sustainable Agriculture (Top-up)",
                    "university": "University Centre Reaseheath",
                    "location": "University Centre Reaseheath",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Agriculture with Crop Management",
                    "university": "University Centre Reaseheath",
                    "location": "University Centre Reaseheath",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "63-64"
                },
                {
                    "course_name": "Sustainable Agriculture with Dairy Herd Management",
                    "university": "University Centre Reaseheath",
                    "location": "University Centre Reaseheath",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "63-64"
                },
                {
                    "course_name": "Sustainable Agriculture with Livestock Management",
                    "university": "University Centre Reaseheath",
                    "location": "University Centre Reaseheath",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "63-64"
                },
                {
                    "course_name": "Veterinary Nursing",
                    "university": "University Centre Reaseheath",
                    "location": "University Centre Reaseheath",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Wildlife Conservation and Ecology",
                    "university": "University Centre Reaseheath",
                    "location": "University Centre Reaseheath",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Zoo Management",
                    "university": "University Centre Reaseheath",
                    "location": "University Centre Reaseheath",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Zoo Management",
                    "university": "University Centre Reaseheath",
                    "location": "University Centre Reaseheath",
                    "qualification": "Cert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "63-64"
                },
                {
                    "course_name": "Zoo Management (Top-Up)",
                    "university": "University Centre Reaseheath",
                    "location": "University Centre Reaseheath",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "0bdd3a2bfadb7364ee3e2388c39bfccc",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "1e7e0ea28062d8bc1497ad35ea47e1a8",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Acting for Stage and Screen",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Acting for Stage and Screen with Foundation",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advertising",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advertising with Foundation",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History with Foundation",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Data Science",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Data Science with Foundation",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Entrepreneurship",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Entrepreneurship with Foundation",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Finance",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Finance with Foundation",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Global Sports Management",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Global Sports Management with Foundation",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Luxury Brand Management",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Luxury Brand Management with Foundation",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management with Foundation",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Marketing",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Marketing with Foundation",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Sustainability",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Sustainability with Foundation",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business, Technology and Entrepreneurship",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business, Technology and Entrepreneurship with Foundation",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing and Analytics",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing and Analytics with Foundation",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Design",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Design with Foundation",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Marketing",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Marketing with Foundation",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Screen Production",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Screen Production with Foundation",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Design",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Design with Foundation",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Foundation",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations with Foundation",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Luxury Hospitality Management",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Luxury Hospitality Management with Foundation",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Media and Communications",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communications with Foundation",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with Foundation",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Screenwriting and Producing",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Screenwriting and Producing with Foundation",
                    "university": "Regent's University London",
                    "location": "Regent's University London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "b747c5100200ea65eceba60e6bb82b84",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Applied Theology",
                    "university": "Regents Theological College",
                    "location": "Regents Theological College",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Applied Theology",
                    "university": "Regents Theological College",
                    "location": "Regents Theological College",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "89d284dfe02102e1f67ad543ab8ed18c",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting with Data Science",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Advanced Architectural Technology",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "MSci",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Psychology",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Social Sciences",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Sociology",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Sport and Exercise Science",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "MArch",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business with Data Analytics",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "102-112"
                },
                {
                    "course_name": "Business with Finance and Economics",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "102-112"
                },
                {
                    "course_name": "Business with Human Resources Management",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business with Marketing",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication Design",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BSc (Hons)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing and Creative Design",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "MSc (UG)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BSc (Hons)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science with Artificial Intelligence",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science with Business Management",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diagnostic Radiography",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "Master of Diagnostic Radiography \u2013 MDRad",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing and Business Analytics",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "MEng",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Design",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Events Management",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion and Textiles Design",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Management",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Media",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Forensic and Analytical Science",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Foundation in Art & Design",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Design",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Design",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BDes (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-108"
                },
                {
                    "course_name": "International Business Management",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Hospitality Management",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tourism Management",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Management",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Artificial Intelligence",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical and Electrical Engineering",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "MEng",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical and Electrical Engineering",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical and Offshore Engineering",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "MEng",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical and Offshore Engineering",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "MEng",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "102-112"
                },
                {
                    "course_name": "Midwifery (Postgraduate Entry)",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Adult and Children and Young People)",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "102-112"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Children and Young People and Mental Health)",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "102-112"
                },
                {
                    "course_name": "Nursing (Children and Young People)",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Mental Health and Adult)",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "102-112"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nutrition and Dietetics",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "MDiet",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "MOccTh",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paramedicine",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Pharmacy",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "MPharm",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Photography",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BA",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "MPhysio",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product, Ceramics and Jewellery",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantity Surveying and Commercial Management",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Renewable Energy Engineering",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Renewable Energy Engineering",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work (Postgraduate Entry)",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Coaching",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Web and Mobile Design",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advancing Practice",
                    "university": "Robert Gordon University",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Analytical Science (Food Analysis, Authenticity and Safety)",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Analytical Sciences (Drug Analysis and Toxicology)",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Analytical Sciences (Environmental Analysis)",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture (Part 2) RIBA/ARB",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MArch Studies",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Technology",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management with Sustainability",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Innovation and Entrepreneurship",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Leadership and Management",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business with Financial Management",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business with HR Management",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business with Strategic Risk Management",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication Design",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Corporate Communications and Public Affairs",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "14 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drilling and Well Engineering",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Management",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Transitions and Sustainability",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Management",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion and Textiles",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Management",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Healthcare Leadership",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Technology",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Technology with Business Intelligence",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Technology with Cyber Security",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Environmental Law",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Fashion Business",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Marketing",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tourism and Hospitality Management",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "14 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "IT for the Energy Industry",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "LLM",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Dispute Resolution",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Energy Law",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and International Commercial Law",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "LLM",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and International Law",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "LLM",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Technology",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "LLM",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oil and Gas Engineering",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oil, Gas and Renewable Energy Law",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "LLM",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Science",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy - Pre-registration",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Procurement and Supply Chain Management",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Legal Practice",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "PgDip (Initial Qualification)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management",
                    "university": "Robert Gordon University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health and Health Promotion",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "13 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Renewable Energy Engineering",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advancing Practice",
                    "university": "Robert Gordon University",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "36 months",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Analytical Science (Food Analysis, Authenticity and Safety)",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Analytical Sciences (Drug Analysis and Toxicology)",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Analytical Sciences (Environmental Analysis)",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication Design",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Corporate Communications and Public Affairs",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "33 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "33 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diagnostic Image Reporting",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "PgCert",
                    "duration": "24 months",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "33 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion and Textiles",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Management",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Technology",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "32 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Technology with Business Intelligence",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "32 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Technology with Cyber Security",
                    "university": "Robert Gordon University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "32 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Environmental Law",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Fashion Business",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "27 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tourism and Hospitality Management",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "33 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "IT for the Energy Industry",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "32 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "LLM",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Energy Law",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "LLM",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and International Commercial Law",
                    "university": "Robert Gordon University",
                    "location": "Main Site - Aberdeen",
                    "qualification": "LLM",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and International Law",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "LLM",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Technology",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "LLM",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Procurement and Supply Chain Management",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Legal Practice",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "PgDip (Initial Qualification)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management",
                    "university": "Robert Gordon University",
                    "location": "Garthdee Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "77506184c280007594f988d0e03e680b",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Acting",
                    "university": "Rose Bruford College",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Actor Musicianship",
                    "university": "Rose Bruford College",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "American Theatre Arts",
                    "university": "Rose Bruford College",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Contemporary and Popular Performance",
                    "university": "Rose Bruford College",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Costume Production",
                    "university": "Rose Bruford College",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Creative Lighting Control",
                    "university": "Rose Bruford College",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Design for Performance",
                    "university": "Rose Bruford College",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "European Theatre Arts",
                    "university": "Rose Bruford College",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Scenic Arts",
                    "university": "Rose Bruford College",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Stage Management",
                    "university": "Rose Bruford College",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Actor and Performer Training",
                    "university": "Rose Bruford College",
                    "location": "Rose Bruford College",
                    "qualification": "MA",
                    "duration": "13 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Actor Musicianship",
                    "university": "Rose Bruford College",
                    "location": "Rose Bruford College",
                    "qualification": "MA (PG)",
                    "duration": "13 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Devising Practice",
                    "university": "Rose Bruford College",
                    "location": "Arthaus.berlin",
                    "qualification": "MFA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Collaborative Theatre Making",
                    "university": "Rose Bruford College",
                    "location": "Rose Bruford College",
                    "qualification": "MA (PG)",
                    "duration": "13 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary Directing Practice",
                    "university": "Rose Bruford College",
                    "location": "Rose Bruford College",
                    "qualification": "MA",
                    "duration": "13 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Devised Theatre and Performance",
                    "university": "Rose Bruford College",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Light in Performance",
                    "university": "Rose Bruford College",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "13 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linklater Teaching Practice MFA (Voice & Theatre Arts)",
                    "university": "Rose Bruford College",
                    "location": "Main Site",
                    "qualification": "MFA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Queer Performance",
                    "university": "Rose Bruford College",
                    "location": "Rose Bruford College",
                    "qualification": "MA (PG)",
                    "duration": "13 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre for Young Audiences",
                    "university": "Rose Bruford College",
                    "location": "Rose Bruford College",
                    "qualification": "MFA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Integral Movement and Performance Practice MFA",
                    "university": "Rose Bruford College",
                    "location": "Arthaus.berlin",
                    "qualification": "MFA (PG)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre for Young Audiences",
                    "university": "Rose Bruford College",
                    "location": "Rose Bruford College",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "23231f1f591ec10b30cd444e10aafe43",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Ballet Education",
                    "university": "Royal Academy of Dance",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "daef8275de67fe4ef486658dea7893a2",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "a4768f8244514b550fa75bc9e66af2a1",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "5309ba073591ceddff2131c867a85b07",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Agri-Management (Top Up)",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agricultural Business Management",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Agricultural Business Management with Foundation Year",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Agricultural Business Management with Professional Placement Year",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Agriculture",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Agriculture and Farm Management",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Agriculture with Foundation Year",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Agriculture with Professional Placement Year",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Animal Management and Applied Zoology (Top Up)",
                    "university": "Royal Agricultural University",
                    "location": "Capel Manor College, Enfield Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Management and Zoology",
                    "university": "Royal Agricultural University",
                    "location": "Capel Manor College, Enfield Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business and Enterprise",
                    "university": "Royal Agricultural University",
                    "location": "Cultural Heritage Institute-RAU Swindon Centre",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Business Management",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business Management (Top Up)",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Foundation Year",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business Management with Professional Placement Year",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Environment and Sustainability",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Environment and Sustainability  with Professional Placement Year",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Environment and Sustainability with Foundation Year",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Equine Health and Welfare (Top Up)",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Equine Management",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Equine Science and Business",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Equine Science and Business with Foundation Year",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Equine Science and Business with Professional Placement Year",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Real Estate",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Real Estate with Foundation Year",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Real Estate with Professional Placement Year",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Residential Estate Agency",
                    "university": "Royal Agricultural University",
                    "location": "Cultural Heritage Institute-RAU Swindon Centre",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Rural Land Management",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Rural Land Management with Foundation Year",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Rural Land Management with Professional Placement Year",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Wildlife and Countryside Management (Top Up)",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Wildlife Conservation and Countryside Management",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Business and Enterprise",
                    "university": "Royal Agricultural University",
                    "location": "Cultural Heritage Institute-RAU Swindon Centre",
                    "qualification": "FdSc",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "56"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Agricultural Technology and Innovation",
                    "university": "Royal Agricultural University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agriculture",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "Grad Dip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "Royal Agricultural University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation and Management of Historic Buildings",
                    "university": "Royal Agricultural University",
                    "location": "Cultural Heritage Institute-RAU Swindon Centre",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA Food and Agriculture",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA Water Management",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Agroecology",
                    "university": "Royal Agricultural University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc by Research Programme",
                    "university": "Royal Agricultural University",
                    "location": "Royal Agricultural University",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Cultural Heritage Management",
                    "university": "Royal Agricultural University",
                    "location": "Cultural Heritage Institute-RAU Swindon Centre",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Heritage and Archaeology",
                    "university": "Royal Agricultural University",
                    "location": "Cultural Heritage Institute-RAU Swindon Centre",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PhD Programme",
                    "university": "Royal Agricultural University",
                    "location": "Royal Agricultural University",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate",
                    "university": "Royal Agricultural University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Rural Estate Management",
                    "university": "Royal Agricultural University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Agriculture and Food Security",
                    "university": "Royal Agricultural University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Agricultural Technology and Innovation",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Agriculture",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "Grad Dip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "Royal Agricultural University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation and Management of Historic Buildings",
                    "university": "Royal Agricultural University",
                    "location": "Cultural Heritage Institute-RAU Swindon Centre",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA Food and Agriculture",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA Water Management",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Agroecology",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc by Research Programme",
                    "university": "Royal Agricultural University",
                    "location": "Royal Agricultural University",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Cultural Heritage Management",
                    "university": "Royal Agricultural University",
                    "location": "Cultural Heritage Institute-RAU Swindon Centre",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Heritage and Archaeology",
                    "university": "Royal Agricultural University",
                    "location": "Cultural Heritage Institute-RAU Swindon Centre",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PhD Programme",
                    "university": "Royal Agricultural University",
                    "location": "Royal Agricultural University",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate",
                    "university": "Royal Agricultural University",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Rural Estate Management",
                    "university": "Royal Agricultural University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Agriculture and Food Security",
                    "university": "Royal Agricultural University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "7a7a8c9678600e874cf5172af9e4ce17",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Acting BA",
                    "university": "Royal Birmingham Conservatoire",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Jazz BMus",
                    "university": "Royal Birmingham Conservatoire",
                    "location": "Main Site",
                    "qualification": "BMus (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Music BMus",
                    "university": "Royal Birmingham Conservatoire",
                    "location": "Main Site",
                    "qualification": "BMus",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Acting MA",
                    "university": "Royal Birmingham Conservatoire",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Acting PgDip",
                    "university": "Royal Birmingham Conservatoire",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Postgraduate Diploma Professional Performance (Full-Time)",
                    "university": "Royal Birmingham Conservatoire",
                    "location": "Main Site",
                    "qualification": "AdvPgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music MMus (Full-Time)",
                    "university": "Royal Birmingham Conservatoire",
                    "location": "Main Site",
                    "qualification": "MMus",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music PgDip (Full-Time)",
                    "university": "Royal Birmingham Conservatoire",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Musicology",
                    "university": "Royal Birmingham Conservatoire",
                    "location": "Main Site",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Postgraduate Diploma Professional Performance  (Part-Time)",
                    "university": "Royal Birmingham Conservatoire",
                    "location": "Main Site",
                    "qualification": "AdvPgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time day and evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music MMus (Part-Time)",
                    "university": "Royal Birmingham Conservatoire",
                    "location": "Main Site",
                    "qualification": "MMus",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music PgCert (Part-Time)",
                    "university": "Royal Birmingham Conservatoire",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music PgDip (Part-Time)",
                    "university": "Royal Birmingham Conservatoire",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Musician as Educator",
                    "university": "Royal Birmingham Conservatoire",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Musicology",
                    "university": "Royal Birmingham Conservatoire",
                    "location": "Main Site",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "010c42b9552239f01fe6f60ce2d64d40",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Acting",
                    "university": "Royal Central School of Speech and Drama, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Costume Production",
                    "university": "Royal Central School of Speech and Drama, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64-120"
                },
                {
                    "course_name": "Drama and Applied Theatre",
                    "university": "Royal Central School of Speech and Drama, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Experimental Arts and Performance",
                    "university": "Royal Central School of Speech and Drama, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Performance Design",
                    "university": "Royal Central School of Speech and Drama, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64-120"
                },
                {
                    "course_name": "Production Arts: Props, Painting and Set",
                    "university": "Royal Central School of Speech and Drama, University of London",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64-120"
                },
                {
                    "course_name": "Production Technologies and Stage Management",
                    "university": "Royal Central School of Speech and Drama, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64-120"
                },
                {
                    "course_name": "Writing for Performance",
                    "university": "Royal Central School of Speech and Drama, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "9e970f7c01d7925ca961bf4047ed2f10",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "07fd8016eeee8b8ebbc0574be97b95b0",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "942cc59fb28a195a4f203fa877c9cec3",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "2b5f213b572869d3b555c4008d608693",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "125edb4b5ea2944eb6c83e4fe3e97bce",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance with a Year in Business",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient and Medieval History",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History and Philosophy",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History with Philosophy",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Psychology",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astrophysics",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astrophysics",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry with a Year in Industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Biology",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology with a Year in Industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Biology with Integrated Foundation Year",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences with a Year in Industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Biomedical Sciences with Integrated Foundation Year",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management (Year in Business)",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Archaeology and Ancient History",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies and Drama",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Studies with Integrated Foundation year",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Classical Studies with Philosophy",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Philosophy",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature and Culture",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature and Culture and Drama",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature and Culture and English",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature and Culture with an Integrated Foundation Year",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Comparative Literature and Culture with Philosophy",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature and Cultures with History of Art and Visual Culture",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Artificial Intelligence)",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Artificial Intelligence)",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Artificial Intelligence) with a Year-in-Industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Artificial Intelligence) with a Year-in-Industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Cyber Security)",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Cyber Security)",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Cyber Security) with a Year-in-Industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Cyber Security) with Year-in-Industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Software Engineering)",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Software Engineering)",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Software Engineering) with a Year-in-Industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Software Engineering) with a Year-in-Industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Mathematics",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with a Year-in-Industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with a Year-in-Industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Integrated Foundation Year",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Systems Engineering",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Systems Engineering",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Systems Engineering with a Year in Industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Systems Engineering with a Year in Industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Systems Engineering with Integrated Foundation Year",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Corporate Finance",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Psychology",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Psychology with a Year in Industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Sociology with a Year in Industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media Culture and Technology",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama and Creative Writing",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama and Philosophy",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama and Theatre Studies",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama and Theatre Studies with Integrated Foundation Year",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Drama with Acting",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama with Film",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama with Philosophy",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth, Climate and Environmental Change",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth, Climate and Environmental Change",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecology and Conservation",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecology and Conservation with a Year in Industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Economics",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Data Science",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Data Science with a Year in Business",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Management",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Management with a Year in Business",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Mathematics",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Mathematics with a Year in Business",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with a Year in Business",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Music",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Political Studies",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Political Studies with a Year in Business",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics, Politics and International Relations",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics, Politics and International Relations with a Year in Business",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with a Year in Industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with a Year in Industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with Integrated Foundation Year",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "English",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and American Literature",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Classical Studies",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Creative Writing",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Drama",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Film Studies",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and History",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Latin",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English with Integrated Foundation Year",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "English with Philosophy",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment and Social Change",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Geology",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Geology with a Year in Industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Geoscience",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Geoscience with a Year of International Study",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Geoscience with Year in Industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film, Television and Digital Production",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film, Television and Digital Production with Integrated Foundation Year",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Finance",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Mathematics",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Mathematics with a Year in Business",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial and Business Economics",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial and Business Economics with a Year in Business",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genetics",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genetics with a Year in Industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography, Culture and Identity",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography, Environment and Climate",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography, Geopolitics and Global Affairs",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology with a Year in Industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology with Integrated Foundation Year",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geoscience",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geoscience with a Year in Industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geoscience with A Year of International Study",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geosciences & Sustainable Energy",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Greek",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Social Care with Integrated Foundation Year",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Health Studies",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Studies with Integrated Foundation Year",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "History",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Music",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Philosophy",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art and Visual Culture and Comparative Literature and Culture",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History with Integrated Foundation Year",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "History, Politics and International Relations",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Geography",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Latin",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with a Year in Industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Criminology with a Year in Industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Integrated Foundation Year",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with International Relations",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with International Relations with a Year in Industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Modern Languages (French)",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Modern Languages (German)",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Modern Languages (Italian)",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Modern Languages (Spanish)",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Philosophy",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Politics",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Politics with a Year in Industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Sociology",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Sociology with a Year in Industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Liberal Arts",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Liberal Arts with a Language Year Abroad",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Liberal Arts with an International Year",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Liberal Arts with Integrated Foundation Year",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Management with Accounting",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Accounting (Year in Business)",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Digital Innovation and Analytics",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Digital Innovation and Analytics (Year in Business)",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Entrepreneurship",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Entrepreneurship (Year in Business)",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Human Resources",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Human Resources (Year in Business)",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with International Business",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with International Business (Year in Business)",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Marketing",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Marketing (Year in Business)",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Music",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Philosophy",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Physics",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Physics",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Integrated Foundation Year",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Mathematics with Philosophy",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Statistics",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Biochemistry",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Biochemistry with a Year in Industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Modern Languages",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and Comparative Literature and Culture",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and Drama",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and English",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and Greek",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and History",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and History of Art and Visual Culture",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and Management",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and Music",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages with History of Art and Visual Culture",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages with International Relations",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages with Philosophy",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BMus (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and English",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Philosophy",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Sound Design for Film, Television and Interactive Media with Integrated Foundation Year",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Sound Design for Film, Television, and Interactive Media",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Theatre",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music with French",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music with German",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music with Integrated Foundation Year",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BMus",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music with Italian",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music with Philosophy",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music with Political Studies",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music with Spanish",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and History of Art and Visual Culture",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Law",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy with Integrated Foundation Year",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Philosophy with International Relations",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy with Politics",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Geography",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Integrated Foundation Year",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Physics with Music",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Particle Physics",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Particle Physics",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Philosophy",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations and Philosophy",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Law",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Law with a Year In Industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics with Philosophy",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, International Relations and Modern Languages (French)",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-147"
                },
                {
                    "course_name": "Politics, International Relations and Modern Languages (German)",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, International Relations and Modern Languages (Italian)",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, International Relations and Modern Languages (Spanish)",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, Philosophy and Economics",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology, Clinical and Cognitive Neuroscience",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology, Clinical Psychology and Mental Health",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology, Development and Development Disorders",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Science",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work (Postgraduate Entry) Central London Campus",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Philosophy",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Video Games Art and Design",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology with a Year in Industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Financial Management",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Electronic and Electrical Engineering",
                    "university": "Royal Holloway, University of London",
                    "location": "Central London Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Neuroscience",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence with a Year in Industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Electronic Engineering",
                    "university": "Royal Holloway, University of London",
                    "location": "Central London Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Art and Archaeology",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Psychology",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature and Culture",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature and Cultures",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MPhil",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Finance",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Finance with a year in industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Corporate Finance",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Royal Holloway, University of London",
                    "location": "Central London Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "Royal Holloway, University of London",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Crusader Studies",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cultural Geography",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security Project Management",
                    "university": "Royal Holloway, University of London",
                    "location": "Central London Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Analytics",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Analytics with a year in industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "Royal Holloway, University of London",
                    "location": "Central London Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama and Theatre Studies",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama, Theatre and Dance by Research",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth Sciences",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth Sciences",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Elections, Campaigns and Democracy",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Geosciences",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Geology and Hydrogeology",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Management",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Entrepreneurship and Innovation",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Entrepreneurship and Innovation (Year in Business)",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Diagnosis and Management",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "European Philosophy",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film, Television and Digital Production",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "Royal Holloway, University of London",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Security, Sustainability and Biodiversity",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "Royal Holloway, University of London",
                    "location": "Central London Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender Studies: Global Futures pathway",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender Studies: History and Socio-Legal Studies pathway",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender Studies: History pathway",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender Studies: International Relations pathway",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender Studies: Philosophy pathway",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender Studies: Politics pathway",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geopolitics, Development, Security and Justice",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Futures",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health: Economics and Policy",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health: Human Health and the Environment",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health: Society, Culture and Behaviour",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Studies",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Holocaust Studies",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Immersive Storytelling",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Security",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Security",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Security with a Year in Industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management (Marketing)",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Media Management",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Public Policy and Development",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Security",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Late Antique and Byzantine Studies",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Logistics and Supply Chain Management",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Machine Learning",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Machine Learning with a year in industry",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media Arts",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media, Power and Public Affairs",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval Studies",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microwave and Optical Fibre Communications",
                    "university": "Royal Holloway, University of London",
                    "location": "Central London Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc Occupational Therapy (Pre-reg) (MSc)",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MMus",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Philosophy",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "PhD",
                    "duration": "48 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Producing Film and Television",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public History",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Rhetoric",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Smart Power Systems",
                    "university": "Royal Holloway, University of London",
                    "location": "Central London Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Sciences",
                    "university": "Royal Holloway, University of London",
                    "location": "Central London Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work - Central London Campus",
                    "university": "Royal Holloway, University of London",
                    "location": "Central London Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Royal Holloway, University of London",
                    "location": "Central London Campus",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability and Management",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Terrorism and Counter-Terrorism Studies",
                    "university": "Royal Holloway, University of London",
                    "location": "Central London Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre Directing",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Victorian Literature, Art and Culture",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Ancient History",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Neuroscience",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Art and Archaeology",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature and Culture",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature and Cultures",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Finance",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Royal Holloway, University of London",
                    "location": "Central London Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Crusader Studies",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security Project Management",
                    "university": "Royal Holloway, University of London",
                    "location": "Central London Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Analytics",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama and Theatre Studies",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama, Theatre and Dance by Research",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth Sciences",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth Sciences",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Elections, Campaigns and Democracy",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Geosciences",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Geology and Hydrogeology",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "Royal Holloway, University of London",
                    "location": "No Locations",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Diagnosis and Management",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "European Philosophy",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film, Television and Digital Production",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "Royal Holloway, University of London",
                    "location": "Central London Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Studies",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Holocaust Studies",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Immersive Storytelling",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Security",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Security",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Public Policy and Development",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "PgDip",
                    "duration": "21 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Security",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Late Antique and Byzantine Studies",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Machine Learning",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media Arts",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media, Power and Public Affairs",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval Studies",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MMus",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Philosophy",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public History",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Sciences",
                    "university": "Royal Holloway, University of London",
                    "location": "Central London Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "Royal Holloway, University of London",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "Royal Holloway, University of London",
                    "location": "Central London Campus",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability and Management",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Terrorism and Counter-Terrorism Studies",
                    "university": "Royal Holloway, University of London",
                    "location": "Central London Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Victorian Literature, Art and Culture",
                    "university": "Royal Holloway, University of London",
                    "location": "Royal Holloway",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "525aafd9427af54ad119b9c2d6d2fffa",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "e36f7cfca893a1bcfa8ab33ac6f4c6a5",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "ac3c637aeb5a2a8dee5cc8f4432b5f2d",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Audio Production",
                    "university": "SAE Institute",
                    "location": "Multiple Locations",
                    "qualification": "BA/BSc (H)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Content Creation & Online Marketing",
                    "university": "SAE Institute",
                    "location": "Multiple Locations",
                    "qualification": "BA/BSc (H)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Film Production",
                    "university": "SAE Institute",
                    "location": "Multiple Locations",
                    "qualification": "BA/BSc (H)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Game Art Animation",
                    "university": "SAE Institute",
                    "location": "Multiple Locations",
                    "qualification": "BA/BSc (H)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Game Design",
                    "university": "SAE Institute",
                    "location": "Multiple Locations",
                    "qualification": "BA/BSc (H)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Games Programming",
                    "university": "SAE Institute",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Music Business",
                    "university": "SAE Institute",
                    "location": "Multiple Locations",
                    "qualification": "BA/BSc (H)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Visual Effects Animation",
                    "university": "SAE Institute",
                    "location": "Multiple Locations",
                    "qualification": "BA/BSc (H)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Web Development",
                    "university": "SAE Institute",
                    "location": "Multiple Locations",
                    "qualification": "BA/BSc (H)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "2a4233cfabe5a761322b8dc6d3b38da5",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Ancient History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Ancient History and Archaeology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Ancient History and Archaeology and Social Anthropology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Ancient History and Art History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Ancient History and Biblical Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Ancient History and Comparative Literature",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Ancient History and Economics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Ancient History and English",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Ancient History and Film Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Ancient History and French",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Ancient History and French (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Ancient History and German",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Ancient History and German (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Ancient History and Greek",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Ancient History and International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Ancient History and Italian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Ancient History and Italian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Ancient History and Latin",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Ancient History and Mathematics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Ancient History and Medieval History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Ancient History and Persian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Ancient History and Philosophy",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Ancient History and Social Anthropology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Ancient History and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Ancient History and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Animal Behaviour",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Applied Mathematics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MMath (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Arabic and Ancient History (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and Art History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Arabic and Art History (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Arabic and Biblical Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Arabic and Biblical Studies (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Arabic and Chinese Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and Chinese Studies (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and Classical Studies (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and Classics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Arabic and Classics (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Arabic and Comparative Literature",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and Comparative Literature (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and Comparative Literature and Persian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and Comparative Literature and Persian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and Economics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Arabic and Economics (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Arabic and English",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Arabic and English (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Arabic and Film Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and Film Studies (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and French",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and French (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and French and Persian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and French and Persian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and Geography",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Arabic and Geography (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Arabic and German",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and German (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and German and Persian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and German and Persian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and Hebrew",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Arabic and Hebrew (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Arabic and International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Arabic and International Relations (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Arabic and Italian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and Italian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and Italian and Persian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and Italian and Persian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and Latin",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Arabic and Latin (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Arabic and Management",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Arabic and Management (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Arabic and Mathematics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Arabic and Mathematics (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Arabic and Medieval History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Arabic and Medieval History (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Arabic and Middle East Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Arabic and Middle East Studies (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Arabic and Modern History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Arabic and Modern History (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Arabic and Persian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and Persian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and Persian and Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and Persian and Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and Persian and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and Persian and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and Philosophy",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and Philosophy (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and Psychology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Arabic and Psychology (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and Social Anthropology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and Social Anthropology (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic and Sustainable Development",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Arabic and Sustainable Development (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Arabic, Comparative Literature and French",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic, Comparative Literature and French (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic, Comparative Literature and German",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic, Comparative Literature and German (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic, Comparative Literature and Italian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic, Comparative Literature and Italian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic, Comparative Literature and Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic, Comparative Literature and Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic, Comparative Literature and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic, Comparative Literature and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic, French and German (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic, French and Italian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic, French and Italian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic, French and Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic, French and Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic, French and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic, French and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic, German and Italian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic, German and Italian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic, German and Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic, German and Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic, German and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic, German and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic, Italian and Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic, Italian and Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic, Italian and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic, Italian and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic, Russian and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Arabic, Russian and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Art History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Art History and Biblical Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Art History and Chinese Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Art History and Chinese Studies (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Art History and Classical Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Art History and Comparative Literature",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Art History and English",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Art History and Film Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Art History and French",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Art History and French (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Art History and Geography",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Art History and German",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Art History and German (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Art History and Greek",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Art History and Hebrew",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Art History and International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Art History and Italian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Art History and Italian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Art History and Management",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Art History and Mathematics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Art History and Medieval History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Art History and Middle East Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Art History and Modern History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Art History and Philosophy",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Art History and Psychology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Art History and Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Art History and Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Art History and Social Anthropology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Art History and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Art History and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Art History and Sustainable Development",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Art History with Bible and Culture",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Astrophysics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "132-144"
                },
                {
                    "course_name": "Astrophysics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MPhys (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "132-144"
                },
                {
                    "course_name": "Biblical Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Biblical Studies and Classical Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Biblical Studies and Comparative Literature",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Biblical Studies and Economics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Biblical Studies and English",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Biblical Studies and Film Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Biblical Studies and French",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Biblical Studies and French (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Biblical Studies and German",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Biblical Studies and German (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Biblical Studies and Greek",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Biblical Studies and Hebrew",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Biblical Studies and International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Biblical Studies and Mathematics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Biblical Studies and Medieval History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Biblical Studies and Philosophy",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Biochemistry (MBiochem)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MBiochem",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Biology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-144"
                },
                {
                    "course_name": "Biology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MBiol (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Biology and Chemistry",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Biology and Computer Science",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Biology and Earth Sciences",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Biology and Economics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Biology and Geography",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Biology and Mathematics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Biology and Philosophy",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Biology and Psychology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Biology and Statistics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Biology and Sustainable Development",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Biology with Arabic",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Biology with French",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Biology with French (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Cell Biology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Chemical Sciences",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Chemistry and Earth Sciences",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-159"
                },
                {
                    "course_name": "Chemistry and Mathematics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Chemistry and Physics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Chemistry with External Placement",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Chemistry with French",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Chemistry with French",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Chemistry with French (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Chemistry with French and External Placement",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Chemistry with Mathematics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Chemistry with Medicinal Chemistry",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Chemistry with Medicinal Chemistry",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Chemistry with Medicinal Chemistry and External Placement",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Chinese Studies and Comparative Literature",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Chinese Studies and Comparative Literature (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Chinese Studies and Economics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Chinese Studies and Economics (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Chinese Studies and Film Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Chinese Studies and Film Studies (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Chinese Studies and French",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Chinese Studies and French (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Chinese Studies and German",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Chinese Studies and German (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Chinese Studies and International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Chinese Studies and International Relations (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Chinese Studies and Italian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Chinese Studies and Italian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Chinese Studies and Management",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Chinese Studies and Management (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Chinese Studies and Modern History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Chinese Studies and Modern History (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Chinese Studies and Persian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Chinese Studies and Persian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Chinese Studies and Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Chinese Studies and Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Chinese Studies and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Chinese Studies and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Chinese Studies, French and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Classical Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Classical Studies and Comparative Literature",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Classical Studies and English",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Classical Studies and Film Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Classical Studies and French",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Classical Studies and French (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Classical Studies and Greek",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Classical Studies and International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Classical Studies and Italian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Classical Studies and Italian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Classical Studies and Latin",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Classical Studies and Mathematics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Classical Studies and Medieval History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Classical Studies and Middle East Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Classical Studies and Modern History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Classical Studies and Persian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Classical Studies and Philosophy",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Classical Studies and Scottish History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Classical Studies and Social Anthropology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Classical Studies and Theological Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Classics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Classics and Comparative Literature",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Classics and English",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Classics and French",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Classics and French (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Classics and Italian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Classics and Italian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Classics and Management",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Classics and Modern History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Classics and Persian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Classics and Philosophy",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Classics and Social Anthropology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Classics and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Classics and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Comparative Literature",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Comparative Literature and English",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Comparative Literature and Film Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Comparative Literature and French",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Comparative Literature and French (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Comparative Literature and French-German",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Comparative Literature and French-German (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Comparative Literature and French-Italian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Comparative Literature and French-Italian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Comparative Literature and French-Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Comparative Literature and French-Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Comparative Literature and French-Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Comparative Literature and French-Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Comparative Literature and Geography",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Comparative Literature and German",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Comparative Literature and German (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Comparative Literature and German-Italian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Comparative Literature and German-Italian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Comparative Literature and German-Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Comparative Literature and German-Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Comparative Literature and German-Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Comparative Literature and German-Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Comparative Literature and Greek",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Comparative Literature and Hebrew",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Comparative Literature and International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Comparative Literature and Italian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Comparative Literature and Italian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Comparative Literature and Italian-Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Comparative Literature and Italian-Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Comparative Literature and Italian-Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Comparative Literature and Italian-Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Comparative Literature and Latin",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Comparative Literature and Management",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Comparative Literature and Medieval History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Comparative Literature and Modern History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Comparative Literature and Persian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Comparative Literature and Philosophy",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Comparative Literature and Psychology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Comparative Literature and Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Comparative Literature and Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Comparative Literature and Russian-Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Comparative Literature and Russian-Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Comparative Literature and Social Anthropology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Comparative Literature and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Comparative Literature and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Computer Science and Economics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Computer Science and Management",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Computer Science and Mathematics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-160"
                },
                {
                    "course_name": "Computer Science and Philosophy",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Computer Science and Psychology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Computer Science and Statistics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-159"
                },
                {
                    "course_name": "Divinity BD",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BD (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Divinity with Biblical Languages",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BD (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth Sciences",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MGeol (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Earth Sciences and Geography",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Ecology and Conservation",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Economics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Economics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Economics and English",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Economics and Film Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Economics and Geography",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Economics and Geography",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Economics and German",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Economics and German (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Economics and International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Economics and Italian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Economics and Italian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Economics and Management",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Economics and Management",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Economics and Mathematics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Economics and Mathematics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Economics and Medieval History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Economics and Middle East Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Economics and Modern History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Economics and Persian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Economics and Philosophy",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Economics and Psychology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Economics and Psychology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Economics and Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Economics and Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Economics and Social Anthropology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Economics and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Economics and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Economics and Statistics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Economics and Statistics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Economics and Sustainable Development",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Economics and Sustainable Development",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Economics with German",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Economics with German (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Economics with Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Economics with Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Economics with Social Anthropology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Economics with Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Economics with Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "English",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "English and Film Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "English and French",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "English and French (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "English and German",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "English and German (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "English and Greek",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "English and Hebrew",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "English and International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "English and Italian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "English and Italian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "English and Latin",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "English and Management",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "English and Mathematics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "English and Medieval History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "English and Middle East Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "English and Modern History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "English and Persian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "English and Philosophy",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "English and Psychology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "English and Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "English and Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "English and Scottish History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "English and Social Anthropology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "English and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "English and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "English and Sustainable Development",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "English and Theological Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "English with Bible and Culture",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Environmental Earth Sciences",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Evolutionary Biology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "FE-HE Pathway to Arts",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Gen)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "FE-HE Pathway to Science",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Film Studies and French",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Film Studies and French (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Film Studies and Geography",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Film Studies and German",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Film Studies and German (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Film Studies and International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Film Studies and Italian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Film Studies and Italian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Film Studies and Modern History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Film Studies and Persian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Film Studies and Philosophy",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Film Studies and Psychology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Film Studies and Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Film Studies and Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Film Studies and Scottish History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Film Studies and Social Anthropology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Film Studies and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Film Studies and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Film Studies and Sustainable Development",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Film Studies and Theological Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Film Studies with Bible and Culture",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Financial Economics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Financial Economics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Financial Economics and Management",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Financial Economics and Management",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Financial Economics and Mathematics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "38"
                },
                {
                    "course_name": "Financial Economics and Mathematics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "French",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and Geography",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "French and Geography (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "French and German",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and German (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and German and Italian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and German and Italian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and German and Persian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and German and Persian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and German and Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and German and Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and German and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and German and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and Greek",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "French and Greek (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "French and Hebrew",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "French and Hebrew (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "French and International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "French and International Relations (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "French and Italian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and Italian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and Italian and Persian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and Italian and Persian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and Italian and Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and Italian and Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and Italian and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and Italian and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and Latin",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "French and Latin (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "French and Management",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "French and Management (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "French and Medieval History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "French and Medieval History (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "French and Modern History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "French and Modern History (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "French and Persian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and Persian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and Persian and Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and Persian and Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and Persian and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and Persian and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and Philosophy",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and Philosophy (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and Psychology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and Psychology (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and Russian and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and Russian and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and Social Anthropology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and Social Anthropology (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "French and Theological Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "French and Theological Studies (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Gateway to Arts",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Gen)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "108"
                },
                {
                    "course_name": "Gateway to Medicine",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Gen)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "108"
                },
                {
                    "course_name": "Gateway to Sciences",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "108"
                },
                {
                    "course_name": "General Degree (Arts)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Gen)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "General Degree (Sciences)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Gen)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Geography",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Geography and Hebrew",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Geography and International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Geography and Italian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Geography and Italian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Geography and Management",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Geography and Management",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Geography and Mathematics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Geography and Medieval History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Geography and Middle East Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Geography and Modern History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Geography and Persian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Geography and Philosophy",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Geography and Psychology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Geography and Psychology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Geography and Scottish History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Geography and Social Anthropology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Geography and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Geography and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Geography and Statistics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-152"
                },
                {
                    "course_name": "Geography and Sustainable Development",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Geography and Sustainable Development",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Geography and Theological Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Geography with Persian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Geography with Social Anthropology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Geography with Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Geography with Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Geology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "German",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "German (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "German and International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "German and International Relations (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "German and Italian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "German and Italian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "German and Italian and Persian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "German and Italian and Persian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "German and Italian and Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "German and Italian and Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "German and Italian and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "German and Italian and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "German and Management",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "German and Management (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "German and Mathematics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "German and Mathematics (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "German and Medieval History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "German and Medieval History (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "German and Middle East Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "German and Middle East Studies (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "German and Modern History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "German and Modern History (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "German and Persian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "German and Persian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "German and Persian and Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "German and Persian and Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "German and Persian and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "German and Persian and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "German and Philosophy",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "German and Philosophy (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "German and Psychology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "German and Psychology (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "German and Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "German and Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "German and Russian and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "German and Russian and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "German and Social Anthropology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "German and Social Anthropology (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "German and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "German and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "German and Sustainable Development",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "German and Sustainable Development (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "German and Theological Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "German and Theological Studies (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Greek",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Greek and Hebrew",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Greek and Modern History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Greek and Persian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Greek and Philosophy",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Greek and Social Anthropology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Greek and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Greek and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Hebrew and Mathematics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Hebrew and Middle East Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "International Relations and Italian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "International Relations and Italian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "International Relations and Management",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "International Relations and Mathematics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "International Relations and Medieval History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "International Relations and Middle East Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "International Relations and Modern History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "International Relations and Persian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "International Relations and Philosophy",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "International Relations and Psychology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "International Relations and Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "International Relations and Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "International Relations and Scottish History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "International Relations and Social Anthropology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "International Relations and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "International Relations and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "International Relations and Sustainable Development",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "International Relations and Theological Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "International Relations with Persian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "International Relations with Religion in Society",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Italian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Italian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Italian and Latin",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Italian and Latin (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Italian and Management",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Italian and Management (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Italian and Mathematics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Italian and Mathematics (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Italian and Medieval History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Italian and Medieval History (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Italian and Modern History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Italian and Modern History (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Italian and Persian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Italian and Persian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Italian and Persian and Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Italian and Persian and Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Italian and Persian and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Italian and Persian and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Italian and Philosophy",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Italian and Philosophy (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Italian and Psychology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Italian and Psychology (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Italian and Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Italian and Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Italian and Russian and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Italian and Russian and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Italian and Scottish History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Italian and Scottish History (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Italian and Social Anthropology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Italian and Social Anthropology (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Italian and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Italian and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Italian and Sustainable Development",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Italian and Sustainable Development (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Latin",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Latin and International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Latin and Mathematics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Latin and Medieval History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Latin and Modern History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Latin and Persian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Latin and Philosophy",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Latin and Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Latin and Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Latin and Social Anthropology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Latin and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Latin and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Management",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Management",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Management and Middle East Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Management and Modern History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Management and Persian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Management and Philosophy",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Management and Psychology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Management and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Management and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Management and Sustainable Development",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Management and Sustainable Development",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Management with Religion in Society",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Marine Biology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MMarBiol",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Marine Biology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-144"
                },
                {
                    "course_name": "Materials Chemistry",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Materials Chemistry",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-159"
                },
                {
                    "course_name": "Materials Chemistry with External Placement",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MMath (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Mathematics and Medieval History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Mathematics and Modern History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Mathematics and Philosophy",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Mathematics and Philosophy",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Mathematics and Physics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Mathematics and Psychology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Mathematics and Psychology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Mathematics and Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Mathematics and Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Mathematics and Scottish History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Mathematics and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Mathematics and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Mathematics and Theological Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Mathematics and Theoretical Physics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MPhys (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Mathematics with Geography",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Mathematics with Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Mathematics with Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Mathematics with Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Mathematics with Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Medicine (5 years) (Scottish Community Orientated MBChB)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MB ChB",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Medicine (6 years)",
                    "university": "University of St Andrews",
                    "location": "Multiple Locations",
                    "qualification": "MB ChB",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Medicine (Graduate Entry) (4 years)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MB ChB",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine - Canadian Programme",
                    "university": "University of St Andrews",
                    "location": "St Andrews yrs 1-3, Edinburgh Medical School yrs 4-6",
                    "qualification": "MB ChB",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144"
                },
                {
                    "course_name": "Medieval History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Medieval History and Archaeology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Medieval History and Middle East Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Medieval History and Persian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Medieval History and Philosophy",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Medieval History and Psychology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Medieval History and Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Medieval History and Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Medieval History and Social Anthropology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Medieval History and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Medieval History and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Medieval History and Theological Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Medieval History with Bible and Culture",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Medieval History with Persian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Middle East Studies and Modern History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Middle East Studies and Persian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Middle East Studies and Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Middle East Studies and Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Middle East Studies and Social Anthropology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Middle East Studies and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Middle East Studies and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern History and New Testament",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern History and Persian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern History and Philosophy",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern History and Psychology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern History and Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern History and Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern History and Social Anthropology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern History and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern History and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern History and Sustainable Development",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern History and Theological Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern History with Bible and Culture",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern History with Persian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and Comparative Literature) and Ancient History (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Arabic and Comparative Literature) and Classical Studies (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Arabic and Comparative Literature) and English (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and Comparative Literature) and International Relations (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and Comparative Literature) and Latin (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Arabic and Comparative Literature) and Management (Year Abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and French) and Ancient History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Arabic and French) and Ancient History (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Arabic and French) and Classical Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Arabic and French) and Classical Studies (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Arabic and French) and English",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and French) and English (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and French) and International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and French) and International Relations (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and French) and Latin",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Arabic and French) and Latin (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Arabic and French) and Management",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and French) and Management (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and German) and Ancient History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Arabic and German) and Ancient History (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Arabic and German) and English",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and German) and English (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and German) and International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and German) and International Relations (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and German) and Management",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and German) and Management (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and Italian) and Ancient History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Arabic and Italian) and Ancient History  (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Arabic and Italian) and Classical Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Arabic and Italian) and Classical Studies (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Arabic and Italian) and English",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and Italian) and English (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and Italian) and International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and Italian) and International Relations (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and Italian) and Latin",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Arabic and Italian) and Latin (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Arabic and Italian) and Management",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and Italian) and Management (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and Persian) and Ancient History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Arabic and Persian) and Ancient History (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Arabic and Persian) and Classical Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Arabic and Persian) and Classical Studies (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Arabic and Persian) and English",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and Persian) and English (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and Persian) and International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and Persian) and International Relations (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and Persian) and Latin",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Arabic and Persian) and Latin (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Arabic and Persian) and Management",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and Persian) and Management",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and Persian) and Middle East Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and Persian) and Middle East Studies (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and Russian) and Classical Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Arabic and Russian) and Classical Studies (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Arabic and Russian) and English",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and Russian) and English (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and Russian) and International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and Russian) and International Relations (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and Russian) and Latin",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Arabic and Russian) and Latin (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Arabic and Russian) and Social Anthropology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Modern Languages (Arabic and Russian) and Social Anthropology (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Modern Languages (Arabic and Spanish) and Ancient History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Arabic and Spanish) and Ancient History (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Arabic and Spanish) and English",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and Spanish) and English (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and Spanish) and International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and Spanish) and International Relations (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and Spanish) and Latin",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Arabic and Spanish) and Latin (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Arabic and Spanish) and Management",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Arabic and Spanish) and Management (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (French and German) and Ancient History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (French and German) and Ancient History (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (French and German) and English",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (French and German) and English (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (French and German) and Greek",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (French and German) and Greek (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (French and German) and International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (French and German) and International Relations (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (French and German) and Management",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (French and German) and Management (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (French and Italian) and Ancient History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (French and Italian) and Ancient History (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (French and Italian) and Classical Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (French and Italian) and Classical Studies (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (French and Italian) and English",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (French and Italian) and English (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (French and Italian) and Greek",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (French and Italian) and Greek (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (French and Italian) and International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (French and Italian) and International Relations (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (French and Italian) and Latin",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (French and Italian) and Latin (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (French and Italian) and Management",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (French and Italian) and Management (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (French and Persian) and Ancient History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (French and Persian) and Ancient History (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (French and Persian) and Classical Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (French and Persian) and Classical Studies (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (French and Persian) and English",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (French and Persian) and English (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (French and Persian) and Greek",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (French and Persian) and Greek (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (French and Persian) and International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (French and Persian) and International Relations (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (French and Persian) and Latin",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (French and Persian) and Latin (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (French and Persian) and Management",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (French and Persian) and Management (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (French and Russian) and Classical Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (French and Russian) and Classical Studies (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (French and Russian) and English",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (French and Russian) and English (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (French and Russian) and International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (French and Russian) and International Relations (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (French and Russian) and Latin",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (French and Russian) and Latin (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (French and Spanish) and Ancient History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (French and Spanish) and Ancient History (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (French and Spanish) and English",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (French and Spanish) and English (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (French and Spanish) and Greek",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (French and Spanish) and Greek (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (French and Spanish) and International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (French and Spanish) and International Relations (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (French and Spanish) and Latin",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (French and Spanish) and Latin (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (French and Spanish) and Management",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (French and Spanish) and Management (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (German and Italian) and Ancient History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (German and Italian) and Ancient History (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (German and Italian) and English",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (German and Italian) and English (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (German and Italian) and Greek",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (German and Italian) and Greek (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (German and Italian) and International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (German and Italian) and International Relations (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (German and Italian) and Management",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (German and Italian) and Management (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (German and Persian) and Ancient History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (German and Persian) and Ancient History (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (German and Persian) and English",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (German and Persian) and English (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (German and Persian) and Greek",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (German and Persian) and Greek (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (German and Persian) and International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (German and Persian) and International Relations (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (German and Persian) and Management",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (German and Persian) and Management (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (German and Russian) and English",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (German and Russian) and English (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (German and Russian) and International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (German and Russian) and International Relations (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (German and Spanish) and Ancient History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (German and Spanish) and Ancient History (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (German and Spanish) and English",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (German and Spanish) and English (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (German and Spanish) and Greek",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (German and Spanish) and Greek (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (German and Spanish) and International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (German and Spanish) and International Relations (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (German and Spanish) and Management",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (German and Spanish) and Management (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Italian and Persian) and Ancient History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Italian and Persian) and Ancient History (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Italian and Persian) and Classical Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Italian and Persian) and Classical Studies (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Italian and Persian) and English",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Italian and Persian) and English (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Italian and Persian) and Greek",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Italian and Persian) and Greek (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Italian and Persian) and International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Italian and Persian) and International Relations (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Italian and Persian) and Latin",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Italian and Persian) and Latin (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Italian and Persian) and Management",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Italian and Persian) and Management (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Italian and Russian) and Classical Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Italian and Russian) and Classical Studies (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Italian and Russian) and English",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Italian and Russian) and English (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Italian and Russian) and International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Italian and Russian) and International Relations (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Italian and Russian) and Latin",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Italian and Russian) and Latin (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Italian and Spanish) and Ancient History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Italian and Spanish) and Ancient History (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Italian and Spanish) and English",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Italian and Spanish) and English (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Italian and Spanish) and Greek",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Italian and Spanish) and Greek (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Italian and Spanish) and International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Italian and Spanish) and International Relations (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Italian and Spanish) and Latin",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Italian and Spanish) and Latin (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Italian and Spanish) and Management",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Italian and Spanish) and Management (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Persian & Spanish) and English (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Persian and Russian) and Classical Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Persian and Russian) and Classical Studies (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Persian and Russian) and English",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Persian and Russian) and English (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Persian and Russian) and International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Persian and Russian) and Intl Relations (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Persian and Russian) and Latin",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Persian and Russian) and Latin (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Persian and Spanish) and Ancient History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Persian and Spanish) and Ancient History (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Persian and Spanish) and English",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Persian and Spanish) and International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Persian and Spanish) and International Relations (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Persian and Spanish) and Latin",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Persian and Spanish) and Latin (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Persian and Spanish) and Management",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Persian and Spanish) and Management (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Russian and Spanish) and English",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Russian and Spanish) and English (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Russian and Spanish) and International Relations",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Russian and Spanish) and International Relations (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Modern Languages (Russian and Spanish) and Latin",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Modern Languages (Russian and Spanish) and Latin (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Molecular Biology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Neuroscience",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Persian and Philosophy",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Persian and Psychology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Persian and Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Persian and Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Persian and Russian and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Persian and Russian and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Persian and Social Anthropology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Persian and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Persian and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Philosophy and Physics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "132-144"
                },
                {
                    "course_name": "Philosophy and Psychology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Philosophy and Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Philosophy and Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Philosophy and Scottish History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Philosophy and Social Anthropology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Philosophy and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Philosophy and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Philosophy and Statistics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Philosophy and Statistics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Philosophy and Sustainable Development",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Philosophy and Sustainable Development",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Philosophy and Theological Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Philosophy with Bible and Culture",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Philosophy with Religion in Society",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Physics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "132-144"
                },
                {
                    "course_name": "Physics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MPhys (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "132-144"
                },
                {
                    "course_name": "Physics and Astronomy (Gateway BSc)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "114"
                },
                {
                    "course_name": "Physics and Astronomy (Gateway MPhys)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MPhys (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "114"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Psychology and Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Psychology and Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Psychology and Social Anthropology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Psychology and Statistics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-152"
                },
                {
                    "course_name": "Psychology and Statistics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-152"
                },
                {
                    "course_name": "Psychology and Sustainable Development",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-152"
                },
                {
                    "course_name": "Psychology and Sustainable Development",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-152"
                },
                {
                    "course_name": "Psychology and Theological Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Psychology with Biology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Psychology with Geography",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Pure Mathematics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MMath (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-160"
                },
                {
                    "course_name": "Russian",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Russian (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Russian and Scottish History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Russian and Scottish History (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Russian and Social Anthropology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Russian and Social Anthropology (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Russian and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Russian and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Russian and Sustainable Development",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Russian and Sustainable Development (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Russian and Theological Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Russian and Theological Studies (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Russian with Geography",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Russian with Geography (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Scottish History",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Scottish History and Social Anthropology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Scottish History and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Scottish History and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Scottish History with Bible and Culture",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Social Anthropology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Social Anthropology and Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Social Anthropology and Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Social Anthropology and Sustainable Development",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Social Anthropology and Theological Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Social Anthropology with Geography",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Social Anthropology with Religion in Society",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144-159"
                },
                {
                    "course_name": "Spanish",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Spanish (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                },
                {
                    "course_name": "Spanish and Sustainable Development",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Spanish and Sustainable Development (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Spanish and Theological Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Spanish and Theological Studies (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Spanish with Geography",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Spanish with Geography (year abroad)",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Statistics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Statistics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MMath (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Statistics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "159-160"
                },
                {
                    "course_name": "Sustainable Development",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Sustainable Development",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Theological Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Theology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MTheol (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-144"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MPhys (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "132-144"
                },
                {
                    "course_name": "Zoology",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "126-136"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Analytic and Exegetical Theology",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Behaviour",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology, Art and Perception",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Statistics and Data Mining",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astrophysics",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Banking and Finance",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biblical Languages and Literature",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Catalysis",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Science",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative, Evolutionary and Developmental Psychology: Origins of Mind",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing and Information Technology",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MFA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data-Intensive Analysis",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Modern and Reformation History",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Policy and Finance",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental History",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Economics",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Management",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender Studies",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geochemistry",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Languages, Literatures, and Cultures",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Social and Political Thought",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Sustainable Development",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Data Science",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Photography",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Computer Interaction",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "IMACS: International Master in Audiovisual and Cinema Studies",
                    "university": "University of St Andrews",
                    "location": "Either",
                    "qualification": "MLitt",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intellectual History",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Education",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Political Theory",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Security Studies",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Iranian Studies",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal and Constitutional Studies",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Ecosystem Management",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Mammal Science",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical Biology",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval English",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval History",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval Studies",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Middle East, Caucasus and Central Asian Security Studies",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Middle Eastern History",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern and Contemporary Literature and Culture",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern History",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Museum and Heritage Studies",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Peacebuilding and Mediation",
                    "university": "University of St Andrews",
                    "location": "Main Site",
                    "qualification": "MLitt",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Playwriting and Screenwriting",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Conversion)",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Research Methods in Psychology",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sacred Music",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistical Ecology",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Studies",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Systematic and Historical Theology",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL)",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL)",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "PgCert",
                    "duration": "0.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL)",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL) with Assessment and Evaluation",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL) with Assessment and Evaluation",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL) with English for Academic Purposes",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL) with English for Academic Purposes",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL) with English Medium Instruction",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL) with English Medium Instruction",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL) with Specialism in Assessment and Evaluation",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "PgCert",
                    "duration": "0.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL) with Specialism in English for Academic Purposes",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "PgCert",
                    "duration": "0.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL) with Specialism in English Medium Instruction",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "PgCert",
                    "duration": "0.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL) with Specialism in Teaching Young Learners",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "PgCert",
                    "duration": "0.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL) with Specialism in Technology for Teaching",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "PgCert",
                    "duration": "0.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL) with Teaching Young Learners",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL) with Teaching Young Learners",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL) with Technology for Teaching",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL) with Technology for Teaching",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Terrorism and Political Violence",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "The Book. History and Techniques of Analysis",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "The Study of Judaism and Christianity",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theology and the Arts",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Transnational, Global and Spatial History",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Women, Writing and Gender",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL)",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL) with Specialism in Assessment and Evaluation",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL) with Specialism in English for Academic Purposes",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL) with Specialism in English Medium Instruction",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL) with Specialism in Teaching Young Learners",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL) with Specialism in Technology for Teaching",
                    "university": "University of St Andrews",
                    "location": "University of St Andrews",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "9e91fae79ae206787c4055f69d2552f8",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Biomedical Science BSc",
                    "university": "St George's, University of London",
                    "location": "St George's, University Of London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science MSci",
                    "university": "St George's, University of London",
                    "location": "St George's, University Of London",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Pharmacology BSc",
                    "university": "St George's, University of London",
                    "location": "St George's, University Of London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diagnostic Radiography BSc",
                    "university": "St George's, University of London",
                    "location": "St George's, University Of London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "81-128"
                },
                {
                    "course_name": "Healthcare Science (Physiological Sciences) BSc",
                    "university": "St George's, University of London",
                    "location": "St George's, University Of London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "81-128"
                },
                {
                    "course_name": "Medicine (Graduate Entry) MBBS",
                    "university": "St George's, University of London",
                    "location": "St George's, University Of London",
                    "qualification": "MB BS",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine (MBBS)",
                    "university": "St George's, University of London",
                    "location": "St George's, University Of London",
                    "qualification": "MB BS",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "99-152"
                },
                {
                    "course_name": "Occupational Therapy (Pre-registration) MSc",
                    "university": "St George's, University of London",
                    "location": "St George's, University Of London",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Therapy BSc",
                    "university": "St George's, University of London",
                    "location": "St George's, University Of London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "81-128"
                },
                {
                    "course_name": "Paramedic Science BSc",
                    "university": "St George's, University of London",
                    "location": "St George's, University Of London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "81-128"
                },
                {
                    "course_name": "Physician Associate Studies MPAS (Taught Postgraduate)",
                    "university": "St George's, University of London",
                    "location": "St George's, University Of London",
                    "qualification": "Master of Physician Associate Studies \u2013 MPAS",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy (Pre-registration) MSc",
                    "university": "St George's, University of London",
                    "location": "St George's, University Of London",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy BSc",
                    "university": "St George's, University of London",
                    "location": "St George's, University Of London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "87-144"
                },
                {
                    "course_name": "Radiotherapy and Oncology BSc",
                    "university": "St George's, University of London",
                    "location": "St George's, University Of London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "75-128"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Biomedical Science - Antimicrobial Resistance",
                    "university": "St George's, University of London",
                    "location": "St George's, University of London",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science - Infection and Immunity",
                    "university": "St George's, University of London",
                    "location": "St George's, University of London",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science - Molecular Mechanisms of Cancer",
                    "university": "St George's, University of London",
                    "location": "St George's, University of London",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science - Reproduction and Development",
                    "university": "St George's, University of London",
                    "location": "St George's, University of London",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Neuroscience Practice",
                    "university": "St George's, University of London",
                    "location": "St George's, University Of London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genomic Medicine",
                    "university": "St George's, University of London",
                    "location": "St George's, University of London",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health",
                    "university": "St George's, University of London",
                    "location": "St George's, University of London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health and Conflict",
                    "university": "St George's, University of London",
                    "location": "St George's, University of London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health and Humanities",
                    "university": "St George's, University of London",
                    "location": "St George's, University of London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health and Mental Health",
                    "university": "St George's, University of London",
                    "location": "St George's, University of London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health, Ethics and Law",
                    "university": "St George's, University of London",
                    "location": "St George's, University of London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health, Infection and Immunity",
                    "university": "St George's, University of London",
                    "location": "St George's, University of London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Ethics, Law and Humanities",
                    "university": "St George's, University of London",
                    "location": "St George's, University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Therapy (Pre-Registration)",
                    "university": "St George's, University of London",
                    "location": "St George's, University of London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physician Associate Studies (Taught Postgraduate)",
                    "university": "St George's, University of London",
                    "location": "St George's, University of London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy (pre-registration)",
                    "university": "St George's, University of London",
                    "location": "St George's, University of London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Cardiology",
                    "university": "St George's, University of London",
                    "location": "St George's, University of London",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Breast Practice",
                    "university": "St George's, University of London",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice",
                    "university": "St George's, University of London",
                    "location": "St George's, University of London",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Neuroscience Practice",
                    "university": "St George's, University of London",
                    "location": "St George's, University Of London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genomic Medicine",
                    "university": "St George's, University of London",
                    "location": "St George's, University of London",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health",
                    "university": "St George's, University of London",
                    "location": "St George's, University of London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health and Conflict",
                    "university": "St George's, University of London",
                    "location": "St George's, University of London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health and Humanities",
                    "university": "St George's, University of London",
                    "location": "St George's, University of London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health and Mental Health",
                    "university": "St George's, University of London",
                    "location": "St George's, University of London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health, Ethics and Law",
                    "university": "St George's, University of London",
                    "location": "St George's, University of London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health, Infection and Immunity",
                    "university": "St George's, University of London",
                    "location": "St George's, University of London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Ethics, Law and Humanities",
                    "university": "St George's, University of London",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Cardiology",
                    "university": "St George's, University of London",
                    "location": "St George's, University of London",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "fd90fc5c19217a03aa4aaff5f40c92b8",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Physical Education and Sport for Young People",
                    "university": "St Mary's University College, Belfast",
                    "location": "St Mary's University College, Belfast",
                    "qualification": "MSci",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Postgraduate Certificate in Education - Irish-medium Education (PGCE)",
                    "university": "St Mary's University College, Belfast",
                    "location": "St Mary's University College, Belfast",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Education",
                    "university": "St Mary's University College, Belfast",
                    "location": "St Mary's University College, Belfast",
                    "qualification": "MEd",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "891d66c579f6c3eb62b88755589fb25c",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Construction",
                    "university": "University Centre at Salford City College",
                    "location": "University Centre at Salford City College",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Media Make Up Artistry (top up)",
                    "university": "University Centre at Salford City College",
                    "location": "University Centre at Salford City College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media Make Up for Fashion",
                    "university": "University Centre at Salford City College",
                    "location": "University Centre at Salford City College",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Musical Theatre",
                    "university": "University Centre at Salford City College",
                    "location": "Pendleton Sixth Form College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "95a9c962a62069b4ad99ef3a7515c004",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accelerated Nursing (Adult)",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Accelerated Nursing (Children and Young People)",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Accelerated Nursing (Mental Health)",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Accounting and Finance with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Accounting and Finance with Professional Placement Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Acoustical and Audio Engineering",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Acoustical and Audio Engineering",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Acoustical and Audio Engineering with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64-72"
                },
                {
                    "course_name": "Acoustical and Audio Engineering with Professional Experience",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Acoustical and Audio Engineering with Professional Experience",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Adult Nursing with a Health Care Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Aeronautical Engineering",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Aeronautical Engineering",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Aeronautical Engineering with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Aircraft Engineering with Pilot Studies",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Aircraft Engineering with Pilot Studies",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Animation",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Animation with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Architectural Design and Technology",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Architectural Design and Technology with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Architectural Engineering",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "108"
                },
                {
                    "course_name": "Architectural Engineering with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Architecture (MArch)",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MArch (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Automation and Instrumentation DipHE",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Biochemistry with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Biochemistry with Studies in the USA",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Biological Sciences with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Biological Sciences with Placement",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biomedical Science with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Building Surveying",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Building Surveying with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Analytics DipHE",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Information Technology",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business Management with Economics",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business Management with Economics with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management with Economics with Professional Placement Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business Management with Entrepreneurship",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business Management with Entrepreneurship with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management with Entrepreneurship with Professional Placement Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business Management with Finance",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business Management with Finance with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management with Finance with Professional Placement Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business Management with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management with Law",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business Management with Law with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management with Law with Professional Placement Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business Management with Marketing",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business Management with Marketing with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management with Marketing with Professional Placement Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business Management with Professional Placement Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business Management with Sport",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business Management with Sport with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "2.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management with Sport with Professional Placement Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business with Human Resource Management",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business with Human Resource Management with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business with Human Resource Management with Professional Placement Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business with Management (Top-Up)",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business with Supply Chain and Project Management",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Business with Supply Chain and Project Management with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business with Supply Chain and Project Management with Professional Placement Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Children and Young Peoples Nursing with a Health Care Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Civil Engineering with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Computer Networks",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Computer Networks with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64-72"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Science with Cyber Security",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Science with Cyber Security with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64-72"
                },
                {
                    "course_name": "Computer Science with Cyber Security with Professional Experience",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Science with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64-72"
                },
                {
                    "course_name": "Computer Science with Professional Experience",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Construction Project Management with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Contemporary History and Politics",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Contemporary Military and International History",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Costume Design",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Costume Design with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Counselling and Psychotherapy (Professional Practice)",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Counselling and Psychotherapy with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Creative and Digital Media DipHE",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Creative Computing",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Creative Computing with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Creative Writing (Multidiscipline)",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology and Sociology with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Criminology with Counselling",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Criminology with Counselling with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Criminology with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Dance",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Data Science DipHE",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Diagnostic Radiography",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Digital Video Production and Marketing",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Editing and Visual Effects for Film and Broadcast",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Electrical & Electronic Engineering with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "English and Film",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "English Language",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "English Multidiscipline",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Environmental Geography with Professional Experience",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Environmental Geography with Studies in the USA",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Environmental Management",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Environmental Management with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Esports Business Management",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Fashion Business and Promotion",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Fashion Design",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Fashion Design with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Fashion Image Making and Styling",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Fashion Image Making and Styling with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Film Production",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Film Studies",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Film TV and Stage Design",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Film, TV and Stage Design with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Fine Art with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Games Design and Production",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Games Design and Production with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Geography with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Graphic Design with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Human Biology and Infectious Diseases",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Human Biology and Infectious Diseases with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Interior Architecture",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Interior Architecture with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Interior Design",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Interior Design with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "International Business Management",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "International Business Management with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "International Business Management with Professional Placement Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "International Relations and Politics",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Journalism with Public Relations",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Journalism: Broadcast",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Journalism: Multimedia",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Law",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Law with Criminology with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Law with Criminology with Professional Placement Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Law with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Law with Professional Placement Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Learning Disabilities Nursing and Social Work (Integrated Practice)",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Marine Biology",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Marine Biology with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Mathematics with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64-72"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Mechanical Engineering with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Media and Performance",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Mental Health Nursing with a Health Care Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Midwifery",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Motion Capture for the Creative Industries",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Motion Capture for the Creative industries",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Music Management and Creative Enterprise",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Music: Creative Music Technology",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Music: Creative Music Technology with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Music: Popular Music and Recording",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Music: Popular Music and Recording with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Nursing - Registered Nurse (Adult)",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Nursing - Registered Nurse (Adult) - First Year Taught at Bury College",
                    "university": "University of Salford",
                    "location": "Bury College",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing - Registered Nurse (Children & Young People)",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Nursing - Registered Nurse (Mental Health)",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Nursing Associate (Direct Entry Route)",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Occupational Therapy - Extended Route",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Pharmaceutical Science",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Pharmaceutical Science with a Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Photography",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Photography with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Physics with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64-72"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Podiatry",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Product Design Engineering",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Prosthetics and Orthotics",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Psychology and Counselling",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Psychology and Criminology",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Psychology of Human and Animal Behaviour",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Psychology of Sport",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Quantity Surveying",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Quantity Surveying with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Real Estate Development and Management",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Real Estate Development and Management with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Real Estate Development and Management with Placement Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Social Media Content Creation DipHE",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Social Work (Postgraduate Entry)",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sociology with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Software Engineering with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64-72"
                },
                {
                    "course_name": "Software Engineering with Professional Experience",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sound Engineering and Production",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Sound Engineering and Production with Professional Experience",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Sport Rehabilitation",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Sports Coaching Analysis (Top Up)",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Journalism",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sports Science",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Sports Science with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Technical Theatre (Production and Design)",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Television and Radio Production",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Theatre & Performance Practice",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Wildlife Conservation",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Wildlife Conservation with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Wildlife Conservation with Placement",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Wildlife Conservation with Zoo Biology",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Wildlife Conservation with Zoo Biology with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Zoology",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Zoology with Foundation Year",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and finance",
                    "university": "University of Salford",
                    "location": "Salford Business School",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Acoustics",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Counselling and Psychotherapy Studies",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Counselling and Psychotherapy Studies (Supervision)",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Medical Imaging",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Physiotherapy",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "PgCert",
                    "duration": "6 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Practice (Neonates)",
                    "university": "University of Salford",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animation",
                    "university": "University of Salford",
                    "location": "MediaCity Campus",
                    "qualification": "PgDip",
                    "duration": "8 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Psychology (Addictions)",
                    "university": "University of Salford",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Psychology (Therapies)",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Social Research Methods",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MArch Studies",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Audio Production",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "BIM and Digital Built Environments",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "PgDip",
                    "duration": "8 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Building Surveying",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Exercise Physiology",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Behavioural Psychotherapy",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Management",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary Fine Art",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Technology",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing (Innovation and Experiment)",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Justice: People and Processes",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cybersecurity, Threat Intelligence and Forensics",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dance: Choreography and Professional Practices",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dance: Creative Dance Education",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dance: Performance and Professional Practices",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diabetes Care",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Society",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Video Production and Marketing",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Documentary Production for TV, Film and Digital Media",
                    "university": "University of Salford",
                    "location": "MediaCity Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama Production for TV, Film and Digital Media",
                    "university": "University of Salford",
                    "location": "MediaCity Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drug Design and Discovery",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drug design and discovery",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Editing\u00a0for TV, Film and Digital Media",
                    "university": "University of Salford",
                    "location": "MediaCity Campus",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Enabling Social Change",
                    "university": "University of Salford",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Assessment and Management",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Design",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MA",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Production",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games and Extended Reality",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Global Environment",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intelligence and Security Studies",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Journalism for Digital Media",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism: News/Broadcast/Sport",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership and Management for Healthcare Practice",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Literature and Culture",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media Psychology",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery (pre-registration)",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "MSc (PG)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery Post RN (pre-registration)",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Block and Blend)",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Therapy (Pre-Registration)",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance Analysis in Sport",
                    "university": "University of Salford",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Podiatry (Pre-Registration)",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Production Management for TV, Film and Digital Media",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management in Construction",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "4 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology of Coercive Control",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Relations and Digital Communications",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantity Surveying",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantity Surveying (Delivered in Sri Lanka)",
                    "university": "University of Salford",
                    "location": "Sri Lanka Colombo School of Construction Technology (CSCT)",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics and Automation",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Safety, Health and Environment",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Screen Acting",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Socially Engaged Photography",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Rehabilitation and Athletic Training (pre-registration)",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Injury Rehabilitation",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strength and Conditioning",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Supervision in Counselling, Psychotherapy and Helping Relationships",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable buildings",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Terrorism and Security",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Communication",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Wildlife documentary production",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Acoustics",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "PgDip",
                    "duration": "20 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Counselling and Psychotherapy Studies",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Counselling and Psychotherapy Studies (Supervision)",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Medical Imaging",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Physiotherapy",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Psychology (Therapies)",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Social Research Methods",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Social Work Practice",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Audio Production",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "BIM and Digital Built Environments",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "PgDip",
                    "duration": "20 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Behaviour Therapy",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Behavioural Psychotherapy",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Management",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing (Innovation and Experiment)",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Justice: People and Processes",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cybersecurity, Threat Intelligence and Forensics",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "PgDip",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dance: Choreography and Professional Practices",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dance: Creative Dance Education",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dental Implantology",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diabetes Care",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drug design and discovery",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Enabling Social Change",
                    "university": "University of Salford",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Assessment and Management",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games and Extended Reality",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intelligence and Security Studies",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Journalism for Digital Media",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership and Management for Healthcare Practice",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Literature and Culture",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mammography Principles and Practice",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media Psychology",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MA",
                    "duration": "28 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Non-Medical Prescribing and Enhanced Clinical Skills",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Non-Surgical Facial Aesthetics",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nuclear Medicine Imaging",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "19 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance Analysis in Sport",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Production Management for TV, Film and Digital Media",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management in Construction",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology of Coercive Control",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantity Surveying",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics and Automation",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "PgDip",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Safety, Health and Environment",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Injury Rehabilitation",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strength and Conditioning",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability",
                    "university": "University of Salford",
                    "location": "No Locations",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable buildings",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "2.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Terrorism and Security",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ultrasound Imaging",
                    "university": "University of Salford",
                    "location": "Peel Park Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "8d2d87070bdc7937e3f8c581724c21ff",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Applied Sports Coaching and Physical Education",
                    "university": "SGS (South Gloucestershire and Stroud College)",
                    "location": "University Centre WISE (UCWISE)",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Applied Sports Conditioning and Exercise",
                    "university": "SGS (South Gloucestershire and Stroud College)",
                    "location": "University Centre WISE (UCWISE)",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Applied Sports Conditioning and Exercise Level 6 Top-up",
                    "university": "SGS (South Gloucestershire and Stroud College)",
                    "location": "University Centre WISE (UCWISE)",
                    "qualification": "BA/BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art History (Bristol School of Art)",
                    "university": "SGS (South Gloucestershire and Stroud College)",
                    "location": "Bristol School of Art",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business",
                    "university": "SGS (South Gloucestershire and Stroud College)",
                    "location": "University Centre WISE (UCWISE)",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business",
                    "university": "SGS (South Gloucestershire and Stroud College)",
                    "location": "University Centre WISE (UCWISE)",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Commercial Dance for Stage and Screen (BIPA)",
                    "university": "SGS (South Gloucestershire and Stroud College)",
                    "location": "Bristol Institute of Performing Arts (BIPA)",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computer Games Design and Development",
                    "university": "SGS (South Gloucestershire and Stroud College)",
                    "location": "University Centre WISE (UCWISE)",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "SGS (South Gloucestershire and Stroud College)",
                    "location": "University Centre WISE (UCWISE)",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Education and Learning",
                    "university": "SGS (South Gloucestershire and Stroud College)",
                    "location": "University Centre WISE (UCWISE)",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Education and Learning - Level 6 Top-up",
                    "university": "SGS (South Gloucestershire and Stroud College)",
                    "location": "University Centre WISE (UCWISE)",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art (Bristol School of Art)",
                    "university": "SGS (South Gloucestershire and Stroud College)",
                    "location": "Bristol School of Art",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Fine Art and Contemporary Arts Practice (Top-up) (Bristol School of Art)",
                    "university": "SGS (South Gloucestershire and Stroud College)",
                    "location": "Bristol School of Art",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Game Art",
                    "university": "SGS (South Gloucestershire and Stroud College)",
                    "location": "University Centre WISE (UCWISE)",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Game Art and Production Top-up",
                    "university": "SGS (South Gloucestershire and Stroud College)",
                    "location": "University Centre WISE (UCWISE)",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership and Management",
                    "university": "SGS (South Gloucestershire and Stroud College)",
                    "location": "University Centre WISE (UCWISE)",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Media Production",
                    "university": "SGS (South Gloucestershire and Stroud College)",
                    "location": "University Centre WISE (UCWISE)",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Media Production - Level 6 Top-up",
                    "university": "SGS (South Gloucestershire and Stroud College)",
                    "location": "University Centre WISE (UCWISE)",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Musical Theatre (BIPA)",
                    "university": "SGS (South Gloucestershire and Stroud College)",
                    "location": "Bristol Institute of Performing Arts (BIPA)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Prosthetics, Modelmaking and Digital Design",
                    "university": "SGS (South Gloucestershire and Stroud College)",
                    "location": "University Centre WISE (UCWISE)",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Prosthetics, Modelmaking and Digital Design Level 6 Top up",
                    "university": "SGS (South Gloucestershire and Stroud College)",
                    "location": "University Centre WISE (UCWISE)",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Make-up Design",
                    "university": "SGS (South Gloucestershire and Stroud College)",
                    "location": "University Centre WISE (UCWISE)",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Specialist Make-up Design Top-up",
                    "university": "SGS (South Gloucestershire and Stroud College)",
                    "location": "University Centre WISE (UCWISE)",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Media",
                    "university": "SGS (South Gloucestershire and Stroud College)",
                    "location": "University Centre WISE (UCWISE)",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sports Therapy Rehabilitation",
                    "university": "SGS (South Gloucestershire and Stroud College)",
                    "location": "University Centre WISE (UCWISE)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Zoological Management and Conservation",
                    "university": "SGS (South Gloucestershire and Stroud College)",
                    "location": "Bristol Zoo Gardens",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-96"
                },
                {
                    "course_name": "Zoological Management and Conservation (Top-up)",
                    "university": "SGS (South Gloucestershire and Stroud College)",
                    "location": "Bristol Zoo Gardens",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "30d6f47e9e032452fd496e65a1a15661",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Animal Management",
                    "university": "Sheffield College",
                    "location": "Hillsborough Campus",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Animal Management",
                    "university": "Sheffield College",
                    "location": "Hillsborough Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Bakery & Patisserie Technology (Yr1)",
                    "university": "Sheffield College",
                    "location": "Generic",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Creative Arts",
                    "university": "Sheffield College",
                    "location": "Hillsborough Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Practice",
                    "university": "Sheffield College",
                    "location": "Hillsborough Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Creative Practice",
                    "university": "Sheffield College",
                    "location": "Hillsborough Campus",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Practice: Graphic Design (Top-up)",
                    "university": "Sheffield College",
                    "location": "Hillsborough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Creative Practice: Media Production (Top-up)",
                    "university": "Sheffield College",
                    "location": "Hillsborough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Creative Practice: Photography (Top-up)",
                    "university": "Sheffield College",
                    "location": "Hillsborough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Dental Technology",
                    "university": "Sheffield College",
                    "location": "City Campus",
                    "qualification": "FdSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "FdSc Assistant Practitioner Health & Social Care",
                    "university": "Sheffield College",
                    "location": "Generic",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Games Design (Top-up)",
                    "university": "Sheffield College",
                    "location": "Hillsborough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Development Yr1",
                    "university": "Sheffield College",
                    "location": "Hillsborough Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "HN Flex Digital Technologies",
                    "university": "Sheffield College",
                    "location": "City Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "HNC Music Portfolio Careers & Music Enterprise & Entrepreneurship",
                    "university": "Sheffield College",
                    "location": "Hillsborough Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Music Performance & Production (Top-up)",
                    "university": "Sheffield College",
                    "location": "Hillsborough Campus",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Music Performance and Production (Top-up)",
                    "university": "Sheffield College",
                    "location": "Hillsborough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Performance in Practice (Top-Up)",
                    "university": "Sheffield College",
                    "location": "Hillsborough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Performance in Practice Yr 1",
                    "university": "Sheffield College",
                    "location": "Generic",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Physical Education and School Sport Foundation Degree",
                    "university": "Sheffield College",
                    "location": "Hillsborough Campus",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Public Services: Policing Studies",
                    "university": "Sheffield College",
                    "location": "Generic",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sport and Exercise Coaching",
                    "university": "Sheffield College",
                    "location": "Generic",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "37eb0236f939fedde74bef28d6a1ed57",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "38ad4111f7f3e4e50345b1671599b173",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Financial Management",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Financial Management and Economics",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering (Private Pilot Instruction)",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering (Private Pilot Instruction)",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering with a Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Social Science with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Social Sciences",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Engineering",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Engineering with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture and Landscape",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MBiolSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MBiolSci",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MBiolSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences with Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MBiolSci",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences with Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering with a Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MBiomedSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MBiomedSci",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and Economics",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and Japanese Studies",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and Modern Languages & Cultures",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with a Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Biological and Medicinal Chemistry",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Biological and Medicinal Chemistry",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and Business Management",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies and History",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies with Japanese",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil and Structural Engineering",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil and Structural Engineering with a Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil and Structural Engineering with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MComp (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Artificial Intelligence)",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Artificial Intelligence)",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MCompu (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Artificial Intelligence) with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Artificial Intelligence) with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MComp (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Software Engineering)",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Software Engineering)",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Software Engineering) with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Software Engineering) with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with a Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MCompu (H)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MComp (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Systems Engineering",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Systems Engineering",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Systems Engineering with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Systems Engineering with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dental Surgery",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BDS",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media and Society",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "East Asian Studies",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "East Asian Studies with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Mathematics",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Modern Languages & Cultures",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Politics",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Finance",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education, Culture and Childhood",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education, Culture and Childhood with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering with a Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronics and Computer Engineering",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronics and Computer Engineering",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and History",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and History with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Modern Languages & Cultures",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Music",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Philosophy",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Philosophy with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Linguistics",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Linguistics with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Literature",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Literature with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEnv",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science with a Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Mathematics",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "General Engineering",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "General Engineering",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "General Engineering with a Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "General Engineering with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "General Engineering with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MGeogSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography and Planning",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography and Planning with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Sustainable Development",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Sustainable Development with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graduate Entry Medicine",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MB ChB",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Modern Languages & Cultures",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Modern Languages with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Music",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Philosophy",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Philosophy with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Politics",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Politics with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Sociology",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management with Study Abroad",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Politics",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Politics with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Japanese Studies",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Japanese Studies and History",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Japanese Studies with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism Studies",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism Studies with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Korean Studies",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Korean Studies with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Korean Studies with Japanese",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Landscape Architecture",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Landscape Architecture",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MLA",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Landscape Architecture",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Landscape Architecture with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Landscape Architecture with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MLA",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (European and International)",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (with Chinese Law)",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (with Spanish Law)",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Criminology",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics and Japanese Studies",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics and Modern Languages & Cultures",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics and Philosophy",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science and Engineering",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science and Engineering",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science and Engineering with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials Science Engineering with a Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MMath",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Philosophy",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Statistics",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MMath (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Statistics",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Statistics with Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MMath",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Statistics with Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with French",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MMath",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with German",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MMath",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MMath",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Spanish",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MMath",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with a Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronic and Robotic Engineering",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronic and Robotic Engineering",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronic and Robotic Engineering with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronic and Robotic Engineering with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MB ChB",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and Cultures",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and Cultures with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BMus (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Korean Studies",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Modern Languages & Cultures",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Philosophy",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BMus (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BMedSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Adult) with a Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BMedSc (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Orthoptics",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BMedSci H",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Orthoptics with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BMedSci H",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MPharm",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Modern Languages & Cultures",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy, Religion and Ethics",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy, Religion and Ethics with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physician Associate Studies (Postgraduate Entry)",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MMedSci",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics and Astrophysics",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics and Astrophysics",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics and Astrophysics with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics and Astrophysics with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with a Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MPhys (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Particle Physics",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Particle Physics",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Philosophy",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Philosophy",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Modern Languages & Cultures",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Philosophy",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Philosophy with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Sociology",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, Philosophy and Economics",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, Philosophy and Economics",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics with a Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics with a Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work (Postgraduate Entry)",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology with Social Policy",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering with a Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Speech and Language Therapy",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BMedSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Speech and Language Therapy with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BMedSci H",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Engineering and Architecture",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Engineering and Architecture with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics with an Industrial Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Studies",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Studies and Planning",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MPlan",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Studies and Planning with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MPlan",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Studies with Foundation Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MBiolSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology with Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "MBiolSci",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoology with Placement Year",
                    "university": "University of Sheffield",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "fc5f8e01c401b1d3f4a02f5660d68238",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Computing",
                    "university": "Shrewsbury Colleges Group",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Technologies",
                    "university": "Shrewsbury Colleges Group",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Healthcare Professions Support",
                    "university": "Shrewsbury Colleges Group",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership & Management",
                    "university": "Shrewsbury Colleges Group",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Outdoor Leadership",
                    "university": "Shrewsbury Colleges Group",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sports Science and Coaching",
                    "university": "Shrewsbury Colleges Group",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "b48608619b71eeedce5bb6db028a11d1",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "3D Design & Animation",
                    "university": "Coleg Sir Gar",
                    "location": "Carmarthen School of Art",
                    "qualification": "Cert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "3D Design & Animation",
                    "university": "Coleg Sir Gar",
                    "location": "Carmarthen School of Art",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Agriculture",
                    "university": "Coleg Sir Gar",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Amaethyddiaeth",
                    "university": "Coleg Sir Gar",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Agriculture Top-up",
                    "university": "Coleg Sir Gar",
                    "location": "Gelli Aur Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Behaviour and Welfare",
                    "university": "Coleg Sir Gar",
                    "location": "Pibwrlwyd Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Animal Behaviour and Welfare Top Up",
                    "university": "Coleg Sir Gar",
                    "location": "Pibwrlwyd Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Animal Science",
                    "university": "Coleg Sir Gar",
                    "location": "Pibwrlwyd Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Ceramics",
                    "university": "Coleg Sir Gar",
                    "location": "Carmarthen School of Art",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Ceramics",
                    "university": "Coleg Sir Gar",
                    "location": "Carmarthen School of Art",
                    "qualification": "Cert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Children, Young People and Society",
                    "university": "Coleg Sir Gar",
                    "location": "Ammanford Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Concept Art & Illustration",
                    "university": "Coleg Sir Gar",
                    "location": "Carmarthen School of Art",
                    "qualification": "Cert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Concept Art & Illustration",
                    "university": "Coleg Sir Gar",
                    "location": "Carmarthen School of Art",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Counselling",
                    "university": "Coleg Sir Gar",
                    "location": "Ammanford Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Counselling",
                    "university": "Coleg Sir Gar",
                    "location": "Ammanford Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Fashion Design",
                    "university": "Coleg Sir Gar",
                    "location": "Carmarthen School of Art",
                    "qualification": "Cert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Fashion Design",
                    "university": "Coleg Sir Gar",
                    "location": "Carmarthen School of Art",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Gradd Sylfaen mewn Amaethyddiaeth",
                    "university": "Coleg Sir Gar",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Fine Art Practice",
                    "university": "Coleg Sir Gar",
                    "location": "Carmarthen School of Art",
                    "qualification": "Cert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Fine Art Practice",
                    "university": "Coleg Sir Gar",
                    "location": "Carmarthen School of Art",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Football Coaching and Performance",
                    "university": "Coleg Sir Gar",
                    "location": "UWTSD Carmarthen",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Football Coaching and Performance (Top-Up)",
                    "university": "Coleg Sir Gar",
                    "location": "UWTSD Carmarthen",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Jewellery Design",
                    "university": "Coleg Sir Gar",
                    "location": "Carmarthen School of Art",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Jewellery Design",
                    "university": "Coleg Sir Gar",
                    "location": "Carmarthen School of Art",
                    "qualification": "Cert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Multidisciplinary in Art & Design",
                    "university": "Coleg Sir Gar",
                    "location": "Carmarthen School of Art",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Multidisciplinary in Art & Design",
                    "university": "Coleg Sir Gar",
                    "location": "Carmarthen School of Art",
                    "qualification": "Cert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Photography",
                    "university": "Coleg Sir Gar",
                    "location": "Carmarthen School of Art",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Photography",
                    "university": "Coleg Sir Gar",
                    "location": "Carmarthen School of Art",
                    "qualification": "Cert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Rugby Coaching and Performance",
                    "university": "Coleg Sir Gar",
                    "location": "UWTSD Carmarthen",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Rugby Coaching and Performance (Top-Up)",
                    "university": "Coleg Sir Gar",
                    "location": "UWTSD Carmarthen",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Social Care Studies",
                    "university": "Coleg Sir Gar",
                    "location": "Ammanford Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Social Care Studies",
                    "university": "Coleg Sir Gar",
                    "location": "Ammanford Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Sports Coaching and Performance",
                    "university": "Coleg Sir Gar",
                    "location": "UWTSD Carmarthen",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sports Coaching and Performance (Top-Up)",
                    "university": "Coleg Sir Gar",
                    "location": "UWTSD Carmarthen",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Textiles",
                    "university": "Coleg Sir Gar",
                    "location": "Carmarthen School of Art",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Textiles",
                    "university": "Coleg Sir Gar",
                    "location": "Carmarthen School of Art",
                    "qualification": "Cert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Animal Science",
                    "university": "Coleg Sir Gar",
                    "location": "Pibwrlwyd Campus",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "40"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "99ddd3d6b863965563eb8715ea3bf008",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "1a5d68bc0e4a70e700dfde76abb0bf03",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Africa and Black Diaspora and Economics including a year abroad",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arabic",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arabic and Economics",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arabic and Global Development",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arabic and History",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arabic and History of Art",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arabic and Law",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arabic and Linguistics",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arabic and Music",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arabic and Politics",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arabic and Social Anthropology",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arabic and World Philosophies",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business, Management, Economics & Law with Foundation Year",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese and Economics",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese and Global Development",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese and History",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese and History of Art",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese and Law",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese and Linguistics",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese and Music",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese and Politics",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese and Social Anthropology",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese and World Philosophies",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Arts and Cultural Industries",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Development Economics",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "East Asian Studies",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "East Asian Studies and Economics",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "East Asian Studies and Global Development",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "East Asian Studies and History",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "East Asian Studies and History of Art",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "East Asian Studies and International Relations",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "East Asian Studies and Law",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "East Asian Studies and Linguistics",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "East Asian Studies and Music",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "East Asian Studies and Politics",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "East Asian Studies and Social Anthropology",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "East Asian Studies and World Philosophies",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics (BA)",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics (BSc)",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Africa and Black Diaspora",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and History",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Law",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Politics",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Social Anthropology",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development and Africa and Black Diaspora",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development and Africa and Black Diaspora including year abroad",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development and Economics",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development and History",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development and Languages and Cultures (Middle East, Africa, South and Southeast Asia)",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development and Languages and Cultures (Middle East, Africa, South and Southeast Asia) including year abroad",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development and Law",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development and Linguistics",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development and Music",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development and Politics",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development and Social Anthropology",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development and World Philosophies",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Liberal Arts",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Africa and Black Diaspora",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Africa and Black Diaspora including year abroad",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Languages and Cultures (Middle East, Africa, South and Southeast Asia) including Year Abroad",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Law",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Music",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Politics",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Social Anthropology",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and World Philosophies",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art and Africa and Black Diaspora",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art and Africa and Black Diaspora including year abroad",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art and Global Development",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art and History",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art and International Relations",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art and Languages and Cultures (Middle East, Africa, South and Southeast Asia)",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art and Languages and Cultures (Middle East, Africa, South and Southeast Asia) including year abroad",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art and Music",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art and Politics",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art and Social Anthropology",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art and World Philosophies",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Africa and Black Diaspora",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Africa and Black Diaspora including Year Abroad",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Arabic",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Chinese",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Economics",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Global Development",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and History",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Japanese",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Korean",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Law",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Music",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Social Anthropology",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and World Philosophies",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Japanese",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Japanese and Economics",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Japanese and Global Development",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Japanese and History",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Japanese and History of Art",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Japanese and Linguistics",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Japanese and Music",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Japanese and Politics",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Japanese and Social Anthropology",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Japanese and World Philosophies",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Korean",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Korean and Economics",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Korean and Global Development",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Korean and History",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Korean and History of Art",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Korean and Law",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Korean and Linguistics",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Korean and Music",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Korean and Politics",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Korean and Social Anthropology",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Korean and World Philosophies",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Languages and Cultures (Middle East, Africa, South and Southeast Asia)",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Languages and Cultures (Middle East, Africa, South and Southeast Asia) and Africa and Black Diaspora",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Languages and Cultures (Middle East, Africa, South and Southeast Asia) and Africa and Black Diaspora including Year Abroad",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Languages and Cultures (Middle East, Africa, South and Southeast Asia) and Arabic including Year Abroad",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Languages and Cultures (Middle East, Africa, South and Southeast Asia) and Economics",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Languages and Cultures (Middle East, Africa, South and Southeast Asia) and International Relations",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Languages and Cultures (Middle East, Africa, South and Southeast Asia) and Law",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Languages and Cultures (Middle East, Africa, South and Southeast Asia) and Law including year abroad",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Languages and Cultures (Middle East, Africa, South and Southeast Asia) and World Philosophies",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Languages and Cultures (Middle East, Africa, South and Southeast Asia) and World Philosophies including year abroad",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Languages and Cultures (Middle East, Africa, South and Southeast Asia) and\u00a0International Relations including year abroad",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Languages and Cultures (Middle East, Africa, South and Southeast Asia) including year abroad",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Africa and Black Diaspora",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Africa and Black Diaspora including year abroad",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Politics",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Social Anthropology",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics and Languages and Cultures (Middle East, Africa, South and Southeast Asia)",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics and Languages and Cultures (Middle East, Africa, South and Southeast Asia) including year abroad",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics and Politics",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics and Social Anthropology",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "LLB Law",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "LLB Law with year abroad",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Africa and Black Diaspora",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Africa and Black Diaspora including year abroad",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Languages and Cultures (Middle East, Africa, South and Southeast Asia)",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Languages and Cultures (Middle East, Africa, South and Southeast Asia) including year abroad",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Social Anthropology",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and World Philosophies",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Africa and Black Diaspora",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Africa and Black Diaspora including Year Abroad",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Languages and Cultures (Middle East, Africa, South and Southeast Asia)",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Languages and Cultures (Middle East, Africa, South and Southeast Asia) including year abroad",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Music",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Social Anthropology",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and World Philosophies",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, Philosophy and Economics",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology and Africa and Black Diaspora",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology and Africa and Black Diaspora including a year abroad",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology and Languages and Cultures (Middle East, Africa, South and Southeast Asia)",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology and World Philosophies",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Sciences, Arts & Humanities with Foundation Year",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "World Philosophies",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "World Philosophies and Africa and Black Diaspora",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "World Philosophies and Africa and Black Diaspora including year abroad",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "African Studies and Intensive Swahili",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology of Food",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology of Food and Intensive Language",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology of Global Futures and Sustainability",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Buddhist Studies",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature (Africa and Asia)",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cultural Studies",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Curating Cultures",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Development Economics",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic Policy",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment, Politics and Development",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Law and Sustainable Development",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender Studies",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender Studies and Law",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Creative and Cultural Industries",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Energy and Climate Policy",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Media and Communications",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Intensive Language",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art and Archaeology of East Asia",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art and Archaeology of East Asia and Intensive Language",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art and Architecture of the Islamic Middle East",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art and/or Archaeology",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Rights Law",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial and Economic Law",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Politics",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Studies and Diplomacy",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Iranian Studies",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Iranian Studies and Intensive Persian",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Islamic Law",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Islamic Studies",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Japanese Studies",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Japanese Studies (Dual Degree Programme)",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Japanese Studies and Intensive Language",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Korean Studies",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Korean Studies and Intensive Language",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Gender",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal Studies (General Programme)",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics and Intensive Language",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "LLM (Master of Laws)",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media in Development",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Middle Eastern Studies",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Migration and Diaspora Studies",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Migration and Diaspora Studies and Intensive Language",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Migration Mobility and Development",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music (Development Pathway)",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Postcolonial Studies",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Policy and Management",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religion in Global Politics",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Research for International Development",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology",
                    "university": "SOAS University of London",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology and Intensive Language",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "South Asian Area Studies",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "South Asian Studies and Intensive Language",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "South East and Pacific Asian Studies",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Taiwan Studies",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Traditions of Yoga and Meditation",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Transnational Queer Feminist Politics",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Violence Conflict and Development",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "African Studies and Intensive Swahili",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology of Food",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology of Food and Intensive Language",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology of Global Futures and Sustainability",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Buddhist Studies",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chinese Studies",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature (Africa and Asia)",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cultural Studies",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Curating Cultures",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Development Economics",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic Policy",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment, Politics and Development",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Law and Sustainable Development",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender Studies",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender Studies and Law",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Creative and Cultural Industries",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Energy and Climate Policy",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Media and Communications",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Intensive Language",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art and Archaeology of East Asia",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art and Archaeology of East Asia and Intensive Language",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art and Architecture of the Islamic Middle East",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art and/or Archaeology",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Rights Law",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial and Economic Law",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Politics",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Studies and Diplomacy",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Iranian Studies",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Iranian Studies and Intensive Persian",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Islamic Law",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Islamic Studies",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Japanese Studies",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Japanese Studies (Dual Degree Programme)",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Japanese Studies and Intensive Language",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Korean Studies",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Korean Studies and Intensive Language",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Gender",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal Studies (General Programme)",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "LLM (Master of Laws)",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media in Development",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Middle Eastern Studies",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Migration and Diaspora Studies",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Migration and Diaspora Studies and Intensive Language",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Migration Mobility and Development",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music (Development Pathway)",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Postcolonial Studies",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Policy and Management",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religion in Global Politics",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Research for International Development",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology",
                    "university": "SOAS University of London",
                    "location": "Multiple Locations",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Anthropology and Intensive Language",
                    "university": "SOAS University of London",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "South Asian Area Studies",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "South Asian Studies and Intensive Language",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "South East and Pacific Asian Studies",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Taiwan Studies",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Traditions of Yoga and Meditation",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Transnational Queer Feminist Politics",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Violence Conflict and Development",
                    "university": "SOAS University of London",
                    "location": "SOAS University of London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "9145b37536c0ba76ca877860afaa093f",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "f4b27b5c449a5863217595f4a47fcb88",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "f729c7e46cada435c70cbc812420dd40",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "405a5f4257c027f3ded02fabccdb3bf7",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Art and Design (Fashion and Textiles)",
                    "university": "South Thames College Group (inc Carshalton, South Thames and Kingston College)",
                    "location": "Kingston College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Art and Design (Graphic Design) (Tattoo)",
                    "university": "South Thames College Group (inc Carshalton, South Thames and Kingston College)",
                    "location": "Kingston College",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Art and Design (Graphic Design) (Tattoo)",
                    "university": "South Thames College Group (inc Carshalton, South Thames and Kingston College)",
                    "location": "Kingston College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Business (Top-up)",
                    "university": "South Thames College Group (inc Carshalton, South Thames and Kingston College)",
                    "location": "Kingston College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "South Thames College Group (inc Carshalton, South Thames and Kingston College)",
                    "location": "South Thames College (Wandsworth)",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "South Thames College Group (inc Carshalton, South Thames and Kingston College)",
                    "location": "South Thames College (Wandsworth)",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Construction Management for England (HTQ)",
                    "university": "South Thames College Group (inc Carshalton, South Thames and Kingston College)",
                    "location": "South Thames College (Wandsworth)",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Education and Learning (Top-up)",
                    "university": "South Thames College Group (inc Carshalton, South Thames and Kingston College)",
                    "location": "Carshalton College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Engineering for England (Electrical and Electronic Engineering) (HTQ)",
                    "university": "South Thames College Group (inc Carshalton, South Thames and Kingston College)",
                    "location": "Kingston College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Engineering for England (General Engineering) (HTQ)",
                    "university": "South Thames College Group (inc Carshalton, South Thames and Kingston College)",
                    "location": "Kingston College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Engineering for England (Mechanical Engineering) (HTQ)",
                    "university": "South Thames College Group (inc Carshalton, South Thames and Kingston College)",
                    "location": "Kingston College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Fine Art",
                    "university": "South Thames College Group (inc Carshalton, South Thames and Kingston College)",
                    "location": "Kingston College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Integrative-Relational Counselling",
                    "university": "South Thames College Group (inc Carshalton, South Thames and Kingston College)",
                    "location": "Kingston College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performing Arts (Acting)",
                    "university": "South Thames College Group (inc Carshalton, South Thames and Kingston College)",
                    "location": "Kingston College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Performing Arts (Technical Arts - Tech Theatre or Make-up)",
                    "university": "South Thames College Group (inc Carshalton, South Thames and Kingston College)",
                    "location": "Kingston College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Social & Community Work (Children, Young People & Families)",
                    "university": "South Thames College Group (inc Carshalton, South Thames and Kingston College)",
                    "location": "Kingston College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Engineering (Electrical and Electronic Engineering)",
                    "university": "South Thames College Group (inc Carshalton, South Thames and Kingston College)",
                    "location": "Kingston College",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Engineering (General Engineering)",
                    "university": "South Thames College Group (inc Carshalton, South Thames and Kingston College)",
                    "location": "Kingston College",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Engineering (Mechanical Engineering)",
                    "university": "South Thames College Group (inc Carshalton, South Thames and Kingston College)",
                    "location": "Kingston College",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Engineering for England (Electrical and Electronic Engineering) (HTQ)",
                    "university": "South Thames College Group (inc Carshalton, South Thames and Kingston College)",
                    "location": "Kingston College",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Engineering for England (General Engineering) (HTQ)",
                    "university": "South Thames College Group (inc Carshalton, South Thames and Kingston College)",
                    "location": "Kingston College",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Engineering for England (Mechanical Engineering) (HTQ)",
                    "university": "South Thames College Group (inc Carshalton, South Thames and Kingston College)",
                    "location": "Kingston College",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "c0dbfdf810df86ae4779da567fcbfab8",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "9bbae13633cc84974806a6df449f1a9d",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Agriculture",
                    "university": "University Centre Sparsholt",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "80-112"
                },
                {
                    "course_name": "Agriculture",
                    "university": "University Centre Sparsholt",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64-80"
                },
                {
                    "course_name": "Agriculture (Top-up)",
                    "university": "University Centre Sparsholt",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Management and Applied Science",
                    "university": "University Centre Sparsholt",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64-80"
                },
                {
                    "course_name": "Applied Animal Science",
                    "university": "University Centre Sparsholt",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-112"
                },
                {
                    "course_name": "Applied Animal Science (Top-up)",
                    "university": "University Centre Sparsholt",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Zoo Science",
                    "university": "University Centre Sparsholt",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64-80"
                },
                {
                    "course_name": "Aquaculture and Fishery Management",
                    "university": "University Centre Sparsholt",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-112"
                },
                {
                    "course_name": "Aquaculture and Fishery Management (Top-up)",
                    "university": "University Centre Sparsholt",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Equestrian Performance & Business Management",
                    "university": "University Centre Sparsholt",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "80-112"
                },
                {
                    "course_name": "Equestrian Performance & Business Management",
                    "university": "University Centre Sparsholt",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64-80"
                },
                {
                    "course_name": "Equestrian Performance & Business Management (Top-up)",
                    "university": "University Centre Sparsholt",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Equine Science & Welfare",
                    "university": "University Centre Sparsholt",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-112"
                },
                {
                    "course_name": "Equine Science & Welfare (Top-up)",
                    "university": "University Centre Sparsholt",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Ecology and Conservation",
                    "university": "University Centre Sparsholt",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64-80"
                },
                {
                    "course_name": "Sports Fisheries and Aquaculture",
                    "university": "University Centre Sparsholt",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64-80"
                },
                {
                    "course_name": "Veterinary Nursing Science",
                    "university": "University Centre Sparsholt",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-112"
                },
                {
                    "course_name": "Wildlife Ecology and Conservation",
                    "university": "University Centre Sparsholt",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64-80"
                },
                {
                    "course_name": "Wildlife Ecology and Conservation",
                    "university": "University Centre Sparsholt",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-112"
                },
                {
                    "course_name": "Wildlife Ecology and Conservation (Top-up)",
                    "university": "University Centre Sparsholt",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Zoo Biology (Top-up)",
                    "university": "University Centre Sparsholt",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Applied Zoo Biology",
                    "university": "University Centre Sparsholt",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Equine Science and Performance Management",
                    "university": "University Centre Sparsholt",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Applied Zoo Biology",
                    "university": "University Centre Sparsholt",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Equine Science and Performance Management",
                    "university": "University Centre Sparsholt",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "6f119da7f573d86f2199a7427519a854",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "970def8ed0df06fdd0827ef48b21b3f4",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Agriculture",
                    "university": "SRUC Scotland's Rural College",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Agriculture",
                    "university": "SRUC Scotland's Rural College",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40-42"
                },
                {
                    "course_name": "Agriculture",
                    "university": "SRUC Scotland's Rural College",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "40-48"
                },
                {
                    "course_name": "Animal Care",
                    "university": "SRUC Scotland's Rural College",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "40-42"
                },
                {
                    "course_name": "Animal Care",
                    "university": "SRUC Scotland's Rural College",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40-42"
                },
                {
                    "course_name": "Animal Welfare Science",
                    "university": "SRUC Scotland's Rural College",
                    "location": "Edinburgh",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "114-120"
                },
                {
                    "course_name": "Applied Animal Science",
                    "university": "SRUC Scotland's Rural College",
                    "location": "Edinburgh",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "114-120"
                },
                {
                    "course_name": "Environmental  Management",
                    "university": "SRUC Scotland's Rural College",
                    "location": "Edinburgh",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40-42"
                },
                {
                    "course_name": "Environmental  Management",
                    "university": "SRUC Scotland's Rural College",
                    "location": "Edinburgh",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-104"
                },
                {
                    "course_name": "Environmental Management",
                    "university": "SRUC Scotland's Rural College",
                    "location": "Edinburgh",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "16-21"
                },
                {
                    "course_name": "Equine Science and Management",
                    "university": "SRUC Scotland's Rural College",
                    "location": "Oatridge",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-104"
                },
                {
                    "course_name": "Equine Studies",
                    "university": "SRUC Scotland's Rural College",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "40-42"
                },
                {
                    "course_name": "Equine Studies",
                    "university": "SRUC Scotland's Rural College",
                    "location": "Oatridge",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40-42"
                },
                {
                    "course_name": "Forestry",
                    "university": "SRUC Scotland's Rural College",
                    "location": "Barony",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40-42"
                },
                {
                    "course_name": "Forestry",
                    "university": "SRUC Scotland's Rural College",
                    "location": "Barony",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "40-42"
                },
                {
                    "course_name": "Horticulture",
                    "university": "SRUC Scotland's Rural College",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "40-42"
                },
                {
                    "course_name": "Horticulture (Landscape Design)",
                    "university": "SRUC Scotland's Rural College",
                    "location": "Edinburgh",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40-42"
                },
                {
                    "course_name": "Horticulture (Plant Science and Technology)",
                    "university": "SRUC Scotland's Rural College",
                    "location": "Edinburgh",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-104"
                },
                {
                    "course_name": "Horticulture (Plantsmanship)",
                    "university": "SRUC Scotland's Rural College",
                    "location": "Edinburgh",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-104"
                },
                {
                    "course_name": "Horticulture (Plantsmanship)",
                    "university": "SRUC Scotland's Rural College",
                    "location": "Edinburgh",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "42-48"
                },
                {
                    "course_name": "Horticulture (Production Science)",
                    "university": "SRUC Scotland's Rural College",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40-42"
                },
                {
                    "course_name": "Rural Animal Health",
                    "university": "SRUC Scotland's Rural College",
                    "location": "Aberdeen",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-75"
                },
                {
                    "course_name": "Rural Business Management",
                    "university": "SRUC Scotland's Rural College",
                    "location": "Barony",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "40-42"
                },
                {
                    "course_name": "Rural Business Management",
                    "university": "SRUC Scotland's Rural College",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40-42"
                },
                {
                    "course_name": "Rural Business Management",
                    "university": "SRUC Scotland's Rural College",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-104"
                },
                {
                    "course_name": "Rural Business Management (Top Up Degree)",
                    "university": "SRUC Scotland's Rural College",
                    "location": "Ayr",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Nursing",
                    "university": "SRUC Scotland's Rural College",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "108-120"
                },
                {
                    "course_name": "Veterinary Science",
                    "university": "SRUC Scotland's Rural College",
                    "location": "Aberdeen",
                    "qualification": "BVSci",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-147"
                },
                {
                    "course_name": "Wildlife and Conservation Management",
                    "university": "SRUC Scotland's Rural College",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "16-21"
                },
                {
                    "course_name": "Wildlife and Conservation Management",
                    "university": "SRUC Scotland's Rural College",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40-42"
                },
                {
                    "course_name": "Wildlife and Conservation Management",
                    "university": "SRUC Scotland's Rural College",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-104"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "d3d6fd6025ff6c5bb9ce31c97e7ddfac",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Applied Microbiology",
                    "university": "University Centre St Helens",
                    "location": "University Centre St Helens",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business (Management)",
                    "university": "University Centre St Helens",
                    "location": "University Centre St Helens",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Criminal Justice",
                    "university": "University Centre St Helens",
                    "location": "University Centre St Helens",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Criminal Justice (Top-Up)",
                    "university": "University Centre St Helens",
                    "location": "University Centre St Helens",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "240"
                },
                {
                    "course_name": "Early Years Practice",
                    "university": "University Centre St Helens",
                    "location": "University Centre St Helens",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Early Years Practice (Top-up)",
                    "university": "University Centre St Helens",
                    "location": "University Centre St Helens",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "240"
                },
                {
                    "course_name": "Exercise, Health and Fitness",
                    "university": "University Centre St Helens",
                    "location": "University Centre St Helens",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Fine Art Painting",
                    "university": "University Centre St Helens",
                    "location": "University Centre St Helens",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Game Art",
                    "university": "University Centre St Helens",
                    "location": "University Centre St Helens",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "University Centre St Helens",
                    "location": "University Centre St Helens",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Media Production",
                    "university": "University Centre St Helens",
                    "location": "University Centre St Helens",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Theatre and Performance",
                    "university": "University Centre St Helens",
                    "location": "University Centre St Helens",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Theatre and Performance (Top-Up)",
                    "university": "University Centre St Helens",
                    "location": "University Centre St Helens",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "240"
                },
                {
                    "course_name": "Tourism and Hospitality Management",
                    "university": "University Centre St Helens",
                    "location": "University Centre St Helens",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "dbe0584d2e95e613be713980c3a064bb",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Acting",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Business Management",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-104"
                },
                {
                    "course_name": "Business Management (Top Up)",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (with Placement Year)",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96-104"
                },
                {
                    "course_name": "Business Management (with Placement Year) with Foundation Year",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "96-104"
                },
                {
                    "course_name": "Business Management and Entrepreneurship",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-104"
                },
                {
                    "course_name": "Business Management and Entrepreneurship (with Placement Year)",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96-104"
                },
                {
                    "course_name": "Business Management and Entrepreneurship (with Placement Year) with Foundation Year",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "96-104"
                },
                {
                    "course_name": "Business Management and Entrepreneurship with Foundation Year",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business Management and Finance",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-104"
                },
                {
                    "course_name": "Business Management and Finance (with Placement Year)",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96-104"
                },
                {
                    "course_name": "Business Management and Finance (with Placement Year) with Foundation Year",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "96-104"
                },
                {
                    "course_name": "Business Management and Finance with Foundation Year",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business Management and Marketing",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-104"
                },
                {
                    "course_name": "Business Management and Marketing (with Placement Year)",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96-104"
                },
                {
                    "course_name": "Business Management and Marketing (with Placement Year) with Foundation Year",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business Management and Marketing with Foundation Year",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business Management with Foundation Year",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business Psychology",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Business Psychology (with Placement Year)",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Chelsea FC Foundation Coaching and Development - Top Up Year",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chelsea Football Club Foundation Coaching and Development",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computer Science",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Computer Science (Artificial Intelligence)",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Computer Science (Artificial Intelligence) with Industrial Placement",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Computer Science (Cyber Security)",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Computer Science (Cyber Security) with Industrial Placement",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Computer Science (Data Science)",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Computer Science (Data Science) with Industrial Placement",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Computer Science (Game Development)",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Computer Science (Game Development) with Industrial Placement",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Computer Science (Software Engineering)",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Computer Science (Software Engineering) with Industrial Placement",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Computer Science (User Experience)",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Computer Science (User Experience) with Industrial Placement",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Computer Science with Industrial Placement",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Creative and Professional Writing",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Creative Production",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Criminology and Sociology (with Placement Year)",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Criminology and Sociology with Foundation Year",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Early Years Education",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-104"
                },
                {
                    "course_name": "Education Studies with Foundation Year",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "English Literature",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "English Literature (with Placement Year)",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Film Studies",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Filmmaking",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Filmmaking (with Placement Year)",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "History",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "History (with Placement Year)",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Law (with Placement Year)",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Law LLB",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Law LLB with Business",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Law LLB with Business with Foundation Year",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Law LLB with Criminology",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "LLB",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Law LLB with Criminology with Foundation Year",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "LLB",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Law LLB with Foundation Year",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "LLB",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Law with Business (with Placement Year)",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Law with Criminology (with Placement Year)",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "LLB",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Liberal Arts",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Liberal Arts (Accelerated 2 year degree)",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Liberal Arts (with Placement Year)",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Marketing and Communication",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Marketing and Communication  (With Placement Year) with Foundation Year",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Marketing and Communication (with Placement Year)",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Marketing and Communication with Foundation Year",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Marketing and Sports Communication",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Marketing and Sports Communication (with Placement Year)",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Marketing and Sports Communication (with Placement Year) with Foundation Year",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "45"
                },
                {
                    "course_name": "Marketing and Sports Communication with Foundation Year",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Nutrition",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-104"
                },
                {
                    "course_name": "Nutrition (with Placement Year)",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Nutrition with Foundation Year",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Physical Education and Sports Coaching",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Physical Education, Sport and Youth Development",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Physical Education, Sport and Youth Development with Foundation Year",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Physical\u00a0Education, Sport\u00a0 and Youth Development (with Placement Year)",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-128"
                },
                {
                    "course_name": "Primary Education (work based one year top-up)",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Education (work-based two year foundation degree)",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Education with QTS",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Primary Education with QTS (Wrexham University)",
                    "university": "St Mary's University, Twickenham",
                    "location": "Wrexham University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Primary Education with QTS with Foundation Year",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Psychology",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Psychology with Foundation Year",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Psychology with Placement Year",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Sport and Exercise Nutrition",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Sport and Exercise Nutrition (with Placement Year)",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Sport and Exercise Nutrition with Foundation Year",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Sport and Exercise Science (with Placement Year)",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Sport and Exercise Science with Foundation Year",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sport Psychology",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Sport Psychology with Foundation Year",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Sport Rehabilitation",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Sport Rehabilitation with Foundation Year",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sports Coaching Science",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Sports Coaching Science (with Placement Year)",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Sports Coaching Science with Foundation Year",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sports Management",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-104"
                },
                {
                    "course_name": "Sports Management (with Placement Year)",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96-104"
                },
                {
                    "course_name": "Sports Management (with Placement Year) with Foundation Year",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "96-104"
                },
                {
                    "course_name": "Sports Management with Foundation Year",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sports Performance Analysis and Talent Identification",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Strength and Conditioning Science",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Strength and Conditioning Science with Foundation Year",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Theological Studies",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-112"
                },
                {
                    "course_name": "Theological Studies with CertHE Philosophy",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Theology, Religion and Ethics",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Primary Education with QTS (work-based two year top-up)",
                    "university": "St Mary's University, Twickenham",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "6f524bdee357a43f142a4080d7b72f7c",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accountancy",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BAcc (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accountancy and Business Law",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BAcc (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accountancy and Business Studies",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BAcc (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accountancy and Economics",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BAcc (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accountancy and Finance",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BAcc (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accountancy and Marketing",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BAcc (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accountancy and Mathematics",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BAcc (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accountancy and Spanish",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BAcc (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accountancy and Sport",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BAcc (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Biology",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Mathematics",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Design",
                    "university": "University of Stirling",
                    "location": "Stirling",
                    "qualification": "BA (Hons)",
                    "duration": "1 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "BA with Honours Professional Education (Secondary, Chemistry)",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "BA with Honours Professional Education (Secondary, Physics)",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology and Mathematics",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology and Professional Education",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology and Psychology",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Computing",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Law",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Studies",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Studies and Economics",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Studies and English Studies",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Studies and Film & Media",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Studies and Finance",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Studies and French",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Studies and Human Resource Management",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Studies and Law",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Studies and Management",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Studies and Marketing",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Studies and Politics",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Studies and Psychology",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Studies and Spanish",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Studies and Sport",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cell Biology",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science and French",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science and Mathematics",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science and Philosophy",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science and Spanish",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Law",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Philosophy",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Politics",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Social Policy",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Artificial Intelligence",
                    "university": "University of Stirling",
                    "location": "Stirling",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecology and Conservation",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Environmental Science",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Marketing",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Mathematics",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Politics",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Studies",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Studies and Film & Media",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Studies and French",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Studies and History",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Studies and Journalism Studies",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Studies and Philosophy",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Studies and Professional Education",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Studies and Psychology",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Studies and Religion",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Studies and Spanish",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Studies, Religion and Professional Education",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Geography",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Geography and Outdoor Education",
                    "university": "University of Stirling",
                    "location": "Stirling",
                    "qualification": "BA/BSc (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Geography and Professional Education",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science and Mathematics",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science and Outdoor Education",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Science and Politics",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "European Languages and Society with International Management",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Media",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Media and French",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Media and History",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Media and Journalism Studies",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Media and Marketing",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Media and Philosophy",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Media and Politics",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Media and Psychology",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Media and Sociology",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film & Media and Spanish",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Mathematics",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and History",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Human Resource Management",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Journalism Studies",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Law",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Mathematics",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Professional Education",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Religion",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Spanish",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French, Spanish and Professional Education",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Heritage",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Journalism Studies",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Law",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Philosophy",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Politics",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Professional Education",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Religion",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Sociology",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Spanish",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History, Politics and Professional Education",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History, Sociology and Professional Education",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management and Law",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management and Marketing",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management and Psychology",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management and Spanish",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Politics",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Politics and Languages",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism Studies",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism Studies and Politics",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism Studies and Spanish",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism Studies and Sport",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (LLB)",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law Accelerated Entry (graduates only)",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "LLB",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Politics",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Spanish",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine and Freshwater Biology",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing and Psychology",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing and Spanish",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Professional Education",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Psychology",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and Business Studies",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Adult - Honours)",
                    "university": "University of Stirling",
                    "location": "Stirling",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "University of Stirling",
                    "location": "Stirling",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Mental Health - Honours)",
                    "university": "University of Stirling",
                    "location": "Stirling",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "University of Stirling",
                    "location": "Stirling",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paramedic Science",
                    "university": "University of Stirling",
                    "location": "Stirling",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Politics",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Psychology",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Religion",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy, Religion and Professional Education",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Psychology",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Religion",
                    "university": "University of Stirling",
                    "location": "Stirling",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Politics and Social Policy",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Sociology",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, Philosophy and Economics",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Accountancy",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Education (Primary) with Specialism in Early Years",
                    "university": "University of Stirling",
                    "location": "Stirling",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Education (Primary) with specialism in Inclusive Practice",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Education (Primary) with specialism in Literacy",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Education (Primary) with Specialism in Modern Languages",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Education (Primary) with specialism in Numeracy",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Education (Primary) with Specialism in the Environment",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and European Language",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Sociology",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religion and Sociology",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Retail Marketing",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scottish History",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Social Policy",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Spanish",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Latin American Studies",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Marketing",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Business Management",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Psychology",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport, Physical Education and Professional Education",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Development and Coaching",
                    "university": "University of Stirling",
                    "location": "Stirling",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Advancing Practice",
                    "university": "University of Stirling",
                    "location": "Stirling",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Social Research",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aquatic Pathobiology",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aquatic Veterinary Studies",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Autism Research",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Behavioural Science",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Big Data",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "University of Stirling",
                    "location": "Stirling",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management",
                    "university": "University of Stirling",
                    "location": "Multiple Locations",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Stirling",
                    "location": "Stirling",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminological Research",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science for Business",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Banking and Finance",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing and Brand Management",
                    "university": "University of Stirling",
                    "location": "Stirling",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media and Communication",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Stirling",
                    "location": "Stirling",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Research",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Linguistics",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language Teaching and Management",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Law and Climate Justice",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Management",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Remote Sensing and Geospatial Sciences",
                    "university": "University of Stirling",
                    "location": "Stirling",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Data Analytics",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Risk Management",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Technology",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender Studies (Applied)",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gerontology and Global Ageing",
                    "university": "University of Stirling",
                    "location": "Online",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Politics",
                    "university": "University of Stirling",
                    "location": "Stirling",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Heritage",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Historical Research",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Animal Interaction",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Rights and Diplomacy",
                    "university": "University of Stirling",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Accounting and Finance",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Conflict and Cooperation",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Journalism",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Investment Analysis",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing Analytics",
                    "university": "University of Stirling",
                    "location": "Stirling",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Data Science",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media Management",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media Research",
                    "university": "University of Stirling",
                    "location": "Stirling",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MRes Health Research",
                    "university": "University of Stirling",
                    "location": "Stirling",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MRes Humanities",
                    "university": "University of Stirling",
                    "location": "Stirling",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological Research Methods",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (accredited conversion course)",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology of Sport",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MSc",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Policy",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MPP",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Relations and Strategic Communication",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Relations and Strategic Communication",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Publishing Studies",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MLitt",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Statistics and Social Research",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work Studies",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MSc",
                    "duration": "28 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Management",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Nutrition (IOC Diploma)",
                    "university": "University of Stirling",
                    "location": "Stirling",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Communication and Public Relations (Joint Degree UPF Barcelona)",
                    "university": "University of Stirling",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Substance Use",
                    "university": "University of Stirling",
                    "location": "Stirling",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Aquaculture",
                    "university": "University of Stirling",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Applied Professional Studies",
                    "university": "University of Stirling",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Big Data",
                    "university": "University of Stirling",
                    "location": "Online",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dementia Studies",
                    "university": "University of Stirling",
                    "location": "Online",
                    "qualification": "MSc",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design for Dementia and Ageing",
                    "university": "University of Stirling",
                    "location": "Online",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Doctorate Degrees at Stirling",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "Doctoral Programme",
                    "duration": "4 years",
                    "study_mode": "Mixed mode",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Leadership (Specialist Qualification for Headship)",
                    "university": "University of Stirling",
                    "location": "Stirling",
                    "qualification": "MSc",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Housing Studies",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Education and Leadership",
                    "university": "University of Stirling",
                    "location": "Stirling Campus",
                    "qualification": "MSc",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "University of Stirling",
                    "location": "Online",
                    "qualification": "MPH",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health Nurse (Health Visiting)",
                    "university": "University of Stirling",
                    "location": "Stirling",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Mixed mode",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Relations and Strategic Communication",
                    "university": "University of Stirling",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Performance Coaching",
                    "university": "University of Stirling",
                    "location": "Online",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages",
                    "university": "University of Stirling",
                    "location": "Online",
                    "qualification": "MSc",
                    "duration": "30 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "eaa635e926948406d4432ce77958e680",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Business & Enterprise with Education",
                    "university": "Stranmillis University College",
                    "location": "Main Site",
                    "qualification": "BEd (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "Stranmillis University College",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Early Childhood Studies (Foundation)",
                    "university": "Stranmillis University College",
                    "location": "Multiple Locations",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Mathematics with Education",
                    "university": "Stranmillis University College",
                    "location": "Main Site",
                    "qualification": "BEd (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Physical Education and Sport",
                    "university": "Stranmillis University College",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Physical Education and Sport (Foundation)",
                    "university": "Stranmillis University College",
                    "location": "Belfast Metropolitan College (Gerald Moag Campus)",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Primary",
                    "university": "Stranmillis University College",
                    "location": "Main Site",
                    "qualification": "BEd (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "136"
                },
                {
                    "course_name": "Religious Studies with Education",
                    "university": "Stranmillis University College",
                    "location": "Main Site",
                    "qualification": "BEd (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Technology & Design with Education",
                    "university": "Stranmillis University College",
                    "location": "Main Site",
                    "qualification": "BEd (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Early Childhood Studies",
                    "university": "Stranmillis University College",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Professional Practice",
                    "university": "Stranmillis University College",
                    "location": "Main Site",
                    "qualification": "DProf",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Early Childhood Studies",
                    "university": "Stranmillis University College",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies",
                    "university": "Stranmillis University College",
                    "location": "Main Site",
                    "qualification": "MEd",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education: Blended Learning",
                    "university": "Stranmillis University College",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pastoral Care",
                    "university": "Stranmillis University College",
                    "location": "Main Site",
                    "qualification": "MEd",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "SEN Literacy",
                    "university": "Stranmillis University College",
                    "location": "Main Site",
                    "qualification": "MEd",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching",
                    "university": "Stranmillis University College",
                    "location": "Main Site",
                    "qualification": "MTeach",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "8b8e7f4ed14392fe8635132a27aa6c37",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting & Business Analysis and Technology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting & Business Enterprise",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting & Business Law",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting & Economics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting & Finance",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting & Hospitality and Tourism Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting & Human Resource Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting & Marketing",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aero-Mechanical Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aero-Mechanical Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Chemistry and Chemical Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSci",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Studies",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Design (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Design - Gaelic Medium (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSci",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry and Immunology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry and Microbiology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry and Pharmacology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology with Science (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biology with Science - Gaelic Medium (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomolecular Sciences",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BBA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analysis and Technology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analysis and Technology & Business Enterprise",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analysis and Technology & Business Law",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analysis and Technology & Economics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analysis and Technology & Finance",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analysis and Technology & Hospitality and Tourism Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analysis and Technology & Human Resource Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analysis and Technology & Marketing",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analysis and Technology & Mathematics and Statistics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Education (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Education - Gaelic Medium (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Enterprise",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Enterprise & Business Law",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Enterprise & Economics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Enterprise & Finance",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Enterprise & Hospitality and Tourism Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Enterprise & Human Resource Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Enterprise & Marketing",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MChem",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Data Science",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MChem",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Drug Discovery",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MChem",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Science (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Science - Gaelic Medium (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Teaching",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MChem",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil and Environmental Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil and Environmental Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer and Electronic Systems",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer and Electronic Systems",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer and Electronic Systems with International Study",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing Science - Gaelic Medium (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Analytics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics & Business Law",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics & Finance",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics & Hospitality and Tourism Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics & Human Resource Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics & Marketing",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics & Mathematics and Statistics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics & Psychology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education and Curricular Studies with Teaching Qualification - Home Economics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education and Curricular Studies with Teaching Qualification - Technological Education",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education and Economics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education and English",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education and English and Creative Writing",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education and French",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education and History",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education and Human Resource Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education and Journalism, Media and Communication",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education and Law",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education and Politics and International Relations",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education and Psychology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education and Social Policy",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education and Spanish",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education and TESOL",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Mechanical Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Mechanical Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Mechanical Engineering with International Study",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Energy Systems",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Digital Systems",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering with Business Studies",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering with International Study",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Academy",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English - Gaelic Medium (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Creative Writing",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Creative Writing and French",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Creative Writing and History",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Creative Writing and Human Resource Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Creative Writing and Journalism, Media and Communication",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Creative Writing and Law",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Creative Writing and Politics and International Relations",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Creative Writing and Psychology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Creative Writing and Social Policy",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Creative Writing and Spanish",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and French",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and History",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Human Resource Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Journalism, Media and Communication",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Law",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Politics and International Relations",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Psychology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Social Policy",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Spanish",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance & Business Law",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance & Hospitality and Tourism Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance & Human Resource Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance & Marketing",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance & Mathematics and Statistics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic and Analytical Chemistry",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MChem",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French - Gaelic Medium (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Economics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and German (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and History",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Hospitality and Tourism Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Human Resource Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Italian (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Journalism, Media and Communication",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Law",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Mandarin - (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Marketing",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Politics and International Relations",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Psychology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Social Policy",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Spanish",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Spanish (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gaelic (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography - Gaelic Medium (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and French (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Italian (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Mandarin (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Spanish (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History - Gaelic Medium (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Economics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Human Resource Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Journalism, Media and Communication",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Law",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Politics and International Relations",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Psychology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Social Policy",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Spanish",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Home Economics (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Home Economics - Gaelic Medium (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hospitality and Tourism Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hospitality and Tourism Management & Business Law",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hospitality and Tourism Management & Human Resource Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hospitality and Tourism Management & Marketing",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management & Business Law",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management & Marketing",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management & Psychology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Immunology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSci",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Immunology and Microbiology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Immunology and Pharmacology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and French (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and German (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Mandarin (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Spanish (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism, Media and Communication and Economics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism, Media and Communication and Human Resource Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism, Media and Communication and Law",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism, Media and Communication and Politics and International Relations",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism, Media and Communication and Psychology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism, Media and Communication and Social Policy",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism, Media and Communication and Spanish",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (Clinical)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (Graduate Entrant) (Clinical)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "LLB",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (LLB)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Economics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Human Resource Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Politics and International Relations",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Psychology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Social Policy",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Spanish",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with a Modern Language (French)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "LLB (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with a Modern Language (Spanish)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "LLB (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "LLB Law (Graduate Entrant)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "LLB",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mandarin (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mandarin and German (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mandarin and Italian (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mandarin and Spanish (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Manufacturing Engineering with Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Manufacturing Engineering with Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing & Business Law",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing & Psychology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master in International Business with a Modern Language",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MIBML",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MMath",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics - Gaelic Medium (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Computer Science",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Physics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Teaching",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics, Statistics and Accounting",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics, Statistics and Business Analysis",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics, Statistics and Economics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics, Statistics and Finance",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with International Study",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with International Study",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSci",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology and Pharmacology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Studies (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Studies - Gaelic Medium (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Naval Architecture and Marine Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Naval Architecture and Marine Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Naval Architecture with High Performance Marine Vehicles",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Naval Architecture with High Performance Marine Vehicles",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Naval Architecture with Ocean Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Naval Architecture with Ocean Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSci",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MPharm",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Education (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Education - Gaelic Medium (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Advanced Research",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Science (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Science - Gaelic Medium (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Teaching",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations and Economics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations and Human Resource Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations and Psychology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations and Social Policy",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations and Spanish",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Education",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Education (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Education - Gaelic Medium (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design and Innovation",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSci",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design and Innovation",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Prosthetics and Orthotics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology - Gaelic Medium (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Counselling",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Economics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Human Resource Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Mathematics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Social Policy",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Spanish",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religious and Moral Education (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Religious and Moral Education - Gaelic Medium (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy and Economics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy and Human Resource Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy and Spanish",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Economics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and French (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and German (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Hospitality and Tourism Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Human Resource Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Italian (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Mandarin (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Marketing",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Speech and Language Pathology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Physical Activity",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Design Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Design Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Technological Education (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Technological Education - Gaelic Medium (PGDE - Graduates only)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Professional Graduate Diploma in Education (Scotland)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "5G Advanced Communications",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Actuarial Science",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Architectural Design",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MArch",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Biochemistry",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Chemical Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science with Artificial Intelligence",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science with Data Science",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Computer Science with Software Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Construction Technologies & BIM",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Drug Delivery",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Electrical Power & Energy Systems",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Immunology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Manufacturing: Technology & Systems",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Materials Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Mechanical Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Mechanical Engineering with Aerospace",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Mechanical Engineering with Energy Systems",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Mechanical Engineering with Industrial Placement",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Mechanical Engineering with Materials",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Mechanical Engineering with Power Plant Technologies",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Naval Architecture",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Pharmaceutical Manufacturing",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Pharmacology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Physics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Economics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Educational and Social Research",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Gender Studies",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Gender Studies (Research Methods)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Physics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Statistics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Statistics in Health Sciences",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Statistics\u00a0in Finance",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Translation",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Translation and Interpreting",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Design (International)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MArch",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Design for the Conservation of Built Heritage",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence and Applications",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Autism",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Autonomous Robotic Intelligent Systems",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business & Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analysis & Consulting",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer Therapies",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Artificial Intelligence",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Data Science",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering (with pathways)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Health Psychology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Pharmacy",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Law",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "PgDip (Initial Qualification)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling and Psychotherapy",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MLitt",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Justice and Penal Change",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology & Social Policy",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Analytics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science for Politics and Policymaking",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Engineering with Advanced Product Development",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design Engineering with Sustainability",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Health Systems",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Journalism",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MLitt",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Manufacturing",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diplomacy and International Security",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics & Finance",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics & Policy of Energy & Climate Change",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Power and Energy Systems",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Systems Innovation",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Management for Process Excellence",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Entrepreneurship, Innovation & Technology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance & Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Technology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Environmental Law and Governance",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health & Social Policy",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Analysis, Policy & Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health History",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Historical Studies",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Rights Law",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "PgCert",
                    "duration": "8 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hydrogeology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Industrial Biotechnology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information and Library Studies",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interdisciplinary English Studies",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MLitt",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Accounting & Finance",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Banking & Finance",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "PgDip (Initial Qualification)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Resource Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Marketing",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Master Project Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Social Welfare",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Investment & Finance",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "PgDip (Initial Qualification)",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law, Technology & Innovation",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Machine Learning and Deep Learning",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Engineering with Specialisation in Autonomous Marine Vehicles",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA (Master of Business Administration)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MBA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronics and Automation",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communication",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MLitt",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mediation and Conflict Resolution",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular Microbiology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nanoscience",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience & Mental Health",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Psychology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Offshore and Pipeline Engineering",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Offshore Floating Systems",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Offshore Wind Energy",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Analysis",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Photonics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Economy",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Research",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Product Design",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Legal Practice",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Diploma",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management & Innovation",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Prosthetics and Orthotics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Policy",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantitative Finance",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantum Technologies",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Renewable Energy and Decarbonisation Technologies",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Research Methods in Psychology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Satellite Data for Sustainable Development",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ship & Offshore Structures",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ship and Offshore Technology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Smart Grids with the Comillas Pontifical University, Madrid",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy (Research Methods)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSW",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Development",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Data Analytics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Supply Chain and Logistics Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Supply Chain and Procurement Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Supply Chain and Sustainability Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability and Environmental Studies",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Engineering (Chemical Processing)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Engineering (Marine Technology)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Engineering (Offshore Renewable Energy)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Engineering (Renewable Energy Systems and the Environment)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Systems Engineering Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Technical Ship Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "TESOL and Intercultural Communication",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Tourism Marketing Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Design",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Wind Energy Systems",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Work & Organisational Psychology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Pharmaceutical Manufacturing",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Physics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Residential Childcare",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Economics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Educational and Social Research",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Gender Studies",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Gender Studies (Research Methods)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Physics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Statistics\u00a0in Finance (online)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Autism",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MEd",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analysis & Consulting",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "PgDip",
                    "duration": "21 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children & Young People in Conflict with the Law",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Health Psychology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Law",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "PgDip (Initial Qualification)",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling and Psychotherapy",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MLitt",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Justice and Penal Change",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "LLM",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology & Social Policy",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Analytics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science for Politics and Policymaking",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Journalism",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MLitt",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diplomacy and International Security",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics & Policy of Energy & Climate Change",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies Part-time Taught Masters Programmes",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MEd",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Leadership",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MEd",
                    "duration": "36 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Entrepreneurship, Innovation & Technology",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Executive MBA (Master of Business Administration)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MBA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genealogical, Palaeographic and Heraldic Studies",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "PgCert",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Environmental Law and Governance",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "LLM",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health & Social Policy",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Analysis, Policy & Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health History",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "PgDip",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Historical Studies",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Rights Law",
                    "university": "University of Strathclyde",
                    "location": "Multiple Locations",
                    "qualification": "PgDip",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interdisciplinary English Studies",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MLitt",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "PgDip (Initial Qualification)",
                    "duration": "21 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Social Welfare",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "PgDip (Initial Qualification)",
                    "duration": "21 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law, Technology & Innovation",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "LLM",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leading Learning & Transformative Practice in Colleges (with TQFE)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "PgCert",
                    "duration": "21 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communication",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MLitt",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mediation and Conflict Resolution",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "PgDip",
                    "duration": "21 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health Social Work",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nanoscience",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Quality & Good Manufacturing Practice",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "21 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Photonics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Economy",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Research",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Legal Practice",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "Diploma",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Practice",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MEd",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management & Innovation",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Policy",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantum Technologies",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Safety and Risk Management",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "9 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy (Research Methods)",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport Data Analytics",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "TESOL and Intercultural Communication",
                    "university": "University of Strathclyde",
                    "location": "University of Strathclyde",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "ac3f8c89fe9112973ae1584cfc0eaf91",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Animal Welfare and Management",
                    "university": "City of Sunderland College",
                    "location": "Northumberland College",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Animal Welfare and Management",
                    "university": "City of Sunderland College",
                    "location": "Kirkley Hall",
                    "qualification": "BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Community Coaching",
                    "university": "City of Sunderland College",
                    "location": "Northumberland College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing",
                    "university": "City of Sunderland College",
                    "location": "Northumberland College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Counselling",
                    "university": "City of Sunderland College",
                    "location": "Bede Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Counselling",
                    "university": "City of Sunderland College",
                    "location": "Bede Campus",
                    "qualification": "FD",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Crime & Criminal Justice",
                    "university": "City of Sunderland College",
                    "location": "Bede Campus",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Engineering General",
                    "university": "City of Sunderland College",
                    "location": "Northumberland College",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Equine Management and Training",
                    "university": "City of Sunderland College",
                    "location": "Kirkley Hall",
                    "qualification": "BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Equine Management, Training and Coaching",
                    "university": "City of Sunderland College",
                    "location": "Northumberland College",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "ESports",
                    "university": "City of Sunderland College",
                    "location": "Bede Campus",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Game Art and Production",
                    "university": "City of Sunderland College",
                    "location": "Bede Campus",
                    "qualification": "FdA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Games Design (Top Up)",
                    "university": "City of Sunderland College",
                    "location": "Bede Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health & Social Care Practice",
                    "university": "City of Sunderland College",
                    "location": "Bede Campus",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Higher National Certificate in Leadership & Management",
                    "university": "City of Sunderland College",
                    "location": "City Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Music Performance Production & Industry",
                    "university": "City of Sunderland College",
                    "location": "Bede Campus",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Performance (Musical Theatre)",
                    "university": "City of Sunderland College",
                    "location": "Bede Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Sports Coaching & Performance",
                    "university": "City of Sunderland College",
                    "location": "Hartlepool Sixth Form",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sports Coaching & Performance",
                    "university": "City of Sunderland College",
                    "location": "Bede Campus",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Visual Arts (Art and Design)",
                    "university": "City of Sunderland College",
                    "location": "Bede Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Wildlife Conservation",
                    "university": "City of Sunderland College",
                    "location": "Kirkley Hall",
                    "qualification": "BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Wildlife Conservation",
                    "university": "City of Sunderland College",
                    "location": "Kirkley Hall",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Working with Children and Families",
                    "university": "City of Sunderland College",
                    "location": "Bede Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Working with Children and Families",
                    "university": "City of Sunderland College",
                    "location": "Northumberland College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Working with Children and Families",
                    "university": "City of Sunderland College",
                    "location": "Bede Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "596f76002523d529c397e09e25436538",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance (with Professional Exemptions ACCA, CIMA, CPA)",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Accounting and Financial Management (Top-Up)",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Adult Nursing (Blended)",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Adult Nursing Practice",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Animal Management",
                    "university": "University of Sunderland",
                    "location": "East Durham College",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Animation and Games Art",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Animation and Games Art with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Automotive Engineering (Top-up)",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Banking and Finance (Top-Up)",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Biochemistry with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Biomedical Science with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Biopharmaceutical Science",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Biopharmaceutical Science with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Business and Financial Management",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Business and Financial Management with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Business and Human Resource Management",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Business and Human Resource Management with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Business and Management",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Business and Management Stage 3",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Business and Marketing Management",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Business and Marketing Management Stage 3",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Marketing Management with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Childhood and Society Studies  (Top Up)",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Childhood Studies",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Childhood Studies with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Childrens and Young People\u2019s Nursing Practice",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Clinical Psychology",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Clinical Psychology with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Computer Science with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Computer Systems Engineering (Top-up)",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cosmetic Science",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Cosmetic Science with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Criminology with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Cybersecurity and Digital Forensics",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Cybersecurity and Digital Forensics with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Education Studies",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Education Studies with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Electronic and Electrical Engineering",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Electronic and Electrical Engineering (Top-up)",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Engineering Technologies (Top-Up)",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "English: Creative and Professional Writing",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "English: Creative and Professional Writing with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Esports Event Management",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88-104"
                },
                {
                    "course_name": "Events Management",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Fashion Design and Promotion",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Fashion Design and Promotion with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Fashion Journalism",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fashion Journalism with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Film and Media",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Film and Media with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Film Production",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Film Production with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Fine Art with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Forensic Psychology with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Game Development",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Game Development with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Graphic Design with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "University of Sunderland",
                    "location": "East Durham College",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Health and Social Care with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Illustration and Design",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Illustration and Design with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40"
                },
                {
                    "course_name": "International Tourism and Hospitality Management",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88-104"
                },
                {
                    "course_name": "International Tourism and Hospitality Management with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Journalism",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Journalism with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Law (Qualifying)",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Law with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Mass Communications (Top-Up)",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics Education (11-16 years) with QTS",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "2 to 3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Mechanical Engineering (Top-Up)",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Media Production",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Media Production with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Media, Culture and Communication",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Media, Culture and Communication with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Medicine",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "MB ChB",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "144"
                },
                {
                    "course_name": "Mental Health Nursing Practice",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Midwifery Practice",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Modern Music Industries",
                    "university": "University of Sunderland",
                    "location": "Northern Academy of Music Education",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Music (Top-up)",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Network Systems Engineering (Top-up)",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Therapy (Accelerated)",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Paramedic Science and Out of Hospital Care",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Performing Arts",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Performing Arts with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Pharmacy",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "MPharm (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Photography, Video and Digital Imaging",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Photography, Video and Digital Imaging with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Physical Education with QTS",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Physiological Sciences",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Physiological Sciences with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Policing Studies (Top-Up)",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA/BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Primary Education with QTS",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Primary Education with SEND",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Professional Dance",
                    "university": "University of Sunderland",
                    "location": "Dance City",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-96"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Professional Policing (accelerated)",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Professional Policing with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Psychology with Counselling",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Psychology with Counselling with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Psychology with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Registered Nursing Associate",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "76"
                },
                {
                    "course_name": "Screen Performance",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Screen Performance with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Social Media Management",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Social Media Management with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Sport and Exercise Sciences",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Sport and Exercise Sciences with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Sport and Rehabilitation Therapy",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Sports Coaching",
                    "university": "University of Sunderland",
                    "location": "East Durham College",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Sports Coaching and Physical Education",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Sports Coaching and Physical Education with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA/BSc",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Sports Journalism",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Sports Journalism with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Sustainable Design Engineering",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Sustainable Design Engineering with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Tourism and Aviation Management",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88-104"
                },
                {
                    "course_name": "Tourism and Aviation Management with Integrated Foundation Year",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Advanced Engineering Design",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Computing",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Cybersecurity",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Data Science",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Project Management",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts, Design and Media (by existing work)",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "PhD",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Childhood and Youth Studies",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Commercial Law and International Trade",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cosmetic Science",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Law and Procedure",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cybersecurity",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drug Discovery and Development",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Management",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Studies",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Management",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management (CIPD Accredited)",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Inequality and Society",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management MSc",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Rights",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism / Journalism (Sports)",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership in Criminal Justice and Policing",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal Practice",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing (Professional Practice)",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration with PgCert",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration with Professional Practice",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media Production (Film and Television)",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MNurse (Adult)",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "MNurs",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical and Biopharmaceutical Formulations",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Sciences for the Overseas Pharmacist Assessment Programme (OSPAP)",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological Research Methods",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Conversion)",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Relations",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Radio, Audio and Podcasting",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Special Educational Needs, Disability and Inclusion",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Sciences",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Design and Manufacture",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Tourism",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Terrorism and Cyber Warfare LLM",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Tourism and Aviation",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Tourism and Aviation",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Tourism and Events",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Tourism and Hospitality",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Practice",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Clinical Pharmacy",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Studies",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Inequality and Society",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Journalism / Journalism (Sports)",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership in Criminal Justice and Policing",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MA Advanced Counselling Practice",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media Production (Film and Television)",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Conversion)",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Relations",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Radio, Audio and Podcasting",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Tourism and Aviation",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Tourism and Hospitality",
                    "university": "University of Sunderland",
                    "location": "Sir Tom Cowie Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Practice",
                    "university": "University of Sunderland",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "fcb6889e372d7f80045c139ba89b4ec7",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Acting",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Actor Musician",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied and Contemporary Theatre",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied and Contemporary Theatre",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astronautics and Space Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astronautics and Space Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astronautics and Space Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astronautics and Space Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astronautics and Space Engineering with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astronautics and Space Engineering with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSci",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSci",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSci",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics and Data Analytics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics and Data Analytics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics and Data Analytics with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Economics and Data Analytics with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and French",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management and Spanish",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Business Analytics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Business Analytics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Business Analytics with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Business Analytics with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Entrepreneurship and Innovation",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Entrepreneurship and Innovation",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Entrepreneurship and Innovation with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Entrepreneurship and Innovation with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Human Resource Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Human Resource Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Human Resource Management with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Human Resource Management with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Marketing",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Marketing",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Marketing with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Marketing with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical and Petroleum Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical and Petroleum Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical and Petroleum Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical and Petroleum Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical and Petroleum Engineering with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical and Petroleum Engineering with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Forensic Investigation",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Forensic Investigation",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Forensic Investigation",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Forensic Investigation with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Forensic Investigation with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer and Internet Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer and Internet Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer and Internet Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer and Internet Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer and Internet Engineering with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer and Internet Engineering with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing with Business Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing with Business Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Music Technology",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BMus (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Psychology",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Psychology",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Sociology with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Sociology with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology with Forensic Investigation",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology with Forensic Investigation",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Mathematics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Mathematics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with Computer Systems",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with Computer Systems",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with Computer Systems",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with Computer Systems",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with Computer Systems with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with Computer Systems with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Creative Writing",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Creative Writing",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and French",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Spanish",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment and Sustainability",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment and Sustainability",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Production and Broadcast Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Mathematics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Mathematics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Mathematics with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Mathematics with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Science and Nutrition",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Science and Nutrition",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Science and Nutrition with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Science and Nutrition with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Foundation Acting",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Foundation Musical Theatre",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Design",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Design",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Airline and Airport Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Airline and Airport Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Event Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Event Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Event Management with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Event Management with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Hospitality and Tourism Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Hospitality and Tourism Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Hospitality and Tourism Management with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Hospitality and Tourism Management with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Hospitality Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Hospitality Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Hospitality Management with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Hospitality Management with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tourism Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tourism Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tourism Management with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tourism Management with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (Law and Technology Pathway)",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (Law and Technology Pathway)",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (Philosophy, Politics and Law Pathway)",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (Philosophy, Politics and Law Pathway)",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Criminology with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Criminology with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "LLB (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "LLB (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "LLB (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with International Relations",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with International Relations",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with International Relations with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "LLB (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with International Relations with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MMath",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MMath",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Physics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MMath",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Physics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Physics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MPhys",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Physics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Physics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MMath",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Physics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Physics with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Physics with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Data Science",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Data Science",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Data Science with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Data Science with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communication",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communication",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communication with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communication with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicinal Chemistry",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicinal Chemistry",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicinal Chemistry",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicinal Chemistry with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicinal Chemistry with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine (Graduate Entry)",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BMBS",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery: Registered Midwife",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages (French and Spanish)",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BMus (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BMus (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Sound Recording (Tonmeister)",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BMus(Hons) / BSc(Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Musical Theatre",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing Studies (Registered Nurse Adult Nursing)",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing Studies (Registered Nurse Adult Nursing) with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing Studies (Registered Nurse Children & Young People Nursing)",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing Studies (Registered Nurse Children & Young People Nursing) with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing Studies (Registered Nurse Mental Health Nursing)",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing Studies (Registered Nurse Mental Health Nursing) with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nutrition",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nutrition",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nutrition and Dietetics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nutrition and Dietetics with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nutrition with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nutrition with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paramedic Science",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physician Associate Studies",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astronomy",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astronomy",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astronomy",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astronomy with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astronomy with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Nuclear Astrophysics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Nuclear Astrophysics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Nuclear Astrophysics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Nuclear Astrophysics with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Nuclear Astrophysics with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Quantum Computing",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Quantum Computing",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Quantum Computing",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Quantum Computing with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Quantum Computing with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Economics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Economics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Sociology",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Sociology",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Sociology with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Sociology with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Affairs",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MPA",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Affairs",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MPA",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Science with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Science with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre Production",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Biosciences",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Biosciences",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Biosciences with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Biosciences with Foundation Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Medicine and Science",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "BVMSci",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "5G and Future Generation Communication Systems",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Acting",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Acting MFA",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MFA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Geotechnical Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Mechanical Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "AI for Translation and Interpreting Studies",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Air Transport Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Quantum Computing",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Banking and Finance",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Behaviour Change",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bridge Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MBA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "15 months",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Vision, Robotics and Machine Learning",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Corporate Environmental Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology (Corporate Crime and Corporate Responsibility)",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology (Cybercrime & Cybersecurity)",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security with Professional Postgraduate Year",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc (PG)",
                    "duration": "15 months",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MPhil (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics (Econometrics and Big Data)",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Entrepreneurship & Innovation Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Psychology",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Strategy",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film, Animation and Digital Arts",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Data Science",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "FinTech and Policy",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Science",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Nutrition",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Infrastructure Engineering and Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intercultural Business Communication and Marketing",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Corporate Finance",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Events Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Financial Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Hotel Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Marketing",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations (International Intervention)",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tourism Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interpreting, Technology and AI",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interpreting, Technology and AI (Chinese pathway)",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Investment Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc (PG)",
                    "duration": "15 months",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Physics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MMus",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Musical Theatre",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Musical Theatre MFA",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MFA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nanotechnology and Renewable Energy",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nuclear Science and Radiation Protection",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational and Organizational Psychology",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Sciences",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Sciences with Industrial Practice",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physician Associate Studies",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "PgDip",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary and Community Care SPQ Community Children's Nursing",
                    "university": "University of Surrey",
                    "location": "Kate Granger Building (Surrey Research Park)",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary and Community Care SPQ Community Children's Nursing with integrated prescribing (V300)",
                    "university": "University of Surrey",
                    "location": "Kate Granger Building (Surrey Research Park)",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary and Community Care SPQ District Nursing",
                    "university": "University of Surrey",
                    "location": "Kate Granger Building (Surrey Research Park)",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary and Community Care SPQ District Nursing with integrated prescribing (V300)",
                    "university": "University of Surrey",
                    "location": "Kate Granger Building (Surrey Research Park)",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Process Systems Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Legal Practice (SQE Pathway)",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "LL.M",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Conversion)",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology in Game Design and Digital Innovation",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Affairs",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health Practice SCPHN Health Visiting",
                    "university": "University of Surrey",
                    "location": "Kate Granger Building (Surrey Research Park)",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health Practice SCPHN School Nursing",
                    "university": "University of Surrey",
                    "location": "Kate Granger Building (Surrey Research Park)",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Renewable Energy Systems Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Satellite Communications Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Psychology",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Space Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Stage and Production Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Hotel Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Marketing",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "15 months",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Development",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Energy",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Energy with Industrial Practice",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching and Practice of Voice and Singing MFA",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MFA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation and AI",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation and AI (Chinese Pathway)",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation, Interpreting and AI",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary General Practice",
                    "university": "University of Surrey",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Water and Environmental Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "5G and Future Generation Communication Systems",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice",
                    "university": "University of Surrey",
                    "location": "Kate Granger Building (Surrey Research Park)",
                    "qualification": "MSc (PG)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Geotechnical Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "AI for Translation and Interpreting Studies",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "Masters in Research (MRes)",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Air Transport Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Behaviour Change",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bridge Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MBA (Executive)",
                    "duration": "24 months",
                    "study_mode": "Part-time weekend",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Vision, Robotics and Machine Learning",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Corporate Environmental Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology (Corporate Crime and Corporate Responsibility)",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology (Cybercrime & Cybersecurity)",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education for Health Professionals",
                    "university": "University of Surrey",
                    "location": "Kate Granger Building (Surrey Research Park)",
                    "qualification": "MA",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education for Health Professionals",
                    "university": "University of Surrey",
                    "location": "Kate Granger Building (Surrey Research Park)",
                    "qualification": "PgCert",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Entrepreneurship & Innovation Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Psychology",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Strategy",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Data Science",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Healthcare Practice",
                    "university": "University of Surrey",
                    "location": "Kate Granger Building (Surrey Research Park)",
                    "qualification": "MSc (PG)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Higher and Professional Education",
                    "university": "University of Surrey",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Infrastructure Engineering and Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intercultural Business Communication and Marketing",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Corporate Finance",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations (International Intervention)",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Investment Management",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership in Healthcare",
                    "university": "University of Surrey",
                    "location": "Kate Granger Building (Surrey Research Park)",
                    "qualification": "MSc (PG)",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Physics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MMus",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nanotechnology and Renewable Energy",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nuclear Science and Radiation Protection",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nutritional Medicine",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Sciences",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary and Community Care SPQ Community Children's Nursing",
                    "university": "University of Surrey",
                    "location": "Kate Granger Building (Surrey Research Park)",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary and Community Care SPQ Community Children's Nursing with integrated prescribing (V300)",
                    "university": "University of Surrey",
                    "location": "Kate Granger Building (Surrey Research Park)",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary and Community Care SPQ District Nursing",
                    "university": "University of Surrey",
                    "location": "Kate Granger Building (Surrey Research Park)",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary and Community Care SPQ District Nursing with integrated prescribing (V300)",
                    "university": "University of Surrey",
                    "location": "Kate Granger Building (Surrey Research Park)",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Process Systems Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology in Game Design and Digital Innovation",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Affairs",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health Practice SCPHN Health Visiting",
                    "university": "University of Surrey",
                    "location": "Kate Granger Building (Surrey Research Park)",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health Practice SCPHN School Nursing",
                    "university": "University of Surrey",
                    "location": "Kate Granger Building (Surrey Research Park)",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Renewable Energy Systems Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Satellite Communications Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Psychology",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Space Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Marketing",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Development",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Energy",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation and AI",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation, Interpreting and AI",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary General Practice",
                    "university": "University of Surrey",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "22 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Water and Environmental Engineering",
                    "university": "University of Surrey",
                    "location": "Stag Hill",
                    "qualification": "MSc",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "efacfa1dfecf840228f35906423c5cd6",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "The College, Swansea University",
                    "location": "The College, Swansea University",
                    "qualification": "Master's Programme",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children and Young People",
                    "university": "The College, Swansea University",
                    "location": "The College, Swansea University",
                    "qualification": "Master's Programme",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "The College, Swansea University",
                    "location": "The College, Swansea University",
                    "qualification": "Master's Programme",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "The College, Swansea University",
                    "location": "The College, Swansea University",
                    "qualification": "Master's Programme",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Languages, Politics and Culture",
                    "university": "The College, Swansea University",
                    "location": "The College, Swansea University",
                    "qualification": "Master's Programme",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "The College, Swansea University",
                    "location": "The College, Swansea University",
                    "qualification": "Master's Programme",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health and Management",
                    "university": "The College, Swansea University",
                    "location": "The College, Swansea University",
                    "qualification": "Master's Programme",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        }
    },
    {
        "id": "8ecec780fdeeb91745eece265b160c4d",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Advanced Make-Up, Hair and Prosthetics",
                    "university": "New College Swindon University Centre",
                    "location": "North Star Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Management",
                    "university": "New College Swindon University Centre",
                    "location": "North Star Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management",
                    "university": "New College Swindon University Centre",
                    "location": "Swindon and Wiltshire Institute of Technology",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Computer Science (Top-Up)",
                    "university": "New College Swindon University Centre",
                    "location": "Swindon and Wiltshire Institute of Technology",
                    "qualification": "BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing for England (Computing)",
                    "university": "New College Swindon University Centre",
                    "location": "Swindon and Wiltshire Institute of Technology",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computing for England (Network Engineering)",
                    "university": "New College Swindon University Centre",
                    "location": "Swindon and Wiltshire Institute of Technology",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Esports",
                    "university": "New College Swindon University Centre",
                    "location": "Swindon and Wiltshire Institute of Technology",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Fashion and Textiles",
                    "university": "New College Swindon University Centre",
                    "location": "Queens Drive Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-168"
                },
                {
                    "course_name": "Film and Television",
                    "university": "New College Swindon University Centre",
                    "location": "Swindon and Wiltshire Institute of Technology",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Fine Art",
                    "university": "New College Swindon University Centre",
                    "location": "North Star Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Game Development",
                    "university": "New College Swindon University Centre",
                    "location": "Swindon and Wiltshire Institute of Technology",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Graphic Design (and Illustration)",
                    "university": "New College Swindon University Centre",
                    "location": "North Star Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-112"
                },
                {
                    "course_name": "Performing Arts (Technical Arts) Media Make-Up",
                    "university": "New College Swindon University Centre",
                    "location": "North Star Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Public Services",
                    "university": "New College Swindon University Centre",
                    "location": "Queens Drive Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "New College Swindon University Centre",
                    "location": "Swindon and Wiltshire Institute of Technology",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Civil Engineering",
                    "university": "New College Swindon University Centre",
                    "location": "Swindon and Wiltshire Institute of Technology",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Construction Management for England (Construction Site Supervisor)",
                    "university": "New College Swindon University Centre",
                    "location": "Swindon and Wiltshire Institute of Technology",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Electrical and Electronic Engineering for England (HTQ)",
                    "university": "New College Swindon University Centre",
                    "location": "Swindon and Wiltshire Institute of Technology",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Manufacturing Engineering for England",
                    "university": "New College Swindon University Centre",
                    "location": "Swindon and Wiltshire Institute of Technology",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48-112"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "af29903b09077ef00344a180823d0750",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Computing (Cyber Security)",
                    "university": "Tameside College",
                    "location": "Tameside One",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Electrical/Electronic Engineering",
                    "university": "Tameside College",
                    "location": "Tameside College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Tameside College",
                    "location": "Tameside College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Certificate of Education in Teaching in the Lifelong Learning Sector (Part Time)",
                    "university": "Tameside College",
                    "location": "Tameside College",
                    "qualification": "CertEd",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Psychology",
                    "university": "Tameside College",
                    "location": "Tameside College",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Education (Supporting Teaching and Learning)",
                    "university": "Tameside College",
                    "location": "Tameside College",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time evening",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Electrical/Electronic Engineering",
                    "university": "Tameside College",
                    "location": "Tameside College",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Tameside College",
                    "location": "Tameside College",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechatronics",
                    "university": "Tameside College",
                    "location": "Tameside College",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Postgraduate Certificate in Education - PGCE (Part Time)",
                    "university": "Tameside College",
                    "location": "Tameside College",
                    "qualification": "PGCE",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Graduate Certificate in Education - PrGCE (Part Time)",
                    "university": "Tameside College",
                    "location": "Tameside College",
                    "qualification": "PGCE (Professional)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "8300339573d375f2098c6cbed0b5b7ee",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "b6b49261dc4b5e6836d4a3f27aef1ae8",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "2D Animation and Stop Motion",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "2D Animation and Stop Motion with Foundation Year",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "80-104"
                },
                {
                    "course_name": "Accounting and Finance (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-64"
                },
                {
                    "course_name": "Aeronautical Engineering",
                    "university": "Teesside University",
                    "location": "Hartlepool College of Further Education",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "40-48"
                },
                {
                    "course_name": "Aerospace Engineering (with Foundation Year and Optional Year in Industry)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-88"
                },
                {
                    "course_name": "Aerospace Engineering (with Optional Year in Industry)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Aerospace Engineering (with Optional Year in Industry)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Airline and Airport Management (Top-up)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Science and Welfare",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Animation",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Animation (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Applied Sport and Exercise (Top-up)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence and Computer Science",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Artificial Intelligence and Computer Science with Foundation Year",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "64-80"
                },
                {
                    "course_name": "Aviation and Tourism",
                    "university": "Teesside University",
                    "location": "Stockton Riverside College",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40-48"
                },
                {
                    "course_name": "Aviation, Travel and Tourism",
                    "university": "Teesside University",
                    "location": "Hartlepool College of Further Education",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40-48"
                },
                {
                    "course_name": "Biology",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Biology (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-88"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Biomedical Science (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-88"
                },
                {
                    "course_name": "Business and Accounting",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-104"
                },
                {
                    "course_name": "Business and Accounting (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-64"
                },
                {
                    "course_name": "Business and Marketing",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "80-96"
                },
                {
                    "course_name": "Business and Marketing (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-64"
                },
                {
                    "course_name": "Business Economics",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-104"
                },
                {
                    "course_name": "Business Economics (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "32-64"
                },
                {
                    "course_name": "Business Finance and Accounting (Top-up)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-104"
                },
                {
                    "course_name": "Business Management (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "32-64"
                },
                {
                    "course_name": "Business Skills",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "Teesside University",
                    "location": "Redcar & Cleveland College",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64-80"
                },
                {
                    "course_name": "Chemical Engineering (with Foundation Year and Optional Year in Industry)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-88"
                },
                {
                    "course_name": "Chemical Engineering (with Optional Year in Industry)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Chemical Engineering (with Optional Year in Industry)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Children and Early Childhood",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-96"
                },
                {
                    "course_name": "Children and Early Childhood (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-64"
                },
                {
                    "course_name": "Chiropractic (with Integrated Masters)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Civil Engineering (Top-up)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BEngTec H",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering (with Foundation Year and Optional Year in Industry)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "32-88"
                },
                {
                    "course_name": "Civil Engineering (with Optional Year in Industry)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Civil Engineering (with Optional Year in Industry)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Comics and Graphic Novels",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Comics and Graphic Novels with Foundation Year",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-88"
                },
                {
                    "course_name": "Computer and Digital Forensics",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Computer and Digital Forensics (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-88"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Computer Science with Foundation Year",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "64-80"
                },
                {
                    "course_name": "Concept Art",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Concept Art with Foundation Year",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Construction Management",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Counselling",
                    "university": "Teesside University",
                    "location": "Multiple Locations",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Crime and Investigation",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-104"
                },
                {
                    "course_name": "Crime and Investigation (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-64"
                },
                {
                    "course_name": "Crime Scene Science",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Crime Scene Science (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "32-88"
                },
                {
                    "course_name": "Criminology",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-104"
                },
                {
                    "course_name": "Criminology (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-64"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-104"
                },
                {
                    "course_name": "Criminology and Sociology (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-64"
                },
                {
                    "course_name": "Criminology with Law",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-104"
                },
                {
                    "course_name": "Criminology with Law (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-64"
                },
                {
                    "course_name": "Criminology with Psychology",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-104"
                },
                {
                    "course_name": "Criminology with Psychology (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-64"
                },
                {
                    "course_name": "Culinary Arts and Management",
                    "university": "Teesside University",
                    "location": "Hartlepool College of Further Education",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Cyber Security with Foundation Year",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64-80"
                },
                {
                    "course_name": "Dental Hygiene",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Dental Nurse Practice",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Diagnostic Radiography",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Digital Media and Communications",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Education Studies",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-96"
                },
                {
                    "course_name": "Education Studies (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-64"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Teesside University",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering (Top-up)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BEngTec H",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering (with Foundation Year and Optional Year in Industry)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-88"
                },
                {
                    "course_name": "Electrical and Electronic Engineering (with Optional Year in Industry)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Electrical and Electronic Engineering (with Optional Year in Industry)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Energy Engineering",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Energy Engineering",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "English and Creative Writing",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "English and Creative Writing (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-64"
                },
                {
                    "course_name": "English Studies",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "English Studies (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-64"
                },
                {
                    "course_name": "Environmental Science",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Fashion",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Fashion (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "32-88"
                },
                {
                    "course_name": "Film and Television Production",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Film and Television Production with Foundation Year",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Fine Art with Foundation Year",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-88"
                },
                {
                    "course_name": "Food Science and Technology",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Food Science and Technology (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "32-88"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Forensic Psychology (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-64"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Forensic Science (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "32-88"
                },
                {
                    "course_name": "Games Art",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Games Art with Foundation Year",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "64-80"
                },
                {
                    "course_name": "Games Design",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Games Design with Foundation Year",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "64-80"
                },
                {
                    "course_name": "Games Development",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Games Development with Foundation Year",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Games Programming",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Games Programming with Foundation Year",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64-80"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Graphic Design (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-88"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "Teesside University",
                    "location": "Multiple Locations",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32-48"
                },
                {
                    "course_name": "Health and Social Care (Top-up)",
                    "university": "Teesside University",
                    "location": "Stockton Riverside College",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Sciences",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Health Sciences",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Health Sciences (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "CertHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Sciences (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-88"
                },
                {
                    "course_name": "Health, Wellbeing and Social Support",
                    "university": "Teesside University",
                    "location": "Redcar & Cleveland College",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40-48"
                },
                {
                    "course_name": "History",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "History (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-64"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "80-96"
                },
                {
                    "course_name": "Human Resource Management (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "32-64"
                },
                {
                    "course_name": "Illustration",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Illustration (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Information and Cyber Security",
                    "university": "Teesside University",
                    "location": "Teesside University London Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Information and Cyber Security",
                    "university": "Teesside University",
                    "location": "Teesside University London Campus",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Information Technology",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Information Technology with Foundation Year",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "64-80"
                },
                {
                    "course_name": "Instrumentation and Control Engineering (with Foundation Year and Optional Year in Industry)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-88"
                },
                {
                    "course_name": "Instrumentation and Control Engineering (with Optional Year in Industry)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Instrumentation and Control Engineering (with Optional Year in Industry)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Interior Design",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Interior Design (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-88"
                },
                {
                    "course_name": "International Business Management (Top-up)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Human Resource Management (Top-up)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Marketing (Top-up)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tourism Management",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-96"
                },
                {
                    "course_name": "International Tourism Management (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "32-64"
                },
                {
                    "course_name": "Journalism",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Journalism with Foundation Year",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Law",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88-112"
                },
                {
                    "course_name": "Law (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-64"
                },
                {
                    "course_name": "Law and Practice",
                    "university": "Teesside University",
                    "location": "Bede Sixth Form College",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40-48"
                },
                {
                    "course_name": "Law, Criminology and Criminal Justice",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88-112"
                },
                {
                    "course_name": "Law, Criminology and Criminal Justice (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-64"
                },
                {
                    "course_name": "Marketing",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "80-104"
                },
                {
                    "course_name": "Marketing (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-64"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Teesside University",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering (Top-up)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BEngTec H",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering (with Foundation Year and Optional Year in Industry)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-88"
                },
                {
                    "course_name": "Mechanical Engineering (with Optional Year in Industry)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Mechanical Engineering (with Optional Year in Industry)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Midwifery",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Music Production",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Music Production (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Nursing Studies (Adult)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing Studies (Child)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing Studies (Learning Disabilities)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing Studies (Mental Health)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nutrition",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Nutrition (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "32-88"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Operating Department Practice Studies",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Optometry",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MOptom",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Paramedic Practice",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Pharmaceutical Science",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Pharmaceutical Science (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-88"
                },
                {
                    "course_name": "Pharmacy",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MPharm (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Photography",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Photography with Foundation Year",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "32-88"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Police Studies (Top-up)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics with International Relations",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Politics with International Relations (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-64"
                },
                {
                    "course_name": "Primary Education with QTS (5-11)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-104"
                },
                {
                    "course_name": "Professional Policing (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-64"
                },
                {
                    "course_name": "Psychology",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Psychology (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-64"
                },
                {
                    "course_name": "Psychology and Counselling",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Psychology and Counselling (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-64"
                },
                {
                    "course_name": "Psychology with Clinical Psychology",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Psychology with Clinical Psychology (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-64"
                },
                {
                    "course_name": "Public Services",
                    "university": "Teesside University",
                    "location": "Hartlepool College of Further Education",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40-48"
                },
                {
                    "course_name": "Salon Management",
                    "university": "Teesside University",
                    "location": "Hartlepool College of Further Education",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40-48"
                },
                {
                    "course_name": "Social Work",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Sociology",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72-96"
                },
                {
                    "course_name": "Sociology (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "32-64"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Software Engineering with Foundation Year",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Sport and Exercise Science (with Foundation Year)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Sport Journalism",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Sport Journalism with Foundation Year",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Sports Coaching and Exercise",
                    "university": "Teesside University",
                    "location": "Hartlepool College of Further Education",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40-48"
                },
                {
                    "course_name": "Sports Therapy and Rehabilitation",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88-112"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL) (Top-up)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Therapeutic Counselling (Top-up)",
                    "university": "Teesside University",
                    "location": "Stockton Riverside College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Effects and Motion Graphics",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Visual Effects and Motion Graphics with Foundation Year",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Sandwich including foundation year",
                    "ucas_points": "See entry requirements"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "2D Animation and Stop Motion",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "3D Games Art",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Biomedical Science",
                    "university": "Teesside University",
                    "location": "Teesside University Darlington",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animation",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Artificial Intelligence",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Data Science",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence with Data Analytics",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aviation Management",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioinformatics",
                    "university": "Teesside University",
                    "location": "Teesside University Darlington",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioprocessing and Biotherapeutics",
                    "university": "Teesside University",
                    "location": "Teesside University Darlington",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer and Molecular Diagnostics",
                    "university": "Teesside University",
                    "location": "Teesside University Darlington",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil and Structural Engineering",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comics and Graphic Novels",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Concept Art",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Crime Intelligence and Data Analytics",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Investigation",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Forensics and Cyber Investigation",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media and Communications",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Early Childhood Studies)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Educational Leadership)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Teaching English to Speakers of Other Languages)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Power and Energy Systems",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Management",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Technology (FinTech)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Processing Engineering",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Science and Biotechnology",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Design",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Illustration",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Instrumentation and Control Engineering",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "IT Project Management",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Policy",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration (MBA)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology",
                    "university": "Teesside University",
                    "location": "Teesside University Darlington",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Multimedia Public Relations",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oil and Gas Management",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Petroleum Engineering",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research Methods (Criminology)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Research Methods (Social Policy)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "22 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strength and Conditioning for Performance and Rehabilitation",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Energy and Clean Technology",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Engineering Systems",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Communication",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Effects",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "2D Animation and Stop Motion",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "3D Games Art",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Biomedical Science",
                    "university": "Teesside University",
                    "location": "Teesside University Darlington",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animation",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Artificial Intelligence",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Data Science",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence with Data Analytics",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aviation Management",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioinformatics",
                    "university": "Teesside University",
                    "location": "Teesside University Darlington",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioprocessing and Biotherapeutics",
                    "university": "Teesside University",
                    "location": "Teesside University Darlington",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer and Molecular Diagnostics",
                    "university": "Teesside University",
                    "location": "Teesside University Darlington",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil and Structural Engineering",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comics and Graphic Novels",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Concept Art",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Crime Intelligence and Data Analytics",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminal Investigation",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Forensics and Cyber Investigation",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media and Communications",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Early Childhood Studies)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Educational Leadership)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Teaching English to Speakers of Other Languages)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Power and Energy Systems",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Management",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Technology (FinTech)",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Processing Engineering",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Science and Biotechnology",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Design",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Illustration",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Instrumentation and Control Engineering",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "IT Project Management",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Microbiology",
                    "university": "Teesside University",
                    "location": "Teesside University Darlington",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Multimedia Public Relations",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oil and Gas Management",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Petroleum Engineering",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strength and Conditioning for Performance and Rehabilitation",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Energy and Clean Technology",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Engineering Systems",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Communication",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual Effects",
                    "university": "Teesside University",
                    "location": "Teesside University Middlesbrough Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "241f84f7884401674d0d9a354f71f066",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "b2bfe13a842202bf720084e2961f2d9f",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "1f0a4155b6cc197c820ee7dbea08a6c7",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "d94731ad2acf943a5dde755081a42519",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Adult Nursing",
                    "university": "University Centre Truro and Penwith",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Adult Nursing Top-Up",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Applied Computing Technologies (Top-Up)",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Applied Media (Top-Up)",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Applied Psychology",
                    "university": "University Centre Truro and Penwith",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Applied Psychology",
                    "university": "University Centre Truro and Penwith",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Applied Social Science (Top-Up)",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Applied Sport and Health Science",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Archaeology (Top-Up)",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Business",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business, Enterprise and Leadership (Top-Up)",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Childhood Education",
                    "university": "University Centre Truro and Penwith",
                    "location": "Multiple Locations",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computing Technologies",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Cyber Security (HTQ)",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Cyber Security (HTQ)",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Early Years Practice",
                    "university": "University Centre Truro and Penwith",
                    "location": "Multiple Locations",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Education and Training (Top-Up)",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Film, Media and Photography",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Health and Wellbeing",
                    "university": "University Centre Truro and Penwith",
                    "location": "Penwith Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "History, Heritage and Culture",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Human Behavioural Studies (Top-Up)",
                    "university": "University Centre Truro and Penwith",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Law (Top-Up)",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Business",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Law with Criminology and Criminal Justice",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Mental Health Nursing",
                    "university": "University Centre Truro and Penwith",
                    "location": "Multiple Locations",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Mental Health Nursing Top-Up",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Nursing Associate (HTQ)",
                    "university": "University Centre Truro and Penwith",
                    "location": "Multiple Locations",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Operating Department Practitioner",
                    "university": "University Centre Truro and Penwith",
                    "location": "Bodmin",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "FdEng",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sports Therapy",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Teaching and Learning",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Youth, Social and Community Studies",
                    "university": "University Centre Truro and Penwith",
                    "location": "Multiple Locations",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Applied Computing Technologies (Top-Up)",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Applied Media (Top-Up)",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Applied Psychology",
                    "university": "University Centre Truro and Penwith",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Applied Psychology",
                    "university": "University Centre Truro and Penwith",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Applied Social Science (Top-Up)",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Applied Sport and Health Science",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "FdSc",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Archaeology (Top-Up)",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Business",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "FdA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Childhood Education",
                    "university": "University Centre Truro and Penwith",
                    "location": "Multiple Locations",
                    "qualification": "FdA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computing Technologies",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "FdSc",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Cyber Security (HTQ)",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Cyber Security (HTQ)",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "FdSc",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Early Years Practice",
                    "university": "University Centre Truro and Penwith",
                    "location": "Multiple Locations",
                    "qualification": "FdA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Education and Training (Top-Up)",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Film, Media and Photography",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "FdA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Health and Wellbeing",
                    "university": "University Centre Truro and Penwith",
                    "location": "Penwith Campus",
                    "qualification": "FdSc",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "History, Heritage and Culture",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "FdA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Human Behavioural Studies (Top-Up)",
                    "university": "University Centre Truro and Penwith",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Law (Top-Up)",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Business",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "FdSc",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Law with Criminology and Criminal Justice",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "FdSc",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "HND",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Modern Methods of Construction (HTQ)",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "FdEng",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sports Therapy",
                    "university": "University Centre Truro and Penwith",
                    "location": "Truro Campus",
                    "qualification": "FdSc",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Youth, Social and Community Studies",
                    "university": "University Centre Truro and Penwith",
                    "location": "Multiple Locations",
                    "qualification": "FdSc",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "4f4a1630072dbcb391a38c223ef697d2",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Primary and Early Years Education with QTS",
                    "university": "Two Mile Ash ITTP (partnered with Chiltern Training Group)",
                    "location": "Main Site TMA ITTP",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "9bb319996d4657981343e33fbed694ab",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "eb1ac1146e0c0955c0ef8c52c3bf3169",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "3D Game Art & VFX",
                    "university": "UCEN Manchester",
                    "location": "City Campus Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Acting",
                    "university": "UCEN Manchester",
                    "location": "City Campus Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Applied Sport and Exercise Science (Top-Up)",
                    "university": "UCEN Manchester",
                    "location": "Openshaw Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management",
                    "university": "UCEN Manchester",
                    "location": "City Campus Manchester",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Childhood and Youth Studies",
                    "university": "UCEN Manchester",
                    "location": "Openshaw Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Childhood and Youth Studies (Top-Up)",
                    "university": "UCEN Manchester",
                    "location": "Openshaw Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "UCEN Manchester",
                    "location": "Openshaw Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computer Networking, Automation and Management",
                    "university": "UCEN Manchester",
                    "location": "City Campus Manchester",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Computer Networking, Automation and Management (Top-Up)",
                    "university": "UCEN Manchester",
                    "location": "City Campus Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing for England",
                    "university": "UCEN Manchester",
                    "location": "City Campus Manchester",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Counselling",
                    "university": "UCEN Manchester",
                    "location": "Shena Simon Campus",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling (Top-up)",
                    "university": "UCEN Manchester",
                    "location": "Shena Simon Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology and Criminal Justice",
                    "university": "UCEN Manchester",
                    "location": "Openshaw Campus",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Criminology and Criminal Justice (Top-Up)",
                    "university": "UCEN Manchester",
                    "location": "Openshaw Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security (Defensive Threat Detection)",
                    "university": "UCEN Manchester",
                    "location": "City Campus Manchester",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Cyber Security (Defensive Threat Detection) (Top up)",
                    "university": "UCEN Manchester",
                    "location": "City Campus Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Dance and Performance",
                    "university": "UCEN Manchester",
                    "location": "City Campus Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Digital Technologies for England (Artificial Intelligence (AI) Solutions and Applications) (HTQ)",
                    "university": "UCEN Manchester",
                    "location": "City Campus Manchester",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Engineering for England",
                    "university": "UCEN Manchester",
                    "location": "Openshaw Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Fashion & Textiles",
                    "university": "UCEN Manchester",
                    "location": "City Campus Manchester",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Film Production & Content Creation",
                    "university": "UCEN Manchester",
                    "location": "City Campus Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Football Industry",
                    "university": "UCEN Manchester",
                    "location": "Openshaw Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Graphic Design and Branding",
                    "university": "UCEN Manchester",
                    "location": "City Campus Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Healthcare Professions Support for England (HTQ)",
                    "university": "UCEN Manchester",
                    "location": "Openshaw Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Integrated Health and Social Care (Top-Up)",
                    "university": "UCEN Manchester",
                    "location": "Openshaw Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership and Management",
                    "university": "UCEN Manchester",
                    "location": "City Campus Manchester",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Make-Up Artistry",
                    "university": "UCEN Manchester",
                    "location": "City Campus Manchester",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Make-Up Artistry (Top-Up)",
                    "university": "UCEN Manchester",
                    "location": "City Campus Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Musical Theatre",
                    "university": "UCEN Manchester",
                    "location": "City Campus Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "PE and School Sport",
                    "university": "UCEN Manchester",
                    "location": "Openshaw Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Performing Arts (Acting)",
                    "university": "UCEN Manchester",
                    "location": "City Campus Manchester",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Performing Arts (Musical Theatre)",
                    "university": "UCEN Manchester",
                    "location": "City Campus Manchester",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Photography and Social Practice",
                    "university": "UCEN Manchester",
                    "location": "City Campus Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Professional Practice in Health and Social Care",
                    "university": "UCEN Manchester",
                    "location": "Openshaw Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "School Sport and Sport Coaching (Top-Up)",
                    "university": "UCEN Manchester",
                    "location": "Openshaw Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Development",
                    "university": "UCEN Manchester",
                    "location": "City Campus Manchester",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Software Development (Top-Up)",
                    "university": "UCEN Manchester",
                    "location": "City Campus Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Special Effects Make Up Artistry (Top-Up)",
                    "university": "UCEN Manchester",
                    "location": "City Campus Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Special Effects Make-Up Artistry",
                    "university": "UCEN Manchester",
                    "location": "City Campus Manchester",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sport And Exercise Science",
                    "university": "UCEN Manchester",
                    "location": "Openshaw Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Technical Theatre and Stage Management",
                    "university": "UCEN Manchester",
                    "location": "City Campus Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "The Animation Industry",
                    "university": "UCEN Manchester",
                    "location": "City Campus Manchester",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Theatre and Performance",
                    "university": "UCEN Manchester",
                    "location": "City Campus Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Vocal Studies and Performance",
                    "university": "UCEN Manchester",
                    "location": "City Campus Manchester",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Civil Engineering",
                    "university": "UCEN Manchester",
                    "location": "Openshaw Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Engineering for England",
                    "university": "UCEN Manchester",
                    "location": "Openshaw Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Leadership and Management",
                    "university": "UCEN Manchester",
                    "location": "City Campus Manchester",
                    "qualification": "HND",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "School Sport and Sport Coaching (Top-Up)",
                    "university": "UCEN Manchester",
                    "location": "Openshaw Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "89cf6155e1bb41b4a9d3141373e84dd8",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Football Business",
                    "university": "UCFB",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Football Business and Finance",
                    "university": "UCFB",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Football Business and Finance with Foundation Year",
                    "university": "UCFB",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Football Business and Marketing",
                    "university": "UCFB",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Football Business and Marketing with Foundation Year",
                    "university": "UCFB",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Football Business and Media",
                    "university": "UCFB",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Football Business and Media with Foundation Year",
                    "university": "UCFB",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Football Business with Foundation Year",
                    "university": "UCFB",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Football Coaching and Management",
                    "university": "UCFB",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Football Coaching and Management",
                    "university": "UCFB",
                    "location": "Multiple Locations",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Football Coaching and Management with Foundation Year",
                    "university": "UCFB",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Football Coaching and Talent Development",
                    "university": "UCFB",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Football Business",
                    "university": "UCFB",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "International Football Business with Foundation Year",
                    "university": "UCFB",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Multimedia Sports Journalism",
                    "university": "UCFB",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Multimedia Sports Journalism with Foundation Year",
                    "university": "UCFB",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Physical Education",
                    "university": "UCFB",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Physical Education with Foundation Year",
                    "university": "UCFB",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sport Psychology",
                    "university": "UCFB",
                    "location": "UCFB Wembley",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sports Business & Sports Law",
                    "university": "UCFB",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sports Business and Coaching",
                    "university": "UCFB",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sports Business and Coaching with Foundation Year",
                    "university": "UCFB",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sports Business and Sports Broadcasting",
                    "university": "UCFB",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sports Business and Sports Broadcasting with Foundation Year",
                    "university": "UCFB",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sports Coaching Science",
                    "university": "UCFB",
                    "location": "UCFB Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sports Coaching Science with Foundation Year",
                    "university": "UCFB",
                    "location": "UCFB Manchester",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sports Management",
                    "university": "UCFB",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sports Management with Foundation Year",
                    "university": "UCFB",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Stadium and Events Management",
                    "university": "UCFB",
                    "location": "UCFB Wembley",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Football Business",
                    "university": "UCFB",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Football Coaching and Analysis",
                    "university": "UCFB",
                    "location": "UCFB Manchester",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Football Communications & Digital Marketing",
                    "university": "UCFB",
                    "location": "UCFB Wembley",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Sport Management",
                    "university": "UCFB",
                    "location": "UCFB Wembley",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance Analysis in Football",
                    "university": "UCFB",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Football Business",
                    "university": "UCFB",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Football Coaching and Analysis",
                    "university": "UCFB",
                    "location": "UCFB Manchester",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Football Communications & Digital Marketing",
                    "university": "UCFB",
                    "location": "UCFB Wembley",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Sport Management",
                    "university": "UCFB",
                    "location": "UCFB Wembley",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance Analysis in Football",
                    "university": "UCFB",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Directorship",
                    "university": "UCFB",
                    "location": "UCFB Manchester",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "ded50e01ab5f09c65a95ce19d4042960",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Ancient History",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient Languages",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient Languages with Year Abroad",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology with a Year Abroad",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Medical Sciences",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and Anthropology",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology with a Placement Year",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology with a Year Abroad (4 years)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural & Interdisciplinary Studies",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural & Interdisciplinary Studies with a Year Abroad",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Technology",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts and Sciences",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BASc (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts and Sciences with Study Abroad",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BASc (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astrophysics",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astrophysics",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bachelor of Laws (UCL) and LLB Bachelor of Laws (University of Hong Kong)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemical Engineering",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemical Engineering",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemical Engineering with Integrated Foundation Year BEng",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "100"
                },
                {
                    "course_name": "Biochemical Engineering with Integrated Foundation Year MEng",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "100"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering with Integrated Foundation Year BEng",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "BEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "100"
                },
                {
                    "course_name": "Biomedical Engineering with Integrated Foundation Year MEng",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "100"
                },
                {
                    "course_name": "Biomedical Sciences",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioprocessing of New Medicines (Business and Management)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "BSc Audiology",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "BSc Experimental Linguistics",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "BSc Experimental Linguistics (International Programme)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bulgarian and Czech",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bulgarian and Danish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bulgarian and Dutch",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bulgarian and East European Studies (4 years)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bulgarian and Finnish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bulgarian and French",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bulgarian and German",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bulgarian and Hebrew",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bulgarian and Hungarian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bulgarian and Italian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bulgarian and Norwegian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bulgarian and Polish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bulgarian and Portuguese",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bulgarian and Romanian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bulgarian and Russian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bulgarian and Serbian/Croatian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bulgarian and Spanish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bulgarian and Swedish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bulgarian and Ukrainian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bulgarian and Yiddish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Health",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Health",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer Biomedicine",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering with Integrated Foundation Year BEng",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "100"
                },
                {
                    "course_name": "Chemical Engineering with Integrated Foundation Year MEng",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "100"
                },
                {
                    "course_name": "Chemistry",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry (International Programme)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Management Studies",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Management Studies",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Mathematics",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Mathematics",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering with Integrated Foundation Year BEng",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "BEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "100"
                },
                {
                    "course_name": "Civil Engineering with Integrated Foundation Year MEng",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MEng",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "100"
                },
                {
                    "course_name": "Classical Archaeology and Classical Civilisation",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and the Ancient World",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and the Ancient World with Study Abroad",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communications",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature with a Year Abroad",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Integrated Foundation Year BSc",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "100"
                },
                {
                    "course_name": "Computer Science with Integrated Foundation Year MEng",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "100"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "Master in Science (with Honours) \u2013 MSci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Arts and Humanities",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Crime and Security Science",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Czech (with Slovak) and East European Studies (4 years)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Czech and Danish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Czech and Dutch",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Czech and Finnish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Czech and French",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Czech and German",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Czech and Hebrew",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Czech and Hungarian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Czech and Italian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Czech and Norwegian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Czech and Polish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Czech and Portuguese",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Czech and Romanian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Czech and Russian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Czech and Serbian/Croatian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Czech and Spanish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Czech and Swedish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Czech and Ukrainian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Czech and Yiddish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Danish and Dutch",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Danish and Finnish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Danish and French",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Danish and German",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Danish and Hebrew",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Danish and Hungarian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Danish and Italian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Danish and Polish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Danish and Portuguese",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Danish and Romanian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Danish and Russian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Danish and Serbian/Croatian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Danish and Spanish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Danish and Ukrainian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Danish and Yiddish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dutch (4 years)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dutch and English",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dutch and Finnish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dutch and French",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dutch and German",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dutch and Hebrew",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dutch and History of Art",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dutch and Hungarian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dutch and Italian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dutch and Latin",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dutch and Management Studies",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dutch and Norwegian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dutch and Philosophy",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dutch and Polish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dutch and Portuguese",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dutch and Romanian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dutch and Russian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dutch and Serbian/Croatian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dutch and Spanish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dutch and Swedish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dutch and Ukrainian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dutch and Yiddish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dutch with Film Studies",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dutch with Management Studies",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Childhood Education BA",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth Sciences",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth Sciences",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth Sciences (International Programme)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics & Business with East European Studies (with a Year Abroad) (4 years)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Business with East European Studies",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Statistics",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics with a Year Abroad",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc Econ H",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education, Society and Culture BA",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering with Integrated Foundation Year BEng",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "100"
                },
                {
                    "course_name": "Electronic and Electrical Engineering with Integrated Foundation Year MEng",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "100"
                },
                {
                    "course_name": "Engineering and Architectural Design",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Geoscience",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Geoscience",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "European Social and Political Studies (4 years)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "European Social and Political Studies Dual Degree",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BFA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art (4 years)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finnish and East European Studies (4 years)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finnish and French",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finnish and German",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finnish and Hebrew",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finnish and Hungarian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finnish and Italian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finnish and Norwegian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finnish and Polish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finnish and Portuguese",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finnish and Romanian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finnish and Russian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finnish and Serbian/Croatian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finnish and Spanish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finnish and Swedish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finnish and Ukrainian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finnish and Yiddish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French (4 years)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and English",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and German",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Hebrew",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and History of Art",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Hungarian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Italian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Latin",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Norwegian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Philosophy",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Polish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Portuguese",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Romanian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Russian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Serbian/Croatian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Spanish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Swedish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Ukrainian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Yiddish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French with Film Studies",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French with Management Studies",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography (International)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography (International)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography and Economics",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geology",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geophysics",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geophysics",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German (4 years)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and English",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Hebrew",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and History of Art",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Hungarian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Italian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Jewish Studies",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Latin",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Norwegian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Philosophy",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Polish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Portuguese",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Romanian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Russian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Serbian/Croatian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Spanish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Swedish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Ukrainian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Yiddish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German with Film Studies",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German with Management Studies",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Humanitarian Studies",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Greek and English",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hebrew and Hungarian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hebrew and Italian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hebrew and Jewish Studies",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hebrew and Jewish Studies (with Year Abroad)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hebrew and Norwegian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hebrew and Polish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hebrew and Romanian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hebrew and Serbian/Croatian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hebrew and Spanish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hebrew and Swedish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hebrew and Ukrainian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History (Central and East European) and Jewish Studies with Year Abroad (4 years)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History (with a Year Abroad)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Philosophy of Science",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Politics of the Americas",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Politics of the Americas with a Year Abroad",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History, Politics and Economics",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Neuroscience",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Sciences",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Sciences and Evolution",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hungarian and East European Studies (4 years)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hungarian and Italian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hungarian and Norwegian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hungarian and Polish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hungarian and Portuguese",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hungarian and Romanian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hungarian and Russian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hungarian and Serbian/Croatian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hungarian and Spanish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hungarian and Swedish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hungarian and Ukrainian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hungarian and Yiddish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Icelandic (4 years)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Infection and Immunity",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information in Society BSc",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Management for Business",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Social and Political Studies",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian (4 years)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and History of Art",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Jewish Studies",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Latin",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Management Studies",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Norwegian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Philosophy",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Polish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Portuguese",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Romanian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Russian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Serbian/Croatian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Spanish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Swedish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Ukrainian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Yiddish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian Studies: UCL-Venice Double Degree",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian with Film Studies",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language and Culture (4 years)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Latin and English",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with French Law (4 years)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with German Law (4 years)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Hispanic Law (4 years)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics (International Programme) (4 years)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management Science",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management Science",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical Computation",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Physics",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Physics",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Statistical Science",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Statistical Science",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Economics",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Economics",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Management Studies",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Management Studies",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Mathematical Physics",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Mathematical Physics",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Modern Languages",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics with Modern Languages",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Business Finance",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Business Finance with Integrated Foundation Year MEng",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "100"
                },
                {
                    "course_name": "Mechanical Engineering with Integrated Foundation Year BEng",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "BEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "100"
                },
                {
                    "course_name": "Mechanical Engineering with Integrated Foundation Year MEng",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "100"
                },
                {
                    "course_name": "Media",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Innovation and Enterprise",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Innovation and Enterprise",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Physics",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine (6 years)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "MB BS",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MEng Construction Engineering, Innovation & Leadership",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Sciences",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Sciences",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Norwegian and Polish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Norwegian and Portuguese",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Norwegian and Romanian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Norwegian and Russian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Norwegian and Serbian/Croatian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Norwegian and Spanish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Norwegian and Ukrainian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Norwegian and Yiddish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nutrition and Medical Sciences",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "MPharm (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmacy with Integrated Pre-registration Training",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "MPharm",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Computer Science",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Economics",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and History of Art",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy, Politics and Economics BSc",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Medical Physics",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Polish and East European Studies (4 years)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Polish and Portuguese",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Polish and Romanian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Polish and Russian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Polish and Serbian/Croatian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Polish and Spanish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Polish and Swedish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Polish and Ukrainian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Polish and Yiddish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, Sociology and East European Studies",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, Sociology and East European Studies with a Year Abroad",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Population Health Sciences",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Language Sciences",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Language Sciences",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with Education",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics and Artificial Intelligence",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics and Artificial Intelligence with Integrated Foundation Year MEng",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "100"
                },
                {
                    "course_name": "Romanian and East European Studies (4 years)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Romanian and Portuguese",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Romanian and Russian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Romanian and Serbian/Croatian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Romanian and Spanish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Romanian and Swedish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Romanian and Ukrainian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Romanian and Yiddish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian and History (4 years)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian and Portuguese",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian and Serbian/Croatian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian and Spanish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian and Swedish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian and Ukrainian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian and Yiddish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian Studies (4 years)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian with an East European Language (4 years)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scandinavian Studies (4 years)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scandinavian Studies and English",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scandinavian Studies and History of Art",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scandinavian Studies and Latin",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scandinavian Studies and Philosophy",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scandinavian Studies with Film Studies",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scandinavian Studies with Management Studies",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science and Engineering for Social Change",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science and Engineering for Social Change with Integrated Foundation Year BSc",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "100"
                },
                {
                    "course_name": "Serbian/Croatian and East European Studies (4 years)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Serbian/Croatian and Portuguese",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Serbian/Croatian and Spanish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Serbian/Croatian and Swedish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Serbian/Croatian and Ukrainian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Serbian/Croatian and Yiddish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Sciences",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Sciences with Data Science",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Politics of Science",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and History of Art",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Latin",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Latin American Studies",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Philosophy",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Portuguese",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Swedish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Ukrainian",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish and Yiddish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish with Film Studies",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish with Management Studies",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Medical Sciences",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistical Science (International Programme)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics and Management for Business",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics, Economics and a Language",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics, Economics and Finance",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Built Environments, Energy and Resources",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Built Environments, Energy, and Resources MEng",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Swedish and Portuguese",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Swedish and Yiddish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Physics",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ukrainian and East European Studies (4 years)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ukrainian and Portuguese",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ukrainian and Swedish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ukrainian and Yiddish",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Planning and Real Estate",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Planning, Design and Management",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Studies",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Viking and Old Norse Studies (4 years)",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Youth, Society and Sustainable Futures",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Advanced Biomedical Imaging",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Educational Practice",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "Grad Dip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Materials Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Materials Science (Data-driven Innovation)",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Materials Science (Energy Storage)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Materials Science (Materials Innovation and Enterprise)",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Materials Science (Sustainability)",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Neuroimaging",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Neuroimaging",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Physiotherapy: Cardiorespiratory",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Physiotherapy: Musculoskeletal",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Physiotherapy: Neurophysiotherapy",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Physiotherapy: Paediatrics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Propulsion",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aesthetics (Aesthetic Surgery)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MS",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aesthetics (Minimally-invasive Aesthetics)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MS",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and Professional Practice",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Analytical Chemistry",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Infectious Disease Epidemiology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Linguistics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Paediatric Neuropsychology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aquatic Conservation, Ecology and Restoration",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeological Science: Technology and Materials",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "Grad Dip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and Heritage of Asia",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural and Urban History and Theory",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Computation",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Design",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MArch",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural History",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Practice",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Space and Computation",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture and Digital Theory",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture and Historic Urban Environments",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archives and Records Management",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art Education, Culture and Practice",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence and Data Engineering",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence and Medical Imaging",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence for Biomedicine and Healthcare",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence for Sustainable Development",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astrophysics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Audio Storytelling for Radio and Podcast",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Audiological Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Audiological Science with Clinical Practice",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Banking and Digital Finance",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Behavioural Science and Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioarchaeological and Forensic Anthropology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemical Engineering",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemical Engineering",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biodiversity and Global Change",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomaterials and Tissue Engineering",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioscience (Research and Development)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioscience (Research and Development) with Practice",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioscience Innovation and Enterprise",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biosciences",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotech and Pharmaceutical Management",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Brain and Mind Sciences",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Brain Sciences",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Building and Urban Design in Development",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Built Environment : Sustainable Heritage (Data Science)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Built Environment: Environmental Design and Engineering",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Built Environment: Sustainable Heritage (Heritage Management)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Built Environment: Sustainable Heritage (Heritage Science)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Burns, Plastic and Reconstructive Surgery",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MBA",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Sustainability",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer Institute",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cardiovascular Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cardiovascular Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cell and Developmental Biology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Central and South-East European Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Centre for Advanced Spatial Analysis",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Process Engineering",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Research",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Child and Adolescent Mental Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Child and Adolescent Psychotherapy",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "DPsych",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Child Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Child Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cinematic and Videogame Architecture",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MArch",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "City Planning",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPlan",
                    "duration": "22 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "Grad Dip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering (with Fluids)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering (with Infrastructure Planning)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering (with Integrated Design)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering (with Structures)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering (with Transport)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil, Environmental and Geomatic Engineering",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate Change",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate, Innovation and Sustainability Policy",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical and Public Health Nutrition",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Drug Development",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Mental Health Sciences",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Neuroscience",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Neuroscience: Neuromuscular Disease",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Neuroscience: Stroke",
                    "university": "UCL (University College London)",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Paediatric Neuropsychology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Pharmacy, International Practice and Policy",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Pharmacy, International Practice and Policy with Extended Placement",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Psychology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "DClinPsy",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Trials and Methodology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical, Educational and Health Psychology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive and Decision Sciences",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Neuroscience",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Business Economics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Economics and Policy",
                    "university": "UCL (University College London)",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Education",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Archaeology: GIS, Data Science and Complexity",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Finance",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Statistics and Machine Learning",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Graphics, Vision and Imaging",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Connected Environments",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation for Archaeology and Museums",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation of Contemporary Art and Media",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservative Dentistry",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Economics and Management",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Countering Extremist Crime and Terrorism",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative and Collaborative Enterprise",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Documentary by Practice",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MFA (PG)",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Health",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "Master of Arts and Science (MASc)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Crime and Forensic Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Crime Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Crime Science with Cybercrime",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Crime Science with Data Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Crime Science with Serious Organised Crime",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cultural Heritage Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cybersecurity",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Machine Learning",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Public Policy (Economics)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Public Policy (Political Science)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Democracy and Comparative Politics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dental Public Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design for Manufacture",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MArch",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design for Performance and Interaction",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MArch",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Designing Audio Experiences: Art, Science and Production",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Development Administration and Planning",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Development Planning Unit",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Development, Technology and Innovation Policy",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Developmental and Educational Psychology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Developmental and Stem Cell Biology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Developmental Neuroscience and Psychopathology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Developmental Psychology and Clinical Practice",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dietetics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "21 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Anthropology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Engineering Management",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Health and Entrepreneurship",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Humanities",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Humanities",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Innovation Built Asset Management",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Manufacturing of Advanced Materials",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media: Critical Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media: Education",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media: Production",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Technologies and Policy",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Disability, Design and Innovation",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Division of Infection and Immunity",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Division of Psychiatry",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Division of Surgery and Interventional Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drug Design",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drug Design",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drug Discovery and Development",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drug Discovery and Pharma Management",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dutch",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ear Institute",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Child Development and Clinical Applications",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Modern Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Modern Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Years Education",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth Sciences",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earthquake Engineering with Disaster Management",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "East European Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Eastman Dental Institute",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Eastman Dental Institute (Clinical Experience)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Eating Disorders and Clinical Nutrition",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecology and Data Science",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecology and Urban Engineering",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecology, Climate Change and Health",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Policy of Energy and the Environment",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economy, State and Society: Economics and Business",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economy, State and Society: History and Society",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economy, State and Society: Politics and Security",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economy, State and Society: Politics and the International Economy",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Advanced Practice)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Assessment)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Citizenship)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Geography)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (History)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Literacy)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Psychology)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Science)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education and International Development",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education and International Development: Conflict, Emergencies and Peace (CEP)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education and Technology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education, Gender and International Development",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education, Health Promotion and International Development",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Leadership (Pre-service)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Mental Health Practitioner",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Planning, Economics and International Development",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Emerging Digital Technologies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Endodontology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MClinDent",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Endodontology (Advanced Training)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MClinDent",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Systems and Data Analytics (ESDA)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering and Education",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering for International Development",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering with Finance",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Education",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Literature",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Linguistics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English: Issues in Modern Culture",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Entrepreneurship",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment and Sustainable Development",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment, Politics and Society",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Anthropology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Archaeology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Design and Engineering",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Systems Engineering",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Epidemiology and Public Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ethnographic and Documentary Film (Practical)",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "European Culture and Thought: Culture",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "European Culture and Thought: Thought",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "European Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "European Studies: European Society",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "European Studies: Modern European Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Experimental and Translational Immunology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Experimental and Translational Immunology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Experimental Pharmacology and Therapeutics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Experimental Psychology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Technology",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance with Data Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Economics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Mathematics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Risk Management",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Technology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MFA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fire Safe Design",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MArch",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Future Manufacturing and Nanoscale Engineering",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender and Sexuality Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender, Society and Representation",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genetics and Multiomics in Medicine",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genetics of Human Disease",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genetics, Evolution and Environment",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography (practice-related)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geophysical Hazards",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geoscience",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geospatial Sciences",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geospatial Sciences (Building Information Modelling and Surveying)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geospatial Sciences (Geographic Information Science and Computing)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geospatial Sciences (Hydrographic Surveying)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Business for Health",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Governance and Ethics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health and Development",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Healthcare Management (Analytics)",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Healthcare Management (Finance)",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Healthcare Management (Leadership)",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Learning",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Management of Natural Resources",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Migration",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Prosperity",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Urbanism",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "Master of Arts and Science (MASc)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Greek, Latin, Ancient Middle East",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Haematology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Data Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Economics and Decision Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Humanities",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Humanities",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health in Urban Development",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Informatics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Informatics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Professions Education",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health, Technology and Public Policy",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health, Wellbeing and Sustainable Buildings",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Healthcare Facilities",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hebrew and Jewish Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Higher Education Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History (SSEES)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Philosophy of Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Housing and City Planning",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Evolution and Behaviour",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Geography",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Rights",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Tissue Repair",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Humanitarian Policy and Practice",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Immersive Factual Storytelling",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Infancy and Early Childhood Development",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Infection and Immunity",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Security",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Studies",
                    "university": "UCL (University College London)",
                    "location": "No Locations",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Infrastructure Investment and Finance",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Infrastructure Planning, Appraisal and Development",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Infrastructure Systems",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Innovation and Public Policy",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Innovation, Public Policy and Public Value",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Institute for Global Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Institute for Global Prosperity",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Institute for Women's Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Institute of Archaeology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Institute of Cognitive Neuroscience",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Institute of Neurology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Institute of Ophthalmology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Institute of the Americas",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrated Machine Learning Systems",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intercultural Communication",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interdisciplinary Societies, Arts and Sciences",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International City Planning",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development in the Americas",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Public Policy",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Real Estate and Planning",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations of the Americas",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Internet Engineering",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Jewish Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Knowledge, Information and Data Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Landscape Architecture",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language and Cognition",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language Sciences (Sign Language and Deaf Studies)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language Sciences: Development of Language and Speech",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language Sciences: Neuroscience of Language and Speech",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language Sciences: Principles of Language and Speech",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language Sciences: Technology of Language and Speech",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language, Culture and History: Dutch Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language, Culture and History: French and Francophone Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language, Culture and History: German History",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language, Culture and History: German Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language, Culture and History: Hispanic Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language, Culture and History: Italian Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language, Culture and History: Scandinavian Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "LLM",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Finance",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal and Political Theory",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Library and Information Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Light and Lighting",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics with a Specialisation in Computational Linguistics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics with a Specialisation in Phonology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics with a Specialisation in Syntax",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Machine Learning",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management of Complex Projects",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Managing Archaeological Sites",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Manufacture and Commercialisation of Stem Cell and Gene Therapies",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Manufacturing with Innovation and Enterprise",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marine Engineering",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing Science",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Public Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPH",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Material and Visual Culture",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials and Molecular Modelling",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Materials for Energy and Environment",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical Modelling",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics Education",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Imaging",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Physics and Bioengineering",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Physics and Biomedical Engineering",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Robotics and Artificial Intelligence",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Statistics and Data Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval and Renaissance Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health Sciences Research",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Museum Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Museums and Galleries in Education",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Education",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nanotechnology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nanotechnology and Regenerative Medicine",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Naval Architecture",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience, Physiology and Pharmacology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neurosurgery",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Obesity and Clinical Nutrition",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oncology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ophthalmology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Optometry and Ophthalmology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Optometry and Ophthalmology (Advanced Clinical Practice)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oral and Maxillofacial Surgery",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oral Medicine",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oral Surgery",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MClinDent",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oral Surgery (Advanced Training)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MClinDent",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Organic Chemistry: Drug Discovery",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Orthodontics (Advanced Training)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MClinDent",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Orthoptics (pre-registration)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paediatrics and Child Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paediatrics and Child Health: Advanced Paediatrics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paediatrics and Child Health: Community Child Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paediatrics and Child Health: Global Child Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paediatrics and Child Health: Intensive Care",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paediatrics and Child Health: Molecular and Genomic Paediatrics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Palaeoanthropology and Palaeolithic Archaeology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pathology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Periodontology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MClinDent",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Formulation and Entrepreneurship",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Research",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophical Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy of Education",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy, Politics and Economics of Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Therapy in Musculoskeletal Healthcare and Rehabilitation",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics and Astronomy",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics and Engineering in Medicine",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics and Engineering in Medicine by distance learning",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy Studies: Cardiorespiratory",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy Studies: Musculoskeletal",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy Studies: Neurophysiotherapy",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy Studies: Paediatrics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Planetary Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Planning",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Policing",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Policy Studies in Education",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Analysis (Russia and Eastern Europe)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Sociology (Russia and Eastern Europe)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, Violence and Crime",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Population Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Power Systems Engineering",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Precision Medicine",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Principles of Conservation",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Educational, Child and Adolescent Psychology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "DEdPsy",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project and Enterprise Management",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Prosperity, Innovation and Entrepreneurship",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Prosperity, People and Planet",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Prosthodontics (Advanced Training)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MClinDent",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological Sciences",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology of Education",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public History",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Management and Leadership",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Policy",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Publishing",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantum Technologies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Race, Ethnicity and Postcolonial Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate Economics and Investment Analysis",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Reception of the Classical World",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Remote Sensing and Environmental Mapping",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Reproductive Science and Women's Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Reproductive Science and Women's Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Research Methods for Archaeology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Respiratory Clinical Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Responsible Finance and Alternative Assets",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Risk and Disaster Reduction",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Risk and Disaster Reduction",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Risk and Disaster Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Risk, Disaster and Resilience",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Robotics and Artificial Intelligence",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian and East European Literature and Culture",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian and Post-Soviet Politics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sainsbury Wellcome Centre Systems Neuroscience",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scandinavian Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "School of Slavonic and East European Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science and Technology Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science Communication",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science, Technology and Society",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science, Technology, Engineering and Public Policy",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scientific and Data Intensive Computing",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Security and Crime Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Security and Crime Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Security Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MRes",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Security Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Situated Practice",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Slade School of Fine Art",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Smart Buildings and Digital Engineering",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Smart Energy and the Built Environment",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social and Cultural Anthropology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social and Geographic Data Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Cognition: Research and Applications",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Development Practice",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Epidemiology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Justice and Education",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Science Research Methods",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "PgDip",
                    "duration": "10 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology of Education",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Systems Engineering",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Space and Climate Physics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Space Science and Engineering: Space Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Space Science and Engineering: Space Technology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Space Syntax: Architecture and Cities",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Space Syntax: Architecture and Cities",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish, Portuguese and Latin American Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spatial Planning",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spatio-temporal Analytics and Big Data Mining",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Special and Inclusive Education",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Special and Inclusive Education (Autism)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Special and Inclusive Education (Specific Learning Difficulties)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Speech and Language Therapy",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "25 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Speech, Hearing and Phonetic Sciences",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Medicine, Exercise and Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistical Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics for Clinical Trials",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Management of Projects",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Construction",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Heritage",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Resources",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Resources: Economics, Policy and Transitions",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Urbanism",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Synthetic Biology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Systems Engineering for the Internet of Things",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Systems Engineering Management",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL) In-Service",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL) Pre-Service",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Technology Management",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Telecommunications",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Telecommunications",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Telecommunications with Business",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Psychoanalytic Studies (Non-Clinical)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation and Technology (Audiovisual)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation and Technology (Scientific, Technical and Medical)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation and Technology (with Interpreting)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation: Research",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation: Translation and Culture",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation: Translation Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translational Neuroscience",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Transport and City Planning",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Transport and Mobility Systems",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "UCL Energy Institute",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "UCL Interaction Centre",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "United States Studies: History and Politics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Design",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MArch",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Design and City Planning",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Development Planning",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Economic Development",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Innovation and Policy",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Regeneration",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Spatial Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Spatial Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Sustainability and Resilience",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Venture Capital and Private Equity with Financial Technology",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Wireless and Optical Communications",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Women's Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Aesthetic Dentistry",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Neuroimaging",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Neuroimaging",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Physiotherapy: Cardiorespiratory",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Physiotherapy: Musculoskeletal",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Physiotherapy: Neurophysiotherapy",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Physiotherapy: Paediatrics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aesthetics (Aesthetic Surgery)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MS",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aesthetics (Minimally-invasive Aesthetics)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MS",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anaesthesia and Perioperative Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Anthropology and Professional Practice",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Educational Leadership",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Infectious Disease Epidemiology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Linguistics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aquatic Conservation, Ecology and Restoration",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeological Science: Technology and Materials",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "Grad Dip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archaeology and Heritage of Asia",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural and Urban History and Theory",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Computation",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural History",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Practice",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architectural Space and Computation",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture and Digital Theory",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture and Historic Urban Environments",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Archives and Records Management",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art Education, Culture and Practice",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Astrophysics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Audio Storytelling for Radio and Podcast",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Audiological Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Audiological Science with Clinical Practice",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Banking and Digital Finance",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Behavioural Science and Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemical Engineering",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Sciences",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioscience Innovation and Enterprise",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotech and Pharmaceutical Management",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Building and Urban Design in Development",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Built Environment : Sustainable Heritage (Data Science)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Built Environment: Environmental Design and Engineering",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Built Environment: Sustainable Heritage (Heritage Management)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Built Environment: Sustainable Heritage (Heritage Science)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Burns, Plastic and Reconstructive Surgery",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MBA",
                    "duration": "28 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Sustainability",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cancer Institute",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cardiovascular Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cardiovascular Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cell and Developmental Biology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Central and South-East European Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Centre for Advanced Spatial Analysis",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Child and Adolescent Mental Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Child Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Child Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cinematic and Videogame Architecture",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MArch",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering (with Fluids)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering (with Infrastructure Planning)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering (with Integrated Design)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering (with Structures)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering (with Transport)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil, Environmental and Geomatic Engineering",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate Change",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Climate, Innovation and Sustainability Policy",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Associate in Psychology (Adult Mental Health) apprenticeship programme",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Drug Development",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Mental Health Sciences",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Neurology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Neurology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "PgCert",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Neurology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "PgDip",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Neuroscience",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Neuroscience: Neuromuscular Disease",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Neuroscience: Stroke",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Ophthalmic Practice",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Paediatric Neuropsychology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Trials and Methodology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical, Educational and Health Psychology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive and Decision Sciences",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cognitive Neuroscience",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Business Economics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Economics and Policy",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Education",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Comparative Literature",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computational Archaeology: GIS, Data Science and Complexity",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Connected Environments",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation for Archaeology and Museums",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservation of Contemporary Art and Media",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Conservative Dentistry",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Economics and Management",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Health",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "Master of Arts and Science (MASc)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cultural Heritage Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cybersecurity",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Democracy and Comparative Politics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dental Public Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design for Manufacture",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MArch",
                    "duration": "30 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design for Performance and Interaction",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MArch",
                    "duration": "30 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Designing Audio Experiences: Art, Science and Production",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Development Administration and Planning",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Development Planning Unit",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Development, Technology and Innovation Policy",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Developmental and Educational Psychology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Anthropology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Engineering Management",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Health and Entrepreneurship",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Humanities",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Humanities",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Innovation Built Asset Management",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media: Critical Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media: Education",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media: Production",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Technologies and Policy",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Division of Infection and Immunity",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Division of Psychiatry",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Division of Surgery and Interventional Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dutch",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ear Institute",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Modern Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Modern Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Years Education",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earth Sciences",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Earthquake Engineering with Disaster Management",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Eastman Dental Institute",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Eating Disorders and Clinical Nutrition",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecology and Data Science",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecology and Urban Engineering",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ecology, Climate Change and Health",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Policy of Energy and the Environment",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Advanced Practice)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Assessment)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Citizenship)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Geography)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (History)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Literacy)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Psychology)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education (Science)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education and International Development",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education and International Development: Conflict, Emergencies and Peace (CEP)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education and Technology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education, Gender and International Development",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education, Health Promotion and International Development",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Leadership (International)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Leadership (Pre-service)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Planning, Economics and International Development",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic and Electrical Engineering",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Endodontology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MClinDent",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Systems and Data Analytics (ESDA)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering and Education",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering for International Development",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Education",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Literature",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Linguistics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English: Issues in Modern Culture",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Enhanced Practice in Ophthalmology Apprenticeship",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "PgCert",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Entrepreneurship",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment and Sustainable Development",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environment, Politics and Society",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Anthropology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Archaeology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Design and Engineering",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Systems Engineering",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Epidemiology and Public Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ethnographic and Documentary Film (Practical)",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "European Culture and Thought: Culture",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "European Culture and Thought: Thought",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "European Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "European Studies: European Society",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "European Studies: Modern European Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Executive Programme Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Experimental and Translational Immunology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Experimental Psychology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Technology",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Mathematics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fire Safe Design",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MArch",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender and Sexuality Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender, Society and Representation",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genetics and Multiomics in Medicine",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Genetics, Evolution and Environment",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography (practice-related)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geophysical Hazards",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geoscience",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geospatial Sciences",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geospatial Sciences (Building Information Modelling and Surveying)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geospatial Sciences (Geographic Information Science and Computing)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geospatial Sciences (Hydrographic Surveying)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Business for Health",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Governance and Ethics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health and Development",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Healthcare Management (Analytics)",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Healthcare Management (Finance)",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Healthcare Management (Leadership)",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Learning",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Management",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Migration",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Prosperity",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Urbanism",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "Master of Arts and Science (MASc)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Greek, Latin, Ancient Middle East",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Haematology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Data Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Economics and Decision Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Humanities",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Humanities",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health in Urban Development",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Informatics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Informatics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health, Technology and Public Policy",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health, Wellbeing and Sustainable Buildings",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Healthcare Facilities",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hebrew and Jewish Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Higher Education Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History (SSEES)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Philosophy of Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Housing and City Planning",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Evolution and Behaviour",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Geography",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Rights",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Tissue Repair",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Humanitarian Policy and Practice",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Immersive Factual Storytelling",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Infection and Immunity",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Studies",
                    "university": "UCL (University College London)",
                    "location": "No Locations",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Infrastructure Investment and Finance",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Infrastructure Planning, Appraisal and Development",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Infrastructure Systems",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Innovation and Public Policy",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Innovation, Public Policy and Public Value",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Institute for Global Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Institute for Global Prosperity",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Institute for Women's Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Institute of Archaeology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Institute of Cognitive Neuroscience",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Institute of Neurology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Institute of Ophthalmology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Institute of the Americas",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrated Machine Learning Systems",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intercultural Communication",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interdisciplinary Societies, Arts and Sciences",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International City Planning",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development in the Americas",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Public Policy",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Real Estate and Planning",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations of the Americas",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Jewish Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Knowledge, Information and Data Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language and Cognition",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language Sciences (Sign Language and Deaf Studies)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language Sciences: Development of Language and Speech",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language Sciences: Neuroscience of Language and Speech",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language Sciences: Principles of Language and Speech",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language Sciences: Technology of Language and Speech",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language, Culture and History: Dutch Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language, Culture and History: French and Francophone Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language, Culture and History: German History",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language, Culture and History: German Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language, Culture and History: Hispanic Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language, Culture and History: Italian Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language, Culture and History: Scandinavian Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Finance",
                    "university": "UCL (University College London)",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leading Transformation And Change: Children And Young People's Mental Health And Wellbeing Services",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal and Political Theory",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Library and Information Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Light and Lighting",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics with a Specialisation in Computational Linguistics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics with a Specialisation in Phonology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics with a Specialisation in Syntax",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Low Intensity Cognitive Behavioural Interventions for Common Mental Health Problems",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Major Infrastructure Delivery",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management of Complex Projects",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Managing Archaeological Sites",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Public Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPH",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Material and Visual Culture",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematical Modelling",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics Education",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Education",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Imaging",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Physics and Bioengineering",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Physics and Biomedical Engineering",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Statistics and Data Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medieval and Renaissance Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health Sciences Research",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Molecular Cell Biology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Museum Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Museums and Galleries in Education",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Education",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nanotechnology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nanotechnology and Regenerative Medicine",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Natural Hazards for Insurers",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience, Physiology and Pharmacology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Obesity and Clinical Nutrition",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oncology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Optometry and Ophthalmology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Optometry and Ophthalmology (Advanced Clinical Practice)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Optometry and Ophthalmology (Enhanced Clinical Practice)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oral and Maxillofacial Surgery",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Oral Surgery",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MClinDent",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paediatric Dentistry",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paediatrics and Child Health: Advanced Paediatrics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paediatrics and Child Health: Community Child Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paediatrics and Child Health: Global Child Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paediatrics and Child Health: Intensive Care",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paediatrics and Child Health: Molecular and Genomic Paediatrics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pain Management",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Palaeoanthropology and Palaeolithic Archaeology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pathology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performing Arts Medicine (by DL)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Periodontology (Distance Learning)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Perioperative Medicine",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy of Education",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy, Politics and Economics of Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physical Therapy in Musculoskeletal Healthcare and Rehabilitation",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics and Astronomy",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics and Engineering in Medicine",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics and Engineering in Medicine by distance learning",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy Studies: Cardiorespiratory",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy Studies: Musculoskeletal",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy Studies: Neurophysiotherapy",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy Studies: Paediatrics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Planetary Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Planning",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Policy Studies in Education",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Analysis (Russia and Eastern Europe)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political Sociology (Russia and Eastern Europe)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, Violence and Crime",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Population Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Precision Medicine",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Principles of Conservation",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project and Enterprise Management",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Prosperity, Innovation and Entrepreneurship",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Prosperity, People and Planet",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Prosthodontics (Advanced Training)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MClinDent",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological Sciences",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology of Education",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public History",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Management and Leadership",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Policy",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Publishing",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Quantum Technologies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Race, Ethnicity and Postcolonial Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate Economics and Investment Analysis",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Reception of the Classical World",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Remote Sensing and Environmental Mapping",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Reproductive Science and Women's Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Reproductive Science and Women's Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Research Methods for Archaeology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Respiratory Clinical Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Responsible Finance and Alternative Assets",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Restorative Dental Practice",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Risk and Disaster Reduction",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Risk and Disaster Reduction",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Risk and Disaster Reduction",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Risk and Disaster Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Risk, Disaster and Resilience",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian and East European Literature and Culture",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian and Post-Soviet Politics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Russian Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scandinavian Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "School of Slavonic and East European Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science and Technology Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science Communication",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science, Technology and Society",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Science, Technology, Engineering and Public Policy",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scientific and Data Intensive Computing",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Security and Crime Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Security Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MRes",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Security Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Senior Wellbeing Practitioner",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Situated Practice",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "30 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Slade School of Fine Art",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Smart Buildings and Digital Engineering",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Smart Energy and the Built Environment",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social and Cultural Anthropology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social and Geographic Data Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Cognition: Research and Applications",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Development Practice",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Epidemiology",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Justice and Education",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology of Education",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Space and Climate Physics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Space Syntax: Architecture and Cities",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Space Syntax: Architecture and Cities",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spanish, Portuguese and Latin American Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spatial Planning",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Spatio-temporal Analytics and Big Data Mining",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Special and Inclusive Education",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Special and Inclusive Education (Autism)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Special and Inclusive Education (Specific Learning Difficulties)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Special Care Dentistry",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Speech, Hearing and Phonetic Sciences",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Medicine, Exercise and Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistical Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics for Clinical Trials",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Management of Projects",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Construction",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Heritage",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Resources",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Resources: Economics, Policy and Transitions",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Urbanism",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Systems Engineering Management",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Systems Engineering Master\u2019s Degree Apprenticeship",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching and Reflective Practice",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL) In-Service",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL) Pre-Service",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Technology Management",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Telecommunications with Business",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theoretical Psychoanalytic Studies (Non-Clinical)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation and Technology (Audiovisual)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation and Technology (Scientific, Technical and Medical)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation and Technology (with Interpreting)",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation: Research",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation: Translation and Culture",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation: Translation Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Transport and City Planning",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "UCL Energy Institute",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "UCL Interaction Centre",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPhil",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "United States Studies: History and Politics",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Design and City Planning",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Development Planning",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Economic Development",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Innovation and Policy",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MPA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Regeneration",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Spatial Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Spatial Science",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Studies",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Sustainability and Resilience",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Venture Capital and Private Equity with Financial Technology",
                    "university": "UCL (University College London)",
                    "location": "London, Stratford (UCL East)",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Women's Health",
                    "university": "UCL (University College London)",
                    "location": "London, Bloomsbury",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "d6b0662a82ca4c6472db3a2cb6d68a45",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting (Pathways)",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-144"
                },
                {
                    "course_name": "Accounting and Law",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-136"
                },
                {
                    "course_name": "Accounting and Management (London)",
                    "university": "Ulster University",
                    "location": "QA Higher Education London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Accounting and Marketing",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Accounting with Computing",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "57-144"
                },
                {
                    "course_name": "Accounting with Education",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "57-144"
                },
                {
                    "course_name": "Animation",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BDes (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Biomedical Science (Pathology) with placement year",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Architectural Engineering",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-117"
                },
                {
                    "course_name": "Architectural Technology and Management",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-117"
                },
                {
                    "course_name": "Architecture",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Design (Foundation Year for Specialist Degrees)",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BDes (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Biology with optional placement year",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BEng",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Biomedical Science with placement year",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Building Surveying",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-117"
                },
                {
                    "course_name": "Business Economics",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-117"
                },
                {
                    "course_name": "Business Studies",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-117"
                },
                {
                    "course_name": "Cinematic Arts",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-117"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Communication and Counselling Studies",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Communication Management and Public Relations",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-117"
                },
                {
                    "course_name": "Communication, Advertising and Marketing",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Community Youth Work",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-96"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Computer Science (Software Systems Development)",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-117"
                },
                {
                    "course_name": "Computing  with Education",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-117"
                },
                {
                    "course_name": "Computing Science",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Computing Systems",
                    "university": "Ulster University",
                    "location": "QA Higher Education London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computing Technologies",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-117"
                },
                {
                    "course_name": "Construction Engineering and Management",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-117"
                },
                {
                    "course_name": "Creative Audio",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-117"
                },
                {
                    "course_name": "Criminology and Criminal Justice",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Criminology and Criminal Justice",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Culinary Arts Management",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design (Product, Ceramics, Silversmithing and Jewellery)",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BDes (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diagnostic Radiography and Imaging",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Dietetics",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Digital Design",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BDes",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media Production",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Drama",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Drama with Education",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Economics",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-117"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-117"
                },
                {
                    "course_name": "Electronic Engineering",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Engineering with Enterprise Development",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Energy",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-117"
                },
                {
                    "course_name": "Engineering Management",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Management with DPP",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "English and History",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "English with Education",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "English with History",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Environmental Health with DPP",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Environmental Science with Education and optional placement year",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Environmental Science with optional placement year",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Event Management",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Finance and Investment Management",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food and Nutrition with placement year",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Food Business and Innovation",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Games Design",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BDes",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Design & Development",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Geography with Education and optional placement year",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Geography with optional placement",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Global Business",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Global Business with Computing",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Global Business with Drama",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Global Business with Education",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Global Business with Irish",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Global Business with Pathways",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BDes (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health & Wellbeing",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Social Care Policy",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-117"
                },
                {
                    "course_name": "Health and Well-being",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Physiology",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "History",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History with Education",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "History with English",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Human Nutrition with placement year",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Illustration",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Technologies",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-117"
                },
                {
                    "course_name": "Interactive Computing",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-117"
                },
                {
                    "course_name": "International Foundation Programme - Science Technology Engineering and Mathematics",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "Fd cert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Foundation Programme Business Humanities Arts and Social Sciences",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "Fd cert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Hospitality Management",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tourism Management",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Irish Language and Literature",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Irish with Education",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Irish with Music",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Journalism",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Journalism with Education",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Journalism with English",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Journalism with History",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Language and Linguistics",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Language and Linguistics (with Counselling Studies)",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Law",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (Graduate Entry)",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Accounting",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Law with Computing",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Irish",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Law with Marketing",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Law with Politics & International Studies",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Politics and International Studies",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Liberal Arts",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-123"
                },
                {
                    "course_name": "Marine Science with optional placement year",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-117"
                },
                {
                    "course_name": "Marketing",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "3 to 4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-117"
                },
                {
                    "course_name": "Mechanical and Manufacturing Engineering",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Enterprise Development",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Mechatronic Engineering",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-117"
                },
                {
                    "course_name": "Mechatronic Engineering with DPP",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Medicine (Graduate Entry)",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "MB BS",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BMus (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Music with Education",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Music with Irish",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Optometry",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "MOptom",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128-144"
                },
                {
                    "course_name": "Outdoor Adventure",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paramedic Science",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Personalised Medicine with optional placement year",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Pharmaceutical Biosciences",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Pharmacy",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "MPharm (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "135-144"
                },
                {
                    "course_name": "Photography with Video",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Planning, Regeneration and Development",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-117"
                },
                {
                    "course_name": "Podiatry",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Politics & International Studies",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Politics & International Studies with Criminology",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Psychology with optional placement year",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-117"
                },
                {
                    "course_name": "Quantity Surveying and Commercial Management",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "128-136"
                },
                {
                    "course_name": "Radiotherapy and Oncology",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Real Estate",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-117"
                },
                {
                    "course_name": "Renewable Energy Engineering",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-144"
                },
                {
                    "course_name": "Screen Production",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Social Policy with Criminology",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Social Policy with Sociology",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Social Work",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Social Work (BMC)",
                    "university": "Ulster University",
                    "location": "Belfast Metropolitan College",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Social Work (Graduate Entry)",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work (SWC)",
                    "university": "Ulster University",
                    "location": "South West College",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Sociology",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-128"
                },
                {
                    "course_name": "Sociology with Criminology",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Sociology with Criminology",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Sociology with Education",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Sociology with History",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Sociology with Politics and International Studies",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-105"
                },
                {
                    "course_name": "Sociology with Politics and International Studies",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MEng (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "141-152"
                },
                {
                    "course_name": "Speech and Language Therapy",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Sport and Exercise Nutrition",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Sciences with optional placement year",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "135-144"
                },
                {
                    "course_name": "Sport Studies with optional placement year",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "135-144"
                },
                {
                    "course_name": "Sport, Physical Activity and Health",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-117"
                },
                {
                    "course_name": "Sports Coaching and Performance",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Technology with Design",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Textile Art, Design and Fashion",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Veterinary Nursing, BSc Hons",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Accounting",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animation",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Behaviour Analysis",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Psychology (Mental Health and Psychological Therapies)",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MArch",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology Research",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Development and Innovation",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil and Infrastructure Engineering (with or without Applied Research)",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (Conversion)",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Management",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science (Analytics and Applications)",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science with Applied Research",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Construction Analytics and BIM",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Storage (with or without Applied Research)",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion and Textile Retail Management",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Production",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fire Safety Engineering",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Design",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Accounting with Analytics",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business (ELearning)",
                    "university": "Ulster University",
                    "location": "E-Learning",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business (Extended) with Data Analytics",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business (Extended) with Data Analytics and Advanced Practice",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business (Extended) with Human Resource Management",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business (Extended) with Human Resource Management and Advanced Practice",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Advanced Practice",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Data Analytics (with or without Advanced Practice)",
                    "university": "Ulster University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Human Resource Management (with or without Advanced Practice)",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Event Management (With and without Advanced Practice)",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tourism and Hospitality Management (With and without Advanced Practice)",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law - Employment Law and Practice",
                    "university": "Ulster University",
                    "location": "E-Learning",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing with Advanced Practice",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA (Master of Business Administration)",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Personalised Medicine",
                    "university": "Ulster University",
                    "location": "E-Learning",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE - Primary Education",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE Post Primary -  English with Drama and Media Studies",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "PGCE (Professional)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physician Associate Studies",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Administration",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MPA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate (With or without Applied Research)",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Smart Manufacturing Systems (With or without Applied Research)",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Public Health Nursing (SCPHN)",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Nursing Practice with Integrated Independent and Supplementary Prescribing (V300)",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Medicine",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Nutrition",
                    "university": "Ulster University",
                    "location": "E-Learning",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Psychology",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Accounting",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strength and Conditioning",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching of English to Speakers of Other Languages (TESOL)",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "User Expereince and Service Design",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advancing Practice",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animation",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Behaviour Analysis",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Psychology (Mental Health and Psychological Therapies)",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Development and Innovation",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cataract and Refractive Surgery (Theory)",
                    "university": "Ulster University",
                    "location": "E-Learning",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil and Infrastructure Engineering (with or without Applied Research)",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MSc (PG)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Community Youth Work",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "MSc (PG)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Management",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "PgCert",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "MSc (PG)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science (Analytics and Applications)",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Construction Analytics and BIM",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education for Healthcare Professionals",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education with Specialisms",
                    "university": "Ulster University",
                    "location": "E-Learning",
                    "qualification": "MEd (PG)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy Storage (with or without Applied Research)",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MSc (PG)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion and Textile Retail Management",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Production",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "FinTech Management",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MSc (PG)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "FinTech Management - Degree Apprenticeship",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MSc (PG)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fire Safety Engineering",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Design",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "General Practice Nursing",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Accounting with Analytics",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business (ELearning)",
                    "university": "Ulster University",
                    "location": "E-Learning",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Data Analytics (with or without Advanced Practice)",
                    "university": "Ulster University",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business with Human Resource Management (with or without Advanced Practice)",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Event Management (With and without Advanced Practice)",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tourism and Hospitality Management (With and without Advanced Practice)",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MSc",
                    "duration": "2.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law - Employment Law and Practice",
                    "university": "Ulster University",
                    "location": "E-Learning",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nurse and Midwife Prescribing",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Personalised Medicine",
                    "university": "Ulster University",
                    "location": "E-Learning",
                    "qualification": "MSc (PG)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PGCE - Education in Further Education",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "PGCE (Professional)",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Administration",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MPA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate (With or without Applied Research)",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "PgCert",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Renewable Energy and Energy Management",
                    "university": "Ulster University",
                    "location": "E-Learning",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Smart Manufacturing Systems (With or without Applied Research)",
                    "university": "Ulster University",
                    "location": "Derry~Londonderry",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MSc (PG)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Nursing Practice with Integrated Independent and Supplementary Prescribing (V300)",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Medicine",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Nutrition",
                    "university": "Ulster University",
                    "location": "E-Learning",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Psychology",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strength and Conditioning",
                    "university": "Ulster University",
                    "location": "Belfast",
                    "qualification": "MSc (PG)",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching of English to Speakers of Other Languages (TESOL)",
                    "university": "Ulster University",
                    "location": "Coleraine",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "5665e22505eda449db228416f1a52306",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "d165b60021b9ae742acdd619fe59a383",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92 Business School",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Accounting and Business Management",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92 Business School",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Accounting and Business Management",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92 Business School",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Accounting and Business Management (Accelerated)",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92 Business School",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92 Business School",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Accounting and Finance (Accelerated)",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92 Business School",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business Management",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92 Business School",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92 Business School",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business Management (Accelerated)",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92 Business School",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business of Football",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92 Business School",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business of Football",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92 Business School",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Computer Science (Accelerated)",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Cyber Security (Accelerated)",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Digital Content Production",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Digital Content Production",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92 Business School",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92 Business School",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Digital Marketing (Accelerated)",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92 Business School",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Exercise Studies",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Health, Exercise and Sport",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Health, Exercise and Sport (Accelerated)",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Physical Education",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Physical Education",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Physical Education (Accelerated)",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Software Development",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sports and Exercise Science",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sports and Exercise Science (Accelerated)",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sports Coaching",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sports Coaching",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sports Coaching (Accelerated)",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sports Journalism",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sports Journalism",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sports Journalism (Accelerated)",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sports Management",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92 Business School",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sports Management",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92 Business School",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sports Management (Accelerated)",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92 Business School",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sports Media and Communications",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sports Media and Communications",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sports Media and Communications (Accelerated)",
                    "university": "University Academy 92 (UA92)",
                    "location": "UA92",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "b1d969d99e8115f96756706f0f5c4f73",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Art and Design",
                    "university": "University Centre Calderdale College",
                    "location": "Calderdale College Main Site",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-56"
                },
                {
                    "course_name": "Community, Public Health and Social Care",
                    "university": "University Centre Calderdale College",
                    "location": "Calderdale College Main Site",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Community, Public Health and Social Care (Top-up)",
                    "university": "University Centre Calderdale College",
                    "location": "Calderdale College Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary Art and Design Practice (Top-up)",
                    "university": "University Centre Calderdale College",
                    "location": "Dean Clough Studio",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling and Applied Psychology (HTQ)",
                    "university": "University Centre Calderdale College",
                    "location": "Calderdale College Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Creative Arts (Graphic Media Communication)",
                    "university": "University Centre Calderdale College",
                    "location": "Calderdale College Main Site",
                    "qualification": "FD",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48-56"
                },
                {
                    "course_name": "Creative Arts with Graphic Media Communication (Top-up)",
                    "university": "University Centre Calderdale College",
                    "location": "Calderdale College Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Arts with Music Production",
                    "university": "University Centre Calderdale College",
                    "location": "Calderdale College Main Site",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Creative Arts with Music Production (Top-up)",
                    "university": "University Centre Calderdale College",
                    "location": "Calderdale College Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Years",
                    "university": "University Centre Calderdale College",
                    "location": "Calderdale College Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Early Years (Top-up)",
                    "university": "University Centre Calderdale College",
                    "location": "Calderdale College Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Early Years (Top-up)",
                    "university": "University Centre Calderdale College",
                    "location": "Calderdale College Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "1.5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional and Postgraduate Certificate in Education / Certificate in Education",
                    "university": "University Centre Calderdale College",
                    "location": "Calderdale College Main Site",
                    "qualification": "CertEd",
                    "duration": "2 years",
                    "study_mode": "Part-time evening",
                    "ucas_points": "N/A"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "d1d24a193e3c5edd4ef9e1810c821117",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Applied Art and Design",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Assistant Practitioner - Health and Care with Foundation Year",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with Accounting",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Business Management with Marketing",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Certificate in Education (Professional Diploma in Teaching - Further Education and Skills)",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "CertEd",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children, Young People and Families",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Construction Management for England (HTQ)",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital & Technology Solutions",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Digital & Technology Solutions Top Up",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital & Technology Solutions Top Up (Data Analytics)",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital & Technology Solutions Top Up (Networking)",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital & Technology Solutions Top Up (Software)",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Film Production",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "FdEd",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Early Childhood Studies Top Up",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Childhood Studies Top Up (Graduate Practitioner Competencies)",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Top-Up (Electrical & Electronic Engineering)",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Top-Up (Mechanical Engineering)",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Game Design (Game Art)",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Game Design (Game Development)",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Health and Social Care Top Up",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrative Counselling",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Music Production",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Performing Arts",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Performing Arts Top Up",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Photography",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Photography Top-Up",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Popular Music Performance",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Professional and Creative Writing",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Professional Graduate Certificate in Education (Professional Diploma in Teaching - Further Education and Skills)",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "PGCE (Professional)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Social Care",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Special Effects Make Up Design and Prosthetics",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Tourism and Business Management Top Up",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Tourism Management",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Certificate in Education (Professional Diploma in Teaching - Further Education and Skills)",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "CertEd",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children, Young People and Families",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "FdA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Construction Management for England (HTQ)",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital & Technology Solutions Top Up",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital & Technology Solutions Top Up (Data Analytics)",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital & Technology Solutions Top Up (Networking)",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital & Technology Solutions Top Up (Software)",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "FdEd",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Early Childhood Studies Top Up",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Childhood Studies Top Up (Graduate Practitioner Competencies)",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Top-Up (Electrical & Electronic Engineering)",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Top-Up (Mechanical Engineering)",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Social Care Top Up",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrative Counselling",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional and Creative Writing",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Professional Graduate Certificate in Education (Professional Diploma in Teaching - Further Education and Skills)",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "PGCE (Professional)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": [
                {
                    "course_name": "Education",
                    "university": "University Centre Grimsby",
                    "location": "University Centre Grimsby",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time weekend",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "49e48f1190cffb57b29c17dcfa21a772",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "1a246a9cd5aa37ba0e38be7a50c7882f",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Animal Science",
                    "university": "University Centre South Devon \u2013 South Devon College",
                    "location": "UCSD",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Applied Animal Science (Top-up)",
                    "university": "University Centre South Devon \u2013 South Devon College",
                    "location": "UCSD",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Assistant Practitioner",
                    "university": "University Centre South Devon \u2013 South Devon College",
                    "location": "UCSD",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business and Management",
                    "university": "University Centre South Devon \u2013 South Devon College",
                    "location": "UCSD",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business and Management",
                    "university": "University Centre South Devon \u2013 South Devon College",
                    "location": "UCSD",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Childhood and Youth Studies (Top-Up)",
                    "university": "University Centre South Devon \u2013 South Devon College",
                    "location": "UCSD",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Coaching (Top-Up)",
                    "university": "University Centre South Devon \u2013 South Devon College",
                    "location": "UCSD",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing",
                    "university": "University Centre South Devon \u2013 South Devon College",
                    "location": "UCSD",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Contemporary Arts Practice",
                    "university": "University Centre South Devon \u2013 South Devon College",
                    "location": "UCSD",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Creative Digital Design",
                    "university": "University Centre South Devon \u2013 South Devon College",
                    "location": "UCSD",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Education and Childhood",
                    "university": "University Centre South Devon \u2013 South Devon College",
                    "location": "UCSD",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Engineering (Design and Development)",
                    "university": "University Centre South Devon \u2013 South Devon College",
                    "location": "UCSD",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Engineering (Electrical Electronics)",
                    "university": "University Centre South Devon \u2013 South Devon College",
                    "location": "UCSD",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Engineering (Electrical Electronics)",
                    "university": "University Centre South Devon \u2013 South Devon College",
                    "location": "UCSD",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Engineering (Manufacturing)",
                    "university": "University Centre South Devon \u2013 South Devon College",
                    "location": "UCSD",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Engineering (Manufacturing)",
                    "university": "University Centre South Devon \u2013 South Devon College",
                    "location": "UCSD",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Engineering (Marine Technologies)",
                    "university": "University Centre South Devon \u2013 South Devon College",
                    "location": "UCSD",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Engineering (Photonics and Optical Electronics)",
                    "university": "University Centre South Devon \u2013 South Devon College",
                    "location": "UCSD",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Enhanced Integrated Care (Top-up)",
                    "university": "University Centre South Devon \u2013 South Devon College",
                    "location": "UCSD",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Photography",
                    "university": "University Centre South Devon \u2013 South Devon College",
                    "location": "UCSD",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Games and Interactive Design",
                    "university": "University Centre South Devon \u2013 South Devon College",
                    "location": "UCSD",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Global Change, sustainability and Society",
                    "university": "University Centre South Devon \u2013 South Devon College",
                    "location": "UCSD",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Hearing Aid Audiology",
                    "university": "University Centre South Devon \u2013 South Devon College",
                    "location": "UCSD",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Integrated Technologies Engineering (Top-Up)",
                    "university": "University Centre South Devon \u2013 South Devon College",
                    "location": "UCSD",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Business",
                    "university": "University Centre South Devon \u2013 South Devon College",
                    "location": "UCSD",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Leadership and Management (Top-up)",
                    "university": "University Centre South Devon \u2013 South Devon College",
                    "location": "UCSD",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Performance and Production",
                    "university": "University Centre South Devon \u2013 South Devon College",
                    "location": "UCSD",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Nursing Associate",
                    "university": "University Centre South Devon \u2013 South Devon College",
                    "location": "UCSD",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Outdoor Adventure Leadership",
                    "university": "University Centre South Devon \u2013 South Devon College",
                    "location": "UCSD",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Psychology and Counselling Skills",
                    "university": "University Centre South Devon \u2013 South Devon College",
                    "location": "UCSD",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Psychology and Criminology",
                    "university": "University Centre South Devon \u2013 South Devon College",
                    "location": "UCSD",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Social and Therapeutic Interventions (Top-Up)",
                    "university": "University Centre South Devon \u2013 South Devon College",
                    "location": "UCSD",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Coaching and Fitness",
                    "university": "University Centre South Devon \u2013 South Devon College",
                    "location": "UCSD",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Working with Children, Young people and Families",
                    "university": "University Centre South Devon \u2013 South Devon College",
                    "location": "UCSD",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Youth Justice and Youth Studies",
                    "university": "University Centre South Devon \u2013 South Devon College",
                    "location": "UCSD",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "9d42fb69fc5886c97b4ebdea231d1901",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "ae9ea151cc032fc7cef45f12decdd3ed",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "79cad1b2bfb02080ddbd414419aa0014",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "86762ad7f2d49b358abfa915777c4756",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "9a3db67c9a5196f69e9fa6532ebe63c7",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "f99618b40b29ed8f1cc37eaf598c92d8",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "39645c473558663bf6d4f01b55232919",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Accounting and Finance (with a Foundation Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Accounting and Finance (with a Placement Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Accounting and Finance Accelerated",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Acting for Stage and Screen",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Aeronautical Engineering",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Aeronautical Engineering (with a Foundation Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Aeronautical Engineering (with placement year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Animation",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BArch (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Art and Design (with a foundation year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Artificial Intelligence (with a foundation year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Artificial Intelligence (with a placement year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Automotive & Motorsport Engineering",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Automotive & Motorsport Engineering (with a Placement Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Automotive & Transport Design",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Automotive & Transport Design (with a Placement Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Automotive and Motorsport Engineering (with a Foundation Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Biomedical Science ( with a foundation year)",
                    "university": "University of Staffordshire",
                    "location": "Sixth Form College, Stoke on Trent",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Biomedical Science (with a placement year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Business Management (Events)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Business Management (Events) (with a Placement Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Business Management (Events) with a Foundation Year",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business Management (Human Resource Management)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Business Management (Human Resource Management) (with a Placement Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Business Management (Human Resource Management) with a Foundation Year",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business Management (Innovation and Entrepreneurship)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Business Management (Innovation and Entrepreneurship) (with a Placement Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Business Management (Innovation and Entrepreneurship) with a Foundation Year",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business Management (Sustainability)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Business Management (Sustainability) (with a Placement Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Business Management (Sustainability) with a Foundation Year",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business Management (Tourism)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Business Management (Tourism) (with a Placement Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Business Management (Tourism) with a Foundation Year",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business Management Top-Up",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management with a Foundation Year",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business Management with a Placement Year",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Cartoon and Comic Arts",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Cloud and Network Computing",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Cloud and Network Computing",
                    "university": "University of Staffordshire",
                    "location": "University of Staffordshire London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Cloud and Network Computing (with a Placement Year)",
                    "university": "University of Staffordshire",
                    "location": "University of Staffordshire London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Cloud and Network Computing with a Foundation Year",
                    "university": "University of Staffordshire",
                    "location": "University of Staffordshire London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Cloud and Network Computing with a Foundation Year",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Cloud and Network Computing with a Placement Year",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Coaching in the Community (Football)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computer Gameplay Design and Production",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Gameplay Design and Production (with a placement year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Gameplay Design and Production with a Foundation Year",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computer Games Design",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Games Design",
                    "university": "University of Staffordshire",
                    "location": "University of Staffordshire London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Games Design & Programming",
                    "university": "University of Staffordshire",
                    "location": "University of Staffordshire London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Games Design (with a foundation year)",
                    "university": "University of Staffordshire",
                    "location": "The College of Haringey, Enfield and North East London (Capital City College Group)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computer Games Design (with a foundation year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computer Games Design (with a placement year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Games Design (with a Placement Year)",
                    "university": "University of Staffordshire",
                    "location": "University of Staffordshire London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Games Design and Programming",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Games Design and Programming (With a foundation year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computer Games Design and Programming (with a Placement Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Games Design and Programming (with a Placement Year)",
                    "university": "University of Staffordshire",
                    "location": "University of Staffordshire London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Games Design with Animation",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Games Design with Animation",
                    "university": "University of Staffordshire",
                    "location": "University of Staffordshire London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Games Design with Animation (with a Foundation Year)",
                    "university": "University of Staffordshire",
                    "location": "The College of Haringey, Enfield and North East London (Capital City College Group)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computer Games Design with Animation (with a Foundation Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computer Games Design with Animation (with a Placement Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Games Design with Animation (with a Placement year)",
                    "university": "University of Staffordshire",
                    "location": "University of Staffordshire London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Games Development",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Games Development (with a foundation year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computer Games Development (with a placement year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Games Programming",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Games Programming (with a foundation year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computer Games Programming (with a placement year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Staffordshire",
                    "location": "University of Staffordshire London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Science (Cyber Security Technologist)",
                    "university": "University of Staffordshire",
                    "location": "Burton and South Derbyshire College",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Computer Science (with a Foundation Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computer Science (with a foundation year)",
                    "university": "University of Staffordshire",
                    "location": "Westminster Kingsway College (Capital City College Group)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computer Science (with a placement year)",
                    "university": "University of Staffordshire",
                    "location": "University of Staffordshire London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Science (with a Placement Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Concept Art for Games and Film",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Concept Art for Games and Film (with a Foundation Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Concept Art for Games and Film (with a Placement Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Contemporary Creative Practice",
                    "university": "University of Staffordshire",
                    "location": "Burton and South Derbyshire College",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Contemporary Creative Practice: Dance and Theatre Arts (Top Up)",
                    "university": "University of Staffordshire",
                    "location": "Burton and South Derbyshire College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary Creative Practice: Design Craft (Top Up)",
                    "university": "University of Staffordshire",
                    "location": "Burton and South Derbyshire College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary Creative Practice: Fashion (Top Up)",
                    "university": "University of Staffordshire",
                    "location": "Burton and South Derbyshire College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary Creative Practice: Fine Art (Top Up)",
                    "university": "University of Staffordshire",
                    "location": "Burton and South Derbyshire College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary Creative Practice: Graphic Design (Top Up)",
                    "university": "University of Staffordshire",
                    "location": "Burton and South Derbyshire College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary Creative Practice: Illustration (Top Up)",
                    "university": "University of Staffordshire",
                    "location": "Burton and South Derbyshire College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary Creative Practice: Moving Image (Top Up)",
                    "university": "University of Staffordshire",
                    "location": "Burton and South Derbyshire College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary Creative Practice: Photography (Top Up)",
                    "university": "University of Staffordshire",
                    "location": "Burton and South Derbyshire College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary Creative Practice: Textiles and Surface Pattern (Top Up)",
                    "university": "University of Staffordshire",
                    "location": "Burton and South Derbyshire College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling Theory and Practice",
                    "university": "University of Staffordshire",
                    "location": "Shrewsbury College of Arts & Technology",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Media Production",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Creative Media Production (Games Development)",
                    "university": "University of Staffordshire",
                    "location": "Multiple Locations",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Creative Media Production (with a Foundation Year)",
                    "university": "University of Staffordshire",
                    "location": "Sixth Form College, Stoke on Trent",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Creative Media Production (with a Placement Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Criminology (with a  Placement Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Criminology (with a Foundation Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "University of Staffordshire",
                    "location": "University of Staffordshire London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Cyber Security (with a Foundation Year)",
                    "university": "University of Staffordshire",
                    "location": "Westminster Kingsway College (Capital City College Group)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Cyber Security (with a foundation year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Cyber Security (with a Placement Year)",
                    "university": "University of Staffordshire",
                    "location": "University of Staffordshire London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Cyber Security (with a Placement Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Digital and Social Media Marketing",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Digital and Social Media Marketing (with a Foundation Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Digital and Social Media Marketing (with a Placement Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Education",
                    "university": "University of Staffordshire",
                    "location": "Multiple Locations",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Education (Top Up)",
                    "university": "University of Staffordshire",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Electrical and Electronic Engineering (with a foundation year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Electrical and Electronic Engineering (with a placement year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "English Literature and Creative Writing",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Esports",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Esports (with a foundation year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Esports (with a placement year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Fashion",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Fashion (with a placement year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Film and Television Production Technology",
                    "university": "University of Staffordshire",
                    "location": "South Staffordshire College",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Film and Television Production Technology (Top Up)",
                    "university": "University of Staffordshire",
                    "location": "South Staffordshire College",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Production",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Forensic and Criminological Psychology",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Forensic and Criminological Psychology (with a Placement Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Forensic Investigation",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Forensic Investigation (with a Foundation Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Forensic Investigation (with a Placement Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Forensic Science  (with a Foundation Year)",
                    "university": "University of Staffordshire",
                    "location": "Stoke-on-Trent College",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Forensic Science (with a Placement Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Games Art",
                    "university": "University of Staffordshire",
                    "location": "University of Staffordshire London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Games Art",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Games Art  (with a foundation year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Games Art (with a Placement Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "University of Staffordshire",
                    "location": "Burton and South Derbyshire College",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Health and Social Care (with Foundation Year)",
                    "university": "University of Staffordshire",
                    "location": "Sixth Form College, Stoke on Trent",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Illustration",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "International Business Management",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "International Business Management (with a Foundation Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "International Business Management (with a Placement Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Law",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Law (two-year accelerated)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "LLB (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Law (with a Foundation Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Mechanical Engineering (with a Foundation Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Mechanical Engineering (with a placement year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Midwifery",
                    "university": "University of Staffordshire",
                    "location": "University of Staffordshire (Centre for Health Innovation)",
                    "qualification": "BMid (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Music Production",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Music Production (with a Foundation Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Music Production (with a Placement Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Musical Theatre",
                    "university": "University of Staffordshire",
                    "location": "Wilkes Academy",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Nursing Practice (Adult) - Stafford Campus",
                    "university": "University of Staffordshire",
                    "location": "University of Staffordshire (Centre for Health Innovation)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Nursing Practice (Adult) - Stoke Campus",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Nursing Practice (Child) (September Entry Stafford)",
                    "university": "University of Staffordshire",
                    "location": "University of Staffordshire (Centre for Health Innovation)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Nursing Practice (Mental Health) - Stafford Campus",
                    "university": "University of Staffordshire",
                    "location": "University of Staffordshire (Centre for Health Innovation)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Operating Department Practice",
                    "university": "University of Staffordshire",
                    "location": "University of Staffordshire (Centre for Health Innovation)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Paramedic Science (September entry) - Stafford",
                    "university": "University of Staffordshire",
                    "location": "University of Staffordshire (Centre for Health Innovation)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Photography",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Physical Education and Sports Coaching",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Physical Education and Sports Coaching (with Foundation Year)",
                    "university": "University of Staffordshire",
                    "location": "Sixth Form College, Stoke on Trent",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Policing and Criminal Investigation",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Policing and Criminal Investigation (with a Foundation Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Policing and Criminal Investigation (with a Placement Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Policing and Criminal Investigation Top-Up",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Education with Qualified Teacher Status",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Product, Furniture and Ceramics",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Product, Furniture and Ceramics (with a Placement Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Professional Policing (with a Foundation Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Psychology (with a foundation year)",
                    "university": "University of Staffordshire",
                    "location": "Sixth Form College, Stoke on Trent",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Psychology (with Placement Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Psychology and  Child Development",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Psychology and Child Development (with Placement Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Psychology and Counselling",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Psychology and Counselling (with Placement Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Rehabilitation and Manual Therapies",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Security and Intelligence",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Security and Intelligence",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Security and Intelligence (with a Foundation Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Security and Intelligence (with a Foundation Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Security and Intelligence (with a Placement Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Security and Intelligence (with a Placement Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Software Development",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Software Development (with a Foundation Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Software Development (with a Placement a Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Sound Design",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Sound Design (with a Foundation Year)",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Sport and Exercise Science with Foundation Year",
                    "university": "University of Staffordshire",
                    "location": "Sixth Form College, Stoke on Trent",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sport Coaching and Sport Development (Top-Up)",
                    "university": "University of Staffordshire",
                    "location": "Burton and South Derbyshire College",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sports Coaching and Physical Education",
                    "university": "University of Staffordshire",
                    "location": "Multiple Locations",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Sports Journalism",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Sports Therapy",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Sustainable Business",
                    "university": "University of Staffordshire",
                    "location": "Burton and South Derbyshire College",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Visitor Attraction and Resort Management",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Working with Children, Young People and Families",
                    "university": "University of Staffordshire",
                    "location": "Staffordshire University (Stoke Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "ac771b8035264bd1c7be7e0cc346dcdc",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "39e76151776c55ea65b0e135d0f9f576",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Business Management",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Animation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Animation with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Arabic and English",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Arabic and International Business",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Arabic and International Communication",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Arabic and International Communication with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Arabic and International Relations",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Arabic and Linguistics",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Architectural Technology",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Architectural Technology with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Architecture and Environmental Design",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Architecture and Environmental Design with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Architecture with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Biochemistry with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Biological Sciences with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Biomedical Science with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Information Systems",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business Information Systems with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management (Digital Business)",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management (Entrepreneurship)",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management (Human Resource Management)",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management (Marketing)",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Management with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Chinese and English",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Chinese and International Business",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Chinese and International Communication",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Chinese and International Communication with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Chinese and International Relations",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Chinese and Linguistics",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Cognitive and Clinical Neuroscience",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Computer Games Development",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computer Games Development with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computer Science with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Construction Management",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Construction Management with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Contemporary Retail",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Creative and Professional Writing BA",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Computing",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Creative Computing with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Creative Media Arts",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Creative Media Arts with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Creative Writing and English Language",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Writing and English Literature",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Writing and English Literature with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Criminology with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Culture, Environment and Social Change",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Cyber Security and Forensics",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Cyber Security and Forensics with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Data Science and Analytics",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Data Science and Analytics with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Digital Media and Communication",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Digital Media and Journalism",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Digital Media Production",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Digital Media, Public Relations and Advertising",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Economics and Management",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "112"
                },
                {
                    "course_name": "English Language and International Communication",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Language and Linguistics",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Language and Linguistics with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Literature  with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "English Literature and History",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Literature and Language",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Literature and Language with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "European Legal Studies",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fashion Business Management with Professional Experience",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Fashion Design",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Marketing and Promotion",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Fashion Photography",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-120"
                },
                {
                    "course_name": "Fashion Photography with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Film",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Finance",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Finance and Business Management",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Financial Economics",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fine Art Mixed Media",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Fine Art Mixed Media with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "FinTech with Data Analytics",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "120"
                },
                {
                    "course_name": "French and English",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "French and International Business",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "104"
                },
                {
                    "course_name": "French and International Communication",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "104"
                },
                {
                    "course_name": "French and International Communication with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "French and International Relations",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "104"
                },
                {
                    "course_name": "French and Linguistics",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Games Art",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Global Challenges",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Global Trade and Business",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Graphic Design with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "History",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "History and Politics",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "104"
                },
                {
                    "course_name": "History with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Human Nutrition",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Human Nutrition with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Illustration",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Illustration with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Interior Architecture",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Interior Architecture with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "International Business",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Event Management",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Marketing",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "International Relations and Development",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "104"
                },
                {
                    "course_name": "International Relations and Development with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "International Relations with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Languages and Translation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Languages and Translation with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Law",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Law and Criminal Justice",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Law and International Relations",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Law with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Law with French Law",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Marketing and Data Analytics",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Marketing Communications with AI Integration",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Marketing Management",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Medical Sciences",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Music Production, Performance and Business",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Music Production, Performance and Business with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Pharmacology and Physiology",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Pharmacology and Physiology with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Photography",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Photography with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Policing",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Politics",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Politics and International Relations with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Politics with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Psychology and Counselling",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Psychology and Counselling with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Psychology and Criminology",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Psychology with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Public Health  BSc",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Quantity Surveying and Commercial Management",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Quantity Surveying and Commercial Management with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Real Estate",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Real Estate with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sociology and Criminology",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sociology with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Software Engineering with Electronics",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BEng",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Software Engineering with Electronics with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Software Engineering with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Spanish and English",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Spanish and International Business",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Spanish and International Communication",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Spanish and International Communication with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Spanish and International Relations",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Spanish and Linguistics",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sports Management",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sustainable Business Management",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Television Production",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Tourism Management",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Tourism Management  (Top-up)",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Urban Planning and Design",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Urban Planning and Design with Foundation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 to 5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "96"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Applied Biomedical Science",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Arabic and International Relations",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Arabic and Linguistics",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Chinese and International Relations",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Chinese and Linguistics",
                    "university": "University of Westminster, London",
                    "location": "No Locations",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative and Professional Writing",
                    "university": "University of Westminster, London",
                    "location": "No Locations",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Writing and English Language",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Writing and English Literature",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Culture, Environment and Social Change",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Language and International Communication",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Language and Linguistics",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Literature and History",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Literature and Language",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Fashion Photography",
                    "university": "University of Westminster, London",
                    "location": "No Locations",
                    "qualification": "BA (Hons)",
                    "duration": "6 to 7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Fine Art Mixed Media",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "96"
                },
                {
                    "course_name": "French and English",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "French and International Relations",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "French and Linguistics",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "History",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "History and Politics",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Languages and Translation",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Law",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Photography",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sociology and Criminology",
                    "university": "University of Westminster, London",
                    "location": "No Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Spanish and English",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Spanish and International Relations",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Spanish and Linguistics",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "BA (Hons)",
                    "duration": "5 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "104"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "AI, Data and Communication",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Air Transport Planning and Management",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Biotechnology",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Management - MPhil",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MPhil",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture & Sustainable Heritage",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture (RIBA part 2)",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MArch",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture and Cities - MPhil",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MPhil",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture and Environmental Design",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Emerging Technologies",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Visual Culture",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence and Digital Health",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts - MPhil",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MPhil",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Audio Production",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Big Data Technologies",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Organisational Psychology",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business of Film",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Engineering - MPhil",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MPhil",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Corporate Finance Law",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing: Writing the City",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cultural and Critical Studies",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security and Forensics",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data and Marketing Analytics",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Analytics",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing Management",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media: Storytelling and Production",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diplomacy and Global Politics",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic Policy and Analysis",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Linguistics",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Literature",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature: Modern and Contemporary Fictions",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Entertainment Law",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Entrepreneurship, Innovation and Enterprise Development",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Event Design and Management",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Expanded Photography",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Fashion Business Management",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film, Television and Moving Image",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance (Banking)",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance (International Finance)",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Accounting",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Accounting - MPhil",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MPhil",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "FinTech with Business Analytics",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "University of Westminster, London",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Contemporary Art",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Criminology",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Media",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Public Health Nutrition",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Public Health with Data Science",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Wellbeing",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Wellbeing with Professional Experience",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Humanities - MPhil",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MPhil",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interactive Media Practice",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Architecture",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International and Commercial Dispute Resolution Law",
                    "university": "University of Westminster, London",
                    "location": "School of Law",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Management",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial and Corporate Law",
                    "university": "University of Westminster, London",
                    "location": "School of Law",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Communication, Liaison and Advocacy",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development Management",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Human Resource Management",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law",
                    "university": "University of Westminster, London",
                    "location": "School of Law",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Media Business",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Planning and Sustainable Development",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Democratic Politics",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Security",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Investment and Risk Finance",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law - MPhil",
                    "university": "University of Westminster, London",
                    "location": "School of Law",
                    "qualification": "MPhil",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Technology",
                    "university": "University of Westminster, London",
                    "location": "School of Law",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal Practice",
                    "university": "University of Westminster, London",
                    "location": "School of Law",
                    "qualification": "PgDip",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Life Sciences - MPhil",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MPhil",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Logistics and Supply Chain Management",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and Marketing - MPhil",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MPhil",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing Communications",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing Management",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration (MBA)",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MBA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communications - MPhil",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MPhil",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Development",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media Management",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media, Campaigning and Social Change",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Menswear",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Menswear with Professional Experience",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Multimedia Journalism (Broadcast)",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Multimedia Journalism (Print and Online)",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Museums, Galleries and Contemporary Culture",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Museums, Galleries and Contemporary Culture with Professional Experience",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Business Management",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Organisations, Economy and Society - MPhil",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MPhil",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Organisations, Economy and Society PhD by Published Work",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "PhD",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Science",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MSc (PG)",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Legal Practice",
                    "university": "University of Westminster, London",
                    "location": "School of Law",
                    "qualification": "LLM",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Writing",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Writing with Extended Work Placement",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Relations",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Real Estate Development",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Media and Digital Communication",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Sciences - MPhil",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MPhil",
                    "duration": "36 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering (Conversion)",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialised Translation",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialised Translation with Professional Experience",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Nutrition",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainability Management and Innovation",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL)",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Tourism Management",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation and Interpreting",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation and Interpreting with Professional Experience",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Transport Planning",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban and Regional Planning",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Design",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "AI, Data and Communication",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day and evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Air Transport Planning and Management",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Block-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Management - MPhil",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MPhil",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Management PhD by Published Work",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "PhD",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture & Sustainable Heritage",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture (Professional Practice, RIBA part 3)",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "PgDip",
                    "duration": "12 months",
                    "study_mode": "Part-time evening only",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture and Cities - MPhil",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MPhil",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture and Cities PhD by Published Work",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "PhD",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture and Environmental Design",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Emerging Technologies",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Art and Visual Culture",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence and Digital Health",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts - MPhil",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MPhil",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts - PhD by Published Work",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "PhD",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Audio Production",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Big Data Technologies",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Organisational Psychology",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time day/evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business of Film",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day/evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communication",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Engineering - MPhil",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MPhil",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science and Engineering - PhD by Published Work",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "PhD",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Corporate Finance Law",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "LLM",
                    "duration": "24 months",
                    "study_mode": "Part-time day/evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Counselling",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "PgDip",
                    "duration": "24 months",
                    "study_mode": "Part-time day/evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing: Writing the City",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cultural and Critical Studies",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security and Forensics",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science and Analytics",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diplomacy and Global Politics",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Energy and Environmental Design",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "PgCert",
                    "duration": "12 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Linguistics",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Literature",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature: Modern and Contemporary Fictions",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Entertainment Law",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "LLM",
                    "duration": "24 months",
                    "study_mode": "Part-time day and evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Event Design and Management",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day and evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Expanded Photography",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film, Television and Moving Image",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day/evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Accounting - MPhil",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MPhil",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Accounting - PhD by Published Work",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "PhD",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "University of Westminster, London",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Contemporary Art",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Criminology",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Development",
                    "university": "University of Westminster, London",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Media",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Public Health Nutrition",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Public Health with Data Science",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Wellbeing",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day and evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Humanities - MPhil",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MPhil",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Humanities PhD by Published Work",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "PhD",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interactive Media Practice",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Architecture",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day and evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International and Commercial Dispute Resolution Law",
                    "university": "University of Westminster, London",
                    "location": "School of Law",
                    "qualification": "LLM",
                    "duration": "24 months",
                    "study_mode": "Part-time day and evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial and Corporate Law",
                    "university": "University of Westminster, London",
                    "location": "School of Law",
                    "qualification": "LLM",
                    "duration": "24 months",
                    "study_mode": "Part-time day and evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Communication, Liaison and Advocacy",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Law",
                    "university": "University of Westminster, London",
                    "location": "School of Law",
                    "qualification": "LLM",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Planning and Sustainable Development",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day and evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Democratic Politics",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations and Security",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law - MPhil",
                    "university": "University of Westminster, London",
                    "location": "School of Law",
                    "qualification": "MPhil",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Technology",
                    "university": "University of Westminster, London",
                    "location": "School of Law",
                    "qualification": "LLM",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law PhD by Published Work",
                    "university": "University of Westminster, London",
                    "location": "School of Law",
                    "qualification": "PhD",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal Practice",
                    "university": "University of Westminster, London",
                    "location": "School of Law",
                    "qualification": "PgDip",
                    "duration": "24 months",
                    "study_mode": "Part-time evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Life Sciences - MPhil",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MPhil",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Life Sciences PhD by Published Work",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "PhD",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Logistics and Supply Chain Management",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and Marketing - MPhil",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MPhil",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management and Marketing PhD by Published Work",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "PhD",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communications - MPhil",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MPhil",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Communications - PhD by Published Work",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "PhD",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Development",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media Management",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media, Campaigning and Social Change",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day and evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Multimedia Journalism (Broadcast)",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day and evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Multimedia Journalism (Print and Online)",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Museums, Galleries and Contemporary Culture",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Business Management",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time evening only",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Organisations, Economy and Society - MPhil",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MPhil",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Science",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MSc (PG)",
                    "duration": "12 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Legal Practice",
                    "university": "University of Westminster, London",
                    "location": "School of Law",
                    "qualification": "LLM",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Writing",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Relations",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Media and Digital Communication",
                    "university": "University of Westminster, London",
                    "location": "Harrow",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Sciences - MPhil",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MPhil",
                    "duration": "60 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Sciences PhD by Published Work",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "PhD",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering (Conversion)",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialised Translation",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise Nutrition",
                    "university": "University of Westminster, London",
                    "location": "115 New Cavendish Street",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL)",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Tourism Management",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation and Interpreting",
                    "university": "University of Westminster, London",
                    "location": "Regent Street",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Transport Planning",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time day-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban and Regional Planning",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day-release",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Urban Design",
                    "university": "University of Westminster, London",
                    "location": "Marylebone",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time day",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "78c3eb476e56e280a0b30cd1537c26b0",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "48d78b72b3059da8e9eba54aafcc4934",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "0f8fbeb694e9b6c7eb21e648babe5019",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "f0d1e965605668708028ba7822bd1c16",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance (with Foundation Year)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance (with Foundation Year) with Placement Year",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Accounting and Finance with Placement Year",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History and Classical Archaeology",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient History and Classical Archaeology with Study in Europe",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive Engineering",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Automotive Engineering",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MBio",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry with Industrial Placement",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MBio",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biochemistry with Placement Year",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MBio",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences with Industrial Placement",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MBio",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biological Sciences with Placement Year",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MBio",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science with Industrial Placement",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MBio",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science with Placement Year",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Systems Engineering",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Systems Engineering",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Industrial Placement",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with International Placement",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Medicinal Chemistry",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Medicinal Chemistry",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MChem (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Civilisation",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classical Civilisation with Study in Europe",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics (Ancient Greek) with Study in Europe",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics (Latin) with Study in Europe",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and English",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Business Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Systems Engineering",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Systems Engineering",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design and Global Sustainable Development",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BASc (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Design for Sustainable Innovation",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BASc (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Discrete Mathematics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Discrete Mathematics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economic Studies and Global Sustainable Development",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BASc (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Management",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics, Politics and International Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics, Psychology and Philosophy (EPP)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education Studies and Global Sustainable Development",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BASc (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Business Management",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Classical Civilisation",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and French",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and German",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Hispanic Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and History",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Italian",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Theatre Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language and Linguistics with Intercalated Year",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature and Creative Writing",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Literature",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Economics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and History",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French and Theatre Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French with Film Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Business Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Economics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and History",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German and Theatre Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German with Film Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Politics with Integrated Year Abroad in Brussels",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Sustainable Development",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BASc (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Sustainable Development and Business Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BASc (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Medical Sciences",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies and Economics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies and Global Sustainable Development",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BASc (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies and History",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies and Theatre Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies with Film Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Global Sustainable Development",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BASc (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Italian",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Philosophy",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Politics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History and Sociology",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art with Italian",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrated Natural Sciences",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "Msci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management (including year abroad)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Management (with Foundation Year)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Classics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Economics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian and Theatre Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian with Film Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Language, Culture and Communication with Intercalated Year",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Sociology",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with French Law",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with German Law",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Humanities",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law with Study Abroad in English",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Liberal Arts",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Life Sciences and Global Sustainable Development",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BASc (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics with Arabic",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics with Chinese",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics with French",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics with German",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics with Italian",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics with Japanese",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics with Russian",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics with Spanish",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (with Foundation Year)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management (with Foundation Year) with Placement Year",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management with Placement Year",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Manufacturing and Mechanical Engineering",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Manufacturing and Mechanical Engineering",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MMath",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Philosophy",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Physics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "Master of Mathematics and Physics",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Physics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Statistics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Statistics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MMathStat",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics, Operational Research, Statistics and Economics (MORSE)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MMorse",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics, Operational Research, Statistics, Economics (MORSE)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media and Creative Industries",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine Graduate Entry",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MB ChB",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and Economics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and Linguistics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages with Linguistics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages with Translation and Transcultural Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MBio",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience with Industrial Placement",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MBio",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience with Placement Year",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Global Sustainable Development",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BASc (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Literature",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Politics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy with Psychology",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy, Literature and Classics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy, Politics and Economics (PPE)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics with Astrophysics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MPhys",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Studies with Chinese",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and Sociology",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, International Studies and French",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, International Studies and German",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, International Studies and Global Sustainable Development",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BASc (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, International Studies and Hispanic Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, International Studies and Italian",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, Philosophy and Law (PPL)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Global Sustainable Development",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BASc (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with Education",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology with Linguistics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Criminology",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology and Global Sustainable Development",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BASc (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Systems Engineering",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Systems Engineering",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and Performance Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and Performance Studies and Global Sustainable Development",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "BASc (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Advanced Legal Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Mechanical Engineering",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Analytical and Polymer Science",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Analytical Sciences and Instrumentation",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient Literature and Thought",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient Visual and Material Culture",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Artificial Intelligence",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Linguistics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Screen Studies: Practice as Research",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Theatre: Arts, Action, Change",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts, Enterprise and Development",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Behavioural and Data Science",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Behavioural and Economic Science (Economics Track)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Behavioural and Economic Science (Science Track)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Big Data and Digital Futures",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology, Bioprocessing and Business Management",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Finance",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business and Management",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business with Marketing",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business with Operations Management",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Caribbean Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Caribbean Studies (MA by Research)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry (MSc by Research)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Scientific Writing",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Childhood in Society",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Ancient History",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Ancient History (MA by Research)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Applications of Psychology",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Communications and Information Engineering",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Community, Engagement, and Belonging",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "Master of Arts and Science (MASc)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science (MSc by Research)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Continental Philosophy",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative and Media Enterprises",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Critical and Cultural Theory",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cultural Policy Studies/Creative Industries/Media and Communication",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Culture of the European Renaissance",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security Engineering",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security Management",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Analytics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Visualisation",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA/MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diagnostics, Data and Digital Health",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diagnostics, Data and Digital Health (Medical Diagnostics)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diagnostics, Data and Digital Health (Medical Imaging)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media and Culture",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Transformation for Healthcare",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Discourse Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Doctor of Business Administration",
                    "university": "University of Warwick",
                    "location": "The Shard",
                    "qualification": "DBA",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama and Theatre Education",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama Education and English Language Teaching",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "E-Business Management",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Modern History",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics (Diploma + MSc)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics (MRes leading to PhD)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and Data Science",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Economics and International Financial Economics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Leadership and Management",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical Power Engineering",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Employment Research",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (School of Engineering)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (WMG)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Business Management",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Design Management",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Doctorate",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "EngD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Comparative Literary Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Comparative Literary Studies (MA by Research)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Drama",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language Teaching",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language Teaching and Applied Linguistics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Bioscience in a Changing Climate",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Humanities",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Economics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Economics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "6 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Financial Technology",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Security",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Games Engineering",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender and International Development",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender and Sexuality",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global and Comparative History",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Decarbonisation and Climate Change",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Decarbonisation and Climate Change (Policy)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Decarbonisation and Climate Change (Science)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "9 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Education and International Development",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Media and Communication",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Sustainable Development",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Sustainable Development",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "Master of Arts and Science (MASc)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Care",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Healthcare Operational Management",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art and Visual Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Medicine",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Humanitarian Engineering",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Humanitarian Engineering (with Management)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Humanitarian Engineering (with Sustainability)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Innovation and Entrepreneurship",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intellectual and Developmental Disabilities",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intercultural Communication",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intercultural Communication for Business and the Professions",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interdisciplinary Biomedical Research",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interdisciplinary Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Corporate Governance and Financial Regulation",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development Law and Human Rights",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Economic Law",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Political Economy",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Politics and East Asia",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Security",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Trade, Strategy and Operations",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Islamic Education: Theory and Practice",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (LLM by Research)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "LLM (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Life Sciences",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Life Sciences (MSc by Research)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Literary Practice",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Literary Translation Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Literary Translation Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management for Business Excellence",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Management of Information Systems and Digital Innovation",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing and Strategy",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics (Diploma plus MSc)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA (Full Time)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Biotechnology and Business Management",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health and Wellbeing",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modelling of Heterogeneous Systems",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern History",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and Cultures",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc by Research (Warwick Medical School)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc by Research (WMG)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Open Professional Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PhD (Warwick Medical School)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PhD by Published Work (Warwick Medical School)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Literature",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and the Arts",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political and Legal Theory",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics of Climate Change",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, Big Data and Quantitative Methods",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Polymer Chemistry",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Polymer Science",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Postgraduate Certificate in Education (PGCE) in Secondary (11-16 or 11-18)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Predictive Modelling and Scientific Computing",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Programme and Project Management",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychological Research",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (MSc by Research)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Education",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MPH",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Renaissance Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Renaissance Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scientific Research and Communication",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Smart, Connected and Autonomous Vehicles",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social and Political Thought",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Inequalities and Research Methods",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy and Social Work",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategy and Innovation",
                    "university": "University of Warwick",
                    "location": "The Shard",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Supply Chain and Logistics Management",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Automotive Electrification",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sustainable Crop Production: Agronomy for the 21st Century",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and Performance Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and Performance Studies (MA by Research)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation and Cultures",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation and Transcultural Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translational Biomedical Research",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual and Material Culture of Ancient Greece",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual and Material Culture of Ancient Rome",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Women's and Gender Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "World Literature",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Writing",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Clinical Practice",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice (Critical Care)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Legal Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Management",
                    "university": "University of Warwick",
                    "location": "The Shard",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Analytical Sciences and Instrumentation",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient Literature and Thought",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Ancient Visual and Material Culture",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Linguistics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Screen Studies: Practice as Research",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Theatre: Arts, Action, Change",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Arts, Enterprise and Development",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Behavioural Science",
                    "university": "University of Warwick",
                    "location": "The Shard",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Big Data and Digital Futures",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PgDip",
                    "duration": "21 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biotechnology, Bioprocessing and Business Management",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Caribbean Studies (MA by Research)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry (MSc by Research)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Chemistry with Scientific Writing",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Childhood in Society",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Ancient History",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Classics and Ancient History (MA by Research)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Community, Engagement, and Belonging",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "Master of Arts and Science (MASc)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Continental Philosophy",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative and Media Enterprises",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Critical and Cultural Theory",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cultural Policy Studies/Creative Industries/Media and Communication",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Culture of the European Renaissance",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Visualisation",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA/MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diagnostics, Data and Digital Health",
                    "university": "University of Warwick",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diagnostics, Data and Digital Health (Medical Diagnostics)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Diagnostics, Data and Digital Health (Medical Imaging)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media and Culture",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Discourse Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Doctor of Business Administration",
                    "university": "University of Warwick",
                    "location": "The Shard",
                    "qualification": "DBA",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Drama and Theatre Education",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Early Modern History",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Innovation",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Leadership and Management",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "5 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Employment Research",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (School of Engineering)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (WMG)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Business Leadership",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering Doctorate",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "EngD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Comparative Literary Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Comparative Literary Studies (MA by Research)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and Drama",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language Teaching",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Language Teaching and Applied Linguistics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Bioscience in a Changing Climate",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Environmental Humanities",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Security",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "French Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender and International Development",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Gender and Sexuality",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "German Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global and Comparative History",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Decarbonisation and Climate Change",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Decarbonisation and Climate Change (Policy)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Decarbonisation and Climate Change (Science)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Education and International Development",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Media and Communication",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Sustainable Development",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Care",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Healthcare Leadership - iheed",
                    "university": "University of Warwick",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "6 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Hispanic Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Art and Visual Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History of Medicine",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Humanitarian Engineering",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Humanitarian Engineering (with Management)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Humanitarian Engineering (with Sustainability)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intellectual and Developmental Disabilities",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intercultural Communication",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Intercultural Communication for Business and the Professions",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interdisciplinary Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Commercial Law",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Corporate Governance and Financial Regulation",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Development Law and Human Rights",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Economic Law",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Political Economy",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Politics and East Asia",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Security",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Islamic Education",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "Qualifying Award",
                    "duration": "3 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Islamic Education: Theory and Practice",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Italian",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law (LLM by Research)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "LLM (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership and Team Coaching",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "Graduate Award",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leading Educational Change and Improvement",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PGA",
                    "duration": "4 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Life Sciences",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Life Sciences (MSc by Research)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Linguistics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Literary Practice",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Literary Translation Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Literary Translation Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics (Diploma plus MSc)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA (Accelerator)",
                    "university": "University of Warwick",
                    "location": "The Shard",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Blended learning (part-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA (Executive)",
                    "university": "University of Warwick",
                    "location": "Multiple Locations",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Biotechnology and Business Management",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Education",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MMEd",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Education for Health Professionals - iheed",
                    "university": "University of Warwick",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "6 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medicine",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MD",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modelling of Heterogeneous Systems",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern History",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Modern Languages and Cultures",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc by Research (Warwick Medical School)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MSc by Research (WMG)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Neuroscience and Psychology of Mental Health - iheed",
                    "university": "University of Warwick",
                    "location": "No Locations",
                    "qualification": "PgCert",
                    "duration": "6 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Open Professional Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PhD (Warwick Medical School)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "PhD by Published Work (Warwick Medical School)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MPhil (Res)",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and Literature",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Philosophy and the Arts",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Political and Legal Theory",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics and International Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics of Climate Change",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Politics, Big Data and Quantitative Methods",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Polymer Chemistry",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Polymer Science",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Postgraduate Certificate in Professional Education",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "5 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pre-Hospital Critical Care",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Predictive Modelling and Scientific Computing",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Education",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "20 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (MSc by Research)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology and Education",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychotherapy and Counselling",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Renaissance Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Renaissance Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Research Methods in Education",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PGA",
                    "duration": "4 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Scientific Research and Communication",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social and Political Thought",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Inequalities and Research Methods",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Policy and Social Work",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Statistics",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Leadership and Change",
                    "university": "University of Warwick",
                    "location": "The Shard",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategy and Innovation",
                    "university": "University of Warwick",
                    "location": "The Shard",
                    "qualification": "PGA",
                    "duration": "4 days",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Supply Chain Leadership",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Teaching English to Speakers of Other Languages (TESOL)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and Performance Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and Performance Studies (MA by Research)",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA (Res)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation and Cultures",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translation and Transcultural Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Translational Biomedical Research",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual and Material Culture of Ancient Greece",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Visual and Material Culture of Ancient Rome",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Women's and Gender Studies",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "PhD",
                    "duration": "7 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "World Literature",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Writing",
                    "university": "University of Warwick",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "1e79bf8518efb5b8ecdd4884e437fd6f",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "03df05bd86f0dcbc48e14b69de65a199",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "a230661e42d0fec174400a5112e14529",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "f14d3995a9d1360163c747eca2c316a8",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "f3ef3d95e1dde7fa2adab76a434f4b4c",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Film and Television",
                    "university": "West Herts College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Game Development",
                    "university": "West Herts College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "West Herts College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Interior Design",
                    "university": "West Herts College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Level 5 HND in Computing for England: Cyber Security (HTQ) (FT)",
                    "university": "West Herts College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Music",
                    "university": "West Herts College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Public Services",
                    "university": "West Herts College",
                    "location": "Main Site",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "e10609e9917f90e1319f4b6a2d6d2fe2",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Accounting and Finance with Foundation Year",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Accounting and Finance with Internship",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Acting for Stage and Screen",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Acting for Stage and Screen (Top-Up)",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Acting for Stage and Screen with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Advertising and Public Relations",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Advertising and Public Relations with Foundation Year",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Air Transport Management Private Pilot Licence",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Air Transport Management with Air Transport Pilot Licence (frozen)",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Air Transport Management with Air Transport Pilot Licence Studies",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Airline and Airport Management",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Airline and Airport Management with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Airline and Airport Management with Placement",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Architectural Design Technology",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Architectural Design Technology with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Artificial Intelligence with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Audio Software Engineering",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Audio Software Engineering with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Bio-Sciences",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Bio-Sciences with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Bio-Sciences with Foundation and Placement",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Bio-Sciences with Placement",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biochemistry with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Biochemistry with Foundation and Placement",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Biochemistry with Placement",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Bioinformatics Genomics",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Bioinformatics Genomics with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioinformatics Genomics with Foundation and Placement",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Bioinformatics Genomics with Placement",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biological Sciences with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Biological Sciences with Foundation and Placement",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Biological Sciences with Placement",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biomedical Informatics",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biomedical Informatics with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Biomedical Informatics with Foundation and Placement",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Biomedical Informatics with Placement",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Biomedical Science with Foundation Year",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Biomedical Science with Foundation Year and Placement",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Biomedical Science with Placement",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Broadcast and Digital Journalism",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Broadcast and Digital Journalism with Foundation Year",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Building Surveying",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Building Surveying with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Analytics with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Economics",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Economics with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Studies",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Studies (top-up)",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Studies (with Foundation Year)",
                    "university": "University of West London",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Studies with Digital Marketing",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Studies with Digital Marketing with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Studies with Entrepreneurship",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Business Studies with Entrepreneurship with Foundation Year",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Business Studies with Internship",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Civil and Environmental Engineering",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Civil and Environmental Engineering (with Foundation Year)",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Civil Engineering with Industrial Placement",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Clinical Science",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Clinical Science with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Coaching for ePlayers",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Composition (Film / Theatre)",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BMus (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Composition with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BMus (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Computer Game Technology",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Games Technology with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Computer Science (with Foundation)",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Computer Science with Industrial Placement",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Construction Project Management with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Contemporary Theatre and Performance (Applied Acting and Devising)",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Contemporary Theatre and Performance (Applied Acting and Devising) - Top-Up",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Contemporary Theatre and Performance (Applied Acting and Devising) with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Criminology with Forensic Investigation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Criminology with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Criminology with Psychology",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Culinary Arts Management",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Culinary Arts Management with Foundation Year",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Culinary Arts Management with Placement",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Cyber Security with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Dance",
                    "university": "University of West London",
                    "location": "The Creative Academy",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Dance - Top Up",
                    "university": "University of West London",
                    "location": "The Creative Academy",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Data Science with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Early Childhood Studies with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Education Studies",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Education Studies with Foundation Year",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Electrical & Electronic Engineering",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Electrical & Electronic Engineering Top Up",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electrical & Electronic Engineering with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Electronic Music Production",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Electronic Music Production - Top Up",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Music Production with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "English and Creative Writing",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English and Creative Writing with Foundation Year",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "English and Film",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English and Film with Foundation Year",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "English, Media and Communications",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English, Media and Communications with Foundation Year",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Esports",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "ESports Management with Event Production",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Esports with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Esports with Livestreaming",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Esports with Livestreaming with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Event Management",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Event Management with Foundation Year",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Event Technology",
                    "university": "University of West London",
                    "location": "Nexus Institute of Creative Arts (Nexus ICA)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Fashion and Textiles",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fashion and Textiles with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Fashion Industry: Branding & Marketing",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fashion Industry: Branding & Marketing with Foundation Year",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Fashion Industry: Buying & Management",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fashion Industry: Buying & Management with Foundation Year",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Fashion Industry: Promotion & Imaging",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Fashion Industry: Promotion & Imaging with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Forensic Science with Foundation Year",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Future Food and Culinary Management",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Future Food and Culinary Management with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Future Food and Culinary Management with Placement",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Games, Design and Animation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Games, Design and Animation with Foundation Year",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Graphic Design (Visual Communication & Illustration)",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Graphic Design with Foundation Year",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Human Biology",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Human Biology with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Human Biology with Foundation and Placement",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Human Biology with Placement",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Human Genetics",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Human Genetics with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Human Genetics with Foundation and Placement",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Human Genetics with Placement",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Human Resource Management with Foundation Year",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Information Technology",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Information Technology (Top Up)",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Technology Management for Business (ITMB)",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Information Technology Management for Business (ITMB) with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Information Technology Management for Business (ITMB) with Industrial Placement",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Information Technology with Foundation Year",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Interior Architecture",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Interior Architecture with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "International Business Management",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Business Management with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "International Hospitality Management",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Hospitality Management with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "International Hospitality Management with Placement",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time (intensive)",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Music Business - Top Up",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tourism Management",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "International Tourism Management with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "International Tourism Management with Placement",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Journalism with Content Creation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Journalism with Content Creation with Foundation Year",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Law",
                    "university": "University of West London",
                    "location": "Multiple Locations",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Law (with foundation year)",
                    "university": "University of West London",
                    "location": "Multiple Locations",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Marketing and Social Media",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Marketing and Social Media with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Master of Midwifery",
                    "university": "University of West London",
                    "location": "Multiple Locations",
                    "qualification": "MMid",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Midwifery (Shortened)",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MMid",
                    "duration": "1.5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Computing",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Mathematics and Computing with Foundation Year",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Mathematics and Statistics",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Mathematics and Statistics with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Media and Communications",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Media and Communications with Foundation Year",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Midwifery",
                    "university": "University of West London",
                    "location": "Multiple Locations",
                    "qualification": "BMid (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Music Management",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Music Management Top-Up",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Performance",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BMus (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Music Performance with Foundation Year",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Music Technology",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Music Technology (Top-Up)",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Technology with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Musical Theatre",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Musical Theatre Performance",
                    "university": "University of West London",
                    "location": "MPAA",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88"
                },
                {
                    "course_name": "Musical Theatre Performance",
                    "university": "University of West London",
                    "location": "The Creative Academy",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Musical Theatre Top Up",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Musical Theatre with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "University of West London",
                    "location": "Multiple Locations",
                    "qualification": "BN (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "University of West London",
                    "location": "Multiple Locations",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Adult) - entry point for Registered Nursing Associates",
                    "university": "University of West London",
                    "location": "Multiple Locations",
                    "qualification": "BN (Hons)",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Adult) Blended Learning - Pre-Registration",
                    "university": "University of West London",
                    "location": "Multiple Locations",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Adult) On Campus - Pre-Registration",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Children's)",
                    "university": "University of West London",
                    "location": "Multiple Locations",
                    "qualification": "BN (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Nursing (Learning Disabilities)",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BN (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Nursing (Learning Disabilities)",
                    "university": "University of West London",
                    "location": "Multiple Locations",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Learning Disabilities) Blended Learning - Pre-Registration",
                    "university": "University of West London",
                    "location": "Multiple Locations",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "University of West London",
                    "location": "Multiple Locations",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "University of West London",
                    "location": "Multiple Locations",
                    "qualification": "BNurs (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Nursing Dual Field (Adult and Mental Health)",
                    "university": "University of West London",
                    "location": "Multiple Locations",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing Dual Field (Children's and Mental Health)",
                    "university": "University of West London",
                    "location": "Multiple Locations",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing Dual Field (Learning Disabilities and Mental Health)",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSci",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nutrition and Food Management",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nutrition and Food Management with Foundation Year",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Nutrition and Food Management with Placement",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Operating Department Practice",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Paramedic Science (Pre-registration)",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance and Music Management",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BMus (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Performance and Music Management with Foundation Year",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BMus (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Performance and Recording",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BMus (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Performance and Recording with Foundation Year",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BMus (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Pharmacology with Foundation Year",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Pharmacology with Foundation Year and Placement",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Pharmacology with Placement",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Photography",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Photography with Foundation Year",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "University of West London",
                    "location": "Multiple Locations",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Politics and International Relations with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Popular Music and Worship (Nexus ICA)",
                    "university": "University of West London",
                    "location": "Nexus Institute of Creative Arts (Nexus ICA)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Popular Music and Worship (Nexus ICA)",
                    "university": "University of West London",
                    "location": "Nexus Institute of Creative Arts (Nexus ICA)",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Popular Music Performance",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BMus",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Popular Music Performance - Top Up",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BMus (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Popular Music Performance with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BMus",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Acting",
                    "university": "University of West London",
                    "location": "Drama Studio London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Professional Policing with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Professional Practice - Health and Social Care",
                    "university": "University of West London",
                    "location": "Ruskin College",
                    "qualification": "CertHE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology with Clinical and Counselling Skills",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology with Criminal Investigation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology with Educational Practice",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Psychology with Foundation Year",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Public Health",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Quantity Surveying",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Quantity Surveying with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Recording, Mixing and Production",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Recording, Mixing and Production - Top Up",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Recording, Mixing and Production with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Social Sciences",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Social Sciences with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Sociology with Foundation Year",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Songwriting",
                    "university": "University of West London",
                    "location": "Nexus Institute of Creative Arts (Nexus ICA)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Sound and Music for Gaming",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Sound and Music for Gaming with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sound Engineering",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Sound Engineering (with Foundation)",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Sport and Exercise Science with Foundation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Sport Psychology",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Visual Effects",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Visual Effects with Foundation Year",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Acting and Theatre Making",
                    "university": "University of West London",
                    "location": "Drama Studio London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Music Technology",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advertising, Branding and Communication",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Project Management",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Project Management",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Project Management (with internship)",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "20 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioinformatics",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Engineering",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Brand Design and Innovation",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "University of West London",
                    "location": "West London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil and Environmental Engineering",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil and Environmental Engineering (with Internship)",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "20 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of West London",
                    "location": "No Locations",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Composition",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MMus",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Composition for Film and Television",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MMus (Comp)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security with Internship",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "20 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dementia Studies: Contemporary Approaches to Practice",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Audio Engineering",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Hospitality and Travel Leadership",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing",
                    "university": "University of West London",
                    "location": "West London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Music Composition",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MMus",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Accounting",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Economics",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Risk Management",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Business Management",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Business Management (with Internship)",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MA",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Black Studies, Decolonisation & Social Justice",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MA (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Communication Design",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Informatics",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "University of West London",
                    "location": "West London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of West London",
                    "location": "West London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Systems",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Systems (Integrated)",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Systems (with internship)",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "20 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interior Environment Design",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MA",
                    "duration": "14 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Banking and Finance Law",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Commercial Law",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management",
                    "university": "University of West London",
                    "location": "West London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Fashion Brand Management: Circular Fashion",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MA",
                    "duration": "14 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Fashion Brand Management: Fashion Buying and Merchandising",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MA",
                    "duration": "14 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Fashion Brand Management: Fashion Insight & Strategy",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MA",
                    "duration": "14 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Fashion Brand Management: Fashion PR and Communication",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MA",
                    "duration": "14 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Fashion Brand Management: Fashion Visual Merchandising",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MA",
                    "duration": "14 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Fashion Brand Management: Phygital Luxury Fashion",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MA",
                    "duration": "14 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Studies in Intellectual Property Law",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tourism and Aviation Management",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tourism and Aviation Management (with internship)",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal Practice",
                    "university": "University of West London",
                    "location": "West London",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Luxury Hospitality Management",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Luxury Hospitality Management with Internship",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MA",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Maritime Business Management",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Masters in Business Administration (MBA)",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mathematics and Statistics",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "MBA Consultancy for Innovation and Change",
                    "university": "University of West London",
                    "location": "West London",
                    "qualification": "MBA",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery",
                    "university": "University of West London",
                    "location": "Multiple Locations",
                    "qualification": "MMid",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery (Shortened)",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MMid",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Performing Arts Education",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Industry Management and Artist Development",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Performance",
                    "university": "University of West London",
                    "location": "Nexus Institute of Creative Arts (Nexus ICA)",
                    "qualification": "MMus (Perf)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Adult) Blended Learning",
                    "university": "University of West London",
                    "location": "Multiple Locations",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Adult) On Campus - Pre-Registration",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nursing (Learning Disabilities) Blended Learning",
                    "university": "University of West London",
                    "location": "Multiple Locations",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nutrition, Health, and Wellbeing",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paramedic Science (Pre-Registration)",
                    "university": "University of West London",
                    "location": "West London",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance (Classical, Jazz & Popular)",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MMus",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Policing",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Policing (International Policing)",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Policing (Investigating Serious Crime).",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Policing (Leadership and Management)",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology Conversion",
                    "university": "University of West London",
                    "location": "West London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "University of West London",
                    "location": "West London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Record Production",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering (with internship)",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "20 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic Financial Management",
                    "university": "University of West London",
                    "location": "West London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Structural Engineering",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and Performance  Practice",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Acting and Theatre Making",
                    "university": "University of West London",
                    "location": "Drama Studio London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Music Technology",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Air Transport Operations and Management (online)",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Project Management",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Project Management",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "University of West London",
                    "location": "West London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Bioinformatics",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil and Environmental Engineering",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Composition",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MMus",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Composition for Film and Television",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MMus (Comp)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Dementia Studies: Contemporary Approaches to Practice",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Audio Engineering",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Hospitality and Travel Leadership",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Inclusion (Language and Literacy)",
                    "university": "University of West London",
                    "location": "West London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Educational Inclusion (Mental Health and Wellbeing)",
                    "university": "University of West London",
                    "location": "West London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Electronic Music Composition",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MMus",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Food Business Management",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Black Studies, Decolonisation & Social Justice",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Communication Design",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Informatics",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Psychology",
                    "university": "University of West London",
                    "location": "West London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of West London",
                    "location": "West London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of West London",
                    "location": "West London",
                    "qualification": "PgDip",
                    "duration": "16 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Systems",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Banking and Finance Law",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business and Commercial Law",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Studies in Intellectual Property Law",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Tourism and Aviation Management",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal Practice",
                    "university": "University of West London",
                    "location": "West London",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal Practice (Top-up)",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Luxury Hospitality Management",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Maritime Business Management",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music and Performing Arts Education",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Industry Management and Artist Development",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Music Performance",
                    "university": "University of West London",
                    "location": "Nexus Institute of Creative Arts (Nexus ICA)",
                    "qualification": "MMus (Perf)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Nutrition, Health, and Wellbeing",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Performance (Classical, Jazz & Popular)",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MMus",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Person-Centred Health and Social Care",
                    "university": "University of West London",
                    "location": "West London",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Policing",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Policing (International Policing)",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Policing (Investigating Serious Crime).",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Policing (Leadership and Management)",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology Conversion",
                    "university": "University of West London",
                    "location": "West London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "University of West London",
                    "location": "West London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Record Production",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Theatre and Performance  Practice",
                    "university": "University of West London",
                    "location": "Main site - West London",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "4828155205fc3ccc5b1b6399dd8c5ae1",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "e7ad5d0aba4e5d09cd2c48a8d7826776",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Art Practice",
                    "university": "West Suffolk College",
                    "location": "West Suffolk College Sixth Form Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business and Management",
                    "university": "West Suffolk College",
                    "location": "University Studies and Professional Development Centre",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Commercial Music Production",
                    "university": "West Suffolk College",
                    "location": "West Suffolk College Sixth Form Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Community Coaching for England",
                    "university": "West Suffolk College",
                    "location": "University Studies and Professional Development Centre",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computing and Digital Technology",
                    "university": "West Suffolk College",
                    "location": "University Studies and Professional Development Centre",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Construction Management for England",
                    "university": "West Suffolk College",
                    "location": "University Studies and Professional Development Centre",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Counselling (Integrative)",
                    "university": "West Suffolk College",
                    "location": "University Studies and Professional Development Centre",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "West Suffolk College",
                    "location": "University Studies and Professional Development Centre",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Engineering (Design) (Top Up)",
                    "university": "West Suffolk College",
                    "location": "University Studies and Professional Development Centre",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Mechatronics) (Top up)",
                    "university": "West Suffolk College",
                    "location": "University Studies and Professional Development Centre",
                    "qualification": "BEng (Hon)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English and History",
                    "university": "West Suffolk College",
                    "location": "University Studies and Professional Development Centre",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Graphic Communication",
                    "university": "West Suffolk College",
                    "location": "West Suffolk College Sixth Form Campus",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Interactive and Immersive Technology",
                    "university": "West Suffolk College",
                    "location": "University Studies and Professional Development Centre",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Psychology",
                    "university": "West Suffolk College",
                    "location": "University Studies and Professional Development Centre",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Psychology and Criminology",
                    "university": "West Suffolk College",
                    "location": "University Studies and Professional Development Centre",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sociology and Criminology",
                    "university": "West Suffolk College",
                    "location": "University Studies and Professional Development Centre",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "West Suffolk College",
                    "location": "University Studies and Professional Development Centre",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Video Games Design",
                    "university": "West Suffolk College",
                    "location": "University Studies and Professional Development Centre",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Construction Management for England",
                    "university": "West Suffolk College",
                    "location": "University Studies and Professional Development Centre",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Engineering (Design) (Top Up)",
                    "university": "West Suffolk College",
                    "location": "University Studies and Professional Development Centre",
                    "qualification": "BEng (Hon)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Engineering (Mechatronics) (Top up)",
                    "university": "West Suffolk College",
                    "location": "University Studies and Professional Development Centre",
                    "qualification": "BEng (Hon)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "0319e15e72cc980b21d05972d55bc30f",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "893cc9308e870de1c3bd115058ee8710",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "8fcb7bee4aeec2dd13377aba08bc2b01",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Animal Conservation, Behaviour and Welfare",
                    "university": "University Centre Weston",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Art and Design",
                    "university": "University Centre Weston",
                    "location": "Loxton Campus",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Assistant Practitioner (Health and Social Care) (HTQ)",
                    "university": "University Centre Weston",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Assistant Practitioner (Mental Health) (HTQ)",
                    "university": "University Centre Weston",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Assistant Practitioner (Occupational Therapy)",
                    "university": "University Centre Weston",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Business Management and Sustainability",
                    "university": "University Centre Weston",
                    "location": "Main Site",
                    "qualification": "BA/BSc (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University Centre Weston",
                    "location": "South West Skills Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Community Coaching HTQ",
                    "university": "University Centre Weston",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Construction Management (HTQ)",
                    "university": "University Centre Weston",
                    "location": "South West Skills Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Counselling",
                    "university": "University Centre Weston",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Criminology and Public Services",
                    "university": "University Centre Weston",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Cyber Security and Networking",
                    "university": "University Centre Weston",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "60"
                },
                {
                    "course_name": "Early Years Education",
                    "university": "University Centre Weston",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Education, Society and Childhood",
                    "university": "University Centre Weston",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Film and Media Arts Production",
                    "university": "University Centre Weston",
                    "location": "Loxton Campus",
                    "qualification": "BA",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Games and Animation Production",
                    "university": "University Centre Weston",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Hair, Make-up and Prosthetics for Production",
                    "university": "University Centre Weston",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Manufacturing Engineering for England (HTQ)",
                    "university": "University Centre Weston",
                    "location": "South West Skills Campus",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "60"
                },
                {
                    "course_name": "Public and Environmental Health",
                    "university": "University Centre Weston",
                    "location": "Main Site",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Quantity Surveying for England (HTQ)",
                    "university": "University Centre Weston",
                    "location": "South West Skills Campus",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Software Development",
                    "university": "University Centre Weston",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "60"
                },
                {
                    "course_name": "Special Educational Needs, Disability and Inclusive Practice",
                    "university": "University Centre Weston",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "66"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "6c1996eeaf3e7eb0e34075dc2ddd8f20",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Animal Behaviour Welfare Conservation (Top Up)",
                    "university": "Weymouth and Kingston Maurward College",
                    "location": "Kingston Maurward Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Behaviour, Welfare and Conservation",
                    "university": "Weymouth and Kingston Maurward College",
                    "location": "Kingston Maurward Campus",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Art and Design Practice",
                    "university": "Weymouth and Kingston Maurward College",
                    "location": "Main Site (Weymouth College)",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computing for England (Computing)",
                    "university": "Weymouth and Kingston Maurward College",
                    "location": "Main Site (Weymouth College)",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Creative Media Production",
                    "university": "Weymouth and Kingston Maurward College",
                    "location": "Main Site (Weymouth College)",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Dairy Herd Management",
                    "university": "Weymouth and Kingston Maurward College",
                    "location": "Kingston Maurward Campus",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Ecological Garden Management",
                    "university": "Weymouth and Kingston Maurward College",
                    "location": "Kingston Maurward Campus",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Equine Management",
                    "university": "Weymouth and Kingston Maurward College",
                    "location": "Kingston Maurward Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "Weymouth and Kingston Maurward College",
                    "location": "Main Site (Weymouth College)",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Leadership & Management for England",
                    "university": "Weymouth and Kingston Maurward College",
                    "location": "Main Site (Weymouth College)",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Marine Ecology and Conservation",
                    "university": "Weymouth and Kingston Maurward College",
                    "location": "Kingston Maurward Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Music (Session Musician)",
                    "university": "Weymouth and Kingston Maurward College",
                    "location": "Main Site (Weymouth College)",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Performing Arts (Musical Theatre)",
                    "university": "Weymouth and Kingston Maurward College",
                    "location": "Main Site (Weymouth College)",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Professional Studies (Health & Community)",
                    "university": "Weymouth and Kingston Maurward College",
                    "location": "Main Site (Weymouth College)",
                    "qualification": "BA/BSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Public Services",
                    "university": "Weymouth and Kingston Maurward College",
                    "location": "Main Site (Weymouth College)",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Public Services Management",
                    "university": "Weymouth and Kingston Maurward College",
                    "location": "Kingston Maurward Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Rural Business Management",
                    "university": "Weymouth and Kingston Maurward College",
                    "location": "Kingston Maurward Campus",
                    "qualification": "FD",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Rural Business Management (Top Up)",
                    "university": "Weymouth and Kingston Maurward College",
                    "location": "Kingston Maurward Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport (Outdoor Adventure Management)",
                    "university": "Weymouth and Kingston Maurward College",
                    "location": "Kingston Maurward Campus",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sport; Coaching, Health and Fitness",
                    "university": "Weymouth and Kingston Maurward College",
                    "location": "Main Site (Weymouth College)",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "The Animation Industry",
                    "university": "Weymouth and Kingston Maurward College",
                    "location": "Main Site (Weymouth College)",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "446438c348608d4fae09d1b01718e7d0",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Applied Science",
                    "university": "Wigan and Leigh College",
                    "location": "Leigh",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "BA (Hons) Education (Top-up)",
                    "university": "Wigan and Leigh College",
                    "location": "Wigan",
                    "qualification": "BA (Hons)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Children, Young People and Their Services",
                    "university": "Wigan and Leigh College",
                    "location": "Wigan",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Computing (Top-Up)",
                    "university": "Wigan and Leigh College",
                    "location": "Wigan",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Industries  (Graphic Design Pathway)",
                    "university": "Wigan and Leigh College",
                    "location": "Wigan",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "HND Business (Entrepreneurship and Small Business Management)",
                    "university": "Wigan and Leigh College",
                    "location": "Wigan",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "International Travel and Tourism Management",
                    "university": "Wigan and Leigh College",
                    "location": "Wigan",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Public Services",
                    "university": "Wigan and Leigh College",
                    "location": "Wigan",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "Wigan and Leigh College",
                    "location": "Leigh",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "c4e2bfc4773dec3df2a414426f23c4fb",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Animal Management (Behaviour and Welfare)",
                    "university": "Wiltshire College and University Centre",
                    "location": "Lackham",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computing",
                    "university": "Wiltshire College and University Centre",
                    "location": "Chippenham",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Crime and Criminal Justice (Public Services)",
                    "university": "Wiltshire College and University Centre",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Early Childhood Education and Care",
                    "university": "Wiltshire College and University Centre",
                    "location": "Trowbridge",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Wiltshire College and University Centre",
                    "location": "Chippenham",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Game Development",
                    "university": "Wiltshire College and University Centre",
                    "location": "Chippenham",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Healthcare Professions' Support for England",
                    "university": "Wiltshire College and University Centre",
                    "location": "Multiple Locations",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Leadership and Management",
                    "university": "Wiltshire College and University Centre",
                    "location": "Lackham",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "Wiltshire College and University Centre",
                    "location": "Chippenham",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sport and Exercise Science (Coaching Science)",
                    "university": "Wiltshire College and University Centre",
                    "location": "Trowbridge",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Visual Effects",
                    "university": "Wiltshire College and University Centre",
                    "location": "Chippenham",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "c23755ef8d90c2e22f6ac3e87d096071",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting & Finance",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Accounting and Finance (with Foundation Year)",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Acting",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Ancient, Classical and Medieval Studies",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Animal Welfare and Society",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Anthropology",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Anthropology (with Foundation Year)",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Anthropology and Archaeology",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Archaeological Practice",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Archaeological Practice with Professional Placement",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Archaeology",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Archaeology (with Foundation Year)",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Business Management (Top-Up)",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Management (with Foundation Year)",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Childhood and Youth Studies",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88-104"
                },
                {
                    "course_name": "Classical Studies",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Classical Studies (with Foundation Year)",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Computer Science (with Foundation Year)",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Creative Writing and English Literature",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Criminology",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Criminology and Sociology",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Criminology with Law",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Criminology with Psychology",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Digital Media Design",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Early Years Education",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Early Years Education (with Foundation Year)",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Education",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Education (with Foundation Year)",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "English Literature and Film",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Event Management",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Event Management (with Foundation Year)",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Fashion Business and Marketing",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Fashion Business and Marketing (with Foundation Year)",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Film and Media Studies",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Film Production",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Film Studies",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Forensic Investigation",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Forensic Investigation and Cyber Security",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Forensic Investigative Psychology",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Geography",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "88-104"
                },
                {
                    "course_name": "History",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "History (with Foundation Year)",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "History and Archaeology",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "History and Politics",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "History with Year Abroad",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Immersive Technologies",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-112"
                },
                {
                    "course_name": "Journalism",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Law",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Law with Criminology",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Marketing (with Foundation Year)",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Media and Communication",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Medieval History",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Medieval History with Year Abroad",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Midwifery",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Modern History",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Modern History with Year Abroad",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Music and Sound Production",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Musical Theatre",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BN (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Nursing (Child)",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BN (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-128"
                },
                {
                    "course_name": "Nursing (Learning Disabilities)",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BN (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BN (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Nutrition and Dietetics",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Philosophy",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Philosophy, Religion and Ethics",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120-128"
                },
                {
                    "course_name": "Politics with International Relations",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Popular Music: Production and Performance",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Primary Education with QTS",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Psychology & Child Development",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Psychology (with Foundation Year)",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Psychology with Criminology",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Sport and Exercise Psychology",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Sport Coaching and Physical Education",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Sport Management",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Sports Journalism",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104-120"
                },
                {
                    "course_name": "Sports Therapy",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112-120"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Applied Criminology",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Sport Psychology",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cultural Heritage and Resource Management",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Death, Religion and Culture",
                    "university": "University of Winchester",
                    "location": "Distance Learning",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing and Analytics",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media Practice",
                    "university": "University of Winchester",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English Literature",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health",
                    "university": "University of Winchester",
                    "location": "Distance Learning",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Science",
                    "university": "University of Winchester",
                    "location": "Distance Learning",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management",
                    "university": "University of Winchester",
                    "location": "West Downs",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Financial Management",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal Practice",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Winchester",
                    "location": "West Downs",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Master of Business Administration (MBA)",
                    "university": "University of Winchester",
                    "location": "West Downs",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media Management",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Conversion)",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Media Management",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Advanced Clinical Practice (Primary Care and Community)",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Criminology",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Sport Psychology",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Community Rehabilitation",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cultural Heritage and Resource Management",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Death, Religion and Culture",
                    "university": "University of Winchester",
                    "location": "Distance Learning",
                    "qualification": "MA",
                    "duration": "24 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing and Analytics",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Media Practice",
                    "university": "University of Winchester",
                    "location": "No Locations",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Global Health",
                    "university": "University of Winchester",
                    "location": "Distance Learning",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health Science",
                    "university": "University of Winchester",
                    "location": "Distance Learning",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management",
                    "university": "University of Winchester",
                    "location": "West Downs",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Financial Management",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Legal Practice",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Marketing",
                    "university": "University of Winchester",
                    "location": "West Downs",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Media Management",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Medical Education",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Project Management",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Psychology (Conversion)",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Media Management",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sport and Exercise",
                    "university": "University of Winchester",
                    "location": "Main Site",
                    "qualification": "MRes",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "0bdb213e45345899d67e1f7258f72efa",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "719a10668d82c734f57998a536c8baf4",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Accounting and Finance with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Accounting and Finance with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Adult Nursing",
                    "university": "University of Wolverhampton",
                    "location": "Multiple Locations",
                    "qualification": "BNurs (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Adult Nursing with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "Multiple Locations",
                    "qualification": "BNurs (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Aerospace Engineering",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Aerospace Engineering with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Aerospace Engineering with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Animal Behaviour and Wildlife Conservation",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Animal Behaviour and Wildlife Conservation",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Animal Behaviour and Wildlife Conservation with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Animation",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Animation with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Architectural Design Technology",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Architectural Design Technology with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Architectural Design Technology with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Architecture with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Automotive Engineering",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BEng",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Automotive Engineering with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Automotive Engineering with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Biological Sciences with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Biological Sciences with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Biomedical Science with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Biomedical Science with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "British Sign Language (Deaf Studies)",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "British Sign Language (Interpreting)",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Building Surveying",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Building Surveying with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Building Surveying with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business and Accounting",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business and Accounting with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business and Accounting with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business and Finance",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business and Finance with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business and Finance with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business and Human Resource Management",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business and Human Resource Management including Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business and Human Resource Management with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business and Law",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business and Law with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business and Marketing Management",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business and Marketing Management with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business and Marketing Management with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business and Tourism Management",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business and Tourism Management with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business and Tourism Management with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business Management including Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business Management with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Chemical Engineering with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Chemical Engineering with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Childhood, Family and Education Studies",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Childhood, Family and Education Studies with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "1-48"
                },
                {
                    "course_name": "Children's Nursing",
                    "university": "University of Wolverhampton",
                    "location": "Sister Dora Building, Walsall",
                    "qualification": "BNurs (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Children's Nursing with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "Sister Dora Building, Walsall",
                    "qualification": "BNurs (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil and Transportation Engineering",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Civil and Transportation Engineering with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Civil and Transportation Engineering with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Civil Engineering with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computer Science with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computer Science with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computing and Information Technology",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Computing and Information Technology with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Construction Management",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Construction Management with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Construction Management with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Creative and Professional Writing",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Creative and Professional Writing with English Literatures",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Creative and Professional Writing with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Criminology & Criminal Justice and Law",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Criminology and Criminal Justice",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Criminology and Criminal Justice and Law with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Criminology and Social Policy",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Criminology and Social Policy with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Cybersecurity",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Cybersecurity with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Digital Marketing Management",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Digital Marketing Management with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Digital Marketing Management with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Early Childhood Studies with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Economics and Business",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Economics and Business with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Economics and Business with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Economics and Finance with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Economics and Finance with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Education Studies",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Education Studies with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "1-48"
                },
                {
                    "course_name": "English Language and Linguistics",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "English Language and Linguistics with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "English Language and Linguistics with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "English Language and Literatures",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "English Language and Literatures with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "English Language and Literatures with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "English Language and TESOL (Teaching English to Speakers of Other Languages)",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "English Language and TESOL (Teaching English To Speakers of Other Languages) with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "English Language and TESOL (Teaching English To Speakers of Other Languages) with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "English Literatures",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "English Literatures and History",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "English Literatures and History with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "English Literatures with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "English Literatures with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Film Production",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Film Production with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Fine Art with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Fine Art with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Fire and Rescue",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Fire and Rescue with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Football Coaching and Performance",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Football Coaching and Performance with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "1-48"
                },
                {
                    "course_name": "Football Coaching and Performance with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Forensic Science with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Forensic Science with Policing",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Forensic Science with Policing with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Forensic Science with Policing with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Forensic Science with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Game Design",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Game Design with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Graphic Design with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Graphic Design with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Health & Wellbeing",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Health & Wellbeing (Top-Up)",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Health and Social Care with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "1-48"
                },
                {
                    "course_name": "Healthcare Science (Physiological Sciences)",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "History",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "History and War Studies",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "History and War Studies with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "History with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Human Resource Management with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Human Resource Management with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Illustration",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Illustration with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Illustration with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "International Business Management",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "International Business Management with Foundation",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "International Business Management with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "International Hospitality Management",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "International Hospitality Management with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "International Hospitality Management with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "International Relations with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Journalism",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Journalism with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Law",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Law with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Law with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Learning Disabilities Nursing with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "Sister Dora Building, Walsall",
                    "qualification": "BNurs (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Learning Disability Nursing",
                    "university": "University of Wolverhampton",
                    "location": "Sister Dora Building, Walsall",
                    "qualification": "BNurs (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Mechanical Engineering with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Mechanical Engineering with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Medical Physiology and Diagnostics",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Medical Physiology and Diagnostics with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Mental Health Nursing",
                    "university": "University of Wolverhampton",
                    "location": "Multiple Locations",
                    "qualification": "BNurs (H)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Mental Health Nursing with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "Multiple Locations",
                    "qualification": "BNurs (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Midwifery",
                    "university": "University of Wolverhampton",
                    "location": "Sister Dora Building, Walsall",
                    "qualification": "BMid (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Midwifery with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BMid (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Motorsport Engineering",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Motorsport Engineering with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Motorsport Engineering with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Occupational Therapy with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Paramedic Science",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Paramedic Science with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Pharmaceutical Science",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Pharmaceutical Science with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Pharmacology with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Pharmacology with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Pharmacy",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "MPharm (H)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Photography",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Photography with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Photography with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Physical Education and School Sport",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Physical Education and School Sport with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "1-48"
                },
                {
                    "course_name": "Physical Education and School Sport with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Physiotherapy with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Podiatry",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Podiatry with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Policing and Intelligence",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Policing and Intelligence with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Politics and History",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Politics and History with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Politics and International Relations with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Politics and Social Policy",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Politics and Social Policy with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Primary Education",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Professional Policing with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Property Management and Real Estate",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Property Management and Real Estate with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Property Management and Real Estate with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Psychology (Criminal Behaviour)",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Psychology and Counselling",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Psychology with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "1-48"
                },
                {
                    "course_name": "Public Health",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Public Health with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "1-48"
                },
                {
                    "course_name": "Quantity Surveying",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Quantity Surveying with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Social Care",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Social Care and Criminology & Criminal Justice",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "MA (PG)",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sociology and Criminology",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sociology and Criminology with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sociology and History",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sociology and History with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sociology and Social Policy",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sociology and Social Policy with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sociology with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Special Educational Needs, Disabilities and Inclusion Studies",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Special Educational Needs, Disabilities and Inclusion Studies with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "1-48"
                },
                {
                    "course_name": "Sport & Exercise Science",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sport and Exercise Science with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sport and Exercise Science with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sport and Exercise Therapy",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sport and Exercise Therapy with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sport and Exercise Therapy with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sports Coaching",
                    "university": "University of Wolverhampton",
                    "location": "Multiple Locations",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "40"
                },
                {
                    "course_name": "Sports Coaching (Top-Up)",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Tourism Management with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "96"
                },
                {
                    "course_name": "War Studies",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "War Studies with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Zoo Animal Management and Conservation",
                    "university": "University of Wolverhampton",
                    "location": "South Staffordshire College - Rodbaston Campus",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Zoo Animal Management and Conservation (Top-Up)",
                    "university": "University of Wolverhampton",
                    "location": "South Staffordshire College - Rodbaston Campus",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Aerospace Engineering",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Aerospace Engineering with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BEng (Hon)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Animal Behaviour and Wildlife Conservation",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Animal Behaviour and Wildlife Conservation",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Animation",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Animation with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Architectural Design Technology",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Architectural Design Technology with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Automotive Engineering",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BEng",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Automotive Engineering with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BEng (Hon)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Biological Sciences",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Biological Sciences with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Biomedical Science with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "British Sign Language (Deaf Studies)",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "British Sign Language (Interpreting)",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Building Surveying",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Building Surveying with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business and Accounting",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business and Accounting with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business and Finance",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business and Finance with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business and Human Resource Management",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business and Human Resource Management including Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business and Law",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business and Marketing Management",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business and Marketing Management with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business and Tourism Management",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business and Tourism Management with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business Management",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Business Management including Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Chemical Engineering",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Chemical Engineering with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BEng (Hon)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Childhood, Family and Education Studies",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Childhood, Family and Education Studies with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "1-48"
                },
                {
                    "course_name": "Civil and Transportation Engineering",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Civil and Transportation Engineering with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BEng (Hon)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "56"
                },
                {
                    "course_name": "Computer Science with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computing and Information Technology",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Construction Management",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Construction Management with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Creative and Professional Writing",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Creative and Professional Writing with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Criminology & Criminal Justice and Law",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Criminology and Criminal Justice",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Criminology and Criminal Justice and Law with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Criminology and Social Policy",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Criminology and Social Policy with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Digital Marketing Management",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Digital Marketing Management with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Economics and Business",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Economics and Business with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Economics and Finance",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Economics and Finance with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Education Studies",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "English Language and Linguistics",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "English Language and Linguistics with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "English Language and Literatures",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "English Language and Literatures with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "English Language and TESOL (Teaching English to Speakers of Other Languages)",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "English Language and TESOL (Teaching English To Speakers of Other Languages) with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "English Literatures",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "English Literatures and History",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "English Literatures and History with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "English Literatures with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Film Production",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Film Production with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Fine Art",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Fine Art with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Fire and Rescue",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Fire and Rescue with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Football Coaching and Performance",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Football Coaching and Performance with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "1-48"
                },
                {
                    "course_name": "Football Coaching and Performance with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Forensic Science with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Forensic Science with Policing",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Forensic Science with Policing with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Game Design",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Game Design with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Graphic Design with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Health & Wellbeing",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Health & Wellbeing (Top-Up)",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Health and Social Care with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "1-48"
                },
                {
                    "course_name": "History",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "History and War Studies",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "History and War Studies with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "History with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Human Resource Management",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Human Resource Management with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Illustration",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Illustration with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "International Business Management",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "International Business Management with Foundation",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "International Hospitality Management",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "International Hospitality Management with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "International Relations",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Journalism",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Journalism with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Law",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "LLB (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Law with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "LLB (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Wolverhampton",
                    "location": "South Staffordshire College (Cannock Campus)",
                    "qualification": "FdEng",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Mechanical Engineering with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BEng (Hon)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Medical Physiology and Diagnostics",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Motorsport Engineering",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BEng (Hon)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Motorsport Engineering with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Pharmaceutical Science",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Pharmaceutical Science with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Pharmacology",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Pharmacology with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Photography",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Photography with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Physical Education and School Sport",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Physical Education and School Sport with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "1-48"
                },
                {
                    "course_name": "Physical Education and School Sport with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Policing and Intelligence",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Policing and Intelligence with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Politics and History",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Politics and History with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Politics and Social Policy",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Politics and Social Policy with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Professional Policing with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Property Management and Real Estate",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Property Management and Real Estate with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Psychology",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Psychology (Criminal Behaviour)",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Psychology and Counselling",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Public Health",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Public Health with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "1-48"
                },
                {
                    "course_name": "Quantity Surveying",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Social Care",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Social Care and Criminology & Criminal Justice",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sociology",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sociology and Criminology",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sociology and Criminology with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sociology and History",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sociology and History with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sociology and Social Policy",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sociology and Social Policy with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sociology with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Special Educational Needs, Disabilities and Inclusion Studies",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sport & Exercise Science",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sport and Exercise Science with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sport and Exercise Science with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sport and Exercise Therapy",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "Sport and Exercise Therapy with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sport and Exercise Therapy with Sandwich Placement",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BSc (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "War Studies",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "96"
                },
                {
                    "course_name": "War Studies with Foundation Year",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "BA (Hons)",
                    "duration": "8 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Adult Nursing",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Adult Nursing",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MN",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Microbiology and Biotechnology",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MArch",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration - Extended",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MBA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering Management",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Exercise Physiology",
                    "university": "University of Wolverhampton",
                    "location": "Walsall Campus",
                    "qualification": "MSc (PG)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science with Professional Practice",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "14 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Project Management with Professional Practice Placement",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security with Professional Practice",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "18 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital and Visual Communications",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing Management",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing Management - Extended",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Wolverhampton",
                    "location": "Walsall Campus",
                    "qualification": "MA",
                    "duration": "16 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Emergency Management & Resilience",
                    "university": "University of Wolverhampton",
                    "location": "Telford Campus",
                    "qualification": "MSc",
                    "duration": "15 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Production",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Accounting",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Accounting - Extended",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Wellbeing (Top-up)",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "6 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Technology Management",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Innovation and Entrepreneurship",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrative Counselling & Psychotherapy",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Administration",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MBA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Law",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Management - Extended",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Human Resource Management",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership and Management",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership and Management - Extended",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "LLM Law Conversion",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering with Professional Practice",
                    "university": "University of Wolverhampton",
                    "location": "Telford Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mental Health Nursing",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MN",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "University of Wolverhampton",
                    "location": "Telford Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "University of Wolverhampton",
                    "location": "Telford Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MPH",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Social Work",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Nursing (District Nursing)",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Nursing (General Practice Nursing)",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "PgDip",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic People Management and Human Resources",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic People Management and Human Resources (Extended)",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "12 months",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strength and Conditioning",
                    "university": "University of Wolverhampton",
                    "location": "Walsall Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Academic Practice in Higher Education",
                    "university": "University of Wolverhampton",
                    "location": "Telford Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Access, Outreach and Social Mobility",
                    "university": "University of Wolverhampton",
                    "location": "Walsall Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Advanced Clinical Practice (Specialist Negotiated Practice)",
                    "university": "University of Wolverhampton",
                    "location": "Walsall Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Applied Microbiology and Biotechnology",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Architecture",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MArch",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Artificial Intelligence",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Administration",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Business Analytics",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Civil Engineering Management",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Clinical Exercise Physiology",
                    "university": "University of Wolverhampton",
                    "location": "Walsall Campus",
                    "qualification": "MSc (PG)",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computer Science",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Construction Project Management",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Data Science",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital and Visual Communications",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing Management",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Marketing Management - Extended",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Education",
                    "university": "University of Wolverhampton",
                    "location": "Walsall Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Emergency Management & Resilience",
                    "university": "University of Wolverhampton",
                    "location": "Telford Campus",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "English",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time day and evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Film and Television Production",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Accounting",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Finance and Accounting - Extended",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Social Care",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Health and Wellbeing (Top-up)",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History (First World War)",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "28 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "History (Second World War: Conflict, Societies, Holocaust)",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "28 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Information Technology Management",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Innovation and Entrepreneurship",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Integrative Counselling & Psychotherapy",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Administration",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MBA",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "International Business Law",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interpreting",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Interpreting",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Law and Human Resource Management",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Leadership",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "LLM Law Conversion",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "LLM",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Mechanical Engineering",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Non-Medical Prescribing for Nurses, Midwives and Allied Health Professionals",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "Postgraduate Credits",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Non-Medical Prescribing for Nurses, Midwives and Allied Health Professionals",
                    "university": "University of Wolverhampton",
                    "location": "Walsall Campus",
                    "qualification": "PgCert",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Prescribing Studies",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "PgCert",
                    "duration": "6 months",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Primary Health Care (Top-up)",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "MSc",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Practice (Top-up)",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "LLM",
                    "duration": "1 year",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Practice in Healthcare (Speciality Practice)",
                    "university": "University of Wolverhampton",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "3 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Professional Practice in Healthcare (Teaching and Learning)",
                    "university": "University of Wolverhampton",
                    "location": "Multiple Locations",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Public Health",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MPH",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Nursing (District Nursing)",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Specialist Community Nursing (General Practice Nursing)",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic People Management & Human Resources",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "PgDip",
                    "duration": "2 years",
                    "study_mode": "Part-time day and evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic People Management and Human Resources",
                    "university": "University of Wolverhampton",
                    "location": "University of Wolverhampton",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Part-time day and evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strategic People Management and Human Resources (Top Up)",
                    "university": "University of Wolverhampton",
                    "location": "City Campus",
                    "qualification": "MA",
                    "duration": "1 year",
                    "study_mode": "Part-time day and evening",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Strength and Conditioning",
                    "university": "University of Wolverhampton",
                    "location": "Walsall Campus",
                    "qualification": "MSc",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "a8b46d0048719f21c2c766c5a9928395",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting & Finance Management (with Industry Placement)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Accounting and Finance Management",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Accounting and Finance Management (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Adult Nursing",
                    "university": "Wrexham University",
                    "location": "Multiple Locations",
                    "qualification": "BN (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Adult Nursing (March intake)",
                    "university": "Wrexham University",
                    "location": "Multiple Locations",
                    "qualification": "BN (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Aeronautical and Mechanical Engineering",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "MEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Aeronautical and Mechanical Engineering (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Aeronautical Engineering",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Aeronautical Engineering (with Industry Placement)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Animal Behaviour, Welfare and Conservation",
                    "university": "Wrexham University",
                    "location": "Northop",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Animal Behaviour, Welfare and Conservation",
                    "university": "Wrexham University",
                    "location": "Northop",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Animal Behaviour, Welfare and Conservation (Top-Up)",
                    "university": "Wrexham University",
                    "location": "Northop",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Animal Behaviour, Welfare and Conservation (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Animal Behaviour, Welfare and Conservation (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Animation",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Animation (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Applied Art",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Applied Art (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Architectural Design Technology",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Automotive Engineering",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "MEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Automotive Engineering",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Automotive Engineering (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Automotive Engineering (with Industrial Placement)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Biochemistry",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Biochemistry (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Biomedical Science (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Building Surveying",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-112"
                },
                {
                    "course_name": "Business & Human Resource Management",
                    "university": "Wrexham University",
                    "location": "Wrexham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Business & Human Resource Management (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Business & Human Resource Management (with Industry Placement)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "80-112"
                },
                {
                    "course_name": "Business & Management",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Business & Management (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Business & Management (with Industry Placement)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Canine Behaviour, Training and Performance",
                    "university": "Wrexham University",
                    "location": "Northop",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Children's Nursing",
                    "university": "Wrexham University",
                    "location": "Multiple Locations",
                    "qualification": "BN (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Coaching: Sport and Fitness",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Coaching: Sport and Fitness (Top-Up)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-112"
                },
                {
                    "course_name": "Comics",
                    "university": "Wrexham University",
                    "location": "Wrexham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Comics (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Computer Game Design and Enterprise",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Computer Game Design and Enterprise (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Computer Game Design and Enterprise (with Industrial Placement)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Computer Game Development",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Computer Game Development (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Computer Science",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Computer Science (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Computer Science (with Industrial Placement)",
                    "university": "Wrexham University",
                    "location": "Wrexham",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Construction Management",
                    "university": "Wrexham University",
                    "location": "Wrexham",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-112"
                },
                {
                    "course_name": "Criminology and Criminal Justice",
                    "university": "Wrexham University",
                    "location": "Wrexham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "Wrexham University",
                    "location": "Wrexham",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Cyber Security (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Cyber Security (with Industrial Placement)",
                    "university": "Wrexham University",
                    "location": "Wrexham",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Early Childhood Studies",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Early Childhood Studies (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Education Studies",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Education Studies (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "MEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Electrical and Electronic Engineering",
                    "university": "Wrexham University",
                    "location": "Wrexham",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Electrical and Electronic Engineering (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Electrical and Electronic Engineering (with Industrial Placement)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Equine Science and Welfare Management",
                    "university": "Wrexham University",
                    "location": "Northop",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Equine Science and Welfare Management (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Fine Art",
                    "university": "Wrexham University",
                    "location": "Wrexham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Fine Art (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Football Coaching and the Performance Specialist",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Football Coaching and the Performance Specialist (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Forensic Science",
                    "university": "Wrexham University",
                    "location": "Wrexham",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Forensic Science (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Forensic Science (with Industry Placement)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Game Art",
                    "university": "Wrexham University",
                    "location": "Wrexham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Game Art (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Game Art (with Industrial Placement)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "80-112"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "Wrexham University",
                    "location": "Wrexham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Graphic Design (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Health and Social Wellbeing",
                    "university": "Wrexham University",
                    "location": "Wrexham",
                    "qualification": "DipHE",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Health and Social Wellbeing (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham",
                    "qualification": "DipHE",
                    "duration": "3 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Illustration",
                    "university": "Wrexham University",
                    "location": "Wrexham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Illustration (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "International Business",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "International Business (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Blended learning (full-time)",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "International Business (with Industry Placement)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "International Tourism & Hospitality Management",
                    "university": "Wrexham University",
                    "location": "Wrexham",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "International Tourism & Hospitality Management (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "International Tourism & Hospitality Management (with Industry Placement)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Law",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Law and Business",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Law and Criminal Justice",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Marketing & Business",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Marketing & Business (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Marketing & Business (with Industry Placement)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Media Production",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Media Production (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Mental Health and Wellbeing",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Mental Health and Wellbeing (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Mental Health Nursing",
                    "university": "Wrexham University",
                    "location": "Multiple Locations",
                    "qualification": "BN (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Mental Health Nursing (March intake)",
                    "university": "Wrexham University",
                    "location": "Multiple Locations",
                    "qualification": "BN (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Nutrition and Dietetics",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Operating Department Practice",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Paramedic Science",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Photography",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "80-112"
                },
                {
                    "course_name": "Photography (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Practical Wildlife Management",
                    "university": "Wrexham University",
                    "location": "Northop",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Practical Wildlife Management (with Foundation year)",
                    "university": "Wrexham University",
                    "location": "Northop",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Psychology",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Psychology (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Quantity Surveying",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Renewable and Sustainable Engineering",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "MEng",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Renewable and Sustainable Engineering",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Renewable and Sustainable Engineering (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Renewable and Sustainable Engineering (with Industrial Placement)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BEng (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Social Work (Qualified Status)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Software Engineering (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Software Engineering (with Industrial Placement)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Speech and Language Therapy",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Sport and Exercise Science (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Sports Injury Rehabilitation",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "96-112"
                },
                {
                    "course_name": "Sports Injury Rehabilitation (with Foundation Year)",
                    "university": "Wrexham University",
                    "location": "Wrexham (Main Campus)",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                },
                {
                    "course_name": "Veterinary Nursing",
                    "university": "Wrexham University",
                    "location": "Northop",
                    "qualification": "FdSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48-72"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "6e3947e8374523d30125c7db5d1f9dfd",
        "Undergraduate": {
            "Full-time": [],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "177ee440e6c3cde8cd17b67d8c27ca2e",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Business",
                    "university": "Yeovil College University Centre",
                    "location": "Yeovil College University Centre",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Childhood Studies Care and Education (top up)",
                    "university": "Yeovil College University Centre",
                    "location": "Yeovil College University Centre",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Childhood Studies Care and Education HTQ",
                    "university": "Yeovil College University Centre",
                    "location": "Yeovil College University Centre",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computing (Level 6 Top Up)",
                    "university": "Yeovil College University Centre",
                    "location": "Yeovil College University Centre",
                    "qualification": "BSc (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Computing for England",
                    "university": "Yeovil College University Centre",
                    "location": "Yeovil College University Centre",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computing for England (Application Development and Testing) (HTQ)",
                    "university": "Yeovil College University Centre",
                    "location": "Yeovil College University Centre",
                    "qualification": "HND",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computing for England (Top-Up) (App, Dev & Testing) HTQ",
                    "university": "Yeovil College University Centre",
                    "location": "Yeovil College University Centre",
                    "qualification": "HND",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Design and Media - 3D Design Visualisation",
                    "university": "Yeovil College University Centre",
                    "location": "Yeovil College University Centre",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Digital Design and Media - 3D Design Visualisation (top up)",
                    "university": "Yeovil College University Centre",
                    "location": "Yeovil College University Centre",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Design and Media - Concept Art for Games and Film",
                    "university": "Yeovil College University Centre",
                    "location": "Yeovil College University Centre",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Digital Design and Media - Concept Art for Games and Film (top up)",
                    "university": "Yeovil College University Centre",
                    "location": "Yeovil College University Centre",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Design and Media - Digital Graphics",
                    "university": "Yeovil College University Centre",
                    "location": "Yeovil College University Centre",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Digital Design and Media - Digital Graphics (top up)",
                    "university": "Yeovil College University Centre",
                    "location": "Yeovil College University Centre",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Design and Media - Digital Media (top up)",
                    "university": "Yeovil College University Centre",
                    "location": "Yeovil College University Centre",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Design and Media \u2013 Digital Media",
                    "university": "Yeovil College University Centre",
                    "location": "Yeovil College University Centre",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Digital Design and Media \u2013 Digital Photography",
                    "university": "Yeovil College University Centre",
                    "location": "Yeovil College University Centre",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Digital Design and Media \u2013 Digital Photography (top up)",
                    "university": "Yeovil College University Centre",
                    "location": "Yeovil College University Centre",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Design and Media \u2013 Surface Pattern and Textiles",
                    "university": "Yeovil College University Centre",
                    "location": "Yeovil College University Centre",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Digital Design and Media \u2013 Surface Pattern and Textiles (top up)",
                    "university": "Yeovil College University Centre",
                    "location": "Yeovil College University Centre",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Digital Design and Media \u2013 Visual Effects for Film and Games",
                    "university": "Yeovil College University Centre",
                    "location": "Yeovil College University Centre",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Digital Design and Media \u2013 Visual Effects for Film and Games (top up)",
                    "university": "Yeovil College University Centre",
                    "location": "Yeovil College University Centre",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": []
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    },
    {
        "id": "e62337a7165239dd2aa289386aed4c83",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "3D Creative Practice (York School of Art)",
                    "university": "York College University Centre",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Business Management",
                    "university": "York College University Centre",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Certificate in Education (Lifelong Learning)",
                    "university": "York College University Centre",
                    "location": "Main Site",
                    "qualification": "CertEd",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Children's Development and Learning",
                    "university": "York College University Centre",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Digital Technologies (Cybersecurity)",
                    "university": "York College University Centre",
                    "location": "Main Site",
                    "qualification": "FdSc",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "64"
                },
                {
                    "course_name": "Engineering (Two Pathways) HTQ",
                    "university": "York College University Centre",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Fashion Design and Garment Technology (York School of Art)",
                    "university": "York College University Centre",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Film Production and Professional Practice (York School of Art)",
                    "university": "York College University Centre",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Graphic and Communication Design (York School of Art)",
                    "university": "York College University Centre",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "72"
                },
                {
                    "course_name": "Leading Children's Development and Learning",
                    "university": "York College University Centre",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                },
                {
                    "course_name": "Media Make-up, Special Effects and Hair Design (York School of Art)",
                    "university": "York College University Centre",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "2 years",
                    "study_mode": "Full-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Media Make-up, Special Effects and Hair Design (York School of Art)",
                    "university": "York College University Centre",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "See entry requirements"
                }
            ],
            "Part-time": [
                {
                    "course_name": "3D Creative Practice (York School of Art)",
                    "university": "York College University Centre",
                    "location": "Main Site",
                    "qualification": "BA (Hons)",
                    "duration": "6 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Business Management",
                    "university": "York College University Centre",
                    "location": "Main Site",
                    "qualification": "FdA",
                    "duration": "4 years",
                    "study_mode": "Part-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Construction Management for England (Site Supervisor)",
                    "university": "York College University Centre",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time day-release",
                    "ucas_points": "32"
                },
                {
                    "course_name": "Engineering (Two Pathways) HTQ",
                    "university": "York College University Centre",
                    "location": "Main Site",
                    "qualification": "HNC",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "32"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [
                {
                    "course_name": "Teacher Training: PGCE/CertEd",
                    "university": "York College University Centre",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Teacher Training: PGCE/CertEd",
                    "university": "York College University Centre",
                    "location": "Main Site",
                    "qualification": "PGCE",
                    "duration": "2 years",
                    "study_mode": "Part-time",
                    "ucas_points": "N/A"
                }
            ]
        }
    },
    {
        "id": "dc2efb0d855bd0d1a651e37a00fd7711",
        "Undergraduate": {
            "Full-time": [
                {
                    "course_name": "Accounting and Finance",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Accounting and Finance with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Accounting and Finance with Placement Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Acting",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "American Studies and War Studies",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "American Studies and War Studies with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Animation",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Biomedical Science",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "British Sign Language, Deaf Studies and Linguistics",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business Management",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Business Management with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Business Management with Placement Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Children, Young People and Society",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Children, Young People and Society with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Computer Science",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Computer Science with Year in Industry",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Counselling and Mental Health",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Counselling and Mental Health with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Creative Writing",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Writing and English Literature",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Writing and English Literature with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Creative Writing and Media",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Creative Writing and Media with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Creative Writing with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Criminology",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Criminology with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Criminology with Police Studies",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Criminology with Police Studies with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Cyber Security",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Cyber Security with Year in Industry",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with year in industry",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Diagnostic Radiography",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Digital Marketing and Data Analytics",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Drama: Education & Community",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Early Years Education and Care (with Graduate Practitioner Competencies)",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Early Years Education and Care (with Graduate Practitioner Competencies) with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Economics",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Economics with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Economics with Placement Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Education, SEN and Mental Health",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Education, SEN and Mental Health with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "English Language and Linguistics",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Language and Linguistics with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "English Language, Linguistics and TESOL",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Language, Linguistics and TESOL with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "English Literature",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Literature and Film Studies",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Literature and Film Studies with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "English Literature and History",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Literature and History with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "English Literature and Religion",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "English Literature and Religion with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "English Literature with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Environmental Geography",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Environmental Geography with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Events and Experience Management",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Events and Experience Management with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Events and Experience Management with Placement Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Fashion Marketing",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Fashion Marketing with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Fashion Marketing with Placement Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Film and Television Production",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Film Studies",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Film Studies and Media",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Film Studies and Media with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Film Studies with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Fine Art",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Forensic Psychology",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Forensic Psychology with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Games Design",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Games Development",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Games Development with Year in Industry",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Geography",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Geography with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Global Business Management (Top Up Degree)",
                    "university": "York St John University",
                    "location": "YSJU London Campus",
                    "qualification": "BA (Hons)",
                    "duration": "1 year",
                    "study_mode": "Full-time",
                    "ucas_points": "N/A"
                },
                {
                    "course_name": "Graphic Design",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "History",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "History and American Studies",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "History and American Studies with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "History and Politics",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "History and Politics with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "History and Religion",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "History and Religion with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "History with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Human Geography",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Human Geography with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Illustration",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Interior Design",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "International Business",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "International Business with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "International Business with Placement Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "International Tourism and Hospitality Management",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "International Tourism and Hospitality Management with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "International Tourism and Hospitality Management with Placement Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Sandwich including industrial placement",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Japanese, Intercultural Communication and Linguistics",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including placement abroad",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Japanese, TESOL and Linguistics",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time with time abroad",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Journalism",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Korean, Intercultural Communication and Linguistics",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Korean, TESOL and Linguistics",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Law",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "LLB (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Law with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "LLB (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Marketing",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Marketing with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Marketing with Placement Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Media and Communication",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Media and Communication with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Media and English Literature",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Media and English Literature with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Media Production",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Midwifery",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "MLaw",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "MLaw",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "MLaw with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "MLaw",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Music",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Production",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Music Production & Music Business",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Musical Theatre",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Nursing (Adult)",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Nursing (Mental Health)",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "112"
                },
                {
                    "course_name": "Occupational Therapy",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BHSc (Hon)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Paramedic Science",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Photography",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Physical Education & Sports Coaching",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Physical Education and Sports Coaching with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Physical Education and Sports Coaching with Integrated Masters",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "Master in Science (with Honours) \u2013 MSci (Hon)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Physical Education and Sports Coaching with Integrated Masters with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "Msci (Hon)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Physiotherapy",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "128"
                },
                {
                    "course_name": "Politics",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Politics and International Relations",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Politics and International Relations with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Politics with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time including foundation year",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Politics, Philosophy and Economics",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Politics, Philosophy and Economics with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Politics, Philosophy and Economics with Placement Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Primary Education (3-7)",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Primary Education (3-7) with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Primary Education (5-11)",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Primary Education (5-11) with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Product Design",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Professional Policing",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Professional Policing with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Psychology",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Psychology with Child Development",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Psychology with Child Development with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Psychology with Counselling",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "120"
                },
                {
                    "course_name": "Psychology with Counselling with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Psychology with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Religion and Theology",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Religion and Theology with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Religion, Philosophy and Ethics",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Religion, Philosophy and Ethics with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sociology",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sociology with Criminology",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sociology with Criminology with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sociology with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Software Engineering",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Software Engineering with Year in Industry",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sport and Exercise Science",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sport and Exercise Science with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sport and Exercise Science with Integrated Masters",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "MSc (UG)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sport and Exercise Science with Integrated Masters with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "MSc (UG)",
                    "duration": "5 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sport and Exercise Therapy",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "Sport and Exercise Therapy with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                },
                {
                    "course_name": "Sports Journalism",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "User Experience Design",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "War Studies and History",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "3 years",
                    "study_mode": "Full-time",
                    "ucas_points": "104"
                },
                {
                    "course_name": "War Studies and History with Foundation Year",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BA (Hons)",
                    "duration": "4 years",
                    "study_mode": "Full-time",
                    "ucas_points": "48"
                }
            ],
            "Part-time": [
                {
                    "course_name": "Data Science Degree Apprenticeship",
                    "university": "York St John University",
                    "location": "York St John University",
                    "qualification": "BSc (Hons)",
                    "duration": "3.5 years",
                    "study_mode": "Mixed mode",
                    "ucas_points": "N/A"
                }
            ]
        },
        "Postgraduate": {
            "Full-time": [],
            "Part-time": []
        }
    }
]

export default courses_updated;
